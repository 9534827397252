import React from "react";
import { CustomLabel, CustomSelectField } from "../../styled";

const InstrumentSelect = ({ instruments, value, handleChange }) => {
  return (
    <>
      <CustomLabel className="mb-3">Instrument</CustomLabel>
      <CustomSelectField
        name="instrumentId"
        value={value}
        onChange={handleChange}
        width="100%"
      >
        <option value="" disabled>
          Instrument
        </option>
        {instruments?.map(({ id, name }, i) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </CustomSelectField>
    </>
  );
};

export default InstrumentSelect;
