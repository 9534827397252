import { toJS } from "mobx";
import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { feedMetadataActions } from "src/constants/feedItemsMetadataEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useModal from "src/hooks/useModal";
import { BookmarkFill, PinAngleFill } from "styled-icons/bootstrap";
import { BookmarkOff } from "@styled-icons/fluentui-system-regular/BookmarkOff";
import { Hide } from "styled-icons/boxicons-regular";
import { Download, Edit } from "styled-icons/evaicons-solid";
import { PinOff } from "styled-icons/fluentui-system-filled";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import {
  dropdownOptionsValues,
  getUserMainTabs,
  getUserSecondaryTabs,
  secondaryNavTabKeys,
  secondaryNavTabs,
} from "../constants";
import useHelpers from "../hooks/useHelpers";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  downloadSingleItem,
  injectUserStore,
  updatedMomentNow,
} from "src/utils/helpers";
import useLatestFeed from "src/hooks/useLatestFeed";
import moment from "moment";

const contextInitialValues = {
  currentMainTab: "",
  setCurrentMainTab: () => {},
  currentSecondaryTab: "",
  setCurrentSecondaryTab: () => {},
  displayedFeedItems: [],
  loadingFeedItems: false,
  currentFeedItem: undefined,
  setCurrentFeedItemId: () => {},
  viewFeedItemModalData: undefined,
  editFeedItemModalData: undefined,
  createWorkUpdateModalData: undefined,
  bookmarkFeedItem: (feedItemId) => {},
  dropdownOptions: [],
  loadingAction: false,
  userFeedMetadata: [],
  getFeedItemMetaInfo: (feedItemId) => {},
  feedUsers: [],
  onEditFeedItemSuccess: (feedItemId, editedObj) => {},
  newFeedItems: [],
  refreshData: () => {},
};
const FeedSectionContext = React.createContext(contextInitialValues);

const FeedSectionContextProvider = ({ children, UserStore, ...props }) => {
  const user = toJS(UserStore.user);
  const userRole = user.role;
  const userId = user.id || user.uid;
  const userExtraFunctions = user.extraFunctions;
  const userMainTabs = getUserMainTabs(userRole);
  const userSecondaryTabs = getUserSecondaryTabs(userRole, userExtraFunctions);

  const { latestFeedItems: newFeedItems } = useLatestFeed({ user });

  const {
    getFeedItems,
    updateFeedItem,
    addFeedItemMetadata,
    getFeedItemMetadata,
    getUserFeedItemsMetadata,
    deleteFeedItemMetadata,
    deleteFeedItemWithClearMetaOption,
    getUsersByIds,
  } = useFirebaseFns();

  const { checkFeedItemMetadata, isFeedItemMetaAlreadyExistsInDB } =
    useHelpers();
  // for managing the view feed item modal state
  const viewFeedItemModalData = useModal();
  // for managing the edit feed item modal state
  const editFeedItemModalData = useModal();
  // for managing the create work update modal
  const createWorkUpdateModalData = useModal();

  const [currentMainTab, setCurrentMainTab] = useState(userMainTabs[0].key);
  const [currentSecondaryTab, setCurrentSecondaryTab] = useState(
    userSecondaryTabs[0].key
  );

  // contains a list of feed items based on the current main tab
  const [currentMainTabItems, setCurrentMainTabItems] = useState([]);
  // contains a list of feed items metadata for the current user
  const [userFeedMetadata, setUserFeedMetadata] = useState([]);
  // contains a list of users that created the current feed items
  const [feedUsers, setFeedUsers] = useState([]);
  const [loadingFeedItems, setLoadingFeedItems] = useState(false);

  // loading state whenever we do an action (bookmark/hide/etc..)
  const [loadingAction, setLoadingAction] = useState(false);

  // used for opening the view/edit modals
  const [currentFeedItemId, setCurrentFeedItemId] = useState("");

  // used to refresh the data and refetch everything by changing the counter
  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const getFeedItemMetaInfo = (feedItemId) => {
    if (!feedItemId) return {};
    // const feedItem = currentMainTabItems.find(({ id }) => id === feedItemId);

    const isBookmarked = checkFeedItemMetadata(
      feedItemId,
      userFeedMetadata,
      feedMetadataActions.BOOKMARK
    );
    const isHidden = checkFeedItemMetadata(
      feedItemId,
      userFeedMetadata,
      feedMetadataActions.HIDE
    );
    const isPinned = checkFeedItemMetadata(
      feedItemId,
      userFeedMetadata,
      feedMetadataActions.PIN
    );

    return {
      isBookmarked,
      isHidden,
      isPinned,
    };
  };
  const addMetadataAction = async (feedItemId, actionType) => {
    try {
      if (!feedItemId) {
        toast.error("no feedItemId");
        return;
      }
      setLoadingAction(true);
      const isDocFound = await isFeedItemMetaAlreadyExistsInDB(
        userId,
        feedItemId,
        actionType
      );
      if (isDocFound) {
        toast.error("Already Done");
        return;
      }

      const feedItem = currentMainTabItems.find(({ id }) => id === feedItemId);
      const feedItemType = feedItem?.type;
      const metaObj = {
        createdAt: new Date(),
        actionType,
        userId,
        feedItemType,
        feedItemId,
      };
      const createdMetaDoc = await addFeedItemMetadata(metaObj);
      toast.success("Success");

      //state update
      setUserFeedMetadata((oldVal) => [...oldVal, createdMetaDoc]);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };
  const removeMetadataAction = async (feedItemId, actionType) => {
    try {
      if (!feedItemId) {
        toast.error("no feedItemId");
        return;
      }
      setLoadingAction(true);
      const isDocFound = await isFeedItemMetaAlreadyExistsInDB(
        userId,
        feedItemId,
        actionType
      );
      // if doc doesnt exist that means we don't have to remove the doc anymore
      if (!isDocFound) {
        toast.error("Already Done");
        return;
      }

      const deletedItemsIds = await deleteFeedItemMetadata(feedItemId, {
        actionType,
        userId,
      });
      toast.success("Success");

      //state update
      setUserFeedMetadata((oldVal) =>
        oldVal.filter((meta) => !deletedItemsIds.includes(meta.id))
      );
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };

  const deleteFeedItem = async (feedItemId) => {
    try {
      if (!feedItemId) {
        toast.error("no feedItemId");
        return;
      }
      setLoadingAction(true);

      const feedItem = currentMainTabItems.find(({ id }) => id === feedItemId);
      const attachmentsUrls =
        feedItem?.attachments?.map(({ url }) => url) || [];

      await deleteFeedItemWithClearMetaOption(feedItemId, attachmentsUrls);
      toast.success("Success");

      //state update
      setCurrentMainTabItems((oldVal) =>
        oldVal.filter((feedItem) => feedItem.id !== feedItemId)
      );
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };

  const onEditFeedItemSuccess = (feedItemId, editedObj) => {
    const updatedFeedItems = currentMainTabItems.map((item) => {
      if (item.id === feedItemId) {
        return {
          ...item,
          // overrides the existing feed item properties with the edited ones
          ...editedObj,
        };
      } else {
        return item;
      }
    });
    setCurrentMainTabItems(updatedFeedItems);
  };

  const downloadFeedAttachment = async (feedItemId) => {
    try {
      const feedItem = currentMainTabItems.find(({ id }) => id === feedItemId);
      if (feedItem) {
        const url = feedItem.attachments[0]?.url;
        if (url) {
          const fileName = feedItem.attachments[0].name;
          downloadSingleItem(url, fileName);
        }
      }
      return feedItem;
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fetchFeedItems = async () => {
      try {
        if (!currentMainTab) {
          setCurrentMainTabItems([]);
        }
        setLoadingFeedItems(true);

        const [currentMainTabItems, feedItemsMetadata] = await Promise.all([
          getFeedItems({
            type: currentMainTab,
            afterPublishDate: false,
            filterByRoles: [userRole],
          }),
          getUserFeedItemsMetadata(userId, { feedItemType: currentMainTab }),
        ]);

        // getting the users that created the current feed items
        const feedItemsUsersIds = currentMainTabItems.map(
          ({ createdBy }) => createdBy
        );
        const feedUsers = await getUsersByIds(feedItemsUsersIds);

        setCurrentMainTabItems(currentMainTabItems);
        setUserFeedMetadata(feedItemsMetadata);
        setFeedUsers(feedUsers);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingFeedItems(false);
      }
    };

    fetchFeedItems();
  }, [currentMainTab, userRole, refresh]);

  // the feed items that will be displayed on the screen after filtering through the main and secondary tabs
  const displayedFeedItems = useMemo(() => {
    if (!currentMainTabItems) return [];
    let finalArr = [];
    if (currentSecondaryTab === secondaryNavTabKeys.ALL) {
      // all tab will contain all items after publishDate but filters out hidden items + sort the pinned items at the top
      const filtered = currentMainTabItems.filter(({ id, publishDate }) => {
        const isAfterPublishDate = updatedMomentNow().isAfter(publishDate);
        const isHidden = checkFeedItemMetadata(
          id,
          userFeedMetadata,
          feedMetadataActions.HIDE
        );
        return isAfterPublishDate && !isHidden;
      });
      const sorted = filtered.sort((firstItem, secondItem) => {
        const isFirstPinned = checkFeedItemMetadata(
          firstItem.id,
          userFeedMetadata,
          feedMetadataActions.PIN
        );
        if (isFirstPinned) return -1;
        return 1;
      });
      finalArr = sorted;
    } else if (currentSecondaryTab === secondaryNavTabKeys.BOOKMARKS) {
      // bookmarks tab only shows bookmarked items
      const filtered = currentMainTabItems.filter(({ id, publishDate }) => {
        const isAfterPublishDate = updatedMomentNow().isAfter(publishDate);

        const isBookmarked = checkFeedItemMetadata(
          id,
          userFeedMetadata,
          feedMetadataActions.BOOKMARK
        );
        return isAfterPublishDate && isBookmarked;
      });
      finalArr = filtered;
    } else if (currentSecondaryTab === secondaryNavTabKeys.HIDDEN) {
      // hidden tab only shows hidden items
      const filtered = currentMainTabItems.filter(({ id, publishDate }) => {
        const isAfterPublishDate = updatedMomentNow().isAfter(publishDate);

        const isHidden = checkFeedItemMetadata(
          id,
          userFeedMetadata,
          feedMetadataActions.HIDE
        );
        return isAfterPublishDate && isHidden;
      });
      finalArr = filtered;
    } else if (currentSecondaryTab === secondaryNavTabKeys.SCHEDULED) {
      // Schheduled tab only shows items that are scheduled in the future
      const filtered = currentMainTabItems.filter(({ publishDate }) => {
        const isAfterPublishDate = updatedMomentNow().isAfter(publishDate);

        return !isAfterPublishDate;
      });
      finalArr = filtered;
    }

    return finalArr;
  }, [
    currentMainTabItems,
    currentMainTab,
    currentSecondaryTab,
    userFeedMetadata,
  ]);

  const currentFeedItem = useMemo(() => {
    if (!currentFeedItemId) return undefined;

    const item = currentMainTabItems.find(({ id }) => id === currentFeedItemId);
    return item;
  }, [currentFeedItemId, currentMainTabItems]);

  const dropdownOptions = [
    {
      title: "Bookmark",
      value: dropdownOptionsValues.BOOKMARK,
      icon: BookmarkFill,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) =>
        addMetadataAction(feedItemId, feedMetadataActions.BOOKMARK),
    },
    {
      title: "Unbookmark",
      value: dropdownOptionsValues.UNBOOKMARK,
      icon: BookmarkOff,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) =>
        removeMetadataAction(feedItemId, feedMetadataActions.BOOKMARK),
    },
    {
      title: "Pin",
      value: dropdownOptionsValues.PIN,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      icon: PinAngleFill,
      onOptionClick: (feedItemId) =>
        addMetadataAction(feedItemId, feedMetadataActions.PIN),
    },
    {
      title: "UnPin",
      value: dropdownOptionsValues.UNPIN,
      icon: PinOff,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) =>
        removeMetadataAction(feedItemId, feedMetadataActions.PIN),
    },
    {
      title: "Hide",
      value: dropdownOptionsValues.HIDE,
      icon: Hide,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) =>
        addMetadataAction(feedItemId, feedMetadataActions.HIDE),
    },
    {
      title: "Restore",
      value: dropdownOptionsValues.RESTORE,
      icon: EyeFill,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) =>
        removeMetadataAction(feedItemId, feedMetadataActions.HIDE),
    },
    {
      title: "Edit",
      value: dropdownOptionsValues.EDIT,
      icon: Edit,
      availableFor: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
      onOptionClick: (feedItemId) => {
        setCurrentFeedItemId(feedItemId);
        editFeedItemModalData.openModal();
      },
    },
    {
      title: "Download attachment",
      value: dropdownOptionsValues.DOWNLOAD_ATTACHMENT,
      icon: Download,
      availableFor: [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
        UserRole.STUDENT,
        UserRole.PARENT,
      ],
      onOptionClick: (feedItemId) => {
        downloadFeedAttachment(feedItemId);
      },
    },
    {
      title: "Delete",
      value: dropdownOptionsValues.DELETE,
      icon: Delete,
      availableFor: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
      onOptionClick: (feedItemId) => deleteFeedItem(feedItemId),
    },
  ];

  //

  const contextValues = React.useMemo(
    () => ({
      currentMainTab,
      setCurrentMainTab,
      currentSecondaryTab,
      setCurrentSecondaryTab,
      displayedFeedItems,
      loadingFeedItems,
      currentFeedItem,
      setCurrentFeedItemId,
      viewFeedItemModalData,
      editFeedItemModalData,
      createWorkUpdateModalData,
      dropdownOptions,
      loadingAction,
      userFeedMetadata,
      getFeedItemMetaInfo,
      feedUsers,
      onEditFeedItemSuccess,
      newFeedItems,
      refreshData,
    }),
    [
      currentMainTab,
      setCurrentMainTab,
      currentSecondaryTab,
      setCurrentSecondaryTab,
      displayedFeedItems,
      loadingFeedItems,
      currentFeedItem,
      setCurrentFeedItemId,
      viewFeedItemModalData,
      editFeedItemModalData,
      createWorkUpdateModalData,
      dropdownOptions,
      loadingAction,
      userFeedMetadata,
      getFeedItemMetaInfo,
      feedUsers,
      onEditFeedItemSuccess,
      newFeedItems,
      refreshData,
    ]
  );

  return (
    <FeedSectionContext.Provider value={contextValues} {...props}>
      {children}
    </FeedSectionContext.Provider>
  );
};

export function useFeedSectionContext() {
  const context = React.useContext(FeedSectionContext);

  return context;
}

export default injectUserStore(FeedSectionContextProvider);
