import styled from "styled-components";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";

export const QrContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  margin: 10px auto;
`;
export const SubmitButton = styled.button`
  transition: all 0.4s;
  color: #fff;
  background-color: #681e46;
  border: 3px solid #681e46;
  border-radius: 30px;
  padding: 10px;
  font-weight: 700;
  & span {
    color: #fff;
  }
  &:hover {
    background-color: #fff;
    border: 3px solid #681e46;
  }
  &:hover span,
  &:hover svg {
    color: #681e46;
  }
`;
export const RightIcon = styled(CaretRightFill)`
  color: #fff;
  width: 20px;
  position: absolute;
  top: 10%;
  right: 15px;
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fbf8f9";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
