import "./style.css";
import NavMenu from "../common/NavMenu";
import BookClassToday from "../common/BookClassToday";
import Footer from "../common/Footer";
import NewsContainer from "./NewContainer";
import React, { useState } from "react";

function ArtisNews() {
  return (
    <main className="artis-news">
      <NavMenu />
      <NewsContainer />
      <BookClassToday />
      <Footer />
    </main>
  );
}

export default ArtisNews;
