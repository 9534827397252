import axios from "axios";
import { toast } from "react-toastify";
import {
  CAPTURE_PAYPAL_ORDER,
  CREATE_PAYPAL_ORDER,
  CREATE_STORAGE_FILE_COPY,
  GENERATE_NEW_USER_ID,
  GET_PAYPAL_ORDER_DETAILS,
  GET_PAYPAL_SUBSCRIPTION_DETAILS,
} from "src/constants/ApiEndpoints";

export const createStorageFileCopy = async (sourcePath, targetPath) => {
  if (!sourcePath || !targetPath) return;

  const body = {
    sourceFilePath: sourcePath,
    targetFilePath: targetPath,
  };
  const { data } = await axios.post(CREATE_STORAGE_FILE_COPY, body);

  if (!data?.success) {
    return;
  }

  return { success: data.success };
};

export const generateUserSystemID = async (userRole, locationId) => {
  if (!userRole || !locationId) {
    toast.error("Couldnt generate user id (Missing location or role)");
    return;
  }
  const { data } = await axios.post(GENERATE_NEW_USER_ID, {
    userRole,
    locationId,
  });

  const newId = data?.nextUserFullID;

  return newId;
};

export const createPaypalOrder = async (purchaseUnits) => {
  if (!purchaseUnits?.length) {
    toast.error("no purchase units provided");
    return;
  }

  const { data } = await axios.post(CREATE_PAYPAL_ORDER, {
    intent: "CAPTURE",
    purchase_units: purchaseUnits,
  });

  return data;
};

export const capturePaypalGroupClassOrder = async (orderID, groupClassData) => {
  const {
    createdBy,
    groupClassId,
    studentId,
    studentType,
    enrollmentType,
    enrollmentDate,
    paymentOptionId,
  } = groupClassData || {};
  if (
    !orderID ||
    !createdBy ||
    !groupClassId ||
    !studentId ||
    !studentType ||
    !enrollmentType ||
    !enrollmentDate ||
    !paymentOptionId
  ) {
    toast.error("missing values");
    return;
  }

  const { data } = await axios.post(CAPTURE_PAYPAL_ORDER, {
    orderID,
    groupClassData,
  });

  return data;
};

export async function getPaypalOrderDetails(orderID) {
  if (!orderID) {
    toast.error("no orderID provided");
    return;
  }

  const { data } = await axios.post(GET_PAYPAL_ORDER_DETAILS, {
    order_id: orderID,
  });

  return data;
}
export async function getPaypalSubscriptionDetails(subscriptionID) {
  if (!subscriptionID) {
    toast.error("no subscriptionID provided");
    return;
  }

  const { data } = await axios.post(GET_PAYPAL_SUBSCRIPTION_DETAILS, {
    subscription_id: subscriptionID,
  });

  return data;
}
