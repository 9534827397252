import React, { useContext } from "react";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import { LessonNavBtn, NavButtonsContainer } from "../styled";
import {
  checkIfCanceledRecurringActivity,
  updatedMomentNow,
} from "src/utils/helpers";

const LessonsNavSection = ({ targetLessons, index, handleIndex }) => {
  const activeLessons = targetLessons?.filter(
    (lsn) =>
      !checkIfCanceledRecurringActivity({
        occuranceDate: updatedMomentNow(),
        canceledAt: lsn.withdrawal?.withdrawalDate,
      })
  );

  const withdrawnLessons = targetLessons?.filter((lsn) =>
    checkIfCanceledRecurringActivity({
      occuranceDate: updatedMomentNow(),
      canceledAt: lsn.withdrawal?.withdrawalDate,
    })
  );

  const { instruments } = useStudentInfoContext();
  return (
    <>
      <div className="d-flex justify-content-between" style={{ gap: 15 }}>
        <NavButtonsContainer>
          {activeLessons?.map((less, i) => {
            const instrumentName = instruments?.find(
              ({ id }) => id === less.instrumentId
            )?.name;
            const isPackageLsn = isPackagePrivateLesson(less.type);

            const displayName = isPackageLsn
              ? `${instrumentName} | PACKAGE`
              : instrumentName;

            const lessonIndex = targetLessons.findIndex(
              (lsn) => lsn.id === less.id
            );
            return (
              <LessonNavBtn
                // style={isCanceled ? { marginLeft: "auto" } : {}}
                className={`${lessonIndex === index ? "active" : "unactive"}`}
                onClick={(e) => handleIndex(e)}
                key={less.id}
                id={less.id}
              >
                {displayName}
              </LessonNavBtn>
            );
          })}
        </NavButtonsContainer>
        <NavButtonsContainer justifyRight>
          {withdrawnLessons?.map((less, i) => {
            const instrumentName = instruments?.find(
              ({ id }) => id === less.instrumentId
            )?.name;
            const isPackageLsn = isPackagePrivateLesson(less.type);

            const displayName = isPackageLsn
              ? `${instrumentName} | PACKAGE`
              : instrumentName;

            const lessonIndex = targetLessons.findIndex(
              (lsn) => lsn.id === less.id
            );
            return (
              <LessonNavBtn
                isWithdrawn
                className={`${lessonIndex === index ? "active" : "unactive"}`}
                onClick={(e) => handleIndex(e)}
                key={less.id}
                id={less.id}
              >
                {displayName}
              </LessonNavBtn>
            );
          })}
        </NavButtonsContainer>
      </div>
    </>
  );
};

export default LessonsNavSection;
