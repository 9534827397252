import React from "react";
import AdminLibrary from "./AdminLibrary";
import RouteContent from "../../../components/common/RouteContent";
const AdminLibraryRoute = ({ user }) => {
  return (
    <RouteContent title={"Admin Library"}>
      <AdminLibrary user={user} />
    </RouteContent>
  );
};
export default AdminLibraryRoute;
