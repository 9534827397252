import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router";
import { Container, PageLogo } from "../shared/StyledComponents";
import ForgotPassword from "./ForgotPassword";
import SignInComponent from "./SignInComponent";
import { signInViews } from "../constants";
import MobileAuthNav from "../MobileAuthNav";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

const SignIn = () => {
  const Navigate = useNavigate();

  // sign-in/forgot-password
  const [currentView, setCurrentView] = useState(signInViews.SIGN_IN);
  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;

  return (
    <Container>
      <div className="text-center">
        <PageLogo
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      {!isDesktopScreen && <MobileAuthNav />}
      {currentView === signInViews.SIGN_IN ? (
        <SignInComponent handleViewChange={handleViewChange} />
      ) : (
        <ForgotPassword handleViewChange={handleViewChange} />
      )}
    </Container>
  );
};

export default SignIn;
