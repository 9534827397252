import { updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { weekDays } from "src/constants/weekDays";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";
import {
  ButtonContainer,
  CustomInput,
  CustomLabel,
  CustomTextarea,
  EllipseIcon,
  FormContainer,
  InputWrapper,
} from "../../styled";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import { useMemo } from "react";
import {
  checkIfCanceledRecurringActivity,
  checkIfSummerBreakReturnIsScheduled,
  getLessonWithCurrentTimeline,
  isDuringSummerBreak,
  updatedMomentNow,
} from "src/utils/helpers";
import WithdrawalModal from "../../../modals/WithdrawalModal";
import LessonChangesModal from "../../../modals/LessonChangesModal";
import LessonHistoryModal from "../../../modals/LessonHistoryModal";
import useModal from "src/hooks/useModal";
import AbsenceRequestModal from "../../../../AbsenceRequestModal";
import ReturnFromSummerBreakModal from "../../../../ReturnFromSummerBreakModal";
import { getTimeDiffInMins } from "src/utils/helpers";
import DeleteLessonModal from "../../../modals/DeleteLessonModal";

const LessonInfoForm = ({ currentLesson, makeUpData }) => {
  const currentLessonTimelineList = currentLesson?.timeline || [];

  const firebase = useContext(FirebaseContext);
  const { teachers, instruments, programs, locations } =
    useStudentInfoContext();

  const absenceRequestModalData = useModal();
  const withdrawalModalData = useModal();
  const lessonChangesModalData = useModal();
  const lessonHistoryModalData = useModal();
  const summerBreakEndModalData = useModal();
  const deleteLessonModalData = useModal();

  const isWithdrawn = checkIfCanceledRecurringActivity({
    occuranceDate: updatedMomentNow(),
    canceledAt: currentLesson?.withdrawal?.withdrawalDate,
  });
  const isWithdrawingInFuture =
    currentLesson?.withdrawal?.withdrawalDate && !isWithdrawn;
  const formattedLastLessonDate = currentLesson?.withdrawal?.withdrawalDate
    ? moment(currentLesson?.withdrawal?.withdrawalDate)
        .subtract(1, "week")
        .format("MM-DD-YYYY")
    : "";

  const lessonWithCurrentTimeline = useMemo(() => {
    if (!currentLesson) {
      return;
    }

    return getLessonWithCurrentTimeline({
      lesson: currentLesson,
      withApproximation: true,
    });
  }, [currentLesson]);
  const currentLessonSummerBreaksList =
    lessonWithCurrentTimeline?.summerBreaks || [];

  const combinedLessonHistory = [
    ...currentLessonSummerBreaksList,
    ...currentLessonTimelineList,
  ].sort((a, b) => {
    const firstDate = a.startDate || a.breakStart;
    const secondDate = b.startDate || b.breakStart;

    return getTimeDiffInMins(secondDate, firstDate);
  });
  const currentHistoryObj = combinedLessonHistory.find(
    ({ startDate, lastDate, breakStart, breakEnd }) => {
      let found = false;
      if (startDate) {
        found = updatedMomentNow().isBetween(
          startDate,
          lastDate || moment().add(50, "years")
        );
      } else if (breakStart) {
        found = updatedMomentNow().isBetween(
          breakStart,
          breakEnd || moment().add(50, "years")
        );
      }
      return found;
    }
  );
  const currentHistoryObjIndex = combinedLessonHistory.findIndex(
    ({ id }) => id === currentHistoryObj?.id
  );
  const showNewEllipse =
    combinedLessonHistory.length - 1 !== currentHistoryObjIndex;

  const teacherId = lessonWithCurrentTimeline?.currentTimeline?.teacherId;
  const teacher = teachers.find(({ id }) => id === teacherId);
  const locationId = lessonWithCurrentTimeline?.currentTimeline?.locationId;

  const teacherProgram = programs.find(
    ({ id }) =>
      id ===
      teacher?.instrumentsInfo?.find(
        (info) => info.instrument === lessonWithCurrentTimeline.instrumentId
      )?.program
  );

  const lessonInstrumentName = instruments.find(
    ({ id }) => id === lessonWithCurrentTimeline?.instrumentId
  )?.name;

  const lessonStartDate = lessonWithCurrentTimeline?.currentTimeline?.startDate;
  const lessonWeekDay = moment(lessonStartDate).day();
  const lessonLength = parseInt(
    lessonWithCurrentTimeline?.currentTimeline?.duration
  );
  const lessonStartTime = moment(lessonStartDate).format("HH:mm");

  const lessonType = lessonWithCurrentTimeline?.currentTimeline?.isVirtual
    ? "Virtual"
    : "In Person";

  const currentSummerBreak = lessonWithCurrentTimeline?.summerBreaks?.find(
    (summerBreak) => isDuringSummerBreak(updatedMomentNow(), summerBreak)
  );
  const isInSummerBreak = !!currentSummerBreak;
  const isInSummerBreakAndReturnScheduled =
    isInSummerBreak &&
    checkIfSummerBreakReturnIsScheduled(
      currentSummerBreak,
      currentLesson.timeline
    );

  const [lessonNote, setLessonNote] = useState("");
  const [loadingSavingNote, setLoadingSavingNote] = useState("");

  useEffect(() => {
    const note = lessonWithCurrentTimeline?.note;
    if (note) {
      setLessonNote(note);
    } else {
      setLessonNote("");
    }
  }, [lessonWithCurrentTimeline?.note]);

  const saveLessonNote = async () => {
    try {
      setLoadingSavingNote(true);
      await updateDoc(firebase.PrivateLesson(lessonWithCurrentTimeline?.id), {
        note: lessonNote,
      });

      toast.success("Note saved successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSavingNote(false);
    }
  };

  return (
    <div>
      {isInSummerBreak && (
        <div>
          <h4
            style={{
              backgroundColor: "#efedcf",
              color: "#681e46",
              textAlign: "center",
            }}
          >
            {isInSummerBreakAndReturnScheduled
              ? "In Summer Break - Returning date scheduled"
              : "In Summer Break"}
          </h4>
        </div>
      )}
      {isWithdrawingInFuture && (
        <div>
          <h4
            style={{
              backgroundColor: "rgb(218,217,215)",
              color: "#681e46",
              textAlign: "center",
            }}
          >
            {`Withdrawing (Last Lesson Date: ${formattedLastLessonDate})`}
          </h4>
        </div>
      )}
      {isWithdrawn && (
        <div>
          <h4
            style={{
              backgroundColor: "rgb(218,217,215)",
              color: "#681e46",
              textAlign: "center",
            }}
          >
            Disabled
          </h4>
        </div>
      )}
      <FormContainer>
        <InputWrapper>
          <CustomLabel>Instrument:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            type="instrumentId"
            className="form-control"
            value={lessonInstrumentName}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Program:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            type="text"
            className="form-control"
            value={teacherProgram?.name}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Teacher:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            name="teacherId"
            type="text"
            className="form-control"
            value={teacher?.fullName}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Class Day:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            name="classDay"
            type="text"
            className="form-control"
            value={weekDays.find(({ id }) => id === lessonWeekDay)?.name}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Lesson Length:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            name="lessonLength"
            type="text"
            className="form-control"
            value={`${lessonLength} Minutes`}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Lesson Time:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            type="time"
            className="form-control"
            name="lessonTime"
            value={lessonStartTime}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Location:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            type="text"
            className="form-control"
            name="locationId"
            value={locations?.find(({ id }) => id === locationId)?.name}
          />
        </InputWrapper>
        <InputWrapper>
          <CustomLabel>Type:</CustomLabel>
          <CustomInput
            disabled
            width="200px"
            type="text"
            className="form-control"
            name="locationId"
            value={lessonType}
          />
        </InputWrapper>
        <InputWrapper className="mt-4">
          <div className="mb-3 d-flex align-items-center">
            <CustomLabel className="mb-0">Note (For Admins):</CustomLabel>
            <div className="ms-4 d-flex">
              <ButtonContainer>
                <PrimaryButtonWithLoading
                  backgroundColor="#e357a2"
                  isLoading={loadingSavingNote}
                  onClick={saveLessonNote}
                  width="145px"
                  className="me-2"
                  padding="6px"
                >
                  Save Note
                </PrimaryButtonWithLoading>
              </ButtonContainer>
              <ButtonContainer>
                <PrimaryButton
                  backgroundColor="#e357a2"
                  onClick={lessonHistoryModalData.openModal}
                  width="145px"
                  padding="6px"
                >
                  Lesson History
                </PrimaryButton>
                {showNewEllipse && <EllipseIcon>New</EllipseIcon>}
              </ButtonContainer>
            </div>
          </div>
          <CustomTextarea
            width="650px"
            height="130px"
            className="form-control"
            name="note"
            value={lessonNote}
            onChange={(e) => setLessonNote(e.target.value)}
          />
        </InputWrapper>
        {isWithdrawn || isInSummerBreak ? (
          <div className="mt-4 m-auto d-flex w-100 justify-content-center">
            <PrimaryButton
              style={{ width: "60%" }}
              onClick={deleteLessonModalData.openModal}
            >
              Delete All
            </PrimaryButton>
          </div>
        ) : null}
        {isInSummerBreak && !isInSummerBreakAndReturnScheduled ? (
          <div className="mt-2 m-auto d-flex w-100 justify-content-center">
            <PrimaryButton
              style={{ width: "60%" }}
              onClick={summerBreakEndModalData.openModal}
            >
              Return From Summer Break
            </PrimaryButton>
          </div>
        ) : null}
        {!isInSummerBreak && !isWithdrawn ? (
          <div className="mt-4 m-auto d-flex w-100 justify-content-evenly">
            <div>
              <PrimaryButton onClick={lessonChangesModalData.openModal}>
                Lesson Changes
              </PrimaryButton>
            </div>
            <div>
              <PrimaryButton onClick={absenceRequestModalData.openModal}>
                Absence Request
              </PrimaryButton>
            </div>
            <div>
              <PrimaryButton onClick={withdrawalModalData.openModal}>
                Withdrawal
              </PrimaryButton>
            </div>
          </div>
        ) : null}

        {absenceRequestModalData.isModalOpen && (
          <AbsenceRequestModal
            absenceRequestModalData={absenceRequestModalData}
            privateLesson={lessonWithCurrentTimeline}
            onAbsenceRequestSuccess={makeUpData.refreshData}
          />
        )}
        {withdrawalModalData.isModalOpen && (
          <WithdrawalModal
            modalData={withdrawalModalData}
            lessonWithCurrentTimeline={lessonWithCurrentTimeline}
            onSuccess={() => {
              window.location.reload();
            }}
          />
        )}
        {lessonChangesModalData.isModalOpen && (
          <LessonChangesModal
            lessonChangesModalData={lessonChangesModalData}
            lessonWithCurrentTimeline={lessonWithCurrentTimeline}
          />
        )}
        {lessonHistoryModalData.isModalOpen && (
          <LessonHistoryModal
            lessonHistoryModalData={lessonHistoryModalData}
            regularPrivateLesson={lessonWithCurrentTimeline}
          />
        )}
        {summerBreakEndModalData.isModalOpen && currentSummerBreak && (
          <ReturnFromSummerBreakModal
            modalData={summerBreakEndModalData}
            privateLessonId={currentLesson.id}
            studentId={currentLesson.studentId}
            summerBreakId={currentSummerBreak.id}
          />
        )}
        {deleteLessonModalData.isModalOpen && (
          <DeleteLessonModal
            loading={makeUpData.loadingSaveData}
            modalData={deleteLessonModalData}
            onDelete={makeUpData.handlePermaDeletePrivateLesson}
          />
        )}
      </FormContainer>
    </div>
  );
};

export default LessonInfoForm;
