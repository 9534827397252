import React from "react";
import { NoteModalHeader } from "src/components/common/styled-components/lessonNotesStyledComponents";

const ModalHeader = ({ closeModal, imageUrl }) => {
  return (
    <NoteModalHeader className="position-relative">
      <div className="position-absolute close-btn-container bg-white rounded-circle">
        <button
          onClick={() => {
            closeModal();
          }}
          className={"close border-0 outline-0"}
        >
          <i className="fa fa-times" />
        </button>
      </div>
      <div className="curve-container position-absolute top-0 left-0 w-100">
        <svg
          width="100%"
          height="100%"
          id="svg"
          viewBox="0 0 750 150"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          className="transition duration-300 ease-in-out delay-150"
        >
          <path fill="#681E46" d="M0,150V0h750v150C750,150,375,70,0,150z" />
        </svg>
      </div>
      <div className="position-relative user-details d-flex justify-content-center flex-column align-items-center">
        <div className="avatar overflow-hidden d-flex align-items-center justify-content-center bg-light rounded-circle border border-4 border-white">
          {imageUrl ? <img src={imageUrl} alt="user image" /> : null}
        </div>
      </div>
    </NoteModalHeader>
  );
};

export default ModalHeader;
