import styled from "styled-components";

export const Container = styled.div`
  width: 280px;
  /* height: 230px; */
  border-radius: 15px;
  background-color: #ff9ed2;
  color: #fff;
  text-align: start;
  padding: 20px 15px 0 15px;
  position: absolute;
  z-index: 2000;
  left: -70px;
  top: 25px;
  font-size: 0.9rem;
`;
