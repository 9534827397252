import React from "react";
import useGroupClassPayment from "./hooks/useGroupClassPayment";
import SelectPaymentMethod from "./SelectPaymentMethod";
import PaymentButtonsSection from "./PaymentButtonsSection";
import { injectUserStore } from "src/utils/helpers";
import EnrollNowPayLater from "./EnrollNowPayLater";
import { enrollmentOptions } from "../../constants";
import FreePaymentSection from "./FreePaymentSection";

const PaymentSection = ({
  studentId,
  studentType,
  groupClassId,
  UserStore,
}) => {
  const user = UserStore?.user;

  const hookData = useGroupClassPayment({
    studentId,
    studentType,
    groupClassId,
    user,
  });
  const {
    loadingInitialData,
    selectedPaymentOption,
    setSelectedPaymentOption,
    isBothPaymentsOptionsAvailable,
    isDirectPaymentAvailable,
    isScheduledPaymentAvailable,
    isFreePaymentAvailable,
    initialData,
    enrollStudent,
    isEnrollNowPayLaterEnrollmentOption,
    subscriptionSuccessCallback,
    isPayNowEnrollmentOption,
    selectedEnrollmentOption,
    setSelectedEnrollmentOption,
    isEnrollNowPayLaterAvailable,
    isEnrollNowPayLaterConfirmed,
    setIsEnrollNowPayLaterConfirmed,
    isSaving,
  } = hookData;

  if (!studentId) {
    return <div></div>;
  }
  if (loadingInitialData) {
    return <div></div>;
  }
  return (
    <div style={{ height: "85%" }}>
      {isEnrollNowPayLaterAvailable && !isFreePaymentAvailable && (
        <div className="mt-3 mb-4">
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              checked={isPayNowEnrollmentOption}
              onChange={(e) =>
                setSelectedEnrollmentOption(enrollmentOptions.payNow.value)
              }
            />
            Pay Now
          </label>
          <label>
            <input
              type="radio"
              className="me-2"
              checked={isEnrollNowPayLaterEnrollmentOption}
              onChange={(e) =>
                setSelectedEnrollmentOption(
                  enrollmentOptions.enroll_now_pay_later.value
                )
              }
            />
            Enroll Now Pay Later
          </label>
        </div>
      )}
      {isFreePaymentAvailable ? (
        <FreePaymentSection enrollStudent={enrollStudent} isSaving={isSaving} />
      ) : isEnrollNowPayLaterEnrollmentOption ? (
        <EnrollNowPayLater
          isEnrollNowPayLaterConfirmed={isEnrollNowPayLaterConfirmed}
          setIsEnrollNowPayLaterConfirmed={setIsEnrollNowPayLaterConfirmed}
          enrollStudent={enrollStudent}
          isSaving={isSaving}
        />
      ) : (
        <>
          {isBothPaymentsOptionsAvailable && (
            <SelectPaymentMethod
              selectedPaymentOption={selectedPaymentOption}
              setSelectedPaymentOption={setSelectedPaymentOption}
              isDirectPaymentAvailable={isDirectPaymentAvailable}
              isScheduledPaymentAvailable={isScheduledPaymentAvailable}
            />
          )}
          <PaymentButtonsSection
            directPaymentOption={initialData.directPaymentOption}
            scheduledPaymentOption={initialData.scheduledPaymentOption}
            selectedPaymentOption={selectedPaymentOption}
            subscriptionSuccessCallback={subscriptionSuccessCallback}
            studentId={studentId}
            studentType={studentType}
            groupClassId={groupClassId}
          />
        </>
      )}
    </div>
  );
};

export default injectUserStore(PaymentSection);
