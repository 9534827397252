import { Button, Tooltip } from "antd";
import defaultProfile from "src/assets/images/default-profile.jpg";
import { ProgramTag, TeacherImage } from "./styled";
import { programDescriptionsEnum } from "../../../utils";

const teachersTableColumns = ({ onNameClick }) => {
  return [
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (_, record) => (
        <TeacherImage
          src={record?.imageUrl || defaultProfile}
          alt="teacher"
          onClick={() => onNameClick(record?.key)}
        />
      ),
      responsive: ["md"],
      width: 120,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button type="link" onClick={() => onNameClick(record?.key)}>
          {text}
        </Button>
      ),
      ellipsis: true,
    },
    {
      title: "Instruments",
      dataIndex: "instrument",
      key: "instrument",
      render: (instruments) => instruments?.join(", "),
    },
    {
      title: "Programs",
      dataIndex: "program",
      key: "instrument",
      render: (programs) =>
        programs?.length ? (
          programs?.map((program, index) => (
            <Tooltip
              color="#681e46DD"
              overlayInnerStyle={{
                fontSize: 16,
                padding: 12,
                textAlign: "center",
              }}
              title={programDescriptionsEnum[program]}
              trigger="click"
              key={index}
            >
              <ProgramTag color="#681e46CC">{program}</ProgramTag>
            </Tooltip>
          ))
        ) : (
          <span>N/A</span>
        ),
      responsive: ["sm"],
    },
  ];
};

export default teachersTableColumns;
