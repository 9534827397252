import React, { useContext, useState } from "react";
import {
  InfoText,
  InfoSecondaryText,
  PrimaryH3,
  UserProfile,
} from "../../../../utils/styled";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { UserRole } from "../../../../constants/UserRoleEnum";

import { FirebaseContext } from "../../../Firebase";
import { Row, Col } from "reactstrap";
import StudentNavSelect from "../../../Dashboard/ParentDashboard/StudentNavSelect";
import NotificationsMenu from "../../NotificationsMenu";
import {
  Alerticon,
  Alertstyle,
  Ellipseicon,
  IconParent,
  IconsContainer,
  LeftSideCont,
  Mailicon,
  Mailstyle,
  MutedText,
  RightSideCont,
  Settingicon,
  settingstyle,
  UserRolestyle,
} from "./styled";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import SettingsMenu from "../../SettingsMenu";
import { mainPagePadding } from "src/constants/styles";
import { getCurrentMomentTimezoneValue } from "src/utils/helpers";

const DesktopRouteHeader = ({ title, UserStore }) => {
  const user = UserStore.user;
  const UserBar = () => {
    const name = user.fullName;

    const NotificationsDropdownContainer = ({ children }) => (
      <div className="ms-2 me-2" style={Alertstyle}>
        {children}
      </div>
    );

    const SettingsDropdownContainer = ({ children }) => (
      <div className="ms-2 me-2" style={settingstyle}>
        {children}
      </div>
    );

    const contactUsEmail = process.env.REACT_APP_CONTACT_US_EMAIL;

    const { longName: zoneName } = getCurrentMomentTimezoneValue();
    return (
      <Row className="justify-content-end align-items-center flex-nowrap">
        <Col sm={"auto"}>
          <LeftSideCont>
            {user.role === UserRole.PARENT ? <StudentNavSelect /> : null}
          </LeftSideCont>
        </Col>
        <Col sm={"auto"}>
          <RightSideCont>
            <MutedText>{zoneName || "Unknown Timezone"}</MutedText>
            <IconsContainer>
              <NotificationsMenu
                dropdownToggleContainer={NotificationsDropdownContainer}
                dropdownToggleIcon={Alerticon}
                ellipseIcon={Ellipseicon}
              />
              <div className="ms-2 me-2" style={Mailstyle}>
                <a href={`mailto:${contactUsEmail}`}>
                  <Mailicon />
                </a>
              </div>
            </IconsContainer>
            <div style={UserRolestyle}>
              <InfoText className={"my-0"}>{name}</InfoText>
              <InfoSecondaryText className={"my-1"} weight={300}>
                {user.role}
              </InfoSecondaryText>
            </div>
            <UserProfile
              src={user.imageUrl || UnknownProfilePhoto}
              className="rounded-circle"
            />

            <SettingsMenu
              dropdownToggleContainer={SettingsDropdownContainer}
              dropdownToggleIcon={Settingicon}
            />
          </RightSideCont>
        </Col>
      </Row>
    );
  };
  return (
    <div style={{ padding: mainPagePadding }}>
      <Row className="align-items-center">
        <Col className="order-sm-2 order-xxl-0" sm={12} xxl={4}>
          <PrimaryH3>{title}</PrimaryH3>
        </Col>
        <Col sm={12} xxl={8}>
          <UserBar />
        </Col>
      </Row>
    </div>
  );
};
export default compose(inject("UserStore"), observer)(DesktopRouteHeader);
