import { useState } from "react";
import { useApplications } from "../../hooks";

import { Checkbox, Form, Input } from "antd";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ModalBody } from "reactstrap";
import { ActionSubmitButton } from "../../styled";

const DeclineModal = ({ isOpen, toggle, applicant, onConfirm }) => {
  const [reasonValue, setReasonValue] = useState(null);
  const [moveToArchiveChecked, setMoveToArchiveChecked] = useState(false);
  const { declineApplication } = useApplications();

  const handleFinish = () => {
    declineApplication(applicant, reasonValue);
    onConfirm(moveToArchiveChecked, "Declined");
    setReasonValue(null);
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Decline application</StyledModalHeader>
      <ModalBody>
        <Form layout="vertical" onFinish={handleFinish}>
          <Form.Item label="Message (optional, will replace default template message)">
            <Input.TextArea
              name="message"
              placeholder="Message"
              onChange={({ target }) => setReasonValue(target?.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={({ target }) =>
                setMoveToArchiveChecked(target?.checked)
              }
            >
              Move application to archive
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <ActionSubmitButton type="primary" htmlType="submit" size="large">
              Decline
            </ActionSubmitButton>
          </Form.Item>
        </Form>
      </ModalBody>
    </StyledModal>
  );
};

export default DeclineModal;
