import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import { Input } from "reactstrap";

const StyledCustomInput = styled(Input)`
  margin-bottom: 16px;
  margin-left: 16px;
`;

const checkbox = ({ label, value, name, ...rest }) => (
  <Field name={name} {...rest}>
    {({ field, form }) => (
      <StyledCustomInput
        {...rest}
        type="checkbox"
        className="col-sm-12"
        label={label}
        defaultChecked={field.value.includes(value)}
        onChange={() => {
          if (field.value.includes(value)) {
            const nextValue = field.value.filter((v) => v !== value);
            form.setFieldValue(name, nextValue);
          } else {
            const nextValue = field.value.concat(value);
            form.setFieldValue(name, nextValue);
          }
        }}
      />
    )}
  </Field>
);

export default checkbox;
