import React from "react";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomCheckboxContainer } from "src/utils/shared/styled";

const EnrollNowPayLater = ({
  isEnrollNowPayLaterConfirmed,
  setIsEnrollNowPayLaterConfirmed,
  enrollStudent,
  isSaving,
}) => {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-end"
    >
      <div>
        <CustomCheckboxContainer
          className={"mt-5 d-flex align-items-center justify-content-center"}
        >
          <input
            checked={isEnrollNowPayLaterConfirmed}
            onChange={(e) => setIsEnrollNowPayLaterConfirmed(e.target.checked)}
            type="checkbox"
            id="checkboxOption"
            name="checkboxOption"
          />
          <label className="ms-2" for="checkboxOption">
            Make sure the student has reviewed and signed the policy
          </label>
        </CustomCheckboxContainer>
        <div className="mt-3 text-center" style={{ width: "80%" }}>
          <PrimaryButtonWithLoading
            width="100%"
            onClick={enrollStudent}
            isLoading={isSaving}
          >
            Enroll And Send Email
          </PrimaryButtonWithLoading>
        </div>
      </div>
    </div>
  );
};

export default EnrollNowPayLater;
