import { useEffect, useState } from "react";
import useGroupClass from "../hooks/useGroupClass";
import { isStudent } from "../../../../constants/UserRoleEnum";
import { groupClassesListViews } from "../constants";
import { groupClassAvailableForMap } from "../../../../constants/groupClassConstants";
import store from "../../../../stores/UserStore";
import IntegrationInfoCard from "../GroupClassesListSection/IntegrationInfoCard";

const IntegrationClassesList = () => {
  const { displayedGroupClasses, initialData } = useGroupClass();
  const { active } = groupClassesListViews;

  const { user } = store;
  const colorsPalette = ["#bdb5dd", "#ddddb5", "#ddb5b5", "#ddb5db", "#f0b1ac"];
  const [totalGroupClasses, setTotalGroupClasses] = useState([]);

  useEffect(() => {
    if (!isStudent(user?.role)) {
      setTotalGroupClasses(
        displayedGroupClasses?.filter(
          (groupClass) =>
            groupClass.availableFor !==
            groupClassAvailableForMap.CURRENT_STUDENTS
        )
      );
    } else {
      setTotalGroupClasses(displayedGroupClasses);
    }
  }, [displayedGroupClasses]);

  return (
    <div>
      {totalGroupClasses
        .filter((groupClass) => groupClass.status === active.value)
        .map((groupClass) => {
          const { id } = groupClass;
          const currentColor = colorsPalette[0];
          colorsPalette.shift();
          colorsPalette.push(currentColor);

          return (
            <IntegrationInfoCard
              headerColor={currentColor}
              key={id}
              groupClass={groupClass}
              teachers={initialData?.teachers}
            />
          );
        })}
    </div>
  );
};

export default IntegrationClassesList;
