import React from "react";
import { viewComponents } from "../../constants";
import { NavButton } from "src/utils/shared/styled";

const NavSection = ({ currentView, setCurrentView }) => {
  return (
    <div>
      <div className="d-flex align-items-center">
        {Object.values(viewComponents).map((view, index) => {
          const { value, name } = view;
          return (
            <div key={index}>
              <NavButton
                className={currentView === value ? "active" : undefined}
                onClick={() => setCurrentView(value)}
                withRedBar
              >
                {name}
              </NavButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavSection;
