import React from "react";
import DisableUserModal from "src/components/Dashboard/common/components/DisableUserModal";
import useModal from "src/hooks/useModal";
import DropdownButton from "src/components/Dashboard/common/components/DropdownButton";
import useStudentActions from "./hooks/useStudentActions";
import PermaDeleteStudentModal from "./PermaDeleteStudentModal";

const StudentActions = ({ student, refreshData, closeStudentInfoModal }) => {
  const { toggleStudentLoginStatus, isLoginDisabledUser, loadingAction } =
    useStudentActions({
      studentId: student?.id,
      onSuccess: () => {
        closeStudentInfoModal();
        refreshData();
      },
    });

  const deleteUserModalData = useModal();

  const optionsList = [
    isLoginDisabledUser
      ? {
          title: "Turn on Platform Access",
          action: () => toggleStudentLoginStatus(true),
        }
      : {
          title: "Turn off Platform Access",
          action: () => toggleStudentLoginStatus(false),
        },
    {
      title: "Delete User",
      action: deleteUserModalData.openModal,
    },
  ];

  return (
    <div className="text-end">
      <DropdownButton loadingAction={loadingAction} optionsList={optionsList} />

      {/* MODALS */}
      {deleteUserModalData.isModalOpen && student.id && (
        <PermaDeleteStudentModal
          modalData={deleteUserModalData}
          studentId={student.id}
          onSuccess={() => {
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default StudentActions;
