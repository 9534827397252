import React, { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { updatedMomentNow } from "src/utils/helpers";

const useDisableUser = ({ userId, authUserId, onSuccess }) => {
  const { disableTeacher } = useFirebaseFns();

  const [deletionDate, setDeletionDate] = useState(updatedMomentNow());

  const [loadingAction, setLoadingAction] = useState(false);

  async function disableUser() {
    if (!userId || !authUserId || !deletionDate) return;
    try {
      setLoadingAction(true);

      const parsedDate = deletionDate
        .set({
          hours: updatedMomentNow().hours(),
          minutes: updatedMomentNow().minutes(),
          seconds: 0,
          millisecond: 0,
        })
        .toDate();

      const disableObj = {
        disabledBy: authUserId,
        disabledAt: parsedDate,
      };
      await disableTeacher(userId, disableObj);
      toast.success("Deleted Successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  return { loadingAction, disableUser, deletionDate, setDeletionDate };
};

export default useDisableUser;
