import "./style.css";
import React from "react";
import NavMenu from "../common/NavMenu";
import BookClassToday from "../common/BookClassToday";
import Footer from "../common/Footer";
import Location from "./Location";
import Form from "./Form";
function ContactUs() {
  return (
    <div className="contact-us-container">
      <NavMenu />
      <main className="contact-us">
        <h2>Contact Us</h2>
        <Location />
        <Form />
      </main>
      <BookClassToday />
      <Footer />
    </div>
  );
}

export default ContactUs;
