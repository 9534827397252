import { addDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import React from "react";
import { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import {
  isDeclinedAbsence,
  isPendingAbsence,
} from "src/constants/absenceTypes";
import {
  parseAbsenceObjDates,
  parseUserActivityDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getTeacherActivities = async (teacherId) => {
    try {
      const q1 = query(
        firebase.usersActivities(),
        where("userId", "==", teacherId),
        where("isActive", "==", true)
      );
      const q2 = query(
        firebase.usersActivities(),
        where("usersIds", "array-contains", teacherId),
        where("isActive", "==", true)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [docs1, docs2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedActivities = [...docs1, ...docs2];

      const activitiesWithParsedDates = combinedActivities.map((activity) => {
        return parseUserActivityDates(activity);
      });
      return activitiesWithParsedDates;
    } catch (err) {
      console.log(err);
    }
  };
  const getTeacherAbsences = async (teacherId) => {
    try {
      if (!teacherId) return [];

      const q = query(firebase.absences(), where("teacherId", "==", teacherId));

      const absences = parseFirebaseDoc((await getDocs(q)).docs);

      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      const absencesWithDates = filteredAbsences.map((absence) =>
        parseAbsenceObjDates(absence)
      );
      return absencesWithDates;
    } catch (err) {
      console.log(err);
    }
  };

  const createTeacherBreak = async (breakObj) => {
    try {
      const res = await addDoc(firebase.breaks(), breakObj);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const createActivity = async (breakId, activityObj) => {
    try {
      if (!breakId || !activityObj) {
        throw { message: "Couldnt create the makeup user activity" };
      }
      const activityRes = await setDoc(
        firebase.userActivity(breakId),
        activityObj
      );
      return activityRes;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    getTeacherActivities,
    getTeacherAbsences,
    createTeacherBreak,
    createActivity,
  };
};

export default useFirebaseFns;
