import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import useEditSummerBreak from "../hooks/useEditSummerBreak";
import {
  CalendarPickerWrapper,
  CustomLabel,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { injectUserStore } from "src/utils/helpers";
import { DeleteIcon, InfoSpan, StyledP } from "../styled";

const EditBody = ({
  modalData,
  privateLessonId,
  onEditSuccess,
  summerBreakId,
  UserStore,
}) => {
  const user = UserStore.user;

  const {
    loadingAction,
    submitChanges,
    handleFormChange,
    formData,
    isBreakStartPickerOpen,
    setIsBreakStartPickerOpen,
    isBreakEndPickerOpen,
    setIsBreakEndPickerOpen,
    currentSchoolYearInLocation,
    currentLessonInfo,
  } = useEditSummerBreak({
    privateLessonId,
    summerBreakId,
    user,
    onEditSuccess,
  });

  const formattedBreakEndDate =
    formData.breakEnd && moment(formData.breakEnd).format("MM/DD/YYYY");

  const lessonStartDate = currentLessonInfo?.startDate;
  const summerSessionStart = currentSchoolYearInLocation?.dates
    ?.summer_session_start
    ? currentSchoolYearInLocation?.dates?.summer_session_start.toDate()
    : null;
  const summerSessionEnd = currentSchoolYearInLocation?.dates
    ?.summer_session_end
    ? currentSchoolYearInLocation?.dates?.summer_session_end.toDate()
    : null;

  return (
    <>
      <StyledBarContent title={"Edit Summer Break"} overFlowContent={"visible"}>
        <div className="p-4">
          <div className="mb-5">
            <CustomLabel>
              Break Start Date:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CalendarPickerWrapper>
              <SingleDatePicker
                date={formData.breakStart}
                onDateChange={(date) => {
                  handleFormChange("breakStart", date);
                }}
                focused={isBreakStartPickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsBreakStartPickerOpen(focused);
                }}
                isOutsideRange={(date) => {
                  //disables dates before the lesson start
                  if (moment(date).isBefore(lessonStartDate, "date"))
                    return true;

                  // disables dates that are not on the same weekday as the lsn
                  if (moment(date).day() !== moment(lessonStartDate).day())
                    return true;

                  // disable dates after and before the current schoolyear summer session
                  if (
                    summerSessionStart &&
                    summerSessionEnd &&
                    !moment(date).isBetween(
                      summerSessionStart,
                      summerSessionEnd,
                      "date",
                      "[]"
                    )
                  ) {
                    return true;
                  }
                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </div>
          <div className="mb-3">
            <CustomLabel>Return From Break Date:</CustomLabel>
            <StyledP>
              {formattedBreakEndDate || "On Going"}
              {formattedBreakEndDate ? (
                <DeleteIcon
                  onClick={() => handleFormChange("breakEnd", null)}
                />
              ) : null}
            </StyledP>
            <div className="mt-3">
              <InfoSpan>
                {formattedBreakEndDate
                  ? "Remove the Return from break date if the break is ongoing."
                  : "use the return from summer break button to stop the summer break."}
              </InfoSpan>
            </div>
          </div>
        </div>

        <div className="mt-5 mb-3 text-center">
          <PrimaryButtonWithLoading
            isLoading={loadingAction}
            onClick={submitChanges}
            width="50%"
          >
            Confirm Changes
          </PrimaryButtonWithLoading>
        </div>
      </StyledBarContent>
      <div className="mt-5"></div>
    </>
  );
};

export default injectUserStore(EditBody);
