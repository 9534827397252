import React, { useContext, useEffect } from "react";
import FormInfoSection from "./FormInfoSection";
import TeacherActions from "./TeacherActions";

const TeacherInfoBody = ({ teacher, modalData, refreshData }) => {
  return (
    <div>
      <div className="mb-4">
        <TeacherActions
          closeTeacherInfoModal={modalData.closeModal}
          teacher={teacher}
          refreshData={refreshData}
        />
      </div>
      <div>
        <FormInfoSection
          teacher={teacher}
          modalData={modalData}
          refreshData={refreshData}
        />
      </div>
    </div>
  );
};

export default TeacherInfoBody;
