import { useContext } from "react";
import { Button } from "reactstrap";
import { AttendanceContext } from "../../common/contexts/AdminAttendanceContext";
import { AttendanceBlockContainer } from "../../common/styled-components/attendanceStylesComponents";

const ExtraAttendanceBlock = ({ lessonId }) => {
  const { isReadOnly, addExtra } = useContext(AttendanceContext);
  return (
    <AttendanceBlockContainer className="d-flex flex-column align-items-center justify-content-end mb-2">
      <Button
        disabled={isReadOnly}
        className={"rounded-pill bg-purple"}
        color={"secondary"}
        onClick={() => addExtra(lessonId)} >
        Add Additional Lesson <i className="fa fa-plus" />
      </Button>
    </AttendanceBlockContainer>
  )
};
export default ExtraAttendanceBlock;
