import React, { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useDeleteLibraryItems = ({ teacherId }) => {
  const { getUserLibraryItems, deleteLibraryItemPermanently } =
    useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);

  async function deleteTeacherLibraryItems() {
    if (!teacherId) return;
    try {
      setLoadingAction(true);

      const teacherLibraryItems = await getUserLibraryItems(teacherId);
      console.log({ teacherLibraryItems });
      for (const libraryItem of teacherLibraryItems) {
        await deleteLibraryItemPermanently(libraryItem);
      }
      toast.success("Deleted All Library Items Successfully");
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAction(false);
    }
  }
  return { loadingAction, deleteTeacherLibraryItems };
};

export default useDeleteLibraryItems;
