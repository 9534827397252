import React from "react";
import { groupClassPaymentTypesOptions } from "../../../constants";
import { CustomSelectField, InputContainer, InputRow } from "../../../styled";
import { CustomLabel } from "src/utils/shared/styled";
import ScheduledPaymentSection from "./ScheduledPaymentSection";
import OneTimePaymentSection from "./OneTimePaymentSection";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const PaymentComponent = ({ hookData }) => {
  const {
    handlePaymentFormInputChange,
    paymentFormInputs,
    isScheduledPayment,
    isOneTimeTypePayment,
    isBothTypePayment,
    handleCreateGroupClass,
    loadingSavingGroupClass,
    isCompleteGroupClassInfo,
    isAvailableForAll,
    isAvailableForCurrentStudents,
    isAvailableForPublic,
    handleAddNewPaymentAmount,
    handleDeletePaymentAmount,
    handleEditPaymentAmount,
  } = hookData;
  return (
    <div>
      <InputRow>
        <InputContainer>
          <CustomLabel>Payment Math:</CustomLabel>
          <CustomSelectField
            value={paymentFormInputs.paymentType}
            onChange={(e) =>
              handlePaymentFormInputChange("paymentType", e.target.value)
            }
            width="100%"
          >
            <option value="" disabled>
              Payment Math
            </option>
            {Object.values(groupClassPaymentTypesOptions).map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))}
          </CustomSelectField>
        </InputContainer>
      </InputRow>
      {isScheduledPayment ? (
        <ScheduledPaymentSection
          paymentFormInputs={paymentFormInputs}
          handlePaymentFormInputChange={handlePaymentFormInputChange}
          isAvailableForAll={isAvailableForAll}
          isAvailableForPublic={isAvailableForPublic}
          isAvailableForCurrentStudents={isAvailableForCurrentStudents}
          handleAddNewPaymentAmount={handleAddNewPaymentAmount}
          handleDeletePaymentAmount={handleDeletePaymentAmount}
          handleEditPaymentAmount={handleEditPaymentAmount}
        />
      ) : isBothTypePayment ? (
        <>
          <ScheduledPaymentSection
            paymentFormInputs={paymentFormInputs}
            handlePaymentFormInputChange={handlePaymentFormInputChange}
            isAvailableForAll={isAvailableForAll}
            isAvailableForPublic={isAvailableForPublic}
            isAvailableForCurrentStudents={isAvailableForCurrentStudents}
            handleAddNewPaymentAmount={handleAddNewPaymentAmount}
            handleDeletePaymentAmount={handleDeletePaymentAmount}
            handleEditPaymentAmount={handleEditPaymentAmount}
          />
          <OneTimePaymentSection
            paymentFormInputs={paymentFormInputs}
            handlePaymentFormInputChange={handlePaymentFormInputChange}
            isBothTypePayment={isBothTypePayment}
            isAvailableForAll={isAvailableForAll}
            isAvailableForPublic={isAvailableForPublic}
            isAvailableForCurrentStudents={isAvailableForCurrentStudents}
            handleAddNewPaymentAmount={handleAddNewPaymentAmount}
            handleDeletePaymentAmount={handleDeletePaymentAmount}
            handleEditPaymentAmount={handleEditPaymentAmount}
          />
        </>
      ) : (
        isOneTimeTypePayment && (
          <OneTimePaymentSection
            paymentFormInputs={paymentFormInputs}
            handlePaymentFormInputChange={handlePaymentFormInputChange}
            isBothTypePayment={isBothTypePayment}
            isAvailableForAll={isAvailableForAll}
            isAvailableForPublic={isAvailableForPublic}
            isAvailableForCurrentStudents={isAvailableForCurrentStudents}
            handleAddNewPaymentAmount={handleAddNewPaymentAmount}
            handleDeletePaymentAmount={handleDeletePaymentAmount}
            handleEditPaymentAmount={handleEditPaymentAmount}
          />
        )
      )}
      {isCompleteGroupClassInfo && (
        <div className="text-center">
          <PrimaryButtonWithLoading
            onClick={handleCreateGroupClass}
            isLoading={loadingSavingGroupClass}
            width="50%"
          >
            Create Group Class
          </PrimaryButtonWithLoading>
        </div>
      )}
    </div>
  );
};

export default PaymentComponent;
