import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  absenceStatuses,
  isPendingAbsence,
  isResolvedAbsence,
} from "src/constants/absenceTypes";
import { parseAbsenceObjDates } from "src/utils/firebaseDatesParserFns";
import {
  getFilterStartAndEndDate,
  getTimeDiffInMins,
  isActiveTeacher,
  sortArrayByDates,
} from "src/utils/helpers";
import { dateFilters, pageTabs } from "../constants";
import useFirebaseFns from "./useFirebaseFns";

const START_DATE_FILTER_MONTH = 12;
const START_DATE_FILTER_DAY = 1;

const customDateFilterInitialValues = {
  startDate: null,
  endDate: null,
};

const useAbsenceData = () => {
  const {
    getTeachers,
    getTeacherAbsences,
    getInstruments,
    getLocations,
    getPrivateAndTrialStudents,
    getAdminCredentialsUsers,
  } = useFirebaseFns();
  // the page current view
  const [currentView, setCurrentView] = useState(pageTabs.PENDING);

  const [initialData, setInitialData] = useState({
    teachers: [],
    teacherAbsences: [],

    locations: [],
    instruments: [],
    combinedStudents: [],
    adminCredentialsUsers: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  // currentAbsenceId is for when opening the absence details modal
  const [currentAbsenceId, setCurrentAbsenceId] = useState("");
  const [justApprovedAbsenceId, setJustApprovedAbsenceId] = useState("");

  // search term from the search bar
  const [searchTerm, setSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  // date filters
  const [dateFilter, setDateFilter] = useState(dateFilters.THIS_MONTH.value);
  const [customDateFilterValues, setCustomDateFilterValues] = useState(
    customDateFilterInitialValues
  );

  const handleDateFilterChange = (filterNewValue) => {
    setDateFilter(filterNewValue);
  };
  const handleCustomDateFilterChange = (name, value) => {
    if (name === "startDate") {
      setCustomDateFilterValues((oldVal) => ({
        ...oldVal,
        startDate: value,
        endDate: null,
      }));
    } else {
      setCustomDateFilterValues((oldVal) => ({
        ...oldVal,
        [name]: value,
      }));
    }
  };

  const getSearchTermTeachersIds = useCallback(() => {
    if (!searchTerm) {
      return initialData.teachers.map(({ id }) => id);
    }

    const filteredTeachers = initialData.teachers.filter(({ fullName }) =>
      fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredTeachersIds = filteredTeachers?.map(({ id }) => id);
    return filteredTeachersIds;
  }, [searchTerm, initialData.teachers]);

  const isDateWithinDateFilter = useCallback(
    (absenceDate) => {
      switch (dateFilter) {
        case dateFilters.THIS_MONTH.value:
          return moment().startOf("month").isSame(absenceDate, "month");

        case dateFilters.THIS_QUARTER.value:
          return (
            // if they are the same year, check if they are the same quarter
            moment().year() === moment(absenceDate).year() &&
            moment().quarter() === moment(absenceDate).quarter()
          );
        case dateFilters.THIS_YEAR.value:
          return moment().startOf("year").isSame(absenceDate, "year");
        case dateFilters.CUSTOM.value:
          if (
            !customDateFilterValues.startDate ||
            !customDateFilterValues.endDate
          ) {
            return false;
          } else {
            return moment(absenceDate).isBetween(
              customDateFilterValues.startDate,
              customDateFilterValues.endDate,
              "date",
              "[]"
            );
          }
        default:
          return false;
      }
    },
    [dateFilter, customDateFilterValues]
  );

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [
          teachers,
          teacherAbsences,
          instruments,
          locations,
          combinedStudents,
          adminCredentialsUsers,
        ] = await Promise.all([
          getTeachers(),
          getTeacherAbsences(),
          getInstruments(),
          getLocations(),
          getPrivateAndTrialStudents(),
          getAdminCredentialsUsers(),
        ]);

        const absencesWithDates = teacherAbsences.map((absence) => {
          const parsedAbsence = parseAbsenceObjDates(absence);
          return parsedAbsence;
        });

        // only get absences of active teachers
        const filteredAbsences = absencesWithDates.filter((absence) => {
          const teacher = teachers.find(({ id }) => id === absence.teacherId);
          const isActive = isActiveTeacher(teacher);
          return isActive;
        });

        // const { startDateFilter, endDateFilter } = getFilterStartAndEndDate(
        //   START_DATE_FILTER_MONTH,
        //   START_DATE_FILTER_DAY
        // );

        // const currentYearAbsences = filteredAbsences.filter(
        //   ({ date, startDate, request }) => {
        //     return moment(
        //       date || startDate || request?.date || request?.startDate
        //     ).isBetween(startDateFilter, endDateFilter, undefined, "[]");
        //   }
        // );
        const sortedAbsences = filteredAbsences.sort((a, b) => {
          const firstDate =
            a.date || a.startDate || a.request?.date || a.request?.startDate;
          const secondDate =
            b.date || b.startDate || b.request?.date || b.request?.startDate;

          return getTimeDiffInMins(firstDate, secondDate);
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          teachers,
          teacherAbsences: sortedAbsences,
          // startDateFilter,
          // endDateFilter,
          instruments,
          locations,
          combinedStudents,
          adminCredentialsUsers,
        }));
      } catch (err) {
        console.error(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  const teacherPendingAbsences = useMemo(() => {
    const searchTermTeachersIds = getSearchTermTeachersIds();
    return initialData.teacherAbsences.filter(
      ({ status, teacherId, date, startDate, request }) => {
        const absenceDate =
          date || startDate || request?.date || request?.startDate;
        return (
          isPendingAbsence(status) &&
          searchTermTeachersIds?.includes(teacherId) &&
          isDateWithinDateFilter(absenceDate)
        );
      }
    );
  }, [
    initialData.teacherAbsences,
    getSearchTermTeachersIds,
    isDateWithinDateFilter,
  ]);
  const teacherResolvedAbsences = useMemo(() => {
    const searchTermTeachersIds = getSearchTermTeachersIds();
    return initialData.teacherAbsences.filter(
      ({ status, teacherId, date, startDate, request }) => {
        const absenceDate =
          date || startDate || request?.date || request?.startDate;
        return (
          isResolvedAbsence(status) &&
          searchTermTeachersIds?.includes(teacherId) &&
          isDateWithinDateFilter(absenceDate)
        );
      }
    );
  }, [
    initialData.teacherAbsences,
    getSearchTermTeachersIds,
    isDateWithinDateFilter,
  ]);

  // current absence obj
  const currentAbsence = useMemo(() => {
    return initialData.teacherAbsences.find(
      ({ id }) => id === currentAbsenceId
    );
  }, [currentAbsenceId, initialData.teacherAbsences]);

  const justApprovedAbsence = useMemo(() => {
    return initialData.teacherAbsences.find(
      ({ id }) => id === justApprovedAbsenceId
    );
  }, [justApprovedAbsenceId, initialData.teacherAbsences]);

  return {
    currentView,
    setCurrentView,
    initialData,
    teacherPendingAbsences,
    teacherResolvedAbsences,
    loadingInitialData,
    currentAbsenceId,
    setCurrentAbsenceId,
    currentAbsence,
    refreshData,
    setJustApprovedAbsenceId,
    justApprovedAbsence,
    searchTerm,
    handleSearchTermChange,
    dateFilter,
    handleDateFilterChange,
    customDateFilterValues,
    handleCustomDateFilterChange,
  };
};

export default useAbsenceData;
