import React from "react";
import useFirebaseFns from "./useFirebaseFns";
import { deleteOptions } from "../constants";
import { useState } from "react";
import { toast } from "react-toastify";
import { arrayUnion } from "firebase/firestore";

const useBreakData = ({ closeModal, event, refreshData }) => {
  const {
    deleteOneTimeBreakAndActivity,
    cancelOneRecurringBreakAndActivity,
    cancelFutureRecurringBreakAndActivity,
  } = useFirebaseFns();
  console.log({ breakId: event.id });

  const [deleteOption, setDeleteOption] = useState(deleteOptions.ONE_EVENT);
  const [loading, setLoading] = useState(false);

  const confirmDeleteRecurringBreak = async () => {
    try {
      setLoading(true);

      if (deleteOption === deleteOptions.ONE_EVENT) {
        await cancelOneRecurringBreakAndActivity(event.id, event.start);
      } else if (deleteOption === deleteOptions.FUTURE_EVENTS) {
        await cancelFutureRecurringBreakAndActivity(event.id, event.start);
      } else {
        toast.error("Can't detect delete option");
        return;
      }

      toast.success("Canceled Successfully");
      refreshData();
      closeModal();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteOneTimeBreak = async () => {
    try {
      setLoading(true);
      await deleteOneTimeBreakAndActivity(event.id);
      toast.success("Deleted Successfully");
      refreshData();
      closeModal();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteOption,
    setDeleteOption,
    confirmDeleteRecurringBreak,
    confirmDeleteOneTimeBreak,
    loading,
  };
};

export default useBreakData;
