import styled from "styled-components";

const TeacherInfoWrapper = styled.div`
  img {
    display: block;
    width: 232px;
    margin: 0 auto;
    border-radius: 12px;
  }
`;

const TeacherInfoList = styled.ul`
  margin-top: 32px;
  padding: 24px;
  border: 1px solid #e4aabb;

  list-style: none;
  border-radius: 1.3rem;
`;

const TeacherInfoItem = styled.li`
  font-size: 18px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export { TeacherInfoItem, TeacherInfoList, TeacherInfoWrapper };
