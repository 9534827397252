import { creationModalTypes } from "../../creationModalTypes";
import AppointmentModalBody from "./AppointmentModalBody";
import useAppointmentData from "./hooks/useAppointmentData";
import {
  BodyInnerWrapper,
  CloseIcon,
  CustomBody,
  CustomHeader,
  CustomModal,
  HeaderContainer,
  ModalTitle,
} from "./styled";

const NewAppointmentModal = ({
  modalData,
  selectedSlotInfo,
  refreshSelectedTeachersEvents,
  locations,
}) => {
  const { resourceId } = selectedSlotInfo.resource;
  const appointmentData = useAppointmentData(resourceId);
  return (
    <div>
      <CustomModal
        size="xs"
        style={{ maxWidth: "550px", minWidth: "550px" }}
        centered
        isOpen={modalData.isModalNameOpen(
          creationModalTypes.newAppointment.name
        )}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          <HeaderContainer>
            <i className="far fa-calendar" style={{ fontSize: "25px" }} />
            <ModalTitle className="fs-3 fw-medium">New Appointment</ModalTitle>
          </HeaderContainer>
        </CustomHeader>
        <CustomBody>
          <BodyInnerWrapper>
            <AppointmentModalBody
              selectedSlotInfo={selectedSlotInfo}
              modalData={modalData}
              appointmentData={appointmentData}
              refreshSelectedTeachersEvents={refreshSelectedTeachersEvents}
              locations={locations}
            />
          </BodyInnerWrapper>
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default NewAppointmentModal;
