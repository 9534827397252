import { useContext, useEffect, useMemo, useState } from "react";
import useFirebaseFns from "../../../../../../hooks/useFirebaseFns";
import { Container } from "reactstrap";
import {
  ActionBtnsContainer,
  CheckIcon,
  DeleteIcon,
  EditIcon,
  IconWrapper,
  TableRow,
} from "./styled";
import { SubmitSpinner } from "../../../../../common";
import { modalMap } from "./utils/constants";
import {
  availableDaysToString,
  instrumentsToString,
  isActiveTeacher,
} from "../../../../../../utils/helpers";
import {
  LessonsInfoModal,
  TeacherCardModal,
  TeacherInfoModal,
  TeacherBreaksModal,
  TeacherReactivationModal,
} from "./modals";
import DeleteTeacherLibraryItemsModal from "./modals/DeleteTeacherLibraryItemsModal";
import {
  Image,
  SortButton,
} from "src/components/Dashboard/TeacherAndStaffDirectory/TeacherAndStaffDirectory.styled";
import { FaSortAlphaDown, FaSortAlphaUpAlt } from "react-icons/fa";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import { ConfirmDeleteModal } from "../../../TeacherAbsence/components/ConfirmDeleteModal";
import { deleteDoc } from "firebase/firestore";
import { FirebaseContext } from "../../../../../Firebase";
import { toast } from "react-toastify";

const TableLayout = ({
  teachersList,
  loadingTeachersList,
  modalData,
  refreshData,
}) => {
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedTeachers, setSortedTeachers] = useState(teachersList || []);

  const [selectedTeacherToDelete, setSelectedTeacherToDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const firebase = useContext(FirebaseContext);
  const { permaDeleteUserAccount } = useFirebaseFns();

  useEffect(() => {
    setSortedTeachers(teachersList);
  }, [teachersList]);

  const handleSort = () => {
    const sorted = [...sortedTeachers].sort((a, b) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setSortedTeachers(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const openCurrentTeacherCardModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.teacherCard.name);
  };
  const openCurrentTeacherInfoModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.teacherInfo.name);
  };
  const openCurrentTeacherLessonsInfoModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.lessonsInfo.name);
  };

  const currentTeacher = useMemo(() => {
    return teachersList?.find(({ id }) => id === currentTeacherId);
  }, [currentTeacherId, teachersList]);

  // No Teachers found display
  if (!teachersList?.length && !loadingTeachersList)
    return (
      <h2 className="text-dark mt-5 text-center">No Teachers Were Found</h2>
    );
  // Loading display
  if (loadingTeachersList) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  const handleDeleteClick = (teacher) => {
    setDeleteModalOpen(true);
    setSelectedTeacherToDelete(teacher?.id);
  };

  const handleDeleteConfirm = async () => {
    toast.loading("Deletion in progress");
    await permaDeleteUserAccount(selectedTeacherToDelete);
    await deleteDoc(firebase.user(selectedTeacherToDelete));
    refreshData();
    setDeleteModalOpen(false);
    toast.dismiss();
    toast.success("Teacher deleted");
  };

  const ActionButtons = ({ teacher }) => {
    return (
      <ActionBtnsContainer>
        <IconWrapper>
          <EditIcon onClick={() => openCurrentTeacherInfoModal(teacher?.id)} />
        </IconWrapper>
        <IconWrapper>
          <CheckIcon
            onClick={() => openCurrentTeacherLessonsInfoModal(teacher?.id)}
          />
        </IconWrapper>

        {!isActiveTeacher(teacher) && (
          <IconWrapper>
            <DeleteIcon onClick={() => handleDeleteClick(teacher)} />
          </IconWrapper>
        )}
      </ActionBtnsContainer>
    );
  };

  return (
    <Container fluid>
      <div className="overflow-hidden">
        <table className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>Photo</th>
              <th>
                <div>
                  Name of Teacher{" "}
                  <SortButton
                    onClick={handleSort}
                    aria-label="Sort by alphabet"
                    title="Sort by alphabet"
                  >
                    {sortOrder === "asc" ? (
                      <FaSortAlphaDown size={24} />
                    ) : (
                      <FaSortAlphaUpAlt size={24} />
                    )}
                  </SortButton>
                </div>
              </th>
              <th>
                <div>Instrument</div>
              </th>
              <th>
                <div>Teaching Days</div>
              </th>
              <th>
                <div>Phone Number</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Actions</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {sortedTeachers?.map((teacher) => {
              const {
                id,
                fullName,
                primaryPhone,
                primaryEmail,
                instrumentsInfo,
                teacherDays,
              } = teacher || {};
              const teacherInstrumentsNames = instrumentsInfo?.map(
                (el) => el?.instrument?.name
              );

              return (
                <TableRow key={id}>
                  <td>
                    <Image
                      src={teacher.imageUrl || UnknownProfilePhoto}
                      alt={teacher.fullName || "teacher"}
                    />
                  </td>
                  <td
                    className="name-cell"
                    style={{ textAlign: "left" }}
                    onClick={() => openCurrentTeacherCardModal(id)}
                  >
                    {fullName || "N/A"}
                  </td>
                  <td>
                    {instrumentsToString(teacherInstrumentsNames) || "N/A"}
                  </td>
                  <td>{availableDaysToString(teacherDays?.availableDays)}</td>
                  <td>{primaryPhone || "N/A"}</td>
                  <td>{primaryEmail || "N/A"}</td>
                  <td>
                    <ActionButtons teacher={teacher} />
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </table>
        {/* Modals */}
        {currentTeacher && (
          <>
            <TeacherCardModal
              teacher={currentTeacher}
              modalData={modalData}
              refreshData={refreshData}
            />
            <TeacherInfoModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <LessonsInfoModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <TeacherBreaksModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <TeacherReactivationModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
            />
            <DeleteTeacherLibraryItemsModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
            />
          </>
        )}
      </div>
      <ConfirmDeleteModal
        open={deleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteModalOpen(false)}
        text="Are you sure you want to delete this teacher?"
      />
    </Container>
  );
};

export default TableLayout;
