export const breakReasons = [
  {
    name: "Teacher Break",
    value: "Teacher Break",
  },
  {
    name: "Lunch Break",
    value: "Lunch Break",
  },
  {
    name: "Emergency Break",
    value: "Emergency Break",
  },
];
export const breakDurations = [
  {
    name: "5 Minutes",
    value: 5,
  },
  {
    name: "10 Minutes",
    value: 10,
  },
  {
    name: "15 Minutes",
    value: 15,
  },
  {
    name: "30 Minutes",
    value: 30,
  },
  {
    name: "45 Minutes",
    value: 45,
  },
  {
    name: "60 Minutes",
    value: 60,
  },
  {
    name: "90 Minutes",
    value: 90,
  },
  {
    name: "120 Minutes",
    value: 120,
  },
];
