import React from "react";
import { useState } from "react";
import { FormGroup } from "reactstrap";
import { SearchIcon } from "../styled";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <FormGroup className="searchContainer">
      <input
        placeholder="Search by Parent Name"
        className="searchInput"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <SearchIcon />
    </FormGroup>
  );
};

export default SearchBar;
