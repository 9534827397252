import moment from "moment";
import React, { useMemo } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { CustomLabel } from "src/utils/shared/styled";
import { getPaymentDateRanges } from "./helperFns";
import { convertPaymentDateToDateStr } from "src/utils/helpers";

const DateRangeSelection = ({
  selectedDateRange,
  setSelectedDateRange,
  isDisabled,
  isOptionDisabled = () => {},
}) => {
  const dateRanges = getPaymentDateRanges();

  const label = "Select Date Range";

  const options = useMemo(() => {
    return dateRanges.map((range) => mapDateRangeToOption(range));
  }, [dateRanges]);

  const handleOptionsChange = (option) => {
    if (!option) return;

    setSelectedDateRange(option.value);
  };

  const selectedOption = selectedDateRange
    ? mapDateRangeToOption(selectedDateRange)
    : null;

  return (
    <div>
      <CustomLabel>{label}:</CustomLabel>
      <CustomReactSelect
        isDisabled={isDisabled}
        isMulti={false}
        placeholder={label}
        width="100%"
        options={options}
        onChange={handleOptionsChange}
        value={selectedOption}
        wrapOptions={false}
        isOptionDisabled={isOptionDisabled}
      />
    </div>
  );
};

const mapDateRangeToOption = (dateRange) => {
  if (!dateRange) return;

  const { start, end } = dateRange;
  const label = `${start} - ${end}`;
  const option = {
    label,
    value: dateRange,
  };

  return option;
};

export default DateRangeSelection;
