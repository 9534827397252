import { injectUserStore } from "src/utils/helpers";
import { toJS } from "mobx";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { compose } from "recompose";
import { SubmitSpinner } from "src/components/common";
import {
  CalendarPickerWrapper,
  CustomTextarea,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import {
  Container,
  RedBarContainer,
  RedBarText,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../../../../styled";
import useWithdrawalData from "../../hooks/useWithdrawalData";
import { SingleDatePicker } from "react-dates";

const WithdrawalSection = ({
  modalData,
  lessonWithCurrentTimeline,
  onSuccess,
  UserStore,
}) => {
  const { closeModal } = modalData;
  const user = toJS(UserStore)?.user;

  const {
    lastDate,
    setLastDate,
    reason,
    setReason,
    loadingSaveWithdrawal,
    submitWithdrawalRequest,
  } = useWithdrawalData({
    user,
    lessonWithCurrentTimeline,
    onSuccess,
    closeModal,
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const lessonStartDate = lessonWithCurrentTimeline?.currentTimeline?.startDate;

  return (
    <>
      <Container>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>Withdrawal Request</RedBarText>
        </RedBarContainer>
        <div
          style={{
            padding: "25px",
            width: "100%",
          }}
        >
          <InputWrapper>
            <CustomLabel>
              Last Lesson Date:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CalendarPickerWrapper>
              <SingleDatePicker
                id="lastLessonDate"
                date={lastDate}
                onDateChange={(date) => {
                  setLastDate(date);
                }}
                focused={isDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  //disables dates before the lesson start
                  if (moment(date).isBefore(lessonStartDate, "date"))
                    return true;

                  // disables dates that are not on the same weekday as the lsn
                  if (moment(date).day() !== moment(lessonStartDate).day())
                    return true;

                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </InputWrapper>
          <div>
            <InputWrapper>
              <CustomLabel>
                Withdrawal Reason:<RequiredInputSpan>*</RequiredInputSpan>
              </CustomLabel>

              <CustomTextarea
                marginTop="0px"
                width="100%"
                className="form-control"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </InputWrapper>
          </div>
        </div>
      </Container>
      <CustomButtonsWrapper>
        <CustomButton onClick={closeModal}>Cancel</CustomButton>
        <CustomButton primary onClick={submitWithdrawalRequest}>
          {loadingSaveWithdrawal ? (
            <div>
              <SubmitSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            "Confirm"
          )}
        </CustomButton>
      </CustomButtonsWrapper>
    </>
  );
};

export default injectUserStore(WithdrawalSection);
