import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useSidebarData = ({ user }) => {
  const {
    getSubmittedTeacherAbsences,
    getPendingMakeupRequests,
    getConcertInvitationsByUserId,
    getApplications,
    getFeedbacks,
  } = useFirebaseFns();

  const [teacherSubmittedAbsences, setTeacherSubmittedAbsences] = useState([]);
  const [pendingMakeupRequests, setPendingMakeupRequests] = useState([]);
  const [concertInvitations, setConcertInvitations] = useState([]);
  const [applications, setApplications] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSubmittedTeacherAbsences = async () => {
      try {
        setLoading(true);
        const [
          absences,
          makeupRequests,
          concertInvitations,
          applications,
          feedbacks,
        ] = await Promise.all([
          getSubmittedTeacherAbsences(),
          getPendingMakeupRequests(),
          getConcertInvitationsByUserId(user.uid),
          getApplications(false),
          getFeedbacks(false),
        ]);
        setTeacherSubmittedAbsences(absences);
        setPendingMakeupRequests(makeupRequests);
        setConcertInvitations(concertInvitations);
        setApplications(applications);
        setFeedbacks(feedbacks);
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSubmittedTeacherAbsences();
  }, []);
  return {
    teacherSubmittedAbsences,
    pendingMakeupRequests,
    concertInvitations,
    applications,
    feedbacks,
    loading,
  };
};

export default useSidebarData;
