import {
  combineArrayToStr,
  getUpcomingGroupClassLesson,
} from "src/utils/helpers";
import moment from "moment";

import {
  InfoPair,
  InfoTitle,
  TextInfoContainer,
  InfoText,
  TeachersPicsContainer,
  ClassInfoContainer,
} from "../../../styled";

const ClassInfo = ({ groupClassObj, teachers }) => {
  const { classes } = groupClassObj;

  const upcomingClass = getUpcomingGroupClassLesson(classes);
  const { teachersIds, startDate } = upcomingClass || {};

  const classTeachers =
    teachersIds?.map((teacherId) =>
      teachers?.find(({ id }) => id === teacherId)
    ) || [];
  const teachersNames = classTeachers.map(({ fullName }) => fullName);

  const teachersNamesStr = combineArrayToStr(teachersNames);

  const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
  const formattedStartTime = moment(startDate).format("hh:mm A");

  const upcomingTimeFrame = () => {
    const firstClass = classes?.[0];
    const lastClass = classes?.[classes?.length - 1];
    const firstClassDate = firstClass.startDate;
    const lastClassDate = lastClass.startDate;

    const classStart = moment(firstClass.startDate);
    const duration = firstClass.duration;

    const formattedFirstDate = classStart.format("hh:mm A");
    const classEnd = classStart.clone().add(duration, "minutes");
    const formattedEndDate = classEnd.format("hh:mm A");

    const firstAndLastClassText = `(${moment(firstClassDate).format(
      "MMM Do"
    )} - ${moment(lastClassDate).format("MMM Do")})`;

    return {
      date: firstAndLastClassText,
      time: `${formattedFirstDate} - ${formattedEndDate}`,
    };
  };

  return (
    <>
      <ClassInfoContainer>
        <TextInfoContainer>
          <InfoPair>
            <InfoTitle>Instructors:</InfoTitle>
            <InfoText>{teachersNamesStr}</InfoText>
          </InfoPair>
          <InfoPair>
            <InfoTitle>Upcoming Day:</InfoTitle>
            <InfoText>{formattedStartDate}</InfoText>
          </InfoPair>
          <InfoPair>
            <InfoTitle>Upcoming Time:</InfoTitle>
            <InfoText>{upcomingTimeFrame().time}</InfoText>
          </InfoPair>
        </TextInfoContainer>
        <TeachersPicsContainer>
          {classTeachers
            .filter(({ imageUrl }) => imageUrl)
            .map((teacher, index) => (
              <img
                src={teacher?.imageUrl}
                alt={teacher?.fullName || "teacher"}
                key={index}
              />
            ))}
        </TeachersPicsContainer>
      </ClassInfoContainer>
    </>
  );
};

export default ClassInfo;
