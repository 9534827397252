import { IoCamera } from "react-icons/io5";
import { FeedbackInputWrapper } from "./styled";
import { ErrorMessage } from "src/utils/shared/styled";

const MessageSection = ({
  value,
  onChange,
  onCameraClick,
  error,
}) => {
  return (
    <>
      <FeedbackInputWrapper>
        <textarea
          placeholder="Share your feedback"
          value={value}
          onChange={onChange}
        />
        <button type="button" onClick={onCameraClick}>
          <IoCamera size={20} />
        </button>
      </FeedbackInputWrapper>
      {error && <ErrorMessage>Message is required</ErrorMessage>}
    </>
  );
};

export default MessageSection;
