import React from "react";
import { SubmitSpinner } from "src/components/common";
import { isAdmin, isSuperAdmin } from "src/constants/UserRoleEnum";
import useModal from "src/hooks/useModal";
import { injectUserStore } from "src/utils/helpers";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import ViewAdminInfoModal from "../../common/components/ViewAdminInfoModal";
import ActionButtons from "./ActionButtons";

const AdminsTable = ({ adminsData, UserStore }) => {
  const user = UserStore.user;
  const {
    users,
    isLoading,
    currentUser,
    setCurrentUserId,
    deleteUserPermanently,
    loadingAction,
    deleteConfirmationModalData,
    onDeleteModalApprove,
    onDeleteModalCancel,
    openDeleteModalWithCurrentUser,
    adminInfoModalData,
    openAdminInfoModalWithCurrentUser,
    refreshData,
  } = adminsData;

  const isAdminUser = isAdmin(user.role);
  const isSuperAdminUser = isSuperAdmin(user.role);

  // Loading display
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  return (
    <CustomTableContainer>
      <CustomTable>
        <thead>
          <TableRow>
            <th>
              <div>Name</div>
            </th>
            <th>
              <div>Email</div>
            </th>
            <th>
              <div>Phone Number</div>
            </th>
            <th>
              <div>Role</div>
            </th>
            {isSuperAdminUser && (
              <th>
                <div>Actions</div>
              </th>
            )}
          </TableRow>
        </thead>
        <tbody>
          {users.map((user) => {
            const { id, fullName, primaryEmail, role, primaryPhone } = user;
            return (
              <TableRow key={id}>
                <ClickableTableCell
                  onClick={() => openAdminInfoModalWithCurrentUser(id)}
                >
                  {fullName}
                </ClickableTableCell>
                <td>{primaryEmail}</td>
                <td>{primaryPhone}</td>
                <td>{role}</td>
                {isSuperAdminUser && (
                  <td>
                    <ActionButtons
                      userId={id}
                      openDeleteModalWithCurrentUser={
                        openDeleteModalWithCurrentUser
                      }
                    />
                  </td>
                )}
              </TableRow>
            );
          })}
        </tbody>
      </CustomTable>

      {/* delete confirmation modal */}
      <ConfirmationModal
        modalData={deleteConfirmationModalData}
        onApprove={onDeleteModalApprove}
        onCancel={onDeleteModalCancel}
        isSubmitting={loadingAction}
        title="Permanently Delete User ?"
        approveBtnText="Delete User"
        cancelBtnText="Cancel"
      />
      <ViewAdminInfoModal
        modalData={adminInfoModalData}
        adminUser={currentUser}
        onExtraFunctionsChangeSuccess={() => refreshData()}
      />
    </CustomTableContainer>
  );
};

export default injectUserStore(AdminsTable);
