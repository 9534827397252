import React, { useRef, useContext, useState, useEffect, useMemo } from "react";
import { Spinner } from "../../../common";
import {
  ADD_LABELS,
  ALLOWED_EXTENSIONS,
  typesOptions,
} from "../../../common/Library/common/constants";
import {
  StudentsContext,
  StudentsSelectContext,
  studentViews,
  TeacherLessonNotesModalContext,
} from "../../../common/Library/common/libraryContext";
import SearchSelect from "../../../common/Library/common/SearchSelect";
import FilesPreview from "../../../common/Library/new-item-modal/FilesPreview";
import LibraryFileUploadInput from "../../../common/Library/new-item-modal/LibraryFileUploadInput";

import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
  LibraryModalInput,
  TextButton,
} from "../../../common/styled-components/teacherLibraryStyledComponents";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-date-picker";
import { CustomDatePickerStyled } from "../../../common/styled-components/lessonNotesStyledComponents";
import { Timestamp } from "firebase/firestore";
import {
  CalendarPickerWrapper,
  PrimaryButton,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import UploadFromDeviceSection from "./UploadFromDeviceSection";
import { fileUploadOptions } from "./constants";
import ChooseFromLibraryModal from "./ChooseFromLibraryModal";
import useModal from "src/hooks/useModal";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import CustomRichTextEditor from "../../common/components/CustomRichTextEditor";
import { SingleDatePicker } from "react-dates";

const ButtonContainer = styled.div`
  width: 47%;
  @media (max-width: ${ScreenSize.LG}px) {
    width: 100%;
  }
`;

const AddLessonNoteModal = ({ onSubmit }) => {
  const { isOpen, close, isEditingMode, note } = useContext(
    TeacherLessonNotesModalContext
  );
  const { students, currentStudentsView, teacherStudents } =
    useContext(StudentsContext);
  const showTeacherStudents =
    currentStudentsView === studentViews.TEACHER_STUDENTS;
  const displayedStudents = showTeacherStudents ? teacherStudents : students;

  const studentsOptions = useMemo(
    () =>
      displayedStudents.map(({ fullName, id }) => ({
        label: fullName,
        value: id,
        id,
      })),
    [students]
  );

  const libraryItemsModalData = useModal();

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [recommendationLink, setRecommendationLink] = useState("");
  const [date, setDate] = useState(isEditingMode ? moment(note.date) : null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // for choosing either uploading the files from device or choosing from the user's library
  //   const [fileUploadOption, setFileUploadOption] = useState(
  //     fileUploadOptions.UPLOAD_FROM_DEVICE
  //   );
  // in case of uploading from device (will contain array of files)
  //  const fileObject = {
  //    file,
  //    name: `${fileType.label} | ${file.name}`,
  //    attachmentType: fileType.value,
  //  };
  const [uploadFiles, setUploadFiles] = useState([]);
  // in case of choosing files from the user's library (will contain array of library items objs)
  const [libraryChosenItems, setLibraryChosenItems] = useState([]);

  const addChosenLibraryItems = (items) => {
    if (!items) {
      toast.warn("Please select at least 1 item");
      return;
    }
    setLibraryChosenItems(items);
  };

  useEffect(() => {
    if (isEditingMode) {
      setNoteTitle(note.title);
      setNoteDescription(note.description);
      setSelectedStudents(
        studentsOptions.filter(({ id }) => note.studentsIds.includes(id))
      );
      setUploadFiles(
        note.attachments.map((attachment) => {
          const attachmentObj = {
            name: `${attachment.attachmentType} | ${attachment.name}`,
            attachmentType: attachment.attachmentType,
            isNew: false,
          };
          return attachmentObj;
        })
      );
    } else {
      if (note?.studentsIds?.length) {
        setSelectedStudents(
          studentsOptions.filter(({ id }) => note.studentsIds.includes(id))
        );
      }
    }
  }, []);

  const submit = (isDraft = false) => {
    // setIsLoading(true);
    toast.info(
      isEditingMode
        ? "Updating lesson note details..."
        : "Creating a new lesson note..."
    );
    toast.info(
      "Saving in background, please don't refresh or close the page until finished"
    );

    const noteDate = date ? date.toDate() : null;

    if (!noteDate) {
      toast.warn("Please add the date");
      return;
    }

    const formData = {
      studentsIds: selectedStudents.map(({ id }) => id),
      title: noteTitle,
      description: noteDescription,
      date: Timestamp.fromDate(noteDate),
      files: uploadFiles,
      libraryChosenItems,
      recommendationLink,
      isDraft,
      isDeleted: false,
    };
    if (note.id) {
      formData.noteId = note.id;
    }
    onSubmit(formData);
    // await onSubmit(formData);
    // toast.success(isEditingMode ? "Updated successfully." : "Created successfully.");
    // setIsLoading(false);
    // close();
  };

  const isDisabled = () => {
    return (
      !noteDescription.length ||
      !noteTitle.length ||
      date === "" ||
      !selectedStudents.length
    );
  };

  const removeFile = (file) => {
    const { isLibraryItem } = file;
    if (isLibraryItem) {
      setLibraryChosenItems((previous) => {
        const newList = previous.filter(({ id }) => id !== file.id);
        return [...newList];
      });
    } else {
      setUploadFiles((previous) => {
        const index = previous.indexOf(file);
        previous.splice(index, 1);
        return [...previous];
      });
    }
  };

  // combines library chosen files with the uploaded files in a single list that can be displayed
  const combinedPreviewFiles = useMemo(() => {
    const formattedLibraryItems = libraryChosenItems.map((item) => {
      const { attachmentType, title, id } = item;
      const fileType = typesOptions.find(
        ({ value }) => value === attachmentType
      );
      const fileTypeName = fileType?.label;
      return {
        ...item,
        attachmentType,
        name: `${fileTypeName} | ${title}`,
        isLibraryItem: true,
      };
    });

    const combinedFiles = [...uploadFiles, ...formattedLibraryItems];
    return combinedFiles;
  }, [uploadFiles, libraryChosenItems]);

  return (
    <LibraryModal className={"p-0"} centered isOpen={isOpen} size={"lg"}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={close}>
            <i className="fas fa-times" />
          </button>
        }
      >
        {isEditingMode ? "Edit " : "Create "} lesson note
      </LibraryModalHeader>
      <LibraryModalBody>
        <div
          className="d-flex mb-3 align-items-end justify-content-between flex-wrap"
          style={{ gap: 30 }}
        >
          <div className="flex-grow-1" style={{ flex: 1 }}>
            <span className="fw-bold me-2 ">
              Date: <RequiredInputSpan>*</RequiredInputSpan>
            </span>

            <CalendarPickerWrapper className="mt-3">
              <SingleDatePicker
                date={date} // momentPropTypes.momentObj or null
                onDateChange={setDate}
                focused={isDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </div>
          <div style={{ flex: 1 }}>
            <SearchSelect
              name="students"
              placeholder={"Student(s)"}
              options={studentsOptions}
              onOptionSelect={(e) => {
                setSelectedStudents(e);
              }}
              selectedOption={selectedStudents}
              isMulti={true}
              width="100%"
              minWidth="230px"
            />
          </div>
        </div>
        <LibraryModalInput
          isRequired
          formik={false}
          label={`Title`}
          name="Title"
          placeholder="Title"
          height={"40px"}
          size={12}
          value={noteTitle}
          customStyle={{
            marginBottom: 5,
          }}
          onChange={(e) => setNoteTitle(e.target.value)}
        />
        <div className="w-100 mb-3">
          <label className="noteLabel ms-0 mb-2" htmlFor="note">
            Note <RequiredInputSpan>*</RequiredInputSpan>
          </label>
          <CustomRichTextEditor
            onEditorValueChange={(value) => {
              setNoteDescription(value);
            }}
            editorValue={noteDescription}
          />
        </div>
        <div>
          <FilesPreview files={combinedPreviewFiles} onRemove={removeFile} />
        </div>
        <div className="text-center mb-4">
          <PrimaryButton onClick={libraryItemsModalData.openModal}>
            Choose From Library
          </PrimaryButton>
        </div>
        <hr />
        <p className="text-center">OR</p>
        <hr />
        <div>
          <UploadFromDeviceSection
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            recommendationLink={recommendationLink}
            setRecommendationLink={setRecommendationLink}
          />
        </div>

        <div
          className={
            "mt-5 d-flex align-items-center justify-content-between pt-2 flex-wrap"
          }
          style={{ gap: 10 }}
        >
          {!isEditingMode ? (
            <ButtonContainer>
              <LibraryModalButton
                width="100%"
                type={"submit"}
                onClick={() => submit(true)}
                disabled={isLoading || isDisabled()}
                className={`d-flex font-weight-bold px-5  me-2 ${
                  isDisabled() && "disabled"
                } text-white align-items-center justify-content-center`}
              >
                {isLoading ? (
                  <Spinner style={{}} size="sm" />
                ) : (
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    <span>Save as draft</span>
                  </div>
                )}
              </LibraryModalButton>
            </ButtonContainer>
          ) : null}
          <ButtonContainer>
            <LibraryModalButton
              width="100%"
              type={"submit"}
              onClick={() => submit(false)}
              disabled={isLoading || isDisabled()}
              className={`d-flex font-weight-bold px-5  ${
                isDisabled() && "disabled"
              } text-white align-items-center justify-content-center`}
            >
              {isLoading ? (
                <Spinner style={{}} size="sm" />
              ) : (
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <span>{isEditingMode ? "Update" : "Publish"}</span>
                </div>
              )}
            </LibraryModalButton>
          </ButtonContainer>
        </div>
        {libraryItemsModalData.isModalOpen && (
          <ChooseFromLibraryModal
            libraryChosenItems={libraryChosenItems}
            modalData={libraryItemsModalData}
            addChosenLibraryItems={addChosenLibraryItems}
          />
        )}
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default AddLessonNoteModal;
