import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const InfoText1 = styled.p`
  margin: 0;
  font-weight: 900;
  font-size: 0.8rem;
  margin-bottom: 5px;
  @media (max-width: ${ScreenSize.SM}px) {
    font-size: 0.6rem;
  }
`;
export const InfoText2 = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: normal;
  @media (max-width: ${ScreenSize.SM}px) {
    font-size: 1rem;
  }
`;
