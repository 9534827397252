import { v4 as uuidv4 } from "uuid";

export const concertSignupOptions = {
  FREE_FOR_ALL_STUDENTS: 1,
  LIMITED: 2,
};
export const concertLimitedSignupOptionTypes = {
  TEACHER_RECOMMENDATION: 1,
  SCHOOL_RECOMMENDATION: 2,
  STUDENT_SIGNUP: 3,
};

// // this is different from the concert invitation request types
// export const concertSignupTypes = {
//   TEACHER_RECOMMENDATION: 1,
//   SCHOOL_RECOMMENDATION: 2,
//   FREE_FOR_ALL_STUDENTS: 3,
//   STUDENT_SIGNUP: 4,
// };

export function isFreeForAllStudentsConcert(signupOption) {
  return signupOption === concertSignupOptions.FREE_FOR_ALL_STUDENTS;
}
export function isTeacherRecommendationConcert(signupTypes) {
  if (typeof signupTypes === "object") {
    return !!signupTypes?.includes(
      concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION
    );
  } else {
    return (
      signupTypes === concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION
    );
  }
}
export function isSchoolRecommendationConcert(signupTypes) {
  if (typeof signupTypes === "object") {
    return !!signupTypes?.includes(
      concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION
    );
  } else {
    return (
      signupTypes === concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION
    );
  }
}

export function isStudentSignupConcert(signupTypes) {
  if (typeof signupTypes === "object") {
    return !!signupTypes?.includes(
      concertLimitedSignupOptionTypes.STUDENT_SIGNUP
    );
  } else {
    return signupTypes === concertLimitedSignupOptionTypes.STUDENT_SIGNUP;
  }
}
export const concertProgramTypes = {
  SOLO_PROGRAM: 1,
  SPECIAL_PROGRAM: 2,
};

export const concertInvitationStatuses = {
  // FIRST_STAGE: 1,
  // SECOND_STAGE: 2,
  // THIRD_STAGE: 3,
  // FOURTH_STAGE: 4,
  TEACHER_RECOMMENDATION_CREATED: 1,
  TEACHER_RECOMMENDATION_ADMIN_ACCEPTED: 2,
  TEACHER_RECOMMENDATION_ADMIN_DECLINED: 3,

  TEACHER_RECOMMENDATION_USERS_ACCEPTED: 4,
  TEACHER_RECOMMENDATION_USERS_DECLINED: 5,
};

export const concertLevels = {
  ALL: 1,
  BEGINNER: 2,
  INTERMEDIATE: 3,
  ADVANCED: 4,
};

export const concertAgeGroupTypes = {
  ANY: 1,
  LIMITED: 2,
};
export const concertTeachingStudioTypes = {
  ALL: 1,
  SPECIFIC: 2,
};

export const concertProgramSoloPerformanceTypes = {
  SOLO: 1,
  SOLO_WITH_ACCOMPANIMENT: 2,
  OTHER: 3,
};

export const concertInitialEquipment = [
  {
    id: uuidv4(),
    name: "Microphone (Cord/Cordless)",
    isAvailable: true,
  },
  {
    id: uuidv4(),
    name: "Chair",
    isAvailable: true,
  },
  {
    id: uuidv4(),
    name: "Music Stand",
    isAvailable: true,
  },
  {
    id: uuidv4(),
    name: "Pedal Extension",
    isAvailable: true,
  },
  {
    id: uuidv4(),
    name: "Sound System",
    isAvailable: true,
  },
];

export const concertPaymentTypes = {
  FREE: 1,
  PAID: 2,
};
export const concertInstrumentTypes = {
  ALL: 1,
  LIMITED: 2,
};
