import styled from "styled-components";
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";

export const CustomModal = styled(Modal)`
  min-width: 800px;
  max-width: 800px;

  & .modal-content {
    border: none;
    border-radius: 30px 30px;
  }
`;
export const CustomHeader = styled(ModalHeader)`
  border-radius: 30px 30px 0 0;
`;
export const CustomBody = styled(ModalBody)`
  margin-top: 20px;
  min-height: 200px;
  padding: 50px !important;
  margin: 0 !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

export const CustomLabel = styled(Label)`
  display: block;
  color: black;
`;

export const CustomSelectField = styled.select`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "auto")};
  outline: none;
  padding: 7px;
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 7px;

  &:disabled {
    background-color: #e6e6e6;
  }
`;
export const CustomInput = styled.input`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "auto")};
  outline: none;
  padding: 7px;
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  &:disabled {
    background-color: #e6e6e6;
  }
`;

export const AvailabilitiesContainer = styled.div`
  background-color: #f3f3f3cc;
  padding: 15px;
  border-radius: 7px;
`;

export const CustomTextarea = styled.textarea`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "100%")};
  min-height: 115px;
  outline: none;
  padding: 7px;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  &:disabled {
    background-color: #e6e6e6;
  }
`;
