import React, { useMemo } from "react";
import { SectionContainer } from "../../styled";
import { getPaypalOrderTotal } from "src/utils/helpers";

const PaypalDirectPaymentDetails = ({ paypalOrderDetails }) => {
  const { purchase_units } = paypalOrderDetails || {};

  const paymentTotal = useMemo(() => {
    return getPaypalOrderTotal(purchase_units);
  }, [paypalOrderDetails]);

  if (!paypalOrderDetails) {
    return <h3>Paypal order details are missing</h3>;
  }
  return (
    <SectionContainer>
      <h5>Direct Payment (Via Paypal)</h5>
      <hr />

      <SectionContainer className="mt-3">
        <p>
          Amount: <span className="ms-2">${paymentTotal}</span>
        </p>
      </SectionContainer>
    </SectionContainer>
  );
};

export default PaypalDirectPaymentDetails;
