import moment from "moment";
import UnknownProfilePhoto from "../../../../../../assets/images/default-profile.jpg";
import {
  availableDaysToString,
  combineArrayToStr,
  instrumentsToString,
} from "../../../../../../utils/helpers";
import styled from "styled-components";
import {
  ActionButtonsContainer,
  Header,
  ImageContainer,
  InfoText,
  ProfileImage,
} from "../styled";
import { ModeEdit } from "styled-icons/material";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";

const MakeupLessonEventContent = ({
  event,
  initialData,
  studioUsages,
  userRole,
  openEditModal,
}) => {
  const { locations, instruments, studios } = initialData;

  const { student, start, end, eventCode } = event || {};

  const teacher = event.teacher;
  const teacherId = teacher?.id || teacher?.uid;

  const instrument = instruments[event.instrumentId];

  const locationName = locations[event.locationId]?.name;
  const studentName = student?.fullName;

  const formattedDate =
    moment(start).format("dddd, MMMM D: h:mm a - ") +
    moment(end).format("h:mm a");

  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );

  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  const teacherStudioUsageInCurrentDate = studioUsages?.find((studioUsage) => {
    const isSameTeacher = teacherId === studioUsage.teacher_id;
    const isSameDate =
      moment(event.start).year() === studioUsage.year &&
      moment(event.start).month() + 1 === studioUsage.month &&
      moment(event.start).format("dddd") === studioUsage.weekday;

    return isSameTeacher && isSameDate;
  });
  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;

  return (
    <div className="p-5 pt-1">
      <ActionButtonsContainer>
        {hasAdminCredentials(userRole) && (
          <ModeEdit
            onClick={openEditModal}
            style={{ cursor: "pointer" }}
            size={30}
          />
        )}
      </ActionButtonsContainer>
      {!!student && (
        <div className="d-flex mt-4 mb-3">
          <ImageContainer>
            <ProfileImage src={student?.imageUrl || UnknownProfilePhoto} />
          </ImageContainer>
          <div>
            <Header>
              {studentName} {`(${instrument?.name})`}
            </Header>
            <InfoText>{formattedDate}</InfoText>
          </div>
        </div>
      )}

      <div className="d-flex">
        <ImageContainer>
          <ProfileImage src={teacher?.imageUrl || UnknownProfilePhoto} />
        </ImageContainer>
        <div>
          <Header>
            {teacher?.fullName}{" "}
            {`(${teachingDaysStr}) (${teachersInstrumentsStr})`}
          </Header>
          <InfoText>{locationName || "No Location found"}</InfoText>
          <InfoText>{studioName || "No Studio found"}</InfoText>
        </div>
      </div>
    </div>
  );
};

export default MakeupLessonEventContent;
