import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import PaymentInfoBody from "./PaymentInfoBody";

const ViewPaymentInfoModal = ({
  modalData,
  paymentObj,
  onPaymentDeleteSuccess = () => {},
}) => {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>Payment Info</h2>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <PaymentInfoBody
          paymentObj={paymentObj}
          modalData={modalData}
          onPaymentDeleteSuccess={onPaymentDeleteSuccess}
        />
        <div className="mt-5"></div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ViewPaymentInfoModal;
