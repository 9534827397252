import { useRef } from "react";
import html2canvas from "html2canvas";

import {
  AttachmentButton,
  AttachmentOverlayWrapper,
  ButtonsColumn,
} from "./styled";

const AttachmentOverlay = ({ isOpen, onCancel, onUpload, onScreenshot }) => {
  const uploadRef = useRef(null);

  const handleScreenshot = async () => {
    try {
      await onScreenshot();
      const canvas = await html2canvas(document.body);
      const blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpg", 1)
      );

      onUpload(blob);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AttachmentOverlayWrapper visible={isOpen}>
      <h4>Attach file</h4>
      <ButtonsColumn>
        <AttachmentButton onClick={handleScreenshot}>
          Snap screenshot
        </AttachmentButton>
        <input
          ref={uploadRef}
          hidden
          type="file"
          accept="image/*"
          onChange={({ target }) => onUpload(target?.files[0])}
        />
        <AttachmentButton onClick={() => uploadRef?.current?.click()}>
          Choose a file
        </AttachmentButton>
        <AttachmentButton onClick={onCancel}>Cancel</AttachmentButton>
      </ButtonsColumn>
    </AttachmentOverlayWrapper>
  );
};

export default AttachmentOverlay;
