import styled from "styled-components";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { EditAlt } from "@styled-icons/boxicons-regular/EditAlt";

export const DeleteIcon = styled(DeleteOff)`
  height: 25px;
  width: 25px;
  /* color: #db0000; */
  cursor: pointer;
`;
export const EditIcon = styled(EditAlt)`
  height: 25px;
  width: 25px;
  /* color: #1800db; */
  cursor: pointer;
`;

export const TimelineContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  border-top: ${({ borderTop }) => borderTop || "none"};
`;
export const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;
