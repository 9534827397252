import React, { useState , useEffect} from "react";
import { Dropdown, DropdownItem } from "reactstrap";
import styled from "styled-components";
import {
  CustomDropDownMenu,
  CustomDropdownToggle,
  CustomLabel,
} from "../../utils/styled";
import "./style.css";
const TimeDropDown = ({
  options,
  keyName,
  renderError,
  onOptionClick,
  className,
  headerClassName = "",
  size,
  header,
  label,
  toggleClassName,
  value,
  CheckDisable,
  CheckLabel,
  menuClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(!CheckLabel ? value ?? "" :  "");
  useEffect(() => {
    if (!CheckLabel) {
      setActiveOption("")
    }
  }, [CheckLabel]);
  return (
    <div
      // style={{ flexDirection: "column" }}
      // className={className ?? (!size ? "col-12" : `col-${size}`)}
      className="w-100"
    >
      {header && <CustomLabel className="mb-3">{header}</CustomLabel>}
      <Dropdown
        className="w-100 dropdownbtn"
        disabled={CheckDisable}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        <CustomDropdownToggle
          className={`w-100 dropdownButton ${headerClassName}`}
          id={`custom-dropdown-${isOpen ? "open" : ""}`}
          isOpen={isOpen}
          caret
        >
          {/* {value ? value : label} */}
          {!activeOption ? label : activeOption}
        </CustomDropdownToggle>
        {/**className={"w-100"} */}
        <CustomDropDownMenu style={{}} className={"w-100"}>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={(e) => {
                e.preventDefault();
                onOptionClick(option);
                setActiveOption(keyName ? option[keyName] : option);
              }}
              active={option == activeOption}
            >
              {`${keyName ? option[keyName] : option}`}
            </DropdownItem>
          ))}
        </CustomDropDownMenu>
      </Dropdown>
      {renderError}
    </div>
  );
};
export default TimeDropDown;
{
  /**
   * const HandelLessonTime = (lessontime) => {
    const DateFormat = moment(lesson["date"]?.lessonDate)
      .format("YYYY MM DD")
      .toString();
    const StartTimeMainFormat = moment(DateFormat + " " + lessontime).toDate();
    const Duration = lesson["date"]?.lessonLength.replace(/\D/g, "");
    if (StartTimeMainFormat) {
      const EndTimeFormat = moment(lessontime, "hh:mm A")
        .add(Duration, "minutes")
        .format("HH:mm:ss a");
      const EndTimeMainFormat = moment(
        DateFormat + " " + EndTimeFormat,
        "YYYY-MM-DD HH:mm A UTC"
      ).toDate();
      const EndTimeTimeStamp = new Timestamp.fromDate(EndTimeMainFormat);
      const StartTimeTimeStamp = new Timestamp.fromDate(StartTimeMainFormat);
      updateLessonDate("startDate", StartTimeTimeStamp);
      updateLessonDate("endDate", EndTimeTimeStamp);
  };
   *
*/
}
