import React, { useMemo } from "react";
import { SectionContainer } from "../../styled";
import moment from "moment";
import {
  groupClassPaymentOptionsMap,
  paymentOptionMethods,
} from "src/constants/groupClassPaymentsConstants";
import { getGroupClassPaymentOptionsTotal } from "src/utils/helpers";

const PaypalScheduledPaymentDetails = ({
  paymentOption,
  paypalSubscriptionDetails,
}) => {
  const {
    status,
    billing_info: {
      cycle_executions, //arr
      failed_payments_count,
      final_payment_time,
      next_billing_time,
      last_payment,
      last_failed_payment,
    },
  } = paypalSubscriptionDetails || {};
  const {
    time: latestPaymentTime,
    amount: { value: latestPaymentAmount },
  } = last_payment || { amount: { value: undefined } };

  const {
    reason_code,
    amount,
    time: latestFailedPaymentTime,
    next_payment_retry_time,
  } = last_failed_payment || {};

  // cycles_remaining is 0 for infinite
  const { cycles_completed, cycles_remaining, total_cycles } =
    cycle_executions[0];

  const nextBillingTime = next_billing_time
    ? moment(next_billing_time).format("MM-DD-YYYY")
    : "N/A";

  const finalPaymentTime = final_payment_time
    ? moment(final_payment_time).format("MM-DD-YYYY")
    : "N/A";
  const formattedLatestPaymentTime = latestPaymentTime
    ? moment(latestPaymentTime).format("MM-DD-YYYY")
    : "N/A";

  const nextPaymentRetryTime = next_payment_retry_time
    ? moment(latestPaymentTime).format("MM-DD-YYYY")
    : "N/A";
  const formattedLatestFailedPaymentTime = latestFailedPaymentTime
    ? moment(latestFailedPaymentTime).format("MM-DD-YYYY")
    : "N/A";

  // payment option details
  const setupPaymentOptions = useMemo(() => {
    const setupOptions = paymentOption?.optionPayments?.filter(({ type }) => {
      const map = Object.values(groupClassPaymentOptionsMap).find(
        (option) => type === option.value
      );
      const isSetup = map.method === paymentOptionMethods.SETUP;
      return isSetup;
    });
    return setupOptions;
  }, [paymentOption]);
  const setupTotal = getGroupClassPaymentOptionsTotal(setupPaymentOptions);
  const scheduledPaymentOptions = useMemo(() => {
    const scheduledOptions = paymentOption?.optionPayments?.filter(
      ({ type }) => {
        const map = Object.values(groupClassPaymentOptionsMap).find(
          (option) => type === option.value
        );
        const isScheduled = map.method === paymentOptionMethods.SCHEDULED;
        return isScheduled;
      }
    );
    return scheduledOptions;
  }, paymentOption);
  const scheduledTotal = getGroupClassPaymentOptionsTotal(
    scheduledPaymentOptions
  );
  if (!paypalSubscriptionDetails) {
    return <h3>Paypal subscription details are missing</h3>;
  } else if (!paymentOption) {
    return <h3>Payment Option is missing</h3>;
  }

  return (
    <SectionContainer>
      <h5>Scheduled Payment (Via Paypal)</h5>
      <hr />

      <SectionContainer className="mt-3">
        <p>
          Setup Amount: <span className="ms-2">${setupTotal}</span>
        </p>
        <p>
          Installment Amount: <span className="ms-2">${scheduledTotal}</span>
        </p>
        <p>
          status: <span className="ms-2">{status}</span>
        </p>
        <p>
          Total cycles: <span className="ms-2">{total_cycles}</span>
        </p>
        <p>
          Cycles completed: <span className="ms-2">{cycles_completed}</span>
        </p>
        <p>
          Cycles remaining: <span className="ms-2">{cycles_remaining}</span>
        </p>

        <p>
          Next billing time:
          <span className="ms-2">{nextBillingTime}</span>
        </p>
        <p>
          Final payment time:
          <span className="ms-2">{finalPaymentTime}</span>
        </p>
        {/* <p>
          Latest Payment Amount:
          <span className="ms-2">${latestPaymentAmount}</span>
        </p> */}
        <p>
          Latest payment time:
          <span className="ms-2">{formattedLatestPaymentTime}</span>
        </p>
        <p>
          Failed payments count:
          <span className="ms-2">{failed_payments_count}</span>
        </p>

        {last_failed_payment ? (
          <>
            <h5>Failed Payment:</h5>
            <hr />
            <SectionContainer>
              <p>
                Reason:
                <span className="ms-2">
                  {last_failed_payment?.reason_code || "N/A"}
                </span>
              </p>
              <p>
                Latest failed payment time:
                <span className="ms-2">{formattedLatestFailedPaymentTime}</span>
              </p>
              <p>
                Next failed payment retry time:
                <span className="ms-2">{nextPaymentRetryTime}</span>
              </p>
            </SectionContainer>
          </>
        ) : null}
      </SectionContainer>
    </SectionContainer>
  );
};

export default PaypalScheduledPaymentDetails;
