import { Interweave } from "interweave";
import moment from "moment";
import React from "react";
import { useFeedSectionContext } from "../../../../contexts/FeedSectionContext";
import { InfoSpan1, InfoSpan2 } from "../../../../styled";
import { BodyContainer } from "../../styled";

const InfoSection = ({ feedItem }) => {
  const { feedUsers } = useFeedSectionContext();
  const { body, title, publishDate, createdBy } = feedItem;
  const formattedPublishDate = publishDate
    ? moment(publishDate).format("MM/DD/YYYY")
    : "N/A";
  const createdByUser = feedUsers.find(({ id }) => createdBy === id);
  const createdByFullName = createdByUser?.fullName;
  return (
    <div>
      <div>
        <h5 className="text-center mb-3">{title}</h5>
      </div>
      <BodyContainer>
        <Interweave content={body} />
      </BodyContainer>
      <div className="mt-3">
        <p className="mb-1">
          <span>
            <InfoSpan1>Published On:</InfoSpan1>{" "}
            <InfoSpan2>{formattedPublishDate}</InfoSpan2>
          </span>
        </p>
        <p className="mb-0">
          <span>
            <InfoSpan1>Published By:</InfoSpan1>{" "}
            <InfoSpan2>{createdByFullName || "N/A"}</InfoSpan2>
          </span>
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
