import React, { useContext } from "react";
import styled from "styled-components";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import { Col, FormGroup } from "reactstrap";
import { SearchContext } from "./StudentList";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
const SearchandAddContainer = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: ${ScreenSize.SM}px) {
    width: 230px;
  }
`;
const Searchicon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;
function SearchContainer() {
  const { searchUser, setsearchUser } = useContext(SearchContext);
  return (
    <SearchandAddContainer className="mt-5 mx-3 flex-lg-row">
      <Col>
        <FormGroup className="searchContainer">
          <input
            placeholder="Search by Student"
            className="searchInput"
            value={searchUser}
            onChange={(e) => setsearchUser(e.target.value)}
          />
          <Searchicon />
        </FormGroup>
      </Col>
    </SearchandAddContainer>
  );
}

export default SearchContainer;
