import useArtisCalendar from "./hooks/useArtisCalendar";
import "./style.css";
import { calendarsList } from "./constants";
import { PrimaryButton } from "src/utils/shared/styled";

function ArtisCalendar() {
  const { selectedCalendar, setSelectedCalendarValue } = useArtisCalendar();
  const { src, downloadSrc } = selectedCalendar || {};

  return (
    <div>
      <div className="d-flex mb-4" style={{ gap: 20 }}>
        {calendarsList.map(({ title, value }, index) => {
          return (
            <PrimaryButton
              width="160px"
              onClick={() => setSelectedCalendarValue(value)}
              key={index}
            >
              {title}
            </PrimaryButton>
          );
        })}
      </div>
      <figure className={"artis-calendar"}>
        <picture>
          <source media="(min-width: 1066px)" srcSet={src} />
          <img loading="lazy" src={src} alt="calendar" />
        </picture>
        <a href={downloadSrc} download>
          Click Here to Download
        </a>
      </figure>
    </div>
  );
}

export default ArtisCalendar;
