import { LessonNoteContainer } from "../../common/styled-components/lessonNotesStyledComponents";
import {
  StyledLink,
  TextButton,
} from "../../common/styled-components/teacherLibraryStyledComponents";
import { useContext, useState } from "react";
import {
  LessonDetailsModalContext,
  LessonNotesContext,
  ShareItemModalContext,
  SubmitHomeworkModalContext,
  TeachersContext,
  ViewHomeworkModalContext,
  ViewItemModalContext,
  ViewNoteModalContext,
} from "../../common/Library/common/libraryContext";
import moment from "moment";
import { Spinner } from "reactstrap";
import NoteAttachmentsList from "./NoteAttachmentsList";
import { UserRole } from "src/constants/UserRoleEnum";
import { isLessonNoteSharedWithUser } from "src/utils/helpers";
import { Interweave } from "interweave";

const LessonNote = ({ note }) => {
  const {
    isEditable,
    showAttachmentsModal,
    editNote,
    deleteNote,
    deleteAttachment,
    publishNote,
    userId,
    userRole,
  } = useContext(LessonNotesContext);

  const isSharedNote = isLessonNoteSharedWithUser(userId, note);

  const isAdminOrTeacher = isEditable;
  const isHwSubmittedForAdminsAndTeachers =
    Object.values(note.homework || {}).length > 0 && isAdminOrTeacher;
  const isHomeworkSubmittedForStudent =
    note?.homework?.[userId] && !isAdminOrTeacher;

  const { teachers } = useContext(TeachersContext);

  const isStudent = userRole === UserRole.STUDENT;

  const { openShareModal } = useContext(ShareItemModalContext);
  const { openHomework, selectedUser, setSelectedUser } = useContext(
    ViewHomeworkModalContext
  );
  const { open: openSubmitHomework } = useContext(SubmitHomeworkModalContext);
  const { open } = useContext(ViewNoteModalContext);
  const { open: previewAttachment } = useContext(ViewItemModalContext);
  const { open: openLessonDetails } = useContext(LessonDetailsModalContext);
  const [isPublishPressed, setIsPublishPressed] = useState(false);

  const teacher = teachers?.find(({ id }) => id === note.teacherId);
  return (
    <LessonNoteContainer>
      <div
        className={
          "mb-3 d-flex flex-wrap align-items-center justify-content-between"
        }
        style={{ gap: "15px" }}
      >
        <div
          className="d-flex align-items-center flex-wrap"
          style={{ gap: 15 }}
        >
          <TextButton className={"ps-0 ms-0"} onClick={() => open(note)}>
            <h5 className={"h3 flex-grow-1 mb-0"}>
              Lesson {moment(note.date.toDate()).format("MM-DD-YYYY")}
              {isStudent && (
                <span
                  className={
                    "px-1 py-1 text-dark badge rounded border border-dark border-2 ms-2 fs-6"
                  }
                >
                  <strong>{teacher?.fullName || "Unknown"}</strong>
                </span>
              )}
            </h5>
          </TextButton>
          {isSharedNote && (
            <span
              className={
                "fs-6 px-2 py-1 text-dark badge rounded border border-dark border-2 ms-2"
              }
            >
              <strong style={{ color: "#5a62ffc7" }}>shared with you</strong>
            </span>
          )}
          {note.isEdited ? (
            <span className="text-muted text-sm">
              <em>Edited</em>
            </span>
          ) : null}
        </div>

        <div className={"d-flex align-items-center justify-content-center"}>
          {note.studentsIds.length > 0 ? (
            isHomeworkSubmittedForStudent ||
            isHwSubmittedForAdminsAndTeachers ? (
              <TextButton
                className={"text-purple px-2"}
                onClick={() => {
                  openHomework(note.homework, note);
                }}
              >
                <i className="fa fa-file-word" /> HW Received
              </TextButton>
            ) : isAdminOrTeacher ? null : (
              <TextButton
                className={"px-2 text-purple"}
                onClick={() => {
                  if (selectedUser?.id !== note.teacherId) {
                    setSelectedUser(note.teacherId);
                  }
                  openSubmitHomework(note.id);
                }}
              >
                <i className="fa fa-check" /> Submit Homework
              </TextButton>
            )
          ) : null}
          <TextButton
            className={"px-2 text-purple"}
            onClick={() => {
              open(note);
            }}
          >
            <i className="fa fa-eye" />
          </TextButton>
          {/* <TextButton className={"text-purple px-2"} onClick={() => {}}>
            <i className="fa fa-book-open" />
          </TextButton> */}
          {!isStudent && !isSharedNote && (
            <TextButton
              className={"px-2 text-purple"}
              onClick={() => {
                openShareModal(note.id);
              }}
            >
              <i className="fa fa-share" />
            </TextButton>
          )}
          {isEditable ? (
            <>
              <TextButton
                className={"px-2 text-purple"}
                onClick={() => {
                  editNote({ ...note, date: note.date.toDate() });
                }}
              >
                <i className="fa fa-pen" />
              </TextButton>
              {!isSharedNote && (
                <TextButton
                  className={"px-2 text-danger"}
                  onClick={() => {
                    deleteNote(note.id);
                  }}
                >
                  <i className="fa fa-trash" />
                </TextButton>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className={"mb-3"}>
        <h4 className={"text-capitalize"}>{note.title}</h4>
        <p className={"note-description"}>
          <Interweave content={note.description} />
        </p>
        {note.isDraft && isEditable ? (
          <div className="alert alert-info">
            This note is drafted
            <button
              style={{ backgroundColor: "purple", color: "white" }}
              className="ms-2 btn"
              onClick={() => {
                editNote({ ...note, date: note.date.toDate() });
              }}
            >
              Edit
            </button>
            <button
              disabled={isPublishPressed}
              onClick={async () => {
                setIsPublishPressed(true);
                await publishNote(note.id);
              }}
              className="ms-2 btn btn-success"
            >
              Publish {isPublishPressed ? <Spinner size={"sm"} /> : null}
            </button>
          </div>
        ) : null}
      </div>
      <div className={"flex-wrap mb-3 d-flex align-items-center"}>
        {isEditable ? (
          <TextButton
            className={"me-2"}
            onClick={() => {
              showAttachmentsModal(note.id);
            }}
          >
            Upload File <i className="fa ms-2 fa-upload" />
          </TextButton>
        ) : null}
        <NoteAttachmentsList note={note} />
      </div>
    </LessonNoteContainer>
  );
};
export default LessonNote;
