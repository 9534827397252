/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
import React, { useContext, useRef, useState, useEffect } from "react";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import QRCode from "qrcode";
import useModal from "src/hooks/useModal";
import FirstStageModal from "./FirstStageModal";
import SecondStageModal from "./SecondStageModal";
import CreatePrivateLessonModal from "../CreatePrivateLessonModal";
import { GENERATE_NEW_USER_ID } from "src/constants/ApiEndpoints";
import axios from "axios";
import { UserRole } from "src/constants/UserRoleEnum";
import { toast } from "react-toastify";

const Closeicon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

const studentInitialData = {
  systemID: "",
  fullName: "",
  birthday: "",
  gender: "",
  enrollmentDate: "",
  primaryEmail: "",
  primaryContactEmail: "",
  primaryContactName: "",
  primaryPhone: "",
  secondaryContactEmail: "",
  secondaryContactName: "",
  secondPhone: "",
  homeAddress: "",
  state: "",
  city: "",
  zip: "",

  imageUrl: "",
  qrCodeImageUrl: "",
};

const AddPrivateStudentModal = ({
  isOpen,
  onAddStudentSuccess,
  onClose,
  instruments,
  locations,
  teachers,
  programs,
  providedStudentInitialValues, // for when creating a pl student from tl student
}) => {
  const createPLModalData = useModal();
  const secondStageModalData = useModal();

  const [uploading, setUploading] = useState(false);

  const [studentInfo, setStudentInfo] = useState(studentInitialData);

  const {
    name,
    birthday,
    primaryContactEmail,
    primaryContactName,
    primaryPhone,
    secondaryContactEmail,
    secondaryContactName,
    secondPhone,
    gender,
    enrollmentDate,
    homeAddress,
    state,
    city,
    zip,
  } = providedStudentInitialValues || {};
  // makes sure we use provided initial data if given
  useEffect(() => {
    if (!providedStudentInitialValues) return;
    console.log("Setting Provided initial values");
    setStudentInfo(providedStudentInitialValues);
  }, [
    enrollmentDate,
    gender,
    name,
    birthday,
    primaryContactEmail,
    primaryContactName,
    primaryPhone,
    secondaryContactEmail,
    secondaryContactName,
    secondPhone,
    homeAddress,
    state,
    city,
    zip,
  ]);

  const [currentUserID, setcurrentUserID] = useState("");
  const uploadRef = useRef();
  const [selectedSystemIDLocation, setSelectedSystemIDLocation] = useState("");

  const firstStageInitialFormData = {
    fullName: studentInfo.fullName,
    systemID: studentInfo.systemID,
  };
  const secondStageInitialFormData = {
    birthday: studentInfo.birthday,
    gender: studentInfo.gender,
    enrollmentDate: studentInfo.enrollmentDate,
    primaryEmail: studentInfo.primaryEmail,
    primaryContactEmail: studentInfo.primaryContactEmail,
    primaryContactName: studentInfo.primaryContactName,
    primaryPhone: studentInfo.primaryPhone,
    secondaryContactEmail: studentInfo.secondaryContactEmail,
    secondaryContactName: studentInfo.secondaryContactName,
    secondPhone: studentInfo.secondPhone,
    homeAddress: studentInfo.homeAddress,
    state: studentInfo.state,
    city: studentInfo.city,
    zip: studentInfo.zip,
  };

  const resetStudentInfo = () => {
    setStudentInfo(studentInitialData);
  };

  useEffect(() => {
    if (locations?.length) {
      setSelectedSystemIDLocation(locations[0]?.id);
    }
  }, []);

  useEffect(() => {
    if (selectedSystemIDLocation && isOpen) {
      const getNewStudentID = async () => {
        try {
          const { data } = await axios.post(GENERATE_NEW_USER_ID, {
            userRole: UserRole.STUDENT,
            locationId: selectedSystemIDLocation,
          });
          if (data) {
            setStudentInfo((oldVal) => ({
              ...oldVal,
              systemID: data.nextUserFullID,
            }));
          }
        } catch (err) {
          setStudentInfo((oldVal) => ({
            ...oldVal,
            systemID: "",
          }));
          console.log(err);
          toast.error("Couldnt generate user id");
        }
      };
      getNewStudentID();
    }
  }, [selectedSystemIDLocation, isOpen]);
  useEffect(() => {
    QRCode.toDataURL(studentInfo.systemID).then((val) =>
      setStudentInfo((oldVal) => ({ ...oldVal, qrCodeImageUrl: val }))
    );
  }, [studentInfo.systemID]);

  const FirstStageComponent = (
    <FirstStageModal
      isOpen={isOpen}
      firstStageInitialFormData={firstStageInitialFormData}
      studentInfo={studentInfo}
      setStudentInfo={setStudentInfo}
      onClose={onClose}
      secondStageModalData={secondStageModalData}
      selectedSystemIDLocation={selectedSystemIDLocation}
      setSelectedSystemIDLocation={setSelectedSystemIDLocation}
      locations={locations}
    />
  );

  const SecondStageComponent = (
    <SecondStageModal
      uploadRef={uploadRef}
      secondStageInitialFormData={secondStageInitialFormData}
      studentInfo={studentInfo}
      setStudentInfo={setStudentInfo}
      secondStageModalData={secondStageModalData}
      setUploading={setUploading}
      uplodaing={uploading}
      onAddStudentSuccess={onAddStudentSuccess}
      setcurrentUserID={setcurrentUserID}
      createPLModalData={createPLModalData}
      resetStudentInfo={resetStudentInfo}
    />
  );

  const ThirdStageModal = (
    <CreatePrivateLessonModal
      studentId={currentUserID}
      modalData={createPLModalData}
      providedInitialData={{
        instruments,
        locations,
        programs,
        teachers,
      }}
    />
  );

  return (
    <>
      {FirstStageComponent}
      {secondStageModalData.isModalOpen ? SecondStageComponent : null}
      {createPLModalData.isModalOpen ? ThirdStageModal : null}
    </>
  );
};
export default AddPrivateStudentModal;
