import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  PDFViewerContainer,
  TextButton,
} from "../../styled-components/teacherLibraryStyledComponents";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

const StyledPDFPage = styled(Page)`
  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;
const PDFViewer = ({ file, title = "" }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const showNavButtons = numPages && numPages > 1;
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <PDFViewerContainer className={"mx-auto position-relative text-black"}>
      <Document
        width={"100%"}
        file={file}
        className={"d-flex justify-content-center"}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <StyledPDFPage pageNumber={pageNumber} />
      </Document>
      {showNavButtons && (
        <div className="d-flex w-100 h-100 top-0 position-absolute align-items-center justify-content-between">
          <TextButton
            style={{ backgroundColor: "#f5f5f5" }}
            onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)}
          >
            <i className="fa fa-chevron-left" />
          </TextButton>
          <TextButton
            style={{ backgroundColor: "#f5f5f5" }}
            onClick={() =>
              setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)
            }
          >
            <i className="fa fa-chevron-right" />
          </TextButton>
        </div>
      )}
      {pageNumber && (
        <div className="mt-3">
          <p className="text-center">Page {pageNumber}</p>
        </div>
      )}
    </PDFViewerContainer>
  );
};
export default PDFViewer;
