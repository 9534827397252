import React from "react";
import RedbarModal from "../RedbarModal";
import AddStudentBody from "./AddStudentBody";
import { CustomButton, CustomButtonsWrapper } from "./styled";
import useAddStudent from "./hooks/useAddStudent";
import { enrollmentComponents } from "./constants";

const AddStudentToGroupClassModal = ({
  groupClassId,
  redbarTitle,
  overflowRedbarContent,
  modalData,
}) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  const hookData = useAddStudent({ groupClassId });

  const { handleNextStep, currentComponent } = hookData;

  const renderFooterContent = () => {
    if (currentComponent === enrollmentComponents.PAYMENT) {
      return <div></div>;
    } else {
      return (
        <div>
          <CustomButtonsWrapper>
            <CustomButton onClick={closeModal}>Cancel</CustomButton>|
            <CustomButton primary onClick={handleNextStep}>
              Next
            </CustomButton>
          </CustomButtonsWrapper>
        </div>
      );
    }
  };
  return (
    <RedbarModal
      renderFooterSection={renderFooterContent}
      modalData={modalData}
      modalHeader={"Add Student"}
      redbarTitle={redbarTitle}
      overflowRedbarContent={overflowRedbarContent}
    >
      <AddStudentBody groupClassId={groupClassId} addStudentData={hookData} />
    </RedbarModal>
  );
};

export default AddStudentToGroupClassModal;
