import { Button } from "antd";
import styled from "styled-components";

const Attachments = styled.ul`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  list-style: none;

  li {
    position: relative;
  }
`;

const AttachmentItem = styled.li`
  position: relative;

  .delete-btn-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition-duration: 150ms;
  }

  &:hover {
    .delete-btn-wrapper {
      transform: translate(-50%, 150%);
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    }
  }
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: 5%;
  right: 5%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  color: #000;
`;

export { Attachments, AttachmentItem, DownloadButton };
