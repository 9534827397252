import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddNewForm from "./AddNewForm";
import { modalDetailsMap } from "./constants";
import { CloseIcon } from "./styled";

const AddNewFeedItemModal = ({ modalData, feedItemType, onCreateSuccess }) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;

  return (
    <div>
      <Modal
        size="lg"
        style={{ maxWidth: "950px" }}
        className="min-w-50  text-dark"
        centered
        isOpen={isModalOpen}
        toggle={toggleModal}
      >
        <ModalHeader
          close={<CloseIcon onClick={closeModal} />}
          className="px-5 pb-0 border-bottom-0 "
          toggle={toggleModal}
        >
          <span className="fs-2 fw-bold">
            {`${modalDetailsMap[feedItemType]?.title2}`}
          </span>
        </ModalHeader>
        <ModalBody className="px-5">
          {feedItemType ? (
            <AddNewForm
              feedItemType={feedItemType}
              closeModal={closeModal}
              onCreateSuccess={onCreateSuccess}
            />
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddNewFeedItemModal;
