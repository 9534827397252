import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import {
  CustomInput,
  CustomSelectField,
  PrimaryButton,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";
import { InputsContainer } from "../styled";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import { useRef } from "react";
import { displayOptions } from "../constants";

const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 8px;
`;
const CustomSearchInput = styled.input`
  &::-webkit-search-cancel-button {
    position: relative;
    right: 50px;
  }
`;

const HeaderSection = ({ makeupNeededData }) => {
  const {
    searchTerm,
    handleSearchTermChange,
    initialData,
    applyFilters,
    handleFiltersChange,
    filterOptions,
    selectableTeachers,
    selectableStudents,
    currentDisplayOption,
    clearFilters,
  } = makeupNeededData;
  const { locations, users } = initialData;

  const areFiltersEnabled = currentDisplayOption === displayOptions.FILTER;
  return (
    <div>
      <Row>
        <InputsContainer>
          <div style={{ width: "25%" }}>
            <CustomLabel>Location:</CustomLabel>
            <CustomSelectField
              width="100%"
              name="selectedLocationId"
              value={filterOptions.selectedLocationId}
              onChange={(e) =>
                handleFiltersChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Location
              </option>
              {locations.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </CustomSelectField>
          </div>
          <div style={{ width: "25%" }}>
            <CustomLabel>Teacher:</CustomLabel>
            <CustomSelectField
              width="100%"
              name="selectedTeacherId"
              value={filterOptions.selectedTeacherId}
              onChange={(e) =>
                handleFiltersChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Teacher
              </option>
              {selectableTeachers.map(({ id, fullName }) => (
                <option key={id} value={id}>
                  {fullName}
                </option>
              ))}
            </CustomSelectField>
          </div>
          <div style={{ width: "25%" }}>
            <CustomLabel>Student:</CustomLabel>
            <CustomSelectField
              width="100%"
              name="selectedStudentId"
              value={filterOptions.selectedStudentId}
              onChange={(e) =>
                handleFiltersChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Student
              </option>
              {selectableStudents.map(({ id, fullName }) => (
                <option key={id} value={id}>
                  {fullName}
                </option>
              ))}
            </CustomSelectField>
          </div>
          {areFiltersEnabled ? (
            <PrimaryButton backgroundColor="#e357a2" onClick={clearFilters}>
              Clear Filters
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={applyFilters}>Apply Filters</PrimaryButton>
          )}
        </InputsContainer>
      </Row>

      <Row className="mt-5">
        <Col xs="6">
          <FormGroup className="searchContainer">
            <CustomSearchInput
              type="search"
              style={{ padding: "10px 10px 10px 25px" }}
              placeholder="Search by Student Name"
              className="searchInput"
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
            />
            <SearchIcon />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderSection;
