import React from "react";
import {
  ConcertImage,
  PrimaryText,
  SecondaryText,
  ConcertDetailsSectionContainer,
  CornerRibbon,
  CustomButton,
} from "./styled";
import moment from "moment";
import guitarImg from "src/assets/images/guitar.webp";
import { PrimaryButton } from "src/utils/shared/styled";
import useModal from "src/hooks/useModal";
import ConcertRecommendationModal from "../../../ConcertRecommendationModal";
import {
  hasAdminCredentials,
  isStudent,
  isTeacher,
} from "src/constants/UserRoleEnum";
import StudentConcertInvitationModal from "../../../StudentConcertInvitationModal";
import { SubmitSpinner } from "src/components/common";
import ConcertSignupListModal from "../../../ConcertSignupListModal";
import ConcertDetailsModal from "../../../ConcertDetailsModal";
import ConcertProgramsModal from "../../../ConcertProgramsModal";
import { PrimaryButtonWithBlink } from "src/utils/shared/shared";
import TeacherButtons from "./components/TeacherButtons";
import StudentButtons from "./components/StudentButtons";
import AdminButtons from "./components/AdminButtons";
import FreeForAllConcertSignupModal from "../../../FreeForAllConcertSignupModal";

const ConcertDetails = ({
  user,
  refreshData,
  locations,
  currentConcert,
  currentConcertColor,
  userActiveInvitations,
  loadingCurrentConcertData,
  setCurrentInvitationId,
  currentInvitationId,
}) => {
  const concertDetailsModalData = useModal();
  const recommendationModalData = useModal();
  const studentInvitationModalData = useModal();
  const signupListModalData = useModal();
  const programsModalData = useModal();
  const studentSignupModalData = useModal();

  const isTeacherUser = isTeacher(user.role);
  const isStudentUser = isStudent(user.role);
  const isAdminUser = hasAdminCredentials(user.role);

  const { title, attachments, date, duration, locationId, address } =
    currentConcert;
  const { url } = attachments?.find(({ type }) => type.includes("image")) || {};

  const formattedDate = moment(date).format("MM/DD/YYYY");
  const formattedTime =
    moment(date).format("hh:mm A") +
    " - " +
    moment(date).add(duration, "minutes").format("hh:mm A");

  const locationName = locations.find(({ id }) => id === locationId)?.name;

  function handleOpenClick() {
    concertDetailsModalData.openModal();
  }

  function handleInvitationClick(invitationId) {
    setCurrentInvitationId(invitationId);
    studentInvitationModalData.openModal();
  }

  if (loadingCurrentConcertData) {
    return (
      <div
        style={{ flex: 1.25 }}
        className="d-flex justify-content-center align-items-center vh-60"
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <ConcertDetailsSectionContainer bgColor={currentConcertColor}>
      <CornerRibbon bgColor="green">Coming</CornerRibbon>
      <div className="text-end p-3">
        <CustomButton onClick={handleOpenClick}>Open</CustomButton>
      </div>
      <div style={{ marginTop: "25px" }}>
        <div className="d-flex flex-wrap p-3" style={{ gap: 10 }}>
          <div style={{ flex: 1 }}>
            <ConcertImage src={url || guitarImg} alt="concert-image" />
          </div>
          <div style={{ flex: 1.5 }}>
            <div className="d-flex justify-content-between mb-5">
              <PrimaryText className="h4">{title}</PrimaryText>
              <SecondaryText>{formattedDate}</SecondaryText>
            </div>
            <div
              className="d-flex justify-content-between flex-wrap"
              style={{ gap: "1rem", marginBottom: "1rem" }}
            >
              <div className="">
                <PrimaryText>Date</PrimaryText>
                <SecondaryText>{formattedDate}</SecondaryText>
              </div>
              <div>
                <PrimaryText>Time</PrimaryText>
                <SecondaryText>{formattedTime}</SecondaryText>
              </div>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <div>
                <PrimaryText>Location</PrimaryText>
                <SecondaryText>{address}</SecondaryText>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 p-3 text-center">
          {isTeacherUser ? (
            <TeacherButtons
              openRecommendationModal={recommendationModalData.openModal}
              openSignupListModal={signupListModalData.openModal}
              openProgramsModal={programsModalData.openModal}
              concert={currentConcert}
            />
          ) : isStudentUser ? (
            <StudentButtons
              userActiveInvitations={userActiveInvitations}
              handleInvitationClick={handleInvitationClick}
              openStudentSignupModal={studentSignupModalData.openModal}
              concert={currentConcert}
            />
          ) : isAdminUser ? (
            <AdminButtons
              concert={currentConcert}
              openSignupListModal={signupListModalData.openModal}
              openProgramsModal={programsModalData.openModal}
            />
          ) : null}
        </div>
      </div>

      {/* MODALS */}
      {recommendationModalData.isModalOpen && (
        <ConcertRecommendationModal
          onInvitationSuccess={() => {
            recommendationModalData.closeModal();
            refreshData();
          }}
          modalData={recommendationModalData}
          concertId={currentConcert.id}
        />
      )}
      {!!currentInvitationId && studentInvitationModalData.isModalOpen && (
        <StudentConcertInvitationModal
          modalData={studentInvitationModalData}
          onSubmit={() => {
            recommendationModalData.closeModal();
            refreshData();
          }}
          invitationId={currentInvitationId}
          concertId={currentConcert.id}
        />
      )}
      {signupListModalData.isModalOpen && (
        <ConcertSignupListModal
          onActionSuccess={() => {
            signupListModalData.closeModal();
            refreshData();
          }}
          modalData={signupListModalData}
          concertId={currentConcert.id}
        />
      )}
      {concertDetailsModalData.isModalOpen && (
        <ConcertDetailsModal
          modalData={concertDetailsModalData}
          concertId={currentConcert.id}
        />
      )}
      {programsModalData.isModalOpen && (
        <ConcertProgramsModal
          modalData={programsModalData}
          concertId={currentConcert.id}
        />
      )}
      {studentSignupModalData.isModalOpen && (
        <FreeForAllConcertSignupModal
          onActionSuccess={() => {
            studentSignupModalData.closeModal();
            refreshData();
          }}
          modalData={studentSignupModalData}
          concertId={currentConcert.id}
        />
      )}
    </ConcertDetailsSectionContainer>
  );
};

export default ConcertDetails;
