import React from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import {
  filtersWithMonthFilter,
  paymentPeriodFilters,
} from "src/constants/paymentPeriodFilters";
import { updatedMomentNow } from "src/utils/helpers";
import { CustomLabel } from "src/utils/shared/styled";

const monthsOptions = Array.from(Array(12).keys()).map((month) => {
  return {
    label: updatedMomentNow().set({ month }).format("MMMM"),
    value: month,
  };
});

const periodOptions = Object.values(paymentPeriodFilters).map((filterObj) => {
  return {
    label: filterObj.title,
    value: filterObj.value,
  };
});

const DatePeriodSelection = ({
  selectedFilter,
  selectedMonth,
  setSelectedFilter,
  handleSelectedMonthChange,
}) => {
  const selectedFilterObj = selectedFilter
    ? Object.values(paymentPeriodFilters).find(
        ({ value }) => value === selectedFilter
      )
    : null;
  const selectedPeriodOption = selectedFilterObj
    ? {
        label: selectedFilterObj.title,
        value: selectedFilterObj.value,
      }
    : null;

  const selectedMonthOption =
    selectedMonth !== ""
      ? {
          label: updatedMomentNow()
            .set({ month: selectedMonth })
            .format("MMMM"),
          value: selectedMonth,
        }
      : null;

  const showMonthFilter = filtersWithMonthFilter.includes(selectedFilter);

  return (
    <div>
      <div>
        <div className="mb-3">
          <CustomLabel displayBlock>Period:</CustomLabel>
          <CustomReactSelect
            isMulti={false}
            placeholder="Period"
            width="100%"
            options={periodOptions}
            onChange={(option) => {
              setSelectedFilter(option?.value);
            }}
            value={selectedPeriodOption}
            wrapOptions={false}
            isClearable={true}
          />
        </div>
        {showMonthFilter && (
          <div>
            <CustomLabel displayBlock>Month:</CustomLabel>
            <CustomReactSelect
              isMulti={false}
              placeholder="Month"
              width="100%"
              options={monthsOptions}
              onChange={(option) => handleSelectedMonthChange(option?.value)}
              value={selectedMonthOption}
              wrapOptions={false}
              isClearable={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePeriodSelection;
