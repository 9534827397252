import styled from "styled-components";

const FeedbackInputWrapper = styled.div`
  position: relative;

  textarea {
    width: 100%;
    min-height: 250px;
    padding: 8px;

    border-radius: 12px;
    outline: none;
    transition-duration: 150ms;

    &:is(:hover, :focus) {
      border-color: #e357a2;
    }
  }

  button {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px;

    border: none;
    background: transparent;

    svg {
      transition-duration: 150ms;
    }

    &:is(:hover, :focus) {
      svg {
        color: #e357a2;
      }

      &:disabled {
        svg {
          color: currentColor;
        }
      }
    }
  }
`;

export { FeedbackInputWrapper };
