import moment from "moment";
import React from "react";
import { getPrivateLessonInfoOnSpecificDate } from "src/utils/helpers";
import { CustomInput } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import { InfoText } from "../../../styled";

const PartiallyApprovedSection = ({
  absenceDetailsData,
  combinedStudents,
  instruments,
  currentAbsence,
}) => {
  const absenceDate =
    currentAbsence.date ||
    currentAbsence.startDate ||
    currentAbsence.request?.date ||
    currentAbsence.request?.startDate;

  const {
    initialData,
    partiallyApprovedValues,
    handlePartiallyApprovedValuesChange,
    partiallyApprovedPrivateLessonsIds,
    partiallyApprovedTrialLessonsIds,
    partiallyApprovedMakeupLessonsIds,
  } = absenceDetailsData;
  const {
    absencePrivateLesson,
    // duration absences
    affectedPLs,
    affectedTLs,
    affectedMakeupLessons,
  } = initialData;

  const combinedAffectedLessonsIds = [
    ...(partiallyApprovedPrivateLessonsIds?.length
      ? partiallyApprovedPrivateLessonsIds
      : []),
    ...(partiallyApprovedTrialLessonsIds?.length
      ? partiallyApprovedTrialLessonsIds
      : []),
    ...(partiallyApprovedMakeupLessonsIds?.length
      ? partiallyApprovedMakeupLessonsIds
      : []),
  ];
  return (
    <div>
      <div
        className="d-flex justify-content-center mt-3 mb-4"
        style={{ gap: 35 }}
      >
        <div>
          <CustomLabel>Start:</CustomLabel>
          <CustomInput
            onChange={(e) =>
              handlePartiallyApprovedValuesChange("startTime", e.target.value)
            }
            value={partiallyApprovedValues.startTime}
            type="time"
          />
        </div>
        <div>
          <CustomLabel>End:</CustomLabel>
          <CustomInput
            onChange={(e) =>
              handlePartiallyApprovedValuesChange("endTime", e.target.value)
            }
            value={partiallyApprovedValues.endTime}
            type="time"
          />
        </div>
      </div>
      <div>
        {combinedAffectedLessonsIds?.length ? (
          <div style={{ marginTop: "70px" }}>
            <InfoText bold className="mb-2">
              Affected Lessons *UPDATED*:
            </InfoText>
            <ul className="ps-4">
              {/* Affected Private Lessons */}
              {partiallyApprovedPrivateLessonsIds?.map((lessonId) => {
                const lesson = affectedPLs?.find(({ id }) => id === lessonId);
                const { studentId, instrumentId } = lesson;
                const { startDate: lessonStart, duration } =
                  getPrivateLessonInfoOnSpecificDate({
                    privateLesson: lesson,
                    date: absenceDate,
                    withTimelineApproximation: true,
                  });
                const lessonEnd = lessonStart
                  ? moment(lessonStart).add(parseInt(duration), "minutes")
                  : undefined;

                const student = combinedStudents?.find(
                  ({ id }) => id === studentId
                );
                const studentName = student?.fullName || student?.name;

                const instrument = instruments?.find(
                  ({ id }) => id === instrumentId
                );
                const formattedStartTime =
                  moment(lessonStart).format("hh:mm A");
                const formattedEndTime = moment(lessonEnd).format("hh:mm A");
                const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Private Lesson*`;
                return <li className="mb-1">{title}</li>;
              })}

              {/* Affected Trial Lessons */}
              {partiallyApprovedTrialLessonsIds?.map((lessonId) => {
                const {
                  id,
                  studentId,
                  instrument: instrumentId,
                  date,
                  lessonLength,
                } = affectedTLs?.find(({ id }) => id === lessonId) || {};

                const student = combinedStudents?.find(
                  ({ id }) => id === studentId
                );
                const studentName = student?.fullName || student?.name;
                const instrument = instruments?.find(
                  ({ id }) => id === instrumentId
                );
                const formattedStartTime = moment(date).format("hh:mm A");
                const formattedEndTime = moment(date)
                  .add(parseInt(lessonLength), "minutes")
                  .format("hh:mm A");

                const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Trial Lesson*`;
                return <li className="mb-1">{title}</li>;
              })}

              {/* Affected Make Up Lessons */}
              {partiallyApprovedMakeupLessonsIds?.map((lessonId) => {
                const { id, studentId, instrumentId, date } =
                  affectedMakeupLessons?.find(({ id }) => id === lessonId) ||
                  {};
                const { startDate, lessonLength } = date || {};

                const student = combinedStudents?.find(
                  ({ id }) => id === studentId
                );
                const studentName = student?.fullName || student?.name;
                const instrument = instruments?.find(
                  ({ id }) => id === instrumentId
                );
                const formattedStartTime = moment(startDate).format("hh:mm A");
                const formattedEndTime = moment(startDate)
                  .add(parseInt(lessonLength), "minutes")
                  .format("hh:mm A");

                const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Make Up Lesson*`;
                return <li className="mb-1">{title}</li>;
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PartiallyApprovedSection;
