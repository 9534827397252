import useAppointmentEvent from "./hooks/useAppointmentEvent";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import {
  availableDaysToString,
  injectUserStore,
  instrumentsToString,
} from "src/utils/helpers";
import moment from "moment";
import styled from "styled-components";
import { toJS } from "mobx";
import { eventInfoItems, teacherInfoItems } from "../eventInfoItems";

import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { Descriptions, Divider } from "antd";

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    transition-duration: 150ms;

    &:is(:hover, :focus) {
      fill: #681e46;
    }
  }

  h4 {
    margin: 0;
    text-transform: capitalize;
  }
`;

const AppointmentEventContent = ({
  event,
  initialData,
  closeEventDetailsModal,
  refreshData,
  studioUsages,
  UserStore,
}) => {
  const user = toJS(UserStore)?.user;
  const canDelete = hasAdminCredentials(user.role);

  const { handleDeleteAppointment } = useAppointmentEvent({
    appointmentId: event.id,
    closeEventDetailsModal,
    refreshData,
  });
  const { locations, studios } = initialData;

  const teacher = event.teacher;
  const teacherId = teacher?.id || teacher?.uid;

  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );
  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  const teacherStudioUsageInCurrentDate = studioUsages?.find((studioUsage) => {
    const isSameTeacher = teacherId === studioUsage.teacher_id;
    const isSameDate =
      moment(event.start).year() === studioUsage.year &&
      moment(event.start).month() + 1 === studioUsage.month &&
      moment(event.start).format("dddd") === studioUsage.weekday;

    return isSameTeacher && isSameDate;
  });

  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;
  const locationName = locations[event.locationId]?.name;

  return (
    <div className="p-5 pt-1">
      <ActionButtonsContainer>
        {event?.title && <h4>{event?.title}</h4>}
        {canDelete && <DeleteOff onClick={handleDeleteAppointment} size={30} />}
      </ActionButtonsContainer>
      <Divider />
      <Descriptions
        title="Appointment information"
        bordered
        column={1}
        items={eventInfoItems(event)}
        labelStyle={{ fontSize: 16 }}
        contentStyle={{ fontSize: 16 }}
      />
      <Divider />
      <Descriptions
        title="Teacher information"
        bordered
        column={1}
        items={teacherInfoItems({
          event,
          teachingDays: teachingDaysStr,
          instruments: teachersInstrumentsStr,
          studio: studioName,
          location: locationName,
        })}
        labelStyle={{ fontSize: 16 }}
        contentStyle={{ fontSize: 16 }}
      />
    </div>
  );
};

export default injectUserStore(AppointmentEventContent);
