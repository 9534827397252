import React from "react";
import ProgramsTable from "../components/ProgramsTable";
import { SubmitSpinner } from "src/components/common";

const ConcertProgramsBody = ({ hookData, closeModal, onActionSuccess }) => {
  const { loadingInitialData } = hookData;

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      <ProgramsTable hookData={hookData} onActionSuccess={onActionSuccess} />
    </div>
  );
};

export default ConcertProgramsBody;
