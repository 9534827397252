import { Form } from "formik";
import styled from "styled-components";

const SignUpForm = styled(Form)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const ErrorMessage = styled.span`
  color: red;
`;

export { SignUpForm, ErrorMessage };
