import React from "react";

const PackageLessonInfo = ({ lesson }) => {
  const { student, instrument, currentTimeline } = lesson || {};

  return (
    <div>
      <div className="ms-2 mb-3">
        <h4 className="fw-bold">Lesson</h4>
      </div>
      <div className="lessonsContainer mb-4 ">
        <div className="UsableContainer leesonsDataContainer flex-wrap">
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Lesson Type : </p>
            <p className="DataInfo">Package Lesson</p>
          </div>
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Instrument : </p>
            <p className="DataInfo">{instrument?.name || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageLessonInfo;
