import React, { useState } from "react";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import InfoMenu from "./InfoMenu";

const InfoDropdown = ({ renderBodyContent }) => {
  const [showInfoMenu, setShowInfoMenu] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setShowInfoMenu(true);
      }}
      onMouseLeave={() => {
        setShowInfoMenu(false);
      }}
      style={{ display: "inline-block", position: "relative" }}
    >
      <InfoCircle
        style={{
          width: "20px",
          marginLeft: "6px",
          color: "#ff9ed2",
          cursor: "pointer",
        }}
      />
      {showInfoMenu && <InfoMenu renderBodyContent={renderBodyContent} />}
    </div>
  );
};

export default InfoDropdown;
