import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import { usePackageLessonContext } from "../../../../contexts/PackageLessonContext";
import {
  DeleteIcon,
  InfoContainer,
  InfoText,
  PackageItemContainer,
} from "../../../../styled";
import {
  isRecurring10PackageSet,
  packageLessonSetTypes,
} from "src/constants/packageLessonsEnum";

const PackageItemInfo = ({ packageItem, index, setNumber }) => {
  const {
    packageLesson,
    packageTeachers,
    locations,
    onDeletePackagItemClick,
    isAbleToDeletePackageItems,
  } = usePackageLessonContext();

  const setObj = packageLesson.packageSets.find(
    (set) => set.setNumber === setNumber
  );

  const { teacherId, locationId, startDate, duration, isVirtual } =
    packageItem || {};

  const weekDay = moment(startDate).day();
  const lessonLength = parseInt(duration);
  const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
  const weekDayName = weekDays.find(({ id }) => id === weekDay)?.name;
  const startTime = moment(startDate).format("hh:mm A");
  const teacher = packageTeachers.find(({ id }) => id === teacherId);
  const teacherName = teacher?.fullName;
  const location = locations.find(({ id }) => id === locationId);
  const locationName = location?.name;

  const lessonType = isVirtual ? "Virtual" : "In Person";

  const showDeletePackageItemBtn =
    isAbleToDeletePackageItems && !isRecurring10PackageSet(setObj.setType);

  return (
    <PackageItemContainer className="mb-3 ">
      <div className="d-flex justify-content-between">
        <div>
          <InfoText bold className="mb-3">{`${index + 1}.`}</InfoText>
        </div>
        {showDeletePackageItemBtn && (
          <div>
            <DeleteIcon
              onClick={() => onDeletePackagItemClick(packageItem.id)}
            />
          </div>
        )}
      </div>
      <InfoContainer>
        <InfoText bold className="me-2">
          Started On:
        </InfoText>
        <InfoText>{formattedStartDate}</InfoText>
      </InfoContainer>

      <InfoContainer>
        <InfoText bold className="me-2">
          Class Day:
        </InfoText>
        <InfoText>{weekDayName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Lesson Time:
        </InfoText>
        <InfoText>{startTime}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Duration:
        </InfoText>
        <InfoText>{`${lessonLength} Minutes`}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Teacher Name:
        </InfoText>
        <InfoText>{teacherName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Location:
        </InfoText>
        <InfoText>{locationName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Type:
        </InfoText>
        <InfoText>{lessonType}</InfoText>
      </InfoContainer>
    </PackageItemContainer>
  );
};
export default PackageItemInfo;
