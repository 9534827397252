import React from "react";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import { CaretLeftFill } from "@styled-icons/bootstrap/CaretLeftFill";

const PagingOptions = ({ logsData }) => {
  const { loadingInitialData, logsListData, loadingLogsList } = logsData || {};
  const {
    hasNextPage = false,
    hasPrevPage = false,
    currentPage = 1,
    lastLogSnap,
    firstLogSnap,
  } = logsListData || {};

  const handleNextPageClick = () => {
    logsData.handleNextLogsPageClick(lastLogSnap);
  };
  const handlePrevPageClick = () => {
    logsData.handlePrevLogsPageClick(firstLogSnap);
  };

  if (loadingLogsList || loadingInitialData) return <div></div>;
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ gap: "10px" }}
    >
      {hasPrevPage && (
        <div onClick={handlePrevPageClick}>
          <CaretLeftFill
            width={30}
            style={{ cursor: "pointer", color: "#681e46" }}
          />
        </div>
      )}
      <span>{currentPage}</span>
      {hasNextPage && (
        <div onClick={handleNextPageClick}>
          <CaretRightFill
            width={30}
            style={{ cursor: "pointer", color: "#681e46" }}
          />
        </div>
      )}
    </div>
  );
};

export default PagingOptions;
