import React from "react";
import { useState } from "react";

const useConfirmation = () => {
  const [isChecked, setIsChecked] = useState(false);

  const onCheckboxChange = () => {
    setIsChecked((oldVal) => !oldVal);
  };

  return { isChecked, onCheckboxChange };
};

export default useConfirmation;
