import React from "react";
import { SectionContainer } from "../../styled";
import { groupClassPaymentStudentTypes } from "src/constants/groupClassPaymentsConstants";
import { getGroupClassPaymentOptionsTotal } from "src/utils/helpers";

const DirectPayment = ({ directPaymentOptions }) => {
  return (
    <SectionContainer className="mt-3">
      <h6>One Time</h6>
      <hr />
      {directPaymentOptions.map((option) => {
        const { optionPayments, forStudentType } = option;
        const paymentTotal = getGroupClassPaymentOptionsTotal(optionPayments);

        const header =
          forStudentType === groupClassPaymentStudentTypes.CURRENT_STUDENT
            ? "Current Students"
            : groupClassPaymentStudentTypes.NEW_STUDENT
            ? "New Students"
            : "";
        return (
          <SectionContainer key={option.id}>
            <h6>{header}</h6>
            <hr />
            <p>
              Amount: <span className="ms-2">${paymentTotal}</span>
            </p>
          </SectionContainer>
        );
      })}
    </SectionContainer>
  );
};

export default DirectPayment;
