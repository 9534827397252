import {
  addDoc,
  arrayUnion,
  deleteDoc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import {
  isCreditedAbsence,
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import { eventsMap } from "src/constants/eventsEnum";
import { logActionTypes, logTypes } from "src/constants/logTypes";
import { scheduleTypes } from "src/constants/scheduleTypes";
import { UserRole } from "src/constants/UserRoleEnum";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import { getTimeDiffInMins } from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getStudentMakeUpLessons = async (studentId) => {
    try {
      const makeUpsQuery = query(
        firebase.makeupLessons(),
        where("studentId", "==", studentId)
      );
      const makeUpsSnap = await getDocs(makeUpsQuery);
      const makeUpLessons = parseFirebaseDoc(makeUpsSnap.docs);

      const makeUpLessonsWithDates = makeUpLessons.map((lesson) => ({
        ...lesson,
        createdAt: lesson.createdAt.toDate(),
        date: {
          ...lesson.date,
          startDate: lesson.date.startDate.toDate(),
          endDate: lesson.date.endDate.toDate(),
        },
      }));
      return makeUpLessonsWithDates;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const createMakeupLesson = async (makeupObj, user) => {
    try {
      const parsedStartTime = moment(makeupObj.startTime, ["hh:mm A"]);
      const startHours = parsedStartTime.get("hour");
      const startMinutes = parsedStartTime.get("minute");

      const parsedEndTime = moment(makeupObj.endTime, ["hh:mm A"]);
      const endHours = parsedEndTime.get("hour");
      const endMinutes = parsedEndTime.get("minute");

      const startDate = moment(makeupObj.startDate)
        .set({
          hour: startHours,
          minute: startMinutes,
          second: 0,
          millisecond: 0,
        })
        .toDate();
      const endDate = moment(makeupObj.startDate)
        .set({
          hour: endHours,
          minute: endMinutes,
          second: 0,
          millisecond: 0,
        })
        .toDate();

      const newMakeupObj = {
        // Ignoring the id prop as it will be added by firebase
        createdAt: new Date(),
        type: makeupObj.type,
        date: {
          startDate,
          endDate,
          lessonLength: getTimeDiffInMins(startDate, endDate),
        },
        forAbsenceId: makeupObj.forAbsenceId,
        forLessonId: makeupObj.forLessonId,
        forLessonType: makeupObj.forLessonType,
        instrumentId: makeupObj.instrumentId,
        locationId: makeupObj.locationId,
        studentId: makeupObj.studentId,
        teacherId: makeupObj.teacherId,
        requestedBy: user.uid,
        isVirtual: makeupObj.isVirtual,
      };

      const makeupRes = await addDoc(firebase.makeupLessons(), newMakeupObj);
      if (makeupRes?.id) {
        /* -- Creating The User Activity -- */
        // the user activity document structure
        const teacherUserActivityObj = {
          createdAt: new Date(),
          start_time: newMakeupObj.date.startDate,
          end_time: newMakeupObj.date.endDate,
          location: newMakeupObj.locationId,
          type: eventsMap.makeupLesson.code,
          userId: newMakeupObj.teacherId,
          scheduleType: scheduleTypes.other.code,
          userRole: UserRole.TEACHER,
          isActive: true,
        };
        await addMakeupUserActivity(makeupRes.id, teacherUserActivityObj);
      }
      return makeupRes;
    } catch (err) {
      console.log(err);
    }
  };
  const addMakeupUserActivity = async (lessonId, userActivityObj) => {
    try {
      const { userId } = userActivityObj;
      if (!userActivityObj || !userId) {
        throw { message: "Couldnt find the makeup user activity" };
      }

      const activityRes = await setDoc(
        firebase.userActivity(lessonId),
        userActivityObj
      );

      return activityRes;
    } catch (err) {
      console.log(err);
    }
  };

  const editMakeupLesson = async (editedMakeupLesson) => {
    try {
      const parsedStartTime = moment(editedMakeupLesson.startTime, ["hh:mm A"]);
      const startHours = parsedStartTime.get("hour");
      const startMinutes = parsedStartTime.get("minute");

      const parsedEndTime = moment(editedMakeupLesson.endTime, ["hh:mm A"]);
      const endHours = parsedEndTime.get("hour");
      const endMinutes = parsedEndTime.get("minute");

      const startDate = moment(editedMakeupLesson.startDate)
        .set({
          hour: startHours,
          minute: startMinutes,
          second: 0,
          millisecond: 0,
        })
        .toDate();
      const endDate = moment(editedMakeupLesson.startDate)
        .set({
          hour: endHours,
          minute: endMinutes,
          second: 0,
          millisecond: 0,
        })
        .toDate();

      const editedMakeupObj = {
        createdAt: editedMakeupLesson.createdAt,
        type: editedMakeupLesson.type,
        date: {
          startDate,
          endDate,
          lessonLength: getTimeDiffInMins(startDate, endDate),
        },
        forAbsenceId: editedMakeupLesson.forAbsenceId,
        forLessonId: editedMakeupLesson.forLessonId,
        forLessonType: editedMakeupLesson.forLessonType,
        instrumentId: editedMakeupLesson.instrumentId,
        locationId: editedMakeupLesson.locationId,
        studentId: editedMakeupLesson.studentId,
        teacherId: editedMakeupLesson.teacherId,
        isVirtual: editedMakeupLesson.isVirtual,
      };

      const makeupRes = await setDoc(
        firebase.makeupLesson(editedMakeupLesson.id),
        editedMakeupObj
      );
      //User Activity update
      const activityDay = moment(editedMakeupObj.date.startDate).format("dddd");
      const teacherUserActivityObj = {
        day: activityDay,
        start_time: editedMakeupObj.date.startDate,
        end_time: editedMakeupObj.date.endDate,
        location: editedMakeupObj.locationId,
        type: eventsMap.makeupLesson.code,
        userId: editedMakeupObj.teacherId,
        scheduleType: scheduleTypes.other.code,
        userRole: UserRole.TEACHER,
        isActive: true,
      };

      await editMakeupUserActivity(
        editedMakeupLesson.id,
        teacherUserActivityObj
      );

      return;
    } catch (err) {
      console.log(err);
    }
  };
  const editMakeupUserActivity = async (lessonId, updatedActivity) => {
    try {
      if (!updatedActivity || !lessonId) {
        throw { message: "Couldnt find the makeup user activity" };
      }

      const updateRes = await updateDoc(firebase.userActivity(lessonId), {
        start_time: updatedActivity.start_time,
        end_time: updatedActivity.end_time,
        location: updatedActivity.location,
      });
      return updateRes;
    } catch (err) {
      console.log(err);
    }
  };
  const deleteMakeupLesson = async (lessonId) => {
    try {
      const deleteRes = await deleteDoc(firebase.makeupLesson(lessonId));
      await deleteMakeupUserActivity(lessonId);

      return deleteRes;
    } catch (err) {
      console.log(err);
    }
  };
  const deleteMakeupUserActivity = async (lessonId) => {
    try {
      await deleteDoc(firebase.userActivity(lessonId));
    } catch (err) {
      console.log(err);
    }
  };

  const editLessonAbsence = async (editedAbsence) => {
    try {
      let { isEdited, id, ...editedAbsenceObj } = editedAbsence;
      editedAbsenceObj = {
        ...editedAbsenceObj,
        absenceType: parseInt(editedAbsence.absenceType),
      };

      const res = await setDoc(
        firebase.absence(editedAbsence.id),
        editedAbsenceObj
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getActivitiesByTeachersIds = async (teachersIds) => {
    try {
      if (teachersIds.length) {
        const q1 = query(
          firebase.usersActivities(),
          where("userId", "in", teachersIds),
          where("isActive", "==", true)
        );
        const q2 = query(
          firebase.usersActivities(),
          where("usersIds", "array-contains-any", teachersIds),
          where("isActive", "==", true)
        );
        const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
        const [docs1, docs2] = [
          parseFirebaseDoc(snap1.docs),
          parseFirebaseDoc(snap2.docs),
        ];
        const combinedActivities = [...docs1, ...docs2];
        return combinedActivities;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getLessonAbsences = async (lessonId) => {
    try {
      const q1 = query(
        firebase.absences(),
        where("lessonId", "==", lessonId)
        // where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const q2 = query(
        firebase.absences(),
        where("affectedPrivateLessonsIds", "array-contains", lessonId)

        // Can't have not-in with array-contains on the same query, but we filter pending absences on client side anyways
        // where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [absences1, absences2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedLessonAbsences = [...absences1, ...absences2];

      const filteredAbsences = combinedLessonAbsences.filter(
        ({ status, absenceType }) => {
          const isActive =
            !isPendingAbsence(status) && !isDeclinedAbsence(status);
          const isCredited = isCreditedAbsence(absenceType);
          return isCredited || isActive;
        }
      );
      console.log({ filteredAbsences, combinedLessonAbsences });
      return filteredAbsences;
    } catch (err) {
      console.log(err);
    }
  };
  const deleteAbsenceDoc = async (absenceId) => {
    try {
      const deleteRes = await deleteDoc(firebase.absence(absenceId));

      return deleteRes;
    } catch (err) {
      console.log(err);
    }
  };

  const getAbsences = async () => {
    try {
      const q = query(
        firebase.absences(),
        where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const absencesSnap = await getDocs(q);
      const absences = parseFirebaseDoc(absencesSnap.docs);

      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      return filteredAbsences;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getStudentMakeUpLessons,
    createMakeupLesson,
    editMakeupLesson,
    deleteMakeupLesson,
    editLessonAbsence,
    getActivitiesByTeachersIds,
    getLessonAbsences,
    deleteAbsenceDoc,
    getAbsences,
  };
};

export default useFirebaseFns;
