import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import store from "src/stores/UserStore";
import { updateDoc } from "firebase/firestore";
import { sendEmailConfirmation } from "../../../utils";
import { toast } from "react-toastify";

import { PrimaryButton, RequiredInputSpan } from "src/utils/shared/styled";
import { FirebaseContext } from "src/components/Firebase";
import ActionConfirmModal from "../../../components/ActionConfirmModal";
import WithdrawModal from "../components/WithdrawModal";
import { MessageInput, PartnerFieldsWrapper } from "./styled";
import { ButtonsWrapper } from "../../GuestProfileSecondPage/styled";
import ProfileSummary from "../components/ProfileSummary";
import CustomTooltip from "src/components/Dashboard/common/components/CustomTooltip";

const PartnerThirdPage = () => {
  const { user, setUser } = store;
  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [messageValue, setMessageValue] = useState(user?.message || "");

  const handleSubmit = async () => {
    if (!messageValue) {
      return toast.error("Please fill all the fields!");
    }

    const userToUpdate = firebase.user(user.uid);

    await updateDoc(userToUpdate, {
      message: messageValue,
      status: "On review",
    });
    setUser({ ...user, message: messageValue, status: "On review" });

    setConfirmModalOpen(true);
    await sendEmailConfirmation(user);
  };

  return (
    <>
      <ProfileSummary user={user} />
      <PartnerFieldsWrapper>
        <label>
          Subject of interest (describe your proposal for partnership with Artis
          School of Music) <RequiredInputSpan>*</RequiredInputSpan>
          <MessageInput
            placeholder="Subject"
            value={messageValue}
            onChange={({ target }) => setMessageValue(target.value)}
            required
          />
        </label>
      </PartnerFieldsWrapper>
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/guest-profile/2")}
        >
          Previous page
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => setWithdrawModalOpen(true)}
        >
          Withdraw
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={handleSubmit}
          disabled={user?.status !== "Need profile information"}
          id="partner-submit"
        >
          Submit
        </PrimaryButton>
        {user?.status !== "Need profile information" && (
          <CustomTooltip anchorSelect="#partner-submit">
            You've already submitted your application
          </CustomTooltip>
        )}
      </ButtonsWrapper>
      <ActionConfirmModal
        isOpen={confirmModalOpen}
        toggle={() => setConfirmModalOpen((prevState) => !prevState)}
        text={`Thank you for completing the application! We will contact you soon`}
      />
      <WithdrawModal
        isOpen={withdrawModalOpen}
        toggle={() => setWithdrawModalOpen((prevState) => !prevState)}
      />
    </>
  );
};

export default PartnerThirdPage;
