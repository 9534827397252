import moment from "moment";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { SubmitSpinner } from "src/components/common";
import { absenceTypes } from "src/constants/absenceTypes";
import useModal from "src/hooks/useModal";
import {
  getCombinedSchoolYears,
  getCurrentSchoolYearOfLocation,
  getLessonWithCurrentTimeline,
  getMakeupPeriodInRequestedDate,
  getTimeDiffInMins,
  isRequestDateWithinSchoolYear,
  updatedMomentNow,
} from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import ConfirmationModal from "../../../../ConfirmationModal";
import AbsenceAndMakeUpItem from "../../shared/AbsenceAndMakeUpItem";
import { AbsencesSectionSpinnerWrapper, CustomSelectField } from "../../styled";

const AbsencesSection = ({
  student,
  currentLesson,
  makeUpData,
  setCurrentAbsenceId,
  makeupModalData,
  currentSchoolYearInLocation,
  schoolYears,
}) => {
  const { dates } = currentSchoolYearInLocation || {};
  const { summer_session_start, summer_session_end } = dates || {};

  const isDuringSA =
    summer_session_start &&
    summer_session_end &&
    updatedMomentNow().isBetween(
      summer_session_start.toDate(),
      summer_session_end.toDate(),
      "minutes",
      "[]"
    );
  // Current absence type (From select box filter)
  const [selectedAbsenceType, setSelectedAbsenceType] = useState(0);

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState("");

  const absenceDeleteConfirmationModalData = useModal();
  const makeupDeleteConfirmationModalData = useModal();
  const deleteAllAbsencesAndMakeupsConfirmationModalData = useModal();

  const lessonWithCurrentTimeline = useMemo(() => {
    if (!currentLesson) {
      return;
    }

    return getLessonWithCurrentTimeline({
      lesson: currentLesson,
      withApproximation: true,
    });
  }, [currentLesson]);

  const selectedSchoolYear = useMemo(() => {
    const schoolYear = schoolYears?.find(
      ({ id }) => id === selectedSchoolYearId
    );

    const currentSchoolYearInLessonLocation = getCurrentSchoolYearOfLocation(
      schoolYear,
      lessonWithCurrentTimeline?.currentTimeline?.locationId
    );

    return currentSchoolYearInLessonLocation;
  }, [selectedSchoolYearId, schoolYears, lessonWithCurrentTimeline]);

  const limitedSchoolYears = useMemo(() => {
    if (
      !schoolYears?.length ||
      !lessonWithCurrentTimeline?.currentTimeline?.locationId
    ) {
      return [];
    }
    const { prevSchoolYear, currentSchoolYear, nextSchoolYear } =
      getCombinedSchoolYears(
        schoolYears,
        lessonWithCurrentTimeline?.currentTimeline?.locationId
      );

    return [prevSchoolYear, currentSchoolYear, nextSchoolYear].filter(
      (el) => el
    );
  }, [schoolYears, lessonWithCurrentTimeline?.currentTimeline?.locationId]);

  const areChangesDetected =
    makeUpData.haveAbsencesChanged || makeUpData.haveMakeupLessonsChanged;

  let lessonAbsences = makeUpData.absencesList;
  // only show absences in current school year
  const filteredLessonAbsences = useMemo(
    () => filterLessonAbsences(lessonAbsences, selectedSchoolYear),
    [lessonAbsences, selectedSchoolYear]
  );

  // returns the absenceTypes with additional property for the number of absences for each type
  const absenceTypesWithAbsencesLength = useMemo(
    () =>
      Object.values(absenceTypes).map((absenceType) => {
        const absencesInAbsenceType = filteredLessonAbsences.filter(
          (absence) =>
            parseInt(absence.absenceType) === parseInt(absenceType.code)
        );
        return {
          ...absenceType,
          numberOfAbsences: absencesInAbsenceType.length,
        };
      }),
    [absenceTypes, filteredLessonAbsences]
  );
  const handleSelectedAbsenceTypeChange = (e) => {
    setSelectedAbsenceType(e.target.value);
  };
  const handleSelectedSchoolYearChange = (e) => {
    setSelectedSchoolYearId(e.target.value);
  };

  //Filters absences based on selected absence type, (if the value === 0 then return all absences)
  const selectedAbsences = useMemo(
    () =>
      filteredLessonAbsences
        .filter((absence) =>
          parseInt(selectedAbsenceType) === 0
            ? true
            : parseInt(absence.absenceType) === parseInt(selectedAbsenceType)
        )
        // sort ascendingly (oldest absence first)
        .sort((a, b) => {
          const firstAbsenceDate = a.date || a.startDate;
          const secondAbsenceDate = b.date || b.startDate;

          return getTimeDiffInMins(secondAbsenceDate, firstAbsenceDate);
        }),
    [filteredLessonAbsences, selectedAbsenceType]
  );

  // by default, the selected school year is the current one
  useEffect(() => {
    if (currentSchoolYearInLocation) {
      setSelectedSchoolYearId(currentSchoolYearInLocation?.id);
    }
  }, []);

  // modals fns
  const openAbsenceDeleteConfirmationModal = (absenceId) => {
    makeUpData.setCurrentAbsenceId(absenceId);
    absenceDeleteConfirmationModalData.openModal();
  };
  const handleApproveAbsenceDelete = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.deleteCurrentAbsence();
      absenceDeleteConfirmationModalData.closeModal();
    }
  };
  const handleDeclineAbsenceDelete = () => {
    absenceDeleteConfirmationModalData.closeModal();
  };
  const openMakeupDeleteConfirmationModal = (makeupId) => {
    makeUpData.setCurrentMakeupId(makeupId);
    makeupDeleteConfirmationModalData.openModal();
  };
  const handleApproveMakeupDelete = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.deleteCurrentMakeupLesson();
      makeupDeleteConfirmationModalData.closeModal();
    }
  };
  const handleDeclineMakeupDelete = () => {
    makeupDeleteConfirmationModalData.closeModal();
  };

  const handleApproveDeleteAllAbsencesAndMakeups = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.handleDeleteAllAbsencesAndMakeUps();
      deleteAllAbsencesAndMakeupsConfirmationModalData.closeModal();
    }
  };
  const handleDeclineDeleteAllAbsencesAndMakeups = () => {
    deleteAllAbsencesAndMakeupsConfirmationModalData.closeModal();
  };

  if (!currentSchoolYearInLocation) {
    toast.error("Can't find a current school year ");
    return <div></div>;
  }

  if (makeUpData.loadingInitialData) {
    return (
      <AbsencesSectionSpinnerWrapper>
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </AbsencesSectionSpinnerWrapper>
    );
  }

  return (
    <>
      <hr className="CenterLine" />
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Absence & Make Up</h3>
          <div>
            {areChangesDetected && (
              <PrimaryButtonWithLoading
                isLoading={makeUpData.loadingSaveData}
                onClick={makeUpData.handleSaveChanges}
                width="150px"
                padding="7px"
              >
                Save Changes
              </PrimaryButtonWithLoading>
            )}
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          style={{ gap: 15 }}
        >
          <div>
            <CustomSelectField
              value={selectedSchoolYearId}
              onChange={handleSelectedSchoolYearChange}
              width={"auto"}
            >
              <option value={""} disabled>
                Select School Year
              </option>
              {limitedSchoolYears?.map((schoolYear) => {
                const { id, title } = schoolYear;

                const summerSessionAppend =
                  isDuringSA && id === currentSchoolYearInLocation?.id
                    ? "(Summer Session)"
                    : "";
                const currentSchoolYearAppend =
                  id === currentSchoolYearInLocation?.id ? "(Current)" : "";
                return (
                  <option key={id} value={id}>
                    {`${title} ${summerSessionAppend} ${currentSchoolYearAppend}`}
                  </option>
                );
              })}
            </CustomSelectField>
          </div>

          <div>
            {/* Absence type select field */}
            <CustomSelectField
              value={selectedAbsenceType}
              onChange={handleSelectedAbsenceTypeChange}
              width={"200px"}
            >
              <option
                value={0}
              >{`All (${filteredLessonAbsences.length})`}</option>
              {absenceTypesWithAbsencesLength.map(
                ({ code, name, abbr, numberOfAbsences }) => (
                  <option key={code} value={code}>
                    {`${abbr} (${numberOfAbsences})`}
                  </option>
                )
              )}
            </CustomSelectField>
          </div>
        </div>

        {/* Absence type select field */}

        <div className="text-end mt-4 mb-5">
          <PrimaryButtonWithLoading
            isLoading={makeUpData.loadingSaveData}
            onClick={deleteAllAbsencesAndMakeupsConfirmationModalData.openModal}
            width="280px"
          >
            Delete All Absences & Make Ups
          </PrimaryButtonWithLoading>
        </div>
        {/* Absences And Make Ups List */}
        <div>
          {selectedAbsences.map((absence, i) => {
            return (
              <AbsenceAndMakeUpItem
                student={student}
                currentLesson={currentLesson}
                absence={absence}
                index={i}
                makeUpData={makeUpData}
                setCurrentAbsenceId={setCurrentAbsenceId}
                makeupModalData={makeupModalData}
                openAbsenceDeleteConfirmationModal={
                  openAbsenceDeleteConfirmationModal
                }
                openMakeupDeleteConfirmationModal={
                  openMakeupDeleteConfirmationModal
                }
              />
            );
          })}
        </div>
        <div className="text-end">
          {areChangesDetected && (
            <PrimaryButtonWithLoading
              isLoading={makeUpData.loadingSaveData}
              onClick={makeUpData.handleSaveChanges}
              width="235px;"
            >
              Save Absences/Make Ups
            </PrimaryButtonWithLoading>
          )}
        </div>

        {absenceDeleteConfirmationModalData.isModalOpen &&
          makeUpData.currentAbsenceId && (
            <ConfirmationModal
              onApprove={handleApproveAbsenceDelete}
              onCancel={handleDeclineAbsenceDelete}
              modalData={absenceDeleteConfirmationModalData}
              isSubmitting={makeUpData.loadingSaveData}
              approveBtnText={"Delete Absence"}
              cancelBtnText="Cancel"
              title="Delete Absence ?"
            />
          )}
        {makeupDeleteConfirmationModalData.isModalOpen &&
          makeUpData.currentMakeupId && (
            <ConfirmationModal
              onApprove={handleApproveMakeupDelete}
              onCancel={handleDeclineMakeupDelete}
              modalData={makeupDeleteConfirmationModalData}
              isSubmitting={makeUpData.loadingSaveData}
              approveBtnText={"Delete"}
              cancelBtnText="Cancel"
              title="Delete Make Up Lesson ?"
            />
          )}
        {deleteAllAbsencesAndMakeupsConfirmationModalData.isModalOpen && (
          <ConfirmationModal
            onApprove={handleApproveDeleteAllAbsencesAndMakeups}
            onCancel={handleDeclineDeleteAllAbsencesAndMakeups}
            modalData={deleteAllAbsencesAndMakeupsConfirmationModalData}
            isSubmitting={makeUpData.loadingSaveData}
            approveBtnText={"Confirm And Delete"}
            cancelBtnText="Cancel"
            title="Delete All Absences and Makeup Lessons ?"
            withCheckbox={true}
            checkboxDescription="I understand that this will delete all absences and make up lessons of this lesson."
          />
        )}
      </div>
    </>
  );
};

export default AbsencesSection;

const filterLessonAbsences = (lessonAbsences, currentSchoolYearInLocation) => {
  if (!lessonAbsences?.length || !currentSchoolYearInLocation) return [];

  const filteredAbsences = lessonAbsences.filter(({ date, startDate }) => {
    const absenceDate = date || startDate;
    const isAbsenceDateWithinSchoolYear = isRequestDateWithinSchoolYear(
      absenceDate,
      currentSchoolYearInLocation
    );

    return isAbsenceDateWithinSchoolYear;
    // const isAbsenceComingFromPastYear = moment(absenceDate).isBefore(
    //   currentSchoolYearInLocation?.dates?.start_date?.toDate()
    // );

    // // if the absence is coming from the past schoolyear (through a makeup period in the current year that overlaps with prev schoolyear ), we dont display it if passed makeup deadline
    // if (isAbsenceComingFromPastYear) {
    //   const absenceMakeupPeriod = getMakeupPeriodInRequestedDate(
    //     absenceDate,
    //     currentSchoolYearInLocation
    //   );
    //   const makeupPeriodDeadline = absenceMakeupPeriod?.deadline?.toDate
    //     ? absenceMakeupPeriod.deadline.toDate()
    //     : undefined;

    //   if (!makeupPeriodDeadline) return false;

    //   const isPassedMakeupDeadline =
    //     moment(absenceDate).isAfter(moment(makeupPeriodDeadline)) ||
    //     updatedMomentNow().isAfter(makeupPeriodDeadline);

    //   return isAbsenceDateWithinSchoolYear && !isPassedMakeupDeadline;
    // } else {
    //   return isAbsenceDateWithinSchoolYear;
    // }
  });
  return filteredAbsences;
};
