import LibraryLabeledCheckbox from "../../common/LibraryLabeledCheckbox";
import React, { useContext } from "react";
import { LibraryContext } from "../../common/libraryContext";
import styled from "styled-components";

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

const SelectAllItems = ({ isAllSelected }) => {
  const { selected, toggle } = useContext(LibraryContext);

  return (
    <ItemsContainer>
      <LibraryLabeledCheckbox
        id={"all_items"}
        label={"Select All"}
        onChange={toggle}
        checked={isAllSelected}
      />
      {selected.length ? (
        <div className={"px-3 text-muted"}>
          <span>{selected.length}</span>
          <span className={"ms-1"}>
            Item{selected.length > 1 && "s"} Selected
          </span>
        </div>
      ) : null}
    </ItemsContainer>
  );
};
export default SelectAllItems;
