import React, { useMemo, useState } from "react";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { InputWithLabel } from "src/utils/shared/shared";
import { isCompleteMakeupOpeningRow } from "../../../../../helperFns";
import moment from "moment";
import { AddNewDayBtn, SelectField } from "../styled";
import {
  getFullDateFromDateAndTimeInputs,
  getTimeDiffInMins,
  updatedMomentNow,
} from "src/utils/helpers";

const MakeupOpenings = ({
  makeupOpenings,
  setMakeupOpenings,
  locations,

  hasPermissionToEdit,
}) => {
  const [showPastOpenings, setShowPastOpenings] = useState(false);

  const handleAddNewDay = () => {
    setMakeupOpenings((oldVal) => [
      ...oldVal,
      {
        date: "",
        start: "",
        end: "",
        location: "",
        isVirtualOnly: false,
        isNew: true,
      },
    ]);
  };
  const handleDeleteDay = (index) => {
    setMakeupOpenings((oldVal) => oldVal.filter((day, i) => i !== index));
  };
  const handleDayChange = (e, i) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    let makeupOpeningsCopy = [...makeupOpenings];
    makeupOpeningsCopy[i][name] = value;
    if (!makeupOpeningsCopy[i].isNew) {
      makeupOpeningsCopy[i].isEdited = true;
    }
    setMakeupOpenings(makeupOpeningsCopy);
  };
  return (
    <div className="mt-5 mb-5   ">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className="me-3 fs-4">Makeup Lessons Openings</h2>
        </div>
        <AddNewDayBtn onClick={() => setShowPastOpenings((oldVal) => !oldVal)}>
          Show Hidden Past Days
        </AddNewDayBtn>
        {hasPermissionToEdit && (
          <AddNewDayBtn onClick={handleAddNewDay}>Add A New Day</AddNewDayBtn>
        )}
      </div>
      {!hasPermissionToEdit && (
        <div className="mt-3 mb-4">
          <h5 style={{ color: " #acacac" }}>
            For the change, please contact a super admin
          </h5>
        </div>
      )}
      {makeupOpenings.map((day, i) => {
        const { date, start, end } = day;
        const isEndTimeNextDay = moment(start, "HH:mm").isAfter(
          moment(end, "HH:mm"),
          "minutes"
        );
        const startDate =
          date && start ? getFullDateFromDateAndTimeInputs(date, start) : null;

        const isCompleteRow = isCompleteMakeupOpeningRow(day, [
          "isVirtualOnly",
        ]);
        const isPastOpening =
          getTimeDiffInMins(
            updatedMomentNow(),
            startDate || updatedMomentNow()
          ) < 0 && !day.isNew;

        const isDisabledField =
          !hasPermissionToEdit || (isCompleteRow && isPastOpening);

        if (isCompleteRow && isPastOpening && !showPastOpenings) {
          return null;
        }
        return (
          <div key={i}>
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <InputWithLabel
                  label="Date"
                  backgroundColor={isCompleteRow && isPastOpening && "#ababab"}
                  color={isCompleteRow && isPastOpening && "#fff"}
                  disabled={isDisabledField}
                  type="date"
                  name="date"
                  width="130px"
                  value={makeupOpenings[i].date}
                  onChange={(e) => handleDayChange(e, i)}
                />
              </div>
              <div>
                <InputWithLabel
                  label="Start"
                  backgroundColor={isCompleteRow && isPastOpening && "#ababab"}
                  color={isCompleteRow && isPastOpening && "#fff"}
                  disabled={isDisabledField}
                  type="time"
                  name="start"
                  width="115px"
                  value={makeupOpenings[i].start}
                  onChange={(e) => handleDayChange(e, i)}
                />
              </div>
              <div>
                <InputWithLabel
                  label={`End ${isEndTimeNextDay ? "(Next Day)" : ""}`}
                  backgroundColor={isCompleteRow && isPastOpening && "#ababab"}
                  color={isCompleteRow && isPastOpening && "#fff"}
                  disabled={isDisabledField}
                  type="time"
                  name="end"
                  width="115px"
                  value={makeupOpenings[i].end}
                  onChange={(e) => handleDayChange(e, i)}
                />
              </div>
              <SelectField
                name="location"
                backgroundColor={isCompleteRow && isPastOpening && "#ababab"}
                color={isCompleteRow && isPastOpening && "#fff"}
                disabled={isDisabledField}
                defaultValue=""
                value={makeupOpenings[i].location}
                onChange={(e) => handleDayChange(e, i)}
                width={"110px"}
              >
                <option value="" disabled>
                  Location
                </option>
                {locations.map((day) => (
                  <option key={day.id} value={day.id}>
                    {day.name}
                  </option>
                ))}
              </SelectField>

              {hasPermissionToEdit && (
                <div
                  onClick={() => handleDeleteDay(i)}
                  className="pb-2"
                  style={{ cursor: "pointer" }}
                >
                  <DeleteOff width={30} height={30} color="#8a8a8a" />
                </div>
              )}
            </div>
            <div className="mt-3 ps-1">
              <label>
                <input
                  disabled={isDisabledField}
                  type="checkbox"
                  className="me-1"
                  name="isVirtualOnly"
                  checked={makeupOpenings[i].isVirtualOnly}
                  onChange={(e) => handleDayChange(e, i)}
                />
                Virtual Only
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MakeupOpenings;
