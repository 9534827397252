import React from "react";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import {
  CustomCheckboxContainer,
  PrimaryButton,
} from "src/utils/shared/styled";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import useConfirmation from "./hooks/useConfirmation";

const ConfirmationModal = ({
  modalData,
  onApprove,
  onCancel,
  isSubmitting,
  title = "",
  approveBtnText = "Confirm",
  cancelBtnText = "Cancel",
  withCheckbox = false,
  checkboxDescription = "",
  extraButtons = [],
  // extraButton= {
  //     onClick: () => {},
  //     btnText: "",
  //     isShownIfCheckboxChecked: false,
  //   }
}) => {
  const { isChecked, onCheckboxChange } = useConfirmation();

  const showApproveButton = withCheckbox ? isChecked : true;
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="lg"
      >
        <CustomModalBody>
          <div className="mb-4 d-flex justify-content-center align-items-center">
            <h2 className="fs-4">{title}</h2>
          </div>
          {withCheckbox && (
            <CustomCheckboxContainer
              className={
                "mt-4 d-flex align-items-center justify-content-center"
              }
            >
              <input
                checked={isChecked}
                onChange={onCheckboxChange}
                type="checkbox"
                id="checkboxOption"
                name="checkboxOption"
              />
              <label className="ms-2" for="checkboxOption">
                {checkboxDescription || "Are you sure ?"}
              </label>
            </CustomCheckboxContainer>
          )}
          <div
            className="mt-4 d-flex justify-content-center"
            style={{ gap: 40 }}
          >
            <PrimaryButton
              onClick={onCancel}
              width="max-content"
              padding="12px 30px"
            >
              {cancelBtnText}
            </PrimaryButton>
            {showApproveButton && (
              <PrimaryButtonWithLoading
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={onApprove}
                width="max-content"
                padding="12px 30px"
              >
                {approveBtnText}
              </PrimaryButtonWithLoading>
            )}
            {extraButtons
              ?.filter(({ isShownIfCheckboxChecked }) =>
                withCheckbox ? isShownIfCheckboxChecked && isChecked : true
              )
              ?.map(({ onClick, btnText }) => {
                return (
                  <PrimaryButtonWithLoading
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={onClick}
                    width="max-content"
                    padding="12px 30px"
                  >
                    {btnText}
                  </PrimaryButtonWithLoading>
                );
              })}
          </div>
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default ConfirmationModal;
