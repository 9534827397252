import { List } from "antd";
import styled from "styled-components";

const StyledList = styled(List)`
  width: 100%;

  @media screen and (min-width: 1300px) {
    width: 300px;
  }
`;

const StyledListItem = styled(List.Item)`
  transition-duration: 150ms;
  cursor: pointer;
  border-radius: 1.3rem;

  background-color: ${({ active }) => (active ? "#33333333" : "")};

  &:hover {
    background-color: #33333333;
  }
`;

const ItemBadge = styled.div`
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  color: #fff;
  background-color: ${({ bgcolor }) => (bgcolor ? bgcolor : "")};
  border-radius: 12px;
  pointer-events: none;
`;

export { StyledList, StyledListItem, ItemBadge };
