import store from "src/stores/UserStore";
import { getUserMissingFields, stages, getStatusColor } from "../utils";

import { List } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import RouteContent from "src/components/common/RouteContent";
import { ProcessWrapper, StyledList, StyledTimeLine } from "./styled";
import { Link } from "react-router-dom";

const GuestProcess = () => {
  const { user } = store;

  const getDotIcon = (stage) => {
    if (user?.status === stage) {
      return <FieldTimeOutlined />;
    } else if (user?.status === "Declined") {
      return "";
    }

    return "";
  };

  const missingFields = getUserMissingFields(user);

  return (
    <RouteContent
      title={
        user.role.replace(/_/g, " ").replace("Applicant", "") +
        " application process"
      }
    >
      <ProcessWrapper>
        <p>Current status: {user?.status}</p>
        <StyledTimeLine
          mode="alternate"
          items={stages.map((stage) => ({
            color: getStatusColor(user, stage),
            dot: getDotIcon(stage),
            children: stage,
          }))}
        />
      </ProcessWrapper>
      {user?.emailVerified && missingFields?.length ? (
        <StyledList
          header="Missing information:"
          bordered
          dataSource={missingFields}
          renderItem={(item) => (
            <List.Item>
              <Link to={item.link} style={{ color: "red" }}>
                {item.field}
              </Link>
            </List.Item>
          )}
        />
      ) : null}
    </RouteContent>
  );
};

export default GuestProcess;
