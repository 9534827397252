import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import {
  LessonNotesContext,
  ViewHomeworkModalContext,
} from "src/components/common/Library/common/libraryContext";
import {
  HomeworkNoteContainer,
  HomeworkTextareaContainer,
} from "src/components/common/styled-components/lessonNotesStyledComponents";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const TeacherCommentSection = ({
  teacherComment,
  setTeacherComment,
  UserStore,
}) => {
  const user = UserStore.user;
  const {
    isOpen,
    close,
    homework,
    approveHomework,
    selectedUser,
    submitTeacherComment,
  } = useContext(ViewHomeworkModalContext);
  const { isEditable, userId, notes } = useContext(LessonNotesContext);

  const [isLoading, setIsLoading] = useState(false);

  const studentHomework =
    homework?.[isEditable ? selectedUser.id : userId] || {};

  const note = notes?.find(
    ({ id }) => homework?.noteId && id === homework.noteId
  );

  const isAbleToEditTeacherComment = user.uid === note?.createdBy;

  const onSaveTeacherCommentClick = async () => {
    try {
      setIsLoading(true);
      await submitTeacherComment(teacherComment);
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTeacherComment(studentHomework?.teacherComment || "");
  }, [studentHomework?.teacherComment]);
  return (
    <>
      <h6>Teacher's Comment </h6>
      {isAbleToEditTeacherComment ? (
        <HomeworkTextareaContainer
          height="130px"
          onChange={(e) => setTeacherComment(e.target.value)}
          value={teacherComment}
          className="mb-2"
        ></HomeworkTextareaContainer>
      ) : (
        <HomeworkNoteContainer className="mb-2">
          {teacherComment}
        </HomeworkNoteContainer>
      )}
    </>
  );
};

export default injectUserStore(TeacherCommentSection);
