export const groupClassesListViews = {
  active: {
    title: "Active",
    value: "active",
  },
  inactive: {
    title: "Inactive",
    value: "inactive",
  },
};
