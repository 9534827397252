import { Checkbox } from "antd";
import styled from "styled-components";

const LocationSelectWrapper = styled.div`
  margin: 48px 0;

  @media screen and (min-width: 768px) {
    margin: 48px;
  }

  h4 {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const BelmontLocation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    gap: 96px;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 480px;

  @media screen and (min-width: 1200px) {
    width: 600px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 20px;
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  border: 1px solid #681e46;
  padding: 48px;
  border-radius: 12px;
`;

export {
  LocationSelectWrapper,
  BelmontLocation,
  MapWrapper,
  StyledCheckbox,
  CheckboxesWrapper,
};
