import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const InformationModal = ({ title, body, close }) => {
  return (
    <Modal isOpen={true} centered={true} toggle={close}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody className={"p-4"}>
        <div className="alert alert-warning lead">{body}</div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          <Button className="bg-purple text-white" onClick={close}>
            Ok
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default InformationModal;
