import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";

const CustomMiniCalendar = ({ calendarKey, dateValue, onDateValueChange }) => {
  const updatedDateValue = dateValue ? new Date(dateValue) : null;

  if (updatedDateValue) {
    updatedDateValue.setFullYear(moment(dateValue).year());
    updatedDateValue.setMonth(moment(dateValue).month());
    updatedDateValue.setDate(moment(dateValue).date());
    updatedDateValue.setHours(moment(dateValue).hours());
    updatedDateValue.setMinutes(moment(dateValue).minutes());
    updatedDateValue.setSeconds(moment(dateValue).seconds());
  }

  return (
    <div>
      <Calendar
        calendarType="US"
        // the key is for re-initializing the calendar state so it updates when we change the big calendar
        key={calendarKey}
        // we manipulate the date for the value and onChange to support timezone (as the mini calendar uses local timezone while big calendar uses moment timezone bec of localizer)
        value={updatedDateValue}
        // value={dateValue}
        onChange={(date) => {
          // const localDateStr = moment.tz(date, moment.tz.guess()).toString();
          const updatedDate = moment()
            .set({
              year: date.getFullYear(), // using the local "date" value inside the moment tz
              month: date.getMonth(), // using the local "date" value inside the moment tz
              date: date.getDate(), // using the local "date" value inside the moment tz
              hours: date.getHours(),
              minutes: date.getMinutes(),
              seconds: date.getSeconds(),
              milliseconds: 0,
            })
            .toDate();
          // console.log({
          //   unModifiedDate: date,
          //   unModifiedDateStr: moment(date).toString(),
          //   updatedDate,
          //   updatedDateStr: moment(updatedDate).toString(),
          // });

          onDateValueChange(updatedDate);
        }}
      />
    </div>
  );
};

export default CustomMiniCalendar;
