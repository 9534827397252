import styled from "styled-components";

const SummaryWrapper = styled.div`
  textarea {
    width: 100%;
    min-height: 250px;
    padding: 8px;

    border-radius: 12px;
    outline: none;
    transition-duration: 150ms;
    resize: none;
    cursor: default;

    &:is(:hover, :focus) {
      border-color: #e357a2;
    }
  }

  img {
    width: 100%;
    margin-top: 12px;
  }

  ul {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    list-style: none;
  }
`;

export { SummaryWrapper };
