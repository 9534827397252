import {
  concertAgeGroupTypes,
  concertInstrumentTypes,
  concertSignupOptions,
  concertTeachingStudioTypes,
} from "src/constants/concertEnum";
import { concertPaymentOptions } from "src/constants/concertPaymentConstants";

export function isValidFirstPage(concertData) {
  let isValid = true;

  const requiredFields = [
    "title",
    "date",
    "startTime",
    "endTime",
    "locationId",
    "attachments",
    "city",
    "state",
    "zip",
    "level",
  ];

  for (let key of Object.keys(concertData)) {
    if (requiredFields.includes(key) && !concertData[key]) {
      isValid = false;
      break;
    } else if (
      key === "teachingStudios" &&
      concertData.teachingStudios.type ===
        concertTeachingStudioTypes.SPECIFIC &&
      !concertData.teachingStudios.value?.length
    ) {
      isValid = false;
      break;
    } else if (
      key === "ageGroup" &&
      concertData.ageGroup.type === concertAgeGroupTypes.LIMITED &&
      (!concertData.ageGroup.min || !concertData.ageGroup.max)
    ) {
      isValid = false;
      break;
    } else if (
      key === "signupDeadline" &&
      concertData.signupDeadline.isEnabled &&
      !concertData.signupDeadline.value
    ) {
      isValid = false;
      break;
    }
  }

  return isValid;
}

export function isValidSecondPage(concertData) {
  const signUpTypesErr =
    concertData.signUpType.type === concertSignupOptions.LIMITED &&
    !concertData.signUpType.value.length;

  const waitingListErr =
    concertData.waitingList.isEnabled && !concertData.waitingList.value;

  const equipmentError = concertData.equipmentList.some(({ name }) => !name);

  const minutesPerProgramErr = !concertData.minsPerProgram;

  const instrumentsError =
    concertData.instruments.type === concertInstrumentTypes.LIMITED &&
    !concertData.instruments.value.length;

  const isValid =
    !signUpTypesErr &&
    !waitingListErr &&
    !equipmentError &&
    !minutesPerProgramErr &&
    !instrumentsError;

  return isValid;
}

function isMoreThanZero(no) {
  if (!no) return false;
  return no > 0;
}
export function isValidPaymentPage(paymentData) {
  const { paymentOption, paymentRates, customRates } = paymentData;
  if (paymentOption === concertPaymentOptions.FREE) {
    return true;
  }

  const filteredPaymentRates = paymentRates.filter(
    ({ isEnabled }) => isEnabled
  );
  if (!filteredPaymentRates.length && !customRates.length) return false;

  loop1: for (const rate of filteredPaymentRates) {
    const {
      title,
      value: rateValue,
      name,
      isEnabled,
      hasOptions,
      options,
    } = rate;
    if (!isEnabled) continue;

    if (!hasOptions && !isMoreThanZero(rateValue)) {
      return false;
    }
    if (hasOptions) {
      loop2: for (const option of options) {
        if (!isMoreThanZero(option.value)) return false;
      }
    }
  }

  for (const customRate of customRates) {
    const { title, value } = customRate;
    if (!title || !isMoreThanZero(value)) {
      return false;
    }
  }

  return true;
}

export function parsePaymentData(paymentData) {
  if (!paymentData) return;
  const parsedRates = paymentData?.paymentRates
    .filter(({ isEnabled }) => isEnabled)
    .map((rate) => {
      const { isEnabled, title, ...restRate } = rate;
      const parsedOptions = rate.options?.map((option) => {
        const { title, ...restOption } = option;
        return {
          ...restOption,
          value: parseInt(option.value),
        };
      });
      return {
        ...restRate,
        ...(rate.value && { value: parseInt(rate.value) }),
        ...(rate.hasOptions && { options: parsedOptions }),
      };
    });

  const parsedCustomRates = paymentData.customRates.map((rate) => {
    return {
      ...rate,
      value: parseInt(rate.value),
    };
  });

  return {
    ...paymentData,
    paymentOption: parseInt(paymentData.paymentOption),
    paymentRates: parsedRates,
    customRates: parsedCustomRates,
  };
}
