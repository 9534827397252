import { Card } from "antd";
import styled from "styled-components";

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 16px 8px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  } ;
`;

const StyledCard = styled(Card)`
  text-align: center;
  font-size: 16px;

  background-color: ${({ current }) => (current ? "#681e4633" : "")};

  .ant-card-head-title {
    font-size: 18px;
  }

  &:hover {
    border-color: ${({ hoverable }) => hoverable && "#681e46aa"};
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
  } ;
`;

export { CardsWrapper, StyledCard };
