export const absenceTypes = {
  regular: { code: 1, abbr: "REG", name: "Regular" },
  sameDayCancelation: { code: 2, abbr: "SDC", name: "Same Day Cancelation" },
  emergencyAbsence: { code: 3, abbr: "EA", name: "Emergency Absence" },
  teacherAbsence: { code: 4, abbr: "TA", name: "Teacher Absence" },
  teacherEmergencyAbsence: {
    code: 5,
    abbr: "TEA",
    name: "Teacher Emergency Absence",
  },
  summerAbsence: { code: 6, abbr: "SA", name: "Summer Absence" },
  schoolEventAbsence: { code: 7, abbr: "SEA", name: "School Event Absence" },
  withdrawalAbsence: { code: 8, abbr: "WA", name: "Withdrawal Absence" },
  missedAbsence: { code: 9, abbr: "MA", name: "Missed Absence" },
  techEmergencyAbsence: {
    code: 10,
    abbr: "TechEA",
    name: "Tech Emergency Absence",
  },
  graceCreditAbsence: {
    code: 11,
    abbr: "GC",
    name: "Grace Credit",
  },
  credited: {
    code: 12,
    abbr: "Credited",
    name: "Credited",
  },
};

export const absenceStatuses = {
  SUBMITTED: 1,
  IN_PROGRESS: 2,
  APPROVED: 3,
  DECLINED: 4,
  PARTIALLY_APPROVED: 5,
};

export const absenceBehaviours = {
  PER_LESSON: 1,
  PER_DURATION: 2,
};

// for TA
export const absenceMakeUpOptions = {
  MAKEUP: 1,
  SUBSTITUTE: 2,
};

// Example of an absence object

export const pendingAbsenceStatusesArr = [
  absenceStatuses.SUBMITTED,
  absenceStatuses.IN_PROGRESS,
];

// absence types that we calculate the max student absences by
export const makeupLimitAbsenceTypes = [
  absenceTypes.regular.code,
  absenceTypes.sameDayCancelation.code,
  absenceTypes.emergencyAbsence.code,
];
export const absenceTypesThatBypassmakeupDeadline = [
  absenceTypes.teacherAbsence.code,
  absenceTypes.teacherEmergencyAbsence.code,
  absenceTypes.graceCreditAbsence.code,
  absenceTypes.credited.code,
];

export const absencesThatShowOnCalendar = [
  absenceTypes.sameDayCancelation.code,
  absenceTypes.emergencyAbsence.code,
  absenceTypes.missedAbsence.code,
  absenceTypes.techEmergencyAbsence.code,
  absenceTypes.graceCreditAbsence.code,
  absenceTypes.credited.code,
];
export const absenceLessonTypes = {
  PRIVATE_LESSON: "privateLesson",
  GROUP_CLASS: "group_class",
};

export const isAbsenceBypassMakeupDeadline = (absenceType) => {
  return absenceTypesThatBypassmakeupDeadline.includes(parseInt(absenceType));
};
export const isAbsenceShownOnCalendar = (absenceType) => {
  return absencesThatShowOnCalendar.includes(parseInt(absenceType));
};

// absence status fns
export const isSubmittedAbsence = (status) => {
  return parseInt(status) === absenceStatuses.SUBMITTED;
};
export const isInProgressAbsence = (status) => {
  return parseInt(status) === absenceStatuses.IN_PROGRESS;
};
export const isApprovedAbsence = (status) => {
  return parseInt(status) === absenceStatuses.APPROVED;
};
export const isDeclinedAbsence = (status) => {
  return parseInt(status) === absenceStatuses.DECLINED;
};
export const isPartiallyApprovedAbsence = (status) => {
  return parseInt(status) === absenceStatuses.PARTIALLY_APPROVED;
};

export const isPendingAbsence = (status) => {
  return pendingAbsenceStatusesArr.includes(parseInt(status));
};
export const isResolvedAbsence = (status) => {
  return !pendingAbsenceStatusesArr.includes(parseInt(status));
};

//absence behaviour fns
export const isPerLessonAbsence = (behaviour) => {
  return parseInt(behaviour) === absenceBehaviours.PER_LESSON;
};
export const isPerDurationAbsence = (behaviour) => {
  return parseInt(behaviour) === absenceBehaviours.PER_DURATION;
};

// absence types fns
export const isTeacherAbsence = (type) => {
  return parseInt(type) === absenceTypes.teacherAbsence.code;
};
export const isRegularAbsence = (type) => {
  return parseInt(type) === absenceTypes.regular.code;
};
export const isSDCAbsence = (type) => {
  return parseInt(type) === absenceTypes.sameDayCancelation.code;
};
export const isEmergencyAbsence = (type) => {
  return parseInt(type) === absenceTypes.emergencyAbsence.code;
};
export const isTeacherEmergencyAbsence = (type) => {
  return parseInt(type) === absenceTypes.teacherEmergencyAbsence.code;
};
export const isMissedAbsence = (type) => {
  return parseInt(type) === absenceTypes.missedAbsence.code;
};
export const isWithdrawalAbsence = (type) => {
  return parseInt(type) === absenceTypes.withdrawalAbsence.code;
};
export const isTechEaAbsence = (type) => {
  return parseInt(type) === absenceTypes.techEmergencyAbsence.code;
};
export const isGraceCreditAbsence = (type) => {
  return parseInt(type) === absenceTypes.graceCreditAbsence.code;
};
export const isSummerAbsence = (type) => {
  return parseInt(type) === absenceTypes.summerAbsence.code;
};
export const isCreditedAbsence = (type) => {
  return parseInt(type) === absenceTypes.credited.code;
};
export const isGroupClassAbsence = (lessonType) => {
  return absenceLessonTypes.GROUP_CLASS === lessonType;
};
