import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  USER_DISABLED,
  USER_NOT_FOUND,
  WRONG_PASSWORD,
} from "src/components/Auth/constants";
import signInSchema from "./signInSchema";

import { Formik } from "formik";
import { ModalBody } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FirebaseContext } from "src/components/Firebase";
import {
  StyledModal,
  StyledModalHeader,
  InputWrapper,
  ReCaptchaWrapper,
} from "../styled";
import { InputWithLabel } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";
import { ErrorMessage, SignUpForm } from "../SignUpModal/styled";

const SignInModal = ({ isOpen, toggle }) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const firebase = useContext(FirebaseContext);

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
      <StyledModalHeader toggle={toggle}>
        <span>Sign in into guest account</span>
      </StyledModalHeader>
      <ModalBody>
        <p style={{ color: "red" }}>Sign in into existing account</p>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={signInSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const { email, password } = values;
            setSubmitting(true);

            if (!captchaVerified) {
              setErrors({ captcha: "Please verify you're not a robot" });
              setSubmitting(false);
              return;
            }

            try {
              const normalizedEmail = email.toLowerCase();

              await firebase.signInWithEmailAndPassword(
                normalizedEmail,
                password
              );

              setSubmitting(false);
              navigate("/dashboard/guest-profile");
            } catch (error) {
              if (error.code === USER_NOT_FOUND) {
                setErrors({ email: "User not found" });
              } else if (error.code === USER_DISABLED) {
                toast.error("User disabled");
              } else if (error.code === WRONG_PASSWORD) {
                setErrors({ password: "Wrong password" });
              } else {
                toast.error(error.message);
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <SignUpForm>
              <InputWrapper>
                <InputWithLabel
                  name="email"
                  type="email"
                  error={errors.email}
                  touched={touched.email}
                  label="Email"
                  placeholder="example@email.com"
                />
              </InputWrapper>
              <InputWrapper>
                <InputWithLabel
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  error={errors.password}
                  touched={touched.password}
                  label="Password"
                  placeholder="Password"
                  onShowClick={() =>
                    setPasswordShown((prevState) => !prevState)
                  }
                  withPasswordShowBtn
                />
              </InputWrapper>
              <ReCaptchaWrapper>
                <ReCAPTCHA
                  sitekey="6LfMfMMpAAAAAH2ySnp37hfNFvKSgb2GwhbjqmkV"
                  onChange={(value) => setCaptchaVerified(!!value)}
                />
              </ReCaptchaWrapper>
              <ErrorMessage>{errors.captcha}</ErrorMessage>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </SignUpForm>
          )}
        </Formik>
      </ModalBody>
    </StyledModal>
  );
};

export default SignInModal;
