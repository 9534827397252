/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
import React, { useContext, useRef, useState, useEffect } from "react";
import * as yup from "yup";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import "./style.css";
import { TrialLessonsContext } from "./TrialLessons";
import {
  adultValidationSchema,
  emailValidationSchema,
  phoneValidationSchema,
  requiredStringSchema,
  requiredIDStringSchema,
  zipValidationSchema,
  dateValidationSchema,
} from "../../../utils/validations";
import { toast } from "react-toastify";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "../../../utils/styled";
import { Form, Formik } from "formik";
import moment from "moment";
import { UserRole } from "../../../constants/UserRoleEnum";
import { FirebaseContext } from "../../Firebase";
import SubmitSpinner from "../../common/SubmitSpinner";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import CreateStudentTrialLesson from "./CreateStudentTrialLesson";
import CustomDropDown from "../../common/DropDown";
import handlePhoneChange from "../common/handlePhoneChange";
import { PrimaryButton } from "src/utils/shared/styled";
import { getDocs, query, where } from "firebase/firestore";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import useDraftStudent from "./hooks/useDraftStudent";
import { updatedMomentNow } from "src/utils/helpers";
const trialStudentValidationSchema = yup.object().shape({
  birthday: dateValidationSchema,
  name: requiredStringSchema,

  primaryContactEmail: emailValidationSchema,
  primaryContactName: requiredStringSchema,
  primaryPhone: phoneValidationSchema,
});

const CustomSelectField = styled.select`
  display: block;
  width: 300px;
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fbf8f9";
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
const ImportPrivateStudentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;
const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const RightIcon = styled(CaretRightFill)`
  color: #fff;
  width: 20px;
  position: absolute;
  top: 10%;
  right: 15px;
`;
const TrialStudentModal = ({
  isOpen = false,
  onClose = () => {},
  onSubmit,
  mode,
  onAddStudentSuccess,
  initialValues = {
    birthday: "",
    name: "",

    primaryContactEmail: "",
    primaryContactName: "",
    primaryPhone: "",

    secondaryContactEmail: "",
    secondaryContactName: "",
    secondPhone: "",
  },
}) => {
  const firebase = useContext(FirebaseContext);
  const {
    checkIfStudentCanBeSavedAsDraft,
    saveStudentAsDraft,
    isSubmitting: isSubmittingDraftStudent,
  } = useDraftStudent();

  const [isImportPrivateStudentEnabled, setIsImportPrivateStudentEnabled] =
    useState(false);

  // states for importing pl students
  const [privateStudents, setPrivateStudents] = useState([]);
  const [loadingPrivateStudents, setLoadingPrivateStudents] = useState(false);
  const [selectedPrivateStudentId, setSelectedPrivateStudentId] = useState("");

  const toggleImportPrivateStudents = () => {
    setSelectedPrivateStudentId(null);
    setIsImportPrivateStudentEnabled((oldVal) => !oldVal);
  };

  // fetching private students if import is enabled
  useEffect(() => {
    if (isImportPrivateStudentEnabled) {
      const fetchPrivateStudents = async () => {
        try {
          setLoadingPrivateStudents(true);
          const q = query(
            firebase.users(),
            where("role", "==", UserRole.STUDENT)
          );
          const privateStudentsList = parseFirebaseDoc((await getDocs(q)).docs);
          setPrivateStudents(privateStudentsList);
        } catch (err) {
          console.log(err);
          toast.error(err?.message);
        } finally {
          setLoadingPrivateStudents(false);
        }
      };
      fetchPrivateStudents();
    }
  }, [isImportPrivateStudentEnabled]);

  const selectedPrivateStudentObj = privateStudents.find(
    ({ id }) => id === selectedPrivateStudentId
  );

  const selectedPrivateStudentInitialValues = selectedPrivateStudentObj
    ? {
        birthday: selectedPrivateStudentObj.birthday,
        name: selectedPrivateStudentObj.fullName,

        primaryContactEmail: selectedPrivateStudentObj.primaryContactEmail,
        primaryContactName: selectedPrivateStudentObj.primaryContactName,
        primaryPhone: selectedPrivateStudentObj.primaryPhone,

        secondaryContactEmail: selectedPrivateStudentObj.secondaryContactEmail,
        secondaryContactName: selectedPrivateStudentObj.secondaryContactName,
        secondPhone: selectedPrivateStudentObj.secondPhone,
      }
    : null;

  return (
    <CustomModal centered isOpen={isOpen} size={"lg"}>
      <CustomModalHeader
        close={<CloseIcon onClick={onClose} />}
      ></CustomModalHeader>
      <CustomModalBody className="">
        <ImportPrivateStudentContainer>
          {/* only show the Import Private student btn if we are creating a new trial student */}
          {!loadingPrivateStudents &&
            mode !== "edit" &&
            (isImportPrivateStudentEnabled ? (
              <>
                <CustomSelectField
                  value={selectedPrivateStudentId}
                  onChange={(e) => setSelectedPrivateStudentId(e.target.value)}
                >
                  <option>Student</option>
                  {privateStudents.map((student) => (
                    <option key={student.id} value={student.id}>
                      {student.fullName}
                    </option>
                  ))}
                </CustomSelectField>
                <PrimaryButton
                  onClick={toggleImportPrivateStudents}
                  backgroundColor="#d31717"
                >
                  Cancel
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton onClick={toggleImportPrivateStudents}>
                Import Private Student
              </PrimaryButton>
            ))}
        </ImportPrivateStudentContainer>
        <Formik
          enableReinitialize
          initialValues={selectedPrivateStudentInitialValues || initialValues}
          validationSchema={trialStudentValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <div>
                <div className="InputGroup row mb-3">
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="name">Name of Student</label>
                      <input
                        className="InputField w-100"
                        name={"name"}
                        placeholder="Name of Student"
                        value={values.name}
                        onChange={(e) => {
                          handleChange("name")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.name && errors.name && (
                        <span className="errorMsg">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="birthday">birthday</label>
                      <input
                        className="InputField w-100"
                        name="birthday"
                        placeholder="Birthday"
                        type="date"
                        max={updatedMomentNow().format("YYYY-MM-DD")}
                        min={updatedMomentNow()
                          .subtract(100, "years")
                          .format("YYYY-MM-DD")}
                        value={values.birthday}
                        onChange={(e) => {
                          handleChange("birthday")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.birthday && errors.birthday && (
                        <span className="errorMsg">{errors.birthday}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="InputGroup row mb-3">
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="primaryEmail">Primary Email</label>
                      <input
                        className="InputField w-100"
                        name={"primaryEmail"}
                        placeholder="Primary Email"
                        value={values.primaryEmail}
                        onChange={(e) => {
                          handleChange("primaryEmail")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.primaryEmail && errors.primaryEmail && (
                        <span className="errorMsg">{errors.primaryEmail}</span>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="InputGroup row mb-3">
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="primaryContactEmail">
                        Primary Contact Email
                      </label>
                      <input
                        className="InputField w-100"
                        name={"primaryContactEmail"}
                        placeholder="Primary Contact Email"
                        value={values.primaryContactEmail}
                        onChange={(e) => {
                          handleChange("primaryContactEmail")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.primaryContactEmail &&
                        errors.primaryContactEmail && (
                          <span className="errorMsg">
                            {errors.primaryContactEmail}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="primaryContactName">
                        Primary Contact Name
                      </label>
                      <input
                        className="InputField w-100"
                        name={"primaryContactName"}
                        placeholder="Primary Contact Name"
                        value={values.primaryContactName}
                        onChange={(e) => {
                          handleChange("primaryContactName")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.primaryContactName &&
                        errors.primaryContactName && (
                          <span className="errorMsg">
                            {errors.primaryContactName}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="primaryPhone">Primary Phone</label>
                      <input
                        className="InputField w-100"
                        name={"primaryPhone"}
                        placeholder="Primary Phone"
                        value={values.primaryPhone}
                        maxLength="14"
                        onChange={(e) => {
                          handleChange("primaryPhone")(e);
                          setFieldValue(
                            "primaryPhone",
                            handlePhoneChange(e.target.value)
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.primaryPhone && errors.primaryPhone && (
                        <span className="errorMsg">
                          {errors.primaryPhone ? "invalid phone number" : " "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="InputGroup row mb-3">
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="secondaryContactEmail">
                        2nd Contact Email
                      </label>
                      <input
                        className="InputField w-100"
                        name={"secondaryContactEmail"}
                        placeholder="2nd Contact Email"
                        value={values.secondaryContactEmail}
                        onChange={(e) => {
                          handleChange("secondaryContactEmail")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.secondaryContactEmail &&
                        errors.secondaryContactEmail && (
                          <span className="errorMsg">
                            {errors.secondaryContactEmail}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="secondaryContactName">
                        2nd Contact Name
                      </label>
                      <input
                        className="InputField w-100"
                        name={"secondaryContactName"}
                        placeholder="2nd Contact Name"
                        value={values.secondaryContactName}
                        onChange={(e) => {
                          handleChange("secondaryContactName")(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.secondaryContactName &&
                        errors.secondaryContactName && (
                          <span className="errorMsg">
                            {errors.secondaryContactName}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputContainer flex-1">
                      <label htmlFor="secondPhone">2nd Phone</label>
                      <input
                        className="InputField w-100"
                        name={"secondPhone"}
                        placeholder="2nd Phone"
                        value={values.secondPhone}
                        maxLength="14"
                        onChange={(e) => {
                          handleChange("secondPhone")(e);
                          setFieldValue(
                            "secondPhone",
                            handlePhoneChange(e.target.value)
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.secondPhone && errors.secondPhone && (
                        <span className="errorMsg">
                          {errors.secondPhone ? "invalid phone number" : " "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                {mode !== "edit" && (
                  <button
                    className="mt-3 mb-3 submitButton"
                    disabled={isSubmittingDraftStudent}
                    style={{ width: "45%", margin: "auto" }}
                    onClick={() =>
                      saveStudentAsDraft(values, (createdDoc) => {
                        onAddStudentSuccess(createdDoc);
                        onClose();
                      })
                    }
                  >
                    {isSubmittingDraftStudent ? (
                      <SubmitSpinner
                        style={{ width: "1rem", height: "1rem" }}
                      />
                    ) : (
                      <div className="w-100 position-relative">
                        <span className="buttonSpan">Save As Draft</span>
                        <RightIcon className="buttonicon" />
                      </div>
                    )}
                  </button>
                )}
                <button
                  className="mt-3 mb-3 submitButton"
                  disabled={isSubmitting}
                  type="submit"
                  style={{ width: "45%", margin: "auto" }}
                >
                  {isSubmitting ? (
                    <SubmitSpinner style={{ width: "1rem", height: "1rem" }} />
                  ) : (
                    <div className="w-100 position-relative">
                      {mode === "edit" ? (
                        <span className="buttonicon">Save</span>
                      ) : (
                        <>
                          <span className="buttonSpan">
                            Save And Create Trial Lesson
                          </span>
                          <RightIcon className="buttonicon" />
                        </>
                      )}
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModalBody>
    </CustomModal>
  );
};
export default TrialStudentModal;
