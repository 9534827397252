import { useParams } from "react-router";
import NavMenu from "../../common/NavMenu";

const OurTeamPersonalPage = () => {
  const { id } = useParams();

  return (
    <main className="our-faculty">
      <NavMenu />
      {id}
    </main>
  );
};

export default OurTeamPersonalPage;
