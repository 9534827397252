import { inject, observer } from "mobx-react";
import React from "react";
import { compose } from "recompose";
import RouteContent from "src/components/common/RouteContent";
import { PrimaryButton } from "src/utils/shared/styled";
import AbsenceTable from "./AbsenceTable";
import useAbsenceData from "./hooks/useAbsenceData";
import { Container } from "./styled";
import { toJS } from "mobx";
import useModal from "src/hooks/useModal";
import moment from "moment";
import { getFilterStartAndEndDate } from "src/utils/helpers";
import TeacherAbsenceRequestModal from "../../common/components/TeacherAbsenceRequestModal";

const START_DATE_FILTER_MONTH = 7;
const START_DATE_FILTER_DAY = 1;

const TeacherAbsences = ({ UserStore }) => {
  const user = toJS(UserStore)?.user;

  const absenceData = useAbsenceData({ teacherId: user?.uid });

  const absenceRequestModalData = useModal();
  const makeUpRequestModalData = useModal();

  const { startDateFilter, endDateFilter } = getFilterStartAndEndDate(
    START_DATE_FILTER_MONTH,
    START_DATE_FILTER_DAY
  );
  const displayedAbsenceDatesStr = `Absence From ${moment(
    startDateFilter
  ).format("MM-DD-YYYY")} To ${moment(endDateFilter).format("MM-DD-YYYY")}`;
  const displayedAbsences = absenceData.initialData.teacherTAs?.filter(
    ({ date, startDate }) =>
      moment(date || startDate).isBetween(
        startDateFilter,
        endDateFilter,
        undefined,
        "[]"
      )
  );

  return (
    <RouteContent title="Absence/Make Up">
      <Container>
        <div
          className="d-flex align-items-center justify-content-between mt-4 mb-4 flex-wrap"
          style={{ gap: "20px" }}
        >
          <div className="d-flex align-items-center">
            <div className="me-3">
              <PrimaryButton onClick={absenceRequestModalData.openModal}>
                Absence Request
              </PrimaryButton>
            </div>
            {/* <div>
              <PrimaryButton>Make Up Request</PrimaryButton>
            </div> */}
          </div>
          <p className="ms-3 mb-0">{displayedAbsenceDatesStr}</p>
        </div>
        <div className="">
          <AbsenceTable
            absenceData={absenceData}
            displayedAbsences={displayedAbsences}
          />
        </div>
      </Container>

      {/* Modals */}
      {absenceRequestModalData.isModalOpen && (
        <TeacherAbsenceRequestModal
          modalData={absenceRequestModalData}
          onAbsenceRequestSuccess={absenceData.refreshData}
          teacher={{ ...user, id: user.uid }}
          limitDatePicker={true}
          submitButtonText="Request Absence"
        />
      )}
    </RouteContent>
  );
};

export default compose(inject("UserStore"), observer)(TeacherAbsences);
