import React from "react";
import { reportDatesFilters } from "../../../../constants";
import DatePeriodSelection from "./components/DatePeriodSelection";
import DateRangeSelection from "../../../../../DateRangeSelection";

const DateFiltersSection = ({
  selectedDateFilterType,
  setSelectedDateFilterType,
  selectedFilterObj,
  handleFilterObjChange,
}) => {
  const renderSelectedFilterTypeComponent = () => {
    if (selectedDateFilterType === reportDatesFilters.BY_RANGE) {
      const handleDateRangeChange = (selecteedRange) => {
        handleFilterObjChange(
          reportDatesFilters.BY_RANGE,
          "selectedRange",
          selecteedRange
        );
      };
      return (
        <DateRangeSelection
          selectedDateRange={
            selectedFilterObj[reportDatesFilters.BY_RANGE]?.selectedRange
          }
          setSelectedDateRange={handleDateRangeChange}
        />
      );
    } else {
      function handlePeriodFilterChange(value) {
        return handleFilterObjChange(
          reportDatesFilters.BY_PERIOD,
          "selectedFilter",
          value || ""
        );
      }
      function handleSelectedMonthChange(value) {
        const modifiedValue =
          value === undefined || value === null ? "" : value;
        return handleFilterObjChange(
          reportDatesFilters.BY_PERIOD,
          "selectedMonth",
          modifiedValue
        );
      }
      return (
        <DatePeriodSelection
          selectedFilter={
            selectedFilterObj[reportDatesFilters.BY_PERIOD]?.selectedFilter
          }
          setSelectedFilter={handlePeriodFilterChange}
          selectedMonth={
            selectedFilterObj[reportDatesFilters.BY_PERIOD]?.selectedMonth
          }
          handleSelectedMonthChange={handleSelectedMonthChange}
        />
      );
    }
  };
  return (
    <div>
      <div className="d-flex flex-wrap" style={{ gap: 20 }}>
        <label>
          <input
            type="radio"
            checked={selectedDateFilterType === reportDatesFilters.BY_RANGE}
            onChange={(e) =>
              setSelectedDateFilterType(reportDatesFilters.BY_RANGE)
            }
          />{" "}
          Select Range
        </label>
        <label>
          <input
            type="radio"
            checked={selectedDateFilterType === reportDatesFilters.BY_PERIOD}
            onChange={(e) =>
              setSelectedDateFilterType(reportDatesFilters.BY_PERIOD)
            }
          />{" "}
          Select Period
        </label>
      </div>
      <div className="mt-3">{renderSelectedFilterTypeComponent()}</div>
    </div>
  );
};

export default DateFiltersSection;
