import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const initialFormData = {
  expirationDate: null,
};
const usePackageLessonExpiration = ({
  lessonId,
  setNumber,
  onSuccess,
  user,
}) => {
  const { getPrivateLessonsByIds, updatePrivateLesson } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    privateLesson: null,
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [loadingSave, setLoadingSave] = useState(false);

  function handleFormChange(name, value) {
    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  async function saveChanges() {
    try {
      if (
        !initialData.privateLesson ||
        !formData.expirationDate ||
        !setNumber
      ) {
        toast.warn("please add expiration date");
        return;
      }

      setLoadingSave(true);
      const expirationFullDate = formData.expirationDate.toDate();

      const updatedPackageSets = initialData.privateLesson.packageSets.map(
        (set) => {
          if (set.setNumber === setNumber) {
            const updatedSet = {
              ...set,
              expirationDate: expirationFullDate,
            };
            return updatedSet;
          } else {
            return set;
          }
        }
      );
      const updateObj = {
        packageSets: updatedPackageSets,
      };

      await updatePrivateLesson(lessonId, updateObj);
      console.log({ lessonId, updateObj });
      toast.success("updated successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSave(false);
    }
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [[privateLesson]] = await Promise.all([
          getPrivateLessonsByIds([lessonId]),
        ]);
        setInitialData({
          privateLesson,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!initialData.privateLesson) return;

    const currentSetExpirationDate = initialData.privateLesson.packageSets.find(
      (set) => set.setNumber === setNumber
    )?.expirationDate;

    if (!currentSetExpirationDate) return;

    handleFormChange("expirationDate", moment(currentSetExpirationDate));
  }, [initialData]);

  return {
    initialData,
    loadingInitialData,
    loadingSave,
    formData,
    handleFormChange,
    saveChanges,
  };
};

export default usePackageLessonExpiration;
