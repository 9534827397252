import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import {
  InfoContainer,
  TimelineContainer,
  DeleteIcon,
  EditIcon,
  InfoText,
} from "../../styled";

const SummerBreakItem = ({
  summerBreak,
  handleDeleteSummerBreakClick,
  handleEditSummerBreakeClick,
}) => {
  const startDate = summerBreak.breakStart;
  const lastDate = summerBreak.breakEnd;

  const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
  const formattedLastDate = lastDate
    ? moment(lastDate).format("MM-DD-YYYY")
    : "In Progress";

  return (
    <TimelineContainer
      borderTop={lastDate ? "4px solid #681e46" : "4px solid #3dba00"}
      className="mb-3 "
    >
      <div>
        <h5 className="text-center">Summer Break</h5>
      </div>
      <div className="d-flex justify-content-end mb-3" style={{ gap: 10 }}>
        <div>
          <EditIcon
            onClick={() => handleEditSummerBreakeClick(summerBreak.id)}
          />
        </div>
        <div>
          <DeleteIcon
            onClick={() => handleDeleteSummerBreakClick(summerBreak.id)}
          />
        </div>
      </div>
      <InfoContainer>
        <InfoText bold className="me-2">
          Started On:
        </InfoText>
        <InfoText>{formattedStartDate}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Returning date:
        </InfoText>
        <InfoText>{formattedLastDate}</InfoText>
      </InfoContainer>
    </TimelineContainer>
  );
};

export default SummerBreakItem;
