import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";

const SidebarContext = React.createContext();

const SidebarContextProvider = ({ children, UserStore, ...props }) => {
  const user = toJS(UserStore.user);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSideBar = () => setIsSidebarOpen((oldVal) => !oldVal);
  const closeSidebar = () => setIsSidebarOpen(false);
  const openSidebar = () => setIsSidebarOpen(true);

  const contextValues = React.useMemo(
    () => ({
      isSidebarOpen,
      toggleSideBar,
      closeSidebar,
      openSidebar,
    }),
    [isSidebarOpen]
  );

  return (
    <SidebarContext.Provider value={contextValues} {...props}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebarContext() {
  const context = React.useContext(SidebarContext);

  return context;
}

export default compose(inject("UserStore"), observer)(SidebarContextProvider);
