import React, { useMemo, useState } from "react";
import moment from "moment";
import {
  getAbsenceMakeUpStatusData,
  getMakeupPeriodInRequestedDate,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  isRequestDateWithinSchoolYear,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  absenceTypes,
  isCreditedAbsence,
  isSDCAbsence,
  makeupLimitAbsenceTypes,
} from "src/constants/absenceTypes";
import {
  CustomTable,
  CustomTableContainer,
  PrimaryButton,
  TableRow,
} from "src/utils/shared/styled";
import { toast } from "react-toastify";
import { parseDate } from "../helperFns";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import DropdownContent from "./DropdownContent";
import CustomDropdown from "./CustomDropdown";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { getCurrentSchoolYear } from "src/utils/helpers";
import AbsencesManualDropdown from "src/components/Dashboard/common/components/AbsencesManualDropdown";
import {
  isDeclinedMakeupRequest,
  isPendingMakeupRequest,
} from "src/constants/makeupRequestEnum";
import { CustomTD } from "../styled";

const AbsenceTable = ({
  lesson,
  initialData,
  openMakeUpModal,
  studentId,
  hasReachedMaxMakeUps,
  selectedLessonAbsences,
}) => {
  const isPackageLsn = isPackagePrivateLesson(lesson.type);

  const absencesWithTheirMakeUps = useMemo(
    () =>
      selectedLessonAbsences.map((absence) => {
        const absenceDate = absence.date || absence.startDate;
        const { duration: privateLessonDuration, locationId } =
          getPrivateLessonInfoOnSpecificDate({
            privateLesson: lesson,
            date: absenceDate,
            withTimelineApproximation: true,
          });

        let makeUpPeriodDeadline;
        if (isPackageLsn) {
          // no make up deadline for package lsns so we set the deadline to a very far in the future date
          makeUpPeriodDeadline = updatedMomentNow().add(5, "years");
        } else {
          const currentSchoolYearInLocation = getCurrentSchoolYear(
            initialData.schoolYears,
            locationId
          );
          // calculating makeup period based on the absence date
          const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
            absenceDate,
            currentSchoolYearInLocation
          );
          makeUpPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
            ? currentMakeupPeriod?.deadline?.toDate()
            : null;
        }

        // the makeup lessons for this absence (filtered by using the same student and lesson)
        const absenceMakeUps = initialData.makeUpLessons.filter(
          (makeup) =>
            makeup.forAbsenceId === absence.id &&
            makeup.studentId === studentId &&
            makeup.forLessonId === lesson.id
        );

        const { absenceMakeUpStatus, absenceTotalMakeUpDuration } =
          getAbsenceMakeUpStatusData(
            absence,
            absenceMakeUps,
            privateLessonDuration,
            makeUpPeriodDeadline
          );

        return {
          ...absence,
          absenceMakeUps,
          absenceTotalMakeUpDuration,
          absenceMakeUpStatus,
        };
      }),
    [selectedLessonAbsences, initialData.makeUpLessons]
  );

  const handleMakeUpButtonClick = (absenceId, absenceMakeUpStatus) => {
    if (absenceMakeUpStatus === absenceMakeUpStatuses.notMadeUpFor.code) {
      openMakeUpModal(absenceId);
    }
  };

  return (
    <CustomTableContainer>
      <CustomTable>
        <thead>
          <TableRow>
            <th>
              <div>Request Date</div>
            </th>
            <th>
              <div>Absence Date</div>
            </th>
            <th>
              <div>Lesson Time</div>
            </th>
            <th>
              <div>
                Absence Type <AbsencesManualDropdown />
              </div>
            </th>
            <th>
              <div>Booking Make Up</div>
            </th>
          </TableRow>
        </thead>
        <tbody>
          {absencesWithTheirMakeUps.map((absence, i) => {
            const absenceType = Object.values(absenceTypes).find(
              (type) => type.code === absence.absenceType
            )?.abbr;

            const absenceDate = absence.date || absence.startDate;

            const { startDate: lessonStartDate } =
              getPrivateLessonInfoOnSpecificDate({
                privateLesson: lesson,
                date: absenceDate,
                withTimelineApproximation: true,
              });
            const lessonStartTime = lessonStartDate
              ? moment(lessonStartDate).format("h:mm a")
              : null;

            const absenceStatusObj = Object.values(absenceMakeUpStatuses).find(
              ({ code }) => code === absence.absenceMakeUpStatus
            );

            const withMakeUpsInfoDropdown = [
              absenceMakeUpStatuses.fullyMadeUpFor.code,
              absenceMakeUpStatuses.fullyScheduled.code,
              absenceMakeUpStatuses.partiallyMadeUpFor.code,
              absenceMakeUpStatuses.partiallyScheduled.code,
            ].includes(absence.absenceMakeUpStatus);

            const reachedMaxMakeupsAbsence =
              [absenceMakeUpStatuses.notMadeUpFor.code].includes(
                absence.absenceMakeUpStatus
              ) &&
              makeupLimitAbsenceTypes.includes(absence.absenceType) &&
              hasReachedMaxMakeUps;

            const showReachedMaxMakeupsBtn =
              reachedMaxMakeupsAbsence && !absence.makeupRequest;

            const showMakeupRequestPendingBtn =
              reachedMaxMakeupsAbsence &&
              isPendingMakeupRequest(absence.makeupRequest?.status);

            const showDeclinedMakeupRequestBtn =
              reachedMaxMakeupsAbsence &&
              isDeclinedMakeupRequest(absence.makeupRequest?.status);

            const showCreditedAbsenceButton = isCreditedAbsence(
              absence.absenceType
            );

            const isOutsideDateLimit = moment(absenceDate).isAfter(
              updatedMomentNow().add(1, "month"),
              "minutes"
            );
            return (
              <TableRow key={absence.id}>
                <td>{parseDate(absence.createdAt)}</td>
                <td>{parseDate(absenceDate)}</td>
                <td>{lessonStartTime}</td>
                <td>{absenceType}</td>
                <CustomTD>
                  {showCreditedAbsenceButton ? (
                    <PrimaryButton backgroundColor={"#0284aa"}>
                      Credit
                    </PrimaryButton>
                  ) : isSDCAbsence(absence.absenceType) ? (
                    <PrimaryButton backgroundColor={"#6e6e6e"}>
                      Not Applicable
                    </PrimaryButton>
                  ) : withMakeUpsInfoDropdown ? (
                    <CustomDropdown
                      dropdownToggleComponent={() => (
                        <PrimaryButton
                          backgroundColor={absenceStatusObj?.bgColor || "black"}
                          onClick={() =>
                            handleMakeUpButtonClick(
                              absence.id,
                              absence.absenceMakeUpStatus
                            )
                          }
                        >
                          {absenceStatusObj.description1}
                        </PrimaryButton>
                      )}
                      dropdownContent={() => (
                        <DropdownContent
                          makeupLessons={absence.absenceMakeUps}
                        />
                      )}
                    />
                  ) : showReachedMaxMakeupsBtn ? (
                    <CustomDropdown
                      dropdownToggleComponent={() => (
                        <PrimaryButton
                          onClick={() =>
                            handleMakeUpButtonClick(
                              absence.id,
                              absence.absenceMakeUpStatus
                            )
                          }
                          backgroundColor={"#bd2c7b"}
                        >
                          Make Up Options
                        </PrimaryButton>
                      )}
                      dropdownContent={() => (
                        <div
                          className="pb-4 p-3"
                          style={{ background: "#0a0a0c", color: "#e6e6e6" }}
                        >
                          <p className="m-0">
                            You reached your maximum number of make ups,
                            Additional make up is available for pay
                          </p>
                        </div>
                      )}
                    />
                  ) : showMakeupRequestPendingBtn ? (
                    <PrimaryButton backgroundColor={"#4f65cd"}>
                      Pending Approval
                    </PrimaryButton>
                  ) : showDeclinedMakeupRequestBtn ? (
                    <CustomDropdown
                      dropdownToggleComponent={() => (
                        <PrimaryButton backgroundColor={"#686868"}>
                          Not Available
                        </PrimaryButton>
                      )}
                      dropdownContent={() => (
                        <div
                          className="pb-4 p-3"
                          style={{ background: "#0a0a0c", color: "#e6e6e6" }}
                        >
                          <p className="m-0">
                            {absence.makeupRequest?.resolve?.reason ||
                              "No Reason Provided"}
                          </p>
                        </div>
                      )}
                    />
                  ) : isOutsideDateLimit ? (
                    <CustomDropdown
                      dropdownToggleComponent={() => (
                        <PrimaryButton backgroundColor={"#f9ae04"}>
                          Booking Later
                        </PrimaryButton>
                      )}
                      dropdownContent={() => (
                        <div
                          className="pb-4 p-3"
                          style={{ background: "#0a0a0c", color: "#e6e6e6" }}
                        >
                          <p className="m-0">
                            The Make Up is available to book 1 month prior
                          </p>
                        </div>
                      )}
                    />
                  ) : (
                    <PrimaryButton
                      backgroundColor={absenceStatusObj?.bgColor || "black"}
                      onClick={() =>
                        handleMakeUpButtonClick(
                          absence.id,
                          absence.absenceMakeUpStatus
                        )
                      }
                    >
                      {absenceStatusObj.description1}
                    </PrimaryButton>
                  )}
                </CustomTD>
              </TableRow>
            );
          })}
        </tbody>
      </CustomTable>
    </CustomTableContainer>
  );
};

export default AbsenceTable;
