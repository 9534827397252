import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Table = styled.table`
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  color: #000000;
  overflow-y: scroll;
  font-weight: bold;
`;

const THead = styled.thead`
  background-color: #f7f3f3;
  position: sticky;
  top: 0;
`;

const TH = styled.th`
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  padding: 20px;
  text-align: center;
`;

const TD = styled.td`
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  padding: 10px;
  text-align: center;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Searchbar = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000014;
  border-radius: 30px;
  opacity: 1;
  width: 300px;
  padding-left: 15px;
  border: none;
`;

const SortButton = styled.button`
  border: none;

  &:hover {
    svg {
      color: #681e46;
    }
  }
`;

export {
  FilterContainer,
  Image,
  Searchbar,
  TD,
  TH,
  Table,
  TableWrapper,
  THead,
  SortButton,
};
