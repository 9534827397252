import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { Spinner } from "../../common";
import SubmitSpinner from "../../common/SubmitSpinner";
import { getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const { firebase, UserStore } = props;
    // useEffect(
    //   () =>
    //     firebase.onAuthUserListener(
    //       (authUser) => {
    //         // TODO check if localstorage has different user
    //         localStorage.setItem("authUser", JSON.stringify(authUser));
    //         UserStore.setUser(authUser);
    //       },
    //       () => {
    //         localStorage.removeItem("authUser");
    //         UserStore.setUser(null);
    //       }
    //     ),
    //   []
    // );
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(
        firebase.auth,
        async (authUser) => {
          UserStore.setLoadingUser();
          if (authUser) {
            const doc = await getDoc(firebase.user(authUser.uid));
            if (doc.exists()) {
              const dbUser = doc.data();
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
              UserStore.setUser(authUser);
            } else {
              firebase.signOut();
              console.log(`No such document! users/${authUser.uid}`);
            }
            UserStore.stopLoadingUser();
          } else {
            UserStore.setUser(null);
            UserStore.stopLoadingUser();
          }
        }
      );

      return unsubscribe;
    }, []);
    return UserStore.isLoaded ? <Component {...props} /> : <Spinner />;
  };
  return compose(
    withFirebase,
    inject("UserStore"),
    observer
  )(WithAuthentication);
};

export default withAuthentication;
