import React from "react";
import { CustomFormInput, SecondaryText } from "../styled";
import { concertProgramSoloPerformanceTypes } from "src/constants/concertEnum";
import { RequiredInputSpan } from "src/utils/shared/styled";

const PerformanceTypeSection = ({ programData, handleProgramDataChange }) => {
  return (
    <>
      <SecondaryText className="mb-2">
        Performance Type
        <RequiredInputSpan>*</RequiredInputSpan>
      </SecondaryText>
      <div>
        <div className="mb-2">
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              checked={
                programData.performanceType.type ===
                concertProgramSoloPerformanceTypes.SOLO
              }
              onChange={() =>
                handleProgramDataChange(
                  "performanceType.type",
                  concertProgramSoloPerformanceTypes.SOLO
                )
              }
            />
            Solo
          </label>
        </div>
        <div className="mb-2">
          <label>
            <input
              type="radio"
              className="me-2"
              checked={
                programData.performanceType.type ===
                concertProgramSoloPerformanceTypes.SOLO_WITH_ACCOMPANIMENT
              }
              onChange={() =>
                handleProgramDataChange(
                  "performanceType.type",
                  concertProgramSoloPerformanceTypes.SOLO_WITH_ACCOMPANIMENT
                )
              }
            />
            Solo w/piano accompaniment
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              className="me-2"
              checked={
                programData.performanceType.type ===
                concertProgramSoloPerformanceTypes.OTHER
              }
              onChange={() =>
                handleProgramDataChange(
                  "performanceType.type",
                  concertProgramSoloPerformanceTypes.OTHER
                )
              }
            />
            Other
            <CustomFormInput
              disabledBgColor="#f2f2f2"
              disabled={
                programData.performanceType.type !==
                concertProgramSoloPerformanceTypes.OTHER
              }
              onChange={(e) =>
                handleProgramDataChange("performanceType.value", e.target.value)
              }
              className="ms-2"
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default PerformanceTypeSection;
