import moment from "moment";
import { isPrivateLessonEvent } from "../../../../constants/eventsEnum";
import { getTimeDiffInMins, updatedMomentNow } from "../../../../utils/helpers";

export const attachAvailableDaysToTeachers = (
  teachers = {},
  availableDays = [],
  locations = {},
  teachersMakeUpOpenings = []
) => {
  if (!Object.keys(teachers).length) return {};
  availableDays?.forEach((daysObj) => {
    const teacherId = daysObj?.id;
    const teacher = teachers[teacherId];
    if (teacher) {
      const teacherWithDay = {
        ...teacher,
        teacherDays: {
          ...daysObj,
          availableDays: daysObj.availableDays.map((availableDay) => ({
            ...availableDay,
            location: locations[availableDay.location],
          })),
        },
        makeupOpenings: teachersMakeUpOpenings
          ?.filter(({ userId }) => userId === teacher.id)
          .map((opening) => ({
            ...opening,
            startDate: opening.startDate.toDate(),
            endDate: opening.endDate.toDate(),
            location: locations[opening.location],
          })),
      };
      teachers[teacherId] = teacherWithDay;
    }
  });
  return teachers;
};
export const addInstrumentsToTeachers = (
  teachers = {},
  instruments = {},
  programs = {}
) => {
  if (!Object.keys(teachers).length) return {};
  if (Object.values(instruments).length) {
    const teachersWithInstruments = Object.values(teachers).map((teacher) => ({
      ...teacher,
      instrumentsInfo: teacher.instrumentsInfo?.map((instrumentInfo) => ({
        ...instrumentInfo,
        instrument: instruments[instrumentInfo?.instrument],
        program: programs[instrumentInfo?.program],
      })),
    }));
    const teachersObj = teachersWithInstruments?.reduce((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});
    return teachersObj;
  } else {
    return teachers;
  }
};

export const isStudentAbleToRequestAbsence = (event) => {
  const { eventCode, start, isModifiedEvent } = event;

  let canRequestAbsence = false;
  const isPrivateLesson = isPrivateLessonEvent(eventCode);
  const isFutureEvent =
    getTimeDiffInMins(updatedMomentNow(), moment(start)) > 0;

  canRequestAbsence =
    isPrivateLesson && isFutureEvent && !event.isPLAbsenceEvent;
  // && !isModifiedEvent;

  return canRequestAbsence;
};

export const filterInitialDataByLocation = (initialData, locationId) => {
  const {
    studentPLs = [],
    studentTLs = [],
    studentMakeupLessons = [],
  } = initialData;
  const filteredTLs = studentTLs.filter((tl) =>
    tl.locations?.includes(locationId)
  );
  const filteredMakeupLessons = studentMakeupLessons.filter(
    (makeUp) => makeUp.locationId === locationId
  );

  return {
    ...initialData,
    studentTLs: filteredTLs,
    studentMakeupLessons: filteredMakeupLessons,
  };
};
