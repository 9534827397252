import React from "react";
import { CurrentStudentsListContainer } from "./styled";
import SearchSection from "./SearchSection";
import StudentRow from "./StudentRow";

const CurrentStudent = ({
  students,
  selectedCurrentStudent,
  setPaymentStudentId,
  currentStudentsSearchTerm,
  setCurrentStudentsSearchTerm,
}) => {
  return (
    <div>
      <CurrentStudentsListContainer>
        <div className="mb-5">
          <SearchSection
            value={currentStudentsSearchTerm}
            onChange={setCurrentStudentsSearchTerm}
          />
        </div>
        {students.map(({ id, fullName }) => (
          <StudentRow
            id={id}
            key={id}
            checked={selectedCurrentStudent?.id === id}
            onChange={() => setPaymentStudentId(id)}
            label={fullName}
          />
        ))}
      </CurrentStudentsListContainer>
    </div>
  );
};

export default CurrentStudent;
