// description1 is used mainly by the student users
// description2 is for admins
export const absenceMakeUpStatuses = {
  notMadeUpFor: {
    code: 1,
    description1: "Booking",
    description2: "Make Up Needed",
    bgColor: "#681e46",
  },
  fullyScheduled: {
    code: 2,
    description1: "Scheduled",
    description2: "Scheduled",
    bgColor: "#00c809",
  },
  fullyMadeUpFor: {
    code: 3,
    description1: "Completed",
    description2: "Completed",
    bgColor: "#00c0c4",
  },
  partiallyScheduled: {
    code: 4,
    description1: "Partially Scheduled",
    description2: "Partially Scheduled",
    bgColor: "#c8c300",
  },
  // partial make up => if the partial makeup date has passed then its partially madeup for
  partiallyMadeUpFor: {
    code: 5,
    description1: "Partially Completed",
    description2: "Partially Completed",
    bgColor: "#c8c300",
  },
  // partial make up => if the makeup deadline date has passed the absence date then its expired
  expired: {
    code: 6,
    description1: "Expired",
    description2: "Expired",
    bgColor: "#f20808",
  },
  noMakeUpDeadline: {
    code: 7,
    description1: "No Make Up Deadline",
    description2: "No Make Up Deadline",
    bgColor: "#000",
  },
};
