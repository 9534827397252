import React from "react";
import ConfirmationModal from "src/components/Dashboard/common/components/ConfirmationModal";
import { modalMap } from "../../utils/constants";
import useDeleteLibraryItems from "./hooks/useDeleteLibraryItems";

const DeleteTeacherLibraryItemsModal = ({
  modalData,
  teacher,
  refreshData,
}) => {
  const { loadingAction, deleteTeacherLibraryItems } = useDeleteLibraryItems({
    teacherId: teacher?.id,
  });

  const onApprove = async () => {
    await deleteTeacherLibraryItems();
    modalData.closeModal();
    refreshData();
  };
  const onCancel = () => {
    modalData.closeModal();
  };

  const confirmationModalData = {
    isModalOpen: modalData.isModalNameOpen(
      modalMap.deleteTeacherLibraryItems.name
    ),
    openModal: () =>
      modalData.openModalWithName(modalMap.deleteTeacherLibraryItems.name),
    closeModal: () => modalData.closeModal(),
    toggleModal: () => modalData.toggleModal(),
  };
  return (
    <ConfirmationModal
      isSubmitting={loadingAction}
      modalData={confirmationModalData}
      onApprove={onApprove}
      onCancel={onCancel}
      approveBtnText="Delete"
      cancelBtnText="Cancel"
      title={`Delete Teacher library items`}
    />
  );
};

export default DeleteTeacherLibraryItemsModal;
