export const eventsMap = {
  trialLesson: {
    code: 1,
    name: "Trial Lesson",
  },
  privateLesson: {
    code: 2,
    name: "Private Lesson",
  },
  recurringBreak: {
    code: 3,
    name: "Recurring Break",
  },
  oneTimeBreak: {
    code: 4,
    name: "One Time Break",
  },
  makeupLesson: {
    code: 5,
    name: "Makeup Lesson",
  },
  appointment: {
    code: 6,
    name: "Appointment",
  },
  calendarLabel: {
    code: 7,
    name: "Calendar Label",
  },
  recurringCalendarLabel: {
    code: 8,
    name: "Recurring Calendar Label",
  },
  groupClass: {
    code: 9,
    name: "Group Class",
  },
};

export const MAX_ITEMS_IN_PACKAGE_LESSON_SET = 10;

const recurringEventsCodes = [
  eventsMap.privateLesson.code,
  eventsMap.recurringBreak.code,
  eventsMap.recurringCalendarLabel.code,
];

export const privateLessonTypes = {
  REGULAR: "regular",
  PACKAGE: "package",
};

export const isPrivateLessonEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.privateLesson.code;
};
export const isRegularPrivateLesson = (lessonType) => {
  return lessonType === privateLessonTypes.REGULAR;
};
export const isPackagePrivateLesson = (lessonType) => {
  return lessonType === privateLessonTypes.PACKAGE;
};
export const isTrialLessonEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.trialLesson.code;
};
export const isRecurringBreakEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.recurringBreak.code;
};
export const isOneTimeBreakEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.oneTimeBreak.code;
};
export const isMakeupLessonEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.makeupLesson.code;
};
export const isAppointmentEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.appointment.code;
};
export const isRecurringEvent = (eventCode, privateLessonType) => {
  const isPackagePrivateLessonEvent = isPackagePrivateLesson(privateLessonType);

  return (
    recurringEventsCodes.includes(parseInt(eventCode)) &&
    !isPackagePrivateLessonEvent
  );
};

export const isSingleTimeCalendarLabelEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.calendarLabel.code;
};
export const isRecurringCalendarLabelEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.recurringCalendarLabel.code;
};
export const isGroupClassEvent = (eventCode) => {
  return parseInt(eventCode) === eventsMap.groupClass.code;
};
