import React from "react";
import { injectUserStore } from "src/utils/helpers";
import { NavButton } from "src/utils/shared/styled";
import { getUserSecondaryTabs } from "../../../constants";
import { useFeedSectionContext } from "../../../contexts/FeedSectionContext";

const SecondaryNav = ({ UserStore }) => {
  const { currentSecondaryTab, setCurrentSecondaryTab } =
    useFeedSectionContext();
  const userRole = UserStore.user?.role;
  const userExtraFunctions = UserStore.user?.extraFunctions;
  const tabs = getUserSecondaryTabs(userRole, userExtraFunctions);

  return (
    <div className="d-flex align-items-center" style={{ overflow: "auto" }}>
      {tabs.map(({ title, key }) => (
        <div key={key}>
          <NavButton
            paddingTop="0px"
            fontSize="0.8rem"
            className={currentSecondaryTab === key ? "active" : undefined}
            onClick={() => setCurrentSecondaryTab(key)}
            withRedBar
          >
            {title}
          </NavButton>
        </div>
      ))}
    </div>
  );
};

export default injectUserStore(SecondaryNav);
