import React from "react";
import RouteContent from "../../../components/common/RouteContent";
import StudentLessonNotes from "./StudentLessonNotes";
const StudentLessonNotesRoute = ({ user }) => {
  return (
    <RouteContent title={"Lesson Notes"}>
      <StudentLessonNotes user={user} />
    </RouteContent>
  );
};
export default StudentLessonNotesRoute;
