import React from "react";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import {
  CustomCheckboxContainer,
  PrimaryButton,
} from "src/utils/shared/styled";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import ConcertRecommendationBody from "./ConcertRecommendationBody";
import useConcertRecommendation from "./hooks/useConcertRecommendation";
import { injectUserStore } from "src/utils/helpers";

const ConcertRecommendationModal = ({
  onInvitationSuccess,
  modalData,
  concertId,
  UserStore,
}) => {
  const user = UserStore.user;

  const hookData = useConcertRecommendation({
    onInvitationSuccess,
    concertId,
    user,
  });
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="lg"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>Recommendation</h2>
        </CustomModalHeader>
        <CustomModalBody>
          <ConcertRecommendationBody
            closeModal={modalData.closeModal}
            hookData={hookData}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(ConcertRecommendationModal);
