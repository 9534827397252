import { InputLabel, Input, ErrorMessage } from "./StyledComponents";
export const InputWithLabel = ({
  label = "",
  type = "text",
  placeholder = "",
  error = "",
  touched = false,
  children,
  ...inputProps
}) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Input {...inputProps} type={type} placeholder={placeholder}>
        {children}
      </Input>
      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default InputWithLabel;
