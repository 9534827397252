import { Select } from "antd";
import dayOptions from "./options";

const TeacherDaySelect = ({ onChange, value }) => {
  return (
    <Select
      style={{ width: "100%" }}
      mode="multiple"
      placeholder="Select days"
      allowClear
      options={dayOptions}
      onChange={onChange}
      defaultValue={value}
    />
  );
};

export default TeacherDaySelect;
