import { feedItems } from "src/constants/feedItemsEnum";
import {
  isAdmin,
  isStudent,
  isSuperAdmin,
  isTeacher,
} from "src/constants/UserRoleEnum";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import { haveFeatureExtraFunction } from "src/utils/helpers";

export const mainNavTabs = Object.values(feedItems).map((item) => ({
  title: item.title,
  key: item.type,
}));

export const secondaryNavTabKeys = {
  ALL: "all",
  BOOKMARKS: "bookmarks",
  HIDDEN: "hidden",
  SCHEDULED: "scheduled",
};
export const secondaryNavTabs = [
  {
    title: "All",
    key: secondaryNavTabKeys.ALL,
  },
  {
    title: "Bookmarks",
    key: secondaryNavTabKeys.BOOKMARKS,
  },
  {
    title: "Hidden",
    key: secondaryNavTabKeys.HIDDEN,
  },
  {
    title: "Scheduled",
    key: secondaryNavTabKeys.SCHEDULED,
  },
];

export const dropdownOptionsValues = {
  BOOKMARK: "bookmark",
  UNBOOKMARK: "unbookmark",
  PIN: "pin",
  UNPIN: "unpin",
  HIDE: "hide",
  RESTORE: "restore",
  EDIT: "edit",
  DELETE: "delete",
  DOWNLOAD_ATTACHMENT: "download_attachment",
};

export const getUserMainTabs = (userRole) => {
  if (!userRole) return [];
  const isAdminUser = isAdmin(userRole);
  const isSuperAdminUser = isSuperAdmin(userRole);
  const isTeacherUser = isTeacher(userRole);
  const isStudentUser = isStudent(userRole);

  if (isAdminUser || isSuperAdminUser) {
    return mainNavTabs;
  } else {
    return mainNavTabs.filter(({ key }) => key !== feedItems.workUpdates.type);
  }
};

export const getUserSecondaryTabs = (userRole, userExtraFunctions) => {
  if (!userRole) return [];
  const isAdminUser = isAdmin(userRole);
  const isSuperAdminUser = isSuperAdmin(userRole);
  const isTeacherUser = isTeacher(userRole);
  const isStudentUser = isStudent(userRole);
  const canSeeScheduledTab =
    isAdminUser &&
    haveFeatureExtraFunction(
      userExtraFunctions,
      adminsExtraFunctions.createFeedItems
    );

  if (isSuperAdminUser || canSeeScheduledTab) {
    return secondaryNavTabs;
  } else {
    return secondaryNavTabs.filter(
      ({ key }) => key !== secondaryNavTabKeys.SCHEDULED
    );
  }
};
