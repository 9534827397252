import moment from "moment";
import React from "react";
import { getAgeFromDate, injectUserStore } from "src/utils/helpers";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import { ActionIconContainer } from "./styled";
import { AddCircle } from "@styled-icons/fluentui-system-regular/AddCircle";
import useModal from "src/hooks/useModal";
import CreateConcertSoloProgramModal from "../../../CreateConcertSoloProgramModal";
import {
  isFreeForAllStudentsConcert,
  isTeacherRecommendationConcert,
} from "src/constants/concertEnum";
import {
  hasAdminCredentials,
  isAdmin,
  isTeacher,
} from "src/constants/UserRoleEnum";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import ConfirmationModal from "../../../ConfirmationModal";
import { concertSignupOptions } from "src/constants/concertEnum";
const SignupsTable = ({
  refreshData,
  onActionSuccess,
  hookData,
  UserStore,
}) => {
  const user = UserStore.user;
  const isTeacherUser = isTeacher(user.role);
  const isAdminUser = hasAdminCredentials(user.role);

  const {
    initialData,
    loadingInitialData,
    soloProgramSignups,
    specialrogramSignups,
    currentSignup,
    setCurrentSignupId,
    deleteCurrentConcertSignup,
    loadingAction,
  } = hookData;
  const {
    concertSignups,
    users,
    signupUsersPLs,
    instruments,
    concert,
    signupsInvitations,
  } = initialData;

  const showAddProgram =
    (isTeacherUser &&
      isTeacherRecommendationConcert(concert?.availableSignupOptions?.value)) ||
    isAdminUser;

  const createProgramModalData = useModal();
  const deleteSignupModalData = useModal();

  function onAddProgramClick(signupId) {
    createProgramModalData.openModal();
    setCurrentSignupId(signupId);
  }
  function onSignupDeleteClick(signupId) {
    deleteSignupModalData.openModal();
    setCurrentSignupId(signupId);
  }

  const onDeleteModalApprove = async () => {
    await deleteCurrentConcertSignup();
    deleteSignupModalData.closeModal();
    setCurrentSignupId("");
  };

  const onDeleteModalCancel = async () => {
    deleteSignupModalData.closeModal();
    setCurrentSignupId("");
  };
  return (
    <div>
      {!!soloProgramSignups.length && (
        <div
          style={{
            overflow: "auto",
          }}
        >
          <CustomTableContainer minHeight="0px">
            <h4>Solo Program Sign Ups</h4>
            <hr />
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <CustomTable>
                <thead style={{ backgroundColor: "#f5f2f4" }}>
                  <TableRow height="70px">
                    <th>
                      <div>No.</div>
                    </th>
                    <th>
                      <div>Student Name</div>
                    </th>
                    <th>
                      <div>Age</div>
                    </th>
                    <th>
                      <div>Instrument</div>
                    </th>
                    <th>
                      <div>Payment</div>
                    </th>
                    {isAdminUser && (
                      <th>
                        <div>Invited By</div>
                      </th>
                    )}
                    <th>
                      <div>Program</div>
                    </th>
                  </TableRow>
                </thead>
                <tbody>
                  {soloProgramSignups.map((signup, i) => {
                    // preparing table values
                    const {
                      createdAt,
                      userId,
                      concertId,
                      programGroupId,
                      programType,
                      id,
                    } = signup;

                    const userPrivateLesson = signupUsersPLs.find(
                      ({ teachersIds, studentId }) =>
                        teachersIds.includes(user.uid) && userId === studentId
                    );
                    const lessonInstrumentId = userPrivateLesson?.instrumentId;
                    const instrumentName = instruments.find(
                      ({ id }) => id === lessonInstrumentId
                    )?.name;

                    const signupUser = users.find(({ id }) => id === userId);
                    const userName = signupUser.fullName;

                    const userAge =
                      signupUser.birthday &&
                      getAgeFromDate(
                        moment(signupUser.birthday, "YYYY-MM-DD").toDate()
                      );

                    const formattedCreatedAt = createdAt
                      ? moment(createdAt).format("MM-DD-YYYY hh:mm A")
                      : "N/A";

                    const invitationObj = signupsInvitations.find(
                      ({ id }) => id === signup.invitationId
                    );
                    const inviterUser = invitationObj
                      ? users.find(({ id }) => id === invitationObj.createdBy)
                      : undefined;
                    const invitedByName = !invitationObj
                      ? "Self Sign Up"
                      : inviterUser?.fullName || "N/A";

                    const isEvenRow = i % 2 === 0;

                    return (
                      <TableRow
                        height="70px"
                        style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                        key={i}
                      >
                        <td>{i+1}</td>

                        <ClickableTableCell onClick={() => {}}>
                          {userName || "N/A"}
                        </ClickableTableCell>
                        <td>{userAge || "N/A"}</td>

                        <td>{instrumentName || "N/A"}</td>
                        <td>{"N/A"}</td>
                        {isAdminUser && <td>{invitedByName}</td>}
                        <td>
                          {showAddProgram && (
                            <ActionIconContainer>
                              <AddCircle
                                onClick={() => onAddProgramClick(id)}
                              />
                              {isAdminUser && (
                                <DeleteOff
                                  onClick={() => onSignupDeleteClick(id)}
                                />
                              )}
                            </ActionIconContainer>
                          )}
                        </td>
                      </TableRow>
                    );
                  })}
                </tbody>
              </CustomTable>
            </div>
          </CustomTableContainer>
        </div>
      )}

      {/* MODALS */}
      {!!currentSignup && createProgramModalData.isModalOpen && (
        <CreateConcertSoloProgramModal
          modalData={createProgramModalData}
          signupId={currentSignup.id}
          onActionSuccess={onActionSuccess}
        />
      )}
      {deleteSignupModalData.isModalOpen && !!currentSignup && (
        <ConfirmationModal
          modalData={deleteSignupModalData}
          onApprove={onDeleteModalApprove}
          onCancel={onDeleteModalCancel}
          isSubmitting={loadingAction}
          title="Delete sign up and program ?"
          approveBtnText="Delete"
          cancelBtnText="Cancel"
        />
      )}
    </div>
  );
};

export default injectUserStore(SignupsTable);
