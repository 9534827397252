import LibrarySingleStudentRow from "./LibrarySingleStudentRow";
import React, { useContext } from "react";
import {StudentsContext, StudentsSelectContext} from "../../common/libraryContext";

const SelectAllStudents = () => {
  const { selected, toggle } = useContext(StudentsSelectContext);
  const {students} = useContext(StudentsContext);
  return (
    <div className={"d-flex align-items-start justify-content-between"}>
      <LibrarySingleStudentRow
        id={"all_students"}
        checked={selected.length === students.length && students.length}
        onChange={toggle}
        label={"All"}
      />

      {selected.length ? (
        <div className={"px-3 text-muted"}>
          <span>{selected.length}</span>
          <span className={"ms-1"}>
            Student{selected.length > 1 && "s"} Selected
          </span>
        </div>
      ) : null}
    </div>
  );
};
export default SelectAllStudents;
