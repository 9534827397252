import { Field, Form, Formik } from "formik";
import { requiredStringSchema } from "src/utils/validations";
import * as yup from "yup";
import {
  InputWithLabel,
  PrimaryButtonWithLoading,
} from "src/utils/shared/shared";
import { newsUserRoles, UserRole } from "src/constants/UserRoleEnum";
import { useContext, useRef, useState } from "react";
import { ErrorMessage } from "src/utils/shared/styled";
import moment from "moment";
import { addDoc, setDoc, Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { modalDetailsMap } from "../constants";
import { feedItems } from "src/constants/feedItemsEnum";
import {
  isFileValidated,
  updatedMomentNow,
  uploadFile,
  uploadFileWithRandomId,
} from "src/utils/helpers";
import { FirebaseContext } from "src/components/Firebase";
import {
  AttachmentName,
  CheckBoxField,
  CheckboxWrapper,
  InputWrapper,
  Textarea,
  UploadAttachmentContainer,
  UploadIcon,
  UploadPlaceholder,
} from "../styled";
import CustomRichTextEditor from "../../CustomRichTextEditor";
import { v4 as uuidv4 } from "uuid";

const AddNewForm = ({
  UserStore,
  closeModal,
  feedItemType,
  onCreateSuccess = () => {},
}) => {
  const { title1 } = modalDetailsMap[feedItemType];
  const firebase = useContext(FirebaseContext);
  // For file upload
  const [uploading, setUploading] = useState(false);
  const uploadRef = useRef();
  const [attachments, setAttachments] = useState([]);
  const addNewFeedItemValidationSchema = yup.object().shape({
    body: requiredStringSchema,
    title: requiredStringSchema,
    addDate: requiredStringSchema,
    addTime: requiredStringSchema,
  });
  const addNewWorkUpdateValidationSchema = yup.object().shape({
    body: requiredStringSchema,
    title: requiredStringSchema,
  });

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const nowTime = updatedMomentNow().format("HH:mm");

  const isAnnouncement = feedItemType === feedItems.announcements.type;
  const isNews = feedItemType === feedItems.news.type;
  const isWorkUpdate = feedItemType === feedItems.workUpdates.type;
  const isOtherTopic = feedItemType === feedItems.otherTopics.type;

  return (
    <Formik
      initialValues={{
        forRoles: [],
        title: "",
        body: "",
        addDate: formattedToday,
        addTime: nowTime,
      }}
      validationSchema={
        isWorkUpdate
          ? addNewWorkUpdateValidationSchema
          : addNewFeedItemValidationSchema
      }
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          let { forRoles, title, body, addDate, addTime } = values;
          let fullDate = moment(addDate + " " + addTime).toDate();
          // if work update, then by default the publish date will be now and the role will be admins/sa only
          if (isWorkUpdate) {
            forRoles = [UserRole.SUPER_ADMIN, UserRole.ADMIN];
            fullDate = updatedMomentNow().toDate();
          } else {
            fullDate = moment(addDate + " " + addTime).toDate();
          }

          if (!forRoles.length) {
            setFieldError("forRoles", "Select at least one role");
            return;
          }

          // if admin role is selected , that means its for both admins/super admins
          if (
            forRoles.includes(UserRole.ADMIN) &&
            !forRoles.includes(UserRole.SUPER_ADMIN)
          ) {
            forRoles = [...forRoles, UserRole.SUPER_ADMIN];
          }

          //
          const attachmentsList = [];
          const filePath = `${feedItemType}/${UserStore.user.uid}/`;
          for (const attachment of attachments) {
            const { downloadUrl } = await uploadFile(
              firebase,
              attachment.file,
              filePath,
              attachment.id
            );
            const attachmentObj = {
              id: attachment.id,
              url: downloadUrl,
              name: attachment.file.name,
              type: attachment.file.type,
            };
            attachmentsList.push(attachmentObj);
          }
          //
          const bodyObj = {
            createdAt: Timestamp.now(),
            createdBy: UserStore.user.uid,
            createdByRole: UserStore.user.role,
            type: feedItemType,
            title,
            forRoles,
            body,
            publishDate: Timestamp.fromDate(fullDate),
            attachments: attachmentsList,
          };
          setSubmitting(true);
          console.log({ bodyObj });
          const resSnap = await addDoc(firebase.feedItems(), bodyObj);

          toast.success("Created Successfully");
          closeModal();
          onCreateSuccess();
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form style={{ minHeight: "300px", margin: "30px 0" }}>
          {console.log(values.body)}
          {!isWorkUpdate && (
            <div className="mb-3 fs-6">
              <CheckboxWrapper>
                <CheckBoxField
                  type="checkbox"
                  value={UserRole.STUDENT}
                  name="forRoles"
                />
                For Students
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckBoxField
                  type="checkbox"
                  value={UserRole.PARENT}
                  name="forRoles"
                />
                For Parents
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckBoxField
                  type="checkbox"
                  value={UserRole.TEACHER}
                  name="forRoles"
                />
                For Teachers
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckBoxField
                  type="checkbox"
                  value={UserRole.ADMIN}
                  name="forRoles"
                />
                For Admins
              </CheckboxWrapper>
              {isNews ? (
                <CheckboxWrapper>
                  <CheckBoxField
                    type="checkbox"
                    value={newsUserRoles.LANDING}
                    name="forRoles"
                  />
                  For Landing Page
                </CheckboxWrapper>
              ) : null}
              {errors.forRoles && (
                <ErrorMessage>{errors.forRoles}</ErrorMessage>
              )}
            </div>
          )}
          <div className="mb-3 text-dark">
            <h2 className="mb-3 fs-4">{title1}</h2>
            <div className="mb-3">
              <InputWithLabel
                name="title"
                placeholder="Title"
                type="text"
                error={errors.title}
                touched={touched.title}
              />
            </div>
            {/* <div>
              <Textarea
                name="body"
                value={values.body}
                onBlur={handleBlur}
                onChange={handleChange}
                height={"100px"}
              />
              {errors.body && touched.body && (
                <ErrorMessage>{errors.body}</ErrorMessage>
              )}
            </div> */}
            <CustomRichTextEditor
              editorValue={values.body}
              onEditorValueChange={(newValue) =>
                setFieldValue("body", newValue)
              }
            />
            {errors.body && touched.body && (
              <ErrorMessage className="mt-2">{errors.body}</ErrorMessage>
            )}
          </div>
          <div className="mb-4">
            <input
              ref={uploadRef}
              accept="image/png, image/gif, image/jpeg, application/pdf, video/mp4,video/x-m4v,video/*"
              hidden
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0];
                if (isFileValidated(file)) {
                  setUploading(true);
                  const attachmentObj = {
                    id: uuidv4(),
                    file,
                  };
                  const updatedAttachments = [...attachments, attachmentObj];
                  setAttachments(updatedAttachments);
                  setUploading(false);
                } else {
                  toast.error("File Not Supported");
                }
              }}
            />
            <UploadAttachmentContainer
              onClick={() => {
                uploadRef.current.click();
              }}
            >
              <UploadPlaceholder>Attachment</UploadPlaceholder>
              <UploadIcon width={30} height={30} />
            </UploadAttachmentContainer>
            {attachments.length ? (
              <div className="mt-3">
                {attachments.map((attachment) => (
                  <AttachmentName key={attachment.id}>
                    {attachment.file?.name}
                  </AttachmentName>
                ))}
              </div>
            ) : null}
            <p className="mt-4 text-secondary">
              Accepted file types: images (png, jpeg, gif), videos (mp4,x-m4v)
              and PDF
            </p>
          </div>
          {!isWorkUpdate && (
            <div className="d-flex align-items-end" style={{ gap: 20 }}>
              <InputWrapper>
                <InputWithLabel
                  name="addDate"
                  error={errors.addDate}
                  touched={touched.addDate}
                  label="Add Date"
                  placeholder="Add Date"
                  type="date"
                />
              </InputWrapper>
              <InputWrapper>
                <InputWithLabel
                  name="addTime"
                  label="Add Time"
                  type="time"
                  width="130px"
                />
              </InputWrapper>
            </div>
          )}

          <div className="mt-5 text-center">
            <PrimaryButtonWithLoading
              isLoading={isSubmitting}
              className="fs-6"
              type="submit"
              width="40%"
            >
              Schedule
            </PrimaryButtonWithLoading>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default compose(inject("UserStore"), observer)(AddNewForm);
