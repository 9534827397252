import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  UserProfile,
} from "../../../../../utils/styled";
import "../../Style/Style.css";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import userImage from "../../../../../images/userImage.png";
import moment from "moment";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { getAgeFromDate } from "src/utils/helpers";
import LessonSection from "./LessonSection";

const Closeicon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 30px;
`;
function LessonInfoModal({ modalData, Userlesson }) {
  const student = Userlesson?.student;

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;

  const age =
    student?.birthday &&
    getAgeFromDate(moment(student.birthday, "YYYY-MM-DD").toDate());
  const formattedAge = age ? `${age} years` : "N/A";

  return (
    <>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        size={"lg"}
      >
        <CustomModalHeader
          className="w-100"
          close={
            <Closeicon
              className="CloseIC"
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
        >
          <div className="userInfoContainer">
            <h4 className="fw-bold">Student Profile</h4>
          </div>
          <div
            className="d-flex align-items-center mt-5 flex-wrap"
            style={{ gap: "20px" }}
          >
            <div>
              <UserProfile
                width={isDesktopScreen ? "150px" : "90px"}
                height={isDesktopScreen ? "150px" : "90px"}
                className={"rounded-circle"}
                src={student?.imageUrl ? student.imageUrl : userImage}
              />
            </div>
            <div>
              <h3>{student?.fullName || "N/A"}</h3>
              <p className="mt-2">{formattedAge}</p>
            </div>
          </div>
        </CustomModalHeader>
        <CustomModalBody>
          <div className="mb-4 mt-4 ">
            <div className="ms-2 mb-3">
              <h4 className="fw-bold">Student Info</h4>
            </div>
            <div className="lessonsContainer mb-4 ">
              <div className="UsableContainer leesonsDataContainer flex-wrap">
                <div className="UsableSinglePart d-flex flex-wrap">
                  <p>Student Birthday : </p>
                  <p className="DataInfo">
                    {moment(student?.birthday).format("MM-DD-YYYY") || "N/A"}
                  </p>
                </div>
                <div className="UsableSinglePart d-flex flex-wrap">
                  <p>Age : </p>
                  <p className="DataInfo">{formattedAge}</p>
                </div>
                <div className="UsableSinglePart d-flex flex-wrap">
                  <p>Student ID : </p>
                  <p className="DataInfo">{student?.systemID || "N/A"}</p>
                </div>
                <div className="UsableSinglePart d-flex flex-wrap">
                  <p>Enrollment Date : </p>
                  <p className="DataInfo">
                    {moment(student?.EnrollmentDate).format("MM-DD-YYYY") ||
                      "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <LessonSection Userlesson={Userlesson} />
          </div>
        </CustomModalBody>
      </CustomModal>
    </>
  );
}

export default LessonInfoModal;
