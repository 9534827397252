import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Col, DropdownItem, Row } from "reactstrap";
import { compose } from "recompose";
import { isSDCAbsence } from "src/constants/absenceTypes";
import {
  isAbsenceNotification,
  isMakeupLessonNotification,
  notificationEvents,
  notificationTypes,
} from "src/constants/notificationsEnum";
import { useUserNotificationsContext } from "src/contexts/UserNotificationsContext";
import SubmitSpinner from "../../SubmitSpinner";
import {
  NotificationRowContainer,
  PrimaryText,
  SecondaryText,
  UnreadNotificationDot,
} from "../styled";

const NotificationsBody = ({ notificationsData, UserStore }) => {
  const user = toJS(UserStore)?.user;

  const { userNotifications } = useUserNotificationsContext();

  const { initialData, loadingInitialData } = notificationsData;
  const { absences, makeupLessons, privateLessons, users } = initialData;

  const getNotificationDisplayInfo = (notification) => {
    const { type, event, createdAt, eventDocId, description } = notification;
    const isAbsenceNot = isAbsenceNotification(event);
    const isMakeupNot = isMakeupLessonNotification(event);

    let title1 = "",
      eventDate = undefined,
      isSDC = false;
    if (isAbsenceNot) {
      const eventStr = "Absence";
      const absence = absences?.find(({ id }) => id === eventDocId);

      const {
        date,
        startDate,
        absenceType,
        lessonId,
        affectedPrivateLessonsIds,
      } = absence || {};
      eventDate = date || startDate;
      isSDC = isSDCAbsence(absenceType);

      const absenceLesson = privateLessons.find(
        ({ id }) => id === lessonId || affectedPrivateLessonsIds?.includes(id)
      );
      const student = users.find(({ id }) => id === absenceLesson?.studentId);
      title1 = `${eventStr}: ${student?.fullName || "N/A"}`;
    } else if (isMakeupNot) {
      const eventStr = "Make Up";
      const makeupLesson = makeupLessons?.find(({ id }) => id === eventDocId);

      const { date, studentId } = makeupLesson || {};
      const { startDate } = date || {};
      eventDate = startDate;

      const student = users.find(({ id }) => id === studentId);
      title1 = `${eventStr}: ${student?.fullName || "N/A"}`;
    }

    const timeAgo = moment(createdAt).fromNow();

    return {
      title1,
      timeAgo,
      eventDate,
      ...(isSDC && { bgColor: "#ffeecf" }),
      description,
    };
  };

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <SubmitSpinner
          style={{
            width: "3rem",
            height: "3rem",
            color: "#681e46",
            margin: "20px",
          }}
        />
      </div>
    );
  }

  if (!userNotifications?.length) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <h4 className="m-4">No notifications in the past 24 hours</h4>
      </div>
    );
  }
  return (
    <div>
      {userNotifications?.map((not) => {
        const { eventDate, timeAgo, title1, bgColor, description } =
          getNotificationDisplayInfo(not);
        const formattedDate = eventDate
          ? moment(eventDate).format("MM-DD-YYYY")
          : "";
        const isRead = not.readBy?.includes(user?.uid);
        return (
          <div key={not.id}>
            <NotificationRowContainer bgColor={bgColor}>
              <Row>
                <Col xs="7">
                  {title1 ? (
                    <PrimaryText className="mb-2">{title1}</PrimaryText>
                  ) : null}
                  {formattedDate ? (
                    <PrimaryText>Date: {formattedDate}</PrimaryText>
                  ) : null}
                  {description ? (
                    <PrimaryText>{description}</PrimaryText>
                  ) : null}
                </Col>
                <Col
                  xs="4"
                  className="d-flex justify-content-center align-items-center"
                >
                  <SecondaryText>{timeAgo}</SecondaryText>
                </Col>
                <Col
                  xs="1"
                  className="d-flex justify-content-center align-items-center"
                >
                  {!isRead && <UnreadNotificationDot />}
                </Col>
              </Row>
            </NotificationRowContainer>
            <DropdownItem divider style={{ margin: 0 }} />
          </div>
        );
      })}
    </div>
  );
};

export default compose(inject("UserStore"), observer)(NotificationsBody);
