import React, { useState } from "react";
import WithdrawalSection from "./components/WithdrawalSection";
import SummerBreakSection from "./components/SummerBreakSection";

const sections = {
  WITHDRAWAL: "withdrawal",
  SUMMER_BREAK: "summer_break",
};
const WithdrawalRequestBody = ({
  modalData,
  lessonWithCurrentTimeline,
  onSuccess,
}) => {
  const [currentSection, setCurrentSection] = useState(sections.WITHDRAWAL);
  return (
    <div>
      <div style={{ marginLeft: "73px" }} className="mb-4 mt-3">
        <label className="me-3">
          <input
            type="radio"
            className="me-2"
            checked={currentSection === sections.WITHDRAWAL}
            onChange={() => setCurrentSection(sections.WITHDRAWAL)}
          />
          Withdrawal
        </label>
        <label>
          <input
            type="radio"
            className="me-2"
            checked={currentSection === sections.SUMMER_BREAK}
            onChange={() => setCurrentSection(sections.SUMMER_BREAK)}
          />
          Summer Break
        </label>
      </div>
      {currentSection === sections.WITHDRAWAL ? (
        <WithdrawalSection
          modalData={modalData}
          lessonWithCurrentTimeline={lessonWithCurrentTimeline}
          onSuccess={onSuccess}
        />
      ) : (
        <SummerBreakSection
          modalData={modalData}
          lessonWithCurrentTimeline={lessonWithCurrentTimeline}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default WithdrawalRequestBody;
