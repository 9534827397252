import React from "react";
import { useContext } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import {
  StudentsContext,
  StudentsSelectContext,
  studentViews,
} from "../../common/libraryContext";

const StudentSelectField = () => {
  const { select, selected, multiSelect } = useContext(StudentsSelectContext);
  const { students, teacherStudents, currentStudentsView } =
    useContext(StudentsContext);
  const { width } = useWindowDimensions();
  const isDesktopScreen = width > ScreenSize.SM;

  const showTeacherStudents =
    currentStudentsView === studentViews.TEACHER_STUDENTS;
  const displayedStudents = showTeacherStudents ? teacherStudents : students;

  const selectedStudentsWithLabel = selected.map((id) => {
    const student = students.find((student) => student.id === id);
    const label = student?.fullName;
    return { value: id, label };
  });
  return (
    <CustomReactSelect
      placeholder={"Select Students"}
      options={displayedStudents.map((student) => {
        const value = student.id;
        const label = student.fullName;
        return {
          label,
          value,
        };
      })}
      onChange={(selectedStudents) =>
        multiSelect(selectedStudents.map(({ value }) => value))
      }
      value={selectedStudentsWithLabel}
      wrapOptions={false}
      bgColor="#fff"
      width={isDesktopScreen ? "60%" : "100%"}
      margin="auto"
    />
  );
};

export default StudentSelectField;
