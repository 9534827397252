import React from "react";
import { SectionContainer } from "../../styled";
import { groupClassPaymentTypes } from "src/constants/groupClassPaymentsConstants";
import DirectPayment from "./DirectPayment";
import ScheduledPayment from "./ScheduledPayment";
import FreePayment from "./FreePayment";

const PaymentOptionsSection = ({ paymentOptions }) => {
  const directPaymentOptions = paymentOptions?.filter(
    ({ paymentType }) => paymentType === groupClassPaymentTypes.ONE_TIME
  );
  const scheduledPaymentOptions = paymentOptions?.filter(
    ({ paymentType }) => paymentType === groupClassPaymentTypes.SCHEDULED
  );
  const freePaymentOptions = paymentOptions?.filter(
    ({ paymentType }) => paymentType === groupClassPaymentTypes.FREE
  );

  return (
    <SectionContainer>
      <h5>Payment Options</h5>
      <hr />
      {!!directPaymentOptions.length && (
        <DirectPayment directPaymentOptions={directPaymentOptions} />
      )}
      {!!scheduledPaymentOptions.length && (
        <ScheduledPayment scheduledPaymentOptions={scheduledPaymentOptions} />
      )}
      {!!freePaymentOptions.length && (
        <FreePayment freePaymentOptions={freePaymentOptions} />
      )}
    </SectionContainer>
  );
};

export default PaymentOptionsSection;
