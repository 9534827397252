import "./style.css";
import React from "react";
import logo from "../../../../assets/icons/logo.svg";
import socialIcon1 from "../../../../assets/icons/socialIcon1.svg";
import socialIcon2 from "../../../../assets/icons/socialIcon2.svg";
import socialIcon3 from "../../../../assets/icons/socialIcon3.svg";
import Subscribe from "./Subscribe";

function Footer() {
  return (
    <footer className="footer-container">
      <figure className="footer-header">
        <article>
          <br />
          <br />
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Artis+School+of+Music/@37.5125167,-122.2939621,18z/data=!4m5!3m4!1s0x808f9f668f4b7f8d:0x4b79c20f4351cf22!8m2!3d37.5121557!4d-122.2931868"
          >
            2033 Ralston Ave, Belmont, CA 94002
          </a>

          <br />
          <pre>
            <a href="tel:0016507320037">(650) 732-0037</a>
            {"   "}|{"   "}
            <a href="mailto:info@artismymusic.com"> info@artismymusic.com</a>
          </pre>
        </article>
        <figure>
          <img loading="lazy" src={logo} alt="logo" />
        </figure>
      </figure>
      <figure className="footer-footer">
        <div className="school-hours">
          <h2>School Hours</h2>
          <p>
            Monday, Tuesday, Thursday, Friday 2-8 pm
            <br /> Wednesday 12:30-8 pm
            <br />
            Saturday 9:30 am- 3:30 pm
          </p>
          <h2>Call for Class Information</h2>
          <p>Monday - Friday: 10am - 2pm</p>
        </div>
        <Subscribe />
        <figure className="social-icons">
          <a target="_blank" href="https://www.facebook.com/artismymusic/">
            {" "}
            <img loading="lazy" src={socialIcon1} alt="social icone" />{" "}
          </a>
          <a target="_blank" href="https://twitter.com/artismusic2015">
            <img loading="lazy" src={socialIcon2} alt="social icone" />{" "}
          </a>

          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCYCJUGle5cHLnzzJ8ImHI2g"
          >
            <img loading="lazy" src={socialIcon3} alt="social icone" />
          </a>
        </figure>
      </figure>
    </footer>
  );
}
export default Footer;
