import styled from "styled-components";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";

export const ThumbsUpIconContainer = styled.div`
  background-color: #681e46;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  & svg {
    color: #fff;
    width: 100%;
    height: 100%;
  }
`;

export const CustomSearchInput = styled.input`
  &::-webkit-search-cancel-button {
    position: relative;
    right: 50px;
  }
`;
export const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  top: 8px;
`;
export const StudentsListContainer = styled.div`
  height: 200px;
  overflow: auto;
`;
