import React, { useContext, useEffect, useMemo, useState } from "react";
import useFirebaseFns from "./useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

import { toast } from "react-toastify";
import {
  parseAbsenceObjDates,
  parseUserActivityDates,
} from "src/utils/firebaseDatesParserFns";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import {
  checkForObjectChanges,
  getLessonWithCurrentTimeline,
  getPrivateLessonInfoOnSpecificDate,
} from "src/utils/helpers";
import {
  checkIfCompleteMakeupLessons,
  checkIfValidMakeUpDurations,
  checkOverlappingActivities,
  formatMakeupLessons,
  checkIfMakeUpsAreShort,
  getDeletedMakeupLessons,
  getEditedAbsences,
  getEditedMakeupLessons,
  getNewMakeupLessons,
} from "../helperFns";
import { eventsMap } from "src/constants/eventsEnum";
import { makeupLessonTypes } from "src/constants/makeupLessonsEnum";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";

const useMakeupData = ({
  studentId,
  privateLesson,
  user,
  deleteMakeupModalData,
}) => {
  const { students, teachers } = useStudentInfoContext();

  const {
    getStudentMakeUpLessons,
    createMakeupLesson,
    editMakeupLesson,
    deleteMakeupLesson,
    editLessonAbsence,
    getActivitiesByTeachersIds,
    getLessonAbsences,
    deleteAbsenceDoc,
    getAbsences,
  } = useFirebaseFns();
  const { deleteLessonAbsencesAndMakeUps, permaDeletePrivateLesson } =
    useGlobalFirebaseFns();

  const [initialData, setInitialData] = useState({
    makeupLessons: [],
    lessonAbsences: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  // absenceList and makeupLessonsList are compared to the initial absences and makeups to detect changes
  const [absencesList, setAbsencesList] = useState([]);
  const [makeupLessonsList, setMakeupLessonsList] = useState([]);
  const [loadingSaveData, setLoadingSaveData] = useState(false);

  // for deleting the absence and make ups + opening the makeup modal
  const [currentAbsenceId, setCurrentAbsenceId] = useState("");
  const [currentMakeupId, setCurrentMakeupId] = useState("");

  const currentAbsence = useMemo(() => {
    if (!currentAbsenceId) return;
    return absencesList.find(({ id }) => id === currentAbsenceId);
  }, [currentAbsenceId, absencesList]);

  useEffect(() => {
    if (privateLesson) {
      setMakeupLessonsList(formatMakeupLessons(initialData.makeupLessons));
      setAbsencesList(initialData.lessonAbsences || []);
    } else {
      setMakeupLessonsList([]);
      setAbsencesList([]);
    }
  }, [initialData, privateLesson]);

  // handles changes for the absences (comment and absence type)
  const handleAbsencesListChange = (id, name, value) => {
    let updatedAbsencesList = [...absencesList].map((absence) =>
      absence.id === id
        ? {
            ...absence,
            [name]: value,
            isEdited: true,
          }
        : absence
    );
    setAbsencesList(updatedAbsencesList);
  };

  // detects if absences have changed
  const haveAbsencesChanged = useMemo(
    () => checkForObjectChanges(initialData.lessonAbsences, absencesList),
    [initialData.lessonAbsences, absencesList]
  );

  const handleAddNewMakeUpLesson = (absenceId) => {
    const absence = initialData.lessonAbsences.find(
      ({ id }) => id === absenceId
    );
    const absenceDate = absence?.date || absence?.startDate;
    const { teacherId, locationId } = getPrivateLessonInfoOnSpecificDate({
      privateLesson: privateLesson,
      date: absenceDate,
      withTimelineApproximation: true,
    });
    if (!teacherId || !locationId) {
      toast.warn("Something Went Wrong (handleAddNewMakeUpLesson)");
      return;
    }
    setMakeupLessonsList((oldVal) => [
      ...oldVal,
      {
        // this id is only used to manage the state, but Its ignored when creating the firebase doc (as firebase auto generates the id for us)
        id: uuidv4(),
        type: makeupLessonTypes.MAKEUP,
        startDate: "",
        startTime: "",
        endTime: "",
        forLessonId: privateLesson?.id,
        forAbsenceId: absenceId,
        forLessonType: eventsMap.privateLesson.code,
        instrumentId: privateLesson?.instrumentId,
        locationId: locationId,
        studentId: privateLesson?.studentId,
        teacherId: teacherId,
        isVirtual: false,
        //
        isNew: true,
      },
    ]);
  };

  const handleMakeupListChange = (name, value, id) => {
    let updatedMakeupList = [...makeupLessonsList].map((lesson) =>
      lesson.id === id
        ? {
            ...lesson,
            [name]: value,
            isEdited: lesson.isNew ? false : true,
          }
        : lesson
    );
    setMakeupLessonsList(updatedMakeupList);
  };
  const handleDeleteNewMakeup = (id) => {
    const updatedMakeupList = makeupLessonsList.filter((lsn) => lsn.id !== id);
    setMakeupLessonsList(updatedMakeupList);
  };

  const handleDeleteAllAbsencesAndMakeUps = async () => {
    try {
      setLoadingSaveData(true);

      await deleteLessonAbsencesAndMakeUps(privateLesson?.id);
      toast.success("All Absences/Make Ups Were Deleted Successfully!");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSaveData(false);
      refreshData();
    }
  };
  const handlePermaDeletePrivateLesson = async () => {
    try {
      setLoadingSaveData(true);

      await permaDeletePrivateLesson(privateLesson?.id);
      toast.success("Lesson has been permanently deleted!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSaveData(false);
    }
  };

  // detects if makeup lessons have changed (edition, addition or deletion)
  const haveMakeupLessonsChanged = useMemo(
    () =>
      checkForObjectChanges(
        formatMakeupLessons(initialData.makeupLessons),
        makeupLessonsList
      ),
    [initialData.makeupLessons, makeupLessonsList]
  );

  useEffect(() => {
    if (privateLesson) {
      //fetch Initial makeup lsns
      const fetchInitialData = async () => {
        try {
          setLoadingInitialData(true);
          const firebaseReqs = [
            getStudentMakeUpLessons(studentId),
            getLessonAbsences(privateLesson.id),
          ];
          const [makeupLessons, lessonAbsences] = await Promise.all(
            firebaseReqs
          );
          const lessonAbsencesWithDates = lessonAbsences.map((absence) =>
            parseAbsenceObjDates(absence)
          );
          setInitialData((oldVal) => ({
            ...oldVal,
            makeupLessons,
            lessonAbsences: lessonAbsencesWithDates,
          }));
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        } finally {
          setLoadingInitialData(false);
        }
      };
      fetchInitialData();
    }
  }, [privateLesson, refresh]);

  const handleSaveChanges = async () => {
    try {
      setLoadingSaveData(true);
      const isCompleteMakeupLessons = checkIfCompleteMakeupLessons(
        makeupLessonsList,
        ["startDate", "startTime", "endTime", "teacherId"]
      );

      if (!isCompleteMakeupLessons) {
        toast.warn("Please fill all required fields");
        return;
      }

      const { isValid: isValidMakeUpDurations } = checkIfValidMakeUpDurations(
        absencesList,
        makeupLessonsList,
        privateLesson
      );
      if (!isValidMakeUpDurations) {
        toast.warn("Make Up durations must not exceed the absence duration");
        return;
      }

      // const isShortMakeUpDurationDetected = checkIfMakeUpsAreShort(
      //   absencesList,
      //   makeupLessonsList,
      //   15
      // );
      // if (isShortMakeUpDurationDetected) {
      //   toast.warn("Make Up durations must exceed 15 minutes");
      //   return;
      // }

      const makeupTeachersIds = [
        ...new Set(makeupLessonsList.map(({ teacherId }) => teacherId)),
      ];

      const [makeupTeachersActivities, allAbsences] = await Promise.all([
        getActivitiesByTeachersIds(makeupTeachersIds),
        getAbsences(),
      ]);
      const allAbsencesWithDates = allAbsences?.map((absence) =>
        parseAbsenceObjDates(absence)
      );
      const activitiesWithDates = makeupTeachersActivities.map((activity) =>
        parseUserActivityDates(activity)
      );
      const validationObj = checkOverlappingActivities(
        makeupTeachersIds,
        activitiesWithDates,
        allAbsencesWithDates,
        makeupLessonsList
      );

      const { isOverlapping, teacherId } = validationObj;
      if (isOverlapping) {
        toast.warn(
          `Makeup lessons are overlapping with ${
            teachers.find(({ id }) => id === teacherId)?.fullName
          } activities`
        );
        return;
      }

      // Absences section
      if (haveAbsencesChanged) {
        // check for the updated absences
        const { areEditedAbsencesDetected, editedAbsences } =
          getEditedAbsences(absencesList);
        if (areEditedAbsencesDetected) {
          for (let editedAbsence of editedAbsences) {
            await editLessonAbsence(editedAbsence);
            setAbsencesList((oldVal) =>
              oldVal.map((absence) =>
                absence.id === editedAbsence.id ? editedAbsence : absence
              )
            );
          }
        }
      }
      //Makeups section
      if (haveMakeupLessonsChanged) {
        // Check for new makeup lessons, then add them to the firebaseReqs
        const { areNewLessonsDetected, newMakeupLessons } =
          getNewMakeupLessons(makeupLessonsList);
        if (areNewLessonsDetected) {
          for (let newMakeupLesson of newMakeupLessons) {
            await createMakeupLesson(newMakeupLesson, user);
          }
        }

        // check for any edition of existing lsns
        const { areEditedLessonsDetected, editedMakeupLessons } =
          getEditedMakeupLessons(makeupLessonsList);
        if (areEditedLessonsDetected) {
          for (let editedMakeupLesson of editedMakeupLessons) {
            await editMakeupLesson(editedMakeupLesson);
          }
        }

        // ! The next commented code was used for detecting and deleting the deleted makeup lessons, but its not used anymore as we have a confirmation modal for deleteing make ups now
        // // check for any deletion of initial lsns
        // const { areDeletedLessonsDetected, deletedMakeupLessons } =
        //   getDeletedMakeupLessons(
        //     formatMakeupLessons(initialData.makeupLessons),
        //     makeupLessonsList
        //   );
        // if (areDeletedLessonsDetected) {
        //   for (let deletedMakeupLesson of deletedMakeupLessons) {
        //     await deleteMakeupLesson(deletedMakeupLesson);
        //   }
        // }
      }
      toast.success("Updated Successfully");
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSaveData(false);
    }
  };

  const deleteCurrentAbsence = async () => {
    try {
      setLoadingSaveData(true);
      if (!currentAbsenceId) {
        toast.error("Can't find absence id to delete");
        return;
      }

      await deleteAbsenceDoc(currentAbsenceId);
      toast.success("Absence Deleted Successfully");
      refreshData();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSaveData(false);
    }
  };
  const deleteCurrentMakeupLesson = async () => {
    try {
      setLoadingSaveData(true);
      if (!currentMakeupId) {
        toast.error("Can't find absence id to delete");
        return;
      }

      await deleteMakeupLesson(currentMakeupId);
      toast.success("Absence Deleted Successfully");
      refreshData();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSaveData(false);
    }
  };
  return {
    initialData,
    loadingInitialData,
    makeupLessonsList,
    handleAddNewMakeUpLesson,
    handleMakeupListChange,
    handleDeleteNewMakeup,
    handleSaveChanges,
    haveMakeupLessonsChanged,
    loadingSaveData,
    refreshData,
    absencesList,
    handleAbsencesListChange,
    haveAbsencesChanged,
    deleteCurrentAbsence,
    deleteCurrentMakeupLesson,
    setCurrentAbsenceId,
    setCurrentMakeupId,
    currentAbsenceId,
    currentMakeupId,
    currentAbsence,
    handleDeleteAllAbsencesAndMakeUps,
    handlePermaDeletePrivateLesson,
  };
};

export default useMakeupData;
