import React from "react";
import { CustomInput } from "src/utils/shared/styled";

const NumberInput = ({ value, onChange, max = 3000 }) => {
  return (
    <CustomInput
      padding="12px"
      type={"number"}
      value={value}
      width="100%"
      max={max}
      onChange={(e) => {
        const targetValue = parseInt(e.target.value);
        let val;
        if (isNaN(targetValue)) {
          val = "";
        } else if (targetValue > max) {
          val = max;
        } else if (targetValue < 1) {
          val = 1;
        } else {
          val = targetValue;
        }
        onChange(val);
      }}
      onWheel={(e) => e.target.blur()} // prevents scroll behaviour
    />
  );
};

export default NumberInput;
