import styled from "styled-components";

export const MainHeader = styled.h4`
  padding: 20px;
  font-size: 1rem;
  border-radius: 20px;
  background-color: #fff;
`;
export const ContentContainer = styled.div`
  padding: 30px;
`;

export const CustomButtonsWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const CustomButton = styled.button`
  border: none;
  outline: none;
  background: #fff;
  color: ${({ primary }) => (primary ? "#681e46" : "grey")};
`;
