import styled from "styled-components";

const EventsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const EventsItem = styled.li`
  padding: 12px;
  background-color: ${({ color }) => (color ? color : "#681e46")};
  border-radius: 12px;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const EventTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #fff;
`;

const EventTime = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
`;

export { EventsList, EventsItem, EventTitle, EventTime };
