import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { ConfirmWrapper } from "./styled";

const ActionConfirmModal = ({ isOpen, toggle, text }) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Confirmation</StyledModalHeader>
      <ModalBody>
        <ConfirmWrapper>
          <p>{text}</p>
          <PrimaryButton backgroundColor="#681e46DD" onClick={toggle}>
            OK
          </PrimaryButton>
        </ConfirmWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default ActionConfirmModal;
