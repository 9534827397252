import React from "react";
import { useMemo } from "react";
import styled from "styled-components";
const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }
`;
const LibraryItems = ({ libraryData, currentTab, libraryChosenItems }) => {
  const {
    initialData,
    loadingInitialData,
    handleSelectedItemsChange,
    isItemSelected,
    selectedItems,
  } = libraryData;
  const { libraryItems } = initialData;

  const currentTabItems = useMemo(() => {
    const filtered = libraryItems.filter(
      ({ attachmentType }) => attachmentType === currentTab
    );

    return filtered;
  }, [libraryItems, currentTab]);

  return (
    <div>
      {currentTabItems.map((item, i) => {
        const { title, attachmentType, downloadUrl, type, id } = item;

        return (
          <div className="d-flex mb-3" style={{ gap: 20 }}>
            <CheckboxContainer>
              <input
                checked={isItemSelected(id)}
                id={`library-item-${i}`}
                onChange={() => handleSelectedItemsChange(id)}
                type="checkbox"
              />
            </CheckboxContainer>
            <label htmlFor={`library-item-${i}`}>{title}</label>
          </div>
        );
      })}
    </div>
  );
};

export default LibraryItems;
