import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { injectUserStore } from "src/utils/helpers";
import useConcertPrograms from "./hooks/useConcertPrograms";
import ConcertProgramsBody from "./ConcertProgramsBody";

const ConcertProgramsModal = ({
  onActionSuccess = () => {},
  modalData,
  concertId,
  UserStore,
}) => {
  const user = UserStore.user;

  const hookData = useConcertPrograms({ user, concertId });

  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="xl"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>Programs</h2>
        </CustomModalHeader>
        <CustomModalBody>
          <ConcertProgramsBody
            onActionSuccess={onActionSuccess}
            closeModal={modalData.closeModal}
            hookData={hookData}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(ConcertProgramsModal);
