import {
  emailNotificationsList,
  internalNotificationsList,
} from "src/constants/notificationSettingsEnum";

export const teachersSettingsRows = [
  {
    description: "New Shared Library Item",
    propName: internalNotificationsList.newSharedLibraryItemToTeacher,
  },
  {
    description: "New Absence Confirmation",
    propName: internalNotificationsList.newAbsenceToTeacher,
  },
  {
    description: "New Make Up Lesson Confirmation",
    propName: internalNotificationsList.newMakeUpLessonToTeacher,
  },
  {
    description: "Lesson Note Student Homework Submission",
    propName: internalNotificationsList.newStudentHwSubmissionToTeacher,
  },
  {
    description: "New Payment Confirmation",
    propName: internalNotificationsList.newPaymentToTeacher,
  },
  {
    description: "New Student Concert Sign Up",
    propName: internalNotificationsList.newConcertSignupToTeacher,
  },
];

export const studentsSettingsRows = [
  {
    description: "New Shared Library Item",
    propName: internalNotificationsList.newSharedLibraryItemToStudent,
  },
  {
    description: "New Absence Confirmation",
    propName: internalNotificationsList.newAbsenceToStudent,
  },
  {
    description: "Make Up Lesson Confirmation",
    propName: internalNotificationsList.newMakeUpLessonToStudent,
  },
  {
    description: "New Teacher Homework Comment",
    propName: internalNotificationsList.newTeacherHwCommentToStudent,
  },
];

export const adminsSettingsRows = [
  {
    description: "New Absence Confirmation",
    propName: internalNotificationsList.newAbsenceToAdmin,
  },
  {
    description: "Make Up Lesson Confirmation",
    propName: internalNotificationsList.newMakeUpLessonToAdmin,
  },
  {
    description: "Lesson Note Student Homework Submission",
    propName: internalNotificationsList.newStudentHwSubmissionToAdmin,
  },
  {
    description: "New Payment Confirmation",
    propName: internalNotificationsList.newPaymentToAdmin,
  },
  {
    description: "New Student Concert Sign Up",
    propName: internalNotificationsList.newConcertSignupToAdmin,
  },
];
export const superAdminsSettingsRows = [
  {
    description: "New Absence Confirmation",
    propName: internalNotificationsList.newAbsenceToSuperAdmin,
  },
  {
    description: "Make Up Lesson Confirmation",
    propName: internalNotificationsList.newMakeUpLessonToSuperAdmin,
  },
  {
    description: "Lesson Note Student Homework Submission",
    propName: internalNotificationsList.newStudentHwSubmissionToSuperAdmin,
  },
  {
    description: "New Payment Confirmation",
    propName: internalNotificationsList.newPaymentToSuperAdmin,
  },
  {
    description: "New Student Concert Sign Up",
    propName: internalNotificationsList.newConcertSignupToSuperAdmin,
  },
];
