import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const ChangeProfileImage = ({ userId, refreshData, closeModal }) => {
  const { changeUserProfileImage } = useFirebaseFns();

  const [isLoading, setIsLoading] = useState(false);

  const uploadRef = useRef();

  const handleUploadBtnClick = () => {
    if (!isLoading) {
      uploadRef.current.click();
    }
  };

  const onUploadFile = async (e) => {
    try {
      const file = e.target.files[0];

      setIsLoading(true);
      await changeUserProfileImage(userId, file);
      toast.success("Successfully uploaded.");
      refreshData();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <input ref={uploadRef} hidden type={"file"} onChange={onUploadFile} />

      <PrimaryButtonWithLoading
        onClick={handleUploadBtnClick}
        isLoading={isLoading}
        width="178px"
        fontSize="1rem"
        padding="8px"
      >
        Change Image
      </PrimaryButtonWithLoading>
    </>
  );
};

export default ChangeProfileImage;
