import { useState, useMemo } from "react";
import useMakeupData from "./hooks/useMakeupData";
import MakeUpBody from "./MakeUpBody";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { toJS } from "mobx";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import {
  isGroupClassEvent,
  isPackagePrivateLesson,
} from "src/constants/eventsEnum";
import { isAbsenceBypassMakeupDeadline } from "src/constants/absenceTypes";

const MakeupModal = ({
  currentAbsence,
  makeupModalData,
  lesson,
  onSuccess,
  UserStore,
  shortenTimeSlots = false,
}) => {
  const isPackageLsn = isPackagePrivateLesson(lesson.type);
  const user = toJS(UserStore)?.user;
  const absenceDate = currentAbsence.date || currentAbsence.startDate;

  const lessonInfoOnAbsenceDate = useMemo(() => {
    if (!lesson) {
      return;
    }
    const lessonInfo = getPrivateLessonInfoOnSpecificDate({
      privateLesson: lesson,
      date: absenceDate,
      withTimelineApproximation: true,
    });

    return lessonInfo;
  }, [lesson, absenceDate]);

  const lessonDuration = parseInt(lessonInfoOnAbsenceDate?.duration);
  const [requestedDate, setRequestedDate] = useState(null);

  const makeupData = useMakeupData({
    lesson,
    lessonInfoOnAbsenceDate,
    requestedDate,
    currentAbsence,
    user,
    shortenTimeSlots,
  });

  if (makeupData.loadingInitialData) {
    return <div></div>;
  }

  const maxDate = makeupData.initialData.maxDateValue;
  const minDate = hasAdminCredentials(user?.role)
    ? null
    : updatedMomentNow().add(1, "day").toDate();
  const requestedDateData = {
    requestedDate,
    setRequestedDate,
    maxDate,
    minDate,
  };
  // stop if no current schoolyear
  if (!makeupData.initialData.currentSchoolYearInLocation) {
    toast.error("Couldnt find school year");
    makeupModalData.closeModal();

    return <div></div>;
  }

  // stop if no makeup period deadline
  if (!makeupData.initialData.makeupPeriodDeadline && !isPackageLsn) {
    toast.error("Can't find school year makeup deadline for this absence");
    console.log("NO MAKEUP DEADLINE FOUND", {
      makeupPeriodDeadline: makeupData.initialData.makeupPeriodDeadline,
      currentAbsenceDate: currentAbsence?.date || currentAbsence?.startDate,
      currentSchoolYearInLocation:
        makeupData.initialData.currentSchoolYearInLocation,
    });
    makeupModalData.closeModal();

    return <div></div>;
  }

  const isPassedMakeupDeadline =
    moment(absenceDate).isAfter(
      moment(makeupData.initialData.makeupPeriodDeadline)
    ) ||
    updatedMomentNow().isAfter(makeupData.initialData.makeupPeriodDeadline);

  if (
    isPassedMakeupDeadline &&
    !isAbsenceBypassMakeupDeadline(currentAbsence?.absenceType) &&
    !isPackageLsn
  ) {
    toast.error("Absence date had Passed Makeup Deadline");
    makeupModalData.closeModal();
    return <div></div>;
  }

  return (
    <div>
      <CustomModal
        centered
        isOpen={makeupModalData.isModalOpen}
        toggle={makeupModalData.toggleModal}
        style={{ maxWidth: "1000px" }}
      >
        <CustomHeader
          close={<CloseIcon onClick={makeupModalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={makeupModalData.toggleModal}
        >
          <h2 className="fw-bold">Make Up Options</h2>
        </CustomHeader>
        <CustomBody>
          <MakeUpBody
            privateLesson={lesson}
            lessonInfoOnAbsenceDate={lessonInfoOnAbsenceDate}
            makeupModalData={makeupModalData}
            makeupData={makeupData}
            requestedDateData={requestedDateData}
            onSuccess={onSuccess}
            user={user}
            lessonDuration={lessonDuration}
            currentAbsence={currentAbsence}
          />
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default compose(inject("UserStore"), observer)(MakeupModal);
