import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import FreeForAllConcertSignupModalBody from "./FreeForAllConcertSignupModalBody";
import useFreeForAllConcertSignup from "./hooks/useFreeForAllConcertSignup";
import { injectUserStore } from "src/utils/helpers";
import { toJS } from "mobx";

const FreeForAllConcertSignupModal = ({
  modalData,
  concertId,
  onActionSuccess = () => {},
  UserStore,
}) => {
  const user = toJS(UserStore)?.user;

  const hookData = useFreeForAllConcertSignup({
    user,
    concertId,
    onActionSuccess,
  });
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="md"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>Free For All Student Concert</h2>
        </CustomModalHeader>
        <CustomModalBody padding="25px">
          <FreeForAllConcertSignupModalBody
            hookData={hookData}
            concertId={concertId}
            onActionSuccess={onActionSuccess}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(FreeForAllConcertSignupModal);
