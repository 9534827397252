import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { injectUserStore } from "src/utils/helpers";
import useConcertDetails from "./hooks/useConcertDetails";
import ConcertDetailsBody from "./ConcertDetailsBody";

const ConcertDetailsModal = ({ modalData, concertId, UserStore }) => {
  const user = UserStore.user;

  const hookData = useConcertDetails({
    concertId,
  });
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="lg"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>Concert Details</h2>
        </CustomModalHeader>
        <CustomModalBody>
          <ConcertDetailsBody
            closeModal={modalData.closeModal}
            hookData={hookData}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(ConcertDetailsModal);
