import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";

export const InstrumentSectionContainer = styled.div`
  margin-bottom: 50px;
`;
export const SelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  height: 45px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#bb8a920f"};
  color: ${({ color }) => color || "#000"};
`;
export const SelectedItem = styled.span`
  background: #ffdede;
  height: 30px;
  margin: 5px;
  padding: 3px 10px;
  border-radius: 10px;
`;
export const CloseIcon = styled(Close)`
  color: #998a8a;
  border-radius: 50%;
  width: 24px;
  cursor: pointer;
  padding-left: 5px;
`;
export const AvailableDaysSectionContainer = styled.div`
  margin-bottom: 25px;
`;
export const AddNewDayBtn = styled.span`
  cursor: pointer;
  font-weight: 700;
  color: #681e46;
`;
export const NoteSection = styled.div`
  margin-bottom: 25px;
`;
export const Textarea = styled.textarea`
  display: block;
  min-height: "85px";
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
`;
export const PrivateLessonsSectionContainer = styled.div`
  padding: 20px;
  border: 1px solid #e4e4e4;
  box-shadow: #eeeeee 0 0 8px 0;
  margin-bottom: 20px;
  border-radius: 7px;
`;
