import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { parseUserPaymentRateDates } from "src/utils/firebaseDatesParserFns";

const initialDataInitialValues = {
  paymentRatesObj: null,
};
const usePaymentInfo = ({
  paymentObj,
  closePaymentInfoModal,
  onPaymentDeleteSuccess,
}) => {
  const { getUserPaymentRateById, deleteUserPayment } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [loadingAction, setLoadingAction] = useState(false);

  async function deletePayment() {
    if (!paymentObj) return;
    try {
      setLoadingAction(true);

      await deleteUserPayment(paymentObj?.id);
      closePaymentInfoModal();
      onPaymentDeleteSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!paymentObj) return;

        setLoadingInitialData(true);

        const [paymentRates] = await Promise.all([
          getUserPaymentRateById(paymentObj.paymentRatesId),
        ]);
        const parsedPaymentRates = parseUserPaymentRateDates(paymentRates);

        setInitialData((oldVal) => ({
          ...oldVal,
          paymentRatesObj: parsedPaymentRates,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };

    fetchInitialData();
  }, [paymentObj.id]);

  return { initialData, loadingInitialData, loadingAction, deletePayment };
};

export default usePaymentInfo;
