import React from "react";
import "./style.css";
import NavMenu from "../common/NavMenu";
import Gallery from "./Gallery";
import About from "./About";
import SubGallery from "./SubGallery";
import Footer from "../common/Footer";
import CalendarContainer from "./CalendarContainer";
import questionIcon from "../../../assets/icons/questionIcon.svg";

const Home = () => {
  const contactUsEmail = process.env.REACT_APP_CONTACT_US_EMAIL;

  return (
    <main className="home-page">
      <NavMenu />
      <figure className="question-modal-container">
        <a href={`mailto:${contactUsEmail}`} className="questions-modal">
          <img src={questionIcon} alt="message icon" />
          Questions
        </a>
      </figure>
      <div className="home-page-container">
        <Gallery />
        <About />
        <SubGallery />
        <CalendarContainer />
        <Footer />
      </div>
    </main>
  );
};

export default Home;
