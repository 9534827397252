import { Select } from "antd";
import { studentInstrumentOptions, teacherInstrumentOptions } from "./options";
import store from "src/stores/UserStore";
import { isTeacherApplicant } from "src/constants/UserRoleEnum";

const InstrumentSelect = ({ onChange, value, mode }) => {
  const { user } = store;

  return (
    <Select
      style={{ width: "100%" }}
      virtual={false}
      mode={mode}
      placeholder="Select instruments"
      allowClear
      options={
        isTeacherApplicant(user.role)
          ? teacherInstrumentOptions
          : studentInstrumentOptions
      }
      onChange={onChange}
      value={value}
    />
  );
};

export default InstrumentSelect;
