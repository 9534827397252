import React from "react";
import {
  AddNewAmountBtn,
  CustomInput,
  CustomSelectField,
  InputContainer,
  InputRow,
  SectionContainer,
} from "../../../../styled";
import { CustomLabel } from "src/utils/shared/styled";
import {
  groupClassIntervalsOptions,
  groupClassPaymentExcutedAtOptions,
  paymentAmountTypes,
  timesToRunOptions,
} from "../../../../constants";
import {
  groupClassScheduledPaymentIntervals,
  scheduledPaymentTimesToRun,
} from "src/constants/groupClassPaymentsConstants";
import NumberInput from "../../shared/NumberInput";
import PaymentAmountsSelection from "../../PaymentAmountsSelection";

const ScheduledPaymentSection = ({
  paymentFormInputs,
  handlePaymentFormInputChange,
  isAvailableForAll,
  isAvailableForPublic,
  isAvailableForCurrentStudents,
  handleAddNewPaymentAmount,
  handleDeletePaymentAmount,
  handleEditPaymentAmount,
}) => {
  const showPublicAmount = isAvailableForAll || isAvailableForPublic;
  const showCurrentStudentsAmount =
    isAvailableForAll || isAvailableForCurrentStudents;

  const executedAtOptions =
    parseInt(paymentFormInputs.interval) ===
    groupClassScheduledPaymentIntervals.WEEK
      ? Object.values(groupClassPaymentExcutedAtOptions.weekly)
      : parseInt(paymentFormInputs.interval) ===
        groupClassScheduledPaymentIntervals.MONTH
      ? Object.values(groupClassPaymentExcutedAtOptions.monthly)
      : parseInt(paymentFormInputs.interval) ===
        groupClassScheduledPaymentIntervals.YEAR
      ? Object.values(groupClassPaymentExcutedAtOptions.yearly)
      : [];

  return (
    <SectionContainer>
      <h5>Scheduled Payment Info</h5>
      <hr />
      <div>
        <InputRow>
          <InputContainer>
            <CustomLabel>Runs Every:</CustomLabel>
            <CustomSelectField
              value={paymentFormInputs.interval}
              onChange={(e) =>
                handlePaymentFormInputChange("interval", e.target.value)
              }
              width="100%"
            >
              <option value="" disabled>
                Interval
              </option>
              {Object.values(groupClassIntervalsOptions).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </CustomSelectField>
          </InputContainer>
          {/* <InputContainer>
            <CustomLabel>Executed At:</CustomLabel>
            <CustomSelectField
              value={paymentFormInputs.excutedAt}
              onChange={(e) =>
                handlePaymentFormInputChange("excutedAt", e.target.value)
              }
              width="100%"
            >
              <option value="" disabled>
                At
              </option>
              {executedAtOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </CustomSelectField>
              </InputContainer> */}
        </InputRow>
        <InputRow>
          <InputContainer>
            <CustomLabel>Interval Count:</CustomLabel>

            <NumberInput
              max={30}
              value={paymentFormInputs.intervalCount}
              onChange={(value) =>
                handlePaymentFormInputChange("intervalCount", value)
              }
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer>
            <CustomLabel>Times To Run (How many payments):</CustomLabel>
            <CustomSelectField
              value={paymentFormInputs.timesToRun.type}
              onChange={(e) =>
                handlePaymentFormInputChange("timesToRun.type", e.target.value)
              }
              width="100%"
            >
              <option value="" disabled>
                Times To Run
              </option>
              {Object.values(timesToRunOptions).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.title}
                </option>
              ))}
            </CustomSelectField>
          </InputContainer>
          {paymentFormInputs.timesToRun.type ===
            scheduledPaymentTimesToRun.ENDS && (
            <InputContainer>
              <CustomLabel>How Many Times:</CustomLabel>
              <NumberInput
                value={paymentFormInputs.timesToRun.amount}
                onChange={(value) =>
                  handlePaymentFormInputChange("timesToRun.amount", value)
                }
              />
            </InputContainer>
          )}
        </InputRow>
        {showPublicAmount && (
          <InputRow>
            <SectionContainer width="100%">
              <div className="d-flex justify-content-between">
                <h6>New Students Amounts:</h6>
                <div>
                  <AddNewAmountBtn
                    onClick={() =>
                      handleAddNewPaymentAmount(
                        paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS
                      )
                    }
                  >
                    Add New
                  </AddNewAmountBtn>
                </div>
              </div>
              <hr />
              <PaymentAmountsSelection
                paymentFormInputs={paymentFormInputs}
                amountType={paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS}
                handleDeletePaymentAmount={handleDeletePaymentAmount}
                handleEditPaymentAmount={handleEditPaymentAmount}
              />
            </SectionContainer>
          </InputRow>
        )}
        {showCurrentStudentsAmount && (
          <InputRow>
            <SectionContainer width="100%">
              <div className="d-flex justify-content-between">
                <h6>Current Students Amounts:</h6>
                <div>
                  <AddNewAmountBtn
                    onClick={() =>
                      handleAddNewPaymentAmount(
                        paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS
                      )
                    }
                  >
                    Add New
                  </AddNewAmountBtn>
                </div>
              </div>
              <hr />
              <PaymentAmountsSelection
                paymentFormInputs={paymentFormInputs}
                amountType={
                  paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS
                }
                handleDeletePaymentAmount={handleDeletePaymentAmount}
                handleEditPaymentAmount={handleEditPaymentAmount}
              />
            </SectionContainer>
          </InputRow>
        )}
      </div>
    </SectionContainer>
  );
};

export default ScheduledPaymentSection;
