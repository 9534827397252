import moment from "moment";
import React, { useMemo, useState } from "react";
import { weekDays } from "src/constants/weekDays";
import { combineArrayToStr, isActiveTeacher } from "src/utils/helpers";
import styled from "styled-components";
import {
  Card,
  CustomLabel,
  CustomLabelInput,
} from "../../../../../../utils/styled";
import { SubmitSpinner } from "../../../../../common";
import { defaultEventsColor } from "../../../constants";
import SingleTeacher from "./SingleTeacher";
import CustomTooltip from "src/components/Dashboard/common/components/CustomTooltip";
import { isTeacherInLocation } from "../../helperFns";
import { PrimaryButton } from "src/utils/shared/styled";

const TeacherColor = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* margin-top: 5px; */
  background-color: ${({ color }) => color || defaultEventsColor};
`;

const displayViews = {
  ACTIVE: "active",
  DISABLED: "disabled",
};
const TeachersList = ({
  loadingTeachersLessons,
  isLoading,
  teachers,
  selectedTeachers,
  onSelectTeacher,
  onClearTeachers,
  selectedLocationId,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [displayView, setDisplayView] = useState(displayViews.ACTIVE);

  const showRemoveAllBtn = !!Object.values(selectedTeachers || {}).length;

  function handleViewChange() {
    if (displayView === displayViews.ACTIVE) {
      setDisplayView(displayViews.DISABLED);
    } else {
      setDisplayView(displayViews.ACTIVE);
    }
  }

  const displayedTeachers = useMemo(() => {
    return Object.values(teachers)
      .filter((teacher) => {
        const isActive = isActiveTeacher(teacher);
        const passViewFilter =
          displayView === displayViews.ACTIVE ? isActive : !isActive;

        const instrumentsStr = combineArrayToStr(
          teacher.instrumentsInfo?.map(({ instrument }) =>
            instrument?.name.toLowerCase()
          )
        );

        const passSearchFilter =
          teacher.fullName.toLowerCase().includes(searchValue.toLowerCase()) ||
          instrumentsStr.includes(searchValue.toLowerCase());

        const passLocationFilter =
          displayView === displayViews.ACTIVE
            ? isTeacherInLocation(teacher, selectedLocationId)
            : true;

        return passLocationFilter && passViewFilter && passSearchFilter;
      })
      .sort((a, b) => {
        const firstName = a.fullName || "";
        const secondName = b.fullName || "";
        return firstName.toLowerCase() > secondName.toLowerCase() ? 1 : -1;
      });
  }, [teachers, displayView, searchValue, selectedLocationId]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Card className="p-2 flex-grow-1">
        <CustomLabelInput
          className={"mt-2"}
          placeholder={"Search"}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <div
          className="mt-4 mb-4 text-center d-flex justify-content-center"
          style={{ gap: 15 }}
        >
          <PrimaryButton width="150px" padding="7px" onClick={handleViewChange}>
            {displayView === displayViews.ACTIVE ? "Active" : "Inactive"}
          </PrimaryButton>
          {showRemoveAllBtn && (
            <PrimaryButton
              style={{ fontSize: "0.7rem" }}
              width="150px"
              padding="7px"
              onClick={onClearTeachers}
            >
              Remove Selections
            </PrimaryButton>
          )}
        </div>

        {!isLoading ? (
          <div className="d-flex flex-column p-2">
            {displayedTeachers.map((teacher) => {
              const { teacherDays, instrumentsInfo, fullName } = teacher;

              // returns first letters of the teacher week days (Filtered by location id)
              const teacherDaysWeekDays = teacherDays?.availableDays
                ?.filter(({ location }) => location === selectedLocationId)
                ?.map((day) => {
                  const { startDate, endDate } = day;
                  const startDateWeekDay = moment(startDate).day();
                  const endDateWeekDay = moment(endDate).day();

                  return [startDateWeekDay, endDateWeekDay];
                })
                ?.flat();
              const uniqueWeekDays = [...new Set(teacherDaysWeekDays || [])];
              const teacherWeekDayLettersArr = uniqueWeekDays.map((weekday) => {
                const weekName = weekDays.find(
                  ({ id, name }) => id === parseInt(weekday)
                )?.name;
                if (!weekName) return "";

                let str = "";

                // 4 is Thursday (we do this to display TH instead of T)
                if (parseInt(weekday) === 4) {
                  str = weekName.slice(0, 2).toUpperCase();
                } else {
                  str = weekName[0];
                }
                return str;
              });
              const teacherWeeksStr = combineArrayToStr(
                teacherWeekDayLettersArr || []
              );

              const teacherInstrumentsStr = combineArrayToStr(
                instrumentsInfo?.map(({ instrument }) => instrument?.name) || []
              );

              const teacherTitle = `${fullName} (${teacherWeeksStr}) (${teacherInstrumentsStr})`;

              return (
                <div
                  key={teacher.id}
                  className="d-flex align-items-center justify-content-between mb-1"
                >
                  <SingleTeacher
                    teacherId={teacher.id}
                    teacherColor={teacher.color}
                    isDisabled={loadingTeachersLessons}
                    isChecked={selectedTeachers[teacher.id]}
                    onChange={onSelectTeacher}
                    label={teacherTitle}
                  />
                </div>
              );
            })}
            <CustomTooltip
              anchorSelect=".teacher-tooltip-anchor-element"
              place="bottom"
              noArrow
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <SubmitSpinner
              style={{ width: "3rem", height: "3rem", color: "#681e46" }}
            />
          </div>
        )}
      </Card>
    </div>
  );
};
export default TeachersList;
