import { PiGuitarDuotone } from "react-icons/pi";
import { GiFlute, GiMusicalScore, GiViolin } from "react-icons/gi";
import { GiDrum } from "react-icons/gi";
import { LiaGuitarSolid } from "react-icons/lia";
import { PiGuitarBold } from "react-icons/pi";
import { BsMusicNoteList } from "react-icons/bs";
import { GiGrandPiano } from "react-icons/gi";
import { PiGuitarLight } from "react-icons/pi";
import { MdKeyboardVoice } from "react-icons/md";

const instrumentsIconsEnum = {
  "Bass Guitar": <PiGuitarDuotone size={24} />,
  Cello: <GiViolin size={30} />,
  Conducting: "",
  Drum: <GiDrum size={24} />,
  Flute: <GiFlute size={24} />,
  Guitar: <LiaGuitarSolid size={24} />,
  Mandolin: <PiGuitarBold size={24} />,
  "Music Composition": <GiMusicalScore size={24} />,
  "Music Theory": <BsMusicNoteList size={24} />,
  Percussion: "",
  Piano: <GiGrandPiano size={30} />,
  Ukulele: <PiGuitarLight size={24} />,
  Viola: <GiViolin size={24} />,
  Violin: <GiViolin size={24} />,
  Voice: <MdKeyboardVoice size={24} />,
};

export default instrumentsIconsEnum;
