import React from "react";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import {
  AddNewDayBtn,
  InstrumentSectionContainer,
  SelectField,
} from "../styled";

const InstrumentsInfo = ({
  instrumentsInfo,
  setInstrumentsInfo,
  instruments,
  programs,
  hasPermissionToEdit,
}) => {
  const handleAddNewInstrumentRow = () => {
    setInstrumentsInfo((oldVal) => [
      ...oldVal,
      {
        instrument: "",
        program: "",
      },
    ]);
  };
  const handleInstrumentsChange = (e, i) => {
    let instrumentsInfoCopy = [...instrumentsInfo];
    instrumentsInfoCopy[i][e.target.name] = e.target.value;
    setInstrumentsInfo(instrumentsInfoCopy);
  };
  const handleRemoveInstrumentInfo = (index) => {
    setInstrumentsInfo((oldVal) => oldVal.filter((el, i) => i !== index));
  };
  return (
    <InstrumentSectionContainer>
      <div className="mb-3 mt-4 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className="me-3 fs-4">Instruments</h2>
        </div>
        {hasPermissionToEdit && (
          <AddNewDayBtn onClick={handleAddNewInstrumentRow}>
            Add A New Instrument
          </AddNewDayBtn>
        )}
      </div>
      {!hasPermissionToEdit && (
        <div className="mt-3 mb-4">
          <h5 style={{ color: " #acacac" }}>
            For the change, please contact a super admin
          </h5>
        </div>
      )}
      {instrumentsInfo.map((instrumentInfo, i) => (
        <div key={i}>
          <div className="d-flex justify-content-between align-items-end mt-3">
            <SelectField
              disabled={!hasPermissionToEdit}
              name="instrument"
              defaultValue=""
              value={instrumentsInfo[i].instrument}
              onChange={(e) => handleInstrumentsChange(e, i)}
              width={"300px"}
            >
              <option value="" disabled>
                Instrument
              </option>
              {instruments.map((instrument) => (
                <option key={instrument.id} value={instrument.id}>
                  {instrument.name}
                </option>
              ))}
            </SelectField>

            <SelectField
              disabled={!hasPermissionToEdit}
              name="program"
              defaultValue=""
              value={instrumentsInfo[i].program}
              onChange={(e) => handleInstrumentsChange(e, i)}
              width={"300px"}
            >
              <option value="" disabled>
                Program
              </option>
              {programs.map((program) => (
                <option key={program.id} value={program.id}>
                  {program.name}
                </option>
              ))}
            </SelectField>
            {hasPermissionToEdit && (
              <div
                onClick={() => handleRemoveInstrumentInfo(i)}
                className="pb-2"
                style={{ cursor: "pointer" }}
              >
                <DeleteOff width={30} height={30} color="#8a8a8a" />
              </div>
            )}
          </div>
        </div>
      ))}
    </InstrumentSectionContainer>
  );
};

export default InstrumentsInfo;
