import { isPartnerApplicant } from "src/constants/UserRoleEnum";
import { SummaryItem } from "../styled";

const DefaultSummary = ({ user }) => {
  return (
    <>
      <SummaryItem>Name: {user?.name || "N/A"}</SummaryItem>
      <SummaryItem>Email: {user?.email}</SummaryItem>
      {!isPartnerApplicant(user?.role) && (
        <SummaryItem>Birthday: {user?.birthday || "N/A"}</SummaryItem>
      )}
      <SummaryItem>Primary phone: {user?.primaryPhone || "N/A"}</SummaryItem>
      <SummaryItem>Home address: {user?.homeAddress || "N/A"}</SummaryItem>
    </>
  );
};

export default DefaultSummary;
