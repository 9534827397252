import React from "react";
import RedbarModal from "../RedbarModal";
import ViewGroupClassStudentPaymentBody from "./ViewGroupClassStudentPaymentBody";
import useGCStudentPayment from "./hooks/useGCStudentPayment";
import { injectUserStore } from "src/utils/helpers";

const ViewGroupClassStudentPaymentModal = ({
  groupClassId,
  studentId,
  modalData,
  UserStore,
}) => {
  const user = UserStore.user;
  const { isModalOpen, toggleModal, closeModal } = modalData;
  const hookData = useGCStudentPayment({ groupClassId, studentId, user });

  //   const renderFooterContent = () => {
  //     if (currentComponent === enrollmentComponents.PAYMENT) {
  //       return <div></div>;
  //     } else {
  //       return (
  //         <div>
  //           <CustomButtonsWrapper>
  //             <CustomButton onClick={closeModal}>Cancel</CustomButton>|
  //             <CustomButton primary onClick={handleNextStep}>
  //               Next
  //             </CustomButton>
  //           </CustomButtonsWrapper>
  //         </div>
  //       );
  //     }
  //   };
  return (
    <RedbarModal
      modalData={modalData}
      modalHeader={"Student Payment"}
      redbarTitle={"Student Payment"}
      overflowRedbarContent={true}
    >
      <ViewGroupClassStudentPaymentBody
        groupClassId={groupClassId}
        hookData={hookData}
        studentId={studentId}
      />
    </RedbarModal>
  );
};

export default injectUserStore(ViewGroupClassStudentPaymentModal);
