import React from "react";
import PackageLessonExpirationBody from "./PackageLessonExpirationBody";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";

const PackageLessonExpirationModal = ({
  modalData,
  lessonId,
  onSuccess = () => {},
  setNumber,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      // style={{ maxWidth: "950px" }}
      // className="min-w-50 text-dark"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Expiration</h2>
      </CustomHeader>
      <CustomBody>
        <PackageLessonExpirationBody
          modalData={modalData}
          lessonId={lessonId}
          onSuccess={onSuccess}
          setNumber={setNumber}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default PackageLessonExpirationModal;
