import styled from "styled-components";
import { Collapse, Button } from "antd";

const InnerCard = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CardButton = styled(Button)`
  height: 100%;
  padding: 12px 16px;
  font-size: 16px;
`;

const StyledCollapse = styled(Collapse)`
  width: 80%;

  font-size: 16px;
  font-weight: 700;
`;

const ClassDescription = styled.div`
  max-width: 100%;

  p {
    max-width: 80%;
    flex: 0 0 40%;
  }

  .title {
    font-size: 16px;
    font-weight: 900;
  }

  .value {
    display: block;
    margin-top: 8px;

    font-size: 18px;
    font-weight: 600;
  }
`;

export {
  CardButton,
  InnerCard,
  ButtonsContainer,
  ClassDescription,
  StyledCollapse,
};
