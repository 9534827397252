import React from "react";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import { makeupLessonTypes } from "src/constants/makeupLessonsEnum";
import { PrimaryButton } from "src/utils/shared/styled";
import useMaxMakeUps from "./hooks/useMaxMakeUps";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import {
  isApprovedMakeupRequest,
  isDeclinedMakeupRequest,
  isPendingMakeupRequest,
} from "src/constants/makeupRequestEnum";

const MaxMakeUpsComponent = ({
  closeMakeupModal,
  user,
  onSuccess,
  absenceId,
  lessonId,
  latestMakeupRequest,
}) => {
  const { status } = latestMakeupRequest || {};
  const isPendingRequest = isPendingMakeupRequest(status);
  const isDeclinedRequest = isDeclinedMakeupRequest(status);
  const isApprovedRequest = isApprovedMakeupRequest(status);

  const { loadingAction, sendMakeupRequest } = useMaxMakeUps({
    user,
    closeMakeupModal,
    onSuccess,
    absenceId,
    lessonId,
  });
  const isAdmin = hasAdminCredentials(user.role);

  function handleApprove() {
    sendMakeupRequest();
  }
  function handleCancel() {
    closeMakeupModal();
  }

  const headerContent = isAdmin ? (
    <>
      Student reached the maximum number of make ups.
      <br /> Additional make up is available for pay ($15)
    </>
  ) : (
    <>
      You reached your maximum number of make ups.
      <br /> Additional make up is available for pay ($15)
    </>
  );
  const approveBtnText = isAdmin
    ? "Yes, Parent Agrees"
    : "Yes, I like to request";
  return (
    <div
      style={{ height: "50vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="">
        {isPendingRequest ? (
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="text-center">
              Makeup Request Is Pending Admin Approval
            </h4>
          </div>
        ) : isDeclinedRequest ? (
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="text-center">Makeup Request Was Declined</h4>
          </div>
        ) : isApprovedRequest ? (
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="text-center">
              Makeup Request Was Approved, You shouldnt see this message
              (Something Went wrong) (Please contact an admin)
            </h4>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="text-center">{headerContent}</h5>
            </div>
            <div
              className="mt-4 d-flex justify-content-center"
              style={{ gap: 20 }}
            >
              <PrimaryButton onClick={handleCancel}>Cancel</PrimaryButton>
              <PrimaryButtonWithLoading
                isLoading={loadingAction}
                onClick={handleApprove}
              >
                {approveBtnText}
              </PrimaryButtonWithLoading>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MaxMakeUpsComponent;
