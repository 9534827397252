import React from "react";
import RouteContent from "../../../common/RouteContent";
import RightStudentHomeItems from "./RightStudentHomeItems";
import { GridContainer, GridItem } from "./styled";
import FeedItemsSection from "../../common/components/FeedItemsSection";
import LatestFeedItemSection from "../../common/components/LatestFeedItemSection";

const StudentDashboard = ({ user }) => {
  return (
    <RouteContent title="Student Dashboard" childrenWithNoPadding={false}>
      <div className="mb-3">
        <LatestFeedItemSection />
      </div>
      <GridContainer>
        <GridItem
          gridArea="feed"
          className="Container"
          style={{ overflow: "auto" }}
        >
          <FeedItemsSection />
        </GridItem>
        <RightStudentHomeItems user={user} />
      </GridContainer>
    </RouteContent>
  );
};

export default StudentDashboard;
