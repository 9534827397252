import moment from "moment";
import React from "react";
import { useFeedSectionContext } from "../../../../contexts/FeedSectionContext";
import {
  DescriptionContainer,
  DescriptionContent,
  FeedItemContainer,
  InfoSpan1,
  InfoSpan2,
  ItemInfoContainer,
  MetaContainer,
} from "../../../../styled";
import { BookmarkFill, PinAngleFill } from "styled-icons/bootstrap";

const ItemInfo = ({ feedItem }) => {
  const { feedUsers, getFeedItemMetaInfo } = useFeedSectionContext();
  const { publishDate, body, createdBy, title } = feedItem || {};
  const formattedPublishDate = publishDate
    ? moment(publishDate).format("MM/DD/YYYY")
    : "N/A";
  const createdByUser = feedUsers.find(({ id }) => createdBy === id);
  const createdByFullName = createdByUser?.fullName;

  const { isPinned } = getFeedItemMetaInfo(feedItem.id);

  return (
    <>
      <MetaContainer>
        {isPinned && (
          <span>
            <PinAngleFill width={17} height={17} color={"#681e46"} />
          </span>
        )}
        <span>
          <InfoSpan1>Published On:</InfoSpan1>{" "}
          <InfoSpan2>{formattedPublishDate}</InfoSpan2>
        </span>
        <span>
          <InfoSpan1>Name:</InfoSpan1>{" "}
          <InfoSpan2>{createdByFullName || "N/A"}</InfoSpan2>
        </span>
      </MetaContainer>

      <div>
        <DescriptionContent>{title || "N/A"}</DescriptionContent>
      </div>
    </>
  );
};

export default ItemInfo;
