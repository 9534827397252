import { Image, Skeleton } from "antd";
import { Attachments, DownloadButton } from "./styled";
import { downloadAttachment } from "../../../utils";
import { DownloadOutlined } from "@ant-design/icons";

const AttachmentsList = ({ attachments }) => {
  return (
    <Attachments>
      <Image.PreviewGroup>
        {attachments?.length ? (
          attachments?.map((attachment, index) => (
            <li key={index}>
              <Image
                width={250}
                src={attachment}
                placeholder
                alt="feedback attachment"
                preview={{
                  destroyOnClose: true,
                }}
                loading="lazy"
              />
              <DownloadButton
                onClick={() => downloadAttachment(attachment)}
                title="Download"
                aria-label="Download"
              >
                <DownloadOutlined />
              </DownloadButton>
            </li>
          ))
        ) : (
          <>
            <li>
              <Skeleton.Image active={false} />
            </li>
            <li>
              <Skeleton.Image active={false} />
            </li>
            <li>
              <Skeleton.Image active={false} />
            </li>
          </>
        )}
      </Image.PreviewGroup>
    </Attachments>
  );
};

export default AttachmentsList;
