import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  min-width: 650px;
  max-width: 650px;
  & .modal-content {
    border-radius: 1.3rem;
    border: none !important;
    padding: 0;
    position: relative;
    z-index: 999999;
  }
`;
export const CustomModalHeader = styled(ModalHeader)`
  border: none !important;
  padding-bottom: 25px !important;
  padding-top: 25px !important;
`;
export const CustomModalBody = styled(ModalBody)`
  padding: 0 !important;
`;

export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
  /* margin-left: -30px; */
  width: 100%;
`;
export const RedBarContainer = styled.div`
  background: #681e46;
  width: 50px;
  color: #fff;
  writing-mode: vertical-lr;
  text-orientation: revert;
  display: flex;
  align-items: center;
  padding: 40px 26px;
`;
export const RedBarText = styled.p`
  font-size: 1.3rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
`;
export const InfoContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
`;
export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;
