import { Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  positiveNumberValidationSchema,
  requiredStringSchema,
} from "src/utils/validations";
import InputWithLabel from "src/components/Auth/shared/InputWithLabel";
import { SubmitSpinner } from "src/components/common";
import { PrimaryButton } from "src/utils/shared/styled";
import { eventsMap } from "../../../../../../../../constants/eventsEnum";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { breakDurations, breakReasons } from "./constants";
import { useEffect } from "react";
import { useState } from "react";
import useTeacherBreakData from "./hooks/useTeacherBreakData";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { toJS } from "mobx";

const FormContainer = styled.div`
  width: 100%;
`;
const InputWrapper = styled.div`
  width: auto;
  margin-bottom: 20px;
`;

const teacherBreakValidationSchema = yup.object().shape({
  date: requiredStringSchema,
  startTime: requiredStringSchema,
  duration: positiveNumberValidationSchema,
  type: positiveNumberValidationSchema,
  reason: requiredStringSchema,
});

const TeacherBreaksBody = ({ refreshData, modalData, teacher, UserStore }) => {
  const user = toJS(UserStore)?.user;
  const { onFormSubmit } = useTeacherBreakData({
    teacherId: teacher?.id,
    refreshData,
    modalData,
    user,
  });
  return (
    <div>
      <FormContainer>
        <Formik
          initialValues={{
            date: "",
            startTime: "",
            duration: "",
            type: eventsMap.oneTimeBreak.code,
            reason: "",
          }}
          validationSchema={teacherBreakValidationSchema}
          onSubmit={onFormSubmit}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form className="d-flex flex-wrap">
              <Container>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="date"
                        error={errors.date}
                        touched={touched.date}
                        label="Date"
                        placeholder="Break Date"
                        type="date"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="startTime"
                        error={errors.startTime}
                        touched={touched.startTime}
                        label="Time"
                        placeholder="Time"
                        type="time"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <Field name="reason">
                        {({ field }) => {
                          return (
                            <InputWithLabel
                              as="select"
                              name="reason"
                              error={errors.reason}
                              touched={touched.reason}
                              label="Type"
                              value={field.value}
                              onChange={field.onChange}
                            >
                              <option value="" disabled>
                                Choose Break Type
                              </option>
                              {breakReasons.map(({ name, value }) => (
                                <option key={value} value={value}>
                                  {name}
                                </option>
                              ))}
                            </InputWithLabel>
                          );
                        }}
                      </Field>
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <Field name="duration">
                        {({ field }) => {
                          return (
                            <InputWithLabel
                              as="select"
                              name="duration"
                              error={errors.duration}
                              touched={touched.duration}
                              label="Duration"
                              value={field.value}
                              onChange={(e) =>
                                setFieldValue(
                                  "duration",
                                  parseInt(e.target.value)
                                )
                              }
                            >
                              <option value="" disabled>
                                Select Duration
                              </option>
                              {breakDurations.map(({ name, value }) => (
                                <option key={value} value={value}>
                                  {name}
                                </option>
                              ))}
                            </InputWithLabel>
                          );
                        }}
                      </Field>
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="me-4">
                      <Field
                        className="me-2"
                        type="radio"
                        name="type"
                        value={eventsMap.oneTimeBreak.code}
                        onChange={(e) =>
                          setFieldValue("type", parseInt(e.target.value))
                        }
                      />
                      Single Time Break
                    </label>
                    <label className="me-4">
                      <Field
                        className="me-2"
                        type="radio"
                        name="type"
                        value={eventsMap.recurringBreak.code}
                        onChange={(e) =>
                          setFieldValue("type", parseInt(e.target.value))
                        }
                      />
                      Recurring Break
                    </label>
                  </Col>
                </Row>

                <div className="mt-4 w-100 d-flex justify-content-center">
                  <PrimaryButton
                    width="300px"
                    type="submit"
                    height="45px"
                    padding="0"
                  >
                    {isSubmitting ? (
                      <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                    ) : (
                      "Save Changes"
                    )}
                  </PrimaryButton>
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </div>
  );
};

export default compose(inject("UserStore"), observer)(TeacherBreaksBody);
