import "./style.css";
import React from "react";

const TeamMemberCardIconsWrapper = ({ isModal, specialization }) => {
  return (
    <div
      className={
        "teamMemberCardIconsWrapper" +
        (isModal ? " teamMemberCardIconsWrapperModal" : "")
      }
    >
      <p className="shortDescriptionParagraph">Artis Years</p>
      <div className="iconsWrapper">
        {specialization &&
          specialization.map((iconObj, index) => {
            const { Icon, amount, name } = iconObj;
            return (
              <React.Fragment key={index}>
                <span className="smallIconGroup">
                  {amount}
                  <Icon className={isModal && "modalSvg"} title={name} />
                </span>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default TeamMemberCardIconsWrapper;
