import React from "react";
import { concertProgramTypes } from "src/constants/concertEnum";

const ProgramTypeSection = ({ programType, setProgramType }) => {
  return (
    <div>
      <label className="me-3">
        <input
          type="radio"
          className="me-2"
          checked={programType === concertProgramTypes.SOLO_PROGRAM}
          onChange={() => setProgramType(concertProgramTypes.SOLO_PROGRAM)}
        />
        Solo Program
      </label>
      <label>
        <input
          type="radio"
          className="me-2"
          checked={programType === concertProgramTypes.SPECIAL_PROGRAM}
          onChange={() => setProgramType(concertProgramTypes.SPECIAL_PROGRAM)}
        />
        Special Program
      </label>
    </div>
  );
};

export default ProgramTypeSection;
