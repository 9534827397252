import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // console.log(error);
    // console.log(info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      toast.error("error:(");
      return (
        <>
          <h1 className="text-center pt-5">
            OOOOPS something went wrong. Try to reload...
          </h1>
          <ToastContainer
            autoClose={8000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
        </>
      );
    }
    return (
      <>
        {this.props.children}
        <ToastContainer
          autoClose={8000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
        />
      </>
    );
  }
}
