import React from "react";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router";

const EmailSentConfirmation = () => {
  const Navigate = useNavigate();

  return (
    <>
      <div className="text-center mb-5">
        <img
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      <h1 className="text-center">
        A Login Link Is Sent, Please Check Your Email
      </h1>
    </>
  );
};

export default EmailSentConfirmation;
