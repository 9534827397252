import { arrayUnion, deleteDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const cancelOneRecurringLabel = async (labelId, canceledDate) => {
    try {
      if (!labelId || !canceledDate) return;
      const updateObj = {
        canceledDates: arrayUnion(canceledDate),
      };

      await updateDoc(firebase.calendarLabel(labelId), updateObj);
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    }
  };
  const cancelFutureRecurringLabel = async (labelId, canceledDate) => {
    try {
      const updateObj = {
        canceledAt: canceledDate,
      };

      await updateDoc(firebase.calendarLabel(labelId), updateObj);
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    }
  };
  return {
    cancelOneRecurringLabel,
    cancelFutureRecurringLabel,
  };
};

export default useFirebaseFns;
