import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 280px;
  /* height: 230px; */
  border-radius: 15px;
  background-color: #ff9ed2;
  color: #fff;
  text-align: start;
  padding: 20px 15px 0 15px;
  position: absolute;
  z-index: 2000;
  left: -70px;
  top: 25px;
  font-size: 0.9rem;
`;
const AbsenceTypesInfoMenu = () => {
  return (
    <Container>
      <div className="mb-3">
        <span className="fw-bold">REG</span>
        <span className="fw-normal"> - Regular</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">SDC</span>
        <span className="fw-normal"> - Same Day Cancelation</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">EA</span>
        <span className="fw-normal"> - Emergency Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">TA</span>
        <span className="fw-normal"> - Teacher Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">TEA</span>
        <span className="fw-normal"> - Teacher Emergency Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">SA</span>
        <span className="fw-normal"> - Summer Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">SEA</span>
        <span className="fw-normal"> - School Event Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">MA</span>
        <span className="fw-normal"> - Missed Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">WA</span>
        <span className="fw-normal"> - Withdrawal Absence</span>
      </div>
      <div className="mb-3">
        <span className="fw-bold">TechEA</span>
        <span className="fw-normal"> - Tech Emergency Absence</span>
      </div>
    </Container>
  );
};

export default AbsenceTypesInfoMenu;
