import React from "react";
import RouteContent from "src/components/common/RouteContent";
import { GridContainer, GridItem } from "./styled";
import CreateClassSection from "./CreateClassSection";
import MiniCalendarSection from "./MiniCalendarSection";
import useGroupClass from "./hooks/useGroupClass";
import GroupClassesListSection from "./GroupClassesListSection";

const GroupClass = () => {
  const hookData = useGroupClass();
  const { handleFiltersChange, groupClassFilters } = hookData;

  return (
    <RouteContent title="Group Class">
      <GridContainer>
        <GridItem gridArea="first">
          <CreateClassSection hookData={hookData} />
        </GridItem>
        <GridItem gridArea="second">
          <MiniCalendarSection
            dateValue={groupClassFilters.dateFilter?.requestDate}
            onDateValueChange={(value) =>
              handleFiltersChange("dateFilter", "requestDate", value)
            }
          />
        </GridItem>
        <GridItem gridArea="third">
          <GroupClassesListSection hookData={hookData} />
        </GridItem>
      </GridContainer>
    </RouteContent>
  );
};

export default GroupClass;
