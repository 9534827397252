import React from "react";
import { InfoContainer, InfoText } from "../../styled";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";
import ActionsSection from "./ActionsSection";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const RequestDetails = ({ makeupRequest, requestData }) => {
  const {} = makeupRequest || {};
  const {
    initialData,
    selectedAction,
    setSelectedAction,
    submitMakeupRequest,
    loadingAction,
    reason,
    setReason,
  } = requestData;
  const { student, teacher, privateLesson, instrument } = initialData;

  const lessonInfo = getPrivateLessonInfoOnSpecificDate({
    date: updatedMomentNow(),
    privateLesson: privateLesson,
    withTimelineApproximation: true,
  });
  const lessonLengthStr = `${lessonInfo?.duration || "N/A"} Minutes`;
  return (
    <div>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Student:
        </InfoText>
        <InfoText>{student?.fullName || "N/A"}</InfoText>
      </InfoContainer>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Instrument:
        </InfoText>
        <InfoText>{instrument?.name || "N/A"}</InfoText>
      </InfoContainer>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Teacher:
        </InfoText>
        <InfoText>{teacher?.fullName || "N/A"}</InfoText>
      </InfoContainer>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Lesson Length:
        </InfoText>
        <InfoText>{lessonLengthStr}</InfoText>
      </InfoContainer>

      <div className="mt-5">
        <ActionsSection
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          reason={reason}
          setReason={setReason}
        />
      </div>

      <div className="text-center mt-5">
        <PrimaryButtonWithLoading
          isLoading={loadingAction}
          onClick={submitMakeupRequest}
        >
          Confirm{" "}
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

export default RequestDetails;
