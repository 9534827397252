import { getDocs } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { Spinner } from "reactstrap";
import CustomDropDown from "../../common/DropDown";
import { FirebaseContext } from "../../Firebase";
import Barcode from 'react-barcode';
const StudentQR = ({ user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [instruments, setInstruments] = useState({});
    const [studentLessons, setStudentLessons] = useState([]);
    const firebase = useContext(FirebaseContext);
    const [selectedInstrument, setSelectedInstrument] = useState({ label: 'Instrument', value: '' });
    const instrumentsOptions = useMemo(() => {
        if (studentLessons?.length > 0 && Object.keys(instruments)?.length > 0) {
            const options = studentLessons.map(({ instrumentId, lessonId }) => {
                const instrument = instruments[instrumentId];
                return {
                    label: instrument.name,
                    value: instrument.id,
                    lessonId
                };
            });
            setSelectedInstrument(previous => options?.[0] ?? previous);
            return options;
        }
        return [];
    }, [studentLessons, instruments]);
    const getInstruments = async () => {
        const instrumentsDocs = await getDocs(firebase.instruments());
        const instrumentsObject = {};
        // instrumentsDocs.forEach(instrument => instrumentsArray.push({ ...instrument.data(), id: instrument.id }));
        instrumentsDocs.forEach(instrument => {
            instrumentsObject[instrument.id] = {
                ...instrument.data(),
                id: instrument.id
            };
        });
        setInstruments(instrumentsObject);
    };
    const getPrivateLessons = async () => {
        const privateLessonsArray = await getDocs(firebase.getStudentPrivateLessons(user.uid));
        const lessons = [];
        privateLessonsArray.forEach((privateLesson) => {
            const lessonData = privateLesson.data();
            lessons.push({
                lessonId: privateLesson.id,
                instrumentId: lessonData.instrumentId,
            });
        });
        setStudentLessons(lessons);
    }
    useEffect(async () => {
        if (user?.uid) {
            setIsLoading(true);
            await Promise.all([
                getInstruments(),
                getPrivateLessons(),
            ]);
            setIsLoading(false);
        }
    }, [user?.uid]);
    return (
        <div className="py-5 d-flex align-items-center flex-column">
            {isLoading ? (
                <div className="d-flex h-100 align-items-center justify-content-center col">
                    <Spinner />
                </div>
            ) : (
                <div className="col-12 d-flex flex-column align-items-center">
                    <div className="col-6 mb-5">
                        <CustomDropDown
                            label={selectedInstrument.label}
                            value={selectedInstrument.label}
                            keyName={"label"}
                            options={instrumentsOptions}
                            onOptionClick={setSelectedInstrument}
                        />
                    </div>
                    <div className="col-12 d-flex px-4 justify-content-center">
                        <QRCode value={`${user.systemID}&${selectedInstrument.lessonId}`} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default StudentQR;
