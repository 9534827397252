import moment from "moment";
import React, { useMemo } from "react";
import { Container } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import { absenceTypes } from "src/constants/absenceTypes";
import { isStudent, isTeacher } from "src/constants/UserRoleEnum";
import { StudentInfoContextProvider } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import {
  getAbsenceMakeUpStatusData,
  getCurrentSchoolYear,
  getMakeupPeriodInRequestedDate,
  getRecurringEventTimelineObj,
} from "src/utils/helpers";
import {
  CustomTableContainer,
  CustomTable,
  TableRow,
  ClickableTableCell,
} from "src/utils/shared/styled";
import ViewStudentInfoModal from "../../common/components/ViewStudentInfoModal";
import { parseDate } from "../../student/Absences/helperFns";

const TableLayout = ({ makeupNeededData }) => {
  const {
    filteredStudentsData,
    initialData,
    filteredStudents,
    loadingFilteredStudentsData,
    currentStudent,
    setCurrentStudentId,
    filteredAbsencesWithAdditionalData,
  } = makeupNeededData;
  const { users, schoolYears, privateLessons, locations } = initialData;
  const { absences, makeupLessons } = filteredStudentsData;

  const studentInfoModalData = useModal();

  const openStudentInfoModal = (studentId) => {
    setCurrentStudentId(studentId);
    studentInfoModalData.openModal();
  };

  if (loadingFilteredStudentsData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <Container fluid>
      <CustomTableContainer>
        <CustomTable className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>Student Name</div>
              </th>
              <th>
                <div>Absence Date</div>
              </th>
              <th>
                <div>Absence Type</div>
              </th>
              <th>
                <div>Status</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {filteredAbsencesWithAdditionalData.map((absence, i) => {
              const {
                makeupStatus,
                makeupStatusStr,
                requestedByName,
                studentName,
                formattedAbsenceDate,
                absenceTypeStr,
                studentId,
              } = absence;

              return (
                <TableRow key={absence.id}>
                  <ClickableTableCell
                    onClick={() => openStudentInfoModal(studentId)}
                  >
                    {studentName || "N/A"}
                  </ClickableTableCell>
                  <td>{formattedAbsenceDate}</td>
                  <td>{absenceTypeStr}</td>
                  <td>{makeupStatusStr}</td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
      {currentStudent && (
        <StudentInfoContextProvider
          providedInitialData={{
            locations,
            schoolYears,
            students: users.filter(({ role }) => isStudent(role)),
            teachers: users.filter(({ role }) => isTeacher(role)),
            privateLessons,
          }}
        >
          <ViewStudentInfoModal
            modalData={studentInfoModalData}
            currentStudent={currentStudent}
          />
        </StudentInfoContextProvider>
      )}
    </Container>
  );
};

export default TableLayout;
