import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const confirmClassCancel = async ({
  to_email,
  to_name,
  classDate,
  isCanceled,
}) => {
  try {
    await emailjs.send(
      "service_m7j255t",
      "template_lb0du76",
      {
        subject: `Group Class ${isCanceled ? "Cancellation" : "Resumption"}`,
        to_name,
        from_name: "Artis School of Music",
        to_email: "arsenii.brovchuk@gmail.com",
        message: `Your group class on ${classDate} has been ${
          isCanceled ? "canceled" : "resumed"
        }`,
        attachmentSrc:
          "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
      },
      "l6-vMvPNxhUUxuyxh"
    );
  } catch (error) {
    console.error(error);
    toast.error("An error occurred");
  }
};

export default confirmClassCancel;
