import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const initialDataInitialValues = {
  concert: undefined,
};
const useConcertDetails = ({ concertId }) => {
  const { getConcertById } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  useEffect(() => {
    if (!concertId) return;
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concert] = await Promise.all([getConcertById(concertId)]);

        setInitialData({
          concert,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  return { initialData, loadingInitialData };
};

export default useConcertDetails;
