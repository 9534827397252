import moment from "moment";
import { useContext, useState } from "react";
import { Modal } from "../../common";
import { CheckInModalContext } from "../../common/contexts/AdminAttendanceContext";
import { Button } from "reactstrap";
import { updatedMomentNow } from "src/utils/helpers";

const CheckInModal = ({ duplicateDate, studentName }) => {
  const { close, isOpen, submit } = useContext(CheckInModalContext);
  const [attendanceDate, setAttendanceDate] = useState(
    updatedMomentNow().format("YYYY-MM-DD")
  );
  const [attendanceTime, setAttendanceTime] = useState(
    updatedMomentNow().format("hh:mm")
  );

  const buttons = (
    <Button
      onClick={() => submit(moment(`${attendanceDate} ${attendanceTime}`))}
      className="ms-2 bg-purple"
    >
      Check In
    </Button>
  );

  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      <p className="lead mb-2 display-6">
        Welcome <strong>{studentName}</strong>
      </p>
      <div className="px-5 col w-100">
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Check In Date: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <input
              className="InputField w-100 m-0"
              type="date"
              value={attendanceDate}
              onChange={(e) => {
                setAttendanceDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Check In Time: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <input
              className="InputField w-100 m-0"
              type="time"
              value={attendanceTime}
              onChange={(e) => {
                setAttendanceTime(e.target.value);
              }}
            />
          </div>
        </div>
        {duplicateDate === "" ? null : (
          <div className="lead alert alert-danger mb-2">
            Already checked in today at <strong>{duplicateDate}</strong>, coming
            back for another lesson?
          </div>
        )}
      </div>
    </div>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Check In"}
      isOpen={isOpen}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default CheckInModal;
