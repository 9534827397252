import React from "react";
import { CreateConcertButton } from "./styled";
import useModal from "src/hooks/useModal";
import CreateConcertModal from "src/components/Dashboard/common/components/CreateConcertModal";

const CreateConcertSection = () => {
  const createConcertModalData = useModal();

  return (
    <>
      <div>
        <CreateConcertButton onClick={createConcertModalData.openModal}>
          <i
            style={{ color: "#ac0f64", marginTop: "7px" }}
            className="fas fa-plus mx-2"
          />
          Create New Concert
        </CreateConcertButton>
      </div>

      {/* MODALS */}
      <CreateConcertModal modalData={createConcertModalData} />
    </>
  );
};

export default CreateConcertSection;
