import { paymentTypes } from "src/constants/paymentsEnum";

const getTeacherPaymentName = (paymentType, paymentMap) => {
  switch (paymentType) {
    case paymentTypes.REGULAR_CLASS:
      return `Private lessons (${paymentMap?.name1?.replace(" lesson", "")})`;
    case paymentTypes.SDC:
      return "SDC hours";
    case paymentTypes.GROUP_CLASS:
      return "Group classes";
    case paymentTypes.BONUS:
      return "Bonuses";
    case paymentTypes.RESERVATION:
      return "Reservation";
    case paymentTypes.ADMINISTRATIVE:
      return "Admin hours";
    case paymentTypes.TRAINING:
      return "Training hours";
    case paymentTypes.REIMBURSEMENT:
      return "Reimbursement";
    default:
      return "Work hours payment";
  }
};

const getAdminPaymentName = (paymentType) => {
  switch (paymentType) {
    case paymentTypes.SALARY:
      return "Salary";
    case paymentTypes.EXTENDED_HOURS:
      return "Extended hours";
    case paymentTypes.SIGN_UPS:
      return "Sign ups";
    case paymentTypes.BONUS:
      return "Bonus";
    case paymentTypes.REIMBURSEMENT:
      return "Reimbursement";
    case paymentTypes.TRAINING:
      return "Training hours";
    default:
      return "Work hours payment";
  }
};

export { getTeacherPaymentName, getAdminPaymentName };
