import { LinkViewerContainer } from "../../styled-components/teacherLibraryStyledComponents";

const LinkViewer = ({ link, title = "" }) => {
  return (
    <LinkViewerContainer
      className={"d-flex justify-content-center align-items-center flex-column"}
    >
      <iframe className={"m-2"} scrolling={"no"} src={link} frameBorder="0" />
      <a
        target={"_blank"}
        href={link}
        className={"fs-3 text-black w-100"}
        style={{ wordWrap: "break-word", display: "block" }}
      >
        {link} <i className="fa fa-link ms-1" />
      </a>
    </LinkViewerContainer>
  );
};
export default LinkViewer;
