import React from "react";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router";

const UpdateConfirmation = () => {
  const Navigate = useNavigate();

  return (
    <>
      <div className="text-center mb-5">
        <img
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      <h1>Password is Updated Successfully</h1>;
    </>
  );
};

export default UpdateConfirmation;
