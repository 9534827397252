import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { SingleDatePicker } from "react-dates";
import { CalendarPickerWrapper } from "src/utils/shared/styled";
import styled from "styled-components";

const CalendarWrapper = styled.div`
  text-align: center;
  .react-date-picker__wrapper {
    padding: 5px 10px;
    border-radius: 15px;
    width: 300px;
    //background-color:
  }
`;

const RequestedDatePicker = ({ requestedDateData }) => {
  const { requestedDate, setRequestedDate, maxDate, minDate } =
    requestedDateData;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const dateValue = requestedDate ? moment(requestedDate) : null;
  return (
    <CalendarWrapper>
      <p className="mb-2">
        Please select a date within 2 weeks from today's date
      </p>
      <CalendarPickerWrapper>
        <SingleDatePicker
          id=""
          date={dateValue} // momentPropTypes.momentObj or null
          onDateChange={(date) => {
            setRequestedDate(date.format("YYYY-MM-DD"));
          }}
          focused={isDatePickerOpen}
          onFocusChange={({ focused }) => {
            setIsDatePickerOpen(focused);
          }}
          isOutsideRange={(date) => {
            if (!minDate || !maxDate) return false;

            if (date.isBetween(minDate, maxDate, "date", "[]")) {
              return false;
            }

            return true;
          }}
          orientation={window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'}
        />
      </CalendarPickerWrapper>
    </CalendarWrapper>
  );
};

export default RequestedDatePicker;
