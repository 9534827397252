import moment from "moment";
import React from "react";
import { ConcertImage, PrimaryText, SecondaryText } from "../styled";
import guitarImg from "src/assets/images/guitar.webp";
import { SubmitSpinner } from "src/components/common";

const ConcertDetailsBody = ({ hookData }) => {
  const { initialData, loadingInitialData } = hookData;
  const { concert } = initialData;
  const { title, date, duration, address, attachments } = concert || {};

  const { url } = attachments?.find(({ type }) => type.includes("image")) || {};
  const formattedDate = moment(date).format("MM/DD/YYYY");
  const formattedTime =
    moment(date).format("hh:mm A") +
    " - " +
    moment(date).add(duration, "minutes").format("hh:mm A");

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="text-center">
        <ConcertImage src={url || guitarImg} alt="concert-image" />
      </div>
      <div className="mt-4" style={{ flex: 1.5 }}>
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ gap: "1rem", marginBottom: "1rem" }}
        >
          <div className="">
            <PrimaryText>Title</PrimaryText>
            <SecondaryText>{title}</SecondaryText>
          </div>
        </div>
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ gap: "1rem", marginBottom: "1rem" }}
        >
          <div className="">
            <PrimaryText>Date</PrimaryText>
            <SecondaryText>{formattedDate}</SecondaryText>
          </div>
          <div>
            <PrimaryText>Time</PrimaryText>
            <SecondaryText>{formattedTime}</SecondaryText>
          </div>
        </div>
        <div>
          <div className="">
            <PrimaryText>Location</PrimaryText>
            <SecondaryText>{address}</SecondaryText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcertDetailsBody;
