import moment from "moment";
import {
  isPerLessonAbsence,
  isTeacherAbsence,
} from "src/constants/absenceTypes";
import {
  isActivityOverlappingWithSingleTimeEvent,
  mapDurationOrAllDayAbsenceToActivity,
  prepareTeacherActivities,
} from "src/utils/helpers";

export const attachAvailableDaysToTeachers = (
  teachers = {},
  availableDays = []
) => {
  if (!Object.keys(teachers).length) return {};

  availableDays?.forEach((daysObj) => {
    const teacherId = daysObj?.id;
    const teacher = teachers[teacherId];
    if (teacher) {
      const teacherWithDay = { ...teacher, teacherDays: daysObj };
      teachers[teacherId] = teacherWithDay;
    }
  });
  return teachers;
};
export const addInstrumentsToTeachers = (
  teachers = {},
  instruments = {},
  programs = {}
) => {
  if (!Object.keys(teachers).length) return {};
  if (Object.values(instruments).length) {
    const teachersWithInstruments = Object.values(teachers).map((teacher) => ({
      ...teacher,
      instrumentsInfo: teacher.instrumentsInfo?.map((instrumentInfo) => ({
        ...instrumentInfo,
        instrument: instruments[instrumentInfo?.instrument],
        program: programs[instrumentInfo?.program],
      })),
    }));
    const teachersObj = teachersWithInstruments?.reduce((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});
    return teachersObj;
  } else {
    return teachers;
  }
};

export const isTeacherInLocation = (teacher, locationId) => {
  if (!teacher || !locationId) return false;

  const availableDays = teacher.teacherDays?.availableDays;
  let pass = false;
  if (availableDays?.length) {
    for (let availableDay of availableDays) {
      const { location: availableDayLocationId } = availableDay || {};
      if (locationId === availableDayLocationId) {
        pass = true;
        break;
      }
    }
  }
  return pass;
};
export const filterTeachersByLocation = (teachers = {}, locationId) => {
  // If no location selected or no teachers, return nothing
  if (!Object.keys(teachers).length || !locationId) return {};

  const filteredTeachers = Object.values(teachers).filter((teacher) => {
    const availableDays = teacher.teacherDays?.availableDays;
    let pass = false;
    if (availableDays?.length) {
      for (let availableDay of availableDays) {
        const { location: availableDayLocationId } = availableDay || {};
        if (locationId === availableDayLocationId) {
          pass = true;
          break;
        }
      }
    }
    return pass;
  });
  const filteredTeachersObj = filteredTeachers?.reduce((acc, cur) => {
    acc[cur.id] = { ...cur };
    return acc;
  }, {});
  return filteredTeachersObj;
};

export const filterLessonsByLocation = (lessonsObj, locationId) => {
  if (!locationId) return {};

  const filteredLessons = Object.values(lessonsObj).filter(
    (lesson) => locationId === lesson?.locationId
  );
  const filteredLessonsObj = filteredLessons?.reduce((acc, cur) => {
    acc[cur.id] = { ...cur };
    return acc;
  }, {});

  return filteredLessonsObj;
};
export const filterTLsByLocation = (lessonsObj, locationId) => {
  if (!locationId) return {};

  const filteredLessons = Object.values(lessonsObj).filter((lesson) => {
    let pass = false;
    for (let lessonLocation of lesson?.locations) {
      if (locationId === lessonLocation) {
        pass = true;
        break;
      }
    }
    return pass;
  });
  const filteredLessonsObj = filteredLessons?.reduce((acc, cur) => {
    acc[cur.id] = { ...cur };
    return acc;
  }, {});

  return filteredLessonsObj;
};

export const getSelectedTeachersResourceMap = (
  selectedTeachersIds = {},
  teachers = {}
) => {
  if (
    !Object.keys(selectedTeachersIds)?.length ||
    !Object.keys(teachers)?.length
  )
    return [];

  const resourceMap = Object.values(selectedTeachersIds)
    .map((teacherId) => teachers[teacherId])
    .map((teacher) => {
      const { id, fullName } = teacher || {};
      return {
        ...teacher,
        resourceId: id,
        resourceTitle: fullName,
      };
    });
  return resourceMap;
};

// validates if start time is before end time and doesn't overlap with an existing calendar event
export const validateAppointment = (
  appointment,
  userActivities = [],
  absences = []
) => {
  const {
    title,
    startDate: appointmentStart,
    endDate: appointmentEnd,
    note,
    color,
    teacherId,
  } = appointment;
  if (moment(appointmentStart).isSameOrAfter(appointmentEnd)) {
    return { isValid: false, message: "Start time has to be before end time" };
  }

  if (!userActivities?.length && !absences?.length) return { isValid: true };

  const teacherAllDayAndDurationTAs = absences?.filter((absence) => {
    const isTA = isTeacherAbsence(absence.absenceType);
    const isSameTeacher = absence.teacherId === teacherId;
    const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
    return isTA && isSameTeacher && !isPerLesson;
  });
  const absencesActivities = teacherAllDayAndDurationTAs?.map((absence) =>
    mapDurationOrAllDayAbsenceToActivity(absence)
  );

  const preparedActivities = prepareTeacherActivities(
    [...userActivities, ...absencesActivities],
    teacherId
  );
  let validationObj = { isValid: true };
  for (const activity of preparedActivities) {
    const isOverlapping = isActivityOverlappingWithSingleTimeEvent({
      eventStartDate: appointmentStart,
      eventEndDate: appointmentEnd,
      teacherId,
      activity,
      absences,
    });
    if (isOverlapping) {
      validationObj = {
        isValid: false,
        message: "The Appointment can't be overlapping with another event",
      };
      break;
    }
  }

  return validationObj;
};
