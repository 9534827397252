import moment from "moment";
import React, { useEffect } from "react";
import { Container } from "reactstrap";

import useModal from "src/hooks/useModal";
import {
  CustomTableContainer,
  CustomTable,
  TableRow,
} from "src/utils/shared/styled";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  isApprovedMakeupRequest,
  isDeclinedMakeupRequest,
} from "src/constants/makeupRequestEnum";

const ResolvedMakeupRequestsTable = ({ makeupReqsData }) => {
  const {
    currentView,
    setCurrentView,
    handleSearchTermChange,
    initialData,
    loadingInitialData,
    setCurrentMakeupRequestId,
    currentMakeupRequest,
    refreshData,
    pendingMakeupRequests,
    resolvedMakeupRequests,
  } = makeupReqsData;
  const { users, makeupRequests, instruments } = initialData;

  return (
    <>
      <Container fluid className="p-0">
        <CustomTableContainer>
          <CustomTable className="w-100 text-dark">
            <thead>
              <TableRow>
                <th>
                  <div>Request Date</div>
                </th>
                <th>
                  <div>Student Name</div>
                </th>
                <th>
                  <div>Instrument</div>
                </th>
                <th>
                  <div>Teacher Name </div>
                </th>
                <th>
                  <div>Status</div>
                </th>
              </TableRow>
            </thead>
            <tbody>
              {resolvedMakeupRequests.map((request) => {
                const {
                  createdAt,
                  createdBy,
                  createdByRole,
                  forAbsenceId,
                  forLessonId,
                  status,
                  privateLesson,
                  resolve,
                } = request;
                const createdAtStr = moment(createdAt).format("MM-DD-YYYY");

                const studentName = users.find(
                  ({ id }) => id === privateLesson?.studentId
                )?.fullName;

                const currentTimelineData = getPrivateLessonInfoOnSpecificDate({
                  date: updatedMomentNow(),
                  privateLesson,
                  withTimelineApproximation: true,
                });
                const teacherName = users.find(
                  ({ id }) => id === currentTimelineData?.teacherId
                )?.fullName;

                const instrumentName = instruments.find(
                  ({ id }) => id === privateLesson?.instrumentId
                )?.name;

                const statusStr = isApprovedMakeupRequest(status)
                  ? "Approved"
                  : isDeclinedMakeupRequest(status)
                  ? "Declined"
                  : "N/A";

                return (
                  <TableRow key={request.id}>
                    <td>{createdAtStr}</td>
                    <td>{studentName || "N/A"}</td>
                    <td>{instrumentName}</td>
                    <td>{teacherName || "N/A"}</td>
                    <td>{statusStr}</td>
                  </TableRow>
                );
              })}
            </tbody>
          </CustomTable>
        </CustomTableContainer>
      </Container>
    </>
  );
};

export default ResolvedMakeupRequestsTable;
