import moment from "moment";
import React from "react";
import {
  getAgeFromDate,
  getTotalConcertProgramDuration,
  injectUserStore,
} from "src/utils/helpers";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import { ActionIconContainer } from "./styled";
import { AddCircle } from "@styled-icons/fluentui-system-regular/AddCircle";
import useModal from "src/hooks/useModal";
import CreateConcertSoloProgramModal from "../../../CreateConcertSoloProgramModal";
import { hasAdminCredentials, isTeacher } from "src/constants/UserRoleEnum";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";

const ProgramsTable = ({ hookData, onActionSuccess, UserStore }) => {
  const user = UserStore.user;
  const {
    initialData,
    loadingInitialData,
    currentProgramId,
    setCurrentProgramId,
    currentProgram,
  } = hookData;
  const {
    concertPrograms,
    programUsers,
    programUsersPLs,
    instruments,
    teachers,
  } = initialData;

  const isTeacherUser = isTeacher(user.role);
  const isAdminUser = hasAdminCredentials(user.role);

  const editProgramModalData = useModal();

  const showEditBtn = isTeacherUser || isAdminUser;

  function onEditProgramClick(programId) {
    setCurrentProgramId(programId);
    editProgramModalData.openModal();
  }

  return (
    <div>
      {!!concertPrograms.length && (
        <div
          style={{
            overflow: "auto",
          }}
        >
          <CustomTableContainer minHeight="0px">
            <h4>Performers:</h4>
            <hr />
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <CustomTable>
                <thead style={{ backgroundColor: "#f5f2f4" }}>
                  <TableRow height="70px">
                    <th>
                      <div>No.</div>
                    </th>
                    <th>
                      <div>Name</div>
                    </th>
                    <th>
                      <div>Instrument</div>
                    </th>
                    <th>
                      <div>Age</div>
                    </th>
                    <th>
                      <div>Songs</div>
                    </th>
                    <th>
                      <div>Length</div>
                    </th>
                    <th>
                      <div>Note</div>
                    </th>
                    {isAdminUser && (
                      <th>
                        <div>Teacher</div>
                      </th>
                    )}
                    <th>
                      <div>Actions</div>
                    </th>
                  </TableRow>
                </thead>
                <tbody>
                  {concertPrograms.map((program, i) => {
                    // preparing table values
                    const {
                      createdAt,
                      usersIds,
                      note,
                      repertoires,
                      equipmentIds,
                    } = program;

                    const programDuration =
                      getTotalConcertProgramDuration(program);

                    const userPrivateLesson = isAdminUser
                      ? programUsersPLs.find(
                          ({ studentId }) => usersIds[0] === studentId
                        )
                      : isTeacherUser
                      ? programUsersPLs.find(
                          ({ teachersIds, studentId }) =>
                            teachersIds.includes(user.uid) &&
                            usersIds[0] === studentId
                        )
                      : undefined;

                    const lessonInstrumentId = userPrivateLesson?.instrumentId;
                    const instrumentName = instruments.find(
                      ({ id }) => id === lessonInstrumentId
                    )?.name;
                    const teacher = teachers.find(
                      ({ id }) =>
                        id ===
                        userPrivateLesson?.teachersIds?.[
                          userPrivateLesson?.teachersIds?.length - 1
                        ]
                    );
                    const teacherName = teacher?.fullName || "N/A";

                    const programUser = programUsers.find(
                      ({ id }) => usersIds[0] === id
                    );
                    const programUserName = programUser?.fullName;

                    const formattedCreatedAt = createdAt
                      ? moment(createdAt).format("MM-DD-YYYY hh:mm A")
                      : "N/A";

                    const programUserAge =
                      programUser.birthday &&
                      getAgeFromDate(
                        moment(programUser.birthday, "YYYY-MM-DD").toDate()
                      );

                    const isEvenRow = i % 2 === 0;

                    return (
                      <TableRow
                        height="70px"
                        style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                        key={program.id}
                      >
                        <td>{i + 1}</td>

                        <ClickableTableCell onClick={() => {}}>
                          {programUserName}
                        </ClickableTableCell>

                        <td>{instrumentName}</td>
                        <td>{programUserAge}</td>

                        <td>
                          {repertoires?.map((rep, i) => (
                            <p key={rep.id} className="mb-2">
                              {i + 1}. {rep.description}
                            </p>
                          ))}
                        </td>
                        <td>{programDuration}</td>

                        <td>{note}</td>
                        {isAdminUser && <td>{teacherName}</td>}
                        <td>
                          {showEditBtn && (
                            <ActionIconContainer>
                              <Edit2Outline
                                onClick={() => onEditProgramClick(program.id)}
                              />
                            </ActionIconContainer>
                          )}
                        </td>
                      </TableRow>
                    );
                  })}
                </tbody>
              </CustomTable>
            </div>
          </CustomTableContainer>
        </div>
      )}
      {editProgramModalData.isModalOpen && currentProgramId && (
        <CreateConcertSoloProgramModal
          hasAlreadySignedUp={true}
          signupId={currentProgram.signupId}
          isEditMode={true}
          programId={currentProgramId}
          modalData={editProgramModalData}
          onActionSuccess={onActionSuccess}
        />
      )}
    </div>
  );
};

export default injectUserStore(ProgramsTable);
