import React from "react";
import styled from "styled-components";
import { Notepad } from "@styled-icons/boxicons-regular/Notepad";
import { CustomTextarea } from "src/utils/shared/styled";
import { Header, ImageContainer, InfoText, ProfileImage } from "../styled";
import UnknownProfilePhoto from "../../../../../../assets/images/default-profile.jpg";
import {
  availableDaysToString,
  combineArrayToStr,
  injectUserStore,
  instrumentsToString,
  isRecurringActivityAbsence,
  sortArrayByDates,
} from "src/utils/helpers";
import moment from "moment";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { hasAdminCredentials, isStudent } from "src/constants/UserRoleEnum";
import { toJS } from "mobx";
import useGroupClassEventContent from "./hooks/useGroupClassEventContent";
import { groupClassLocationStatuses } from "src/constants/groupClassConstants";

const GroupClassEventContent = ({
  event,
  closeEventDetailsModal,
  UserStore,
}) => {
  const user = toJS(UserStore)?.user;
  const isStudentUser = isStudent(user.role);
  const showAbsences = !isStudentUser;

  const hookData = useGroupClassEventContent({ event, showAbsences });
  const { initialData } = hookData;
  const { absences, users } = initialData;
  const { teachers, start, end, duration, locationStatus } = event;

  //   const formattedDate = moment(start).format("MM/DD/YYYY hh:mm A");
  //   const formattedEndTime = moment(end).format("hh:mm A");
  //   const formattedDateAndTime = `${formattedDate} - ${formattedEndTime}`;

  const formattedStart = moment(start).format("hh:mm A");
  const formattedEnd = moment(end).format("hh:mm A");

  const formattedClassTime = `${formattedStart} - ${formattedEnd}`;

  const classDuration = `${duration} Minutes`;

  const locationStatusStr =
    locationStatus === groupClassLocationStatuses.IN_PERSON
      ? "In Person"
      : locationStatus === groupClassLocationStatuses.VIRTUAL
      ? "Virtual"
      : "In Person/Virtual";

  const weekDay = moment(start).format("dddd");

  const sortedClasses = sortArrayByDates(event.classes, {
    asc: true,
    dateAccessor: "startDate",
  });

  const firstClassDate = moment(sortedClasses[0].startDate).format("MMM Do");
  const lastClassDate = moment(
    sortedClasses[sortedClasses.length - 1].startDate
  ).format("MMM Do");
  const classPeriod = `${firstClassDate} - ${lastClassDate}`;

  let studentAbsenceNames = "";
  if (showAbsences) {
    const classAbsences = absences.filter((absence) => {
      const isAbsence = isRecurringActivityAbsence(
        {
          start: start,
          end: end,
          id: event.mainLessonId,
        },
        absence,
        { excludeTA: true, excludeGCAbsence: false }
      );
      return isAbsence;
    });

    const absencesStudentIds = [
      ...new Set(
        classAbsences.map(({ studentId }) => studentId).filter((el) => el)
      ),
    ];
    const absencesStudentNames = absencesStudentIds.map(
      (id) => users.find((user) => user.id === id)?.fullName
    );
    studentAbsenceNames = combineArrayToStr(absencesStudentNames);
  }

  return (
    <div className="p-5 pt-1">
      <div className="mb-3">
        <h5>Instructor:</h5>
        <hr />
      </div>
      {teachers.map((teacher) => {
        return (
          <div className="d-flex">
            <ImageContainer>
              <ProfileImage src={teacher?.imageUrl || UnknownProfilePhoto} />
            </ImageContainer>
            <div>
              <Header>{teacher?.fullName}</Header>
            </div>
          </div>
        );
      })}

      <div className="mt-4 mb-3">
        <h5>Class Info:</h5>
        <hr />
      </div>
      <div className="d-flex mt-4 mb-4">
        <ImageContainer>
          <Notepad width={45} height={45} color="#681e46" />
        </ImageContainer>
        <div className="w-100">
          {/* <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Date/Time:
            </InfoText>
            <InfoText>{formattedDateAndTime}</InfoText>
          </div> */}
          <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Week Day:
            </InfoText>
            <InfoText>{weekDay}</InfoText>
          </div>
          <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Class Time:
            </InfoText>
            <InfoText>{formattedClassTime}</InfoText>
          </div>
          <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Duration:
            </InfoText>
            <InfoText>{classDuration}</InfoText>
          </div>
          <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Class Period:
            </InfoText>
            <InfoText>{classPeriod}</InfoText>
          </div>

          {showAbsences && (
            <div className="d-flex mb-2">
              <InfoText className="me-2" bold>
                Student Absence:
              </InfoText>
              <InfoText>{studentAbsenceNames}</InfoText>
            </div>
          )}
          {/* <div className="d-flex mb-2">
            <InfoText className="me-2" bold>
              Class Type:
            </InfoText>
            <InfoText>{locationStatusStr}</InfoText>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default injectUserStore(GroupClassEventContent);
