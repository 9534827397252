import React from "react";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { Plus } from "@styled-icons/entypo/Plus";
import { Open } from "@styled-icons/fluentui-system-regular/Open";
import {
  GroupClassCardContainer,
  GroupClassHeader,
  InnerCardContainer,
  ButtonsContainer,
  StyledButton,
  GroupClassActionButton,
} from "../../styled";
import ClassInfo from "./ClassInfo";
import useGroupClassInfoCard from "./hooks/useGroupClassInfoCard";
import { infoCardViews } from "./constants";
import EnrolledStudents from "./EnrolledStudents";
import moment from "moment";
import { groupClassesListViews } from "../../constants";
import { Popconfirm } from "antd";

const GroupClassInfoCard = ({
  headerColor,
  teachers,
  groupClassObj,
  onAddStudentClick,
  onOpenClick,
  onClassStatusChange,
  onGroupClassDelete,
}) => {
  const { classes, title } = groupClassObj;
  const { active } = groupClassesListViews;
  const firstClass = classes?.[0];
  const lastClass = classes?.[classes?.length - 1];

  let classTitleInfo = "";
  if (firstClass && lastClass) {
    const firstClassDate = firstClass.startDate;
    const lastClassDate = lastClass.startDate;

    const formattedFirstDate = moment(firstClassDate).format("dddd hh:mm A");
    const firstAndLastClassText = `(${moment(firstClassDate).format(
      "MMM Do"
    )} - ${moment(lastClassDate).format("MMM Do")})`;

    classTitleInfo = `${formattedFirstDate} ${firstAndLastClassText}`;
  }

  const hookData = useGroupClassInfoCard({ groupClass: groupClassObj });
  const {
    setCurrentInfoCardView,
    isStudentEnrollmentsView,
    groupClassEnrollments,
  } = hookData;

  const onViewStudentsClick = () => {
    setCurrentInfoCardView(infoCardViews.enrollments.value);
  };
  const onViewClassInfoClick = () => {
    setCurrentInfoCardView(infoCardViews.info.value);
  };

  const statusButtonText =
    groupClassObj.status === active.value ? "Deactivate" : "Activate";

  return (
    <GroupClassCardContainer>
      <GroupClassHeader bgColor={headerColor}>
        <span>Class: {title || ""}</span>
        <span>{classTitleInfo}</span>
      </GroupClassHeader>
      <InnerCardContainer>
        {isStudentEnrollmentsView ? (
          <EnrolledStudents enrollments={groupClassEnrollments} />
        ) : (
          <ClassInfo groupClassObj={groupClassObj} teachers={teachers} />
        )}

        <ButtonsContainer>
          <div>
            <StyledButton onClick={() => onAddStudentClick()}>
              <Plus />
              Add Student
            </StyledButton>
            {isStudentEnrollmentsView ? (
              <StyledButton onClick={() => onViewClassInfoClick()}>
                <EyeFill />
                View Class Info
              </StyledButton>
            ) : (
              <StyledButton onClick={() => onViewStudentsClick()}>
                <EyeFill />
                View Students
              </StyledButton>
            )}
            <StyledButton onClick={() => onOpenClick()}>
              <Open />
              Open
            </StyledButton>
            <Popconfirm
              title={statusButtonText}
              description={`${statusButtonText} this group class?`}
              okText={statusButtonText}
              onConfirm={onClassStatusChange}
            >
              <GroupClassActionButton>
                {statusButtonText}
              </GroupClassActionButton>
            </Popconfirm>
            <Popconfirm
              title="Delete"
              description={`Delete this group class?`}
              okText="Delete"
              onConfirm={onGroupClassDelete}
            >
              <GroupClassActionButton>Delete</GroupClassActionButton>
            </Popconfirm>
          </div>
        </ButtonsContainer>
      </InnerCardContainer>
    </GroupClassCardContainer>
  );
};

export default GroupClassInfoCard;
