import { getDoc, updateDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import {
  InputLabel,
  PrimaryButton,
} from "../../../../../../../../../utils/shared/styled";
import InputWithLabel from "../../../../../../../../Auth/shared/InputWithLabel";
import { SubmitSpinner } from "../../../../../../../../common";
import { FirebaseContext } from "../../../../../../../../Firebase";
import * as yup from "yup";
import { requiredStringSchema } from "../../../../../../../../../utils/validations";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

import useModal from "src/hooks/useModal";
import ChangeAuthInfoModal from "../../../../../../../common/components/ChangeAuthInfoModal";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { workConditions } from "src/constants/paymentsEnum";

const BasicInfoValidationSchema = yup.object().shape({
  fullName: requiredStringSchema,
});

const FormContainer = styled.div`
  /* width: 60%; */
`;
const InputWrapper = styled.div`
  width: ${({ width }) => width || "100%"};
  /* margin-right: 20px;
  margin-bottom: 20px; */
`;
const QRContainer = styled.div`
  width: 40%;
  text-align: center;
`;
const QRImage = styled.img`
  width: 250px;
  height: 250px;
`;
const FormInfoSection = ({ teacher, modalData, refreshData }) => {
  const firebase = useContext(FirebaseContext);

  const authInfoChangeModalData = useModal();

  const {
    QRCodeImage,
    systemID,
    fullName,
    birthday,
    enrollmentDate,
    primaryPhone,
    primaryEmail,
    homeAddress,
    city,
    state,
    zip,
    trialQuestionnaireLink,
    zoomEmail,
    zoomPassword,
    meetingId,
    meetingPasscode,
    workCondition,
  } = teacher || {};

  return (
    <div className="">
      <FormContainer>
        <Formik
          initialValues={{
            fullName: fullName || "",
            birthday: birthday || "",
            enrollmentDate: enrollmentDate || "",
            primaryPhone: primaryPhone || "",
            primaryEmail: primaryEmail || "",
            homeAddress: homeAddress || "",
            city: city || "",
            state: state || "",
            zip: zip || "",
            trialQuestionnaireLink: trialQuestionnaireLink || "",
            zoomEmail: zoomEmail || "",
            zoomPassword: zoomPassword || "",
            meetingId: meetingId || "",
            meetingPasscode: meetingPasscode || "",
            workCondition: workCondition || "",
          }}
          validationSchema={BasicInfoValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("TRIGGERED SUBMIT !!");
            try {
              setSubmitting(true);
              const updateRes = await updateDoc(
                firebase.user(teacher.id),
                values
              );
              toast.success("Teacher Updated Successfully");
            } catch (error) {
              toast.error(error.message);
              console.log("saving teacher error", error);
            } finally {
              setSubmitting(false);
              refreshData();
              modalData.closeModal();
            }
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => {
            const { homeAddress, city, state, zip } = values;
            const fullAddressValue = `${homeAddress ? `${homeAddress}` : ""}${
              city ? `, ${city}` : ""
            }${state ? `, ${state}` : ""}${zip ? `, ${zip}` : ""}`;

            const selectedWorkCondition = Object.values(workConditions).find(
              ({ value }) => value === values.workCondition
            );

            return (
              <Form>
                <Row className="mb-3">
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="fullName"
                        error={errors.fullName}
                        touched={touched.fullName}
                        label="Teacher's Name"
                        placeholder="Name Of Teacher"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="birthday"
                        error={errors.birthday}
                        touched={touched.birthday}
                        label="Birthday"
                        type="date"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="enrollmentDate"
                        error={errors.enrollmentDate}
                        touched={touched.enrollmentDate}
                        label="Enrollment Date"
                        type="date"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="primaryPhone"
                        error={errors.primaryPhone}
                        touched={touched.primaryPhone}
                        label="Primary Phone"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="primaryEmail"
                        error={errors.primaryEmail}
                        touched={touched.primaryEmail}
                        label={
                          <>
                            Primary Email (For Login)
                            <PrimaryButton
                              // has to specify type='button' so it won't behave as the submit btn
                              type="button"
                              onClick={authInfoChangeModalData.openModal}
                              className="ms-2"
                              padding="5px"
                              width="110px"
                            >
                              Change
                            </PrimaryButton>
                          </>
                        }
                        placeholder="Primary Email"
                        type="email"
                        disabled
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="homeAddress"
                        error={errors.homeAddress}
                        touched={touched.homeAddress}
                        label="Home Address"
                        placeholder="Home Address"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="city"
                        error={errors.city}
                        touched={touched.city}
                        label="City"
                        placeholder="City"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="state"
                        error={errors.state}
                        touched={touched.state}
                        label="State"
                        placeholder="State"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="zip"
                        error={errors.zip}
                        touched={touched.zip}
                        label="Zip Code"
                        placeholder="Zip"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        // name="homeAddress"
                        // error={errors.homeAddress}
                        // touched={touched.homeAddress}
                        label="Full Address"
                        placeholder="Full Address"
                        type="text"
                        value={fullAddressValue}
                        disabled
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper className="mt-3 mb-3">
                      <InputLabel>Payment Type</InputLabel>
                      <CustomReactSelect
                        placeholder="Payment Type"
                        options={Object.values(workConditions).map(
                          (condition) => {
                            const value = condition.value;
                            const label = condition.title;
                            return {
                              label,
                              value,
                            };
                          }
                        )}
                        onChange={(option) => {
                          if (!option) return;

                          setFieldValue("workCondition", option.value);
                        }}
                        value={
                          selectedWorkCondition
                            ? {
                                label: selectedWorkCondition.title,
                                value: selectedWorkCondition.value,
                              }
                            : null
                        }
                        isMulti={false}
                        width="100%"
                        wrapOptions={false}
                      />
                      <InputWithLabel
                        // name="homeAddress"
                        // error={errors.homeAddress}
                        // touched={touched.homeAddress}
                        label="Full Address"
                        placeholder="Full Address"
                        type="text"
                        value={fullAddressValue}
                        disabled
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="trialQuestionnaireLink"
                        error={errors.trialQuestionnaireLink}
                        touched={touched.trialQuestionnaireLink}
                        label="Trial Questionnaire Link"
                        placeholder="Trial Questionnaire Link"
                        type="url"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <hr className="mb-4 mt-4" />
                <Row className="mb-3">
                  <h4>Teacher Virtual Account Info</h4>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="zoomEmail"
                        error={errors.zoomEmail}
                        touched={touched.zoomEmail}
                        label="Zoom Email Address"
                        placeholder="Zoom Email Address"
                        type="email"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="zoomPassword"
                        error={errors.zoomPassword}
                        touched={touched.zoomPassword}
                        label="Zoom Password"
                        placeholder="Zoom Password"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="meetingId"
                        error={errors.meetingId}
                        touched={touched.meetingId}
                        label="Zoom Meeting ID"
                        placeholder="Zoom Meeting ID"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                  <Col>
                    <InputWrapper>
                      <InputWithLabel
                        name="meetingPasscode"
                        error={errors.meetingPasscode}
                        touched={touched.meetingPasscode}
                        label="Zoom Meeting Passcode"
                        placeholder="Zoom Meeting Passcode"
                        type="text"
                      />
                    </InputWrapper>
                  </Col>
                </Row>
                <div className="mt-4 w-100 d-flex justify-content-center">
                  <PrimaryButton
                    width="300px"
                    type="submit"
                    height="45px"
                    padding="0"
                  >
                    {isSubmitting ? (
                      <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                    ) : (
                      "Save Changes"
                    )}
                  </PrimaryButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
      <ChangeAuthInfoModal
        userId={teacher.id}
        modalData={authInfoChangeModalData}
        title="Change Login Email"
        onSuccessCallback={() => refreshData()}
      />
    </div>
  );
};

export default FormInfoSection;
