import { Avatar } from "antd";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";

const teacherInfoItems = (teacher) => [
  {
    children: (
      <Avatar
        src={teacher?.imageUrl || UnknownProfilePhoto}
        alt={teacher?.fullName || "teacher"}
        size={100}
      />
    ),
  },
  {
    label: "Contact name",
    children: teacher?.fullName,
  },
  {
    label: "Email",
    children: teacher?.primaryEmail || "No email found",
  },
  {
    label: "Phone",
    children: teacher?.primaryPhone || "No phone found",
  },
];

export default teacherInfoItems;
