import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import RouteContent from "../../common/RouteContent";
import ProfileDetails from "./ProfileDetails";
import "./style.css";
import lightBurbleBackground from "./images/lightPurple.svg";
import darkBurbleBackground from "./images/darkPurple.svg";
import { RightArrow } from "@styled-icons/boxicons-solid/RightArrow";
import ProfileModal from "./ProfileModal";
import { isGuest, isParent, isStudent } from "src/constants/UserRoleEnum.js";
import { profilePageViews } from "./constants.js";
import { PrimaryButton } from "src/utils/shared/styled.js";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import ProfileImageModal from "./ProfileImageModal/index.js";
import useModal from "src/hooks/useModal.js";
import ChangeProfilePicture from "./ChangeProfilePicture";
import { FirebaseContext } from "src/components/Firebase";
import store from "src/stores/UserStore";
import { Space } from "antd";
import CustomTooltip from "../common/components/CustomTooltip";
import ActionConfirmModal from "../Guest/components/ActionConfirmModal";

function Profile({ user: authUser, selectedStudent }) {
  const profileImageModalData = useModal();
  // changes the view to display the logged in user profile or the students profile (in case of parent)
  const [currentView, setCurrentView] = useState(profilePageViews.USER);
  const [resendConfirmModalOpen, setResendConfirmModalOpen] = useState(false);
  const toggleCurrentView = () => {
    if (currentView === profilePageViews.STUDENT) {
      setCurrentView(profilePageViews.USER);
    } else if (currentView === profilePageViews.USER) {
      setCurrentView(profilePageViews.STUDENT);
    }
  };
  const navigate = useNavigate();

  const user =
    currentView === profilePageViews.STUDENT ? selectedStudent : authUser;
  const firebase = useContext(FirebaseContext);

  const isStudentUser = isStudent(user.role);

  const showToggleViewButton = isParent(authUser.role);

  const userName = user.fullName;
  const userRole = user.role;
  const [modalData, setModalData] = useState({});

  const changeModalData = ({
    activeStatus,
    fieldName,
    headerText,
    inputType,
    bodyText,
  }) => {
    setModalData({
      activeStatus,
      fieldName,
      headerText,
      inputType,
      bodyText,
    });
  };

  const handleResendClick = async () => {
    await firebase.sendEmailVerification();
    setResendConfirmModalOpen(true);
  };

  return (
    <>
      <RouteContent title={userRole.replace(/_/g, " ") + " Profile"}>
        {showToggleViewButton && (
          <div className="text-center">
            <PrimaryButton width="250px" onClick={toggleCurrentView}>
              Toggle User Profile
              <RightArrow
                className="ms-2"
                width={20}
                height={20}
                color="#fff"
              />
            </PrimaryButton>
          </div>
        )}
        {isGuest(user.role) && !store.user.emailVerified && (
          <div className="resend-email">
            <p style={{ fontSize: 20 }}>
              We've sent you a confirmation email, please confirm it to access
              the next steps of the application process.
            </p>
            <PrimaryButton
              width="300px"
              backgroundColor="#681e46DD"
              onClick={handleResendClick}
            >
              Resend confirmation link
            </PrimaryButton>
          </div>
        )}
        <figure
          className={"profile-page " + (isStudentUser ? "student" : "")}
          style={{ position: "relative" }}
        >
          <figure className="profile-personal-details">
            <picture className="profile-background-image">
              <img
                loading="lazy"
                src={
                  isStudentUser ? lightBurbleBackground : darkBurbleBackground
                }
                alt="purple background"
              />
            </picture>
            <picture className="profile-image">
              <img
                style={{ objectFit: "cover" }}
                loading="lazy"
                src={user.imageUrl || UnknownProfilePhoto}
                alt={userName}
              />
            </picture>
            <h3> {userRole}</h3>
            <h2>{userName}</h2>
            <ChangeProfilePicture
              profileImageModalData={profileImageModalData}
            />
          </figure>
          <ProfileDetails
            user={user}
            authUser={authUser}
            changeModalData={changeModalData}
            userRole={userRole}
            currentView={currentView}
          />
          {isGuest(user.role) && (
            <Space className="buttons-wrapper">
              <PrimaryButton
                backgroundColor="#681e46DD"
                onClick={() => navigate("/dashboard/guest-profile/2")}
                disabled={!user?.emailVerified}
                id="next-step-btn"
              >
                Next page
              </PrimaryButton>
              {!user?.emailVerified && (
                <CustomTooltip anchorSelect="#next-step-btn">
                  Please confirm your email to access the next steps
                </CustomTooltip>
              )}
            </Space>
          )}
        </figure>
      </RouteContent>

      <ProfileModal
        {...user}
        {...modalData}
        changeModalData={changeModalData}
      />
      <ProfileImageModal modalData={profileImageModalData} user={user} />
      <ActionConfirmModal
        isOpen={resendConfirmModalOpen}
        toggle={() => setResendConfirmModalOpen((prevState) => !prevState)}
        text={`Email was sent to ${user?.email || user?.primaryEmail}`}
      />
    </>
  );
}

export default Profile;
