import React from "react";
import { feedItems } from "src/constants/feedItemsEnum";
import useLatestFeed from "src/hooks/useLatestFeed";
import { injectUserStore } from "src/utils/helpers";
import { CloseIcon, LatestItemContainer } from "./styled";

const LatestFeedItemSection = ({ UserStore }) => {
  const user = UserStore.user;
  const { latestFeedItem, showLatestFeedItem, toggleLatestFeedSection } =
    useLatestFeed({ user });

  if (!latestFeedItem || !showLatestFeedItem) {
    return <div></div>;
  }

  const { title, description, body, type } = latestFeedItem;
  const typeObj = Object.values(feedItems).find((item) => item.type === type);
  const itemTypeTitle = typeObj?.title || "Update";
  return (
    <LatestItemContainer>
      <div>
        <h4>Check The Latest {itemTypeTitle}</h4>
        <p>{title}</p>
      </div>
      <div>
        <CloseIcon onClick={toggleLatestFeedSection} />
      </div>
    </LatestItemContainer>
  );
};

export default injectUserStore(LatestFeedItemSection);
