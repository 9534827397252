import React from "react";
import { GridItem } from "../styled";
import { CreateClassButton } from "./styled";
import CreateGroupClassModal from "../../common/components/CreateGroupClassModal";
import useModal from "src/hooks/useModal";
import { CustomLabel } from "src/utils/styled";
import { CustomSelectField, PrimaryButton } from "src/utils/shared/styled";

const CreateClassSection = ({ hookData }) => {
  const createClassModalData = useModal();
  const { handleFiltersChange, resetFilters, groupClassFilters, initialData } =
    hookData;
  return (
    <>
      <div>
        <CreateClassButton onClick={createClassModalData.openModal}>
          <i
            style={{ color: "#ac0f64", marginTop: "7px" }}
            className="fas fa-plus mx-2"
          />
          Create New Class
        </CreateClassButton>

        <div style={{ width: "40%", margin: "auto", marginTop: "70px" }}>
          <div>
            <CustomLabel>Location:</CustomLabel>
            <CustomSelectField
              value={groupClassFilters.locationFilter?.locationId}
              onChange={(e) =>
                handleFiltersChange(
                  "locationFilter",
                  "locationId",
                  e.target.value
                )
              }
              width="100%"
            >
              <option value="" disabled>
                Location
              </option>
              {initialData.locations.map((location, i) => {
                return (
                  <option key={location.id} value={location.id}>
                    {location.name || "N/A"}
                  </option>
                );
              })}
            </CustomSelectField>
          </div>
          <div className="mt-4">
            <PrimaryButton
              width="100%"
              backgroundColor="#c361c1"
              onClick={() => resetFilters()}
            >
              Reset Filters
            </PrimaryButton>
          </div>
        </div>
      </div>

      {/* MODALS */}
      <CreateGroupClassModal modalData={createClassModalData} />
    </>
  );
};

export default CreateClassSection;
