import React from "react";
import { emailValidationSchema } from "src/utils/validations";
import {
  Container,
  RedBarContainer,
  RedBarText,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../styled";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { SubmitSpinner } from "src/components/common";
import { RequiredInputSpan } from "src/utils/shared/styled";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const authInfoChangeValidationSchema = yup.object().shape({
  newEmail: emailValidationSchema,
});

const ChangeAuthInfoBody = ({
  userId,
  title,
  modalData,
  onSuccessCallback = () => {},
}) => {
  const { updateUserAuth } = useFirebaseFns();

  return (
    <>
      <Formik
        initialValues={{ newEmail: "" }}
        validationSchema={authInfoChangeValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            const { newEmail } = values;
            const lowerCaseEmail = newEmail.toLowerCase();
            const newUserRecord = await updateUserAuth(userId, {
              email: lowerCaseEmail,
            });
            if (!newUserRecord?.uid) {
              toast.error("Something went wrong");
              return;
            }

            toast.success("Updated Successfully");
            onSuccessCallback();
          } catch (err) {
            console.log(err);
            toast.error(err.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          touched,
          errors,
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            <Form>
              <Container>
                {/* Red Bar */}
                <RedBarContainer>
                  <RedBarText>{title}</RedBarText>
                </RedBarContainer>
                <div
                  style={{
                    padding: "25px",
                    width: "100%",
                  }}
                >
                  <div className="h-100 w-100 d-flex align-items-center">
                    <InputWrapper className="w-100">
                      <CustomLabel>
                        New Login Email:<RequiredInputSpan>*</RequiredInputSpan>
                      </CustomLabel>

                      <CustomInput
                        width="100%"
                        className="form-control"
                        name="newEmail"
                        value={values.newEmail}
                        onChange={handleChange}
                        error={errors.newEmail}
                        onBlur={handleBlur}
                      />
                      {touched.newEmail && errors.newEmail && (
                        <span className="errorMsg mt-2 ms-1">
                          {errors.newEmail}
                        </span>
                      )}
                    </InputWrapper>
                  </div>
                </div>
              </Container>
              <CustomButtonsWrapper>
                <CustomButton onClick={modalData.closeModal}>
                  Cancel
                </CustomButton>
                <CustomButton type="submit" primary>
                  {isSubmitting ? (
                    <div>
                      <SubmitSpinner
                        style={{ width: "20px", height: "20px" }}
                      />
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </CustomButton>
              </CustomButtonsWrapper>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ChangeAuthInfoBody;
