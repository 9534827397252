import React, { useContext } from "react";
import {
  AdminUserContext,
  StudentsContext,
  TeachersContext,
} from "../common/libraryContext";
import LabeledRadioButton from "../common/LabeledRadioButton";
import SearchSelect from "../common/SearchSelect";
import { isActiveTeacher } from "src/utils/helpers";

const AdminUserSelect = () => {
  const {
    setSelectedUserType,
    selectUserType,
    selectedUserType,
    onUserSelect,
  } = useContext(AdminUserContext);
  const { teachers } = useContext(TeachersContext);
  const { students } = useContext(StudentsContext);
  const teachersOptions = teachers
    .filter((teacher) => isActiveTeacher(teacher))
    .map(({ id, fullName }) => ({ label: fullName, value: id }));
  const studentsOptions = students.map(({ id, fullName }) => ({
    label: fullName,
    value: id,
  }));
  return (
    <div className={"mb-4"}>
      <div className={"d-flex align-items-center"}>
        <LabeledRadioButton
          label={"all"}
          checked={selectedUserType === "all"}
          id={"all"}
          onChange={() => {
            setSelectedUserType("all");
            selectUserType("all");
          }}
        />
        <LabeledRadioButton
          label={"school"}
          checked={selectedUserType === "school"}
          id={"school"}
          onChange={() => {
            setSelectedUserType("school");
            selectUserType("school");
          }}
        />
        <LabeledRadioButton
          label={"teacher"}
          checked={selectedUserType === "teacher"}
          id={"teacher"}
          onChange={() => setSelectedUserType("teacher")}
        />
        <LabeledRadioButton
          label={"student"}
          checked={selectedUserType === "student"}
          id={"student"}
          onChange={() => setSelectedUserType("student")}
        />
        {selectedUserType === "teacher" ? (
          <SearchSelect
            options={teachersOptions}
            name={"user-type-teacher"}
            onOptionSelect={(e) => {
              onUserSelect(e.value);
            }}
            selectedOption={[]}
            placeholder={"Select a teacher"}
          />
        ) : selectedUserType === "student" ? (
          <SearchSelect
            options={studentsOptions}
            name={"user-type-student"}
            onOptionSelect={(e) => {
              onUserSelect(e.value);
            }}
            selectedOption={[]}
            placeholder={"Select a student"}
          />
        ) : null}
      </div>
    </div>
  );
};
export default AdminUserSelect;
