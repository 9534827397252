import React from "react";
import { useContext } from "react";
import { TrialLessonsContext } from "src/components/Dashboard/TrialLessons/TrialLessons";
import { PrimaryButton } from "src/utils/shared/styled";

import {
  HeaderContainer,
  LessonsInfoContainer,
  NavButtonsContainer,
  RedBarContainer,
  RedBarText,
} from "../../styled";
import LessonsInfoContent from "./LessonsInfoContent";
import LessonsNavSection from "./LessonsNavSection";

const LessonsInfoSection = ({
  trialStudentData,
  showCreateStudentTrialLesson,
  studentIndex,
}) => {
  const { instruments, students, lessons } = useContext(TrialLessonsContext);

  const {
    selectedLesson,
    studentLessons,
    setSelectedLessonId,
    handleTrialLessonUpdate,
    followUpNote,
    setFollowUpNote,
    handleDeleteTrialLesson,
  } = trialStudentData;

  const selectedLessonInstrumentName =
    instruments[selectedLesson?.instrument]?.name;

  const openEditLessonModal = () => {
    showCreateStudentTrialLesson(studentIndex, "edit", selectedLesson?.id);
  };

  return (
    <div>
      <HeaderContainer>
        <div className="mt-3 mb-2 d-flex justify-content-end align-items-center">
          <PrimaryButton
            onClick={() => {
              showCreateStudentTrialLesson(studentIndex);
            }}
            width="105px"
          >
            Add New
          </PrimaryButton>
        </div>

        <NavButtonsContainer>
          <LessonsNavSection
            trialLessons={studentLessons}
            selectedLesson={selectedLesson}
            setSelectedLessonId={setSelectedLessonId}
          />
        </NavButtonsContainer>
      </HeaderContainer>
      <LessonsInfoContainer>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>{selectedLessonInstrumentName || "N/A"}</RedBarText>
        </RedBarContainer>
        {/* Lesson Form Container */}
        <LessonsInfoContent
          selectedLesson={selectedLesson}
          openEditLessonModal={openEditLessonModal}
          handleTrialLessonUpdate={handleTrialLessonUpdate}
          followUpNote={followUpNote}
          setFollowUpNote={setFollowUpNote}
          handleDeleteTrialLesson={handleDeleteTrialLesson}
        />
      </LessonsInfoContainer>
    </div>
  );
};

export default LessonsInfoSection;
