import moment from "moment";
import UnknownProfilePhoto from "../../../../../../assets/images/default-profile.jpg";
import {
  availableDaysToString,
  combineArrayToStr,
  getRecurringOccuranceWeekNumber,
  instrumentsToString,
} from "../../../../../../utils/helpers";
import styled from "styled-components";
import {
  eventsMap,
  isPackagePrivateLesson,
  isPrivateLessonEvent,
  isTrialLessonEvent,
} from "../../../../../../constants/eventsEnum";
import { ModeEdit } from "@styled-icons/material/ModeEdit";
import { hasAdminCredentials, UserRole } from "src/constants/UserRoleEnum";
import { Reset } from "@styled-icons/boxicons-regular/Reset";
import { updateDoc } from "firebase/firestore";
import { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";
import { CustomTextarea } from "src/utils/shared/styled";
import { Notepad } from "@styled-icons/boxicons-regular/Notepad";
import {
  ActionButtonsContainer,
  Header,
  ImageContainer,
  InfoText,
  ProfileImage,
} from "../styled";

const PrivateLessonEventContent = ({
  event,
  initialData,
  openModificationModal,
  openPackageLessonEditModal,
  userRole,
  refreshData,
  closeEventDetailsModal,
  studioUsages,
}) => {
  const firebase = useContext(FirebaseContext);

  const { locations, instruments, studios } = initialData;

  const {
    student,
    instrumentId,
    modifiedInstrumentId,

    start,
    end,
    eventCode,
    isModifiedEvent,
    isPLAbsenceEvent,
    currentTimeline,
    type,
  } = event || {};

  const teacher = event.teacher || currentTimeline.teacher;
  const teacherId = teacher?.id || teacher?.uid;

  const instrument = modifiedInstrumentId
    ? instruments[modifiedInstrumentId]
    : instruments[instrumentId];

  const isRegularPL =
    isPrivateLessonEvent(eventCode) && !isPackagePrivateLesson(type);

  const locationName = isRegularPL
    ? locations[currentTimeline.locationId]?.name
    : locations[event.locationId]?.name;

  const studentName = student?.fullName;

  const formattedDate =
    moment(start).format("dddd, MMMM D: h:mm a - ") +
    moment(end).format("h:mm a");
  const eventDay = moment(start).format("dddd");
  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );

  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  const teacherStudioUsageInCurrentDate = studioUsages?.find((studioUsage) => {
    const isSameTeacher = teacherId === studioUsage.teacher_id;
    const isSameDate =
      moment(event.start).year() === studioUsage.year &&
      moment(event.start).month() + 1 === studioUsage.month &&
      moment(event.start).format("dddd") === studioUsage.weekday;

    return isSameTeacher && isSameDate;
  });
  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;

  // show the timeline note on the first lesson occurance in the new timeline
  let isTimelineNoteShown = false;
  if (
    isRegularPL &&
    currentTimeline?.note &&
    currentTimeline?.startDate &&
    moment(start).isSame(currentTimeline.startDate, "date")
  ) {
    isTimelineNoteShown = true;
  }

  const resetModifiedPrivateLessonEvent = async () => {
    try {
      const modification = event.modifications?.find(
        ({ weekNumber, date, timelineObjId }) =>
          date &&
          moment(date).isSame(event.start, "minutes") &&
          timelineObjId === event.currentTimeline?.id
      );
      const eventWeekNumber = modification?.weekNumber;
      const modificationTimelineObjId = modification?.timelineObjId;
      const updatedModifications = event.modifications.filter(
        ({ weekNumber, timelineObjId }) =>
          weekNumber !== eventWeekNumber &&
          timelineObjId !== modificationTimelineObjId
      );

      // const updatedActivityModifications = updatedModifications.map(
      //   (modification) => {
      //     return {
      //       id: modification.id,
      //       timelineObjId: modification.timelineObjId,
      //       start_time: modification.date,
      //       end_time: moment(modification.date)
      //         .add(modification.duration, "minutes")
      //         .toDate(),
      //       weekNumber: modification.weekNumber,
      //     };
      //   }
      // );
      await updateDoc(firebase.PrivateLesson(event.id), {
        modifications: updatedModifications,
      });

      // await updateDoc(firebase.userActivity(event.id), {
      //   modifications: updatedActivityModifications,
      // });

      toast.success("Reset Successfully");
      closeEventDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  return (
    <div className="p-5 pt-1">
      <ActionButtonsContainer>
        {!isModifiedEvent &&
          !isPLAbsenceEvent &&
          hasAdminCredentials(userRole) && (
            <ModeEdit
              onClick={
                isRegularPL ? openModificationModal : openPackageLessonEditModal
              }
              style={{ cursor: "pointer" }}
              size={30}
            />
          )}
        {isRegularPL &&
          isModifiedEvent &&
          !isPLAbsenceEvent &&
          hasAdminCredentials(userRole) && (
            <Reset
              onClick={resetModifiedPrivateLessonEvent}
              style={{ cursor: "pointer" }}
              size={30}
            />
          )}
      </ActionButtonsContainer>
      {!!student && (
        <div className="d-flex mt-4 mb-3">
          <ImageContainer>
            <ProfileImage src={student?.imageUrl || UnknownProfilePhoto} />
          </ImageContainer>
          <div>
            <Header>
              {studentName} {`(${instrument?.name})`}
            </Header>
            <InfoText>{formattedDate}</InfoText>
            {isRegularPL && <InfoText>Weekly on {eventDay}</InfoText>}
          </div>
        </div>
      )}
      {isTimelineNoteShown ? (
        <div className="d-flex mt-4 mb-4">
          <ImageContainer>
            <Notepad width={45} height={45} color="#681e46" />
          </ImageContainer>
          <div className="w-100">
            <CustomTextarea
              marginTop="0px"
              boxShadow="none"
              bgColor="#fff"
              width="100%"
              value={currentTimeline?.note}
              disabled
            ></CustomTextarea>
          </div>
        </div>
      ) : null}
      <div className="d-flex">
        <ImageContainer>
          <ProfileImage src={teacher?.imageUrl || UnknownProfilePhoto} />
        </ImageContainer>
        <div>
          <Header>
            {teacher?.fullName}{" "}
            {`(${teachingDaysStr}) (${teachersInstrumentsStr})`}
          </Header>
          <InfoText>{locationName || "No Location found"}</InfoText>
          <InfoText>{studioName || "No Studio found"}</InfoText>
        </div>
      </div>
    </div>
  );
};

export default PrivateLessonEventContent;
