import { Input } from "antd";
import styled from "styled-components";

const PartnerFieldsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;

  label {
    width: 100%;
    font-size: 18px;
  }
`;

const MessageInput = styled(Input.TextArea)`
  width: 100%;
  min-height: 90px !important;
  margin-top: 8px;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`;

export { MessageInput, PartnerFieldsWrapper };
