export const makeupLessonTypes = {
  MAKEUP: "makeUp",
  SUBSTITUTE: "substitute",
  PAID_MAKEUP: "paid_makeUp",
};
export function isMakeupTypeMakeupLesson(type) {
  if (!type) return;
  return type === makeupLessonTypes.MAKEUP;
}
export function isSubstituteTypeMakeupLesson(type) {
  if (!type) return;
  return type === makeupLessonTypes.SUBSTITUTE;
}
export function isPaidMakeupTypeMakeupLesson(type) {
  if (!type) return;
  return type === makeupLessonTypes.PAID_MAKEUP;
}
