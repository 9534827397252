import styled from "styled-components";

const FeedbackStatusWrapper = styled.div`
  width: 100%;
  border: 1px solid #33333333;
  padding: 16px;
  border-radius: 12px;

  @media screen and (min-width: 1300px) {
    width: 25%;
  }
`;

const StatusField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 24px;

  button {
    display: block;
    margin-left: auto;
  }
`;

export { FeedbackStatusWrapper, StatusField };
