import React from "react";
import useModal from "src/hooks/useModal";
import DropdownButton from "src/components/Dashboard/common/components/DropdownButton";
import useParentActions from "./hooks/useParentActions";
import ConfirmationModal from "../../../../ConfirmationModal";

const ParentActions = ({ parentId, refreshData, closeParentInfoModal }) => {
  const {
    toggleParentLoginStatus,
    isLoginDisabledUser,
    loadingAction,
    deleteUserPermanently,
  } = useParentActions({
    parentId,
    onSuccess: () => {
      closeParentInfoModal();
      refreshData();
    },
  });

  const deleteUserModalData = useModal();

  const optionsList = [
    isLoginDisabledUser
      ? {
          title: "Turn on Platform Access",
          action: () => toggleParentLoginStatus(true),
        }
      : {
          title: "Turn off Platform Access",
          action: () => toggleParentLoginStatus(false),
        },
    {
      title: "Delete User",
      action: deleteUserModalData.openModal,
    },
  ];

  const onDeleteApprove = async () => {
    await deleteUserPermanently();
  };
  const onDeleteDecline = () => {
    deleteUserModalData.closeModal();
  };

  return (
    <div className="text-end">
      <DropdownButton loadingAction={loadingAction} optionsList={optionsList} />

      {/* MODALS */}
      <ConfirmationModal
        onApprove={onDeleteApprove}
        onCancel={onDeleteDecline}
        modalData={deleteUserModalData}
        isSubmitting={loadingAction}
        approveBtnText={"Delete"}
        cancelBtnText="Cancel"
        title="Delete Parent Permanently ?"
        withCheckbox={true}
        checkboxDescription="I understand that this will delete this parent user permanently."
      />
    </div>
  );
};

export default ParentActions;
