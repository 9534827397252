import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment-timezone";
import React from "react";
import { compose } from "recompose";

const TimezoneContext = React.createContext();

const TimezoneContextProvider = ({ children, UserStore, ...props }) => {
  const user = toJS(UserStore.user);
  const [timeZone, setTimeZone] = React.useState(moment.tz.guess());

  //   console.log({ timeZone, momentTimezone: moment().format("Z"), user });

  //TODO: THIS IS FOR TESTING
  // React.useEffect(() => {
  //   if (user) {
  //     setTimeZone("America/Los_Angeles");
  //   }
  // }, [user]);

  // sets global timezone when the timeZone state changes
  // React.useEffect(() => {
  //   moment.tz.setDefault(timeZone);
  //   const localeStr = moment().toLocaleString();
  //   const normalStr = moment().toString();
  //   console.log({ normalStr, localeStr });
  // }, [timeZone]);

  const contextValues = React.useMemo(
    () => ({
      timeZone,
      setTimeZone,
    }),
    [timeZone, setTimeZone]
  );

  return (
    <TimezoneContext.Provider value={contextValues} {...props}>
      {children}
    </TimezoneContext.Provider>
  );
};

export function useTimezoneContext() {
  const context = React.useContext(TimezoneContext);

  return context;
}

export default compose(inject("UserStore"), observer)(TimezoneContextProvider);
