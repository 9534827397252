import { withAuthorization } from "../../Auth";

import { Container as MainContainer } from "../../../utils/styled";

import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { ScreenSize } from "../../../constants/ScreenSizeEnum";
import { Outlet, useLocation } from "react-router";
import { MainContentContainer } from "./styled";
import { DesktopSidebar, MobileSidebar } from "./Sidebar";
import useSidebarData from "./Sidebar/hooks/useSidebarData";
import { isConcertInvitationPendingStudentAction } from "src/utils/helpers";

const Container = ({ sidebarContentType, user }) => {
  const location = useLocation();

  const {
    teacherSubmittedAbsences,
    pendingMakeupRequests,
    concertInvitations,
    applications,
    feedbacks,
  } = useSidebarData({
    user,
  });
  const submittedTAsLength = teacherSubmittedAbsences?.length;
  const pendingMakeupRequestsLength = pendingMakeupRequests?.length;
  const pendingConcertInvitationsLength = concertInvitations.filter(
    (invitation) =>
      isConcertInvitationPendingStudentAction(invitation, user.uid)
  ).length;
  const applicationsLength = applications?.length;
  const feedbacksLength = feedbacks?.length;

  const { width } = useWindowDimensions();

  const isDesktopScreen = width > ScreenSize.MD;

  return (
    <MainContainer>
      <div className="d-flex" style={{ flex: 1 }}>
        {isDesktopScreen ? (
          <DesktopSidebar
            user={user}
            location={location}
            submittedTAsLength={submittedTAsLength}
            pendingMakeupRequestsLength={pendingMakeupRequestsLength}
            pendingConcertInvitationsLength={pendingConcertInvitationsLength}
            applicationsLength={applicationsLength}
            feedbacksLength={feedbacksLength}
            sidebarContentType={sidebarContentType}
          />
        ) : (
          <MobileSidebar
            user={user}
            location={location}
            submittedTAsLength={submittedTAsLength}
            applicationsLength={applicationsLength}
            feedbacksLength={feedbacksLength}
            pendingMakeupRequestsLength={pendingMakeupRequestsLength}
            pendingConcertInvitationsLength={pendingConcertInvitationsLength}
            sidebarContentType={sidebarContentType}
          />
        )}
        <MainContentContainer>
          <Outlet />
        </MainContentContainer>
      </div>
    </MainContainer>
  );
};

export default withAuthorization(Container);
