const subscribeScript = (script1, script2) => {
  script1.id = "signupScript";
  script1.src =
    "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
  script1.async = true;
  script1.defer = true;

  script2.innerHTML = `var _ctct_m = "cea6e621975c1808a73520a36ffe7de6"`;

  document.body.appendChild(script1);
  document.body.appendChild(script2);

  const interval = setInterval(() => {
    const elements = document.querySelectorAll(".ctct-form-element");

    if (elements.length >= 2) {
      const footerEl = elements[0];
      const modalEl = elements[1];

      modalEl.id = "email_address_1";
      modalEl.placeholder = "EMAIL";

      modalEl.addEventListener("input", () => {
        footerEl.value = modalEl.value;
      });

      clearInterval(interval);
    }
  }, 1000);
};

export default subscribeScript;
