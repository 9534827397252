import React, { useContext } from "react";
import LibraryStudentsList from "./students-list/LibraryStudentsList";
import AttachmentsList from "./attachments-list/AttachmentsList";
import {
  ActiveTabContext,
  StudentsSelectContext,
} from "../common/libraryContext";
import StudentsUploads from "./attachments-list/StudentsUploads";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import StudentSelectField from "../common/mobile/StudentSelectField";

const TabContent = ({ user }) => {
  const { activeTab, isTeacher, isAdmin } = useContext(ActiveTabContext);
  // const { select, selected, multiSelect } = useContext(StudentsSelectContext);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.XL;

  const displayDesktopStudentsList = isDesktopScreen && (isTeacher || isAdmin);
  const displayMobileStudentsList = !isDesktopScreen && (isTeacher || isAdmin);

  return (
    <div>
      {/* {displayMobileStudentsList ? (
        <div className="mb-4">
          <StudentSelectField />
        </div>
      ) : null} */}
      <div className={"d-flex"}>
        {/* {displayDesktopStudentsList ? <LibraryStudentsList /> : null} */}
        {activeTab === "studentsUploads" ? (
          <StudentsUploads />
        ) : (
          <AttachmentsList user={user} />
        )}
      </div>
    </div>
  );
};
export default TabContent;
