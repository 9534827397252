import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import InfoDropdown from "src/components/Dashboard/common/components/InfoDropdown";
import {
  groupClassPaymentOptionsMap,
  groupClassPaymentStudentTypes,
  groupClassScheduledPaymentIntervals,
  paymentOptionMethods,
  paymentOptionTypes,
  scheduledPaymentTimesToRun,
} from "src/constants/groupClassPaymentsConstants";
import {
  getGroupClassPaymentOptionsTotal,
  getGroupClassScheduledPaymentOptionTotal,
} from "src/utils/helpers";

const planNamesMap = [
  {
    name: "New Students Plan",
    value: groupClassPaymentStudentTypes.NEW_STUDENT,
  },
  {
    name: "Current Students Plan",
    value: groupClassPaymentStudentTypes.CURRENT_STUDENT,
  },
];
const intervalsMap = [
  {
    value: groupClassScheduledPaymentIntervals.WEEK,
    name: "Week",
  },
  {
    value: groupClassScheduledPaymentIntervals.MONTH,
    name: "Month",
  },
  {
    value: groupClassScheduledPaymentIntervals.YEAR,
    name: "Year",
  },
];
const ScheduledPaymentButton = ({ paymentOption, onSubscriptionSuccess }) => {
  const {
    paymentType,
    optionPayments,
    forStudentType,
    paypalPlanId,
    interval,
    intervalCount,
    timesToRun,
  } = paymentOption;

  const setupPaymentOptions = useMemo(() => {
    const setupOptions = optionPayments?.filter(({ type }) => {
      const map = Object.values(groupClassPaymentOptionsMap).find(
        (option) => type === option.value
      );
      const isSetup = map.method === paymentOptionMethods.SETUP;
      return isSetup;
    });
    return setupOptions;
  }, [paymentOption]);
  const setupTotal = getGroupClassPaymentOptionsTotal(setupPaymentOptions);

  const renderSetupPaymentInfoBody = () => {
    return (
      <div>
        {setupPaymentOptions?.map((option) => {
          const { type, amount, id } = option;
          const optionObj = Object.values(groupClassPaymentOptionsMap).find(
            ({ value }) => value === type
          );
          const optionName = optionObj?.title1;

          return (
            <div className="mb-3" key={id}>
              <span className="fw-bold">{optionName}</span>
              <span className="fw-normal"> - ${amount}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const scheduledPaymentOptions = useMemo(() => {
    const scheduledOptions = optionPayments?.filter(({ type }) => {
      const map = Object.values(groupClassPaymentOptionsMap).find(
        (option) => type === option.value
      );
      const isScheduled = map.method === paymentOptionMethods.SCHEDULED;
      return isScheduled;
    });
    return scheduledOptions;
  }, paymentOption);
  const scheduledTotal = getGroupClassPaymentOptionsTotal(
    scheduledPaymentOptions
  );
  const renderScheduledPaymentInfoBody = () => {
    return (
      <div>
        {scheduledPaymentOptions?.map((option) => {
          const { type, amount, id } = option;
          const optionObj = Object.values(groupClassPaymentOptionsMap).find(
            ({ value }) => value === type
          );
          const optionName = optionObj?.title1;

          return (
            <div className="mb-3" key={id}>
              <span className="fw-bold">{optionName}</span>
              <span className="fw-normal"> - ${amount}</span>
            </div>
          );
        })}
      </div>
    );
  };

  async function createSubscription(data, actions) {
    // console.log("create SUB", { data, actions });

    return actions.subscription.create({
      plan_id: paypalPlanId, // Creates the subscription
    });
  }

  async function onApprove(data, actions) {
    const { orderID, subscriptionID } = data;
    // console.log("approve SUB", { data, actions });

    await onSubscriptionSuccess(subscriptionID);
    toast.success("You have subscribed to the plan successfully");
  }

  const planName = planNamesMap.find(
    ({ value }) => value === forStudentType
  )?.name;

  const intervalName = intervalsMap.find(
    ({ value }) => value === interval
  )?.name;

  const runType =
    timesToRun === scheduledPaymentTimesToRun.CONTINOUS
      ? "Continous"
      : `${timesToRun.amount} Times`;

  return (
    <div className="mt-4">
      <h4>Subscription Details:</h4>
      <div>
        <p>
          Plan Type: <span>{planName}</span>
        </p>
        <p>
          Interval: <span>{intervalName}</span>
        </p>
        <p>
          Interval Count: <span>{intervalCount}</span>
        </p>
        <p>
          Run Type: <span>{runType}</span>
        </p>
        <p>
          Setup Amount:
          <span>
            ${setupTotal}
            <InfoDropdown renderBodyContent={renderSetupPaymentInfoBody} />
          </span>
        </p>
        <p>
          Installment Amount:{" "}
          <span>
            ${scheduledTotal}
            <InfoDropdown renderBodyContent={renderScheduledPaymentInfoBody} />
          </span>
        </p>
      </div>
      <PayPalButtons
        // style={{ layout: "horizontal" }}
        onApprove={onApprove}
        onCancel={(data, actions) => {
          // console.log("CANCELED", { data, actions });
        }}
        onError={(err) => {
          // console.log("ERROR", { err });
        }}
        onClick={(data, actions) => {
          // console.log("CLICKED", { data, actions });
        }}
        createSubscription={createSubscription}
      />
    </div>
  );
};

export default ScheduledPaymentButton;
