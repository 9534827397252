import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { SubmitSpinner } from "../../../../../../../../../common";
import { FirebaseContext } from "../../../../../../../../../Firebase";
import { PrimaryButton } from "../../../../../../../../../../utils/shared/styled";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { setDoc, updateDoc, Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { useRegistrationContext } from "../../../../../../../../../../contexts/RegisterationContext";
import { UserRole } from "../../../../../../../../../../constants/UserRoleEnum";
import {
  getRandomColor,
  getStartAndEndDatesFromTimeInputs,
  updatedMomentNow,
} from "../../../../../../../../../../utils/helpers";
import { useInitialPageDataContext } from "../../../../../../../../../../contexts/InitialPageDataContext";
import {
  isCompleteInstrumentsInfo,
  isCompleteTeacherDays,
} from "../../../../../../helperFns";
import InstrumentsInfo from "./InstrumentsInfo";
import TeacherDays from "./TeacherDays";
import { NoteSection, Textarea } from "./styled";
import { isValidStartAndEndTime } from "./helperFns";
import moment from "moment";
import { workConditions } from "src/constants/paymentsEnum";
import { v4 as uuidv4 } from "uuid";

const initialValues = {};
const FormInfo = ({ modalData, refreshData }) => {
  const firebase = useContext(FirebaseContext);
  const {
    instruments,
    locations,
    programs,
    loading: loadingInitialData,
  } = useInitialPageDataContext();

  const [instrumentsInfo, setInstrumentsInfo] = useState([
    {
      instrument: "",
      program: "",
    },
  ]);
  const [note, setNote] = useState("");
  const [color, setColor] = useState(getRandomColor());
  const { step, nextStep, formValues, setFormValues } =
    useRegistrationContext();
  const [teacherDays, setTeacherDays] = useState([
    {
      id: uuidv4(),
      day: "",
      start: "",
      end: "",
      location: "",
      isVirtualOnly: false,
    },
  ]);

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const { firstStepValues, secondStepValues } = formValues;
          const thirdStepValues = {
            instrumentsInfo,
            note,
            color,
          };
          const userValues = {
            createdAt: Timestamp.now(),
            role: UserRole.TEACHER,
            workCondition: workConditions.partTime.value,
            ...firstStepValues,
            ...secondStepValues,
            ...thirdStepValues,
          };
          if (
            isCompleteTeacherDays(teacherDays, ["isVirtualOnly"]) &&
            isCompleteInstrumentsInfo(instrumentsInfo)
          ) {
            const isStartTimeBeforeEndTime =
              isValidStartAndEndTime(teacherDays);
            // Create the teacher
            const auth = getAuth(firebase.secondaryApp);

            const lowerCaseEmail = userValues.primaryEmail.toLowerCase();
            const authUser = await createUserWithEmailAndPassword(
              auth,
              lowerCaseEmail,
              process.env.REACT_APP_USERS_GLOBAL_PASSWORD
            );
            if (authUser.user.uid) {
              const usersDocResponse = await setDoc(
                firebase.user(authUser.user.uid),
                { ...userValues, primaryEmail: lowerCaseEmail }
              );

              const parsedTeacherDays = teacherDays.map((day) => {
                const { startDate, endDate } =
                  getStartAndEndDatesFromTimeInputs(
                    updatedMomentNow().day(day.day),
                    day.start,
                    day.end
                  );
                return {
                  id: day.id,
                  startDate,
                  endDate,
                  location: day.location,
                  isVirtualOnly: day.isVirtualOnly || false,
                  disabledForRoles: day.disabledForRoles || [],
                };
              });
              // Add the teacher's available days
              await setDoc(firebase.userAvailableDays(authUser.user.uid), {
                userRole: UserRole.TEACHER,
                availableDays: parsedTeacherDays,
              });

              toast.success("Teacher Created Successfully");
            } else {
              toast.error("No user id was found");
            }
            refreshData();
            modalData.closeModal();
          } else {
            toast.warn("Please fill all data ");
          }
        } catch (error) {
          toast.error(error.message);
          console.log("saving teacher-error", error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ touched, errors, isSubmitting, values }) => (
        <Form className="w-75 ps-3">
          {/* Selected Instruments Section */}
          <InstrumentsInfo
            instrumentsInfo={instrumentsInfo}
            setInstrumentsInfo={setInstrumentsInfo}
            instruments={instruments}
            programs={programs}
          />

          {/* Teacher Days Section */}
          <TeacherDays
            teacherDays={teacherDays}
            setTeacherDays={setTeacherDays}
            color={color}
            setColor={setColor}
            locations={locations}
          />

          {/* Note Section */}
          <NoteSection>
            <h2 className="mb-3">Note</h2>
            <Textarea
              name="note"
              value={note}
              onChange={handleNoteChange}
              height={"100px"}
            />
          </NoteSection>
          <div className="text-end">
            <PrimaryButton type="submit" height="45px" padding="0">
              {isSubmitting ? (
                <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
              ) : (
                "Create Teacher"
              )}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormInfo;
