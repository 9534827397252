export const schoolPolicies = {
  absencePolicy: {
    key: "absence_policy",
    title: "Absence Policy",
  },
  makeupPolicy: {
    key: "makeup_policy",
    title: "Make Up Policy",
  },
  packageAbsencePolicy: {
    key: "package_absence_policy",
    title: "Package Lesson Absence Policy",
  },
};
