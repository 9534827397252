import { useMemo, useState } from "react";
import moment from "moment";
import RouteContent from "../../../common/RouteContent";
import CustomCalendarComponent from "../../../common/CustomCalendar";
import TeachersList from "./components/TeachersList";
import useScheduleData from "./hooks/useScheduleData";
import { getSelectedTeachersResourceMap } from "./helperFns";
import {
  mapTLToEvent,
  excludeAbsenceFromPLs,
  mapSchoolBreakToScheduleEvent,
  excludeSchoolBreaksFromRecurringEvents,
  mapMakeupLessonToEvent,
  mapAppointmentToEvent,
  joinSimilarEvents,
  shareSchoolBreaksAcrossResources,
  mapCalendarLabelToEvent,
  mapBreakToEvent,
  excludeTeacherBreaksFromAbsences,
  filterWithdrawalFromPLs,
  generateRecurringEvents,
  mapPackagePrivateLessonToEvents,
  eventPropGetter,
  filterSummerBreaksFromPLs,
  isEventWithinDateMonth,
  mapGroupClassToEvents,
} from "../helperFns";
import {
  eventsMap,
  isSingleTimeCalendarLabelEvent,
  isOneTimeBreakEvent,
  isRecurringBreakEvent,
  isRecurringCalendarLabelEvent,
  isPackagePrivateLesson,
} from "../../../../constants/eventsEnum";
import TeacherCard from "../components/TeacherCard";
import useModal from "../hooks/useModal";
import EventDetailsModal from "../components/EventDetailsModal";
import { UserRole } from "../../../../constants/UserRoleEnum";
import useCreationModal from "./hooks/useCreationModal";
import { creationModalTypes } from "./creationModalTypes";
import NewAppointmentModal from "./modals/NewAppointmentModal";
import { CustomSelectField } from "../styled";
import {
  checkIfCanceledRecurringActivity,
  injectUserStore,
  updatedMomentNow,
} from "src/utils/helpers";
import { modalDetailsEventsCodes } from "../constants";
import { isCanceledTrialLesson } from "src/constants/trialLessonStatuses";
import TeacherBreakDeleteOptionsModal from "./modals/TeacherBreakDeleteOptionsModal";
import CustomEventComponent from "../components/CustomEventComponent";
import CustomTooltip from "../../common/components/CustomTooltip";
import CustomMiniCalendar from "../../common/components/CustomMiniCalendar";
import { groupClassesListViews } from "../../GroupClass/constants";

const AdminSchedule = ({ UserStore }) => {
  const [currentDate, setCurrentDate] = useState(updatedMomentNow().toDate());

  const [miniCalendarKey, setMiniCalendarKey] = useState(0);

  const [currentView, setCurrentView] = useState("week");
  const onViewChange = (newView) => {
    setCurrentView(newView);
  };

  // state for when clicking on an empty slot on the calendar (week and day views only)
  const [selectedSlotInfo, setSelectedSlotInfo] = useState({
    start: "",
    end: "",
    resource: null,
  });

  // schedule data
  const {
    initialData,
    loadingInitialData,
    selectedTeachers,

    loadingTeachersLessons,
    selectedEvent,
    handleSelectedTeachersChange,
    handleClearSelectedTeachers,
    setSelectedEvent,
    refreshSelectedTeachersEvents,
    selectedLocationId,
    handleSelectedLocationIdChange,
    combinedSchoolBreaks,

    selectedTeachersData,
  } = useScheduleData();
  const {
    selectedTeachersPLs,
    selectedTeachersAbsences,
    selectedTeachersTLs,
    selectedTeachersMakeupLessons,
    selectedTeachersAppointments,
    selectedTeachersLabels,
    selectedTeachersBreaks,
    selectedTeachersStudioUsages,
    selectedTeachersGroupClasses,
  } = selectedTeachersData;

  // Gets resource map for grouping events by teacher in day view
  const resourceMap = useMemo(
    () =>
      getSelectedTeachersResourceMap(selectedTeachers, initialData.teachers),
    [selectedTeachers]
  );

  // modal data for the event details modal
  const eventDetailsModalData = useModal();

  // teacher breaks delete options modal
  const breakDeleteModalData = useModal();

  // modal data for appointments creation / event modifications
  const creationModalData = useCreationModal();

  const preparedPLsRecurringEvents = useMemo(() => {
    const regularPrivateLessonsRecurringEvents = generateRecurringEvents({
      docs: selectedTeachersPLs.filter(
        ({ type }) => !isPackagePrivateLesson(type)
      ),
      startDateAccessor: "startDate",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "teacherId",
      eventCode: eventsMap.privateLesson.code,
      instruments: initialData.instruments,
      resourceIds: Object.values(selectedTeachers),
    }).filter(
      ({ currentTimeline }) =>
        selectedLocationId === currentTimeline?.locationId
    );

    const packagePrivateLessonsEvents = selectedTeachersPLs
      .filter(({ type }) => isPackagePrivateLesson(type))
      .map((lesson) =>
        mapPackagePrivateLessonToEvents({
          packageLesson: lesson,
          selectedTeachers: Object.values(selectedTeachers),
          role: UserRole.SUPER_ADMIN,
          instruments: initialData.instruments,
        })
      )
      .flat()
      .filter(({ locationId }) => selectedLocationId === locationId);

    const combinedPrivateLessonsEvents = [
      ...regularPrivateLessonsRecurringEvents,
      ...packagePrivateLessonsEvents,
    ];

    const filteredAbsencesFromPLs = excludeAbsenceFromPLs(
      combinedPrivateLessonsEvents,
      selectedTeachersAbsences
    );
    // remove the event starting from the withdrawal date + change color and title of last lesson date
    const filteredWithdrawalsFromPLs = filterWithdrawalFromPLs(
      filteredAbsencesFromPLs
    );

    const filteredPLsRecurringEvents = filterSummerBreaksFromPLs(
      filteredWithdrawalsFromPLs
    );

    const PLsRecurringEventsWithoutSchoolBreaks =
      excludeSchoolBreaksFromRecurringEvents(
        filteredPLsRecurringEvents,
        combinedSchoolBreaks
      );

    return PLsRecurringEventsWithoutSchoolBreaks;
  }, [
    selectedTeachersAbsences,
    selectedTeachersPLs,
    selectedTeachers,
    selectedLocationId,
    initialData,
    combinedSchoolBreaks,
    currentDate,
  ]);
  const groupClassesEvents = useMemo(() => {
    const events = selectedTeachersGroupClasses
      .map((groupClass) =>
        mapGroupClassToEvents({
          groupClass,
          selectedTeachers: Object.values(selectedTeachers),
          role: UserRole.SUPER_ADMIN,
        })
      )
      .flat();

    return events;
  }, [selectedTeachersGroupClasses, selectedTeachers]);

  const trialLessonsEvents = useMemo(
    () =>
      selectedTeachersTLs
        .filter((tl) => {
          const isWithinMonth = isEventWithinDateMonth(
            tl.date,
            moment(tl.date).add(tl.lessonLength),
            currentDate
          );
          const isCanceled = isCanceledTrialLesson(tl.status);
          return isWithinMonth && !isCanceled;
        })
        .map((lesson) =>
          mapTLToEvent(lesson, UserRole.SUPER_ADMIN, initialData.instruments)
        ),
    [selectedTeachersTLs, currentDate]
  );
  const makeupLessonsEvents = useMemo(
    () =>
      selectedTeachersMakeupLessons
        .filter((lesson) => {
          const isWithinMonth = isEventWithinDateMonth(
            lesson.date?.startDate,
            lesson.date?.endDate,
            currentDate
          );
          return isWithinMonth;
        })
        .map((lesson) => mapMakeupLessonToEvent(lesson)),
    [selectedTeachersMakeupLessons, currentDate]
  );
  const appointmentsEvents = useMemo(
    () =>
      selectedTeachersAppointments
        .filter((appointment) => {
          const isWithinMonth = isEventWithinDateMonth(
            appointment.startDate,
            appointment.endDate,
            currentDate
          );
          return isWithinMonth;
        })
        .map((appointment) => mapAppointmentToEvent(appointment)),
    [selectedTeachersAppointments, currentDate]
  );

  const totalSchoolBreaksEventsWithResources = useMemo(() => {
    const schoolBreaksCalendarEvents = combinedSchoolBreaks?.map(
      (schoolBreak) => mapSchoolBreakToScheduleEvent(schoolBreak)
    );

    const withResources = shareSchoolBreaksAcrossResources(
      resourceMap,
      schoolBreaksCalendarEvents,
      currentView
    );
    return withResources;
  }, [combinedSchoolBreaks, resourceMap, currentView]);

  const allCalendarLabelEvents = useMemo(() => {
    const oneTimeCalendarLabelsEvents = selectedTeachersLabels
      .filter(({ type }) => isSingleTimeCalendarLabelEvent(type))
      .map((label) => mapCalendarLabelToEvent(label));

    //

    const recurringLabels = selectedTeachersLabels.filter(({ type }) =>
      isRecurringCalendarLabelEvent(type)
    );

    const recurringEvents = generateRecurringEvents({
      docs: recurringLabels,
      startDateAccessor: "date",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "userId",
      eventCode: eventsMap.recurringCalendarLabel.code,
    });

    // filter out canceled breaks
    const filteredRecurringLabels = recurringEvents.filter(
      ({ start, canceledAt, canceledDates }) => {
        const isCanceled = checkIfCanceledRecurringActivity({
          occuranceDate: start,
          canceledAt,
          canceledDates,
        });
        return !isCanceled;
      }
    );

    return [...oneTimeCalendarLabelsEvents, ...filteredRecurringLabels];
  }, [selectedTeachersLabels, currentDate]);

  const allBreaksWithoutSchoolBreaks = useMemo(() => {
    //
    const oneTimeBreaksEvents = selectedTeachersBreaks
      .filter(({ type, date, duration }) => {
        const isRecurring = isRecurringBreakEvent(type);
        const isWithinMonth = isRecurring
          ? false
          : isEventWithinDateMonth(
              date,
              moment(date).add(duration),
              currentDate
            );

        return isWithinMonth && !isRecurring;
      })
      .map((userBreak) => mapBreakToEvent(userBreak));

    const recurringBreaks = selectedTeachersBreaks.filter(({ type }) =>
      isRecurringBreakEvent(type)
    );

    const recurringEvents = generateRecurringEvents({
      docs: recurringBreaks,
      startDateAccessor: "date",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "userId",
      eventCode: eventsMap.recurringBreak.code,
    });
    // filter out canceled breaks
    const filteredRecurringBreaks = recurringEvents.filter(
      ({ start, canceledAt, canceledDates }) => {
        const isCanceled = checkIfCanceledRecurringActivity({
          occuranceDate: start,
          canceledAt,
          canceledDates,
        });
        return !isCanceled;
      }
    );

    const allBreaksEventsWithoutAbsences = excludeTeacherBreaksFromAbsences(
      [...oneTimeBreaksEvents, ...filteredRecurringBreaks],
      selectedTeachersAbsences
    );
    return excludeSchoolBreaksFromRecurringEvents(
      allBreaksEventsWithoutAbsences,
      combinedSchoolBreaks
    );
  }, [
    selectedTeachersBreaks,
    currentDate,
    selectedTeachersAbsences,
    combinedSchoolBreaks,
  ]);

  const selectedEvents = useMemo(
    () =>
      joinSimilarEvents([
        ...preparedPLsRecurringEvents,
        ...trialLessonsEvents,
        ...makeupLessonsEvents,
        ...appointmentsEvents,
        ...totalSchoolBreaksEventsWithResources,
        ...(!["agenda"].includes(currentView) ? allCalendarLabelEvents : []),
        ...allBreaksWithoutSchoolBreaks,
        ...groupClassesEvents
          .filter(
            (event) => event?.status === groupClassesListViews.active.value
          )
          .map((event) => ({
            ...event,
            title: !event?.isCanceled
              ? event?.title
              : `${event?.title} (Canceled)`,
          })),
      ]),
    [
      preparedPLsRecurringEvents,
      trialLessonsEvents,
      makeupLessonsEvents,
      appointmentsEvents,
      totalSchoolBreaksEventsWithResources,
      allCalendarLabelEvents,
      allBreaksWithoutSchoolBreaks,
      groupClassesEvents,
    ]
  );

  const { components } = useMemo(
    () => ({
      components: {
        resourceHeader: ({ label, resource, index }) => {
          return (
            <TeacherCard
              teacher={resource}
              currentDate={currentDate}
              studios={initialData?.studios}
              selectedTeachersStudioUsages={selectedTeachersStudioUsages}
            />
          );
        },
        event: ({ event }) => (
          <CustomEventComponent
            groupClassesAbsences={initialData.groupClassesAbsences}
            event={event}
            style={{ backgroundColor: "yellow" }}
          />
        ),
      },
    }),
    [initialData, currentDate]
  );
  const onSelectSlot = (slotInfo) => {
    const { action, start, end, resourceId } = slotInfo;
    if (
      ["day", "week"].includes(currentView) &&
      ["click", "select"].includes(action) &&
      resourceId
    ) {
      const resource = resourceMap.find(
        (resource) => resource.resourceId === resourceId
      );
      setSelectedSlotInfo({
        action,
        start,
        end,
        resource,
      });
      creationModalData.openModalWithName(
        creationModalTypes.newAppointment.name
      );
    }
  };

  const showTimeIndicatorDot = useMemo(() => {
    const show = !["agenda", "day"].includes(currentView);

    return show;
  }, [currentView]);
  return (
    <RouteContent title={"My Schedule"}>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div>
          <CustomSelectField
            width="300px"
            value={selectedLocationId}
            onChange={(e) => handleSelectedLocationIdChange(e.target.value)}
          >
            <option value="" disabled>
              Select A Location
            </option>

            {Object.values(initialData.locations)?.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </CustomSelectField>
        </div>
      </div>

      <div
        style={{ minHeight: 700 }}
        className="d-flex flex-grow-1 flex-shrink-1 mt-5"
      >
        <div className="d-flex flex-column col-xl-3 col-sm-4 me-3 vh-100 overflow-auto">
          <div className="mb-4">
            <CustomMiniCalendar
              calendarKey={miniCalendarKey}
              onDateValueChange={setCurrentDate}
              dateValue={currentDate}
            />
          </div>
          <TeachersList
            loadingTeachersLessons={loadingTeachersLessons}
            teachers={initialData?.teachers}
            selectedTeachers={selectedTeachers}
            onSelectTeacher={handleSelectedTeachersChange}
            onClearTeachers={handleClearSelectedTeachers}
            isLoading={loadingInitialData}
            selectedLocationId={selectedLocationId}
          />
        </div>
        <div className="col-xl-9 col-sm-8 vh-100">
          <CustomCalendarComponent
            showCurrentTimeIndicatorDot={showTimeIndicatorDot}
            defaultView={currentView}
            events={selectedEvents}
            // On clicking on an event in the calendar
            onSelectEvent={(event) => {
              if (
                isRecurringBreakEvent(event.eventCode) ||
                isOneTimeBreakEvent(event.eventCode)
              ) {
                breakDeleteModalData.openModal();
              } else if (modalDetailsEventsCodes.includes(event.eventCode)) {
                eventDetailsModalData.openModal();
              }
              setSelectedEvent(event);
            }}
            resourceIdAccessor="resourceId"
            resources={resourceMap?.length ? resourceMap : null}
            resourceTitleAccessor="resourceTitle"
            eventPropGetter={(event, start, end, isSelected) =>
              eventPropGetter(
                event,
                start,
                end,
                isSelected,
                initialData.groupClassesAbsences
              )
            }
            popup
            components={components}
            date={currentDate}
            onNavigate={(newDate) => {
              setCurrentDate(newDate);
              setMiniCalendarKey((oldVal) => oldVal + 1);
            }}
            selectable={true}
            onSelectSlot={onSelectSlot}
            onView={onViewChange}
          />
          <CustomTooltip
            style={{ zIndex: 10 }}
            anchorSelect=".calendar-event-tooltip"
          />
        </div>
      </div>
      {eventDetailsModalData.isModalOpen &&
        modalDetailsEventsCodes.includes(selectedEvent.eventCode) && (
          <EventDetailsModal
            event={selectedEvent}
            modalData={eventDetailsModalData}
            initialData={initialData}
            refreshData={refreshSelectedTeachersEvents}
            userRole={UserRole.ADMIN}
            studioUsages={selectedTeachersStudioUsages}
          />
        )}
      {breakDeleteModalData.isModalOpen && selectedEvent && (
        <TeacherBreakDeleteOptionsModal
          modalData={breakDeleteModalData}
          event={selectedEvent}
          refreshData={refreshSelectedTeachersEvents}
        />
      )}
      {creationModalData.isModalNameOpen(
        creationModalTypes.newAppointment.name
      ) && (
        <NewAppointmentModal
          selectedSlotInfo={selectedSlotInfo}
          modalData={creationModalData}
          refreshSelectedTeachersEvents={refreshSelectedTeachersEvents}
          locations={Object.values(initialData.locations)}
        />
      )}
    </RouteContent>
  );
};
export default injectUserStore(AdminSchedule);
