import React, { useState, useContext, useEffect, useRef } from "react";
import UnknownProfilePhoto from "../../../../../../../../../assets/images/default-profile.jpg";
import { PrimaryButton } from "../../../../../../../../../utils/shared/styled";
import { FirebaseContext } from "../../../../../../../../Firebase";
import styled from "styled-components";
import { useRegistrationContext } from "../../../../../../../../../contexts/RegisterationContext";

const ImageWrapper = styled.div`
  text-align: center;
`;
const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;
const TeacherName = styled.p`
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 10px;
`;
const UserProfile = () => {
  const { formValues } = useRegistrationContext();
  const { firstStepValues, secondStepValues } = formValues;

  return (
    <div className="w-25">
      <ImageWrapper>
        <Image
          width={150}
          height={150}
          src={secondStepValues?.imageUrl || UnknownProfilePhoto}
        />
      </ImageWrapper>
      <TeacherName>{firstStepValues?.fullName || "N/A"}</TeacherName>
    </div>
  );
};

export default UserProfile;
