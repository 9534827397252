import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { pageTabs } from "../constants";
import { toast } from "react-toastify";
import { isStudent } from "src/constants/UserRoleEnum";
import {
  isPendingMakeupRequest,
  isResolvedMakeupRequest,
} from "src/constants/makeupRequestEnum";

const useMakeupRequests = () => {
  const {
    getTeachers,
    getTeacherAbsences,
    getInstruments,
    getLocations,
    getPrivateAndTrialStudents,
    getAdminCredentialsUsers,
    getUsers,
    getMakeupRequests,
    getPrivateLessonsByIds,
  } = useFirebaseFns();
  // the page current view
  const [currentView, setCurrentView] = useState(pageTabs.PENDING);

  const [initialData, setInitialData] = useState({
    users: [],
    makeupRequests: [],
    instruments: [],
    // startDateFilter: "",
    // endDateFilter: "",
    // locations: [],
    // combinedStudents: [],
    // adminCredentialsUsers: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentMakeupRequestId, setCurrentMakeupRequestId] = useState("");

  // search term from the search bar
  const [searchTerm, setSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const getSearchTermStudentsIds = useCallback(() => {
    if (!searchTerm) {
      return initialData.users
        .filter(({ role }) => isStudent(role))
        .map(({ id }) => id);
    }

    const filteredStudents = initialData.users.filter(({ fullName }) =>
      fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredStudentsIds = filteredStudents?.map(({ id }) => id);
    return filteredStudentsIds;
  }, [searchTerm, initialData]);

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [makeupRequests, users, instruments] = await Promise.all([
          getMakeupRequests(),
          getUsers(),
          getInstruments(),
        ]);
        const makeupRequestsPLIds = [
          ...new Set(makeupRequests.map(({ forLessonId }) => forLessonId)),
        ];
        const requestsPLs = makeupRequestsPLIds.length
          ? await getPrivateLessonsByIds(makeupRequestsPLIds)
          : [];

        const makeupRequestsWithData = makeupRequests.map((makeupRequest) => {
          const reqLesson = requestsPLs.find(
            ({ id }) => id === makeupRequest.forLessonId
          );
          const makeupRequestWithPl = {
            ...makeupRequest,
            privateLesson: reqLesson,
          };
          return makeupRequestWithPl;
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          makeupRequests: makeupRequestsWithData,
          users,
          instruments,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  const pendingMakeupRequests = useMemo(() => {
    const searchTermStudentsIds = getSearchTermStudentsIds();
    return initialData.makeupRequests.filter(
      ({ status, privateLesson }) =>
        isPendingMakeupRequest(status) &&
        searchTermStudentsIds?.includes(privateLesson?.studentId)
    );
  }, [initialData, getSearchTermStudentsIds]);

  const resolvedMakeupRequests = useMemo(() => {
    const searchTermStudentsIds = getSearchTermStudentsIds();
    return initialData.makeupRequests.filter(
      ({ status, privateLesson }) =>
        isResolvedMakeupRequest(status) &&
        searchTermStudentsIds?.includes(privateLesson?.studentId)
    );
  }, [initialData, getSearchTermStudentsIds]);

  // current absence obj
  const currentMakeupRequest = useMemo(() => {
    return initialData.makeupRequests.find(
      ({ id }) => id === currentMakeupRequestId
    );
  }, [currentMakeupRequestId, initialData]);

  return {
    currentView,
    setCurrentView,
    handleSearchTermChange,
    initialData,
    loadingInitialData,
    setCurrentMakeupRequestId,
    currentMakeupRequest,
    refreshData,
    pendingMakeupRequests,
    resolvedMakeupRequests,
  };
};

export default useMakeupRequests;
