import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const CustomRichTextEditor = ({ editorValue, onEditorValueChange }) => {
  const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  return (
    <div>
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        // initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            // "image",
            // "charmap",
            "preview",
            // "anchor",
            // "searchreplace",
            // "visualblocks",
            "code",
            "fullscreen",
            // "insertdatetime",
            // "media",
            // "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "code link | bold italic underline |forecolor backcolor | alignleft aligncenter " +
            "alignright | bullist numlist outdent indent | ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        value={editorValue}
        onEditorChange={(value) => onEditorValueChange(value)}
      />
    </div>
  );
};

export default CustomRichTextEditor;
