import React from "react";
import {
  CustomSearchInput,
  SearchIcon,
  StudentsListContainer,
} from "../../styled";
import { FormGroup } from "reactstrap";
import StudentItem from "./StudentItem";

const StudentsSelectionSection = ({
  selectedStudents,
  isStudentSelected,
  handleSelectedStudentsChange,
  filteredStudentsList,
  studentSearchTerm,
  setStudentSearchTerm,
  privateLessons,
  instruments,
  programType,
}) => {
  return (
    <div>
      <div>
        <FormGroup className="searchContainer">
          <CustomSearchInput
            type="search"
            style={{ padding: "10px 10px 10px 25px" }}
            placeholder="Search by Student Name"
            className="searchInput"
            value={studentSearchTerm}
            onChange={(e) => setStudentSearchTerm(e.target.value)}
          />

          <SearchIcon />
        </FormGroup>
      </div>
      <div className="mt-3 ">
        <StudentsListContainer>
          {filteredStudentsList.map((student) => {
            return (
              <StudentItem
                isStudentSelected={isStudentSelected}
                key={student.id}
                student={student}
                handleSelectedStudentsChange={handleSelectedStudentsChange}
                privateLessons={privateLessons}
                instruments={instruments}
                programType={programType}
              />
            );
          })}
        </StudentsListContainer>
      </div>
    </div>
  );
};

export default StudentsSelectionSection;
