import React from "react";
import useModal from "src/hooks/useModal";
import moment from "moment";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import SinglePackageLessonCreationModal from "../../../../common/components/SinglePackageLessonCreationModal";
import {
  getLessonPackageItemsInfo,
  getPackageLessonSetExpirationDate,
} from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import { InfoText1, InfoText2 } from "../styled";

const PrivateLessonInfo = ({ privateLesson }) => {
  const addNewSetItemModalData = useModal();

  const isPackageLsn =
    privateLesson && isPackagePrivateLesson(privateLesson.type);

  let currentSet, isFull, isExpired, formattedExpirationDate;
  if (privateLesson && isPackageLsn) {
    const { lastSetInfo } = getLessonPackageItemsInfo(
      privateLesson.packageSets
    );
    isFull = lastSetInfo?.isFullSet;
    isExpired = lastSetInfo?.isPassedSetDeadline;
    currentSet = lastSetInfo?.set;

    const currentSetObj = privateLesson.packageSets?.find(
      ({ setNumber }) => setNumber === currentSet
    );

    const setItems = lastSetInfo.setItems;

    const expirationDate = getPackageLessonSetExpirationDate(currentSetObj);

    formattedExpirationDate = moment(expirationDate).format("MM-DD-YYYY");
  }

  let instrumentName,
    teacherName,
    programName,
    time,
    day,
    formattedNextLessonDate;
  if (isPackagePrivateLesson(privateLesson?.type)) {
    const { nextPackageItem, instrument } = privateLesson || {};
    console.log({ nextPackageItem });
    const { teacher, program, startDate, duration } = nextPackageItem || {};
    instrumentName = instrument?.name;
    teacherName = teacher?.fullName;
    programName = program?.name;

    const endDate = moment(startDate).add(parseInt(duration), "minutes");
    time = `${moment(startDate).format("hh:mm A")} - ${moment(endDate).format(
      "hh:mm A"
    )}`;
    day = moment(startDate).format("dddd");
    formattedNextLessonDate = startDate
      ? moment(startDate).format("dddd, MMM D, YYYY")
      : null;
  } else {
    const { currentTimeline, instrument } = privateLesson || {};
    const { teacher, program, nextDate, startDate, duration } =
      currentTimeline || {};

    instrumentName = instrument?.name;
    teacherName = teacher?.fullName;
    programName = program?.name;

    const endDate = moment(startDate).add(parseInt(duration), "minutes");
    time = `${moment(startDate).format("hh:mm A")} - ${moment(endDate).format(
      "hh:mm A"
    )}`;
    day = moment(startDate).format("dddd");
    formattedNextLessonDate = nextDate
      ? moment(nextDate).format("dddd, MMM D, YYYY")
      : null;
  }

  return (
    <>
      <div className="mt-3 mb-3">
        {isPackageLsn ? (
          isFull ? (
            <PrimaryButton
              backgroundColor="#e357a2"
              width="120px"
              padding="6px"
            >
              Completed
            </PrimaryButton>
          ) : isExpired ? (
            <PrimaryButton
              backgroundColor="#e357a2"
              width="120px"
              padding="6px"
            >
              Expired
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => addNewSetItemModalData.openModal()}
              width="120px"
              padding="6px"
            >
              Schedule
            </PrimaryButton>
          )
        ) : null}
      </div>
      {isPackageLsn && (
        <div className="mt-3 mb-3">
          <h6>Expires in: {formattedExpirationDate}</h6>
        </div>
      )}

      {formattedNextLessonDate ? (
        <div className="d-flex justify-content-between">
          <div>
            <div className="mb-3 mx-2">
              <InfoText1>Next Lesson Date</InfoText1>
              <InfoText2>{formattedNextLessonDate || "N/A"}</InfoText2>
            </div>
            <div className="mb-3 mx-2">
              <InfoText1>Program</InfoText1>
              <InfoText2>{programName || "N/A"}</InfoText2>
            </div>
            <div className="mb-3 mx-2">
              <InfoText1>Lesson Day</InfoText1>
              <InfoText2>{day || "N/A"}</InfoText2>
            </div>
          </div>
          <div className="w-35">
            <div className="mb-3 mx-2">
              <InfoText1>Current Teacher</InfoText1>
              <InfoText2>{teacherName || "N/A"}</InfoText2>
            </div>
            <div className="mb-3 mx-2">
              <InfoText1>Lesson Time</InfoText1>
              <InfoText2>{time || "N/A"}</InfoText2>
            </div>
            <div className="mb-3 mx-2">
              <InfoText1>Instrument</InfoText1>
              <InfoText2>{instrumentName || "N/A"}</InfoText2>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="text-center">No Upcoming Lesson</h4>
        </div>
      )}

      {addNewSetItemModalData.isModalOpen && isPackageLsn && currentSet && (
        <SinglePackageLessonCreationModal
          onLessonCreateSuccess={() => {
            window.location.reload();
          }}
          lessonDuration={privateLesson.packageSets[0]?.setItems?.[0]?.duration}
          lessonLocationId={
            privateLesson.packageSets[0]?.setItems?.[0]?.locationId
          }
          lessonInstrumentId={privateLesson.instrumentId}
          modalData={addNewSetItemModalData}
          isNewLesson={false}
          itemSet={currentSet}
          packageLessonId={privateLesson.id}
          shortenTimeSlots={true}
        />
      )}
    </>
  );
};

export default PrivateLessonInfo;
