import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { CloseIcon } from "./styled";
import TrialStudentInfoBody from "./TrialStudentInfoBody";

const TrialStudentsInfoModal = ({
  modalData,
  studentIndex,
  showCreateStudentTrialLesson,
  onEditStudentClick,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      padding="0px"
      size="lg"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomModalHeader
        padding="25px"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Trial Student</h2>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <TrialStudentInfoBody
          studentIndex={studentIndex}
          modalData={modalData}
          showCreateStudentTrialLesson={showCreateStudentTrialLesson}
          onEditStudentClick={onEditStudentClick}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default TrialStudentsInfoModal;
