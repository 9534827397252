import React from "react";
import { Col, Row } from "reactstrap";
import RouteContent from "src/components/common/RouteContent";
import useParentsData from "./hooks/useParentsData";
import SearchBar from "./SearchBar";
import TableLayout from "./TableLayout";

const ParentsPage = () => {
  const parentsData = useParentsData();
  const { searchTerm, setSearchTerm } = parentsData;
  return (
    <RouteContent title="Parents">
      <Row>
        <Col xs={6}>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </Col>
      </Row>
      <div className="mt-4">
        <TableLayout parentsData={parentsData} />
      </div>
    </RouteContent>
  );
};

export default ParentsPage;
