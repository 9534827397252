import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 2rem;

  .chart-desktop {
    display: block;
  }

  .chart-mobile {
    display: none;
  }

  .chart-desktop,
  .chart-mobile {
    p {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 700;
      text-align: center;

      span {
        color: #269900;
      }
    }
  }

  @media (max-width: ${ScreenSize.MD}px) {
    grid-template-columns: 1fr;

    .chart-desktop {
      display: none;
    }

    .chart-mobile {
      display: block;
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  & > div {
    width: 30%;
    min-width: 200px;
  }
`;
