import React from "react";
import {
  CustomFormInput,
  CustomFormLabel,
  Group,
  GroupItem,
  PrimarySpan,
  PrimaryText,
  SecondaryText,
} from "../styled";
import { SubmitSpinner } from "src/components/common";
import {
  concertProgramSoloPerformanceTypes,
  concertProgramTypes,
} from "src/constants/concertEnum";
import PerformanceTypeSection from "../components/PerformanceTypeSection";
import RepertoiresSection from "../components/RepertoiresSection";
import EquipmentSection from "../components/EquipmentSection";
import { ModalButtonWithLoading } from "src/utils/shared/shared";
import { CustomTextarea } from "src/utils/shared/styled";

const CreateConcertSoloProgramBody = ({ isEditMode, hookData }) => {
  const {
    initialData,
    loadingInitialData,
    handleProgramDataChange,
    programData,
    addNewRepertoireRow,
    handleDeleteRepertoire,
    handleRepertoireChange,
    handleCreateProgram,
    loadingAction,
    handleEditProgram,
  } = hookData;
  const { concertSignup, signupUser, concert } = initialData;
  const { fullName } = signupUser || {};

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ height: "40vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  return (
    <div style={{ height: "400px", overflow: "auto", padding: "15px" }}>
      <div>
        <SecondaryText className="mb-2">Student Name</SecondaryText>
        <PrimaryText>{fullName}</PrimaryText>
      </div>
      <Group className="mt-5">
        <GroupItem>
          <PerformanceTypeSection
            handleProgramDataChange={handleProgramDataChange}
            programData={programData}
          />
        </GroupItem>
      </Group>
      <div className="mt-5">
        <RepertoiresSection
          repertoires={programData.repertoires}
          addNewRepertoireRow={addNewRepertoireRow}
          handleDeleteRepertoire={handleDeleteRepertoire}
          handleRepertoireChange={handleRepertoireChange}
        />
      </div>

      <div className="mt-5">
        <EquipmentSection
          handleProgramDataChange={handleProgramDataChange}
          programData={programData}
          equipmentList={concert?.equipmentList}
        />
      </div>
      <div className="mt-5">
        <SecondaryText className="mb-2">Note (Optional)</SecondaryText>
        <CustomTextarea
          width="100%"
          height="100px"
          value={programData.note}
          onChange={(e) => handleProgramDataChange("note", e.target.value)}
        />
      </div>
      <div className="mt-5 text-end">
        {isEditMode ? (
          <ModalButtonWithLoading
            onClick={handleEditProgram}
            isLoading={loadingAction}
            primary
            fontWeight="bold"
          >
            Update Program
          </ModalButtonWithLoading>
        ) : (
          <ModalButtonWithLoading
            onClick={handleCreateProgram}
            isLoading={loadingAction}
            primary
            fontWeight="bold"
          >
            Create Program
          </ModalButtonWithLoading>
        )}
      </div>
    </div>
  );
};

export default CreateConcertSoloProgramBody;
