import { firebaseConfig as devFirebaseConfig } from "./config.js";
import { firebaseConfig as prodFirebaseConfig } from "./deploy-config.js";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  updatePassword,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import logAnalyticsEvents from "src/utils/logAnalyticsEvents";

const REACT_APP_CONFIRMATION_EMAIL_REDIRECT =
  process.env.REACT_APP_MODE === "prod"
    ? "https://www.artismymusic.com/confirm"
    : "https://artisconnect-a3c6e.web.app/confirm";

// const REACT_APP_CONFIRMATION_EMAIL_REDIRECT = "http://localhost:3000/confirm";

const firebaseConfig =
  process.env.REACT_APP_MODE === "prod"
    ? prodFirebaseConfig
    : process.env.REACT_APP_MODE === "dev"
    ? devFirebaseConfig
    : "";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "us-central1");
const secondaryApp = initializeApp(firebaseConfig, "Secondary");
const analytics = getAnalytics(app);
logAnalyticsEvents(analytics);

class Firebase {
  constructor() {
    // app.initializeApp(firebaseConfig);
    this.secondaryApp = secondaryApp;
    this.emailAuthProvider = EmailAuthProvider;
    this.auth = auth;
    this.db = db;
    this.storage = storage;
    this.functions = functions;
  }

  // uses cloud fns callable function to update the user auth info + corresponding firestore
  // params:  { userId: "", updateObj: { email: "", password:"" } }
  updateUserAuthWithAdminSDK = httpsCallable(functions, "updateUserAuth");
  deleteUserWithAdminSDK = httpsCallable(functions, "deleteUser");
  getUserFromAuthWithAdminSDK = httpsCallable(functions, "getUserFromAuth");

  signUpWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  signInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password);

  // Login with email link
  sendSignInLinkToEmail = (email, actionCodeSettings) =>
    sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  isSignInWithEmailLink = (location) =>
    isSignInWithEmailLink(this.auth, location);
  signInWithEmailLink = (email, location) =>
    signInWithEmailLink(this.auth, email, location);

  signOut = () => signOut(this.auth);

  passwordReset = (email) => sendPasswordResetEmail(this.auth, email);

  sendEmailVerification = () =>
    sendEmailVerification(this.auth.currentUser, {
      url: REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  passwordUpdate = (password) =>
    updatePassword(this.auth.currentUser, password);

  updateEmail = (email) => updateEmail(this.auth.currentUser, email);
  updateProfile = (data) => updateProfile(this.auth.currentUser, data);

  onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(this.auth, async (authUser) => {
      if (authUser) {
        const doc = await getDoc(this.user(authUser.uid));
        if (doc.exists()) {
          const dbUser = doc.data();
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          };
          next(authUser);
        } else {
          this.signOut();
          console.log(`No such document! users/${authUser.uid}`);
        }
      } else {
        fallback();
      }
    });

  // user = (uid) => this.db().collection(`users`).doc(uid);
  user = (uid) => {
    const docRef = doc(this.db, "users", uid);
    return docRef;
  };
  getStudentBySystemId = (systemID) => {
    const collectionRef = collection(this.db, "users");
    return query(collectionRef, where("systemID", "==", systemID));
  };
  // users = () => this.db().collection("users");
  users = () => collection(this.db, "users");
  // student = (id) => collection(this.db, "users", id);
  // userDegrees = (uid) => this.db().collection(`users/${uid}/degrees`);
  userDegrees = (uid) => collection(this.db, `users/${uid}/degrees`);
  // userCertificates = (uid) => this.db().collection(`users/${uid}/certificates`);
  userCertificates = (uid) => collection(this.db, `users/${uid}/certificates`);
  // instruments = () => this.db().collection("Instruments");
  instruments = () => collection(this.db, "Instruments");
  instrument = (id) => doc(this.db, "Instruments", id);
  // programs = () => this.db().collection("Programs");
  programs = () => collection(this.db, "Programs");
  program = (id) => doc(this.db, "Programs", id);

  // lessons = () => this.db().collection("Lessons");
  lessons = () => collection(this.db, "Lessons");
  // studios = () => this.db().collection("Studios");
  studios = () => collection(this.db, "Studios");
  // lesson = (uid) => this.db().collection("Lessons").doc(uid);

  getParentStudents = (parentEmail, key = "primaryContactEmail") => {
    const collectionRef = collection(this.db, "users");
    return query(collectionRef, where(key, "==", parentEmail));
  };

  lesson = (id) => {
    const docRef = doc(this.db, "Lessons", id);
    return docRef;
  };
  trialLessons = () => collection(this.db, "TrialLessons");
  trialLesson = (id) => {
    const docRef = doc(this.db, "TrialLessons", id);
    return docRef;
  };
  trialStudents = () => collection(this.db, "TrialStudents");
  trialStudent = (id) => {
    const docRef = doc(this.db, "TrialStudents", id);
    return docRef;
  };
  getStudentLessons = (studentId) => {
    const collectionRef = collection(this.db, "Lessons");
    return query(collectionRef, where("studentId", "==", studentId));
  };

  lessonsNotes = () => collection(this.db, "LessonsNotes");
  lessonNote = (id) => doc(this.db, "LessonsNotes", id);

  getTeacherLessonsNotes = (teacherId) => {
    const collectionRef = collection(this.db, "LessonsNotes");
    return query(collectionRef, where("teacherId", "==", teacherId));
  };

  getSharedLessonsNotes = (teacherId) => {
    const collectionRef = collection(this.db, "LessonsNotes");
    return query(
      collectionRef,
      where("sharedWith", "array-contains", teacherId)
    );
  };
  getStudentLessonsNotes = (studentId) => {
    const collectionRef = collection(this.db, "LessonsNotes");
    return query(
      collectionRef,
      where("studentsIds", "array-contains", studentId)
    );
  };
  getLessonNote = (noteId) => doc(this.db, "LessonsNotes", noteId);

  getLessonNotes = (lessonId) => {
    const collectionRef = collection(this.db, "LessonsNotes");
    return query(collectionRef, where("lessonId", "in", lessonId));
  };

  locations = () => collection(this.db, "Locations");

  // teacherLibrary = (teacherId) =>
  //   this.db().collection("AdminLibrary").doc(teacherId);
  // teacherLibrary = (teacherId) => {
  //   const docRef = doc(this.db, "AdminLibrary", teacherId);
  //   return docRef;
  // };

  getLessonAttendanceCollection = () => collection(this.db, "Attendance");
  attendance = (id) => doc(this.db, "Attendance", id);

  getLessonAttendanceList = (lessonId, studentId) => {
    const ref = collection(this.db, "Attendance");
    return query(
      ref,
      where("lessonId", "==", lessonId),
      where("studentId", "==", studentId)
    );
  };
  getLessonAttendanceDoc = (docId) => doc(this.db, "Attendance", docId);

  getSchoolYears = () => collection(this.db, "SchoolYears");
  getSchoolYearDoc = (id) => doc(this.db, "SchoolYears", id);

  usersAvailableDays = () => collection(this.db, "UsersAvailableDays");
  userAvailableDays = (id) => doc(this.db, "UsersAvailableDays", id);

  usersActivities = () => collection(this.db, "UsersActivities");
  userActivity = (id) => doc(this.db, "UsersActivities", id);

  makeupLessons = () => collection(this.db, "MakeupLessons");
  makeupLesson = (id) => doc(this.db, "MakeupLessons", id);

  filterUsersByRole = (role) => {
    const collectionRef = collection(this.db, "users");
    return query(collectionRef, where("role", "==", role));
  };
  getTeacherLessons = (teacherId) => {
    const collectionRef = collection(this.db, "Lessons");
    return query(collectionRef, where("teacherId", "==", teacherId));
  };

  getTeacherPrivateLessons = (teacherId) => {
    const collectionRef = collection(this.db, "PrivateLessons");
    // return collectionRef;
    // return query(collectionRef, where("teacherId", "==", teacherId));
    return query(
      collectionRef,
      where("teachersIds", "array-contains", teacherId)
    );
  };

  getTeacherTrialLessons = (teacherId) => {
    const collectionRef = collection(this.db, "TrialLessons");
    return query(collectionRef, where("teacherId", "==", teacherId));
  };
  getTeacherStudents = (studentsIds) => {
    const collectionRef = collection(this.db, "users");
    return query(collectionRef, where(documentId(), "in", studentsIds));
  };
  library = () => {
    return collection(this.db, "Library");
  };
  getSingleLibraryDoc = (itemId) => {
    return doc(this.db, "Library", itemId);
  };
  getLibrary = (userId) => {
    const collectionRef = collection(this.db, "Library");
    return query(collectionRef, where("userId", "==", userId));
  };
  setSharedLibraryItem = (itemId) => {
    return doc(this.db, "Library", itemId);
  };
  getSharedLibraryItems = (userId) => {
    const collectionRef = collection(this.db, "Library");
    return query(collectionRef, where("sharedWith", "array-contains", userId));
  };
  setSharedItem = (itemId) => {
    return doc(this.db, "Library", itemId);
  };
  getSharedItems = (userId) => {
    const collectionRef = collection(this.db, "Library");
    return query(collectionRef, where("sharedWith", "array-contains", userId));
  };
  getLocations = () => {
    return collection(this.db, "Locations");
  };
  getLocation = (id) => doc(this.db, "Locations", id);
  getTrialLessons = () => {
    return collection(this.db, "TrialLessons");
  };
  getTrialLesson = (lessonId) => {
    return doc(this.db, "TrialLessons", lessonId);
  };
  getTrialStudents = () => {
    return collection(this.db, "TrialStudents");
  };
  getTrialStudent = (userId) => {
    return doc(this.db, "TrialStudents", userId);
  };
  getStudentPrivateLessons = (studentId) => {
    const ref = collection(this.db, "PrivateLessons");
    return query(ref, where("studentId", "==", studentId));
  };
  PrivateLessons = () => collection(this.db, "PrivateLessons");
  PrivateLesson = (id) => {
    const docRef = doc(this.db, "PrivateLessons", id);
    return docRef;
  };
  PrivateLessonsStudents = () => collection(this.db, "PrivateLessonsStudents");
  PrivateLessonsStudent = (userId) => {
    return doc(this.db, "PrivateLessonsStudents", userId);
  };

  appointments = () => collection(this.db, "Appointments");
  appointment = (id) => doc(this.db, "Appointments", id);

  feedItems = () => collection(this.db, "FeedItems");
  feedItem = (id) => doc(this.db, "FeedItems", id);
  feedItemsMetadata = () => collection(this.db, "FeedItemsMetadata");
  feedItemMetadata = (id) => doc(this.db, "FeedItemsMetadata", id);

  absences = () => collection(this.db, "Absences");
  absence = (id) => doc(this.db, "Absences", id);

  absenceLogs = () => collection(this.db, "AbsenceLogs");
  absenceLog = (id) => doc(this.db, "AbsenceLogs", id);

  calendarLabels = () => collection(this.db, "CalendarLabels");
  calendarLabel = (id) => doc(this.db, "CalendarLabels", id);

  makeupOpenings = () => collection(this.db, "MakeupOpenings");
  makeupOpening = (id) => doc(this.db, "MakeupOpenings", id);

  breaks = () => collection(this.db, "Breaks");
  break = (id) => doc(this.db, "Breaks", id);

  notifications = () => collection(this.db, "Notifications");
  notification = (id) => doc(this.db, "Notifications", id);

  schoolPolicies = () => collection(this.db, "SchoolPolicies");
  schoolPolicy = (id) => doc(this.db, "SchoolPolicies", id);

  notificationSettings = () => collection(this.db, "NotificationSettings");
  notificationSetting = (id) => doc(this.db, "NotificationSettings", id);

  studioUsages = () => collection(this.db, "StudioUsage");
  studioUsage = (id) => doc(this.db, "StudioUsage", id);

  usersPaymentRates = () => collection(this.db, "UsersPaymentRates");
  userPaymentRate = (id) => doc(this.db, "UsersPaymentRates", id);

  usersPayment = () => collection(this.db, "UsersPayment");
  userPayment = (id) => doc(this.db, "UsersPayment", id);

  makeupRequests = () => collection(this.db, "MakeupRequests");
  makeupRequest = (id) => doc(this.db, "MakeupRequests", id);

  groupClasses = () => collection(this.db, "GroupClasses");
  groupClass = (id) => doc(this.db, "GroupClasses", id);

  groupClassAvailableDays = () =>
    collection(this.db, "GroupClassAvailableDays");
  groupClassAvailableDay = (id) => doc(this.db, "GroupClassAvailableDays", id);

  groupClassPaymentOptions = () =>
    collection(this.db, "GroupClassPaymentOptions");
  groupClassPaymentOption = (id) =>
    doc(this.db, "GroupClassPaymentOptions", id);

  groupClassEnrollments = () => collection(this.db, "GroupClassEnrollments");
  groupClassEnrollment = (id) => doc(this.db, "GroupClassEnrollments", id);

  groupClassPayments = () => collection(this.db, "GroupClassPayments");
  groupClassPayment = (id) => doc(this.db, "GroupClassPayments", id);

  concerts = () => collection(this.db, "Concerts");
  concert = (id) => doc(this.db, "Concerts", id);

  concertInvitations = () => collection(this.db, "ConcertInvitations");
  concertInvitation = (id) => doc(this.db, "ConcertInvitations", id);

  concertSignups = () => collection(this.db, "ConcertsSignups");
  concertSignup = (id) => doc(this.db, "ConcertsSignups", id);

  concertPrograms = () => collection(this.db, "ConcertPrograms");
  concertProgram = (id) => doc(this.db, "ConcertPrograms", id);

  concertPaymentOptions = () => collection(this.db, "ConcertPaymentOptions");
  concertPaymentOption = (id) => doc(this.db, "ConcertPaymentOptions", id);

  feedbacks = () => collection(this.db, "Feedbacks");
  feedback = (id) => doc(this.db, "Feedbacks", id);

  featureNotifications = () => collection(this.db, "FeatureNotifications");
  featureNotification = (id) => doc(this.db, "FeatureNotifications", id);
}

export default Firebase;
