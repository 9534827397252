import React from "react";
import IconsTable from "./IconsTable";
import "./style.css";

const Content = () => {
  return (
    <article className="instruction-content">
      <h2 className="second-heading">
        Private Lessons On-Line and in-Person in Belmont Ca.
      </h2>
      <p className="firstParagraphPrivateInstructions fontSize25">
        Enrollment is welcome anytime! It’s never too late to join.
      </p>
      <p className="firstParagraphPrivateInstructions fontSize25">
        Just register on-line, call us or stop by the school to sign up!
      </p>
      <IconsTable />
      <strong>
        <br />{" "}
      </strong>
      <p className="fontSize25">
        <strong>Lessons are available for students 5 and up</strong>. Class is
        once a week.
        <br />
        <br />
        Teacher will teach the basic foundations with a variety of styles,
        techniques,
        <br />
        <br />
        and different levels based on the different learning structures for each
        child.
      </p>
    </article>
  );
};

export default Content;
