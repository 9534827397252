import moment from "moment";
import { toast } from "react-toastify";
import { isRecurringEvent } from "src/constants/eventsEnum";
import {
  getFullDateFromDateAndTimeInputs,
  getTimeDiffInMins,
  isActivityOverlappingWithRecurringEvent,
  isFutureAndAfterRequestDateActivity,
  prepareTeacherActivities,
} from "src/utils/helpers";

export const checkIfLessonInTeacherAvailabilities = (
  { lessonStartDate, duration },
  teacherAvailableDays = []
) => {
  // if no teacher availabilities, then return false as teacher isnt available
  if (!teacherAvailableDays?.length || !lessonStartDate || !duration)
    return false;

  let isLessonWithinAvailabilities = false;

  const lessonEndDate = moment(lessonStartDate)
    .add(parseInt(duration), "minutes")
    .toDate();

  for (const teacherDay of teacherAvailableDays) {
    const { startDate, endDate } = teacherDay;
    const teacherDayDuration = getTimeDiffInMins(startDate, endDate);
    // if same week day
    let teacherDayStartDate, teacherDayEndDate;
    if (moment(lessonStartDate).day() === moment(startDate).day()) {
      teacherDayStartDate = getFullDateFromDateAndTimeInputs(
        lessonStartDate,
        moment(startDate).format("HH:mm")
      );
      teacherDayEndDate = moment(teacherDayStartDate)
        .add(teacherDayDuration, "minutes")
        .toDate();
    } else if (moment(lessonStartDate).day() === moment(endDate).day()) {
      teacherDayEndDate = getFullDateFromDateAndTimeInputs(
        lessonStartDate,
        moment(endDate).format("HH:mm")
      );
      teacherDayStartDate = moment(teacherDayEndDate)
        .subtract(teacherDayDuration, "minutes")
        .toDate();
    }

    if (teacherDayStartDate && teacherDayEndDate) {
      console.log({
        teacherDayStartDate: moment(teacherDayStartDate).toString(),
        teacherDayEndDate: moment(teacherDayEndDate).toString(),
        lessonStartDate: moment(lessonStartDate).toString(),
      });
    }
    if (
      teacherDayStartDate &&
      teacherDayEndDate &&
      moment(lessonStartDate).isBetween(
        teacherDayStartDate,
        teacherDayEndDate,
        "minutes",
        "[)"
      ) &&
      moment(lessonEndDate).isBetween(
        teacherDayStartDate,
        teacherDayEndDate,
        "minutes",
        "(]"
      )
    ) {
      isLessonWithinAvailabilities = true;
      break;
    }
  }

  return isLessonWithinAvailabilities;
};

export const checkForOverlapping = (
  teacherActivities = [],
  teacherId,
  { lessonId, lessonStartDate, duration }
) => {
  if (!lessonStartDate || !duration || !lessonId) {
    toast.error("Missing params in (checkForOverlapping) Fn");
    return true;
  }

  if (!teacherActivities?.length) return false;

  const lessonEndDate = moment(lessonStartDate).add(
    parseInt(duration),
    "minutes"
  );
  const preparedActivities = prepareTeacherActivities(
    teacherActivities,
    teacherId
  );
  // filters out future one time events
  const futureActivities = preparedActivities.filter((activity) => {
    if (!isRecurringEvent(activity.type, activity.privateLessonType)) {
      return isFutureAndAfterRequestDateActivity(
        lessonStartDate,
        activity.end_time
      );
    } else {
      return true;
    }
  });

  let isOverlapping = false;
  for (const activity of futureActivities) {
    if (
      isActivityOverlappingWithRecurringEvent({
        eventStartDate: lessonStartDate,
        eventEndDate: lessonEndDate,
        eventId: lessonId,
        teacherId,
        activity,
      })
    ) {
      isOverlapping = true;
      break;
    }
  }
  return isOverlapping;
};
