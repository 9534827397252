import React from "react";
import CreateConcertSection from "./components/CreateConcertSection";
import RouteContent from "src/components/common/RouteContent";
import ConcertsListWithDetails from "../common/components/concerts/ConcertsListWithDetails";

const Concerts = () => {
  return (
    <RouteContent title="Concerts">
      <div>
        <CreateConcertSection />
      </div>
      <div className="mt-5">
        <ConcertsListWithDetails />
      </div>
    </RouteContent>
  );
};

export default Concerts;
