import moment from "moment";
import { updatedMomentNow } from "src/utils/helpers";

// gets the nearest weekly lesson
export const getNearestLesson = (lessonsWithDates) => {
  let nearestLesson;
  const todayDate = new Date();
  const todayData = { day: todayDate.getDay(), hour: todayDate.getHours() };

  // sort by day in the week and time ascendingly
  const orderedLessons = lessonsWithDates
    .sort((a, b) => {
      return a.date.dateData.day - b.date.dateData.day;
    })
    .sort((a, b) =>
      a.date.dateData.day === b.date.dateData.day
        ? a.date.dateData.hour - b.date.dateData.hour
        : 0
    );
  console.log({ orderedLessons });
  const indexOfNearestLesson = orderedLessons.findIndex((lesson) => {
    if (lesson.date.dateData.day === todayData.day) {
      return lesson.date.dateData.hour >= todayData.hour;
    } else {
      return lesson.date.dateData.day >= todayData.day;
    }
  });
  console.log({ indexOfNearestLesson });
  nearestLesson =
    indexOfNearestLesson !== -1
      ? [...orderedLessons].slice(indexOfNearestLesson)[0]
      : orderedLessons[0];

  return nearestLesson;
};

// Gets the date of the same day and hour in the nearest future occurance
export const nextDayAndTime = (startDate) => {
  const now = updatedMomentNow().toDate();

  const dayOfWeek = moment(startDate).day();
  const hours = moment(startDate).hours();
  const minutes = moment(startDate).minutes();

  // const result = new Date(
  //   now.getFullYear(),
  //   now.getMonth(),
  //   now.getDate() + ((7 + dayOfWeek - now.getDay()) % 7),
  //   hour,
  //   minute
  // );
  let result = updatedMomentNow()
    .set({
      date:
        updatedMomentNow().date() +
        ((7 + dayOfWeek - updatedMomentNow().day()) % 7),
      hours,
      minutes,
    })
    .toDate();

  if (moment(now).isAfter(result, "hours")) {
    result = moment(result).add(1, "week").toDate();
    // result.setDate(result.getDate() + 7);
  }

  return result;
};
