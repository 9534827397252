import moment from "moment";
import React from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";

const CalendarWrapper = styled.div`
  text-align: center;
  .react-date-picker__wrapper {
    padding: 5px 10px;
    border-radius: 15px;
    width: 300px;
    //background-color:
  }
`;
const RequestedDatePicker = ({ requestedDateData }) => {
  const { requestedDate, setRequestedDate, maxDate, minDate } =
    requestedDateData;
  return (
    <CalendarWrapper>
      <p className="mb-2">
        Please select a date within 2 weeks from today's date
      </p>
      <input
        type="date"
        onChange={(e) => setRequestedDate(e.target.value)}
        value={requestedDate}
        // max={moment(maxDate).format("YYYY-MM-DD")}
        // min={moment(minDate).format("YYYY-MM-DD")}
      />
    </CalendarWrapper>
  );
};

export default RequestedDatePicker;
