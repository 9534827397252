import React from "react";
import usePackageItemData from "./hooks/usePackageItemData";
import { PrimaryButton } from "src/utils/shared/styled";
import InstrumentSection from "./InstrumentSection";
import LessonLengthSelect from "./LessonLengthSelect";
import LocationSelect from "./LocationSelect";
import useModal from "src/hooks/useModal";
import SinglePackageLessonCreationModal from "../../../SinglePackageLessonCreationModal";

const ExistingPackageSetItemCreation = ({
  initialLessonDuration,
  initialLessonLocationId,
  packageLessonId,
  setNumber,
  instrumentId,
}) => {
  const scheduleLessonModalData = useModal();

  const {
    initialData,

    selectedLessonDuration,
    setSelectedLessonDuration,
    selectedLocationId,
    setSelectedLocationId,
    canScheduleLesson,
  } = usePackageItemData({
    initialLessonDuration,
    initialLessonLocationId,
  });

  return (
    <div>
      <div className="d-flex" style={{ gap: "30px" }}>
        <div style={{ width: "33.333%" }}>
          <InstrumentSection
            instruments={initialData.instruments}
            value={instrumentId}
          />
        </div>
        <div style={{ width: "33.333%" }}>
          <LessonLengthSelect
            value={selectedLessonDuration}
            handleChange={(e) =>
              setSelectedLessonDuration(parseInt(e.target.value))
            }
          />
        </div>
        <div style={{ width: "33.333%" }}>
          <LocationSelect
            locations={initialData.locations}
            value={selectedLocationId}
            handleChange={(e) => setSelectedLocationId(e.target.value)}
          />
        </div>
      </div>
      <div>
        <div className="mt-5 text-center">
          {canScheduleLesson && (
            <PrimaryButton
              onClick={scheduleLessonModalData.openModal}
              width="50%"
            >
              Schedule the first lesson
            </PrimaryButton>
          )}
        </div>

        {/* creates space */}
      </div>

      {/* Modals */}
      {scheduleLessonModalData.isModalOpen && (
        <SinglePackageLessonCreationModal
          onLessonCreateSuccess={() => {
            window.location.reload();
          }}
          lessonDuration={selectedLessonDuration}
          lessonLocationId={selectedLocationId}
          lessonInstrumentId={instrumentId}
          modalData={scheduleLessonModalData}
          isNewLesson={false}
          itemSet={setNumber}
          packageLessonId={packageLessonId}
          shortenTimeSlots={false}
        />
      )}
    </div>
  );
};

export default ExistingPackageSetItemCreation;
