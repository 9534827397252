import { MdDelete, MdEmail } from "react-icons/md";
import { CardActionsWrapper } from "./styled";
import { BiSolidArchiveIn } from "react-icons/bi";
import { Button, Popconfirm } from "antd";

const CardActions = ({
  onReplyClick,
  onForwardClick,
  onArchiveClick,
  onDeleteClick,
  isLoading,
}) => {
  return (
    <CardActionsWrapper>
      <Button
        aria-label="Reply by email"
        onClick={onReplyClick}
        title="Reply by email"
      >
        <MdEmail size={18} />
      </Button>
      <Button onClick={onForwardClick}>Forward to developer</Button>
      <Button
        aria-label="Move to archive"
        onClick={onArchiveClick}
        loading={isLoading}
        title="Move to archive"
      >
        <BiSolidArchiveIn size={18} />
      </Button>
      <Popconfirm
        title="Delete feedback"
        description="Delete this feedback?"
        okButtonProps={[{ loading: isLoading }]}
        onConfirm={onDeleteClick}
      >
        <Button aria-label="Delete" title="Delete">
          <MdDelete size={18} />
        </Button>
      </Popconfirm>
    </CardActionsWrapper>
  );
};

export default CardActions;
