import { useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import store from "src/stores/UserStore";

import { CustomLabel } from "src/utils/styled";
import {
  CurrentStudentContainer,
  FormField,
  StyledButton,
  StyledInput,
} from "./styled";

const CurrentStudentBlock = () => {
  const [emailValue, setEmailValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn } = store;
  const { getUserByEmail } = useFirebaseFns();

  useEffect(() => {
    if (isLoggedIn) {
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const user = await getUserByEmail(emailValue);

    console.log(user);
    setIsLoading(false);
  };

  return (
    <CurrentStudentContainer>
      <CustomLabel>Enter your email</CustomLabel>
      <StyledInput
        name="email"
        type="email"
        onChange={({ target }) => setEmailValue(target?.value)}
      />
      <FormField margin>
        <StyledButton
          disabled={!emailValue}
          loading={isLoading}
          size="large"
          type="primary"
          onClick={handleSubmit}
        >
          Submit
        </StyledButton>
      </FormField>
    </CurrentStudentContainer>
  );
};

export default CurrentStudentBlock;
