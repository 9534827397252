import ReactECharts from "echarts-for-react";
import { getAdminChartColor, getTeacherChartColor } from "./helpers";

const PaymentInfoChart = ({ data = [], name, subtitle, userType }) => {
  return (
    <ReactECharts
      style={{ height: 500, overflow: "visible" }}
      option={{
        title: {
          text: "Gross payments",
          ...(subtitle && { subtext: subtitle }),
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: 0,
        },
        grid: {
          left: 10,
          containLabel: false,
          bottom: 10,
          top: 10,
          right: 30,
        },
        series: [
          {
            name,
            type: "pie",
            radius: "70%",
            data: data?.map((item) => ({
              ...item,
              itemStyle: {
                color:
                  userType === "teacher"
                    ? getTeacherChartColor(item?.name)
                    : getAdminChartColor(item?.type),
              },
            })),
            colorBy: "data",
            top: 20,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "inner",
                  color: "#131313",
                  fontWeight: 600,
                  formatter: (params) =>
                    "$" + params?.value?.toLocaleString("en-US"),
                },
                labelLine: {
                  show: true,
                },
              },
            },
          },
        ],
      }}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
};

export default PaymentInfoChart;
