import {
  addDoc,
  arrayUnion,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import {
  absenceTypes,
  isDeclinedAbsence,
  isPendingAbsence,
} from "src/constants/absenceTypes";
import { eventsMap, privateLessonTypes } from "src/constants/eventsEnum";
import { scheduleTypes } from "src/constants/scheduleTypes";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  parseUserActivityDates,
  parseUserAvailableDayDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getInstruments = async () => {
    try {
      const instrumentsSnap = await getDocs(firebase.instruments());
      const instruments = parseFirebaseDoc(instrumentsSnap.docs);
      return instruments;
    } catch (err) {
      console.log(err);
    }
  };

  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = parseFirebaseDoc(locationsSnap.docs);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeachers = async () => {
    try {
      const teachersQuery = query(
        firebase.users(),
        where("role", "==", UserRole.TEACHER)
      );
      const teachersSnap = await getDocs(teachersQuery);
      const teachers = parseFirebaseDoc(teachersSnap.docs);
      return teachers;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
  const getTeachersActivities = async () => {
    try {
      const activitiesQuery = query(
        firebase.usersActivities(),
        where("isActive", "==", true)
      );
      const activitiesSnap = await getDocs(activitiesQuery);
      const activities = parseFirebaseDoc(activitiesSnap.docs);
      const activitiesWithDates = activities.map((activity) =>
        parseUserActivityDates(activity)
      );
      return activitiesWithDates;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getPrograms = async () => {
    try {
      const programsSnap = await getDocs(firebase.programs());
      const programs = parseFirebaseDoc(programsSnap.docs);
      return programs;
    } catch (err) {
      console.log(err);
    }
  };

  const createRegularPrivateLesson = async (lessonObj) => {
    try {
      if (!lessonObj) return;

      const plRes = await addDoc(firebase.PrivateLessons(), lessonObj);
      if (plRes?.id) {
        await updateDoc(firebase.user(lessonObj.studentId), {
          Privatelessons: arrayUnion(plRes.id),
        });

        const activityRes = await createRegularLessonActivity(
          lessonObj,
          plRes.id
        );
      } else {
        toast.error("Couldn't create an activity for the lesson");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createRegularLessonActivity = async (lessonObj, lessonId) => {
    try {
      const timelineObj = lessonObj.timeline[0];
      const activityStart = timelineObj.startDate;
      const lessonDuration = timelineObj.duration;

      const activityEnd = moment(activityStart)
        .add(lessonDuration, "minutes")
        .toDate();

      const activityTimelineObj = {
        id: timelineObj.id, // same id that the timeline has in the original doc
        start_time: activityStart, // first lesson date
        end_time: activityEnd,
        location: timelineObj.locationId,
        userId: timelineObj.teacherId,
        userRole: UserRole.TEACHER,
      };

      const activityObj = {
        createdAt: new Date(),
        scheduleType: scheduleTypes.teacherDays.code,
        type: eventsMap.privateLesson.code,
        isActive: true,
        privateLessonType: privateLessonTypes.REGULAR,

        timeline: [activityTimelineObj],
      };
      console.log({ lessonActivityObj: activityObj });
      return await setDoc(firebase.userActivity(lessonId), activityObj);
    } catch (err) {
      console.log(err);
    }
  };
  const getTeachersTAs = async () => {
    try {
      const q = query(
        firebase.absences(),
        where("absenceType", "==", absenceTypes.teacherAbsence.code)
      );

      const absences = parseFirebaseDoc((await getDocs(q)).docs);
      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      return filteredAbsences;
    } catch (err) {
      console.log(err);
    }
  };

  const createTeacherBreakAndActivity = async (breakObj, userId) => {
    try {
      const { type } = breakObj;

      const res = await addDoc(firebase.breaks(), breakObj);

      if (!res?.id) {
        throw { message: "Couldnt create the break user activity" };
      }
      const timelineObj = breakObj.timeline[0];
      const activityStart = timelineObj.date;
      const breakDuration = timelineObj.duration;

      const activityEnd = moment(activityStart)
        .add(breakDuration, "minutes")
        .toDate();

      const breakTimelineObj = {
        id: timelineObj.id, // same id that the timeline has in the original doc
        start_time: activityStart, // first lesson date
        end_time: activityEnd,
        location: "",
        userId: breakObj.userId,
        userRole: breakObj.userRole,
        requestedBy: userId,
      };
      const activityObj = {
        createdAt: new Date(),
        scheduleType: scheduleTypes.other.code,
        isActive: true,
        type: parseInt(type),
        timeline: [breakTimelineObj],
      };
      console.log({ breakActivityObj: activityObj });

      await setDoc(firebase.userActivity(res.id), activityObj);

      return res;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    getTeachersActivities,
    getTeachers,
    getInstruments,
    getLocations,
    getPrograms,
    createRegularPrivateLesson,
    getTeachersTAs,
    createTeacherBreakAndActivity,
  };
};

export default useFirebaseFns;
