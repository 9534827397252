import { Space, Button } from "antd";
import { StyledActionButton } from "./styled";
import moment from "moment";

const applicationsTableColumns = ({
  onNameClick,
  onDeleteClick,
  onArchiveClick,
}) => {
  const currentMonth = moment().format("MM/YYYY");

  return [
    {
      title: "Request date",
      dataIndex: "request_date",
      key: "request_date",
      responsive: ["sm"],
      sorter: (a, b) =>
        moment(a.request_date, "MM/DD/YYYY").valueOf() -
        moment(b.request_date, "MM/DD/YYYY").valueOf(),
      filters: [
        {
          text: "This month",
          value: currentMonth,
        },
        {
          text: "Last month",
          value: moment().subtract(1, "months").format("MM/YYYY"),
        },
      ],
      onFilter: (value, record) => {
        const recordDate = moment(record?.request_date, "MM/DD/YYYY");
        return recordDate.format("MM/YYYY") === value;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
      render: (_, record) => (
        <Button type="link" onClick={() => onNameClick(record?.key)}>
          {record?.name}
        </Button>
      ),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      key: "instrument",
      responsive: ["lg"],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) =>
        a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
      filters: [
        {
          text: "Guest Student",
          value: "Guest_Student",
        },
        {
          text: "Teacher Applicant",
          value: "Teacher_Applicant",
        },
        {
          text: "Admin Applicant",
          value: "Admin_Applicant",
        },
        {
          text: "Partner Applicant",
          value: "Partner_Applicant",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) =>
        record?.type?.toLowerCase()?.replace(/_/g, " ") ===
        value?.toLowerCase()?.replace(/_/g, " "),
      responsive: ["sm"],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <StyledActionButton
            onClick={() => onDeleteClick(record?.key)}
            actiontype={record?.withdrawal ? "requestedDelete" : "delete"}
          >
            {record?.withdrawal ? "Withdraw" : "Delete"}
          </StyledActionButton>
          <StyledActionButton
            onClick={() => onArchiveClick(record?.key)}
            actiontype="archive"
          >
            Archive
          </StyledActionButton>
        </Space>
      ),
    },
  ];
};

export default applicationsTableColumns;
