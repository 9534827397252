import React from "react";
import { injectUserStore } from "src/utils/helpers";
import { NavButton } from "src/utils/shared/styled";
import styled from "styled-components";
import { getUserMainTabs } from "../../../constants";
import { useFeedSectionContext } from "../../../contexts/FeedSectionContext";
import { EllipseIcon } from "../../../styled";

const NavButtonContainer = styled.div`
  position: relative;
  margin-top: 15px;
`;

const MainNav = ({ UserStore }) => {
  const user = UserStore.user;
  const { currentMainTab, setCurrentMainTab, newFeedItems } =
    useFeedSectionContext();

  const tabs = getUserMainTabs(user.role);
  return (
    <div
      className="d-flex align-items-center pb-2"
      style={{ overflow: "auto" }}
    >
      {tabs.map(({ title, key }) => {
        const hasNewItems = !!newFeedItems.filter(({ type }) => type === key)
          .length;

        return (
          <NavButtonContainer key={key}>
            <NavButton
              fontSize="1.1rem"
              className={currentMainTab === key ? "active" : undefined}
              onClick={() => setCurrentMainTab(key)}
              withRedBar
            >
              {title}
            </NavButton>
            {hasNewItems && <EllipseIcon>New</EllipseIcon>}
          </NavButtonContainer>
        );
      })}
    </div>
  );
};

export default injectUserStore(MainNav);
