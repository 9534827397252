import React from "react";
import SignupsTable from "../components/SignupsTable";
import { SubmitSpinner } from "src/components/common";

const ConcertSignupListBody = ({ hookData, closeModal, onActionSuccess }) => {
  if (hookData.loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      <SignupsTable hookData={hookData} onActionSuccess={onActionSuccess} />
    </div>
  );
};

export default ConcertSignupListBody;
