import {
  MediaPlayerContainer,
  PlayerControlsContainer,
  PlayerSeekContainer,
  TextButton,
} from "../../styled-components/teacherLibraryStyledComponents";
import React from "react";
import moment from "moment";

const MediaControls = ({
  playedDuration,
  setIsPlaying,
  setMediaUrl,
  mediaUrl,
  setPlayedDuration,
  duration,
  isReady,
  originalUrl,
  className = "",
  title = ''
}) => {
  const play = () => {
    if (mediaUrl === "") {
      setMediaUrl(originalUrl);
    }
    setIsPlaying(true);
  };
  const pause = () => {
    setIsPlaying(false);
  };
  const stop = () => {
    setPlayedDuration(0);
    pause();
    setMediaUrl("");
  };
  const formatSeconds = (seconds) => {
    return moment.utc(seconds * 1000).format("HH:mm:ss");
  };
  return (
    <MediaPlayerContainer className={`flex-grow-1 ${className}`}>
      <h3 className={"mb-3 text-center"}>{title}</h3>
      <PlayerSeekContainer
        played={`${100 * playedDuration}%`}
        className={"mb-2 position-relative mx-auto"}
      >
        <span className="total d-block" />
        <div className="w-100 played d-flex align-items-center position-absolute">
          <span className={"duration"} />
          <span className={"indicator"} />
        </div>
      </PlayerSeekContainer>
      <PlayerControlsContainer
        className={"d-flex align-items-center justify-content-between"}
      >
        <span className="current-time">
          {formatSeconds(Math.floor(playedDuration * duration))}
        </span>
        <div className={"controls-btns d-flex justify-content-center"}>
          <TextButton disabled={!isReady} onClick={play}>
            <i className="fa fa-play" />
          </TextButton>
          <TextButton disabled={!isReady} onClick={pause}>
            <i className="fa fa-pause" />
          </TextButton>
          <TextButton disabled={!isReady} onClick={stop}>
            <i className="fa fa-stop" />
          </TextButton>
        </div>
        <span className="duration">{formatSeconds(Math.floor(duration))}</span>
      </PlayerControlsContainer>
    </MediaPlayerContainer>
  );
};
export default MediaControls;
