import { updateDoc } from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import { mapRegularLessonTimelineToActivity } from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);
  const { getPrivateLessonsByIds, getUserActivityById } =
    useGlobalFirebaseFns();

  const withdrawPLAndActivity = async (withdrawalObj, lessonId) => {
    try {
      if (!withdrawalObj || !lessonId) return;
      const {
        withdrawal: { withdrawalDate },
      } = withdrawalObj;

      await updateDoc(firebase.PrivateLesson(lessonId), withdrawalObj);
      await updateDoc(firebase.userActivity(lessonId), {
        canceledAt: withdrawalDate,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const requestSummerBreakWithActivity = async (summerBreakObj, lessonId) => {
    try {
      if (!summerBreakObj || !lessonId) return;
      const lesson = (await getPrivateLessonsByIds([lessonId]))?.[0];
      const activity = await getUserActivityById(lessonId);
      if (!lesson || !activity) return;

      const { breakStart } = summerBreakObj;

      const updatedTimeline = lesson.timeline.map((timelineObj, i) => {
        if (i === lesson.timeline.length - 1) {
          return {
            ...timelineObj,
            lastDate: breakStart,
          };
        } else {
          return timelineObj;
        }
      });

      const currentSummerBreaks = lesson.summerBreaks || [];
      const updatedSummerBreaks = [...currentSummerBreaks, summerBreakObj];
      const updateObj = {
        summerBreaks: updatedSummerBreaks,
        timeline: updatedTimeline,
      };

      const canceledRange = {
        start: breakStart,
      };
      const updatedActivityTimeline = updatedTimeline.map((timelineObj) =>
        mapRegularLessonTimelineToActivity(timelineObj)
      );
      const currentCanceledRanges = activity.canceledRanges || [];
      const updatedCanceledRanges = [...currentCanceledRanges, canceledRange];
      const activityUpdateObj = {
        canceledRanges: updatedCanceledRanges,
        timeline: updatedActivityTimeline,
      };

      console.log({ updateObj, activityUpdateObj });
      await updateDoc(firebase.PrivateLesson(lessonId), updateObj);
      await updateDoc(firebase.userActivity(lessonId), activityUpdateObj);
    } catch (err) {
      console.log(err);
    }
  };
  return { withdrawPLAndActivity, requestSummerBreakWithActivity };
};

export default useFirebaseFns;
