import { UserRole } from "./UserRoleEnum";

// in bytes
const _500MB_ = 500000000;
const _2GB_ = 2000000000;
const _50GB_ = 50000000000;

export const usersDefaultStorageCapacity = {
  [UserRole.STUDENT]: _500MB_,
  [UserRole.PARENT]: _500MB_,
  [UserRole.TEACHER]: _2GB_,
  [UserRole.ADMIN]: _50GB_,
  [UserRole.SUPER_ADMIN]: _50GB_,
};
