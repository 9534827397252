import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useParentActions = ({ parentId, onSuccess = () => {} }) => {
  const {
    updateUserAuth,
    getUserAuthInfo,
    updateUserInfo,
    permaDeleteUserAccount,
  } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);
  const [userAuthInfo, setUserAuthInfo] = useState(null);

  async function toggleParentLoginStatus(isCurrentlyDisabled) {
    if (!parentId) return;
    try {
      setLoadingAction(true);

      const updateObj = {
        disabled: isCurrentlyDisabled ? false : true,
      };
      await updateUserAuth(parentId, updateObj);
      toast.success("Done Successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  const deleteUserPermanently = async () => {
    try {
      if (!parentId) {
        toast.error("Failed to delete user");
        return;
      }
      setLoadingAction(true);
      await permaDeleteUserAccount(parentId);
      toast.success("Deleted Successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingAction(true);
        const authInfo = await getUserAuthInfo(parentId);

        setUserAuthInfo(authInfo);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingAction(false);
      }
    };
    fetchInitialData();
  }, []);

  const isLoginDisabledUser = useMemo(() => {
    return !!userAuthInfo?.disabled;
  }, [userAuthInfo]);

  return {
    loadingAction,
    toggleParentLoginStatus,
    isLoginDisabledUser,
    deleteUserPermanently,
  };
};

export default useParentActions;
