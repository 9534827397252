import { documentId, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import {
  isDeclinedAbsence,
  isPendingAbsence,
} from "src/constants/absenceTypes";
import { parseUserAvailableDayDates } from "src/utils/firebaseDatesParserFns";
import {
  getFirebaseDoc,
  parseFirebaseDoc,
} from "../../../../../utils/getFirebaseDoc";
import { chunkArrayInGroups } from "../../../../../utils/helpers";
import { FirebaseContext } from "../../../../Firebase";
import { studentTypes } from "../../constants";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getInstruments = async () => {
    try {
      const instrumentsSnap = await getDocs(firebase.instruments());
      const instruments = getFirebaseDoc(instrumentsSnap);
      return instruments;
    } catch (err) {
      console.log(err);
    }
  };
  const getPrograms = async () => {
    try {
      const programsSnap = await getDocs(firebase.programs());
      const programs = getFirebaseDoc(programsSnap);
      return programs;
    } catch (err) {
      console.log(err);
    }
  };
  const getStudios = async () => {
    try {
      const studiosSnap = await getDocs(firebase.studios());
      const studios = getFirebaseDoc(studiosSnap);
      return studios;
    } catch (err) {
      console.log(err);
    }
  };
  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = getFirebaseDoc(locationsSnap);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const getStudentPLs = async (studentId) => {
    try {
      const privateLessonsQuery = query(
        firebase.PrivateLessons(),
        where("studentId", "==", studentId)
      );
      const privateLessonsSnapshot = await getDocs(privateLessonsQuery);
      const privateLessons = getFirebaseDoc(privateLessonsSnapshot);
      return privateLessons;
    } catch (err) {
      console.log(err);
    }
  };
  const getStudentTLs = async (studentId) => {
    try {
      const trialLessonsQuery = query(
        firebase.trialLessons(),
        where("studentId", "==", studentId)
      );
      const trialLessonsSnapshot = await getDocs(trialLessonsQuery);
      const trialLessons = getFirebaseDoc(trialLessonsSnapshot);
      return trialLessons;
    } catch (err) {
      console.log(err);
    }
  };
  const getStudentMakeupLessons = async (studentId) => {
    try {
      const makeupLessonsQuery = query(
        firebase.makeupLessons(),
        where("studentId", "==", studentId)
      );
      const makeupLessonsSnapshot = await getDocs(makeupLessonsQuery);
      const makeupLessons = getFirebaseDoc(makeupLessonsSnapshot);
      return makeupLessons;
    } catch (err) {
      console.log(err);
    }
  };

  // Takes an array of teachers ids , returns those teachers from firebase (supports more than 10 teachers)
  const getTeachersByIds = async (teachersIds) => {
    if (!teachersIds?.length) return {};
    try {
      const teachersIdsChunks = chunkArrayInGroups(teachersIds, 10);
      let requests = [];
      teachersIdsChunks.forEach((teachersIdsArray) => {
        const teachersQuery = query(
          firebase.users(),
          where(documentId(), "in", teachersIdsArray)
        );
        const req = getDocs(teachersQuery);
        requests.push(req);
      });
      const teachersSnapshotsChunks = await Promise.all(requests);
      const teachersChunks = teachersSnapshotsChunks.map((teachersSnapshot) =>
        getFirebaseDoc(teachersSnapshot)
      );
      const teachers = teachersChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      return teachers;
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  const getTeachersAvailableDays = async (teachersIds) => {
    if (!teachersIds?.length) return {};
    try {
      const teachersIdsChunks = chunkArrayInGroups(teachersIds, 10);
      let requests = [];
      teachersIdsChunks.forEach((teachersIdsArray) => {
        const availableDaysQuery = query(
          firebase.usersAvailableDays(),
          where(documentId(), "in", teachersIdsArray)
        );
        const req = getDocs(availableDaysQuery);
        requests.push(req);
      });
      const availableDaysSnapshotsChunks = await Promise.all(requests);
      const availableDaysChunks = availableDaysSnapshotsChunks.map(
        (availableDaysSnapshot) => parseFirebaseDoc(availableDaysSnapshot.docs)
      );
      const availableDays = availableDaysChunks.flat();
      const availableDaysWithDates = availableDays.map((day) =>
        parseUserAvailableDayDates(day)
      );

      return availableDaysWithDates;
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  const getTeachersMakeUpOpenings = async (teachersIds) => {
    if (!teachersIds?.length) return [];

    try {
      const teachersIdsChunks = chunkArrayInGroups(teachersIds, 10);
      let requests = [];
      teachersIdsChunks.forEach((teachersIdsArray) => {
        const makeupOpeningsQuery = query(
          firebase.makeupOpenings(),
          where("userId", "in", teachersIdsArray)
        );
        const req = getDocs(makeupOpeningsQuery);
        requests.push(req);
      });
      const openingsSnapshotsChunks = await Promise.all(requests);
      const openingsChunks = openingsSnapshotsChunks.map((openingSnap) =>
        parseFirebaseDoc(openingSnap.docs)
      );
      return openingsChunks.flat();
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const getSchoolYears = async () => {
    try {
      const schoolYearsSnap = await getDocs(firebase.getSchoolYears());
      const schoolYears = parseFirebaseDoc(schoolYearsSnap.docs);
      return schoolYears;
    } catch (err) {
      console.log(err);
    }
  };

  const getAbsencesByTeacherIds = async (teacherIds) => {
    try {
      if (!teacherIds?.length) return [];

      const teacherIdsChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdsChunks.forEach((teacherIdsArray) => {
        const absencesQuery = query(
          firebase.absences(),
          where("teacherId", "in", teacherIdsArray)
        );
        const req = getDocs(absencesQuery);
        requests.push(req);
      });
      const absencesSnapshotsChunks = await Promise.all(requests);
      const absencesChunks = absencesSnapshotsChunks.map((absencesSnapshot) =>
        parseFirebaseDoc(absencesSnapshot.docs)
      );
      const teacherAbsences = absencesChunks
        .flat()
        .filter(
          ({ status }) =>
            !isPendingAbsence(status) && !isDeclinedAbsence(status)
        );

      return teacherAbsences;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    getInstruments,
    getLocations,
    getStudios,
    getPrograms,
    getStudentPLs,
    getStudentTLs,
    getTeachersByIds,
    getTeachersAvailableDays,
    getStudentMakeupLessons,
    getSchoolYears,
    getAbsencesByTeacherIds,
    getTeachersMakeUpOpenings,
  };
};

export default useFirebaseFns;
