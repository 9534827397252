import { useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { InfoItem } from "../styled";
import { Divider } from "antd";

const StudentApplicantInfo = ({ applicant }) => {
  const [teacher, setTeacher] = useState(null);
  const { getTeachersByIds } = useFirebaseFns();

  useEffect(() => {
    (async () => {
      if (applicant?.selectedTeacherId) {
        const teacherResponse = await getTeachersByIds([
          applicant?.selectedTeacherId,
        ]);

        setTeacher(teacherResponse[0]);
      }
    })();
  }, []);

  return (
    <>
      <InfoItem capitalize>
        <span>Instruments:</span> {applicant?.instruments?.join(", ") || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem capitalize>
        <span>Package:</span> {applicant?.package || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem capitalize>
        <span>Program:</span> {applicant?.program || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem capitalize>
        <span>Class type:</span> {applicant?.classType || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem capitalize>
        <span>Selected teacher:</span> {teacher?.fullName || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem capitalize>
        <span>Selected class days:</span>{" "}
        {applicant?.selectedClassDays?.join(", ") || "N/A"}
      </InfoItem>{" "}
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Selected class time:</span>{" "}
        {applicant?.selectedClassTime || "N/A"}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Paid:</span> {applicant?.paid ? "Yes" : "No"}
      </InfoItem>
    </>
  );
};

export default StudentApplicantInfo;
