import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import { CustomLabel, CustomSelectField } from "../../styled";

const WeekDaySelect = ({ teacher, formInputs, handleFormInputsChange }) => {
  // to get the teacher days weekdays, we consider both the startDate weekday and endDate weekday
  const teacherDaysWeekDays = teacher?.teacherAvailableDays
    ?.map((day) => {
      const { startDate, endDate } = day;
      const startDateWeekDay = moment(startDate).day();
      const endDateWeekDay = moment(endDate).day();

      return [startDateWeekDay, endDateWeekDay];
    })
    ?.flat();
  const uniqueWeekDays = [...new Set(teacherDaysWeekDays || [])];

  return (
    <>
      <CustomLabel className="mb-3">Available Days</CustomLabel>
      <CustomSelectField
        disabled={!formInputs.teacherId}
        name="day"
        value={formInputs.day}
        onChange={handleFormInputsChange}
        width="100%"
      >
        <option value="" disabled>
          Day
        </option>
        {uniqueWeekDays.map((day, i) => {
          const dayName = weekDays.find(({ id }) => id === parseInt(day)).name;

          return (
            <option key={day} value={day}>
              {dayName || "N/A"}
            </option>
          );
        }) || []}
      </CustomSelectField>
    </>
  );
};

export default WeekDaySelect;
