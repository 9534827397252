import {
  AttachmentsItemContainer,
  StyledLink,
  TextButton,
} from "../../../styled-components/teacherLibraryStyledComponents";
import LibraryLabeledCheckbox from "../../common/LibraryLabeledCheckbox";
import { useContext, useEffect, useState } from "react";
import {
  ActiveTabContext,
  AdminUserContext,
  LibraryContext,
} from "../../common/libraryContext";
import { FirebaseContext } from "../../../../Firebase";
import { getDoc } from "firebase/firestore";
import { isLibraryItemSharedWithUser } from "src/utils/helpers";

const AttachmentsSingleItem = ({
  user,
  item,
  checked,
  showActions,
  showItem,
}) => {
  const {
    id,
    title,
    userId,
    fileId,
    attachmentType,
    isDeleted,
    downloadUrl,
    sharedWith = [],
  } = item;
  const isSharedItem = isLibraryItemSharedWithUser(user.uid, item);

  const { select, shareOne, deleteOne, downloadOne, openRenameItemModal } =
    useContext(LibraryContext);
  const firebase = useContext(FirebaseContext);
  const { activeTab, isAdmin, isTeacher } = useContext(ActiveTabContext);

  const isAbleToRenameItem = user.uid === userId || isAdmin;

  const [userDetails, setUserDetails] = useState({});
  useEffect(async () => {
    if (userId !== "school") {
      const user = await getDoc(firebase.user(userId));
      const userData = user.data();
      setUserDetails(userData);
    }
  }, []);
  const addedBy = () => {
    if (isAdmin) {
      if (userId === "school") {
        return (
          <span
            className={
              "bg-light px-2 py-1 text-dark badge rounded border border-dark border-2 ms-2"
            }
          >
            <strong>School</strong>
          </span>
        );
      } else {
        if (userDetails?.fullName || userDetails?.fullName) {
          return (
            <span
              className={
                "bg-light px-2 py-1 text-dark badge rounded border border-dark border-2 ms-2"
              }
            >
              <strong>{userDetails?.fullName || userDetails?.fullName}</strong>
            </span>
          );
        }
        return null;
      }
    } else if (isSharedItem) {
      return (
        <span
          className={
            "px-2 py-1 text-dark badge rounded border border-dark border-2 ms-2"
          }
        >
          <strong style={{ color: "#5a62ffc7" }}>shared with you</strong>
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <AttachmentsItemContainer className={"d-flex"}>
      <div className={"d-flex align-items-center"}>
        <LibraryLabeledCheckbox
          id={id}
          onChange={() => select(id)}
          checked={checked}
        />
      </div>

      <div
        //  className={"d-flex justify-content-between w-100"}
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "6fr 2fr",
        }}
      >
        <TextButton
          truncate
          className={`ms-2 text-start ${
            isDeleted ? "text-danger text-decoration-line-through" : ""
          }`}
          style={{ width: "100%" }}
          onClick={() => showItem(id)}
        >
          {title}
          {addedBy()}
        </TextButton>
        {showActions && (
          <div
            style={{ marginLeft: "auto" }}
            className="d-flex align-items-center"
          >
            {activeTab === "recommendationLinks" ? (
              <StyledLink href={downloadUrl}>
                <i className="fa fa-external-link-alt" />
              </StyledLink>
            ) : (
              <TextButton
                className={""}
                onClick={() => {
                  downloadOne(id);
                }}
              >
                <i className="fa fa-cloud-download-alt" />
              </TextButton>
            )}
            {isAbleToRenameItem && (
              <TextButton
                className={"ms-2"}
                onClick={() => openRenameItemModal(id)}
              >
                <i className="fa fa-pen" />
              </TextButton>
            )}
            {(isAdmin || isTeacher) && (
              <TextButton className={"ms-2"} onClick={() => shareOne(id)}>
                <i className="fa fa-share" />
              </TextButton>
            )}
            <TextButton className={"ms-2"} onClick={() => deleteOne(id)}>
              <i className="fa fa-trash" />
            </TextButton>
          </div>
        )}
      </div>
    </AttachmentsItemContainer>
  );
};
export default AttachmentsSingleItem;
