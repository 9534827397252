import { useEffect, useState } from "react";
import TeamMemberCardIconsWrapper from "../TeamMemberCardIconsWrapper";
import "./style.css";
import { Avatar } from "antd";
import { CardHeader, SocialIcons } from "../styled";

const TeamMemberHeaderWrapper = ({
  image,
  name,
  profession,
  specialization,
  raisingTalent,
  social,
  isModal,
}) => {
  const [socialUrlSelected, setSocialUrlSelected] = useState(null);

  useEffect(() => {
    if (socialUrlSelected) {
      window.location.href = socialUrlSelected;
    }
  }, [socialUrlSelected]);

  return (
    <CardHeader>
      <Avatar src={image} alt={name} size={isModal ? 180 : 120} />
      <h2 style={raisingTalent ? { marginTop: "35px" } : {}}>{name}</h2>
      <p>{profession}</p>
      <SocialIcons>
        {social &&
          social.map((socialObg) => {
            const { Icon, url, name } = socialObg;
            return (
              <Icon
                key={name}
                className="socialIcon"
                onClick={() => setSocialUrlSelected(url)}
                title={name}
              />
            );
          })}
      </SocialIcons>

      {isModal && specialization && specialization[0] && (
        <TeamMemberCardIconsWrapper
          specialization={specialization}
          isModal={true}
        />
      )}
    </CardHeader>
  );
};

export default TeamMemberHeaderWrapper;
