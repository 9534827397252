import { DropdownMenu } from "reactstrap";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

const { default: styled } = require("styled-components");

export const CustomDropdownMenu = styled(DropdownMenu)`
  width: 500px;
  box-shadow: 0px 0px 7px 0px #e1e1e1;
  background-color: #fdfdfd;
  max-height: 500px;

  overflow-y: auto;

  @media (max-width: ${ScreenSize.SM}px) {
    width: 350px;
  }
`;
export const NotificationRowContainer = styled.div`
  padding: 15px;
  background-color: ${({ bgColor }) => bgColor || "auto"};
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const PrimaryText = styled.p`
  margin: 0;
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
`;
export const SecondaryText = styled.p`
  margin: 0;
  color: #939393;
`;
export const UnreadNotificationDot = styled.span`
  width: 10px;
  height: 10px;
  background-color: #681e46;
  border-radius: 50%;
`;
