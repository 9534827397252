import ReactECharts from "echarts-for-react";

const colorsPallete = [
  "#26C6DA",
  "#FFCA28",
  "#5C6BC0",
  "#D4E157",
  "#c426da",
  "#da2629",
  "#26da71",
];

const PaymentChart = ({
  seriesDataList = [],
  seriesName = "Access From",
  seriesSubtitle,
}) => {
  return (
    <ReactECharts
      style={{ height: 500, overflow: "visible" }}
      option={{
        title: {
          text: "Activity Chart",
          ...(seriesSubtitle && { subtext: seriesSubtitle }),
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          bottom: 0,
        },
        grid: {
          left: 10,
          containLabel: false,
          bottom: 10,
          top: 10,
          right: 30,
        },

        color: colorsPallete,

        series: [
          {
            name: seriesName,
            type: "pie",
            radius: "70%",
            data: seriesDataList,
            colorBy: "data",
            top: 20,

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },

            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "inner",
                  formatter: function (params) {
                    return !params?.name?.toLowerCase()?.includes("sign")
                      ? `${params.value} Hrs`
                      : params?.value;
                  },
                },
                labelLine: {
                  show: true,
                },
              },
            },
          },
        ],
      }}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
};
export default PaymentChart;
