import React, { createContext } from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { Provider } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import UserStore from "./stores/UserStore";
import Layout from "./views/layout";
import Firebase, { FirebaseContext } from "./components/Firebase";
import ErrorBoundary from "./views/ErrorBoundary";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.css";
import { toast } from "react-toastify";
import ParentDashboardProvider from "./components/Dashboard/ParentDashboard/ParentDashboardProvider";
import TimezoneContextProvider from "./contexts/TimezoneContext";
import SidebarContextProvider from "./contexts/SidebarContext";
import { setConfiguredTimezone } from "./utils/helpers";
// required for react-tooltip to work
import "react-tooltip/dist/react-tooltip.css";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "./constants/constantVariables";
import { ConfigProvider } from "antd";

// prepares the global app moment timezone
setConfiguredTimezone();

const App = () => {
  return (
    <BrowserRouter>
      <Provider UserStore={UserStore}>
        <TimezoneContextProvider user={UserStore.user}>
          <FirebaseContext.Provider value={new Firebase()}>
            <PayPalScriptProvider
              options={{
                clientId: PAYPAL_CLIENT_ID,
                currency: "USD",
                vault: true,
                // intent: "capture",
                // "data-client-token": "abc123xyz==",
              }}
            >
              <SidebarContextProvider>
                <ParentDashboardProvider>
                  <Layout>
                    <ErrorBoundary>
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#681e46",
                            borderRadius: "1.3rem",
                            fontFamily: "Ubuntu, sans-serif",
                          },
                        }}
                      >
                        {" "}
                        <Routes />
                      </ConfigProvider>
                    </ErrorBoundary>
                  </Layout>
                </ParentDashboardProvider>
              </SidebarContextProvider>
            </PayPalScriptProvider>
          </FirebaseContext.Provider>
        </TimezoneContextProvider>
      </Provider>
    </BrowserRouter>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));

const config = {
  onUpdate: () => {
    toast.success(
      "WOW! New content is available and will be used when all tabs for this page are closed!",
      {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(config);
