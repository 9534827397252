import { useState } from "react";
import SubMenu from "antd/es/menu/SubMenu";
import { Menu } from "antd";
import SignUpModal from "../../SignUpModal";

const CareerDropdown = () => {
  const [teacherModalOpen, setTeacherModalOpen] = useState(false);
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);

  const teacherModalToggle = () =>
    setTeacherModalOpen((prevState) => !prevState);

  const adminModalToggle = () => setAdminModalOpen((prevState) => !prevState);

  const partnerModalToggle = () =>
    setPartnerModalOpen((prevState) => !prevState);

  return (
    <>
      <SubMenu
        key="career-menu"
        title="Career sign up"
        popupClassName="guest-menu"
      >
        <Menu.Item key="teacher" onClick={teacherModalToggle}>
          Teacher
        </Menu.Item>
        <Menu.Item key="admin" onClick={adminModalToggle}>
          Administration
        </Menu.Item>
        <Menu.Item key="partner" onClick={partnerModalToggle}>
          Partner
        </Menu.Item>
      </SubMenu>
      <SignUpModal
        type="teacher"
        isOpen={teacherModalOpen}
        toggle={teacherModalToggle}
      />
      <SignUpModal
        type="administration"
        isOpen={adminModalOpen}
        toggle={adminModalToggle}
      />
      <SignUpModal
        type="partner"
        isOpen={partnerModalOpen}
        toggle={partnerModalToggle}
      />
    </>
  );
};

export default CareerDropdown;
