import { useMemo, useState } from "react";
import useScheduleData from "src/components/Dashboard/Schedule/TeacherSchedule/hooks/useScheduleData";
import moment from "moment";
import store from "src/stores/UserStore";
import {
  excludeAbsenceFromPLs,
  excludeSchoolBreaksFromRecurringEvents,
  excludeTeacherBreaksFromAbsences,
  filterSummerBreaksFromPLs,
  filterWithdrawalFromPLs,
  generateRecurringEvents,
  joinSimilarEvents,
  mapAppointmentToEvent,
  mapBreakToEvent,
  mapGroupClassToEvents,
  mapMakeupLessonToEvent,
  mapPackagePrivateLessonToEvents,
  mapSchoolBreakToScheduleEvent,
  mapTLToEvent,
} from "src/components/Dashboard/Schedule/helperFns";
import {
  eventsMap,
  isPackagePrivateLesson,
  isRecurringBreakEvent,
} from "src/constants/eventsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import { isCanceledTrialLesson } from "src/constants/trialLessonStatuses";
import {
  checkIfCanceledRecurringActivity,
  updatedMomentNow,
} from "src/utils/helpers";

const useScheduleReminder = () => {
  const { user } = store;
  const { initialData, selectedLocationId, combinedSchoolBreaks } =
    useScheduleData(user);

  const {
    instruments,
    teacherPLs,
    teacherTLs,
    teacherMakeupLessons,
    teacherAppointments,
    absences,
    breaks,
    groupClasses,
  } = initialData;

  const [currentDate] = useState(updatedMomentNow());

  const regularPrivateLessonsRecurringEvents = useMemo(() => {
    const recurringEvents = generateRecurringEvents({
      docs: teacherPLs.filter(({ type }) => !isPackagePrivateLesson(type)),
      startDateAccessor: "startDate",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "teacherId",
      eventCode: eventsMap.privateLesson.code,
      instruments,
      resourceIds: [user?.uid],
    });

    const eventsInLocation = recurringEvents.filter(
      ({ currentTimeline }) =>
        selectedLocationId === currentTimeline?.locationId
    );

    return eventsInLocation;
  }, [teacherPLs, selectedLocationId, currentDate]);

  const packagePrivateLessonsEvents = useMemo(() => {
    const packageItemsEvents = teacherPLs
      .filter(({ type }) => isPackagePrivateLesson(type))
      .map((lesson) =>
        mapPackagePrivateLessonToEvents({
          packageLesson: lesson,
          selectedTeachers: [user?.uid],
          role: UserRole.TEACHER,
          instruments,
        })
      );
    const flatPackageLessonEvents = packageItemsEvents.flat();

    const eventsInLocation = flatPackageLessonEvents.filter(
      ({ locationId }) => selectedLocationId === locationId
    );

    return eventsInLocation;
  }, [teacherPLs, selectedLocationId]);

  const combinedPrivateLessonsEvents = useMemo(() => {
    return [
      ...regularPrivateLessonsRecurringEvents,
      ...packagePrivateLessonsEvents,
    ];
  }, [regularPrivateLessonsRecurringEvents, packagePrivateLessonsEvents]);

  const filteredPLsRecurringEvents = useMemo(() => {
    const filteredAbsencesFromPLs = excludeAbsenceFromPLs(
      combinedPrivateLessonsEvents,
      absences
    );

    const filteredWithdrawalsFromPLs = filterWithdrawalFromPLs(
      filteredAbsencesFromPLs
    );

    const filteredSummerBreaksFromPLs = filterSummerBreaksFromPLs(
      filteredWithdrawalsFromPLs
    );
    return filteredSummerBreaksFromPLs;
  }, [combinedPrivateLessonsEvents, absences]);
  const PLsRecurringEventsWithoutSchoolBreaks = useMemo(
    () =>
      excludeSchoolBreaksFromRecurringEvents(
        filteredPLsRecurringEvents,
        combinedSchoolBreaks
      ),
    [filteredPLsRecurringEvents, combinedSchoolBreaks]
  );
  const trialLessonsEvents = useMemo(
    () =>
      teacherTLs
        .filter(({ status }) => !isCanceledTrialLesson(status))
        .map((lesson) => mapTLToEvent(lesson, UserRole.TEACHER, instruments)),
    [teacherTLs, instruments]
  );
  const makeupLessonsEvents = useMemo(
    () =>
      teacherMakeupLessons.map((lesson) =>
        mapMakeupLessonToEvent(lesson, instruments)
      ),
    [teacherMakeupLessons, instruments]
  );
  const appointmentsEvents = useMemo(
    () =>
      teacherAppointments.map((appointment) =>
        mapAppointmentToEvent(appointment)
      ),
    [teacherAppointments]
  );

  const schoolBreaksCalendarEvents = useMemo(
    () =>
      combinedSchoolBreaks.map((schoolBreak) =>
        mapSchoolBreakToScheduleEvent(schoolBreak)
      ),
    [combinedSchoolBreaks]
  );

  const oneTimeBreaksEvents = useMemo(
    () =>
      breaks
        .filter(({ type }) => !isRecurringBreakEvent(type))
        .map((userBreak) => mapBreakToEvent(userBreak)),
    [breaks]
  );
  const recurringBreaksEvents = useMemo(() => {
    const recurringBreaks = breaks.filter(({ type }) =>
      isRecurringBreakEvent(type)
    );

    const recurringEvents = generateRecurringEvents({
      docs: recurringBreaks,
      startDateAccessor: "date",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "userId",
      eventCode: eventsMap.recurringBreak.code,
    });

    const filteredRecurringBreaks = recurringEvents.filter(
      ({ start, canceledAt, canceledDates }) => {
        const isCanceled = checkIfCanceledRecurringActivity({
          occuranceDate: start,
          canceledAt,
          canceledDates,
        });
        return !isCanceled;
      }
    );
    return filteredRecurringBreaks;
  }, [breaks, currentDate]);

  const allBreaksEventsWithoutAbsences = useMemo(
    () =>
      excludeTeacherBreaksFromAbsences(
        [...oneTimeBreaksEvents, ...recurringBreaksEvents],
        absences
      ),
    [oneTimeBreaksEvents, recurringBreaksEvents, absences]
  );

  const allBreaksWithoutSchoolBreaks = useMemo(
    () =>
      excludeSchoolBreaksFromRecurringEvents(
        allBreaksEventsWithoutAbsences,
        combinedSchoolBreaks
      ),
    [allBreaksEventsWithoutAbsences, combinedSchoolBreaks]
  );

  const groupClassesEvents = useMemo(() => {
    const events = groupClasses
      .map((groupClass) =>
        mapGroupClassToEvents({
          groupClass,
          selectedTeachers: [user?.uid],
          role: UserRole.SUPER_ADMIN,
        })
      )
      .flat();

    return events;
  }, [groupClasses, user?.uid]);

  let selectedEvents = useMemo(
    () => [
      ...PLsRecurringEventsWithoutSchoolBreaks,
      ...trialLessonsEvents,
      ...makeupLessonsEvents,
      ...appointmentsEvents,
      ...schoolBreaksCalendarEvents,
      ...allBreaksWithoutSchoolBreaks,
      ...groupClassesEvents,
    ],
    [
      PLsRecurringEventsWithoutSchoolBreaks,
      trialLessonsEvents,
      makeupLessonsEvents,
      appointmentsEvents,
      schoolBreaksCalendarEvents,
      allBreaksWithoutSchoolBreaks,
      groupClassesEvents,
    ]
  );

  selectedEvents = useMemo(
    () => joinSimilarEvents(selectedEvents),
    [selectedEvents]
  );

  const isSameDay = (date1, date2) => {
    return moment(date1).isSame(moment(date2), "date");
  };

  const todayEvents = useMemo(() => {
    return selectedEvents
      ?.filter((event) => {
        const eventStart = moment(event?.start);

        return isSameDay(eventStart, currentDate);
      })
      ?.map((event) => {
        const start = moment(event?.start);
        const end = moment(event?.end);

        return {
          ...event,
          start,
          end,
        };
      });
  }, [selectedEvents]);

  return {
    todayEvents,
    selectedEvents,
  };
};

export default useScheduleReminder;
