import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { paymentPeriodFilters } from "src/constants/paymentPeriodFilters";
import { paymentsMap, paymentTypes } from "src/constants/paymentsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  capitalizeFirstLetter,
  momentPayment,
  updatedMomentNow,
} from "src/utils/helpers";

const paymentTypesIncludedInChart = [
  paymentTypes.ADMINISTRATIVE,
  paymentTypes.EA,
  paymentTypes.REGULAR_CLASS,
  paymentTypes.RESERVATION,
  paymentTypes.SDC,
  paymentTypes.TRAINING,
  paymentTypes.SALARY,
  paymentTypes.EXTENDED_HOURS,
  paymentTypes.SIGN_UPS,
];
const initialDataInitialValues = {
  userPaymentsList: [],
};
const usePayments = ({ userId }) => {
  const { getUserPayments } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentPaymentId, setCurrentPaymentId] = useState("");

  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [customStart, setCustomStart] = useState("");
  const [customEnd, setCustomEnd] = useState("");

  function handleSelectedMonthChange(option) {
    if (!option) setSelectedMonth("");
    setSelectedMonth(option.value);
  }

  // fetches the initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!userId) return;
        setLoadingInitialData(true);

        const [selectedUserPayments] = await Promise.all([
          getUserPayments(userId, { orderByDate: true }),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          userPaymentsList: selectedUserPayments,
        }));
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [userId]);

  useEffect(() => {
    setSelectedMonth("");
    setCustomStart("");
    setCustomEnd("");
  }, [selectedFilter]);

  const currentPaymentObj = useMemo(() => {
    const paymentObj = initialData.userPaymentsList.find(
      ({ id }) => id === currentPaymentId
    );
    return paymentObj;
  }, [currentPaymentId, initialData]);

  // for data that appears on the chart
  const dataSetList = useMemo(() => {
    if (!initialData.userPaymentsList?.length) return [];

    let paymentTypesAggregatorObj = {};

    loop1: for (const paymentObj of initialData.userPaymentsList) {
      const { startDate, endDate, payments } = paymentObj;

      // ignores prev years data
      if (
        moment(startDate).isBefore(updatedMomentNow(), "years") &&
        moment(endDate).isBefore(updatedMomentNow(), "years")
      ) {
        continue loop1;
      }

      loop2: for (const payment of payments) {
        const {
          type,
          fractionPerUnit,
          calculatedBy,
          calculatedByAmount,
          totalPaid,
        } = payment;

        if (!paymentTypesIncludedInChart.includes(type)) {
          continue loop2;
        }

        const currentPaymentMap = paymentsMap.find(
          (map) =>
            map.paymentType === type &&
            map.fractionPerUnit === fractionPerUnit &&
            map.paymentCalculationType === calculatedBy
        );

        if (!currentPaymentMap) {
          toast.error(`Issue with dataSetList with type ${type}`);
          continue loop2;
        }

        const hoursValue = calculatedByAmount * fractionPerUnit;
        paymentTypesAggregatorObj = {
          ...paymentTypesAggregatorObj,
          [currentPaymentMap.paymentName]:
            (paymentTypesAggregatorObj[currentPaymentMap.paymentName] || 0) +
            hoursValue,
        };
      }
    }

    const dataSet = Object.keys(paymentTypesAggregatorObj).map((key) => {
      const dataObj = {
        value: paymentTypesAggregatorObj[key],
        name: capitalizeFirstLetter(key),
      };
      return dataObj;
    });
    return dataSet?.filter(({ name }) => name !== "Salary");
  }, [initialData]);

  const filteredPaymentsList = useMemo(() => {
    let filtered = [];
    if (!selectedFilter) {
      filtered = initialData.userPaymentsList.slice(0, 4);
    } else {
      filtered = initialData.userPaymentsList.filter((paymentObj) => {
        const { startDate } = paymentObj;

        let pass;
        if (selectedFilter === paymentPeriodFilters.currentMonth.value) {
          pass = momentPayment().startOf("month").isSame(startDate, "month");
        } else if (selectedFilter === paymentPeriodFilters.lastMonth.value) {
          pass = momentPayment()
            .subtract(1, "month")
            .startOf("month")
            .isSame(startDate, "month");
        } else if (selectedFilter === paymentPeriodFilters.currentYear.value) {
          pass = momentPayment().startOf("year").isSame(startDate, "year");
        } else if (selectedFilter === paymentPeriodFilters.lastYear.value) {
          pass = momentPayment()
            .subtract(1, "year")
            .startOf("year")
            .isSame(startDate, "year");
        } else if (selectedFilter === paymentPeriodFilters.custom.value) {
          pass = momentPayment(startDate).isBetween(
            customStart,
            customEnd,
            null,
            "[]"
          );
        }

        return (
          pass &&
          (selectedMonth === "" ||
            selectedMonth === momentPayment(startDate).month())
        );
      });
    }

    return filtered;
  }, [selectedFilter, selectedMonth, customStart, customEnd, initialData]);

  return {
    initialData,
    loadingInitialData,
    currentPaymentObj,
    setCurrentPaymentId,
    dataSetList,
    filteredPaymentsList,
    selectedFilter,
    setSelectedFilter,
    selectedMonth,
    handleSelectedMonthChange,
    customStart,
    setCustomStart,
    customEnd,
    setCustomEnd,
  };
};

export default usePayments;
