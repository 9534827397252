import React, { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const usePermaDeleteStudent = ({ studentId, onSuccess, closeModal }) => {
  const { permaDeleteStudentUser } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);

  const handlePermaDeleteStudent = async () => {
    try {
      setLoadingAction(true);

      await permaDeleteStudentUser(studentId);
      toast.success("Student Permanently Deleted!");
      closeModal();
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };
  return { handlePermaDeleteStudent, loadingAction };
};

export default usePermaDeleteStudent;
