import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const GridContainer = styled.main`
  display: grid;
  grid-template-areas:
    "feed feed feed feed feed feed redbar redbar redbar redbar"
    "feed feed feed feed feed feed lessons lessons lessons lessons"
    "feed feed feed feed feed feed lessons lessons lessons lessons";
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 100px 360px 350px;
  margin-top: 20px;

  @media (max-width: ${ScreenSize.XL}px) {
    grid-template-areas:
      "redbar redbar redbar redbar"
      "lessons lessons lessons lessons"
      /* "calendar calendar calendar calendar " */
      "feed feed feed feed";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: unset;
  }
`;
export const GridItem = styled.section`
  grid-area: ${({ gridArea }) => gridArea || "none"};
  background-color: ${({ bgColor }) => bgColor || "none"};
`;
