import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useParentsData = () => {
  const { getParents, getStudentsByParentsEmails } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    parents: [],
    students: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const [currentStudentId, setCurrentStudentId] = useState("");
  const [currentParentId, setCurrentParentId] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [parents] = await Promise.all([getParents()]);

        const parentsEmails = parents.map(({ primaryEmail }) => primaryEmail);
        const students = await getStudentsByParentsEmails(parentsEmails);
        setInitialData({
          parents,
          students,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  // search for teachers by search term
  const displayedParents = useMemo(
    () =>
      searchTerm
        ? initialData.parents.filter((parent) => {
            return parent?.fullName
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase());
          })
        : initialData.parents,
    [searchTerm, initialData.parents]
  );

  const currentStudent = useMemo(() => {
    if (currentStudentId) {
      return initialData.students.find(({ id }) => id === currentStudentId);
    }
  }, [currentStudentId, initialData.students]);

  const currentParent = useMemo(() => {
    if (currentParentId) {
      return initialData.parents.find(({ id }) => id === currentParentId);
    }
  }, [currentParentId, initialData.parents]);

  return {
    initialData,
    loadingInitialData,
    refreshData,
    displayedParents,
    searchTerm,
    setSearchTerm,
    currentStudent,
    setCurrentStudentId,
    currentParent,
    setCurrentParentId,
  };
};

export default useParentsData;
