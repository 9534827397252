import styled from "styled-components";

export const NavButtonsContainer = styled.div`
  width: 400px;
`;

export const InnerContainer = styled.div`
  margin-top: 20px;
  height: 350px;
  overflow: auto;
  padding: 10px;
`;
export const CustomButtonsWrapper = styled.div`
  width: 35%;
  margin: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const CustomButton = styled.button`
  border: none;
  outline: none;
  background: #fff;
  color: ${({ primary }) => (primary ? "#681e46" : "grey")};
  font-weight: bold;
`;
