import React from "react";
import useAdminActions from "./hooks/useAdminActions";
import DropdownButton from "../../../../DropdownButton";

const AdminActions = ({ admin }) => {
  const { loadingAction, isLoginDisabledUser, toggleUserLoginStatus } =
    useAdminActions({
      adminId: admin?.id,
      onSuccess: () => {
        window.location.reload();
      },
    });

  const optionsList = [
    isLoginDisabledUser
      ? {
          title: "Turn on Platform Access",
          action: () => toggleUserLoginStatus(true),
        }
      : {
          title: "Turn off Platform Access",
          action: () => toggleUserLoginStatus(false),
        },
    // {
    //   title: "Delete User",
    //   action: () => {},
    // },
  ];
  return (
    <div className="text-end">
      <DropdownButton loadingAction={loadingAction} optionsList={optionsList} />
    </div>
  );
};

export default AdminActions;
