import styled from "styled-components";

const ProfileField = styled.div`
  &:not(:first-child) {
    margin-top: 64px;
  }
`;

const ElementTitle = styled.h5`
  display: flex;
  justify-content: space-between;
`;

const HintSpan = styled.span`
  font-size: 14px;
  color: #bbb;
`;

export { ElementTitle, ProfileField, HintSpan };
