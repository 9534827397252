import GuestDropdown from "./GuestDropdown";
import { DropdownWrapper } from "./styled";

const GuestMap = () => {
  return (
    <DropdownWrapper>
      <GuestDropdown />
    </DropdownWrapper>
  );
};

export default GuestMap;
