import React from "react";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import SchoolYearForm from "./SchoolYearForm";

const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const SchoolYearModal = ({ modalData }) => {
  const { isModalOpen, openModal, closeModal, toggleModal } = modalData;

  return (
    <Modal
      size="md"
      className="min-w-50  text-dark"
      centered
      isOpen={isModalOpen}
      toggle={toggleModal}
    >
      <ModalHeader
        close={<CloseIcon onClick={closeModal} />}
        className="px-5 pb-0 border-bottom-0 "
        toggle={toggleModal}
      >
        <span className="fs-3 fw-bold">School Year</span>
      </ModalHeader>
      <ModalBody className="px-5">
        <SchoolYearForm closeModal={closeModal} />
      </ModalBody>
    </Modal>
  );
};
export default SchoolYearModal;
