import { useEffect, useRef } from "react";
import "./style.css";

const ModalWrapper = ({ children, onClickToClose }) => {
  const myWrapper = useRef(null);
  const onClickHandler = (e) => {
    e.target === myWrapper.current && onClickToClose(e);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <div className="modalWrapper" onClick={onClickHandler} ref={myWrapper}>
      {children}
    </div>
  );
};

export default ModalWrapper;
