import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { groupClassEnrollmentTypes } from "src/constants/groupClassEnrollmentsConstants";
import {
  groupClassPaymentMethods,
  groupClassPaymentStudentTypes,
  groupClassPaymentTypes,
} from "src/constants/groupClassPaymentsConstants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getGroupClassClassesByFromDates,
  getUpcomingGroupClassLesson,
} from "src/utils/helpers";
import { enrollmentOptions } from "../../../constants";

const initialDataInitialValues = {
  directPaymentOption: null,
  scheduledPaymentOption: null,
  freePaymentOption: null,
  student: null,
  groupClass: null,
};

const useGroupClassPayment = ({
  user,
  studentId,
  studentType,
  groupClassId,
}) => {
  const {
    getPaymentOptionsByGroupClassId,
    getUserById,
    getGroupClassById,
    createGroupClassPayment,
    createGroupClassEnrollment,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedEnrollmentOption, setSelectedEnrollmentOption] = useState(
    enrollmentOptions.payNow.value
  );
  const [isEnrollNowPayLaterConfirmed, setIsEnrollNowPayLaterConfirmed] =
    useState(false);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const isDirectPaymentAvailable = useMemo(() => {
    const exists = !!initialData.directPaymentOption;
    return exists;
  }, [initialData]);
  const isScheduledPaymentAvailable = useMemo(() => {
    const exists = !!initialData.scheduledPaymentOption;
    return exists;
  }, [initialData]);
  const isFreePaymentAvailable = useMemo(() => {
    const exists = !!initialData.freePaymentOption;
    return exists;
  }, [initialData]);

  const isBothPaymentsOptionsAvailable = useMemo(
    () => isDirectPaymentAvailable && isScheduledPaymentAvailable,
    [isDirectPaymentAvailable, isScheduledPaymentAvailable]
  );

  const isEnrollNowPayLaterAvailable = useMemo(() => {
    // return studentType === groupClassPaymentStudentTypes.CURRENT_STUDENT;
    return true;
  }, [studentType]);

  const isPayNowEnrollmentOption = useMemo(() => {
    return selectedEnrollmentOption === enrollmentOptions.payNow.value;
  }, [selectedEnrollmentOption]);
  const isEnrollNowPayLaterEnrollmentOption = useMemo(() => {
    return (
      selectedEnrollmentOption === enrollmentOptions.enroll_now_pay_later.value
    );
  }, [selectedEnrollmentOption]);

  async function enrollStudent() {
    try {
      if (!user || !groupClassId || !studentId || !initialData.student) {
        toast.error("missing info");
        return;
      }

      if (!isFreePaymentAvailable && !isEnrollNowPayLaterConfirmed) {
        toast.error("please confirm the checkbox");
        return;
      }
      setIsSaving(true);

      // const upcomingClassDay = getUpcomingGroupClassLesson(
      //   initialData.groupClass.classes
      // );

      const enrollmentObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        groupClassId: groupClassId,
        studentId: studentId,
        studentType: initialData.student.role,
        enrollmentType: groupClassEnrollmentTypes.REGULAR, // regular would only need the groupCLassId , but makeup would need the specific classId as they makeup 1 time}
        enrollmentDate: new Date(),
      };

      console.log({ enrollmentObj });
      await createGroupClassEnrollment(enrollmentObj);

      toast.success("Enrolled successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSaving(false);
    }
  }
  async function subscriptionSuccessCallback(subscriptionID) {
    try {
      if (
        !user ||
        !groupClassId ||
        !studentId ||
        !initialData.student ||
        !subscriptionID
      ) {
        toast.error("missing info");
        return;
      }
      setIsSaving(true);

      // const upcomingClassDay = getUpcomingGroupClassLesson(
      //   initialData.groupClass.classes
      // );

      const enrollmentObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        groupClassId: groupClassId,
        studentId: studentId,
        studentType: initialData.student.role,
        enrollmentType: groupClassEnrollmentTypes.REGULAR, // regular would only need the groupCLassId , but makeup would need the specific classId as they makeup 1 time}
        enrollmentDate: new Date(),
      };

      const userPaymentOption = initialData.scheduledPaymentOption;
      const paymentObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        groupClassId: groupClassId,
        userId: studentId,
        userRole: initialData.student.role,
        paymentOptionId: userPaymentOption.id,
        paymentMethod: groupClassPaymentMethods.PAYPAL,
        paypalSubscriptionID: subscriptionID,
      };

      console.log({ paymentObj, enrollmentObj });
      await Promise.all([
        paymentObj ? createGroupClassPayment(paymentObj) : null,
        createGroupClassEnrollment(enrollmentObj),
      ]);

      toast.success("Enrolled successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!studentId || !groupClassId) return;

        setLoadingInitialData(true);

        const [paymentOptions, student, groupClass] = await Promise.all([
          getPaymentOptionsByGroupClassId(groupClassId),
          getUserById(studentId),
          getGroupClassById(groupClassId),
        ]);
        const directPaymentOption = paymentOptions.find(
          (paymentOption) =>
            paymentOption.forStudentType === studentType &&
            paymentOption.paymentType === groupClassPaymentTypes.ONE_TIME
        );

        const scheduledPaymentOption = paymentOptions.find(
          (paymentOption) =>
            paymentOption.forStudentType === studentType &&
            paymentOption.paymentType === groupClassPaymentTypes.SCHEDULED
        );

        const freePaymentOption = paymentOptions.find(
          (paymentOption) =>
            paymentOption.forStudentType === studentType &&
            paymentOption.paymentType === groupClassPaymentTypes.FREE
        );

        setInitialData({
          directPaymentOption,
          scheduledPaymentOption,
          freePaymentOption,
          student,
          groupClass,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (initialData.directPaymentOption) {
      setSelectedPaymentOption(groupClassPaymentTypes.ONE_TIME);
    } else if (initialData.scheduledPaymentOption) {
      setSelectedPaymentOption(groupClassPaymentTypes.SCHEDULED);
    } else if (initialData.freePaymentOption) {
      setSelectedPaymentOption(groupClassPaymentTypes.FREE);
    }
  }, [initialData]);

  return {
    selectedPaymentOption,
    initialData,
    loadingInitialData,
    setSelectedPaymentOption,
    isDirectPaymentAvailable,
    isScheduledPaymentAvailable,
    isBothPaymentsOptionsAvailable,
    selectedEnrollmentOption,
    setSelectedEnrollmentOption,
    isPayNowEnrollmentOption,
    isEnrollNowPayLaterEnrollmentOption,
    isEnrollNowPayLaterAvailable,
    isEnrollNowPayLaterConfirmed,
    setIsEnrollNowPayLaterConfirmed,
    isSaving,
    enrollStudent,
    subscriptionSuccessCallback,
    isFreePaymentAvailable,
  };
};

export default useGroupClassPayment;
