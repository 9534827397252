import { useContext, useEffect, useState } from "react";
import store from "src/stores/UserStore";

import { Navigate } from "react-router";
import { FirebaseContext } from "src/components/Firebase";
import { Spinner } from "src/components/common";
import { updateDoc } from "firebase/firestore";

const EmailConfirm = () => {
  const { user, setEmailVerified } = store;
  const [verified, setVerified] = useState(false);
  const firebase = useContext(FirebaseContext);
  const userToUpdate = firebase.user(user.uid);

  useEffect(() => {
    (async () => {
      const currentUser = firebase.auth.currentUser;

      if (currentUser.emailVerified) {
        setVerified(true);
        setEmailVerified(true);
        await updateDoc(userToUpdate, {
          emailVerified: true,
          status: "Need profile information",
        });
      }
    })();
  }, [firebase.auth.currentUser, setEmailVerified, userToUpdate]);

  return verified ? <Navigate to="/dashboard/guest-profile" /> : <Spinner />;
};

export default EmailConfirm;
