import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import EditTimelineBody from "./EditTimelineBody";
import { CloseIcon } from "./styled";

const EditRegularLessonTimelineModal = ({
  modalData,
  regularPrivateLesson,
  currentTimeline,
}) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      //   padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>Edit Timeline</h2>
      </CustomModalHeader>
      <CustomModalBody
      //    padding="0px"
      >
        <EditTimelineBody
          regularPrivateLesson={regularPrivateLesson}
          currentTimeline={currentTimeline}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default EditRegularLessonTimelineModal;
