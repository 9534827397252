import React from "react";
import { SectionContainer } from "../../styled";

const ExternalPaymentDetails = () => {
  return (
    <SectionContainer>
      <h5>Extenal Payment</h5>
      <hr />

      <SectionContainer className="mt-3">
        <p>This student has paid using external method</p>
      </SectionContainer>
    </SectionContainer>
  );
};

export default ExternalPaymentDetails;
