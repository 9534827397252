import moment from "moment";
import styled from "styled-components";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import {
  availableDaysToString,
  combineArrayToStr,
  instrumentsToString,
} from "../../../../../../utils/helpers";
import { studentInfoItems, eventInfoItems } from "../eventInfoItems";

import { ModeEdit } from "styled-icons/material";
import { Descriptions, Divider } from "antd";

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    transition-duration: 150ms;

    &:is(:hover, :focus) {
      fill: #681e46;
    }
  }

  h4 {
    margin: 0;
    text-transform: capitalize;
  }
`;

const TrialLessonEventContent = ({
  event,
  initialData,
  studioUsages,
  userRole,
  openEditModal,
}) => {
  const { locations, instruments, studios } = initialData;
  const teacher = event.teacher;
  const teacherId = teacher?.id || teacher?.uid;
  const instrument = instruments[event.instrument];
  const locationName = combineArrayToStr(
    event.locations?.map((locationId) => locations[locationId]?.name)
  );

  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );

  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  const teacherStudioUsageInCurrentDate = studioUsages?.find((studioUsage) => {
    const isSameTeacher = teacherId === studioUsage.teacher_id;
    const isSameDate =
      moment(event.start).year() === studioUsage.year &&
      moment(event.start).month() + 1 === studioUsage.month &&
      moment(event.start).format("dddd") === studioUsage.weekday;

    return isSameTeacher && isSameDate;
  });

  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;

  return (
    <div className="p-5 pt-1">
      <ActionButtonsContainer>
        {event?.title && <h4>{event?.title}</h4>}
        {hasAdminCredentials(userRole) && (
          <ModeEdit
            onClick={openEditModal}
            style={{ cursor: "pointer" }}
            size={30}
          />
        )}
      </ActionButtonsContainer>
      <Divider />
      <Descriptions
        title="Lesson information"
        bordered
        column={1}
        items={eventInfoItems(event)}
        labelStyle={{ fontSize: 16 }}
        contentStyle={{ fontSize: 16 }}
      />
      <Divider />
      <Descriptions
        title="Student information"
        bordered
        column={1}
        items={studentInfoItems(event)}
        labelStyle={{ fontSize: 16 }}
        contentStyle={{ fontSize: 16 }}
      />
    </div>
  );
};

export default TrialLessonEventContent;
