import { UserRole } from "src/constants/UserRoleEnum";

const roleOptions = [
  {
    label: "Admin",
    value: UserRole.ADMIN,
  },
  {
    label: "Parent",
    value: UserRole.PARENT,
  },
  {
    label: "Teacher",
    value: UserRole.TEACHER,
  },
  {
    label: "Student",
    value: UserRole.STUDENT,
  },
  {
    label: "Group class student",
    value: UserRole.GROUP_CLASS_STUDENT,
  },
  {
    label: "Guest student",
    value: UserRole.GUEST_STUDENT,
  },
  {
    label: "Teacher applicant",
    value: UserRole.TEACHER_APPLICANT,
  },
  {
    label: "Admin applicant",
    value: UserRole.ADMIN_APPLICANT,
  },
  {
    label: "Partner applicant",
    value: UserRole.PARTNER_APPLICANT,
  },
];

export default roleOptions;
