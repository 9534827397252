import moment from "moment";
import { useMemo } from "react";
import {
  absenceTypes,
  isCreditedAbsence,
  isPendingAbsence,
  isSummerAbsence,
} from "src/constants/absenceTypes";
import {
  getAbsenceMakeUpStatusData,
  getMakeupPeriodInRequestedDate,
  getTimeDiffInMins,
  injectUserStore,
  isActiveTeacher,
  updatedMomentNow,
} from "src/utils/helpers";
import { CustomSelectField, PrimaryButton } from "src/utils/shared/styled";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { CustomInput, CustomLabel, MakeUpRow } from "../../styled";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import {
  isMakeupTypeMakeupLesson,
  isPaidMakeupTypeMakeupLesson,
  isSubstituteTypeMakeupLesson,
  makeupLessonTypes,
} from "src/constants/makeupLessonsEnum";
import useMakeupSection from "./hooks/useMakeupSection";
import DropdownMenuComponent from "../../../../CustomDropdownMenu";
import ConfirmationModal from "../../../../ConfirmationModal";

const MakeUpSection = ({
  makeUpData,
  absence,
  setCurrentAbsenceId,
  makeupModalData,
  lessonDuration,
  currentSchoolYearInLocation,
  openMakeupDeleteConfirmationModal,
  instrumentId,
  currentLesson,
  isPassedLastSetDeadline,
  UserStore,
}) => {
  const user = UserStore.user;
  const { teachers } = useStudentInfoContext();

  const {
    isLoading,
    reachedMaxMakeupsAndNoRequest,
    reachedMaxMakeupsAndPending,
    reachedMaxMakeupsAndDeclined,
    reachedMaxMakeupsAndApproved,
    creditedAbsenceModalData,
    onCreditedAbsenceModalApprove,
    onCreditedAbsenceModalCancel,
  } = useMakeupSection({
    absence,
    privateLesson: currentLesson,
    setCurrentAbsenceId,
    refreshData: makeUpData.refreshData,
    user,
  });

  const setCurrentAbsenceAndOpenMakeupModal = (absenceId) => {
    setCurrentAbsenceId(absenceId);
    makeupModalData.openModal();
  };
  const setCurrentAbsenceAndOpenCreditedAbsenceModal = (absenceId) => {
    setCurrentAbsenceId(absenceId);
    creditedAbsenceModalData.openModal();
  };

  // available teachers for the lesson instrument (appears in the teachers select field)
  const availableTeachers = useMemo(() => {
    if (!teachers?.length || !instrumentId) return [];

    const filteredTeachers = teachers.filter((teacher) => {
      const teacherInstruments =
        teacher.instrumentsInfo?.map((info) => info.instrument) || [];
      const sameInstrument = teacherInstruments.includes(instrumentId);

      return sameInstrument;
    });

    return filteredTeachers;
  }, [instrumentId, teachers]);

  const absenceDate = absence.date || absence.startDate;

  const { id: absenceId, absenceType } = absence;
  const {
    initialData,
    handleAddNewMakeUpLesson,
    makeupLessonsList,
    handleMakeupListChange,
    handleDeleteNewMakeup,
    absencesList,
  } = makeUpData;

  const isSA = isSummerAbsence(absenceType);

  const filteredMakeupLessonsList = useMemo(
    () =>
      makeupLessonsList.filter(
        (makeupLesson) => makeupLesson.forAbsenceId === absenceId
      ),
    [makeupLessonsList, absenceId]
  );
  const lessonLength = parseInt(lessonDuration);
  const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
    absenceDate,
    currentSchoolYearInLocation
  );
  const makeupPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
    ? currentMakeupPeriod.deadline.toDate()
    : undefined;

  const { absenceMakeUpStatus } = getAbsenceMakeUpStatusData(
    absence,
    filteredMakeupLessonsList.filter(({ isNew }) => !isNew),
    lessonLength,
    makeupPeriodDeadline
  );
  // sums up all makeup durations for an absence
  const absenceMakeupsDuration = makeupLessonsList
    .filter(({ forAbsenceId }) => forAbsenceId === absence.id)
    .reduce((acc, currentMakeup) => {
      const parsedStartTime = moment(currentMakeup.startTime, [
        "hh:mm A",
      ]).toDate();
      const parsedEndTime = moment(currentMakeup.endTime, ["hh:mm A"]).toDate();
      const makeUpLength = getTimeDiffInMins(parsedStartTime, parsedEndTime);
      return acc + makeUpLength;
    }, 0);

  const isCreditedAbs = isCreditedAbsence(absenceType);
  const isPendingCreditedAbsence =
    isCreditedAbs && isPendingAbsence(absence.status);

  const isActiveCreditedAbsence =
    isCreditedAbs && !isPendingAbsence(absence.status);

  const isSAOutsideDateLimit =
    isSA &&
    moment(absenceDate).isAfter(updatedMomentNow().add(1, "month"), "minutes");

  const showMakeUpBtn =
    !isLoading &&
    !reachedMaxMakeupsAndDeclined &&
    parseInt(absenceType) !== absenceTypes.sameDayCancelation.code &&
    !filteredMakeupLessonsList.length &&
    [absenceMakeUpStatuses.notMadeUpFor.code].includes(absenceMakeUpStatus) &&
    !isPassedLastSetDeadline &&
    !isSAOutsideDateLimit &&
    !isCreditedAbs;

  const showExpiredBtn =
    [absenceMakeUpStatuses.expired.code].includes(absenceMakeUpStatus) &&
    !isCreditedAbs;

  const isPartiallyMadeUpAndCanStillAddSplit =
    !isLoading &&
    absenceMakeupsDuration < lessonLength &&
    [
      absenceMakeUpStatuses.partiallyScheduled.code,
      absenceMakeUpStatuses.partiallyMadeUpFor.code,
    ].includes(absenceMakeUpStatus) &&
    ![absenceTypes.sameDayCancelation.code].includes(absenceType) &&
    !isPassedLastSetDeadline &&
    !isSAOutsideDateLimit;

  const isNotMadeUpForAndCanAddSplit =
    !isLoading &&
    !reachedMaxMakeupsAndNoRequest &&
    !reachedMaxMakeupsAndDeclined &&
    !reachedMaxMakeupsAndPending &&
    absenceMakeupsDuration < lessonLength &&
    [absenceMakeUpStatuses.notMadeUpFor.code].includes(absenceMakeUpStatus) &&
    ![absenceTypes.sameDayCancelation.code].includes(absenceType) &&
    !isPassedLastSetDeadline &&
    !isSAOutsideDateLimit;

  const showAddSplitBtn =
    !isCreditedAbs &&
    (isPartiallyMadeUpAndCanStillAddSplit || isNotMadeUpForAndCanAddSplit);

  const showNotAvailableBtn =
    !isLoading && reachedMaxMakeupsAndDeclined && !isCreditedAbs;

  const showPendingCreditAbsenceBtn = !isLoading && isPendingCreditedAbsence;

  return (
    <div>
      <div className="text-center mt-4 mb-2">
        {showPendingCreditAbsenceBtn ? (
          <PrimaryButton
            onClick={() =>
              setCurrentAbsenceAndOpenCreditedAbsenceModal(absence.id)
            }
            width="150px"
            padding="7px"
          >
            Pending
          </PrimaryButton>
        ) : showMakeUpBtn ? (
          <PrimaryButton
            onClick={() => setCurrentAbsenceAndOpenMakeupModal(absence.id)}
            width="150px"
            padding="7px"
          >
            MAKE UP
          </PrimaryButton>
        ) : showExpiredBtn ? (
          <PrimaryButton
            onClick={() => {}}
            width="150px"
            padding="7px"
            backgroundColor={absenceMakeUpStatuses.expired.bgColor}
          >
            Expired
          </PrimaryButton>
        ) : showNotAvailableBtn ? (
          <PrimaryButton
            onClick={() => setCurrentAbsenceAndOpenMakeupModal(absence.id)}
            width="150px"
            padding="7px"
            backgroundColor={"#686868"}
          >
            Not Available
          </PrimaryButton>
        ) : isSAOutsideDateLimit ? (
          <DropdownMenuComponent
            openAndCloseOnHover={true}
            dropdownToggleComponent={() => (
              <PrimaryButton
                width="150px"
                padding="7px"
                backgroundColor={"#e357a2"}
              >
                Booking Later
              </PrimaryButton>
            )}
            dropdownContent={() => (
              <div
                className="pb-4 p-3"
                style={{ background: "#0a0a0c", color: "#e6e6e6" }}
              >
                <p className="m-0">
                  The Make Up is available to book 1 month prior
                </p>
              </div>
            )}
          />
        ) : null}
      </div>
      <div className="text-center mb-3">
        {showAddSplitBtn && (
          <PrimaryButton
            onClick={() => handleAddNewMakeUpLesson(absenceId)}
            width="150px"
            padding="7px"
          >
            Add Split
          </PrimaryButton>
        )}
      </div>
      {filteredMakeupLessonsList.map((makeupLesson, i) => {
        const {
          startDate,
          startTime,
          endTime,
          id,
          teacherId,
          isNew,
          isVirtual,
          type,
        } = makeupLesson;

        const makeupTeacher = teachers.find(({ id }) => id === teacherId);

        const isPaidMakeup = isPaidMakeupTypeMakeupLesson(type);

        return (
          <>
            {isPaidMakeup && (
              <div
                className="text-center mb-3"
                style={{ fontSize: "1.2rem", color: "#009900" }}
              >
                <span>Paid Make Up</span>
              </div>
            )}
            <MakeUpRow key={id}>
              <div>
                {i === 0 && <CustomLabel>Make Up Date:</CustomLabel>}
                <CustomInput
                  width="130px"
                  type="date"
                  className="form-control"
                  name="startDate"
                  value={startDate}
                  onChange={(e) =>
                    handleMakeupListChange("startDate", e.target.value, id)
                  }
                />
              </div>
              <div>
                {i === 0 && <CustomLabel>Start Time:</CustomLabel>}
                <CustomInput
                  width="130px"
                  type="time"
                  className="form-control"
                  name="startTime"
                  value={startTime}
                  onChange={(e) =>
                    handleMakeupListChange("startTime", e.target.value, id)
                  }
                />
              </div>
              <div>
                {i === 0 && <CustomLabel>End Time:</CustomLabel>}
                <CustomInput
                  width="130px"
                  type="time"
                  className="form-control"
                  name="endTime"
                  value={endTime}
                  onChange={(e) =>
                    handleMakeupListChange("endTime", e.target.value, id)
                  }
                />
              </div>
              <div>
                {i === 0 && (
                  <CustomLabel style={{ display: "block" }}>
                    Teacher:
                  </CustomLabel>
                )}
                {isActiveTeacher(makeupTeacher) || isNew ? (
                  <CustomSelectField
                    hideDisabledOptions
                    value={teacherId}
                    name="teacherId"
                    onChange={(e) =>
                      handleMakeupListChange("teacherId", e.target.value, id)
                    }
                    width="100%"
                  >
                    <option value="" disabled>
                      Select Teacher
                    </option>
                    {availableTeachers.map((teacher) => (
                      <option
                        disabled={!isActiveTeacher(teacher)}
                        key={teacher.id}
                        value={teacher.id}
                      >
                        {teacher.fullName}
                      </option>
                    ))}
                  </CustomSelectField>
                ) : (
                  <CustomInput
                    width="130px"
                    disabled
                    value={makeupTeacher?.fullName}
                  />
                )}
              </div>
              <div
                onClick={() =>
                  isNew
                    ? handleDeleteNewMakeup(id)
                    : openMakeupDeleteConfirmationModal(id)
                }
                className="mb-2"
                style={{ cursor: "pointer" }}
              >
                <DeleteOff width={30} height={30} color="#8a8a8a" />
              </div>
            </MakeUpRow>
            {!isPaidMakeup && (
              <div className="text-center mb-3 mt-1">
                <label className="me-3">
                  <input
                    type="radio"
                    className="me-2"
                    checked={isMakeupTypeMakeupLesson(type)}
                    onChange={() =>
                      handleMakeupListChange(
                        "type",
                        makeupLessonTypes.MAKEUP,
                        id
                      )
                    }
                  />
                  Make Up
                </label>
                <label>
                  <input
                    type="radio"
                    className="me-2"
                    checked={isSubstituteTypeMakeupLesson(type)}
                    onChange={() =>
                      handleMakeupListChange(
                        "type",
                        makeupLessonTypes.SUBSTITUTE,
                        id
                      )
                    }
                  />
                  Substitute
                </label>
              </div>
            )}
            <div className="text-center mb-3 mt-1">
              <label className="me-3">
                <input
                  type="radio"
                  className="me-2"
                  checked={!isVirtual}
                  onChange={(e) =>
                    handleMakeupListChange("isVirtual", false, id)
                  }
                />
                In Person
              </label>
              <label>
                <input
                  type="radio"
                  className="me-2"
                  checked={isVirtual}
                  onChange={(e) =>
                    handleMakeupListChange("isVirtual", true, id)
                  }
                />
                Virtual
              </label>
            </div>
          </>
        );
      })}

      <ConfirmationModal
        modalData={creditedAbsenceModalData}
        onApprove={onCreditedAbsenceModalApprove}
        onCancel={onCreditedAbsenceModalCancel}
        isSubmitting={isLoading}
        title="make sure to receive the management authorization for the credit"
        approveBtnText="Confirm"
        cancelBtnText="Cancel"
      />
    </div>
  );
};

export default injectUserStore(MakeUpSection);
