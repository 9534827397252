import React, { useEffect, useMemo, useState } from "react";
import { paymentUserTypes } from "../../../constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import { reportDatesFilters } from "../constants";
import {
  calculatePaymentTotal,
  convertPaymentDateStrToDate,
  roundToNearestDecimals,
} from "src/utils/helpers";
import moment from "moment";
import { UserRole } from "src/constants/UserRoleEnum";
import { isPaymentInLocation } from "../helperFns";

const initialDataInitialValues = {
  locations: [],
};
const reportDataInitialValues = {
  payments: [],
  users: [],
};
const selectedFilterObjInitialValues = {
  [reportDatesFilters.BY_RANGE]: {
    selectedRange: null,
  },
  [reportDatesFilters.BY_PERIOD]: {
    selectedFilter: "",
    selectedMonth: "",
  },
};

const useReportData = () => {
  const {
    getTeachers,
    getAdminsTeam,
    getTeachersAndAdminTeam,
    getLocations,
    getPaymentsWithinDateRange,
    getPaymentsDuringDatePeriod,
    getTeachersAvailableDays,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentUserType, setCurrentUserType] = useState(
    paymentUserTypes.INSTRUCTOR
  );

  const [selectedDateFilterType, setSelectedDateFilterType] = useState(
    reportDatesFilters.BY_RANGE
  );
  const [selectedFilterObj, setSelectedFilterObj] = useState(
    selectedFilterObjInitialValues
  );
  const [selectedLocationId, setSelectedLocationId] = useState("");

  const [reportData, setReportData] = useState(reportDataInitialValues);
  const [loadingReportData, setLoadingReportData] = useState(false);

  function handleFilterObjChange(filterType, propName, value) {
    setSelectedFilterObj((oldVal) => ({
      ...oldVal,
      [filterType]: {
        ...oldVal[filterType],
        [propName]: value,
      },
    }));
  }

  async function loadReportData(onSuccess = () => {}) {
    try {
      const isRangeFilterSelectedAndIncomplete =
        selectedDateFilterType === reportDatesFilters.BY_RANGE &&
        !selectedFilterObj[reportDatesFilters.BY_RANGE]?.selectedRange;
      const isPeriodFilterSelectedAndIncomplete =
        selectedDateFilterType === reportDatesFilters.BY_PERIOD &&
        !selectedFilterObj[reportDatesFilters.BY_PERIOD]?.selectedFilter;

      if (isRangeFilterSelectedAndIncomplete) {
        toast.warn("Please select a date range");
        return;
      }
      if (isPeriodFilterSelectedAndIncomplete) {
        toast.warn("Please select a period");
        return;
      }

      setLoadingReportData(true);

      const isRangeFilter =
        selectedDateFilterType === reportDatesFilters.BY_RANGE;
      const isPeriodFilter =
        selectedDateFilterType === reportDatesFilters.BY_PERIOD;

      const requestedUserRoles =
        currentUserType === paymentUserTypes.INSTRUCTOR
          ? [UserRole.TEACHER]
          : currentUserType === paymentUserTypes.OPERATION_TEAM
          ? [UserRole.SUPER_ADMIN, UserRole.ADMIN]
          : [UserRole.TEACHER, UserRole.SUPER_ADMIN, UserRole.ADMIN];

      let paymentsReq;
      if (isRangeFilter) {
        const { selectedRange } =
          selectedFilterObj[reportDatesFilters.BY_RANGE] || {};
        const { start, end } = selectedRange || {};
        const startDate = convertPaymentDateStrToDate(start);
        const endDate = convertPaymentDateStrToDate(end);
        paymentsReq = getPaymentsWithinDateRange({
          startDate,
          endDate,
          userRoles: requestedUserRoles,
        });
      } else if (isPeriodFilter) {
        const { selectedFilter, selectedMonth } =
          selectedFilterObj[reportDatesFilters.BY_PERIOD] || {};
        paymentsReq = getPaymentsDuringDatePeriod({
          period: selectedFilter,
          month: selectedMonth,
          userRoles: requestedUserRoles,
        });
      }

      const usersReq =
        currentUserType === paymentUserTypes.INSTRUCTOR
          ? getTeachers()
          : currentUserType === paymentUserTypes.INSTRUCTOR
          ? getAdminsTeam()
          : getTeachersAndAdminTeam();
      const usersAvailableDaysReq =
        currentUserType === paymentUserTypes.INSTRUCTOR ||
        currentUserType === paymentUserTypes.BOTH
          ? getTeachersAvailableDays()
          : [];

      const [users, usersAvailableDays, payments] = await Promise.all([
        usersReq,
        usersAvailableDaysReq,
        paymentsReq,
      ]);

      const paymentsInLocation = selectedLocationId
        ? payments.filter((paymentObj) =>
            isPaymentInLocation({
              payment: paymentObj,
              selectedLocationId,
              users,
              usersAvailableDays,
            })
          )
        : payments;

      setReportData({
        payments: paymentsInLocation,
        users,
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [locations] = await Promise.all([getLocations()]);

        setInitialData({
          locations,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  const paymentsTotal = useMemo(() => {
    let total = 0;
    for (const paymentObj of reportData.payments) {
      const paymentTotal = calculatePaymentTotal(paymentObj.payments);
      if (paymentTotal) total = total + paymentTotal;
    }

    return roundToNearestDecimals(total, 2);
  }, [reportData]);

  return {
    currentUserType,
    setCurrentUserType,
    loadReportData,
    loadingReportData,
    reportData,
    selectedDateFilterType,
    setSelectedDateFilterType,
    selectedFilterObj,
    handleFilterObjChange,
    selectedLocationId,
    setSelectedLocationId,
    initialData,
    loadingInitialData,
    paymentsTotal,
  };
};

export default useReportData;
