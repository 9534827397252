import React from "react";
import { Button } from "reactstrap";
import { Modal } from "src/components/common";

const DeleteConfirmModal = ({ onDelete, close }) => {
  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      <p className="lead mb-0">
        Are you sure you want to delete the selected school year?
      </p>
      <div className="m-2 alert alert-warning fw-bold">
        This will also delete all data related to this school year
      </div>
    </div>
  );

  const buttons = (
    <>
      <Button onClick={onDelete} color="danger">
        Yes, Delete
      </Button>
    </>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Confirm Deletion"}
      isOpen={true}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default DeleteConfirmModal;
