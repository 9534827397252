import React, { useState, useContext, useEffect, useRef } from "react";
import UnknownProfilePhoto from "../../../../../../../../../assets/images/default-profile.jpg";
import { PrimaryButton } from "../../../../../../../../../utils/shared/styled";
import { v4 as uuidv4 } from "uuid";
import { FirebaseContext } from "../../../../../../../../Firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import styled from "styled-components";
import { useRegistrationContext } from "../../../../../../../../../contexts/RegisterationContext";

const ImageWrapper = styled.div`
  text-align: center;
`;
const NameAndIdWrapper = styled.div`
  padding-left: 20px;
  font-weight: bold;
  & span {
    font-size: 1.5rem;
  }
  & p {
    font-size: 1.2rem;
    margin: 0;
  }
`;
const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;

const UserProfile = () => {
  const firebase = useContext(FirebaseContext);
  const { formValues, setFormValues } = useRegistrationContext();
  const { firstStepValues, secondStepValues } = formValues;
  const [uploading, setUploading] = useState(false);
  const uploadRef = useRef();

  return (
    <>
      <div className="d-flex flex-wrap align-items-center w-50">
        <ImageWrapper className="w-50">
          <Image
            width={150}
            height={150}
            src={secondStepValues?.imageUrl || UnknownProfilePhoto}
          />
          <input
            ref={uploadRef}
            hidden
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              const filePath = `profileImages/${firstStepValues?.systemID}`;

              const fileRef = ref(firebase.storage, filePath);
              const uploadTask = uploadBytesResumable(fileRef, file);
              uploadTask.on(
                "state_changed",
                () => {
                  setUploading(true);
                },
                () => {
                  setUploading(false);
                },
                () => {
                  getDownloadURL(fileRef).then((url) => {
                    setUploading(false);
                    setFormValues((oldVal) => ({
                      ...oldVal,
                      secondStepValues: {
                        ...oldVal.secondStepValues,
                        imageUrl: url,
                      },
                    }));
                  });
                }
              );
            }}
          />
        </ImageWrapper>
        <NameAndIdWrapper className="w-50">
          <span>{firstStepValues?.fullName}</span>
          <p>Teacher ID: {firstStepValues?.systemID}</p>
        </NameAndIdWrapper>
        <div className="text-center w-50 mt-4">
          <PrimaryButton
            onClick={() => {
              uploadRef.current.click();
            }}
            width="100%"
          >
            Upload Photo
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
