import { Card } from "antd";
import styled from "styled-components";

const ProfileSummaryCard = styled(Card)`
  border: 1px solid #681e46;
  justify-content: center;
`;

const SummaryList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
`;

const SummaryItem = styled.li`
  font-size: 18px;
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "")};
`;

export { ProfileSummaryCard, SummaryList, SummaryItem };
