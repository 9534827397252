import { FooterButton, StyledFooter } from "./styled";

const ModalFooter = ({
  currentSection,
  onNextClick,
  onBackClick,
  onSubmit,
}) => {
  return (
    <StyledFooter>
      {currentSection === 2 && (
        <FooterButton onClick={onBackClick}>Back</FooterButton>
      )}
      {currentSection === 2 && (
        <FooterButton onClick={onSubmit}>Submit</FooterButton>
      )}
      {currentSection === 1 && (
        <FooterButton leftmargin="true" onClick={onNextClick}>
          Next
        </FooterButton>
      )}
    </StyledFooter>
  );
};

export default ModalFooter;
