/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { TrialLessonsContext } from "./TrialLessons";
/**import ReactPaginate from "react-paginate";
import { Next } from "@styled-icons/foundation/Next";
import { Previous } from "@styled-icons/foundation/Previous";
 */
import styled from "styled-components";
import { Edit } from "@styled-icons/entypo/Edit";
import { Check2Square } from "@styled-icons/bootstrap/Check2Square";
import { ListCheck } from "@styled-icons/bootstrap/ListCheck";
import AddTrialLessonStudentModal from "./AddTrialLessonStudentModal";
import { SortAlphaDown } from "@styled-icons/bootstrap/SortAlphaDown";
import "./style.css";
import { toast } from "react-toastify";
import { updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../Firebase";
import CreateStudentTrialLesson from "./CreateStudentTrialLesson";
import _ from "lodash";

import {
  checkIfCompletedTrialStudent,
  getTimeDiffInMins,
  updatedMomentNow,
} from "../../../utils/helpers";
import moment from "moment";
import useModal from "src/hooks/useModal";
import TrialStudentInfoModal from "./modals/TrialStudentInfoModal";
/**
 * const Nexticon = styled(Next)`
  width: 20px;
`;
const Previousicon = styled(Previous)`
  width: 20px;
`;
 */
const Editicon = styled(Edit)`
  color: #151515;
  width: 17px;
`;
const Checkicon = styled(Check2Square)`
  color: #151515;
  width: 17px;
`;
const ListCheckicon = styled(ListCheck)`
  color: #151515;
  width: 17px;
`;
const SortIcon = styled(SortAlphaDown)`
  color: #681e46;
  width: 35px;
  margin-left: 15px;
  cursor: pointer;
`;
const TrialLessonsStudentsTable = ({ onStudentUpdateSuccess }) => {
  const {
    locations,
    updateStudents,
    students,
    instruments,
    programs,
    teachers,
  } = useContext(TrialLessonsContext);
  const firebase = useContext(FirebaseContext);

  const lessonsInfoModalData = useModal();

  const [isAddClassInfoModelOpen, setIsAddClassInfoModelOpen] = useState(false);
  const [isEditStudentModelOpen, setIsEditStudentModelOpen] = useState(false);
  const [trialStudentLessonModal, setTrialStudentLessonModal] = useState({
    isOpen: false,
    index: 0,
    mode: "add",
    id: undefined,
  });
  const [studentIndex, setStudentIndex] = useState(0);
  const [updateUser, setUpdateUser] = useState(false);
  const pressedStudent = students[studentIndex];
  const showCreateStudentTrialLesson = (index, mode, id) => {
    setTrialStudentLessonModal({
      isOpen: true,
      index,
      mode,
      id,
    });
  };
  const onCloseLessonModal = () => {
    setTrialStudentLessonModal({
      isOpen: false,
      index: 0,
      mode: "add",
      id: undefined,
    });
  };

  var x = document.getElementsByTagName("BODY")[0];
  const renderActions = (index, isCompletedStudent) => (
    <div className="userActions">
      <div>
        <Editicon
          className="icon"
          onClick={() => {
            const student = students[index];
            setIsEditStudentModelOpen(true);
            setStudentIndex(index);
            x.classList.add("overflowBody");
          }}
        />
      </div>
      {isCompletedStudent && (
        <div>
          <ListCheckicon
            className="icon"
            onClick={() => {
              setStudentIndex(index);
              lessonsInfoModalData.openModal();
            }}
          />
        </div>
      )}
    </div>
  );
  const curr = students.map((student, index) => {
    const {
      id,
      name,
      primaryContact,
      primaryPhone,
      primaryEmail,
      trialLessons = [],
    } = student;

    let closestTrialLesson;
    trialLessons?.forEach((tl) => {
      const lessonDate = tl.date.toDate();
      const currentClosestLessonDate = closestTrialLesson?.date.toDate();
      const timeDiff = getTimeDiffInMins(updatedMomentNow(), lessonDate);
      const currentClosestTimeDiff = getTimeDiffInMins(
        updatedMomentNow(),
        currentClosestLessonDate
      );
      if (
        !closestTrialLesson ||
        Math.abs(timeDiff) < Math.abs(currentClosestTimeDiff)
      ) {
        closestTrialLesson = tl;
      }
    });
    const formattedDate = closestTrialLesson
      ? moment(closestTrialLesson.date.toDate()).format("MM/DD/YYYY")
      : "N/A";
    const isCompletedStudent = checkIfCompletedTrialStudent(student);

    const lesson = _.last(trialLessons);
    let location = "";
    if (lesson?.locations?.length) {
      location = locations.filter(({ id }) => id === lesson.locations[0]);
      location = location[0].name;
    }
    let teacher = "";
    if (lesson?.teacherId?.length) {
      teacher = teachers[lesson.teacherId]?.fullName;
    }
    return (
      <tr key={id} id={id} className="TableRow">
        <td
          onClick={() => {
            // setStudentIndex(index);
            // setIsViewUserDataOpen(true);
            // x.classList.add("overflowBody");
            setStudentIndex(index);
            lessonsInfoModalData.openModal();
          }}
        >
          {name}
        </td>
        <td>
          {lesson?.instrument ? instruments[lesson.instrument]?.name : ""}
        </td>
        <td>{location}</td>
        <td>{teacher}</td>
        <td>{formattedDate}</td>

        <td>
          <div className="Iconsactions">
            {renderActions(index, isCompletedStudent)}
          </div>
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="tableContainer">
        <table className="studentTables">
          <thead>
            <tr className="TableRow">
              <th>
                <div className="HeadContainer">Name of Student</div>
              </th>
              <th>
                <div className="HeadContainer">Instrument</div>
              </th>
              <th>
                <div className="HeadContainer">Location</div>
              </th>
              <th>
                <div className="HeadContainer">Teacher</div>
              </th>
              <th>
                <div className="HeadContainer">Trial Lesson Date</div>
              </th>
              <th className="actions">
                <div className="HeadContainer">
                  <>Actions</>
                </div>
              </th>
            </tr>
          </thead>
          {/* <tbody>{!isArrSorted ? curr : sorted}</tbody> */}
          <tbody>{curr}</tbody>
        </table>
      </div>
      {/* <ViewAllUserData
        isUserModalopen={viewUserDataOpen}
        CloseModal={setIsViewUserDataOpen}
        CurrentStudent={pressedStudent}
        Teachers={teachers}
        Instruments={instruments}
        Programs={programs}
        onAddLessonClick={() => {
          onEditLessonClick(undefined, "add");
        }}
        onEditLessonClick={(id) => {
          onEditLessonClick(id, "edit");
        }}
        onEditStudentClick={(student) => {
          const index = students.indexOf(student);
          setIsEditStudentModelOpen(true);
          setStudentIndex(index);
        }}
      /> */}
      <AddTrialLessonStudentModal
        user={pressedStudent}
        initialValues={pressedStudent}
        mode={"edit"}
        onStudentUpdateSuccess={onStudentUpdateSuccess}
        LessonModal={isAddClassInfoModelOpen}
        onCloseLessonModal={() => setIsAddClassInfoModelOpen(false)}
        isOpen={isEditStudentModelOpen}
        updateModal={updateUser}
        onAddStudentSuccess={(id, student) => {
          updateStudents(
            students.map((st) => (st.id === id ? { ...student } : st))
          );
          setIsEditStudentModelOpen(false);
        }}
        onClose={() => setIsEditStudentModelOpen(false)}
        closeUpdateModal={() => setUpdateUser(false)}
      />
      <CreateStudentTrialLesson
        studentID={students[trialStudentLessonModal.index]?.id}
        studentIndex={trialStudentLessonModal.index}
        isOpen={trialStudentLessonModal.isOpen}
        firebase={firebase}
        lessonId={trialStudentLessonModal.id}
        mode={trialStudentLessonModal.mode}
        onClose={onCloseLessonModal}
      />

      <TrialStudentInfoModal
        studentIndex={studentIndex}
        modalData={lessonsInfoModalData}
        showCreateStudentTrialLesson={showCreateStudentTrialLesson}
        onEditStudentClick={(student) => {
          const index = students.indexOf(student);
          setIsEditStudentModelOpen(true);
          setStudentIndex(index);
        }}
      />
    </>
  );
};
export default TrialLessonsStudentsTable;
