import Modal from "../../Modal";
import React, { useContext } from "react";
import {
  ActiveTabContext,
  DeleteItemsModalContext,
  LibraryContext,
} from "../common/libraryContext";
import { Button } from "reactstrap";

const DeleteConfirmModal = () => {
  const { state, close, deleteItems } = useContext(DeleteItemsModalContext);
  const { isAdmin, activeTab } = useContext(ActiveTabContext);
  const { library, selected } = useContext(LibraryContext);

  const modalBody = () => (
    <div className={"d-flex py-5 align-items-center justify-content-center"}>
      <p className="lead mb-0">
        Are you sure you want to delete the selected item
        {state.items.length > 1 ? "s" : ""}?
      </p>
    </div>
  );

  const currentTabLibraryItems = library[activeTab];

  const isAllSelected =
    selected.length === currentTabLibraryItems.length &&
    currentTabLibraryItems.length;

  const buttons = (
    <>
      <Button
        className={"ms-2"}
        color="danger"
        onClick={() => deleteItems(false)}
      >
        Yes, Delete
      </Button>
      {isAdmin ? (
        <>
          {isAllSelected ? (
            <Button
              className={"ms-2"}
              color="danger"
              onClick={() => deleteItems(true, true)}
            >
              Delete crossed over items
            </Button>
          ) : null}
          <Button
            className={"ms-2"}
            color="danger"
            onClick={() => deleteItems(true)}
          >
            Delete forever
          </Button>
        </>
      ) : null}
    </>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Confirm Deletion"}
      isOpen={state.isOpen}
      buttons={buttons}
      toggle={close}
    />
  );
};
export default DeleteConfirmModal;
