import { toJS } from "mobx";
import React, { useContext, useState } from "react";
import { SubmitSpinner } from "src/components/common";
import {
  CustomLabel,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../styled";
import "react-datepicker/dist/react-datepicker.css";
import { SingleDatePicker } from "react-dates";
import { CalendarPickerWrapper } from "src/utils/shared/styled";
import {
  getPrivateLessonInfoOnSpecificDate,
  injectUserStore,
  updatedMomentNow,
} from "src/utils/helpers";
import StyledBarContent from "src/components/common/StyledBarContent";
import usePackageLessonExpiration from "../hooks/usePackageLessonExpiration";

const PackageLessonExpirationBody = ({
  modalData,
  lessonId,
  onSuccess,
  UserStore,
  setNumber,
}) => {
  const user = toJS(UserStore)?.user;

  const { closeModal } = modalData;

  const {
    initialData,
    loadingInitialData,
    formData,
    handleFormChange,
    loadingSave,
    saveChanges,
  } = usePackageLessonExpiration({
    lessonId,
    onSuccess,
    user,
    setNumber,
  });
  const { privateLesson } = initialData;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  if (loadingInitialData) {
    return <div></div>;
  }

  return (
    <>
      <StyledBarContent overFlowContent={true} title="Change Expiration Date">
        <div className="p-4">
          <InputWrapper>
            <CustomLabel>Expiration Date:</CustomLabel>

            <CalendarPickerWrapper>
              <SingleDatePicker
                id="absenceDate"
                date={formData.expirationDate} // momentPropTypes.momentObj or null
                onDateChange={(date) => {
                  handleFormChange("expirationDate", date);
                }}
                focused={isDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </InputWrapper>
        </div>
      </StyledBarContent>
      <CustomButtonsWrapper>
        <CustomButton onClick={closeModal}>Cancel</CustomButton>
        <CustomButton primary onClick={saveChanges}>
          {loadingSave ? (
            <div>
              <SubmitSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            "Confirm"
          )}
        </CustomButton>
      </CustomButtonsWrapper>
    </>
  );
};

export default injectUserStore(PackageLessonExpirationBody);
