export const optionsPropNames = {
  LESSON_TIME: "lessonTime",
  LESSON_LENGTH: "lessonLength",
  IS_VIRTUAL: "isVirtual",
  LOCATION: "locationId",
  TEACHER: "teacherId",
  CLASS_DAY: "lessonLeweekDayngth",
  INSTRUMENT: "instrumentId",
};

export const lessonChangeGroups = {
  LESSON: "lesson",
  TEACHER: "teacher",
  INSTRUMENT: "instrumentId",
  LOCATION: "location",
};

export const changeOptionsList = [
  {
    title: "Lesson",
    propName: lessonChangeGroups.LESSON,
    description: "Lesson Day/Time/Length/Type",
    availableOptions: [
      optionsPropNames.CLASS_DAY,
      optionsPropNames.LESSON_TIME,
      optionsPropNames.LESSON_LENGTH,
      optionsPropNames.IS_VIRTUAL,
    ],
  },
  {
    title: "Teacher",
    propName: lessonChangeGroups.TEACHER,
    description: "Teacher/Lesson Day/Time/Length/Type",
    availableOptions: [
      optionsPropNames.TEACHER,
      optionsPropNames.CLASS_DAY,
      optionsPropNames.LESSON_TIME,
      optionsPropNames.LESSON_LENGTH,
      optionsPropNames.IS_VIRTUAL,
    ],
  },
  {
    title: "Instrument",
    propName: lessonChangeGroups.INSTRUMENT,
    description: "Instrument",
    availableOptions: [optionsPropNames.INSTRUMENT],
  },
  {
    title: "Location",
    propName: lessonChangeGroups.LOCATION,
    description: "Location/Teacher/Lesson Day/Time/Length/Type",
    availableOptions: [
      optionsPropNames.LOCATION,
      optionsPropNames.TEACHER,
      optionsPropNames.CLASS_DAY,
      optionsPropNames.LESSON_TIME,
      optionsPropNames.LESSON_LENGTH,
      optionsPropNames.IS_VIRTUAL,
    ],
  },
];
