import { Form, Formik } from "formik";
import React from "react";
import { InputWithLabel } from "../../../../../../../../../utils/shared/shared";
import * as yup from "yup";
import {
  requiredStringSchema,
  emailValidationSchema,
  zipValidationSchema,
  phoneValidationSchema,
  adultValidationSchema,
} from "../../../../../../../../../utils/validations";
import styled from "styled-components";
import { PrimaryButton } from "../../../../../../../../../utils/shared/styled";
import { useRegistrationContext } from "../../../../../../../../../contexts/RegisterationContext";
import handlePhoneChange from "src/components/Dashboard/common/handlePhoneChange";
const InputWrapper = styled.div`
  width: ${({ width }) => width || "30%"};
`;

const BasicInfoValidationSchema = yup.object().shape({
  // birthday: adultValidationSchema,
  gender: requiredStringSchema,
  enrollmentDate: requiredStringSchema,
  primaryPhone: phoneValidationSchema,
  primaryEmail: emailValidationSchema,
  homeAddress: requiredStringSchema,
  city: requiredStringSchema,
  state: requiredStringSchema,
  zip: zipValidationSchema,
});
const BasicInfoForm = () => {
  const { step, nextStep, formValues, setFormValues } =
    useRegistrationContext();
  const initialValues = {
    birthday: "",
    gender: "",
    enrollmentDate: "",
    primaryPhone: "",
    primaryEmail: "",
    homeAddress: "",
    city: "",
    state: "",
    zip: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BasicInfoValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        console.log({ values });
        setSubmitting(true);
        setFormValues((oldVal) => ({
          ...oldVal,
          secondStepValues: { ...oldVal.secondStepValues, ...values },
        }));
        nextStep();
        setSubmitting(false);
      }}
    >
      {({ touched, errors, isSubmitting, values, setFieldValue }) => (
        <Form className="mt-3">
          <div className="d-flex justify-content-between">
            <InputWrapper>
              <InputWithLabel
                name="birthday"
                error={errors.birthday}
                touched={touched.birthday}
                label="Birthday"
                placeholder="Birthday"
                type="date"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="gender"
                error={errors.gender}
                touched={touched.gender}
                label="Gender"
                placeholder="Gender"
                type="text"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="enrollmentDate"
                error={errors.enrollmentDate}
                touched={touched.enrollmentDate}
                label="Enrollment Date"
                placeholder="Enrollment Date"
                type="date"
              />
            </InputWrapper>
          </div>
          <div className="d-flex justify-content-between">
            <InputWrapper>
              <InputWithLabel
                name="primaryPhone"
                error={errors.primaryPhone}
                touched={touched.primaryPhone}
                onChange={(e) => {
                  const value = handlePhoneChange(e.target.value);
                  setFieldValue("primaryPhone", value);
                }}
                maxlength="14"
                label="Primary Phone"
                placeholder="Primary Phone"
                type="text"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="primaryEmail"
                error={errors.primaryEmail}
                touched={touched.primaryEmail}
                label="Primary Email"
                placeholder="Primary Email"
                type="email"
              />
            </InputWrapper>
            {/* filler */}
            <InputWrapper />
          </div>
          <div className="d-flex justify-content-between ">
            <InputWrapper width="100%">
              <InputWithLabel
                name="homeAddress"
                error={errors.homeAddress}
                touched={touched.homeAddress}
                label="Home Address"
                placeholder="Home Address"
                type="text"
              />
            </InputWrapper>
          </div>
          <div className="d-flex justify-content-between">
            <InputWrapper>
              <InputWithLabel
                name="city"
                error={errors.city}
                touched={touched.city}
                label="City"
                placeholder="City"
                type="text"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="state"
                error={errors.state}
                touched={touched.state}
                label="State"
                placeholder="State"
                type="text"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="zip"
                error={errors.zip}
                touched={touched.zip}
                label="ZIP"
                placeholder="ZIP"
                type="text"
              />
            </InputWrapper>
          </div>
          <div className="text-center mt-5">
            <PrimaryButton type="submit" height="45px" padding="0">
              Next Page
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BasicInfoForm;
