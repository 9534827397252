import styled from "styled-components";
import { SearchAlt2 } from "styled-icons/boxicons-regular";

export const Container = styled.div`
  padding: 10px;
`;

export const TopSectionContainer = styled.div`
  margin-bottom: 15px;
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`;
export const SearchBar = styled.input`
  border-radius: 20px;
  padding: 10px 15px;
  width: 100%;
  border: 2px solid #dddddd;
  outline: none;
  transition: ease-in-out 0.1s;
  cursor: pointer;
  color: #979797;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #681e46;
  }
`;
export const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;

export const TableContainer = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

export const CustomLabel = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
`;

export const CustomInput = styled.input`
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  ::placeholder {
    color: #979797;
  }

  &[type="time"] {
    /* width: 180px; */
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  padding: 12px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fff";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
