import React, { useContext, useEffect, useState } from "react";
import {
  LessonNotesContext,
  ViewHomeworkModalContext,
  ViewItemModalContext,
} from "../../../common/Library/common/libraryContext";
import { Modal } from "../../../common";
import SingleHomeworkItem from "./SingleHomeworkitem";
import {
  HomeworkModalContainer,
  HomeworkNoteContainer,
} from "../../../common/styled-components/lessonNotesStyledComponents";
import BaseNoteModal from "../BaseNoteModal";
import moment from "moment";
import TeacherCommentSection from "./TeacherCommentSection";
import { Button, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { injectUserStore } from "src/utils/helpers";

const ViewHomeworkModal = ({ UserStore }) => {
  const user = UserStore?.user;
  const {
    isOpen,
    close,
    homework,
    approveHomework,
    selectedUser,
    submitTeacherComment,
  } = useContext(ViewHomeworkModalContext);

  const { isEditable, userId, notes } = useContext(LessonNotesContext);

  const [teacherComment, setTeacherComment] = useState("");

  const studentHomework =
    homework?.[isEditable ? selectedUser.id : userId] || {};

  const note = notes?.find(
    ({ id }) => homework?.noteId && id === homework.noteId
  );

  const isAbleToEditTeacherComment = user.uid === note?.createdBy;

  const onSaveTeacherCommentClick = async () => {
    try {
      await submitTeacherComment(teacherComment);
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const modalBody = () => (
    <HomeworkModalContainer>
      <p className="lead mb-2">
        Lesson Date: {moment(homework.lesson.date).format("MM-DD-YYYY")}
      </p>
      {/* <p className="lead mb-2">Title: {homework.lesson.title}</p> */}
      {Object.keys(studentHomework).length > 0 ? (
        <>
          {(studentHomework?.attachments || []).map((item, index) => (
            <SingleHomeworkItem
              item={{
                title: item?.name || item?.title || item?.downloadUrl,
                ...item,
              }}
              key={index}
            />
          ))}
          <h6>Student/Family Comment</h6>
          <HomeworkNoteContainer height="130px" className="mb-2">
            {studentHomework.comment}
          </HomeworkNoteContainer>
          <TeacherCommentSection
            teacherComment={teacherComment}
            setTeacherComment={setTeacherComment}
          />
          {studentHomework?.isApproved ? (
            <div className="alert alert-success mb-2">
              Approved <i className="fa fa-check" />
            </div>
          ) : null}
        </>
      ) : (
        <div className="alert alert-warning lead">
          This student didn't submit homework yet
        </div>
      )}
    </HomeworkModalContainer>
  );
  return (
    <BaseNoteModal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Student Homework"}
      isOpen={isOpen}
      hasAction={
        isEditable &&
        Object.keys(studentHomework).length > 0 &&
        !studentHomework?.isApproved
      }
      closeButtonText={"Close"}
      actionButtonColor={"success"}
      buttonText={studentHomework?.isApproved ? "Approved" : "Approve"}
      okAction={studentHomework?.isApproved ? () => {} : approveHomework}
      toggle={close}
      extraButton={
        isAbleToEditTeacherComment ? (
          <Button
            className={"rounded-pill ms-2 bg-purple"}
            onClick={onSaveTeacherCommentClick}
          >
            Save and Send Comment
            {/* {isLoading ? <Spinner size={"sm"} /> : null} */}
          </Button>
        ) : null
      }
    />
  );
};
export default injectUserStore(ViewHomeworkModal);
