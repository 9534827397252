import styled from "styled-components";
import { Delete } from "styled-icons/fluentui-system-filled";

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 7px;

  // for the buttons
  & svg {
    color: #ff008a;
  }

  & > * {
    cursor: pointer;
  }
`;
export const DeleteIcon = styled(Delete)`
  height: 25px;
  width: 25px;
`;
