import React from "react";
import { useNavigate } from "react-router";
import useModal from "../hooks/useModal";
import { Calendar as CalendarIcon } from "@styled-icons/boxicons-solid/Calendar";
import { PersonBadgeFill } from "@styled-icons/bootstrap/PersonBadgeFill";
import SchoolYearModal from "./SchoolYearModal";
import { PrimaryButton } from "../../../../../utils/shared/styled";
// import { PrimaryButton } from "../../../../../utils/styled";

const ButtonsSection = () => {
  const Navigate = useNavigate();

  return (
    <div className="mt-4">
      <PrimaryButton
        className="fs-6 mb-4"
        type="submit"
        width="100%"
        onClick={() => Navigate("/dashboard/recent-posts")}
      >
        <CalendarIcon width={25} height={25} className="me-2" />
        View Recent Posts
      </PrimaryButton>
      <PrimaryButton
        onClick={() => Navigate("/dashboard/school-dates")}
        className="fs-6"
        type="submit"
        width="100%"
      >
        <PersonBadgeFill width={25} height={25} className="me-2" />
        Update School Year
      </PrimaryButton>
    </div>
  );
};
export default ButtonsSection;
