import { useEffect, useMemo, useState } from "react";
import usePayments from "src/components/Dashboard/MyPayments/hooks/usePayments";
import store from "src/stores/UserStore";
import { filterPaymentsByDateRange } from "../helpers";
import { paymentsMap, paymentTypes } from "src/constants/paymentsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  getAdminPaymentName,
  getTeacherPaymentName,
} from "../../ViewPaymentInfoModal/PaymentInfoBody/helpers";

import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import PaymentInfoChart from "../../ViewPaymentInfoModal/PaymentInfoBody/PaymentInfoChart";
import { Divider } from "antd";
import TotalPaymentsList from "../../ViewPaymentInfoModal/PaymentInfoBody/PrivateLessonsSection/TotalPaymentsList";

const PaymentDetailsModal = ({ modalData, filter }) => {
  const { user } = store;
  const { isModalOpen, toggleModal } = modalData;
  const { initialData } = usePayments({ userId: user?.uid });

  const [filteredPayments, setFilteredPayments] = useState([]);

  useEffect(() => {
    setFilteredPayments(
      filterPaymentsByDateRange(initialData?.userPaymentsList, filter)?.flatMap(
        (item) => item?.payments
      )
    );
  }, [initialData?.userPaymentsList, filter]);

  const paymentsChartData = useMemo(() => {
    const groupedPayments = filteredPayments.reduce((acc, payment) => {
      const { totalPaid } = payment;

      const paymentMap = paymentsMap.find(
        (map) =>
          payment.type === map.paymentType &&
          payment.fractionPerUnit === map.fractionPerUnit &&
          payment.calculatedBy === map.paymentCalculationType
      );

      const paymentName =
        user?.role === UserRole.TEACHER
          ? getTeacherPaymentName(payment?.type, paymentMap)
          : getAdminPaymentName(payment?.type);

      if (!acc[paymentName]) {
        acc[paymentName] = {
          name: paymentName,
          value: 0,
          type: payment?.type,
        };
      }

      acc[paymentName].value += totalPaid;

      return acc;
    }, {});

    return Object.values(groupedPayments);
  }, [filteredPayments]);

  const totalPayment = paymentsChartData?.reduce(
    (acc, payment) => acc + payment.value,
    0
  );

  return (
    <StyledModal isOpen={isModalOpen} centered toggle={toggleModal}>
      <StyledModalHeader toggle={toggleModal}>
        Payments from {filter[0]} to {filter[1]}
      </StyledModalHeader>
      <ModalBody>
        <PaymentInfoChart
          subtitle={`Period: ${filter[0]} - ${filter[1]}`}
          data={paymentsChartData?.filter(
            (payment) => payment?.type !== paymentTypes.REIMBURSEMENT
          )}
          userType={user?.role === UserRole.TEACHER ? "teacher" : "admin"}
        />
        <Divider />
        <TotalPaymentsList
          payments={paymentsChartData}
          userType={user?.role === UserRole.TEACHER ? "teacher" : "admin"}
        />
        <Divider />
        <h4
          style={{ color: "#269900" }}
          className="d-flex justify-content-between fw-bold mt-5"
        >
          <span>Total Payments:</span>
          <span>${totalPayment?.toLocaleString("en-US")}</span>
        </h4>
      </ModalBody>
    </StyledModal>
  );
};

export default PaymentDetailsModal;
