import styled from "styled-components";
import { Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";

export const CustomLabel = styled(Label)`
  display: block;
  color: black;
`;

export const CustomSelectField = styled.select`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "auto")};
  outline: none;
  padding: 7px;
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 7px;

  &:disabled {
    background-color: #e6e6e6;
  }
`;
export const CustomInput = styled.input`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "auto")};
  outline: none;
  padding: 7px;
  text-align: center;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  &:disabled {
    background-color: #e6e6e6;
  }
`;

export const AvailabilitiesContainer = styled.div`
  background-color: #f3f3f3cc;
  padding: 15px;
  border-radius: 7px;
`;

export const CustomTextarea = styled.textarea`
  background-color: #f9f5f7;
  width: ${({ width }) => (width ? width : "100%")};
  min-height: 115px;
  outline: none;
  padding: 7px;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  &:disabled {
    background-color: #e6e6e6;
  }
`;
