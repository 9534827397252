import RouteContent from "../../common/RouteContent";
import styled from "styled-components";
import FeedItemsSection from "../common/components/FeedItemsSection";
import LatestFeedItemSection from "../common/components/LatestFeedItemSection";

const FeedSectionContainer = styled.div`
  height: 80vh;
`;
const AdminDashboard = () => {
  return (
    <RouteContent title="Dashboard">
      <div className="mb-3">
        <LatestFeedItemSection />
      </div>
      <FeedSectionContainer>
        <FeedItemsSection />
      </FeedSectionContainer>
    </RouteContent>
  );
};
export default AdminDashboard;
