import React, { useMemo } from "react";
import { momentLocalizer, Views, Day } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { CustomCalendar } from "./styled";
import WeekHeader from "./components/WeekHeader";
import MobileCustomToolbar from "./components/MobileCustomToolbar";
import {
  getCurrentMomentTimezoneValue,
  updatedMomentNow,
} from "src/utils/helpers";

const localizer = momentLocalizer(moment);

const CustomCalendarComponent = ({
  events,
  components,
  date,
  showCurrentTimeIndicatorDot,
  ...rest
}) => {
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;

  // new components (can be different for each invokation based on the props)
  const newComponents = components || {};

  // default custom components
  const { components: defaultComponents } = useMemo(
    () => ({
      components: {
        week: {
          header: WeekHeader,
        },
        // only shows the custom toolbar for mobile screens
        ...(!isDesktopScreen && {
          toolbar: MobileCustomToolbar,
        }),
      },
    }),
    [isDesktopScreen]
  );
  return (
    <>
      <CustomCalendar
        showCurrentTimeIndicatorDot={showCurrentTimeIndicatorDot}
        defaultView="week"
        showMultiDayTimes
        defaultDate={updatedMomentNow().toDate()}
        events={events}
        localizer={localizer}
        // removes the time before the event title (week-day views)
        formats={{
          eventTimeRangeFormat: () => {
            return "";
          },
        }}
        components={{ ...defaultComponents, ...newComponents }}
        scrollToTime={updatedMomentNow().toDate()}
        dayLayoutAlgorithm={"no-overlap"}
        getNow={() => {
          return updatedMomentNow().toDate();
        }}
        date={date}
        step={15}
        timeslots={4}
        {...rest}
      />
    </>
  );
};
export default CustomCalendarComponent;
