import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import store from "src/stores/UserStore";
import { updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import { ModalBody } from "reactstrap";
import { PrimaryButton } from "src/utils/shared/styled";
import { FirebaseContext } from "src/components/Firebase";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ButtonsWrapper, InputWrapper, StyledInput } from "./styled";

const WithdrawModal = ({ isOpen, toggle }) => {
  const [reasonValue, setReasonValue] = useState("");
  const firebase = useContext(FirebaseContext);
  const { user } = store;
  const userToUpdate = firebase.user(user.uid);
  const navigate = useNavigate();

  const handleApplicationWithdraw = async () => {
    toggle();
    await firebase.signOut();
    await updateDoc(userToUpdate, {
      withdrawal: {
        requested: true,
        reason: reasonValue,
      },
    });

    navigate("/");
    toast.success("Application withdrawn");
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
      <StyledModalHeader toggle={toggle}>
        Application withdrawal
      </StyledModalHeader>
      <ModalBody>
        <InputWrapper>
          <label>
            Please provide a reason why you want to withdraw your application
            <StyledInput
              placeholder="Reason"
              onChange={({ target }) => setReasonValue(target?.value)}
              value={reasonValue}
            />
          </label>
        </InputWrapper>
        <ButtonsWrapper>
          <PrimaryButton
            type="button"
            onClick={toggle}
            backgroundColor="#681e4677"
            width="50%"
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            type="button"
            onClick={handleApplicationWithdraw}
            backgroundColor="#681e46DD"
            width="50%"
          >
            Confirm
          </PrimaryButton>
        </ButtonsWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default WithdrawModal;
