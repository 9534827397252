export const logTypes = {
  absence: { code: 1, name: "Absence" },
  makeUp: { code: 2, name: "Make Up" },
};

export const logActionTypes = {
  CREATE: {
    code: 1,
    name: "Created",
  },
  UPDATE: {
    code: 2,
    name: "Updated",
  },
  DELETE: {
    code: 3,
    name: "Deleted",
  },
};

const absenceLogObj = {
  createdAt: new Date(),
  type: logTypes.absence.code,
  docId: "a;klsfqe34",
  absenceType: 1,
  studentId: "asgfw3r2134",
  studentName: "Ahmed Trial 3",
  teacherId: "adgwetqe2rq",
  teacherName: "Teacher 7",
  date: new Date(),
  instrumentId: "adsgflkwenr",
  action: logActionTypes.CREATE.code,
  locationId: "asdfoikeqer",
  requestById: "asdqewqwe",
  requestByName: "Super Admin 1",
  requestByRole: "Superadmin",
};
