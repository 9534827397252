import { arrayUnion, updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import {
  getRecurringOccuranceWeekNumber,
  getTimeDiffInMins,
} from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";

const usePLModificationData = ({
  event,
  modalData,
  closeDetailsModal,
  refreshData,
}) => {
  const firebase = useContext(FirebaseContext);
  const { start: occuranceDate, currentTimeline } = event;
  const { startDate } = currentTimeline || {};
  const originalDuration = getTimeDiffInMins(event.start, event.end);

  const [modificationsObj, setModificationsObj] = useState({
    title: {
      isEnabled: false,
      value: "",
    },
    date: {
      isEnabled: false,
      value: "",
    },
    duration: {
      isEnabled: false,
      value: "",
    },
    instrumentId: {
      isEnabled: false,
      value: "",
    },
  });

  const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);

  const handleFieldInputChange = (e) => {
    setModificationsObj((oldVal) => ({
      ...oldVal,
      [e.target.name]: {
        ...oldVal[e.target.name],
        value: e.target.value,
      },
    }));
  };
  const enableField = (key) => {
    setModificationsObj((oldVal) => ({
      ...oldVal,
      [key]: {
        ...oldVal[key],
        isEnabled: true,
      },
    }));
  };
  const disableField = (key) => {
    setModificationsObj((oldVal) => ({
      ...oldVal,
      [key]: {
        ...oldVal[key],
        isEnabled: false,
        value: "",
      },
    }));
  };

  const saveModifications = async () => {
    try {
      setLoadingSaveChanges(true);
      const { isValid, errorMessage } = validateInputFields();
      if (isValid) {
        const { title, date, duration, instrumentId } = modificationsObj;

        const parsedDate = moment(
          date.value,
          moment.HTML5_FMT.DATETIME_LOCAL
        ).toDate();

        const modificationObj = {
          id: uuidv4(),
          timelineObjId: currentTimeline.id,
          weekNumber: getRecurringOccuranceWeekNumber(startDate, occuranceDate),
          // by default we apply the original event data , and if we modified any field it overrides original data
          date: occuranceDate,
          ...(date.isEnabled && {
            date: parsedDate,
          }),
          title: event.title,
          ...(title.isEnabled && {
            title: title.value,
          }),
          duration: originalDuration,
          ...(duration.isEnabled && {
            duration: parseInt(duration.value),
          }),
          instrumentId: event.instrumentId,
          ...(instrumentId.isEnabled && {
            instrumentId: instrumentId.value,
          }),
        };

        await updateDoc(firebase.PrivateLesson(event.id), {
          modifications: arrayUnion(modificationObj),
        });

        // const modificationEnd = moment(modificationObj.date)
        //   .add(modificationObj.duration, "minutes")
        //   .toDate();
        // const activityModificationObj = {
        //   id: modificationObj.id,
        //   timelineObjId: currentTimeline.id,
        //   start_time: modificationObj.date,
        //   end_time: modificationEnd,
        //   weekNumber: modificationObj.weekNumber,
        // };
        // await updateDoc(firebase.userActivity(event.id), {
        //   modifications: arrayUnion(activityModificationObj),
        // });

        toast.success("Lesson Updated Successfully");
        modalData.closeModal();
        closeDetailsModal();
        refreshData();
      } else {
        toast.warn(errorMessage);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSaveChanges(false);
    }
  };

  const validateInputFields = () => {
    let isValid = true,
      invalidKey = "",
      errorMessage;
    if (
      Object.values(modificationsObj)
        .map(({ isEnabled }) => isEnabled)
        .every((isEnabled) => !isEnabled)
    ) {
      return {
        isValid: false,
        errorMessage: "Have to change at least 1 field",
      };
    }

    for (let key of Object.keys(modificationsObj)) {
      const valueObj = modificationsObj[key];
      if (valueObj.isEnabled && !valueObj.value) {
        isValid = false;
        invalidKey = key;
      }
    }
    errorMessage = `Please fill all enabled fields: ${invalidKey}`;

    return { isValid, errorMessage };
  };

  return {
    modificationsObj,
    handleFieldInputChange,
    enableField,
    disableField,
    loadingSaveChanges,
    saveModifications,
  };
};

export default usePLModificationData;
