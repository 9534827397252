import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const ConcertImage = styled.img`
  width: 100%;
  min-width: 250px;
  height: 230px;
  object-fit: cover;

  @media (max-width: ${ScreenSize.LG}px) {
    height: 250px;
  }
`;
export const PrimaryText = styled.p`
  margin: 0;
`;
export const SecondaryText = styled.p`
  margin: 0;
  color: #a0a0a0;
`;

//Concert Details
export const ConcertDetailsSectionContainer = styled.div`
  flex: 1.25;
  border-radius: 13px;
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
  position: relative;
`;
export const CornerRibbon = styled.div`
  position: absolute;
  text-align: center;
  line-height: 100px;
  letter-spacing: 1px;
  top: -10px;
  left: -103px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  width: 250px;
  height: 70px;
`;

export const CustomButton = styled.button`
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;
  color: #e35567;
`;
