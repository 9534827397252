export const attachAvailableDaysToTeacher = (teacher, availableDays = []) => {
  const teacherWithAvailableDays = {
    ...teacher,
    teacherDays: availableDays,
  };
  return teacherWithAvailableDays;
};
export const addInstrumentsToTeacher = (
  teacher = {},
  instruments = {},
  programs = {}
) => {
  if (Object.values(instruments).length && Object.values(programs).length) {
    const teacherWithInstruments = {
      ...teacher,
      instrumentsInfo: teacher.instrumentsInfo?.map((instrumentInfo) => ({
        ...instrumentInfo,
        instrument: instruments[instrumentInfo?.instrument],
        program: programs[instrumentInfo?.program],
      })),
    };

    return teacherWithInstruments;
  } else {
    return teacher;
  }
};

export const filterInitialDataByLocation = (initialData, locationId) => {
  const {
    teacherPLs = [],
    teacherTLs = [],
    teacherMakeupLessons = [],
    teacherAppointments = [],
  } = initialData;
  const filteredTLs = teacherTLs.filter((tl) =>
    tl.locations?.includes(locationId)
  );
  const filteredMakeupLessons = teacherMakeupLessons.filter(
    (makeUp) => makeUp.locationId === locationId
  );

  const filteredAppointments = teacherAppointments.filter(
    (appointment) => appointment.locationId === locationId
  );
  return {
    ...initialData,
    teacherTLs: filteredTLs,
    teacherMakeupLessons: filteredMakeupLessons,
    teacherAppointments: filteredAppointments,
  };
};
