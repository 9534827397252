import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { updatedMomentNow } from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";

const useWithdrawalData = ({
  user,
  lessonWithCurrentTimeline,
  onSuccess,
  closeModal,
}) => {
  const { withdrawPLAndActivity } = useFirebaseFns();

  const lessonStartDate = lessonWithCurrentTimeline.currentTimeline?.startDate;

  // last lesson date that will appear on the calendar, then the lesson will disappear after that date
  const [lastDate, setLastDate] = useState(null);
  const [reason, setReason] = useState("");
  const [loadingSaveWithdrawal, setLoadingSaveWithdrawal] = useState(false);

  const submitWithdrawalRequest = async () => {
    try {
      if (loadingSaveWithdrawal) return;
      if (!reason || !lastDate) {
        toast.warn("Please Enter All Required Fields");
        return;
      }

      setLoadingSaveWithdrawal(true);

      const fullLastDate = moment(lastDate)
        .set({
          hours: moment(lessonStartDate).get("hours"),
          minutes: moment(lessonStartDate).get("minutes"),
        })
        .toDate();
      const withdrawalDate = moment(fullLastDate).add(1, "week").toDate();

      const requestDate = updatedMomentNow().toDate();
      const formattedRequestDate = moment(requestDate).format("MM-DD-YYYY");
      const formattedLastDate = moment(lastDate).format("MM-DD-YYYY");
      const withdrawalNote = `${formattedRequestDate} : Received Withdrawal Request, last lesson date is ${formattedLastDate}`;

      const withdrawalObj = {
        withdrawal: {
          requestDate,
          requestBy: user.uid,

          withdrawalDate,
          reason,
        },
        note: `${lessonWithCurrentTimeline.note}\n${withdrawalNote}`,
      };

      await withdrawPLAndActivity(withdrawalObj, lessonWithCurrentTimeline.id);

      toast.success("Withdrawal Request Submitted");
      onSuccess();
      closeModal();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSaveWithdrawal(false);
    }
  };

  console.log(reason);
  return {
    lastDate,
    setLastDate,
    loadingSaveWithdrawal,
    submitWithdrawalRequest,
    reason,
    setReason,
  };
};

export default useWithdrawalData;
