import {
  FiltersWrapper,
  InstrumentsItem,
  InstrumentsList,
  SearchInput,
} from "./styled";
import { icons } from "../staffInfo";

const Filters = ({
  onInstrumentsSelect,
  instrumentsValue,
  onSearch,
  searchValue,
}) => {
  return (
    <FiltersWrapper>
      <InstrumentsList>
        {icons?.map((icon) => (
          <InstrumentsItem selected={instrumentsValue?.includes(icon.name)}>
            <button
              type="button"
              onClick={() => onInstrumentsSelect(icon?.name)}
              title={icon.name}
            >
              <icon.Icon size={48} />
            </button>
          </InstrumentsItem>
        ))}
      </InstrumentsList>
      <SearchInput
        type="text"
        placeholder="input name to search"
        value={searchValue}
        onChange={onSearch}
      />
    </FiltersWrapper>
  );
};

export default Filters;
