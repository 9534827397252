import { getDocs, Timestamp, updateDoc } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import CustomDropDown from "src/components/common/DropDown";
import { FirebaseContext } from "src/components/Firebase";
import { SCHOOL_DATES_ALERTS, SCHOOL_DATES_TABS, SchoolDatesContext } from "..";
import { getTimeDiffInMins } from "src/utils/helpers";

const MakeupDeadlines = ({ changeTab, showInfoModal }) => {
  const firebase = useContext(FirebaseContext);
  const {
    schoolYearsOptions,
    schoolYears,
    setSchoolYears,
    locations,
    lockSchoolYear,
    setIsUnlockConfirmModalOpen,
    selectedLocation,
    setSelectedLocation,
    locationsOptions,
    selectedSchoolYear,
    setSelectedSchoolYear,
  } = useContext(SchoolDatesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolYearMakeupDeadlines, setSchoolMakeupDeadlines] = useState([]);
  console.log({ schoolYearMakeupDeadlines });

  useEffect(() => {
    const schoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    if (schoolYear) {
      const makeupDeadlinesObject =
        schoolYear?.locations?.[selectedLocation.value]?.makeups || {};
      const makeupDeadlinesArray = [];
      for (const key in makeupDeadlinesObject) {
        const makeupDeadline = makeupDeadlinesObject[key];
        if (
          makeupDeadline?.start_date?.toDate &&
          makeupDeadline?.end_date?.toDate &&
          makeupDeadline?.deadline?.toDate
        ) {
          makeupDeadlinesArray.push({
            start_date: moment(makeupDeadline.start_date.toDate()).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(makeupDeadline.end_date.toDate()).format(
              "YYYY-MM-DD"
            ),
            deadline: moment(makeupDeadline.deadline.toDate()).format(
              "YYYY-MM-DD"
            ),
          });
        }
      }
      const sortedMakeupDeadlinesArray = makeupDeadlinesArray.sort((a, b) => {
        const firstDate = moment(a.start_date, "YYYY-MM-DD");
        const secondDate = moment(b.start_date, "YYYY-MM-DD");

        return getTimeDiffInMins(secondDate, firstDate);
      });
      setSchoolMakeupDeadlines(sortedMakeupDeadlinesArray);
    }
  }, [selectedSchoolYear, selectedLocation]);
  const saveSchoolYearMakeups = async () => {
    toast.info("Saving...");
    setIsLoading(true);
    const { value } = selectedSchoolYear;
    const currentSchoolYear = schoolYears.find(({ id }) => id === value);
    const newMakeupsData = schoolYearMakeupDeadlines
      .filter((makeup) => {
        return (
          makeup.start_date !== "" &&
          makeup.end_date !== "" &&
          makeup.deadline !== ""
        );
      })
      .map((makeup) => ({
        start_date: Timestamp.fromDate(
          moment(makeup.start_date, "YYYY-MM-DD")
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        end_date: Timestamp.fromDate(
          moment(makeup.end_date, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        deadline: Timestamp.fromDate(
          moment(makeup.deadline, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
      }));
    if (newMakeupsData.length === 0) {
      toast.error("You must have at least one section for makeup deadlines");
      return;
    }
    const locationId = selectedLocation.value;
    const newDocumentData = {
      isLocked: true,
      locations: {
        ...(currentSchoolYear?.locations || {}),
        [locationId]: {
          ...(currentSchoolYear?.locations?.[locationId] || {}),
          makeups: {
            ...newMakeupsData,
          },
        },
      },
    };
    await updateDoc(firebase.getSchoolYearDoc(value), newDocumentData);
    toast.success("Success.");
    if (currentSchoolYear?.locations?.[locationId]?.dates === undefined) {
      toast.warn(SCHOOL_DATES_ALERTS.schoolDates, {
        autoClose: false,
      });
      showInfoModal("Required Data", SCHOOL_DATES_ALERTS.schoolDates);
      changeTab(SCHOOL_DATES_TABS[0]);
    } else if (
      currentSchoolYear?.locations?.[locationId]?.breaks === undefined
    ) {
      toast.warn(SCHOOL_DATES_ALERTS.schoolBreaks, {
        autoClose: false,
      });
      showInfoModal("Required Data", SCHOOL_DATES_ALERTS.schoolBreaks);
      changeTab(SCHOOL_DATES_TABS[1]);
    } else {
      setSelectedSchoolYear((previous) => ({ ...previous, isLocked: true }));
      setSchoolYears((previous) =>
        previous.map((schoolYear) => {
          if (schoolYear.id === value) {
            return {
              ...schoolYear,
              isLocked: true,
              locations: {
                ...schoolYear.locations,
                [locationId]: {
                  ...(schoolYear.locations?.[locationId] || {}),
                  makeups: {
                    ...newMakeupsData,
                  },
                },
              },
            };
          }
          return schoolYear;
        })
      );
      showInfoModal("All set", SCHOOL_DATES_ALERTS.allSet);
    }
    setIsLoading(false);
  };
  const updateSchoolYearMakeups = (index, key, value) => {
    setSchoolMakeupDeadlines((previous) => {
      previous[index][key] = value;
      return [].concat(previous);
    });
  };

  const addAnotherMakeup = () => {
    // if (schoolYearMakeupDeadlines.length < 3) {
    setSchoolMakeupDeadlines((previous) => {
      const tempArray = [].concat(previous);
      tempArray.push({
        start_date: "",
        end_date: "",
        deadline: "",
      });
      return tempArray;
    });
    // }
  };
  const removeMakeup = (index) => {
    setSchoolMakeupDeadlines((previous) => {
      const tempArray = [].concat(previous);
      tempArray.splice(index, 1);
      return tempArray;
    });
  };
  const isYearDataNotCompleted = () => {
    const currentSelectedSchoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    return (
      Object.keys(
        currentSelectedSchoolYear?.locations?.[selectedLocation.value] || {}
      ).length < 3
    );
  };
  return (
    <div className="h-100 w-100 p-4">
      {isLoading ? (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <SubmitSpinner style={{ color: "#681E46" }} />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0">
              <div className="h5 mb-1">School Year</div>
              <CustomDropDown
                label={selectedSchoolYear.label}
                options={schoolYearsOptions}
                keyName={"label"}
                value={selectedSchoolYear.label}
                onOptionClick={setSelectedSchoolYear}
              />
            </div>
            <span className="col-1 px-3" />
            <div className="inputContainer col-4 ms-2">
              <div className="h5 mb-1">Location</div>
              <CustomDropDown
                label={selectedLocation.label}
                options={locationsOptions}
                keyName={"label"}
                value={selectedLocation.label}
                onOptionClick={setSelectedLocation}
              />
            </div>
            {selectedSchoolYear.isLocked ? (
              <Button
                onClick={() => setIsUnlockConfirmModalOpen(true)}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="danger"
              >
                Unlock
              </Button>
            ) : (
              <Button
                onClick={lockSchoolYear}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="success"
              >
                Lock
              </Button>
            )}
          </div>
          {isYearDataNotCompleted() ? (
            <div className="alert alert-warning">
              Please fill the <b>school year dates</b>, <b>school breaks</b>,
              and <b>makeup deadlines</b> and save
            </div>
          ) : null}
          {schoolYearMakeupDeadlines.map((makeup, index) => (
            <div key={index}>
              <div className="d-flex align-items-center mb-4">
                <span className="h5 me-2">Section {index + 1}</span>
                <div className="inputContainer col-4 m-0 me-2">
                  <input
                    disabled={selectedSchoolYear.isLocked}
                    className="InputField w-100 m-0"
                    type="date"
                    name={`start_date_${index}`}
                    value={makeup.start_date}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "start_date",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <span className="px-3">TO</span>
                <div className="inputContainer col-4 m-0">
                  <input
                    disabled={selectedSchoolYear.isLocked}
                    className="InputField w-100 m-0"
                    type="date"
                    name={`end_date_${index}`}
                    value={makeup.end_date}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "end_date",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <span className="h5 me-2">Deadline</span>
                <div className="inputContainer col-4 m-0 me-2">
                  <input
                    disabled={selectedSchoolYear.isLocked}
                    className="InputField w-100 m-0"
                    type="date"
                    name={`deadline_${index}`}
                    value={makeup.deadline}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "deadline",
                        e.target.value
                      );
                    }}
                  />
                </div>
                {!selectedSchoolYear.isLocked ? (
                  schoolYearMakeupDeadlines.length > 1 ? (
                    <Button
                      className="ms-5"
                      color="danger"
                      onClick={() => removeMakeup(index)}
                    >
                      Remove this section
                      <i className="ms-2 fa fa-times" />
                    </Button>
                  ) : null
                ) : null}
              </div>
            </div>
          ))}
          {!selectedSchoolYear.isLocked ? (
            <div className="d-flex justify-content-center">
              <button
                onClick={saveSchoolYearMakeups}
                className="submitbutton h5 px-5"
              >
                Save
              </button>
              {/* { schoolYearMakeupDeadlines.length < 3 ? ( */}
              <button
                onClick={addAnotherMakeup}
                className="submitbutton h5 px-5 ms-3"
                style={{ width: 200 }}
              >
                Add More
              </button>
              {/* ) : null } */}
            </div>
          ) : null}
          {/*  {isUnlockConfirmModalOpen ?*/}
          {/*<UnlockConfirmModal close={() => setIsUnlockConfirmModalOpen(false)} onConfirm={unlockSchoolYear}/> : null}*/}
        </div>
      )}
    </div>
  );
};
export default MakeupDeadlines;
