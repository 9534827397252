import React, { useMemo, useState } from "react";
import ViewPaymentInfoModal from "src/components/Dashboard/common/components/ViewPaymentInfoModal";
import useModal from "src/hooks/useModal";
import { calculatePaymentTotal, momentPayment } from "src/utils/helpers";
import {
  CustomTable,
  CustomTableContainer,
  PrimaryButton,
  TableRow,
} from "src/utils/shared/styled";

const ReportTable = ({ paymentsTotal, payments, users }) => {
  const paymentInfoModaltData = useModal();

  const [currentPaymentId, setCurrentPaymentId] = useState("");

  const currentPaymentObj = useMemo(() => {
    if (!currentPaymentId) return;

    const paymentObj = payments.find(({ id }) => id === currentPaymentId);

    return paymentObj;
  }, [currentPaymentId, payments]);

  const handleOpenClick = (paymentId) => {
    setCurrentPaymentId(paymentId);
    paymentInfoModaltData.openModal();
  };
  return (
    <div>
      {paymentsTotal ? (
        <div>
          <h4>Total Payment: ${paymentsTotal}</h4>
        </div>
      ) : null}
      <CustomTableContainer>
        <CustomTable>
          <thead>
            <TableRow>
              <th>
                <div>Pay Date</div>
              </th>
              <th>
                <div>Name</div>
              </th>
              <th>
                <div>Role</div>
              </th>
              <th>
                <div>Total Pay</div>
              </th>
              <th>
                <div>Action</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {payments.map((payment, i) => {
              const { startDate, userId, userRole, payments } = payment;

              const formattedStartDate =
                momentPayment(startDate).format("MM/DD/YYYY");

              const paymentTotal = calculatePaymentTotal(payments);

              const paymentUser = users?.find(({ id }) => id === userId);
              const paymentUserName = paymentUser?.fullName;

              const isEvenRow = i % 2 === 0;

              return (
                <TableRow
                  style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                  key={payment.id}
                >
                  <td>{formattedStartDate}</td>
                  <td>{paymentUserName}</td>
                  <td>{userRole}</td>
                  <td>{`$${paymentTotal}`}</td>
                  <td>
                    <PrimaryButton
                      onClick={() => handleOpenClick(payment.id)}
                      width="100px"
                      padding="6px"
                    >
                      Open
                    </PrimaryButton>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>

      {/* MODALS */}
      {paymentInfoModaltData.isModalOpen && currentPaymentObj && (
        <ViewPaymentInfoModal
          modalData={paymentInfoModaltData}
          paymentObj={currentPaymentObj}
          onPaymentDeleteSuccess={() => window.location.reload()}
        />
      )}
    </div>
  );
};

export default ReportTable;
