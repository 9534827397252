import video1 from "src/assets/videos/homepage-video-1.mp4";
import { ModalBody } from "reactstrap";
import { StyledModal, StyledModalHeader } from "../styled";

import { PlayerWrapper, StyledPlayer } from "./styled";

const VideoModal = ({ isOpen, toggle }) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
      <StyledModalHeader toggle={toggle}>Video</StyledModalHeader>
      <ModalBody>
        <PlayerWrapper>
          <StyledPlayer url={video1} controls playing volume={0.3} />
          <p>Sign up as guest to view more</p>
        </PlayerWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default VideoModal;
