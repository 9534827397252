import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { absenceTypes } from "src/constants/absenceTypes";

const useAbsenceData = ({ teacherId }) => {
  const { getTeacherAbsences, getPrivateLessonsByTeacherId } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    teacherTAs: [],
    teacherPLs: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [teacherTAs, teacherPLs] = await Promise.all([
          getTeacherAbsences(teacherId, absenceTypes.teacherAbsence.code),
          getPrivateLessonsByTeacherId(teacherId),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          teacherTAs,
          teacherPLs,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  return {
    initialData,
    loadingInitialData,
    refreshData,
  };
};

export default useAbsenceData;
