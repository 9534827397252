import React from "react";
import { PrimaryButton } from "src/utils/shared/styled";
import { KeyboardArrowDown } from "@styled-icons/material/KeyboardArrowDown";
import ActionsDropdownContent from "./ActionsDropdownContent";
import styled from "styled-components";
import DropdownMenuComponent from "../CustomDropdownMenu";

const StyledArrowDown = styled(KeyboardArrowDown)`
  color: #fff;
  width: 25px;
  height: 25px;
`;
const DropdownButton = ({ loadingAction = false, optionsList = [] }) => {
  const DropdownToggleComponent = () => {
    return (
      <PrimaryButton
        padding="7px"
        borderRadius="6px"
        width="130px"
        backgroundColor="#e357a2"
      >
        Actions <StyledArrowDown />
      </PrimaryButton>
    );
  };
  const DropdownContent = () => (
    <div>
      <ActionsDropdownContent
        loadingAction={loadingAction}
        optionsList={optionsList}
      />
    </div>
  );
  return (
    <div className="text-end">
      <DropdownMenuComponent
        dropdownToggleComponent={DropdownToggleComponent}
        dropdownContent={DropdownContent}
        dropdownMenuTopValue="16px"
      />
    </div>
  );
};

export default DropdownButton;
