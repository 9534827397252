import { useEffect, useState } from "react";
import useModal from "src/hooks/useModal";
import filterTypeOptions from "./filterTypeOptions";

import StyledBarContent from "src/components/common/StyledBarContent";
import { ContentContainer, CustomButtonsWrapper } from "../styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { Select } from "antd";
import PaymentDetailsModal from "../PaymentDetailsModal";
import CustomTooltip from "../../CustomTooltip";
import CustomRangePicker from "../../CustomRangePicker";

const PaymentHistoryBody = () => {
  const [currentFilter, setCurrentFilter] = useState("month");
  const [filterValue, setFilterValue] = useState([]);
  const modalData = useModal();

  useEffect(() => {
    setFilterValue([]);
  }, [currentFilter]);

  const handleDateChange = (value) => {
    const formattedDates = value?.map((date) => date.format("MM/DD/YYYY"));
    setFilterValue(formattedDates);
  };

  const rangePickerRender =
    currentFilter === "month" ? (
      <CustomRangePicker picker="month" onChange={handleDateChange} />
    ) : currentFilter === "quarter" ? (
      <CustomRangePicker picker="quarter" onChange={handleDateChange} />
    ) : currentFilter === "year" ? (
      <CustomRangePicker picker="year" onChange={handleDateChange} />
    ) : currentFilter === "custom" ? (
      <CustomRangePicker onChange={handleDateChange} />
    ) : (
      ""
    );

  return (
    <>
      <StyledBarContent overFlowContent="visible" title="Report">
        <ContentContainer>
          <Select
            className="w-100"
            virtual={false}
            placeholder="Select filter"
            allowClear
            options={filterTypeOptions}
            onChange={(value) => setCurrentFilter(value)}
            value={currentFilter}
          />
          {rangePickerRender}
        </ContentContainer>
      </StyledBarContent>
      <CustomButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={modalData.openModal}
          id="confirm-filter-btn"
          disabled={filterValue?.length !== 2}
        >
          Confirm
        </PrimaryButton>
        {filterValue?.length !== 2 && (
          <CustomTooltip anchorSelect="#confirm-filter-btn">
            Please select the date range
          </CustomTooltip>
        )}
      </CustomButtonsWrapper>
      {filterValue?.length === 2 && (
        <PaymentDetailsModal modalData={modalData} filter={filterValue} />
      )}
    </>
  );
};

export default PaymentHistoryBody;
