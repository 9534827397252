import moment from "moment";
import { toast } from "react-toastify";
import {
  isPerLessonAbsence,
  isTeacherAbsence,
} from "src/constants/absenceTypes";
import {
  isRecurringBreakEvent,
  isRecurringEvent,
} from "src/constants/eventsEnum";
import { scheduleTypes } from "src/constants/scheduleTypes";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  getFullDateFromDateAndTimeInputs,
  isActivityOverlappingWithRecurringEvent,
  isActivityOverlappingWithSingleTimeEvent,
  mapDurationOrAllDayAbsenceToActivity,
  prepareTeacherActivities,
} from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

const useTeacherBreakData = ({ teacherId, refreshData, modalData, user }) => {
  const {
    getTeacherActivities,
    getTeacherAbsences,
    createTeacherBreak,
    createActivity,
  } = useFirebaseFns();

  const onFormSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const { date, startTime, duration, type, reason } = values;
      const [teacherActivities = [], teacherAbsences = []] = await Promise.all([
        getTeacherActivities(teacherId),
        getTeacherAbsences(teacherId),
      ]);

      const isRecurringBreak = isRecurringBreakEvent(type);
      const breakStartDate = getFullDateFromDateAndTimeInputs(date, startTime);
      const breakEndDate = moment(breakStartDate)
        .add(duration, "minutes")
        .toDate();

      const preparedActivities = prepareTeacherActivities(
        teacherActivities,
        teacherId
      );

      const isTeacherBreakOverlapping = isRecurringBreak
        ? isRecurringBreakOverlappingWithActivities(
            breakStartDate,
            breakEndDate,
            teacherId,
            preparedActivities
          )
        : isSingleTimeBreakOverlappingWithActivities(
            breakStartDate,
            breakEndDate,
            teacherId,
            preparedActivities,
            teacherAbsences
          );
      if (isTeacherBreakOverlapping) {
        toast.warn(
          "Teacher Break is overlapping with another activity at that time"
        );
        return;
      }

      let breakObj;
      let activityObj;
      if (isRecurringBreak) {
        const timelineObj = {
          id: uuidv4(),
          date: breakStartDate,
          duration: parseInt(duration),
          requestedAt: new Date(),
          requestedBy: user?.uid,
        };

        breakObj = {
          createdAt: new Date(),
          type: parseInt(type),
          reason,
          userRole: UserRole.TEACHER,
          userId: teacherId,
          timeline: [timelineObj],
        };
        const activityStart = timelineObj.date;
        const breakDuration = timelineObj.duration;

        const activityEnd = moment(activityStart)
          .add(breakDuration, "minutes")
          .toDate();

        const breakTimelineObj = {
          id: timelineObj.id, // same id that the timeline has in the original doc
          start_time: activityStart, // first lesson date
          end_time: activityEnd,
          location: "",
          userId: breakObj.userId,
          userRole: breakObj.userRole,
        };
        activityObj = {
          createdAt: new Date(),
          scheduleType: scheduleTypes.other.code,
          isActive: true,
          type: parseInt(type),
          timeline: [breakTimelineObj],
        };
      } else {
        breakObj = {
          createdAt: new Date(),
          date: breakStartDate,
          duration: parseInt(duration),
          type: parseInt(type),
          reason,
          userRole: UserRole.TEACHER,
          userId: teacherId,
          requestedBy: user?.uid,
        };

        const activityStart = breakObj.date;
        const breakDuration = breakObj.duration;

        const activityEnd = moment(activityStart)
          .add(breakDuration, "minutes")
          .toDate();
        activityObj = {
          createdAt: new Date(),
          start_time: activityStart,
          end_time: activityEnd,
          isActive: true,
          location: "",
          scheduleType: scheduleTypes.other.code,
          type: parseInt(type),
          userRole: UserRole.TEACHER,
          userId: teacherId,
          isVirtual: false,
        };
      }
      console.log({ breakObj });
      const breakRes = await createTeacherBreak(breakObj);

      if (!breakRes?.id) {
        toast.error("Can't find just created break id");
        return;
      }

      console.log({ activityObj });
      const activityRes = await createActivity(breakRes.id, activityObj);
      toast.success("Break Created Successfully");
      refreshData();
      modalData.closeModal();
    } catch (error) {
      toast.error(error.message);
      console.log("saving teacher break error", error);
    } finally {
      setSubmitting(false);
    }
  };

  const isSingleTimeBreakOverlappingWithActivities = (
    breakStartDate,
    breakEndDate,
    teacherId,
    preparedActivities,
    teacherAbsences = []
  ) => {
    if (!preparedActivities?.length && !teacherAbsences?.length) return false;

    const teacherAllDayAndDurationTAs = teacherAbsences?.filter((absence) => {
      const isTA = isTeacherAbsence(absence.absenceType);
      const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
      return isTA && !isPerLesson;
    });

    const absencesActivities = teacherAllDayAndDurationTAs?.map((absence) =>
      mapDurationOrAllDayAbsenceToActivity(absence)
    );

    let isTeacherBreakOverlapping = false;
    // we dont care about absences in case of recurring break
    const allActivitiesArr = [...preparedActivities, ...absencesActivities];
    for (const activity of allActivitiesArr) {
      const isOverlapping = isActivityOverlappingWithSingleTimeEvent({
        eventStartDate: breakStartDate,
        eventEndDate: breakEndDate,
        teacherId,
        activity,
        absences: teacherAbsences,
      });
      if (isOverlapping) {
        isTeacherBreakOverlapping = true;
        break;
      }
    }

    return isTeacherBreakOverlapping;
  };

  const isRecurringBreakOverlappingWithActivities = (
    breakStartDate,
    breakEndDate,
    teacherId,
    preparedActivities
  ) => {
    if (!preparedActivities?.length) return false;

    // filters out past one time events
    const futureActivities = preparedActivities.filter((activity) => {
      if (!isRecurringEvent(activity.type, activity.privateLessonType)) {
        return moment(breakStartDate).isBefore(activity.end_time);
      } else {
        return true;
      }
    });

    let isOverlapping = false;
    for (const activity of futureActivities) {
      if (
        isActivityOverlappingWithRecurringEvent({
          eventStartDate: breakStartDate,
          eventEndDate: breakEndDate,
          teacherId,
          activity,
        })
      ) {
        isOverlapping = true;
        break;
      }
    }
    return isOverlapping;
  };

  return { onFormSubmit };
};

export default useTeacherBreakData;
