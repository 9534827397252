import React, { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { LessonDetailsModalContext } from '../../../common/Library/common/libraryContext';
import { NoteModalHeader } from '../../../common/styled-components/lessonNotesStyledComponents';
const ViewLessonDetailsModal = () => {
    const {lesson, isOpen, close} = useContext(LessonDetailsModalContext);

    return (
        <Modal
      className={"p-0"}
      centered
      isOpen={isOpen}
      size={'lg'}
    >
      <NoteModalHeader className="position-relative">
        <div className="position-absolute close-btn-container bg-white rounded-circle">
          <button onClick={close} className={"close border-0 outline-0"}>
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="curve-container position-absolute top-0 left-0 w-100">
          <svg
            width="100%"
            height="100%"
            id="svg"
            viewBox="0 0 750 150"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition duration-300 ease-in-out delay-150"
          >
            <path fill="#681E46" d="M0,150V0h750v150C750,150,375,70,0,150z" />
          </svg>
        </div>
      </NoteModalHeader>
      <ModalBody className={"pt-2 pb-0"}>
          Lesson details comes here
      </ModalBody>
      <ModalFooter className={"w-100 border-0 pt-0"}>
        <div className="d-flex w-100 align-items-center justify-content-center">
          <Button className="rounded-pill" color="secondary" onClick={close}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
    )
}
export default ViewLessonDetailsModal;