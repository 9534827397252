import { TimePicker } from "antd";
import moment from "moment";

const ClassTimeSelect = ({ onChange, value }) => {
  const handleTimeChange = (time) => {
    if (time) {
      const formattedTime = time.format("hh:mm A");
      onChange(formattedTime);
    } else {
      onChange(null);
    }
  };

  return (
    <TimePicker
      use12Hours
      format="hh:mm A"
      showNow={false}
      onChange={handleTimeChange}
      value={value ? moment(value, "hh:mm A") : null}
      minuteStep={10}
    />
  );
};

export default ClassTimeSelect;
