import React from "react";
import ConfirmationModal from "src/components/Dashboard/common/components/ConfirmationModal";
import useReactivation from "./hooks/useReactivation";
import { modalMap } from "../../utils/constants";

const TeacherReactivationModal = ({ modalData, teacher, refreshData }) => {
  const { loadingAction, reactivateTeacher } = useReactivation({
    teacherId: teacher?.id,
  });

  const onApprove = async () => {
    await reactivateTeacher();
    modalData.closeModal();
    refreshData();
  };
  const onCancel = () => {
    modalData.closeModal();
  };

  const confirmationModalData = {
    isModalOpen: modalData.isModalNameOpen(modalMap.teacherReactivation.name),
    openModal: () =>
      modalData.openModalWithName(modalMap.teacherReactivation.name),
    closeModal: () => modalData.closeModal(),
    toggleModal: () => modalData.toggleModal(),
  };
  return (
    <ConfirmationModal
      isSubmitting={loadingAction}
      modalData={confirmationModalData}
      onApprove={onApprove}
      onCancel={onCancel}
      approveBtnText="Reactivate"
      cancelBtnText="Cancel"
      title={`Reactivate ${teacher?.fullName}`}
    />
  );
};

export default TeacherReactivationModal;
