const programsEnum = {
  REGULAR: "Regular",
  ADVANCEMENT: "Advancement",
  HONOR_1: "Honor 1",
  HONOR_2: "Honor 2",
};

const programOptions = [
  {
    title: "The Regular Program",
    text: "Designed with an easier pace and less emphasis on high piano achievement. It is suitable for beginner level students. Students will still be encouraged to do performances, participate in school evaluations, and establish a solid music theory foundation as part of their yearly learning goals.",
    value: programsEnum["REGULAR"],
  },
  {
    title: "The Advancement Program",
    text: "Designed for students who learn well with more focused and detailed learning plans. Students will set up appropriate learning goals with their teachers including participation in Artis concerts, the international ABRSM music examinations, or local performance opportunities. In addition to performance skills, students will be expected to develop a good theory foundation.",
    value: programsEnum["ADVANCEMENT"],
  },
  {
    title: "The Honor 1 Program",
    text: "Designed with more demanding, detailed, and structured learning plans. The teacher seeks to expand each student’s skills and insights to help the students reach to the next level of instrumental development. The lessons are designed to cover performance skills and a strong theory foundation. Teacher is capable of exploring the intermediate and advanced solo repertoires with the student and assisting them to reach a higher purpose or their learning goals.",
    value: programsEnum["HONOR_1"],
  },
  {
    title: "The Honor 2 Program",
    text: "Offered for the students who take the instruction profoundly with highly demanding, direct and well structured learning goals. The teacher seeks to explore individual student’s highest potential to evolve their performance skills, insights, and their own expression to support the students reach their highest achievement or goals of instrumental development. The lessons are designed to cover in-depth musicianship, including an understanding of the composers, music background in the periods of music history, and wide-ranging solo repertoires on the weekly teaching plans.",
    value: programsEnum["HONOR_2"],
  },
];

const programDescriptionsEnum = {
  [programsEnum["REGULAR"]]:
    "Designed with an easier pace and less emphasis on high piano achievement. It is suitable for beginner level students. Students will still be encouraged to do performances, participate in school evaluations, and establish a solid music theory foundation as part of their yearly learning goals.",
  [programsEnum["ADVANCEMENT"]]:
    "Designed for students who learn well with more focused and detailed learning plans. Students will set up appropriate learning goals with their teachers including participation in Artis concerts, the international ABRSM music examinations, or local performance opportunities. In addition to performance skills, students will be expected to develop a good theory foundation.",
  [programsEnum["HONOR_1"]]:
    "Designed with more demanding, detailed, and structured learning plans. The teacher seeks to expand each student’s skills and insights to help the students reach to the next level of instrumental development. The lessons are designed to cover performance skills and a strong theory foundation. Teacher is capable of exploring the intermediate and advanced solo repertoires with the student and assisting them to reach a higher purpose or their learning goals.",
  [programsEnum["HONOR_2"]]:
    "Offered for the students who take the instruction profoundly with highly demanding, direct and well structured learning goals. The teacher seeks to explore individual student’s highest potential to evolve their performance skills, insights, and their own expression to support the students reach their highest achievement or goals of instrumental development. The lessons are designed to cover in-depth musicianship, including an understanding of the composers, music background in the periods of music history, and wide-ranging solo repertoires on the weekly teaching plans.",
};

export { programsEnum, programOptions, programDescriptionsEnum };
