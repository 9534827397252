import React from "react";
import useModal from "src/hooks/useModal";
import CreateConcertSoloProgramModal from "../../CreateConcertSoloProgramModal";
import { PrimaryButton } from "src/utils/shared/styled";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const FreeForAllConcertSignupModalBody = ({
  hookData,
  concertId,
  onActionSuccess,
}) => {
  const soloProgramModalData = useModal();
  const { createUserSignup, loadingAction } = hookData;

  return (
    <div>
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ gap: 20 }}
      >
        <PrimaryButtonWithLoading
          isLoading={loadingAction}
          onClick={createUserSignup}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          I would like to enroll
        </PrimaryButtonWithLoading>
        <PrimaryButton
          onClick={soloProgramModalData.openModal}
          minWidth="200px"
          style={{ flex: 1 }}
        >
          I would like to enroll and add my program
        </PrimaryButton>
      </div>
      <div>
        {soloProgramModalData.isModalOpen && concertId && (
          <CreateConcertSoloProgramModal
            modalData={soloProgramModalData}
            onActionSuccess={onActionSuccess}
            hasAlreadySignedUp={false}
            concertId={concertId}
          />
        )}
      </div>
    </div>
  );
};

export default FreeForAllConcertSignupModalBody;
