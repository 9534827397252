import { useContext, useEffect } from "react";
import { useSidebarContext } from "src/contexts/SidebarContext";
import { useNavigate } from "react-router-dom";
import {
  hasAdminCredentials,
  isAdmin,
  isSuperAdmin,
} from "src/constants/UserRoleEnum";
import { menuItems, adminMenuItems, superAdminMenuItems } from "../constants";

import {
  LogoContainer,
  MobileSidebarOverlay,
  MobileSidebarMainContainer,
  StyledLogo,
  TeacherAbsenceNotification,
  StyledMenu,
} from "../styled";
import { Link } from "react-router-dom";
import logo from "src/assets/images/logo.png";
import { FirebaseContext } from "src/components/Firebase";

const MobileSidebar = ({
  sidebarContentType,
  location,
  user,
  submittedTAsLength,
  applicationsLength,
  feedbacksLength,
}) => {
  const { isSidebarOpen, toggleSideBar, closeSidebar } = useSidebarContext();
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  // sidebar is initially closed
  useEffect(() => {
    if (isSidebarOpen) closeSidebar();
  }, []);

  const handleToggleClick = ({ target }) => {
    const normalizedContent = target?.textContent.trim().toLowerCase();

    if (
      normalizedContent !== "users" &&
      normalizedContent !== "lessons" &&
      normalizedContent !== "absence/make up" &&
      normalizedContent !== "lessons notes"
    ) {
      toggleSideBar();
    }
  };

  const handleMenuItemClick = ({ item }) => {
    if (item.props.link !== "/") {
      navigate(item.props.link);
    } else {
      firebase.signOut();
      navigate("/");
    }
  };

  const addNotificationBadge = (label, count) => (
    <>
      {label}{" "}
      {count > 0 && (
        <TeacherAbsenceNotification className="ms-1">
          {count}
        </TeacherAbsenceNotification>
      )}
    </>
  );

  const adminAndSuperAdminItems = isAdmin(user.role)
    ? adminMenuItems
    : superAdminMenuItems;

  return (
    <MobileSidebarOverlay onClick={handleToggleClick} isHidden={!isSidebarOpen}>
      <MobileSidebarMainContainer className={!isSidebarOpen ? "is-closed" : ""}>
        <LogoContainer className="align-self-center ">
          <Link to="/">
            <StyledLogo src={logo} alt="Logo" />
          </Link>
        </LogoContainer>
        {!isAdmin(user.role) && !isSuperAdmin(user.role) ? (
          <ul className="list-unstyled components" style={{ overflow: "auto" }}>
            {menuItems(user, sidebarContentType).map((menuItem) => {
              const isAdminTeacherAbsencePage =
                menuItem.link === "/dashboard/teacher-absence";
              const isApplicationsPage =
                menuItem.link === "/dashboard/applications";
              const isFeedbacksPage = menuItem.link === "/dashboard/feedbacks";

              // shows a notification for the Teacher Absence menu item if there is at least one "SUBMITTED" teacher absence
              const showTeacherAbsenceNotification =
                isAdminTeacherAbsencePage &&
                hasAdminCredentials(user?.role) &&
                submittedTAsLength;
              const showApplicationsNotification =
                isApplicationsPage &&
                hasAdminCredentials(user?.role) &&
                applicationsLength;
              const showFeedbacksNotification =
                isFeedbacksPage &&
                hasAdminCredentials(user?.role) &&
                feedbacksLength;

              const notificationMarkup = (
                <TeacherAbsenceNotification className="ms-1">
                  {showTeacherAbsenceNotification
                    ? submittedTAsLength
                    : showApplicationsNotification
                    ? applicationsLength
                    : showFeedbacksNotification
                    ? feedbacksLength
                    : ""}
                </TeacherAbsenceNotification>
              );

              return (
                <li
                  key={menuItem.text}
                  className={
                    menuItem.link === location.pathname ? "active" : ""
                  }
                >
                  <Link to={menuItem.link}>
                    {menuItem.Styledicon ? (
                      <menuItem.Styledicon />
                    ) : (
                      <i className={`fas fa-${menuItem.icon}`} />
                    )}
                    {menuItem.text}
                    {notificationMarkup}
                  </Link>
                </li>
              );
            })}
            <li>
              <Link to={"/"} href="#" onClick={firebase.signOut}>
                <i className="fas fa-power-off" />
                <span>Sign Out</span>
              </Link>
            </li>
          </ul>
        ) : (
          <StyledMenu
            mode="inline"
            items={adminAndSuperAdminItems.map((menuItem) => {
              const updatedChildren = menuItem.children?.map((child) => {
                let notificationCount = 0;
                if (child.link === "/dashboard/teacher-absence") {
                  notificationCount = submittedTAsLength;
                }

                return {
                  ...child,
                  label: addNotificationBadge(child.label, notificationCount),
                  className: child.link === location.pathname ? "active" : "",
                };
              });

              let notificationCount = 0;

              if (menuItem.link === "/dashboard/applications") {
                notificationCount = applicationsLength;
              } else if (menuItem.link === "/dashboard/feedbacks") {
                notificationCount = feedbacksLength;
              }

              return {
                ...menuItem,
                label: addNotificationBadge(menuItem.label, notificationCount),
                children: updatedChildren,
                className: menuItem.link === location.pathname ? "active" : "",
              };
            })}
            triggerSubMenuAction="click"
            selectable={false}
            onClick={handleMenuItemClick}
          />
        )}
      </MobileSidebarMainContainer>
    </MobileSidebarOverlay>
  );
};
export default MobileSidebar;
