import styled from "styled-components";

export const TopSectionContainer = styled.div`
  display: flex;
  gap: 30px;
`;
export const TopSectionItemContainer = styled.div`
  flex: 1;
`;
export const RatesSectionContainer = styled.div`
  background-color: #f6f2f4;
  min-height: 250px;
  border-radius: 20px;
  padding: 20px;
`;
export const RatesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  & label {
    display: block;
  }
`;
export const RateItemContainer = styled.div`
  width: 45%;
`;
export const CurrencyInputContainer = styled.span`
  display: inline-block;
  position: relative;
  &::before {
    content: "$";
    font-family: "Roboto Regular", sans-serif;
    font-size: 1rem;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  & > input {
    padding-left: 17px;
  }
`;
export const IconsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;

  & svg {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
`;
