import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import AttachmentsList from "../../ActiveFeedback/AttachmentsList";
import { Divider } from "antd";
import { FeedbackMessage } from "../../ActiveFeedback/styled";

const FeedbackDetailsModal = ({ isOpen, toggle, feedback }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      centered
      toggle={toggle}
      size="lg"
      zIndex={999}
    >
      <StyledModalHeader
        toggle={toggle}
      >{`${feedback?.name}'s feedback`}</StyledModalHeader>
      <ModalBody>
        <h4>Message:</h4>
        <FeedbackMessage>{feedback?.message}</FeedbackMessage>
        <Divider />
        <h4>Attachments:</h4>
        <AttachmentsList attachments={feedback?.attachments} />
        <Divider />
        <h4>Created by:</h4>
        <p>
          <strong>Name:</strong> {feedback?.name || "N/A"}
        </p>
        <p>
          <strong>Email:</strong> {feedback?.email || "N/A"}
        </p>
        <p>
          <strong>Role:</strong> {feedback?.role || "N/A"}
        </p>
        <p>
          <strong>Created at:</strong> {feedback?.request_date}
        </p>
      </ModalBody>
    </StyledModal>
  );
};

export default FeedbackDetailsModal;
