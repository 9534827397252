import React from "react";
import { CustomModal, CustomModalBody } from "src/utils/styled";
import ModalBodyContent from "./ModalBodyContent";
import ModalHeaderContent from "./ModalHeaderContent";

const ViewFeedItemModal = ({ modalData, feedItem }) => {
  const { isModalOpen, toggleModal } = modalData;

  return (
    <CustomModal size="lg" centered isOpen={isModalOpen} toggle={toggleModal}>
      <CustomModalBody padding="0">
        <div>
          <ModalHeaderContent modalData={modalData} feedItem={feedItem} />
        </div>
        <div className="mt-4">
          <ModalBodyContent feedItem={feedItem} />
        </div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ViewFeedItemModal;
