import { useState } from "react";
import { useScheduleReminder } from "./hooks";
import ScheduleReminderModal from "./ScheduleReminderModal";
import { ReminderButton } from "./styled";

const ScheduleReminder = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { todayEvents } = useScheduleReminder();

  return (
    <>
      <ReminderButton type="button" onClick={() => setModalOpen(true)}>
        My schedule today
      </ReminderButton>
      <ScheduleReminderModal
        isOpen={modalOpen}
        toggle={() => setModalOpen((prevState) => !prevState)}
        events={todayEvents}
      />
    </>
  );
};

export default ScheduleReminder;
