import React from "react";
import { PaymentGroupContainer } from "../styled";
import { CustomCheckboxContainer } from "src/utils/shared/styled";
import { CustomInputWithCurrency } from "src/utils/shared/shared";
import RateOptionItem from "./RateOptionItem";

const RateItem = ({ rate, onPaymentRateChange, onPaymentRateOptionChange }) => {
  return (
    <div key={rate.name}>
      <PaymentGroupContainer className="mb-4">
        <CustomCheckboxContainer>
          <input
            checked={rate.isEnabled}
            onChange={(e) =>
              onPaymentRateChange(rate.name, "isEnabled", e.target.checked)
            }
            type="checkbox"
            id={rate.name}
            name="checkboxOption"
          />
          <label className="ms-2" htmlFor={rate.name}>
            {rate.title}
          </label>
        </CustomCheckboxContainer>
        {!rate.hasOptions && (
          <div>
            <CustomInputWithCurrency
              onWheel={(e) => e.target.blur()} // prevents scroll behaviour
              type="number"
              disabled={!rate.isEnabled}
              disabledBgColor={"#f2f2f2"}
              value={rate.value}
              onChange={(e) =>
                onPaymentRateChange(rate.name, "value", e.target.value)
              }
              placeholder="0"
            />
          </div>
        )}
      </PaymentGroupContainer>
      {rate.hasOptions &&
        rate.options.map((option) => {
          return (
            <RateOptionItem
              key={option.name}
              rate={rate}
              option={option}
              onPaymentRateOptionChange={onPaymentRateOptionChange}
            />
          );
        })}
      <hr />
    </div>
  );
};

export default RateItem;
