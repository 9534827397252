import React from "react";
import AffectedLessonsBody from "./AffectedLessonsBody";
import { CustomModal, CustomModalBody, CustomModalHeader } from "./styled";

const AffectedLessonsModal = ({
  modalData,
  absencesData,
  withJustApprovedAbsence,
}) => {
  const closeModal = () => {
    modalData.closeModal();
    absencesData.setJustApprovedAbsenceId("");
  };
  const toggleModal = () => {
    modalData.toggleModal();
    absencesData.setJustApprovedAbsenceId("");
  };
  return (
    <CustomModal
      size="lg"
      centered
      isOpen={modalData.isModalOpen}
      toggle={toggleModal}
    >
      <CustomModalHeader
        // close={<CloseIcon onClick={modalData.closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2 className="fs-3 fw-bold">Affected Private Lessons</h2>
      </CustomModalHeader>
      <CustomModalBody>
        <AffectedLessonsBody
          absencesData={absencesData}
          modalData={modalData}
          withJustApprovedAbsence={withJustApprovedAbsence}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default AffectedLessonsModal;
