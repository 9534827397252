import React from "react";
import { SubmitSpinner } from "src/components/common";
import { StudentInfoContextProvider } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import ViewParentInfoModal from "../../common/components/ViewParentInfoModal";
import ViewStudentInfoModal from "../../common/components/ViewStudentInfoModal";

const TableLayout = ({ parentsData }) => {
  const {
    displayedParents,
    loadingInitialData,
    initialData,
    currentStudent,
    setCurrentStudentId,
    currentParent,
    setCurrentParentId,
  } = parentsData;
  const { students } = initialData;

  const studentInfoModalData = useModal();
  const parentInfoModalData = useModal();

  const handleStudentClick = (studentId) => {
    if (!studentId) return;
    setCurrentStudentId(studentId);
    studentInfoModalData.openModal();
  };

  const handleParentClick = (parentId) => {
    if (!parentId) return;
    setCurrentParentId(parentId);
    parentInfoModalData.openModal();
  };

  if (loadingInitialData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  return (
    <CustomTableContainer>
      <CustomTable>
        <thead>
          <TableRow>
            <th>
              <div>Name</div>
            </th>
            <th>
              <div>Login Email</div>
            </th>
            <th>
              <div>1st Student</div>
            </th>
            <th>
              <div>2nd Student</div>
            </th>
            <th>
              <div>3rd Student</div>
            </th>
          </TableRow>
        </thead>
        <tbody>
          {displayedParents.map((parent) => {
            const parentName = parent.fullName;
            const parentEmail = parent.primaryEmail;
            const parentStudents = students.filter(
              ({ primaryContactEmail, secondaryContactEmail }) =>
                [primaryContactEmail, secondaryContactEmail].includes(
                  parent.primaryEmail
                )
            );

            const [firstStudent, secondStudent, thirdStudent] = parentStudents;
            return (
              <TableRow key={parent.id}>
                <ClickableTableCell
                  onClick={() => handleParentClick(parent?.id)}
                >
                  {parentName}
                </ClickableTableCell>
                <td>{parentEmail}</td>
                <ClickableTableCell
                  onClick={() => handleStudentClick(firstStudent?.id)}
                >
                  {firstStudent?.fullName || "N/A"}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={() => handleStudentClick(secondStudent?.id)}
                >
                  {secondStudent?.fullName || "N/A"}
                </ClickableTableCell>
                <ClickableTableCell
                  onClick={() => handleStudentClick(thirdStudent?.id)}
                >
                  {thirdStudent?.fullName || "N/A"}
                </ClickableTableCell>
              </TableRow>
            );
          })}
        </tbody>
      </CustomTable>
      {currentStudent && (
        <StudentInfoContextProvider>
          <ViewStudentInfoModal
            modalData={studentInfoModalData}
            currentStudent={currentStudent}
          />
        </StudentInfoContextProvider>
      )}
      {currentParent && (
        <ViewParentInfoModal
          modalData={parentInfoModalData}
          parentUser={currentParent}
        />
      )}
    </CustomTableContainer>
  );
};

export default TableLayout;
