import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  InfoText,
  UserProfile,
} from "src/utils/styled";
import * as yup from "yup";
import {
  adultValidationSchema,
  emailValidationSchema,
  phoneValidationSchema,
  requiredStringSchema,
  requiredIDStringSchema,
  zipValidationSchema,
  dateValidationSchema,
} from "src/utils/validations";
import styled from "styled-components";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";

const Closeicon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "300px")};
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fbf8f9";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
const Righticon = styled(CaretRightFill)`
  color: #fff;
  width: 20px;
  position: absolute;
  top: 10%;
  right: 15px;
`;

const FirstaddStudentFormValuesValidationSchema = yup.object().shape({
  fullName: requiredStringSchema,
  systemID: requiredStringSchema,
});
const FirstStageModal = ({
  isOpen,
  firstStageInitialFormData,
  studentInfo,
  setStudentInfo,
  onClose,
  secondStageModalData,
  selectedSystemIDLocation,
  setSelectedSystemIDLocation,
  locations,
}) => {
  return (
    <CustomModal centered isOpen={isOpen} size={"lg"}>
      <CustomModalHeader
        close={
          <Closeicon
            onClick={() => {
              onClose();
              var x = document.getElementsByTagName("BODY")[0];
              x.classList.remove("overflowBody");
            }}
          />
        }
      >
        <InfoText>Add New Student</InfoText>
      </CustomModalHeader>
      <CustomModalBody>
        <Formik
          enableReinitialize
          initialValues={firstStageInitialFormData}
          validationSchema={FirstaddStudentFormValuesValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onClose();
            secondStageModalData.openModal();
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <UpdateSystemID
                studentInfo={studentInfo}
                setFieldValue={setFieldValue}
              />
              <div className="d-flex justify-content-center w-50 m-auto mb-4">
                <div>
                  <p className="mb-2">Location</p>
                  <CustomSelectField
                    width="300px"
                    value={selectedSystemIDLocation}
                    onChange={(e) =>
                      setSelectedSystemIDLocation(e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Select A Location
                    </option>

                    {locations?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </CustomSelectField>
                </div>
              </div>
              <div className="d-flex">
                <div className="InputGroup lastInputGroup">
                  <div className="w-100 inputContainer">
                    <label htmlFor="fullName">student Name</label>
                    <input
                      className="InputField"
                      label={"Name of student"}
                      name={"fullName"}
                      placeholder="Name of Student"
                      value={values.fullName}
                      onChange={(e) => {
                        handleChange("fullName")(e);
                        setStudentInfo((oldVal) => ({
                          ...oldVal,
                          fullName: e.target.value,
                        }));
                      }}
                      error={errors.fullName}
                      onBlur={handleBlur}
                      maxLength="40"
                    />

                    {touched.fullName && errors.fullName && (
                      <span className="errorMsg">{errors.fullName}</span>
                    )}
                  </div>
                  <div className="w-100 inputContainer">
                    <label htmlFor="systemID">student ID</label>
                    <input
                      className="InputField"
                      label={"systemID"}
                      name={"systemID"}
                      placeholder="System ID"
                      value={values.systemID}
                      readOnly
                      // onChange={(e) => {
                      //   handleChange("systemID")(e);
                      //   setStudentInfo((oldVal) => ({
                      //     ...oldVal,
                      //     systemID: e.target.value,
                      //   }));
                      // }}
                      error={errors.systemID}
                      onBlur={handleBlur}
                    />
                    {touched.systemID && errors.systemID && (
                      <span className="errorMsg">{errors.systemID}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="mt-3 mb-3 submitButton"
                  type="submit"
                  style={{ width: "50%", margin: "auto" }}
                >
                  <div className="w-100 position-relative">
                    <span className="buttonSpan">Next Page</span>
                    <Righticon className="buttonicon" />
                  </div>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModalBody>
    </CustomModal>
  );
};

export default FirstStageModal;
// This component is only used to update the systemID Formik state for the first stage modal
const UpdateSystemID = ({ studentInfo, setFieldValue }) => {
  useEffect(() => {
    setFieldValue("systemID", studentInfo.systemID);
  }, [studentInfo.systemID]);

  return <></>;
};
