import React, { useState } from "react";

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen((oldVal) => !oldVal);

  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useModal;
