import styled from "styled-components";

const FiltersWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`;

const InstrumentsList = styled.ul`
  margin: 32px 0 0 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

const InstrumentsItem = styled.li`
  width: 56px;
  height: 56px;

  list-style: none;
  background-color: ${({ selected }) => (selected ? "#681e46" : "#ddc5ffcc")};
  border-radius: 12px;
  transition-duration: 150ms;

  button {
    width: 100%;
    height: 100%;
    border: none;
    padding: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: transparent;
  }

  svg {
    transition-duration: 150ms;
    fill: ${({ selected }) => (selected ? "#fff" : "#212529")};
  }
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 420px;
  border: 1px solid #986b78;
  padding: 12px;
  margin-top: 32px;

  font-size: 18px;
  border-radius: 12px;
  background-color: #efe1e5;
  transition-duration: 150ms;
  outline: none;
`;

export { FiltersWrapper, InstrumentsList, InstrumentsItem, SearchInput };
