const BASE_API_URL =
  process.env.REACT_APP_MODE === "prod"
    ? "https://us-central1-artisconnect-test.cloudfunctions.net"
    : process.env.REACT_APP_MODE === "dev"
    ? "https://us-central1-artisconnect-a3c6e.cloudfunctions.net"
    : "";
//
//
//

/*--------------- DEVELOPMENT (DON'T ENABLE UNLESS YOU ARE A DEV TESTING LOCALLY) -----------------------*/
// const BASE_API_URL = "http://localhost:5000/artisconnect-a3c6e/us-central1";
// TEST PROJ
// const BASE_API_URL = "http://localhost:5000/artisconnect-test/us-central1";
//
//
//

export const GENERATE_NEW_USER_ID = `${BASE_API_URL}/expressFunctions/get-new-user-id`;
export const GET_TEACHERS_LIST = `${BASE_API_URL}/expressFunctions/teachers-list`;
export const GENERATE_AVAILABILITIES_DOC = `${BASE_API_URL}/expressFunctions/generate-availabilities-doc`;
export const DOWNLOAD_AVAILABILITIES_PDF = `${BASE_API_URL}/expressFunctions/download-availabilities-pdf`;
export const CREATE_STORAGE_FILE_COPY = `${BASE_API_URL}/expressFunctions/copy-storage-file`;
export const CONTACT_US_ENDPOINT = `${BASE_API_URL}/expressFunctions/contact-us-submission`;
export const CREATE_PAYPAL_ORDER = `${BASE_API_URL}/expressFunctions/paypal/create-order`;
export const CAPTURE_PAYPAL_ORDER = `${BASE_API_URL}/expressFunctions/paypal/capture-group-class-order`;
export const GET_PAYPAL_ORDER_DETAILS = `${BASE_API_URL}/expressFunctions/paypal/get-paypal-order-details`;
export const GET_PAYPAL_SUBSCRIPTION_DETAILS = `${BASE_API_URL}/expressFunctions/paypal/get-paypal-subscription-details`;
