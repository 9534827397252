import moment from "moment";
import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import { paymentRates } from "src/constants/paymentsEnum";
import useRatesHistory from "../hooks/useRatesHistory";
import {
  ContentContainer,
  InfoContainer,
  InfoText,
  RatesContainer,
} from "../styled";

const RatesHistoryBody = ({ userId }) => {
  const ratesHistoryData = useRatesHistory({ userId });
  const { initialData, loadingInitialData } = ratesHistoryData;
  const { userPaymentRates } = initialData;
  return (
    <div>
      <StyledBarContent title="Payment Rates History">
        <ContentContainer>
          <div
            style={{
              width: "100%",
              height: "400px",
              overflow: "auto",
            }}
          >
            {userPaymentRates?.map((rateObj, i) => {
              const { createdAt, rates, createdByUser } = rateObj;
              const { fullName, role } = createdByUser || {};
              return (
                <RatesContainer
                  key={rateObj.id}
                  borderTop={
                    i === 0 ? "4px solid #3dba00" : "4px solid #681e46"
                  }
                  className="mb-3 "
                >
                  <InfoContainer>
                    <InfoText bold className="me-2">
                      Created By:
                    </InfoText>
                    <InfoText>{`${role} - ${fullName}`}</InfoText>
                  </InfoContainer>
                  <InfoContainer>
                    <InfoText bold className="me-2">
                      Created At:
                    </InfoText>
                    <InfoText>
                      {moment(createdAt).format("MM/DD/YYYY")}
                    </InfoText>
                  </InfoContainer>
                  {rates?.map((rate) => {
                    const { title, type, value } = rate;
                    const label = `${paymentRates[title]?.name1} per ${type}`;

                    return (
                      <InfoContainer key={rate.id}>
                        <InfoText bold className="me-2">
                          {label}:
                        </InfoText>
                        <InfoText>${value}</InfoText>
                      </InfoContainer>
                    );
                  })}
                </RatesContainer>
              );
            })}
          </div>
        </ContentContainer>
      </StyledBarContent>
    </div>
  );
};

export default RatesHistoryBody;
