import { LibraryMusic } from "@styled-icons/material-rounded/LibraryMusic";
import { Microphone } from "@styled-icons/fa-solid/Microphone";
import { CameraVideo } from "@styled-icons/bootstrap/CameraVideo";
import { Link } from "@styled-icons/evaicons-solid/Link";
import { Upload } from "@styled-icons/feather/Upload";

export const ADD_LABELS = {
  musicSheets: "Music Sheet",
  recordings: "Recording",
  videos: "Video",
  recommendationLinks: "Recommendation Link or Website",
};
export const WORK_UPDATE_ATTACHMENT_LABELS = {
  image: "Image",
  document: "Document",
  video: "Video",
};
export const MODAL_SIZE = {
  musicSheets: "xl",
  recordings: "lg",
  videos: "lg",
  recommendationLinks: "lg",
};
export const DIRECTORY = {
  musicSheets: "music-sheets/",
  recordings: "recordings/",
  videos: "videos/",
};
export const TABS = [
  {
    title: "Music Sheets",
    key: "musicSheets",
    IconComponent: LibraryMusic,
  },
  {
    title: "Recordings",
    key: "recordings",
    IconComponent: Microphone,
  },
  {
    title: "Videos",
    key: "videos",
    IconComponent: CameraVideo,
  },
  {
    title: "Recommendation Links or Websites",
    key: "recommendationLinks",
    IconComponent: Link,
  },
  {
    title: "Students Uploads",
    key: "studentsUploads",
    IconComponent: Upload,
  },
];

export const typesOptions = [
  {
    id: "musicSheets",
    value: "musicSheets",
    label: "Music Sheet Or Image",
    acceptedFileTypes: ["image/", "application/pdf"],
  },
  {
    id: "recordings",
    value: "recordings",
    label: "Recording",
    acceptedFileTypes: ["audio/"],
  },
  {
    id: "videos",
    value: "videos",
    label: "Video",
    acceptedFileTypes: ["video/"],
  },
  {
    id: "recommendationLinks",
    value: "recommendationLinks",
    label: "Recommendation Link",
  },
];

export const isValidFileType = (fileType, typeOptionValue) => {
  if (!fileType || !typeOptionValue) return false;
  const typeOption = typesOptions.find(
    ({ value }) => value === typeOptionValue
  );
  if (!typeOption) return false;

  const isValid = typeOption.acceptedFileTypes?.some((acceptedType) =>
    fileType.includes(acceptedType)
  );

  return isValid;
};

export const workUpdatesTypesOptions = [
  {
    id: "image",
    value: "image",
    label: "Image",
  },
  {
    id: "document",
    value: "document",
    label: "document (PDF)",
  },
  {
    id: "videos",
    value: "videos",
    label: "Video",
  },
];

export const ALLOWED_WORK_UPDATES_ATTACHMENST_EXTENSIONS = [
  "png",
  "jpeg",
  "jpg",
  "pdf",
  "mp4",
];

export const IMAGE_EXTENSIONS = ["pdf", "jpg", "png", "jpeg"];

export const AUDIO_EXTENSIONS = ["mp4", "mp3", "mpeg", "quicktime", "x-m4a"];

export const VIDEO_EXTENSIONS = [
  "mp4",
  "x-matroska",
  "quicktime",
  "webm",
  "x-m4a",
];

export const ALLOWED_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  "mpeg",
  "pdf",
  "mp4",
  "mp3",
  "x-matroska",
  "quicktime",
  "webm",
  "x-m4a",
];
