import moment from "moment";
import { UserRole } from "src/constants/UserRoleEnum";
import { eventsMap, isRecurringEvent } from "../../../../constants/eventsEnum";
import { scheduleTypes } from "../../../../constants/scheduleTypes";
import { weekDays } from "../../../../constants/weekDays";
import {
  areTwoEventsOverlapping,
  checkIfCanceledFutureEvents,
  checkStartTimeIsBeforeEndTime,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getFullDateFromDateAndTimeInputs,
  getFutureTimelineOnWeekday,
  getStartAndEndDatesFromTimeInputs,
  getTimeDiffInMins,
  prepareTeacherActivities,
  updatedMomentNow,
} from "../../../../utils/helpers";

// today moment
const momToday = updatedMomentNow();

// Checks if the teacherDays array is valid
export const isCompleteTeacherDays = (teacherDays = [], passKeys = []) => {
  let isValid = true;
  teacherDays.forEach((availableDay) => {
    Object.keys(availableDay).forEach((key) => {
      if (
        !availableDay[key] &&
        availableDay[key] !== 0 &&
        !passKeys.includes(key)
      ) {
        isValid = false;
      }
    });
  });
  return isValid;
};
export const isCompleteMakeupOpenings = (
  makeupOpenings = [],
  passKeys = []
) => {
  let isValid = true;
  makeupOpenings.forEach((opening) => {
    Object.keys(opening).forEach((key) => {
      if (!opening[key] && !passKeys.includes(key)) isValid = false;
    });
  });
  return isValid;
};
export const isCompleteMakeupOpeningRow = (
  makeupOpening = {},
  passKeys = []
) => {
  let isValid = true;
  Object.keys(makeupOpening).forEach((key) => {
    if (!makeupOpening[key] && !passKeys.includes(key)) isValid = false;
  });

  return isValid;
};
export const isCompleteInstrumentsInfo = (
  instrumentsInfo = [],
  passKey = ""
) => {
  let isValid = true;
  instrumentsInfo.forEach((instrumentInfo) => {
    Object.keys(instrumentInfo).forEach((key) => {
      if (!instrumentInfo[key] && key !== passKey) isValid = false;
    });
  });
  return isValid;
};

// checks if the new teacher days (after the edit) don't conflict with the activities
export const validateTeacherDays = (
  teacherId,
  newTeacherDays,
  userActivities
) => {
  if (typeof newTeacherDays !== "object" || typeof userActivities !== "object")
    return { isValid: false, message: "missing arguments" };

  // if no teacher days and no activities then its valid
  if (!newTeacherDays.length && !userActivities?.length) {
    return { isValid: true };
  }

  const preparedTeacherActivities = prepareTeacherActivities(
    userActivities,
    teacherId
  );

  // filter out the old non-recurring events (ex:trial lessons)
  const filteredActivities =
    preparedTeacherActivities.filter((activity) => {
      if (!isRecurringEvent(activity.type, activity.privateLessonType)) {
        return momToday.isBefore(activity?.end_time);
      } else {
        return true;
      }
    }) || [];

  // gets the activities that are created in teacherDays schedule
  const activitiesInTeacherDaysSchedule =
    getActivitiesDuringTeacherDays(filteredActivities);

  let areValidActivities = true;
  loop1: for (const activity of activitiesInTeacherDaysSchedule) {
    let isActivityPassed = false;
    loop2: for (const teacherDay of newTeacherDays) {
      const { startDate: teacherDayStartDate, endDate: teacherDayEndDate } =
        getStartAndEndDatesFromTimeInputs(
          updatedMomentNow().day(teacherDay.day),
          teacherDay.start,
          teacherDay.end
        );

      const isRecurring = isRecurringEvent(
        activity.type,
        activity.privateLessonType
      );
      let timelineObj;
      if (isRecurring) {
        timelineObj =
          getFutureTimelineOnWeekday({
            requestDate: teacherDayStartDate,
            timeline: activity.timeline,
            startDateAccessor: "start_time",
            endDateAccessor: "end_time",
            lastDateAccessor: "lastDate",
          }) ||
          getFutureTimelineOnWeekday({
            requestDate: teacherDayEndDate,
            timeline: activity.timeline,
            startDateAccessor: "start_time",
            endDateAccessor: "end_time",
            lastDateAccessor: "lastDate",
          });
      }
      const isSameTeacher = timelineObj?.userId === teacherId;

      if (isRecurring && !timelineObj) {
        continue loop2;
      }

      // if a timeline was found but with diff teacher , then skip this activity
      if (isRecurring && timelineObj && !isSameTeacher) {
        continue loop1;
      }

      const activityStart = timelineObj?.start_time || activity.start_time;
      const activityEnd = timelineObj?.end_time || activity.end_time;
      // checks if the event is canceled (for all future events + canceled date must be in past)
      const isFutureCanceledActivity = checkIfCanceledFutureEvents({
        occuranceDate: teacherDayStartDate,
        canceledAt: activity.canceledAt,
        canceledDateRanges: activity.canceledRanges,
      });
      // activity has to be overlapping with the teacher day + the day start has to be before or same as the activity start + the day end has to be after or same as the activity end
      if (
        isFutureCanceledActivity ||
        areTwoEventsOverlapping(
          {
            start_time: teacherDayStartDate,
            end_time: teacherDayEndDate,
            isRecurring: true,
          },
          {
            start_time: activityStart,
            end_time: activityEnd,
            isRecurring: isRecurring,
          }
        )
      ) {
        isActivityPassed = true;
        break loop2;
      }
    }

    if (!isActivityPassed) {
      areValidActivities = false;
      break loop1;
    }
  }
  if (!areValidActivities) {
    return {
      isValid: false,
      message: "the teacher events has to be within this schedule",
    };
  }
  return { isValid: true };
};

export const validateMakeupOpenings = (
  makeupOpeningsWithDates,
  teacherDays,
  schoolYears
) => {
  let validationObj = { isValid: true };

  loop1: for (const opening of makeupOpeningsWithDates) {
    const { startDate, endDate, location } = opening;

    const combinedSchoolBreaks = getCombinedSchoolBreaks(schoolYears, location);

    // continue if opening date is during a school break (no need to proceed to validate against the teacher day)
    if (
      moment(opening.startDate).isSameOrBefore(updatedMomentNow(), "date") ||
      isOpeningDuringSchoolBreak(opening, combinedSchoolBreaks)
    ) {
      continue;
    }

    loop2: for (const teacherDay of teacherDays) {
      const { startDate: teacherDayStartDate, endDate: teacherDayEndDate } =
        getStartAndEndDatesFromTimeInputs(
          updatedMomentNow().day(teacherDay.day),
          teacherDay.start,
          teacherDay.end
        );
      //checks if teacherDay and makeup opening are overlapping
      if (
        areTwoEventsOverlapping(
          {
            start_time: teacherDayStartDate,
            end_time: teacherDayEndDate,
            isRecurring: true,
          },
          {
            start_time: opening.startDate,
            end_time: opening.endDate,
            isRecurring: false,
          }
        )
      ) {
        validationObj = {
          isValid: false,
          message:
            "Makeup opening days and normal teacher days can't be overlapping",
        };
        // No need to continue through the first loop (opening days loop) if the current opening isnt valid
        break loop1;
      }
    }

    // Check if there is a school break then check if there is user activities in this period
  }

  return validationObj;
};

//checks if opening is within a school break
const isOpeningDuringSchoolBreak = (opening, schoolBreaks) => {
  if (!schoolBreaks?.length) return false;

  let isDuringSchoolBreak = false;
  for (const schoolBreak of schoolBreaks) {
    if (
      moment(opening.startDate).isBetween(
        schoolBreak.from.toDate(),
        schoolBreak.to.toDate(),
        undefined,
        "[]"
      ) &&
      moment(opening.endDate).isBetween(
        schoolBreak.from.toDate(),
        schoolBreak.to.toDate(),
        undefined,
        "[]"
      )
    ) {
      console.log("the opening matches a school event", {
        schoolBreak,
        opening,
      });
      isDuringSchoolBreak = true;
      break;
    }
  }

  return isDuringSchoolBreak;
};

// start time has to be after end time
const getActivitiesDuringTeacherDays = (activities) => {
  const filteredActivities = activities?.filter(
    (activity) =>
      parseInt(activity.scheduleType) === scheduleTypes.teacherDays.code
  );

  return filteredActivities || [];
};
export const mapRolesToOptions = (
  roles = Object.values(UserRole).filter((role) => role !== UserRole.PARENT)
) => {
  const options = roles.map((role) => ({
    label: role + "s",
    value: role,
  }));

  return options;
};
export const mapOptionsToRoles = (options = []) => {
  const roles = options.map(({ value }) => value);

  return roles;
};
