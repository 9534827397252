import { SummaryItem } from "../styled";

const StudentSummary = ({ user, teacher }) => {
  return (
    <>
      <SummaryItem capitalize>
        Selected instruments: {user?.instruments?.join(", ") || "N/A"}
      </SummaryItem>
      <SummaryItem capitalize>
        Selected package: {user?.package?.replace(/_/g, " ") || "N/A"}
      </SummaryItem>
      <SummaryItem capitalize>
        Selected class type: {user?.classType || "N/A"}
      </SummaryItem>
      <SummaryItem capitalize>
        Selected program: {user?.program || "N/A"}
      </SummaryItem>
      <SummaryItem capitalize>
        Selected teacher: {teacher?.fullName || "N/A"}
      </SummaryItem>
      <SummaryItem capitalize>
        Selected days: {user?.selectedClassDays?.join(", ") || "N/A"}
      </SummaryItem>
      <SummaryItem>
        Selected time: {user?.selectedClassTime || "N/A"}
      </SummaryItem>
    </>
  );
};

export default StudentSummary;
