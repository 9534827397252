import { documentId, getDocs, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import {
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import { chunkArrayInGroups } from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getPrivateLessonsByIds = async (privateLessonsIds) => {
    try {
      if (!privateLessonsIds?.length) return [];

      const privateLessonsIdsChunks = chunkArrayInGroups(privateLessonsIds, 10);
      let requests = [];
      privateLessonsIdsChunks.forEach((privateLessonsIdsArray) => {
        const q = query(
          firebase.PrivateLessons(),
          where(documentId(), "in", privateLessonsIdsArray)
        );
        const req = getDocs(q);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        parseFirebaseDoc(lessonsSnapshot.docs)
      );
      const privateLessons = lessonsChunks.flat();
      return privateLessons;
    } catch (err) {
      console.log(err);
    }
  };

  const getLessonAbsences = async (lessonId) => {
    try {
      const q1 = query(
        firebase.absences(),
        where("lessonId", "==", lessonId),
        where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const q2 = query(
        firebase.absences(),
        where("affectedPrivateLessonsIds", "array-contains", lessonId)

        // Can't have not-in with array-contains on the same query, but we filter pending absences on client side anyways
        // where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [absences1, absences2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedLessonAbsences = [...absences1, ...absences2];

      const filteredAbsences = combinedLessonAbsences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      return filteredAbsences;
    } catch (err) {
      console.log(err);
    }
  };

  const getSchoolYears = async () => {
    try {
      const schoolYearsSnap = await getDocs(firebase.getSchoolYears());
      const schoolYears = parseFirebaseDoc(schoolYearsSnap.docs);
      return schoolYears;
    } catch (err) {
      console.log(err);
    }
  };

  const getMakeupLessonsByAbsenceId = async (absenceId) => {
    try {
      if (!absenceId) return [];

      const q = query(
        firebase.makeupLessons(),
        where("forAbsenceId", "==", absenceId)
      );
      const snap = await getDocs(q);
      const makeupLessons = parseFirebaseDoc(snap.docs);

      return makeupLessons;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    getPrivateLessonsByIds,
    getLessonAbsences,
    getSchoolYears,
    getMakeupLessonsByAbsenceId,
  };
};

export default useFirebaseFns;
