import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { weekDays } from "src/constants/weekDays";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  checkForOverlapping,
  checkIfLessonInTeacherAvailabilities,
} from "../../../helperFns/lessonTimelineHelpers";
import { v4 as uuidv4 } from "uuid";

import {
  checkIfActivityDuringSchoolBreak,
  getCombinedSchoolBreaks,
  getFullDateFromDateAndTimeInputs,
  sortArrayByDates,
} from "src/utils/helpers";
import { validatePackageLessonItem } from "../helperFns";
import {
  isDeclinedAbsence,
  isPendingAbsence,
} from "src/constants/absenceTypes";

const initialDataInitialValues = {
  packageLesson: null,
  teachers: [],
  teachersAvailableDays: [],
  programs: [],
  locations: [],
  schoolYears: [],
};

const initialFormDataValues = {
  time: "",
  duration: "",
  teacherId: "",
  locationId: "",
  weekDay: "",
  isVirtual: "",

  lastLessonDate: undefined, // date
  newLessonDate: undefined, // date
};
const usePackageLessonSchedule = ({ user, packageLessonId, setNumber }) => {
  const {
    getPrivateLessonsByIds,
    getTeachers,
    getTeachersAvailableDays,
    getPrograms,
    getLocations,
    getTeacherActivities,
    getTeacherAbsences,
    getSchoolYears,
    updatePackageSetsAndActivity,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [formData, setFormData] = useState(initialFormDataValues);

  function handleFormChange(name, value) {
    if (name === "locationId") {
      setFormData((oldVal) => ({
        ...oldVal,
        [name]: value,
        teacherId: "",
        weekDay: "",
      }));
      return;
    }
    if (name === "teacherId") {
      setFormData((oldVal) => ({
        ...oldVal,
        [name]: value,
        weekDay: "",
      }));
      return;
    }
    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  async function saveChanges() {
    try {
      const {
        lastLessonDate,
        newLessonDate,
        time,
        duration,
        teacherId,
        locationId,
        weekDay,
        isVirtual,
      } = formData;

      if (
        !lastLessonDate ||
        !duration ||
        !time ||
        !newLessonDate ||
        !teacherId ||
        !locationId
      ) {
        toast.warn("Please fill all required fields");
        return;
      }

      setSavingData(true);

      const fullLastLessonDate = moment(lastLessonDate).toDate();
      const fullNewLessonStartDate = getFullDateFromDateAndTimeInputs(
        newLessonDate,
        time
      );

      const teacherDays = initialData.teachersAvailableDays.find(
        ({ id }) => id === formData.teacherId
      )?.availableDays;

      const isWithinTeacherAvailabilities =
        checkIfLessonInTeacherAvailabilities(
          {
            lessonStartDate: fullNewLessonStartDate,
            duration: parseInt(formData.duration),
          },
          teacherDays
        );

      if (!isWithinTeacherAvailabilities) {
        toast.warn("Must be within the teacher schedule in (Teacher Days)");
        return;
      }
      const { packageSets } = initialData.packageLesson || {};

      const currentSet = packageSets?.find(
        (setObj) => setNumber === setObj.setNumber
      );
      if (!currentSet) {
        toast.error("Cant find current set");
        return;
      }
      const updatedSetItems = [];

      const ascSortedSetItems =
        sortArrayByDates(currentSet?.setItems, {
          asc: true,
          dateAccessor: "startDate",
        }) || [];

      const newScheduleStartLessonIndex = ascSortedSetItems?.findIndex(
        (setObj) => {
          const passDate = moment(setObj.startDate).isAfter(
            lastLessonDate,
            "date"
          );
          return passDate;
        }
      );
      for (let i = 0; i < newScheduleStartLessonIndex; i++) {
        const currItem = ascSortedSetItems[i];
        updatedSetItems.push(currItem);
      }

      const teacherActivities = await getTeacherActivities(formData.teacherId);
      const teacherTAs = await getTeacherAbsences(formData.teacherId);
      const filteredAbsences = teacherTAs.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );

      const combinedBreaks = getCombinedSchoolBreaks(
        initialData.schoolYears,
        locationId
      );

      let weekIterationIndex = 0;
      loop1: for (
        let i = newScheduleStartLessonIndex;
        i < ascSortedSetItems.length;
        i++
      ) {
        const currentItem = ascSortedSetItems[i];
        let updatedStartDate;
        loop2: while (true) {
          if (weekIterationIndex > 2000) {
            toast.error("Error with getMultiDayTeacherDaysAvailabilities");
            break loop1;
          }
          updatedStartDate = moment(fullNewLessonStartDate)
            .add(weekIterationIndex, "weeks")
            .toDate();
          const isDuringSchoolBreak = combinedBreaks?.some((schoolBreak) =>
            checkIfActivityDuringSchoolBreak(
              { start: updatedStartDate },
              schoolBreak
            )
          );
          // console.log({ isDuringSchoolBreak, date: moment(date).toString() });
          if (isDuringSchoolBreak) {
            weekIterationIndex++;
            continue loop2;
          }
          weekIterationIndex++;
          break loop2;
        }

        const updatedItemObj = {
          id: currentItem?.id || uuidv4(),
          startDate: updatedStartDate,
          duration: parseInt(duration),
          teacherId,
          locationId,
          isVirtual,
          requestedBy: user.uid,
          requestedAt: new Date(),
        };
        const { isValid, message: validationErrorMessage } =
          validatePackageLessonItem(
            updatedItemObj,
            initialData.packageLesson.id,
            teacherActivities,
            filteredAbsences
          );

        if (!isValid) {
          toast.warn(validationErrorMessage);
          return;
        }
        updatedSetItems.push(updatedItemObj);
      }

      const updatedSets = initialData.packageLesson.packageSets?.map(
        (setObj) => {
          if (setObj.setNumber === setNumber) {
            return { ...setObj, setItems: updatedSetItems };
          } else {
            return setObj;
          }
        }
      );
      console.log({
        oldSets: initialData.packageLesson.packageSets,
        updatedSets,
      });

      await updatePackageSetsAndActivity(updatedSets, packageLessonId);

      toast.success("Lesson Edited Successfully");
      //   window.location.reload();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setSavingData(false);
    }
  }

  // available teachers for the lesson instrument + selected location (appears in the teachers select field)
  const availableTeachers = useMemo(() => {
    const filteredTeachers = initialData.teachers.filter((teacher) => {
      const teacherInstruments =
        teacher.instrumentsInfo?.map((info) => info.instrument) || [];
      const sameInstrument = teacherInstruments.includes(
        initialData.packageLesson.instrumentId
      );

      const teacherLocations =
        initialData.teachersAvailableDays
          .find(({ id }) => id === teacher.id)
          ?.availableDays?.map(({ location }) => location) || [];
      const sameLocation = teacherLocations.includes(formData.locationId);

      return sameInstrument && sameLocation;
    });

    return filteredTeachers;
  }, [initialData, formData]);

  const availableClassDays = useMemo(() => {
    const teacherDaysWeekDays = initialData.teachersAvailableDays
      .find(({ id }) => id === formData.teacherId)
      ?.availableDays?.filter(
        ({ location }) => location === formData.locationId
      )
      ?.map((day) => {
        const { startDate, endDate } = day;
        const startDateWeekDay = moment(startDate).day();
        const endDateWeekDay = moment(endDate).day();

        return [startDateWeekDay, endDateWeekDay];
      })
      ?.flat();
    const uniqueWeekDays = [...new Set(teacherDaysWeekDays || [])];

    const filteredWeekDays = weekDays.filter(({ id }) => {
      const isDayAvailable = uniqueWeekDays.includes(id);
      return isDayAvailable;
    });

    return filteredWeekDays;
  }, [formData, initialData]);

  useEffect(() => {
    if (!packageLessonId) return;

    const fetchData = async () => {
      try {
        setLoadingInitialData(true);
        const [
          [packageLesson],
          teachers,
          teachersAvailableDays,
          programs,
          locations,
          schoolYears,
        ] = await Promise.all([
          getPrivateLessonsByIds([packageLessonId]),
          getTeachers(),
          getTeachersAvailableDays(),
          getPrograms(),
          getLocations(),
          getSchoolYears(),
        ]);

        setInitialData({
          packageLesson,
          teachers,
          teachersAvailableDays,
          programs,
          locations,
          schoolYears,
        });

        const { packageSets } = packageLesson || {};
        const currentSet = packageSets?.find(
          (setObj) => setNumber === setObj.setNumber
        );

        if (!currentSet || !currentSet.setItems?.length) {
          throw { message: "Couldnt find current set items" };
        }

        const { startDate, duration, teacherId, locationId, isVirtual } =
          currentSet.setItems[0];
        const date = moment(startDate).format("YYYY-MM-DD");
        const time = moment(startDate).format("HH:mm");
        // const strDuration = `${duration}`;
        const weekDay = moment(startDate).weekday();

        setFormData({
          time,
          duration,
          teacherId,
          locationId,
          weekDay,
          isVirtual,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [packageLessonId]);

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears?.length || !initialData.packageLesson)
      return [];

    const locationId =
      initialData.packageLesson?.packageSets?.[0]?.setItems?.[0]?.locationId;

    if (!locationId) return [];

    const combinedBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      locationId
    );

    return combinedBreaks;
  }, [initialData]);

  return {
    initialData,
    loadingInitialData,
    formData,
    handleFormChange,
    availableTeachers,
    availableClassDays,
    savingData,
    saveChanges,
    combinedSchoolBreaks,
  };
};

export default usePackageLessonSchedule;
