import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";

import useCreateGroupClass from "../hooks/useCreateGroupClass";
import { injectUserStore } from "src/utils/helpers";
import { createGroupClassViews } from "../constants";
import ClassInfoComponent from "./components/ClassInfoComponent";
import PaymentComponent from "./components/PaymentComponent";

const CreateClassBody = ({ UserStore }) => {
  const user = UserStore.user;
  const hookData = useCreateGroupClass({ user });
  const { currentView } = hookData;

  const renderCurrentViewComponent = () => {
    if (currentView === createGroupClassViews.CLASS_INFO) {
      return <ClassInfoComponent hookData={hookData} />;
    } else if (currentView === createGroupClassViews.PAYMENT) {
      return <PaymentComponent hookData={hookData} />;
    } else {
      return;
    }
  };
  return (
    <div>
      <StyledBarContent title="Create Group Class" overFlowContent={true}>
        <div className="p-4">{renderCurrentViewComponent()}</div>
      </StyledBarContent>

      <div className="mt-4"></div>
    </div>
  );
};

export default injectUserStore(CreateClassBody);
