import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import { injectUserStore } from "src/utils/helpers";
import useCreateConcert from "../hooks/useCreateConcert";
import { concertViews } from "../constants";
import FirstPage from "../components/FirstPage";
import { ModalButton } from "src/utils/shared/styled";
import { CustomButtonsWrapper } from "../styled";
import { isValidFirstPage, isValidSecondPage } from "../helperFns";
import { toast } from "react-toastify";
import SecondPage from "../components/SecondPage";
import { ModalButtonWithLoading } from "src/utils/shared/shared";
import PaymentPage from "../components/PaymentPage";

const CreateConcertBody = ({ closeModal, UserStore }) => {
  const user = UserStore.user;
  const hookData = useCreateConcert({ user });
  const {
    currentView,
    setCurrentView,
    concertData,
    handleCreateConcert,
    isSavingConcert,
  } = hookData;

  const renderCurrentViewComponent = () => {
    if (currentView === concertViews.FIRST_PAGE) {
      return <FirstPage closeModal={closeModal} hookData={hookData} />;
    } else if (currentView === concertViews.SECOND_PAGE) {
      return <SecondPage hookData={hookData} />;
    } else if (currentView === concertViews.THIRD_PAGE) {
      return <PaymentPage hookData={hookData} />;
    } else {
      return;
    }
  };

  const renderCurrentViewButtons = () => {
    if (currentView === concertViews.FIRST_PAGE) {
      return (
        <CustomButtonsWrapper className="mt-4">
          <ModalButton onClick={closeModal}>Cancel</ModalButton>
          <ModalButton
            primary
            onClick={() => {
              if (!isValidFirstPage(concertData)) {
                toast.warn("First Page values are incomplete");
                return;
              }
              setCurrentView(concertViews.SECOND_PAGE);
            }}
          >
            Next
          </ModalButton>
        </CustomButtonsWrapper>
      );
    } else if (currentView === concertViews.SECOND_PAGE) {
      return (
        <CustomButtonsWrapper className="mt-4">
          <ModalButton
            onClick={() => {
              setCurrentView(concertViews.FIRST_PAGE);
            }}
          >
            Back
          </ModalButton>
          <ModalButton
            primary
            onClick={() => {
              if (!isValidSecondPage(concertData)) {
                toast.warn("Second Page values are incomplete");
                return;
              }
              setCurrentView(concertViews.THIRD_PAGE);
            }}
          >
            Next
          </ModalButton>
        </CustomButtonsWrapper>
      );
    } else if (currentView === concertViews.THIRD_PAGE) {
      return (
        <CustomButtonsWrapper className="mt-4">
          <ModalButton onClick={() => setCurrentView(concertViews.SECOND_PAGE)}>
            Back
          </ModalButton>
          <ModalButtonWithLoading
            isLoading={isSavingConcert}
            primary
            onClick={() => {
              handleCreateConcert();
            }}
          >
            Confirm
          </ModalButtonWithLoading>
        </CustomButtonsWrapper>
      );
    } else if (currentView === concertViews.THIRD_PAGE) {
      return <div></div>;
    } else {
      return;
    }
  };
  return (
    <div>
      <StyledBarContent title="Create Concert" overFlowContent={true}>
        <div className="p-4">{renderCurrentViewComponent()}</div>
      </StyledBarContent>
      <div>{renderCurrentViewButtons()}</div>
      <div className="mt-4"></div>
    </div>
  );
};

export default injectUserStore(CreateConcertBody);
