import { PrimaryButton } from "src/utils/shared/styled";
import styled from "styled-components";

export const RowContainer = styled.div`
  border-radius: 35px;
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  gap: 15px;
  flex-wrap: wrap;
`;
export const InfoText1 = styled.p`
  margin: 0;
  font-size: 2.1rem;
  font-weight: bold;
`;
export const InfoText2 = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: grey;
`;
export const CustomPrimaryButton = styled(PrimaryButton)`
  width: 130px;
  color: #681e46;
  background: #fff;
  box-shadow: 0px 2px 10px #00000014;
  transition-duration: 150ms;

  &:hover {
    color: #fff;
    background: #681e46;
  }
`;
