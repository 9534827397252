import React, { useContext } from "react";
import { documentId, getDocs, query, where } from "firebase/firestore";
import { chunkArrayInGroups } from "../../../../../utils/helpers";
import { FirebaseContext } from "../../../../Firebase";
import {
  getFirebaseDoc,
  parseFirebaseDoc,
} from "../../../../../utils/getFirebaseDoc";
import { UserRole } from "../../../../../constants/UserRoleEnum";
import { filterLessonsByLocation, filterTLsByLocation } from "../helperFns";
import { studentTypes } from "../../constants";
import {
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getTeachers = async () => {
    try {
      const teachersQuery = query(
        firebase.users(),
        where("role", "==", UserRole.TEACHER)
      );
      const teachersSnapshot = await getDocs(teachersQuery);
      const teachers = getFirebaseDoc(teachersSnapshot);
      return teachers;
    } catch (err) {
      console.log(err);
    }
  };
  // Takes an array of student ids , returns those students from firebase whether they are private or trial students (supports more than 10 students)
  const getStudentsByIds = async (
    studentIds,
    studentType = studentTypes.privateStudent.name
  ) => {
    if (!studentIds?.length) return {};
    try {
      const studentIdsChunks = chunkArrayInGroups(studentIds, 10);
      let requests = [];
      studentIdsChunks.forEach((studentIdsArray) => {
        const studentsDocRef =
          studentType === studentTypes.privateStudent.name
            ? firebase.users()
            : studentType === studentTypes.trialStudent.name
            ? firebase.trialStudents()
            : null;
        const studentsQuery = query(
          studentsDocRef,
          where(documentId(), "in", studentIdsArray)
        );
        const req = getDocs(studentsQuery);
        requests.push(req);
      });
      const studentsSnapshotsChunks = await Promise.all(requests);
      const studentsChunks = studentsSnapshotsChunks.map((studentsSnapshot) =>
        getFirebaseDoc(studentsSnapshot)
      );
      const students = studentsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      return students;
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  //   returns private lessons object containing the lessons for the given teacherIds and locationId (Supports more than 10 teacherIds)
  const getPrivateLessonsByTeacherIds = async (teacherIds, locationId) => {
    if (!teacherIds?.length) return {};
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const lessonsQuery = query(
          firebase.PrivateLessons(),
          where("teachersIds", "array-contains-any", teacherIdsArray)
        );
        const req = getDocs(lessonsQuery);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        getFirebaseDoc(lessonsSnapshot)
      );
      const lessons = lessonsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      // const filteredLessons = filterLessonsByLocation(lessons, locationId);
      return lessons;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getPrivateLessons = async () => {
    try {
      const snap = await getDocs(firebase.PrivateLessons());
      const lessons = parseFirebaseDoc(snap.docs);

      return lessons;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getTrialLessonsByTeacherIds = async (teacherIds, locationId) => {
    if (!teacherIds?.length) return {};
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const lessonsQuery = query(
          firebase.trialLessons(),
          where("teacherId", "in", teacherIdsArray)
        );
        const req = getDocs(lessonsQuery);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        getFirebaseDoc(lessonsSnapshot)
      );
      const lessons = lessonsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      const filteredLessons = filterTLsByLocation(lessons, locationId);
      return filteredLessons;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  //   returns makeup lessons object containing the lessons for the given teacherIds and locationsIds (Supports more than 10 teacherIds)
  const getMakeupLessonsByTeacherIds = async (teacherIds, locationId) => {
    if (!teacherIds?.length) return {};
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const lessonsQuery = query(
          firebase.makeupLessons(),
          where("teacherId", "in", teacherIdsArray)
        );
        const req = getDocs(lessonsQuery);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        getFirebaseDoc(lessonsSnapshot)
      );
      const lessons = lessonsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      const filteredLessons = filterLessonsByLocation(lessons, locationId);
      return filteredLessons;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getAppointmentsByTeacherIds = async (teacherIds, locationId) => {
    if (!teacherIds?.length) return {};
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const appointmentsQuery = query(
          firebase.appointments(),
          where("teacherId", "in", teacherIdsArray)
        );
        const req = getDocs(appointmentsQuery);
        requests.push(req);
      });
      const appointmentsSnapshotsChunks = await Promise.all(requests);
      const appointmentsChunks = appointmentsSnapshotsChunks.map(
        (appointmentsSnapshot) => getFirebaseDoc(appointmentsSnapshot)
      );
      const appointments = appointmentsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );

      // using the filterLessonsByLocation fn on the appointments bec it has the same properties we need for filtering as the lessons (locationId)
      const filteredAppointments = filterLessonsByLocation(
        appointments,
        locationId
      );

      return filteredAppointments;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getAbsencesByTeacherIds = async (teacherIds) => {
    try {
      if (!teacherIds?.length) return [];

      const teacherIdsChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdsChunks.forEach((teacherIdsArray) => {
        const absencesQuery = query(
          firebase.absences(),
          where("teacherId", "in", teacherIdsArray)
        );
        const req = getDocs(absencesQuery);
        requests.push(req);
      });
      const absencesSnapshotsChunks = await Promise.all(requests);
      const absencesChunks = absencesSnapshotsChunks.map((absencesSnapshot) =>
        parseFirebaseDoc(absencesSnapshot.docs)
      );
      const teacherAbsences = absencesChunks
        .flat()
        .filter(
          ({ status }) =>
            !isPendingAbsence(status) && !isDeclinedAbsence(status)
        );

      return teacherAbsences;
    } catch (err) {
      console.log(err);
    }
  };
  const getSchoolYears = async () => {
    try {
      const schoolYearsSnap = await getDocs(firebase.getSchoolYears());
      const schoolYears = parseFirebaseDoc(schoolYearsSnap.docs);
      return schoolYears;
    } catch (err) {
      console.log(err);
    }
  };

  const getCalendarLabelsByTeacherIds = async (teacherIds) => {
    if (!teacherIds?.length) return {};
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const labelsQuery = query(
          firebase.calendarLabels(),
          where("userId", "in", teacherIdsArray)
        );
        const req = getDocs(labelsQuery);
        requests.push(req);
      });
      const labelsSnapshotsChunks = await Promise.all(requests);
      const labelsChunks = labelsSnapshotsChunks.map((labelsSnapshot) =>
        getFirebaseDoc(labelsSnapshot)
      );
      const labels = labelsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      return labels;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getBreaksByTeacherIds = async (teacherIds) => {
    if (!teacherIds?.length) return [];
    try {
      const teacherIdChunks = chunkArrayInGroups(teacherIds, 10);
      let requests = [];
      teacherIdChunks.forEach((teacherIdsArray) => {
        const q = query(
          firebase.breaks(),
          where("userId", "in", teacherIdsArray)
        );
        const req = getDocs(q);
        requests.push(req);
      });
      const snapshotsChunks = await Promise.all(requests);
      const chunks = snapshotsChunks.map((appointmentsSnapshot) =>
        parseFirebaseDoc(appointmentsSnapshot.docs)
      );

      const breaks = chunks.flat();
      return breaks;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  return {
    getTeachers,
    getStudentsByIds,
    getPrivateLessonsByTeacherIds,
    getTrialLessonsByTeacherIds,
    getMakeupLessonsByTeacherIds,
    getAppointmentsByTeacherIds,
    getAbsencesByTeacherIds,
    getSchoolYears,
    getCalendarLabelsByTeacherIds,
    getBreaksByTeacherIds,
    getPrivateLessons,
  };
};

export default useFirebaseFns;
