// Teacher Dashboard Home
import React from "react";
import RouteContent from "../../../common/RouteContent";
// import "../../Style/Style.css";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import DateAndCalendarSection from "./DateAndCalendarSection";
import LatestFeedItemSection from "../../common/components/LatestFeedItemSection";
import FeedItemsSection from "../../common/components/FeedItemsSection";
const Container = styled.div`
  padding: 30px 30px;
  display: flex;
`;
function RecentPosts({ UserStore }) {
  return (
    <>
      <RouteContent title="Recent Posts"></RouteContent>
      <div>
        <LatestFeedItemSection />
      </div>
      <Container>
        <div style={{ width: "60%" }}>
          <FeedItemsSection />
        </div>
        <div style={{ width: "40%" }}>
          <DateAndCalendarSection />
        </div>
      </Container>
    </>
  );
}

export default compose(inject("UserStore"), observer)(RecentPosts);
