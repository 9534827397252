import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  absenceBehaviours,
  absenceLessonTypes,
  absenceStatuses,
  absenceTypes,
} from "src/constants/absenceTypes";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getGroupClassClassesByFromDates } from "src/utils/helpers";

const initialDataInitialValues = {
  groupClass: null,
  students: [],
  enrollments: [],
};

const useGroupClassAbsence = ({ groupClassId, user }) => {
  const {
    getGroupClassById,
    getUsersByIds,
    getGroupClassEnrollments,
    createAbsenceDoc,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null); // moment obj
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleStudentIdChange = (option) => {
    setSelectedStudentId(option?.value || "");
  };
  const handleSelectedDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  async function requestAbsence() {
    try {
      if (!selectedDate || !selectedStudentId) {
        toast.warn("Please fill all fields");
        return;
      }

      setIsSaving(true);

      const classInfoOnDate = initialData.groupClass.classes.find((classObj) =>
        moment(classObj.startDate).isSame(moment(selectedDate).toDate(), "date")
      );

      if (!classInfoOnDate) {
        toast.error("Something went wrong confirming the absence");
        return;
      }

      const absenceDate = classInfoOnDate.startDate;
      const absenceTeachers = classInfoOnDate.teachersIds || [];

      const absenceObj = {
        createdAt: new Date(),
        date: absenceDate,
        updatedAt: new Date(),
        absenceType: absenceTypes.regular.code,
        status: absenceStatuses.APPROVED,

        absenceBehaviour: absenceBehaviours.PER_LESSON,

        // only for per lesson absences
        lessonType: absenceLessonTypes.GROUP_CLASS,
        lessonId: groupClassId,
        userId: user.uid,
        userRole: user.role,
        teachersIds: absenceTeachers,
        studentId: selectedStudentId,
      };
      console.log({ absenceObj });

      await createAbsenceDoc(absenceObj);

      toast.success("Absence Confirmed!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSaving(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);

        const [groupClass, enrollments] = await Promise.all([
          getGroupClassById(groupClassId),
          getGroupClassEnrollments(groupClassId),
        ]);

        const studentsIds = [
          ...new Set(enrollments?.map(({ studentId }) => studentId)),
        ];
        const students = await getUsersByIds(studentsIds);

        setInitialData({
          groupClass,
          enrollments,
          students,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [groupClassId]);

  const selectedStudent = useMemo(() => {
    if (!selectedStudentId || !initialData.students?.length) return;

    return initialData.students.find(({ id }) => id === selectedStudentId);
  }, [selectedStudentId, initialData]);

  const classDates = useMemo(() => {
    if (!initialData.groupClass) return [];

    const classes = initialData.groupClass?.classes || [];

    const dates = classes.map(({ startDate }) => startDate);
    return dates;
  }, [initialData]);

  return {
    initialData,
    loadingInitialData,
    handleStudentIdChange,
    handleSelectedDateChange,
    selectedStudent,
    selectedDate,
    isDatePickerOpen,
    setIsDatePickerOpen,
    classDates,
    isSaving,
    requestAbsence,
  };
};

export default useGroupClassAbsence;
