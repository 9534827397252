import React from "react";
import { TABS } from "src/components/common/Library/common/constants";
import { NavButton } from "src/utils/shared/styled";

const NavigationBar = ({ currentTab, setCurrentTab }) => {
  const filteredTabs = TABS.filter(({ key }) => key !== "studentsUploads");
  return (
    <div className="d-flex align-items-center" style={{ overflow: "auto" }}>
      {filteredTabs.map(({ title, key, IconComponent }) => (
        <div>
          <NavButton
            fontSize="1rem"
            className={currentTab === key ? "active" : undefined}
            onClick={() => setCurrentTab(key)}
            withRedBar
          >
            {title}
          </NavButton>
        </div>
      ))}
    </div>
  );
};

export default NavigationBar;
