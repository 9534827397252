import { useEffect, useRef } from "react";
import {
  eventsMap,
  isSingleTimeCalendarLabelEvent,
  isOneTimeBreakEvent,
  isRecurringBreakEvent,
  isRecurringCalendarLabelEvent,
  isPackagePrivateLesson,
  isAppointmentEvent,
  isPrivateLessonEvent,
  isTrialLessonEvent,
  isMakeupLessonEvent,
} from "src/constants/eventsEnum";
import moment from "moment";

const CustomEventComponent = ({ event }) => {
  const { eventCode } = event;

  const ref = useRef(null);

  const isSingleTimeLabel = isSingleTimeCalendarLabelEvent(eventCode);
  const isRecurringLabel = isRecurringCalendarLabelEvent(eventCode);
  const isSingleTimeBreak = isOneTimeBreakEvent(eventCode);
  const isRecurringBreak = isRecurringBreakEvent(eventCode);
  const isAppointment = isAppointmentEvent(eventCode);
  const isPL = isPrivateLessonEvent(eventCode);
  const isTrialLesson = isTrialLessonEvent(eventCode);
  const isMakeupLesson = isMakeupLessonEvent(eventCode);

  const hasTooltip =
    isSingleTimeBreak ||
    isRecurringBreak ||
    isRecurringLabel ||
    isSingleTimeLabel ||
    isAppointment ||
    isPL ||
    isTrialLesson ||
    isMakeupLesson;

  const formattedStart = moment(event.start).format("hh:mm A");
  const formattedEnd = moment(event.end).format("hh:mm A");

  let tooltipTitle;
  if (hasTooltip) {
    if (isSingleTimeBreak || isRecurringBreak) {
      tooltipTitle = `Teacher Break: ${formattedStart} - ${formattedEnd}`;
    } else if (isRecurringLabel || isSingleTimeLabel) {
      tooltipTitle = event.note || event.title;
    } else if (isAppointment) {
      tooltipTitle = `Appointment: ${formattedStart} - ${formattedEnd} (${event.title})`;
    } else if (isPL) {
      tooltipTitle = `Private Lesson: ${formattedStart} - ${formattedEnd} (${event.student?.fullName})`;
    } else if (isTrialLesson) {
      tooltipTitle = `Trial Lesson: ${formattedStart} - ${formattedEnd} (${event.student?.name})`;
    } else if (isMakeupLesson) {
      tooltipTitle = `Makeup Lesson: ${formattedStart} - ${formattedEnd} (${event.student?.fullName})`;
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: event?.isCanceled && "#989898",
      }}
    >
      {hasTooltip ? (
        <>
          <div
            className="calendar-event-tooltip"
            data-tooltip-content={tooltipTitle}
          >
            <span>{event.title}</span>
          </div>
        </>
      ) : (
        <span>{event.title}</span>
      )}
    </div>
  );
};
export default CustomEventComponent;
