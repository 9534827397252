import { Menu } from "antd";
import styled from "styled-components";

const GuestDropdownMenu = styled(Menu)`
  border-radius: 1.3rem;

  .ant-menu-title-content {
    color: #fff;
    font-size: 20px;
  }

  .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-arrow::before {
    display: inline-block;
    margin-left: 8px;
    color: #fff;
  }

  .ant-menu-submenu {
    height: 100% !important;
    width: 150px !important;
  }

  .ant-menu-item {
    padding: 0 8px !important;
  }

  .ant-menu-submenu-title {
    height: 100% !important;
    width: 100% !important;
    border-radius: 1.3rem !important;
    margin: 0 !important;
    padding: 0 12px !important;

    &:active {
      background-color: #681e46 !important;
    }

    > span {
      color: #fff;
    }
  }

  .ant-menu,
  .ant-menu-submenu,
  .ant-menu-item {
    background-color: #681e46 !important;
    border-radius: 1.3rem !important;
    user-select: none !important;
  }
`;

export { GuestDropdownMenu };
