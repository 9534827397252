/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from "@styled-icons/evaicons-solid/Close";
import {
  addDoc,
  arrayUnion,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { compose } from "recompose";
import {
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import { eventsMap } from "src/constants/eventsEnum";
import { trialLessonStatuses } from "src/constants/trialLessonStatuses";
import useModal from "src/hooks/useModal";
import {
  getSchoolBreakInSpecificDate,
  getTeacherAbsencesOnRequestDate,
  getTeacherActivitiesInSpecificDate,
} from "src/hooks/useTeachersAvailabilities/helperFns";
import {
  parseAbsenceObjDates,
  parseUserActivityDates,
  parseUserAvailableDayDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import {
  CalendarPickerWrapper,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import styled from "styled-components";
import { LessonsLengthEnum } from "../../../constants/LessonsLength";
import { scheduleTypes } from "../../../constants/scheduleTypes";
import { UserRole } from "../../../constants/UserRoleEnum";
import { weekDays } from "../../../constants/weekDays";
import {
  convertDurationOrAllDayAbsenceToBusySlot,
  getAvailableTimeSlots,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getTimeChunks,
  isActiveTeacher,
  mergeOverlappingDateRanges,
  prepareBusySlots,
  prepareMakeupOpenings,
  prepareTeacherDays,
  updatedMomentNow,
} from "../../../utils/helpers";
import {
  CustomLabel,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  InfoText,
} from "../../../utils/styled";
import CustomDropDown from "../../common/DropDown";
import SubmitSpinner from "../../common/SubmitSpinner";
import TimeDropDown from "../../common/TimeDropDown";
import ConfirmationModal from "../common/components/ConfirmationModal";

import "./style.css";
import { TrialLessonsContext } from "./TrialLessons";
import { SingleDatePicker } from "react-dates";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const breakDurations = {
  100: { lessonDuration: 100, breakDuration: 5 },
  80: { lessonDuration: 80, breakDuration: 10 },
};

const initialLessonValues = {
  trialDate: "",
  instrument: "",
  teacherId: "",
  // locations: [],
  lessonLength: "",
  time: "",
  note: "",
  fee: "",
  isPaid: false,
  dateOfPayment: "",
  isVirtual: false,
};

const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fbf8f9";
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const CreateStudentTrialLesson = ({
  isOpen,
  firebase,
  mode,
  studentIndex,
  lessonId,
  onClose,
  studentID,
  UserStore,
}) => {
  const {
    instruments,
    teachers,
    locations,
    programs = [],
    students,
    onLessonUpdateSuccess,
    onLessonCreateSuccess,
  } = useContext(TrialLessonsContext);
  const { getUserMakeUpOpenings } = useFirebaseFns();

  //new lesson confirmation modal
  const confirmationModalData = useModal();

  const user = toJS(UserStore)?.user;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lesson, setLesson] = useState({ ...initialLessonValues });

  const [availableDays, setAvailableDays] = useState([]);
  const [makeupOpenings, setMakeupOpenings] = useState([]);

  const [selectableTeachers, setSelectableTeachers] = useState({});
  const [TeacherHours, setTeacherHours] = useState([]);
  const [lessonTime, setLessonTime] = useState("");
  const [TeacherCheckAvailableHours, setTeacherCheckAvailableHours] = useState(
    []
  );
  const [makeupAvailabilities, setMakeupAvailabilities] = useState([]);
  const [UserActivity, setUserActivity] = useState([]);
  const [teacherBusySlots, setTeacherBusySlots] = useState([]);
  const [TeacherTimeChunck, setTeacherTimeChunck] = useState([]);
  const [TeacherTimeChunckValues, setTeacherTimeChunckValues] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  console.log({ makeupAvailabilities });

  const combinedAvailabilities = useMemo(() => {
    return [
      ...(TeacherCheckAvailableHours || []),
      ...(makeupAvailabilities || []),
    ];
  }, [TeacherCheckAvailableHours, makeupAvailabilities]);
  // console.log({
  //   TeacherTimeChunck,
  //   TeacherTimeChunckValues,
  //   TeacherCheckAvailableHours,
  // });
  const updateLessonState = (key, value) => {
    setLesson((previous) => ({ ...previous, [key]: value }));
  };
  useEffect(() => {
    if (lesson?.trialDate && lesson?.lessonLength) {
      const preparedTeacherDay = prepareTeacherDays(
        lesson.trialDate,
        availableDays
      )?.[0];
      const preparedMakeupOpening = prepareMakeupOpenings(
        lesson.trialDate,
        makeupOpenings
      )?.[0];
      const Duration = lesson?.lessonLength;
      if (preparedMakeupOpening) {
        if (!lesson.isVirtual && preparedMakeupOpening.isVirtualOnly) {
          return;
        } else {
          HandelTeacherBusySlots(
            preparedMakeupOpening.newStartDate,
            preparedMakeupOpening.newEndDate,
            Duration,
            true
          );
        }
      } else if (preparedTeacherDay) {
        if (!lesson.isVirtual && preparedTeacherDay.isVirtualOnly) {
          return;
        } else {
          HandelTeacherBusySlots(
            preparedTeacherDay.newStartDate,
            preparedTeacherDay.newEndDate,
            Duration
          );
        }
      }
    }
  }, [
    teacherBusySlots,
    lesson?.trialDate,
    lesson?.lessonLength,
    lesson?.isVirtual,
    availableDays,
  ]);
  useEffect(() => {
    if (lesson?.trialDate && lesson?.lessonLength) {
      const combinedAvailableHours = [
        ...(TeacherCheckAvailableHours || []),
        ...(makeupAvailabilities || []),
      ];
      if (combinedAvailableHours.length > 0) {
        const availableTimeArray = [];
        combinedAvailableHours.forEach((TeacherAvTime) => {
          const TimeChunck = getTimeChunks(
            TeacherAvTime.start_time,
            TeacherAvTime.end_time,
            lesson?.lessonLength,
            15,
            false
          );
          availableTimeArray.push(TimeChunck);
        });
        setTeacherTimeChunck(availableTimeArray);
      } else {
        setTeacherTimeChunck([]);
      }
    }
  }, [
    TeacherCheckAvailableHours,
    makeupAvailabilities,
    lesson.lessonLength,
    lesson.trialDate,
  ]);
  useEffect(() => {
    HandelTeacherTimeChunck();
  }, [TeacherTimeChunck]);
  const HandelTeacherBusySlots = (
    StartTimeMainFormat,
    EndTimeMainFormat,
    Duration,
    isMakeupOpening = false
  ) => {
    const combinedSchoolBreaks = getCombinedSchoolBreaks(
      schoolYears,
      selectedLocationId
    );

    // gets the school break that is hapening during the requestedDate
    const schoolBreakInRequestedDate = getSchoolBreakInSpecificDate(
      combinedSchoolBreaks,
      lesson.trialDate
    );
    const schoolBreakBusySlots = schoolBreakInRequestedDate
      ? [
          {
            start_time: schoolBreakInRequestedDate.from.toDate(),
            end_time: schoolBreakInRequestedDate.to.toDate(),
          },
        ]
      : [];
    // console.log({
    //   schoolBreakInRequestedDate,
    //   schoolBreakBusySlots: schoolBreakBusySlots.map(
    //     ({ start_time, end_time }) => ({
    //       start_time: moment(start_time).toString(),
    //       end_time: moment(end_time).toString(),
    //     })
    //   ),
    // });
    // get teacher absences (TAs) on request date without per lesson absences
    const absencesWithDates =
      absences?.map((absence) => parseAbsenceObjDates(absence)) || [];
    const teacherAbsencesOnRequestedDate = getTeacherAbsencesOnRequestDate(
      lesson.teacherId,
      absencesWithDates,
      lesson?.trialDate
    );
    const StartTime = new Date(StartTimeMainFormat);
    const EndTime = new Date(EndTimeMainFormat);
    const absencesBusySlots =
      teacherAbsencesOnRequestedDate.map((absence) =>
        convertDurationOrAllDayAbsenceToBusySlot(absence, StartTime, EndTime)
      ) || [];

    const combinedBusySlots = [
      ...absencesBusySlots,
      ...teacherBusySlots,
      ...schoolBreakBusySlots,
    ];

    const preparedBusySlots = prepareBusySlots(
      combinedBusySlots,
      StartTime,
      EndTime
    );

    const TeacherAvailability = getAvailableTimeSlots(
      StartTime,
      EndTime,
      preparedBusySlots || [],
      parseInt(Duration),
      true
    );

    if (isMakeupOpening) {
      setMakeupAvailabilities(TeacherAvailability);
    } else {
      setTeacherCheckAvailableHours(TeacherAvailability);
    }
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      const absencesQuery = query(
        firebase.absences(),
        where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const absences = parseFirebaseDoc((await getDocs(absencesQuery)).docs);
      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );

      const schoolYears = parseFirebaseDoc(
        (await getDocs(firebase.getSchoolYears())).docs
      );
      setSchoolYears(schoolYears);
      setAbsences(filteredAbsences);
    };
    fetchInitialData();
  }, []);
  const HandleTeacherAvailability = () => {
    const Activities = [];
    setTeacherCheckAvailableHours([]);
    setTeacherBusySlots([]);
    setTeacherTimeChunck([]);
    setTeacherTimeChunckValues([]);
    const DateFormat = moment(lesson.trialDate).format("YYYY MM DD").toString();
    const date = new Date();

    if (UserActivity?.length) {
      // returns recurring events at the same weekDay and non-recurring events at the same exact day + adds start_time and end_time to them (based on the requested date)
      const UserActivityWithDates =
        UserActivity?.map((activity) => parseUserActivityDates(activity)) || [];

      const absencesWithDates = absences.map((absence) =>
        parseAbsenceObjDates(absence)
      );
      const teacherActivitiesInRequestedDate =
        getTeacherActivitiesInSpecificDate(
          lesson.teacherId,
          UserActivityWithDates,
          lesson?.trialDate,
          absencesWithDates
        );

      const busySlots = teacherActivitiesInRequestedDate?.map((activity) => ({
        start_time: activity?.start_time,
        end_time: activity?.end_time,
      }));

      setTeacherBusySlots(busySlots);
    } else {
      setTeacherBusySlots([]);
    }
  };
  const HandelLessonTime = (lessontime) => {
    const DateFormat = moment(lesson?.trialDate)
      .format("YYYY MM DD")
      .toString();
    const TeacherStartTime = Object.values(lessontime)?.[0]
      .slice(0, 8)
      .toString();
    // const StartTimeMainFormat = moment(
    //   DateFormat + " " + TeacherStartTime
    // ).toDate();
    // const StartTimeTimeStamp = new Timestamp.fromDate(StartTimeMainFormat);
    updateLessonState("time", TeacherStartTime);
  };

  useEffect(() => {
    if (lesson?.trialDate) {
      HandleTeacherAvailability();
    }
  }, [lesson?.trialDate]);
  useEffect(() => {
    if (lesson?.instrument) {
      Object.freeze(teachers);
      let tempTeachers = Object.values(teachers);
      tempTeachers = tempTeachers.filter((teacher) => {
        const isActive = isActiveTeacher(teacher);

        const sameInstrument = teacher.instrumentsInfo?.some((obj) => {
          return obj.instrument === lesson.instrument;
        });
        return isActive && sameInstrument;
      });
      const transactionsTeachers = {};
      for (let currentTeacher of tempTeachers) {
        const currentProgramId =
          currentTeacher.instrumentsInfo.find(
            ({ instrument }) => instrument === lesson.instrument
          )?.program || "";
        const programName = programs?.[currentProgramId]?.name;
        transactionsTeachers[currentTeacher.id] = {
          ...currentTeacher,
          fullName: `${currentTeacher.fullName} | ${programName}`,
        };
      }
      setSelectableTeachers({ ...transactionsTeachers });
    }
  }, [lesson?.instrument]);
  useEffect(async () => {
    setAvailableDays([]);
    if (lesson?.teacherId) {
      const q1 = query(
        firebase.usersActivities(),
        where("userId", "==", lesson.teacherId),
        where("isActive", "==", true)
      );
      const q2 = query(
        firebase.usersActivities(),
        where("usersIds", "array-contains", lesson.teacherId),
        where("isActive", "==", true)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [docs1, docs2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedActivities = [...docs1, ...docs2];

      setUserActivity(combinedActivities);

      const [TeacherAvailableTime, openings] = await Promise.all([
        getDoc(firebase.userAvailableDays(lesson?.teacherId)),
        getUserMakeUpOpenings(lesson?.teacherId),
      ]);
      const availableDaysWithDates = parseUserAvailableDayDates(
        TeacherAvailableTime.data()
      );
      const TeacherAvailableTimeData = availableDaysWithDates.availableDays;
      const availableDaysInLocation =
        availableDaysWithDates.availableDays?.filter(
          (teacherDay) =>
            teacherDay.location === selectedLocationId && !teacherDay.isPaused
        );
      const openingsInLocation = openings.filter(
        ({ location }) => location === selectedLocationId
      );
      // const availableDaysArray = [];
      // TeacherAvailableTimeData.forEach((Day) => {
      //   if (Day.location === selectedLocationId) {
      //     const locationData = locations.find(({ id }) => id === Day.location);
      //     const DayName = weekDays.filter(
      //       (Currday) => Currday.id.toString() === Day.day.toString()
      //     )[0].name;
      //     const Avilableday = {
      //       ...Day,
      //       locationName: locationData.name,
      //       DayName,
      //     };
      //     availableDaysArray.push(Avilableday);
      //   }
      // });

      //TODO: FIX THIS
      if (mode === "edit") {
        updateLessonState(
          "trialDate",
          moment(lesson.date.toDate()).format("YYYY-MM-DD")
        );
      }
      setAvailableDays(availableDaysInLocation);
      setMakeupOpenings(openingsInLocation);
    }
  }, [lesson?.teacherId, selectedLocationId]);
  const currentStudent = students[studentIndex];

  useEffect(() => {
    if (isOpen) {
      if (mode === "edit") {
        const selectedLesson = currentStudent.trialLessons.filter(
          ({ id }) => id === lessonId
        )[0];
        setLesson({ ...selectedLesson });
        updateLessonState("instrument", selectedLesson.instrument);
        updateLessonState("teacherId", selectedLesson.teacherId);
        updateLessonState(
          "trialDate",
          moment(selectedLesson.date.toDate()).format("YYYY-MM-DD")
        );
        setSelectedLocationId(selectedLesson.locations[0]);

        const startTime = moment(selectedLesson.date.toDate()).format(
          "hh:mm A"
        );
        const endTime = moment(selectedLesson.date.toDate())
          .add(selectedLesson.lessonLength, "minutes")
          .format("hh:mm A");

        const lessonTimeValue = [`${startTime} : ${endTime}`];
        HandelLessonTime(lessonTimeValue);
        setLessonTime(lessonTimeValue);
      } else {
        setLesson(initialLessonValues);
      }
    }
  }, [isOpen]);
  const saveLesson = async () => {
    try {
      if (
        !lesson.trialDate ||
        !lesson.instrument ||
        !lesson.teacherId ||
        !selectedLocationId ||
        !lesson.lessonLength ||
        !lesson.time ||
        !lesson.note
      ) {
        toast.warn("Please fill all required fields");
        return;
      }
      setIsSubmitting(true);
      const { selectedTeacher, trialDate, time, ...lessonData } = lesson;
      const lessonHrs = moment(time, "hh:mm A").get("hours");
      const lessonMins = moment(time, "hh:mm A").get("minutes");

      const lessonFullDate = moment(trialDate)
        .set({
          hours: lessonHrs,
          minutes: lessonMins,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      lessonData.locations = [selectedLocationId];
      let id;
      if (mode === "edit") {
        lessonData.updatedAt = Timestamp.now();
        lessonData.date = Timestamp.fromDate(moment(lessonFullDate).toDate());
        if (teachers[lessonData.teacherId] === undefined) {
          lessonData.teacherId = selectedTeacher;
        }
        await updateDoc(firebase.getTrialLesson(lessonId), lessonData);
        onLessonUpdateSuccess(lessonId, lessonData);
        id = lessonId;

        const activityUpdateObj = {
          start_time: lessonFullDate,
          end_time: moment(lessonFullDate)
            .add(parseInt(lesson?.lessonLength), "minutes")
            .toDate(),
          userId: lesson.teacherId,
        };
        const res = await updateDoc(
          firebase.userActivity(lessonId),
          activityUpdateObj
        );
      } else {
        const lessonDocument = {
          ...lessonData,
          date: Timestamp.fromDate(moment(lessonFullDate).toDate()),
          studentId: studentID,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          status: trialLessonStatuses.active.code,
        };

        const lessonDoc = await addDoc(
          firebase.getTrialLessons(),
          lessonDocument
        );
        await updateDoc(firebase.getTrialStudent(studentID), {
          lessons: arrayUnion(lessonDoc.id),
        });
        onLessonCreateSuccess(studentID, lessonDoc.id, lessonDocument);
        id = lessonDoc.id;
        console.log({ lessonId: lessonDoc.id });
        const newActivityObj = {
          start_time: Timestamp.fromDate(moment(lessonFullDate).toDate()),
          end_time: Timestamp.fromDate(
            moment(lessonFullDate).add(lesson.lessonLength, "minutes").toDate()
          ),
          userId: lesson?.teacherId,
          type: eventsMap.trialLesson.code,
          location: selectedLocationId,
          createdAt: Timestamp.now(),
          scheduleType: scheduleTypes.teacherDays.code,
          userRole: UserRole.TEACHER,
          isActive: true,
        };
        await setDoc(firebase.userActivity(lessonDoc.id), newActivityObj);
      }
      if (
        Object.keys(breakDurations).includes(
          JSON.stringify(lesson?.lessonLength)
        )
      ) {
        const breakDuration =
          breakDurations[lesson?.lessonLength].breakDuration;
        const reason = `Break for ${lesson?.lessonLength} minutes Trial lesson`;

        const breakObj = {
          createdAt: new Date(),
          date: moment(lessonFullDate)
            .add(lesson?.lessonLength, "minutes")
            .toDate(),
          duration: breakDuration,
          type: eventsMap.oneTimeBreak.code,
          reason,
          userRole: UserRole.TEACHER,
          userId: lesson?.teacherId,
        };
        await createTeacherBreakAndActivity(breakObj, user?.uid);
      }

      toast.success(
        `Lesson ${mode === "edit" ? "Updated" : "Created"} Successfully`
      );
      setLesson({});
      setIsSubmitting(false);
      onClose();
      window.location.reload();
    } catch (error) {
      setIsSubmitting(false);
      toast.error("please fill all the current fields");
      console.log("error", error);
    }
  };
  const createTeacherBreakAndActivity = async (breakObj, userId) => {
    try {
      const { date, duration, type, userRole } = breakObj;

      const res = await addDoc(firebase.breaks(), breakObj);

      if (!res?.id) {
        throw { message: "Couldnt create the break user activity" };
      }
      const activityObj = {
        createdAt: new Date(),
        day: moment(date).format("dddd"),
        start_time: date,
        end_time: moment(date).add(parseInt(duration), "minutes").toDate(),
        isActive: true,
        location: "",
        scheduleType: scheduleTypes.other.code,
        type: parseInt(type),
        userRole,
        userId: breakObj.userId,
        isVirtual: false,
        requestedBy: userId,
      };

      await setDoc(firebase.userActivity(res.id), activityObj);

      return res;
    } catch (err) {
      console.log(err);
    }
  };
  const HandelTeacherTimeChunck = () => {
    const timeArray = [];
    TeacherTimeChunck.forEach((TimeChunck) => {
      TimeChunck.forEach((eachTime) => {
        const firstval = Object.values(eachTime)[0];
        const lastval = Object.values(eachTime)[1];
        let mainArr = [`${firstval} : ${lastval}`];
        timeArray.push(mainArr);
      });
    });
    setTeacherTimeChunckValues(timeArray);
  };

  // for the confirmation modal (when creating new lsn)
  const handleApproveOption = async () => {
    await saveLesson();
    confirmationModalData.closeModal();
  };
  const handleCancelOption = () => {
    confirmationModalData.closeModal();
  };
  const handleSaveLessonClick = async () => {
    if (mode === "edit") {
      await saveLesson();
    } else {
      confirmationModalData.openModal();
    }
  };

  function isAvailableDateOption(date) {
    const isOnSameWeekDayAsAvailableDay = availableDays.some(
      (availableDay) =>
        moment(date).weekday() === moment(availableDay.startDate).weekday() ||
        moment(date).weekday() === moment(availableDay.endDate).weekday()
    );
    const isOnSameDayAsMakeupOpening = makeupOpenings.some(
      (makeupOpening) =>
        moment(makeupOpening.startDate).isSame(date, "date") ||
        moment(makeupOpening.endDate).isSame(date, "date")
    );
    return isOnSameWeekDayAsAvailableDay || isOnSameDayAsMakeupOpening;
  }

  return (
    <CustomModal centered isOpen={isOpen} className="lessonModal" size={"lg"}>
      <CustomModalHeader
        close={
          <CloseIcon
            onClick={() => {
              onClose(false);
              var x = document.getElementsByTagName("BODY")[0];
              x.classList.remove("overflowBody");
            }}
          />
        }
      >
        <InfoText>Trial Lesson</InfoText>
      </CustomModalHeader>
      <CustomModalBody className={"p-0 lessonModalbody"}>
        <div className="lessonContainer">
          <div className="userAddlessonContainer">
            <div className="">
              <div className="InfoConatiner w-100">
                <div>
                  <div className="d-flex justify-content-center mb-4">
                    <div>
                      <p>
                        Location<RequiredInputSpan>*</RequiredInputSpan>
                      </p>
                      <CustomSelectField
                        width="300px"
                        value={selectedLocationId}
                        onChange={(e) => setSelectedLocationId(e.target.value)}
                      >
                        <option value="" disabled>
                          Select A Location
                        </option>
                        {locations.map((location) => (
                          <option key={location.id} value={location.id}>
                            {location.name}
                          </option>
                        ))}
                      </CustomSelectField>
                    </div>
                  </div>
                  <div className="InputGroup mb-2">
                    <div className="col pe-2">
                      <CustomDropDown
                        label={
                          lesson.instrument
                            ? instruments[lesson.instrument].name
                            : "Instrument"
                        }
                        header={
                          <p>
                            Instrument
                            <RequiredInputSpan>*</RequiredInputSpan>
                          </p>
                        }
                        options={Object.values(instruments)}
                        keyName={"name"}
                        onOptionClick={(value) => {
                          updateLessonState("instrument", value.id);
                        }}
                        size={"auto"}
                      />
                    </div>
                    <div className="col ps-2">
                      <CustomDropDown
                        label={"Teacher"}
                        className="w-100"
                        header={
                          <p>
                            Teacher
                            <RequiredInputSpan>*</RequiredInputSpan>
                          </p>
                        }
                        options={Object.values(selectableTeachers)}
                        keyName={"fullName"}
                        value={
                          selectableTeachers?.[lesson?.teacherId]?.fullName ||
                          ""
                        }
                        onOptionClick={(value) => {
                          updateLessonState("teacherId", value.id);
                        }}
                        size={"auto"}
                      />
                    </div>
                  </div>
                  <div className="InputGroup mb-2">
                    <div className="col px-2">
                      <div className="inputContainer w-100">
                        <CustomLabel className="mb-3">
                          Trial Date
                          <RequiredInputSpan>*</RequiredInputSpan>
                        </CustomLabel>
                        <CalendarPickerWrapper padding="5px" bgColor="#f7f4f6">
                          <SingleDatePicker
                            id="date-picker"
                            date={
                              lesson?.trialDate
                                ? moment(lesson?.trialDate)
                                : null
                            } // momentPropTypes.momentObj or null
                            onDateChange={(date) => {
                              const isoDate = moment(date).format("YYYY-MM-DD");
                              updateLessonState("trialDate", isoDate);
                            }}
                            focused={isDatePickerOpen} // PropTypes.bool
                            onFocusChange={({ focused }) => {
                              setIsDatePickerOpen(focused);
                            }}
                            // numberOfMonths={1}
                            // showClearDate={true}
                            isOutsideRange={(date) => {
                              if (!isAvailableDateOption(date)) {
                                return true;
                              }

                              // exclude school breaks
                              // if (
                              //   combinedSchoolBreaks.some((schoolBreak) => {
                              //     return moment(date).isBetween(
                              //       schoolBreak.from.toDate(),
                              //       schoolBreak.to.toDate(),
                              //       "minutes",
                              //       "()"
                              //     );
                              //   })
                              // ) {
                              //   return true;
                              // }

                              return false;
                            }}
                          />
                        </CalendarPickerWrapper>
                        {/* <input
                          className="InputField w-100 m-0"
                          name={"Trial Date"}
                          id={"TrialLessonDate"}
                          type="date"
                          placeholder="Trial Lesson Date"
                          style={{ padding: 8 }}
                          max={updatedMomentNow()
                            .add(4, "years")
                            .format("YYYY-MM-DD")}
                          min={updatedMomentNow()
                            .isoWeekday(
                              lesson.day || lesson.day === 0
                                ? parseInt(lesson.day)
                                : 1
                            )
                            .format("YYYY-MM-DD")}
                          step="7"
                          disabled={lesson?.day === undefined}
                          value={lesson?.trialDate || ""}
                          onChange={(e) => {
                            updateLessonState("trialDate", e.target.value);
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="inputContainer w-100"
                        // onClick={() => {
                        //   HandelChooseDay();
                        // }}
                      >
                        <CustomDropDown
                          label={
                            LessonsLengthEnum?.[lesson?.lessonLength] ||
                            "Lesson Length"
                          }
                          header={
                            <p>
                              Lesson Length
                              <RequiredInputSpan>*</RequiredInputSpan>
                            </p>
                          }
                          options={
                            lesson?.trialDate === "" || !lesson?.trialDate
                              ? []
                              : Object.values(LessonsLengthEnum)
                          }
                          onOptionClick={(value) => {
                            updateLessonState("lessonLength", parseInt(value));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="UsableSinglePart m-0 mb-2 w-100">
                    <div className="d-flex p-3 mb-3">
                      <p className="form-label  me-5">Teacher Available Time</p>
                      <div>
                        <label className="me-3">
                          <input
                            type="radio"
                            className="me-2"
                            name="isVirtual"
                            checked={!lesson?.isVirtual}
                            // value={false}
                            onChange={() => {
                              updateLessonState("isVirtual", false);
                            }}
                          />
                          In Person
                        </label>
                        <label>
                          <input
                            type="radio"
                            className="me-2"
                            name="isVirtual"
                            checked={lesson?.isVirtual}
                            // value={true}
                            onChange={() => {
                              updateLessonState("isVirtual", true);
                            }}
                          />
                          Virtual
                        </label>
                      </div>
                    </div>

                    <>
                      <>
                        {combinedAvailabilities?.length > 0 ? (
                          <div className="d-block">
                            <p className="mb-2">
                              {teachers?.[lesson.teacherId]?.fullName ||
                                "" ||
                                "N/A"}{" "}
                              is available
                            </p>
                            {combinedAvailabilities.map((AvTime, index) => {
                              return (
                                <p key={index}>
                                  {moment(lesson.trialDate).format(
                                    "MM/DD/YYYY"
                                  )}{" "}
                                  ,{" "}
                                  {moment(AvTime.start_time).format("hh:mm A")}{" "}
                                  - {moment(AvTime.end_time).format("hh:mm A")}{" "}
                                  ,{" "}
                                  {
                                    locations?.find(
                                      ({ id }) => id === selectedLocationId
                                    )?.name
                                  }
                                </p>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            {lesson.lessonLength && lesson.trialDate ? (
                              <p className="mb-2">
                                This Teacher Don't have available time based on
                                this lesson length
                              </p>
                            ) : (
                              "-"
                            )}
                          </div>
                        )}
                      </>
                    </>
                  </div>
                  <div className="InputGroup mb-2">
                    <div className="col">
                      <TimeDropDown
                        header={
                          <p>
                            Lesson Time
                            <RequiredInputSpan>*</RequiredInputSpan>
                          </p>
                        }
                        label={lessonTime === "" ? "Lesson Time" : lessonTime}
                        options={Object.values(TeacherTimeChunckValues)}
                        onOptionClick={(value) => {
                          HandelLessonTime(value);
                          setLessonTime(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="InputGroup mb-2">
                    <div className="col">
                      <div className="inputContainer w-100">
                        <label className="noteLabel" htmlFor="note">
                          note
                          <RequiredInputSpan>*</RequiredInputSpan>
                        </label>
                        <textarea
                          placeholder="Previous musical experience, parent's name"
                          className="textArea m-0"
                          id={"note"}
                          name="note"
                          value={lesson.note}
                          onChange={(e) =>
                            updateLessonState("note", e.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="InputGroup mb-2">
                    <div className="col">
                      <div className="inputContainer w-100">
                        <label htmlFor="fee" className="mx-0">
                          Fee
                        </label>
                        <input
                          className="InputField mx-0 w-100"
                          name={"fee"}
                          id="fee"
                          value={lesson.fee}
                          placeholder="Fee"
                          onChange={(e) => {
                            updateLessonState("fee", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col flex-grow-0">
                      <div className="inputContainer d-flex flex-column h-100 align-items-center justify-content-between">
                        <label htmlFor="isPaid">Paid</label>
                        <input
                          className="checkbox"
                          type="checkbox"
                          id="isPaid"
                          name="isPaid"
                          checked={lesson.isPaid}
                          onChange={(e) => {
                            updateLessonState("isPaid", !lesson.isPaid);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="inputContainer flex-1">
                        <label htmlFor="paymentDate" className="mx-0">
                          Date of Payment
                        </label>
                        <input
                          className="InputField mx-0 w-100"
                          name={"paymentDate"}
                          id={"paymentDate"}
                          type="date"
                          value={lesson.dateOfPayment}
                          max={updatedMomentNow()
                            .subtract(0, "years")
                            .format("YYYY-MM-DD")}
                          min={updatedMomentNow()
                            .subtract(90, "years")
                            .format("YYYY-MM-DD")}
                          placeholder="Date of Payment"
                          onChange={(e) => {
                            updateLessonState("dateOfPayment", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ExtraInfo">
              <div>
                <div className="text-center">
                  <button
                    onClick={handleSaveLessonClick}
                    className="w-50 mt-3 mb-3 submitbutton"
                    type="submit"
                    style={{ margin: "auto" }}
                  >
                    {isSubmitting ? (
                      <SubmitSpinner style={{}} size="sm" />
                    ) : (
                      <div>
                        {mode === "edit"
                          ? "Update"
                          : "Create Lesson And Send Confirmation Email"}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ConfirmationModal
            modalData={confirmationModalData}
            onApprove={handleApproveOption}
            onCancel={handleCancelOption}
            isSubmitting={isSubmitting}
            title="Confirm Lesson ?"
            approveBtnText="Create Lesson"
            cancelBtnText="Cancel"
          />
        </div>
      </CustomModalBody>
    </CustomModal>
  );
};
export default compose(inject("UserStore"), observer)(CreateStudentTrialLesson);
