import Select from "react-select";

const CustomReactSelect = ({
  placeholder,
  wrapOptions = true,
  bgColor = "#681E460D",
  textColor,
  width = "320px",
  margin = "0px",
  isMulti = true,
  disabledBackgroundColor,
  ...props
}) => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: "#f4f4f4e6",
      color: "#151515",
    }),

    control: (provided) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: bgColor,
      backgroundColor: props.isDisabled
        ? disabledBackgroundColor || bgColor
        : bgColor,
      color: "#505050",
      borderRadius: 30,
      border: "2px solid #681E46 !important",
      outline: "none",
      width,
      margin,
      whiteSpace: "nowrap",
    }),

    singleValue: (provided, state) => {
      return { ...provided, backgroundColor: "#e5e5e5" };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        border: "1px solid #777",
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      ...(!wrapOptions && { flexWrap: "nowrap" }),
    }),
  };
  return (
    <Select
      isMulti={isMulti}
      styles={customStyles}
      className="basic-multi-select"
      placeholder={placeholder || "Select..."}
      {...props}
    />
  );
};

export default CustomReactSelect;
