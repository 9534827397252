import React from "react";
import { PrimaryButton } from "src/utils/shared/styled";
import { SectionContainer } from "../../styled";
import useModal from "src/hooks/useModal";
import ConfirmationModal from "../../../ConfirmationModal";

const NotPaidComponent = ({ loadingAction, markAsPaidExternally }) => {
  const confirmationModalData = useModal();

  function onApprove() {
    markAsPaidExternally();
  }
  function onCancel() {
    confirmationModalData.closeModal();
  }
  return (
    <>
      <SectionContainer>
        <h5>This student hasnt paid yet</h5>
        <hr />

        <SectionContainer className="mt-3">
          <PrimaryButton
            onClick={() => confirmationModalData.openModal()}
            width="100%"
          >
            Mark As Paid Using External Method
          </PrimaryButton>
        </SectionContainer>
      </SectionContainer>

      {/* MODALS */}
      {confirmationModalData.isModalOpen && (
        <ConfirmationModal
          modalData={confirmationModalData}
          onApprove={onApprove}
          onCancel={onCancel}
          isSubmitting={loadingAction}
          title="Mark As Paid ?"
          approveBtnText="Yes"
          cancelBtnText="Cancel"
        />
      )}
    </>
  );
};

export default NotPaidComponent;
