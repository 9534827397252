import React from "react";
import { PaymentGroupContainer } from "../styled";
import { CustomInputWithCurrency } from "src/utils/shared/shared";
import { CustomLabel } from "src/utils/shared/styled";

const RateOptionItem = ({ rate, option, onPaymentRateOptionChange }) => {
  return (
    <PaymentGroupContainer className="mb-3" key={option.name}>
      <div>
        <CustomLabel>{option.title}</CustomLabel>
      </div>
      <div>
        <CustomInputWithCurrency
          onWheel={(e) => e.target.blur()} // prevents scroll behaviour
          type="number"
          disabled={!rate.isEnabled}
          disabledBgColor={"#f2f2f2"}
          value={option.value}
          onChange={(e) =>
            onPaymentRateOptionChange(
              rate.name,
              option.name,
              "value",
              e.target.value
            )
          }
          placeholder="0"
        />
      </div>
    </PaymentGroupContainer>
  );
};

export default RateOptionItem;
