import React from "react";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalHeader,
} from "src/components/common/styled-components/teacherLibraryStyledComponents";
import ChooseFromLibraryBody from "./ChooseFromLibraryBody";

const ChooseFromLibraryModal = ({
  modalData,
  addChosenLibraryItems,
  libraryChosenItems,
}) => {
  return (
    <LibraryModal
      className={"p-0"}
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
      size={"lg"}
    >
      <LibraryModalHeader
        close={
          <button className="close" onClick={modalData.closeModal}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Library
      </LibraryModalHeader>
      <LibraryModalBody>
        <ChooseFromLibraryBody
          modalData={modalData}
          addChosenLibraryItems={addChosenLibraryItems}
          libraryChosenItems={libraryChosenItems}
        />
      </LibraryModalBody>
    </LibraryModal>
  );
};

export default ChooseFromLibraryModal;
