import styled from "styled-components";
import { Delete } from "styled-icons/fluentui-system-filled";

export const InfoSpan = styled.span`
  font-size: 0.8rem;
  color: #989898;
`;

export const StyledP = styled.p`
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 13px;
  border: 1px solid #d5d5d5;
  font-size: 1.1rem;
  margin: 0;
`;
export const DeleteIcon = styled(Delete)`
  height: 25px;
  width: 25px;
  cursor: pointer;
`;
