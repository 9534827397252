import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { useApplications } from "./hooks";
import { setTableRowClassName } from "./utils";
import { formatTimestamp } from "src/utils/helpers";

import { StyledTable } from "./styled";
import RouteContent from "src/components/common/RouteContent";
import { PrimaryButton } from "src/utils/shared/styled";
import DeleteModal from "./components/DeleteModal";
import { ButtonsWrapper } from "../Guest/GuestProfile/GuestProfileSecondPage/styled";
import ApplicantDetailsModal from "./components/ApplicantDetailsModal";
import applicationsTableColumns from "./applicationsTableColumns";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [applicantToAction, setApplicantToAction] = useState(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { getApplications } = useFirebaseFns();
  const { archiveApplicationToggle } = useApplications();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getApplications(false);

      setApplications(response);
      setIsLoading(false);
    })();
  }, []);

  const handleDetailsModalOpen = (applicantId) => {
    setApplicantToAction(applicantId);
    setDetailsModalOpen(true);
  };

  const handleDeleteModalOpen = (applicantId) => {
    setApplicantToAction(applicantId);
    setDeleteModalOpen(true);
  };

  const handleApplicationDelete = (applicantId) => {
    setApplications((prevState) =>
      prevState?.filter((application) => application?.id !== applicantId)
    );
    setDeleteModalOpen(false);
  };

  const handleApplicationArchive = async (applicantId) => {
    setIsLoading(true);
    await archiveApplicationToggle(applicantId, true);
    setApplications((prevState) =>
      prevState?.filter((application) => application?.id !== applicantId)
    );
    setIsLoading(false);
  };

  const handleApplicationStatusUpdate = (applicantId, status) => {
    setApplications((prevState) =>
      prevState?.map((application) =>
        application?.id === applicantId
          ? { ...application, status }
          : application
      )
    );
  };

  const columns = applicationsTableColumns({
    onNameClick: handleDetailsModalOpen,
    onDeleteClick: handleDeleteModalOpen,
    onArchiveClick: handleApplicationArchive,
  });

  return (
    <RouteContent title="Applications">
      <StyledTable
        loading={isLoading}
        columns={columns}
        dataSource={applications
          ?.map((application) => {
            const date = formatTimestamp(application?.createdAt);

            return {
              request_date: date,
              name: application?.name || "N/A",
              type: application?.role?.replace(/_/g, " "),
              instrument: application?.instruments?.join(", ") || "N/A",
              key: application?.id,
              withdrawal: application?.withdrawal?.requested,
              status: application?.status,
            };
          })
          .filter(Boolean)}
        rowClassName={(record) => setTableRowClassName(record?.status)}
        bordered
      />
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/applications/archive")}
        >
          Applications archive
        </PrimaryButton>
      </ButtonsWrapper>
      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen((prevState) => !prevState)}
        applicantId={applicantToAction}
        onConfirm={() => handleApplicationDelete(applicantToAction)}
      />
      <ApplicantDetailsModal
        isOpen={detailsModalOpen}
        toggle={() => setDetailsModalOpen((prevState) => !prevState)}
        applicantId={applicantToAction}
        onArchive={() => handleApplicationArchive(applicantToAction)}
        onStatusUpdate={(status) =>
          handleApplicationStatusUpdate(applicantToAction, status)
        }
      />
    </RouteContent>
  );
};

export default Applications;
