import React, { useMemo, useState } from "react";

const useAttachments = ({ attachments }) => {
  const [currentAttachmentId, setCurrentAttachmentId] = useState(
    attachments?.[0]?.id || ""
  );

  const currentAttachment = useMemo(() => {
    if (!currentAttachmentId) return;

    const curr = attachments?.find(({ id }) => id === currentAttachmentId);
    return curr;
  }, [currentAttachmentId, attachments]);

  const currentAttachmentIndex = useMemo(() => {
    if (!attachments?.length || !currentAttachmentId) return -1;

    const i = attachments.findIndex(({ id }) => id === currentAttachmentId);

    return i;
  }, [currentAttachmentId, attachments]);

  const hasNextAttachment = useMemo(() => {
    if (!attachments?.length || !currentAttachmentId) return false;

    // not found
    if (currentAttachmentIndex === -1) {
      return false;
    }

    const hasNext = !!attachments[currentAttachmentIndex + 1];
    return hasNext;
  }, [attachments, currentAttachmentId, currentAttachmentIndex]);

  const hasPrevAttachment = useMemo(() => {
    if (!attachments?.length || !currentAttachmentId) return false;

    // not found
    if (currentAttachmentIndex === -1 || currentAttachmentIndex === 0) {
      return false;
    }

    const hasPrev = !!attachments[currentAttachmentIndex - 1];
    return hasPrev;
  }, [attachments, currentAttachmentId, currentAttachmentIndex]);

  function handleNextAttachment() {
    // not found
    if (currentAttachmentIndex === -1) {
      return;
    }

    const newAttachment = attachments[currentAttachmentIndex + 1];
    setCurrentAttachmentId(newAttachment?.id);
  }
  function handlePrevAttachment() {
    // not found
    if (currentAttachmentIndex === -1 || currentAttachmentIndex === 0) {
      return;
    }

    const newAttachment = attachments[currentAttachmentIndex - 1];
    setCurrentAttachmentId(newAttachment?.id);
  }

  return {
    currentAttachment,
    setCurrentAttachmentId,
    hasNextAttachment,
    hasPrevAttachment,
    currentAttachmentIndex,
    handleNextAttachment,
    handlePrevAttachment,
  };
};

export default useAttachments;
