import { Sun } from "@styled-icons/bootstrap/Sun";
import { Clouds } from "@styled-icons/bootstrap/Clouds";
import { CloudSnow } from "@styled-icons/bootstrap/CloudSnow";
import { CloudRainHeavy } from "@styled-icons/bootstrap/CloudRainHeavy";

export const WeatherIconMap = {
  Rain: <CloudRainHeavy width={40} height={40} />,
  Snow: <CloudSnow width={40} height={40} />,
  Clouds: <Clouds width={40} height={40} />,
  Clear: <Sun width={40} height={40} />,
};
