import React, { useContext, useEffect, useState } from "react";
import { getDocs, query, documentId, where } from "firebase/firestore";
import { FirebaseContext } from "../../../Firebase";
import { parseFirebaseDoc } from "../../../../utils/getFirebaseDoc";
import PenIcon from "./PenIcon";

import "./style.css";
import styled from "styled-components";
import {
  isGuest,
  isParent,
  isPartnerApplicant,
  isStudent,
  isTeacher,
} from "src/constants/UserRoleEnum";
import { profilePageViews } from "../constants";
import moment from "moment";
import store from "src/stores/UserStore";
import { ErrorMessage } from "src/utils/shared/styled";

const CustomH4 = styled.h4`
  min-height: 25px;
`;

function ProfileDetails({
  user,
  authUser,
  userRole,
  changeModalData,
  currentView,
}) {
  const {
    birthday,
    primaryEmail: email,
    selectedInstruments,
    primaryPhone,
    homeAddress,
    city,
    state,
    zip,
    primaryContactName,
    primaryEmail,
    secondContactName,
    secondEmail,
    secondPhone,
    primaryContactEmail,
    secondaryContactEmail,
    instrumentsInfo,
    lessonId,
    name,
  } = user;

  const isStudentUser = isStudent(user.role);
  const isParentUser = isParent(user.role);
  const isTeacherUser = isTeacher(user.role);

  const isNotAbleToChangePrimaryEmail =
    isParent(authUser.role) && currentView === profilePageViews.STUDENT;

  const [instrumentsNames, setInstrumentsNames] = useState(false);

  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    const data = async (instruments) => {
      const InstrumentsQuery = query(
        firebase.instruments(),
        where(documentId(), "in", instruments)
      );
      let Names = await getDocs(InstrumentsQuery);
      Names = parseFirebaseDoc(Names.docs);
      Names = Names.map((el) => el?.name);

      setInstrumentsNames(Names);
    };

    if (isTeacher(userRole) && instrumentsInfo) {
      let instruments = instrumentsInfo?.map((el) => el?.instrument);
      instruments = [...new Set(instruments)];
      data(instruments);
    }
  }, [selectedInstruments, firebase]);

  const formattedBirthday = moment(birthday, "YYYY-MM-DD").format("MM.DD.YYYY");

  return (
    <div className="profile-details">
      {isGuest(user.role) &&
        (!user.name || !user.primaryPhone || !user.birthday) && (
          <span className="instruction">
            Please fill all the required fields to further the application
            process
          </span>
        )}
      <div className="profile-details-grid">
        {birthday && !isGuest(user.role) && (
          <div className="profile-detail-element">
            <h5>Birthday</h5>
            <CustomH4>
              {formattedBirthday}
              <PenIcon
                inputType="date"
                changeModalData={changeModalData}
                fieldName="birthday"
                headerText="Birthday"
                bodyText={<p>Birthday</p>}
              />
            </CustomH4>
          </div>
        )}

        {isGuest(user.role) && (
          <>
            <div className="profile-detail-element">
              <h5>Full name</h5>
              <CustomH4>
                {store.user.name ? (
                  store.user.name
                ) : (
                  <ErrorMessage>Required</ErrorMessage>
                )}
                <PenIcon
                  inputType="text"
                  changeModalData={changeModalData}
                  fieldName="name"
                  headerText="Name"
                />
              </CustomH4>
            </div>
            <div className="profile-detail-element">
              <h5>Email</h5>
              <CustomH4>
                {store.user.email}
                <PenIcon
                  inputType="email"
                  changeModalData={changeModalData}
                  fieldName="email"
                  headerText="Email"
                />
              </CustomH4>
            </div>
            {!isPartnerApplicant(user.role) && (
              <div className="profile-detail-element">
                <h5>Birthday</h5>
                <CustomH4>
                  {store.user.birthday ? (
                    formattedBirthday
                  ) : (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <PenIcon
                    inputType="date"
                    changeModalData={changeModalData}
                    fieldName="birthday"
                    headerText="Birthday"
                    bodyText={<p>You will be changing your birthday</p>}
                  />
                </CustomH4>
              </div>
            )}
          </>
        )}
        {email && (
          <div className="profile-detail-element">
            <h5>Login Email</h5>
            <CustomH4>
              {email + "  "}{" "}
              {!isNotAbleToChangePrimaryEmail && (
                <PenIcon
                  inputType="email"
                  changeModalData={changeModalData}
                  fieldName="email"
                  headerText="Email"
                  bodyText={
                    <p>
                      This new email address will be your new login <br />
                      username
                    </p>
                  }
                />
              )}
            </CustomH4>
          </div>
        )}

        {instrumentsNames && (
          <div className="profile-detail-element">
            <h5>Instrument</h5>
            <CustomH4>{instrumentsNames.join(", ")}</CustomH4>
          </div>
        )}
        {!isParentUser && (
          <div className="profile-detail-element">
            <h5>Primary Phone</h5>
            <CustomH4>
              {primaryPhone ? (
                primaryPhone
              ) : (
                <ErrorMessage>Required</ErrorMessage>
              )}
              <PenIcon
                inputType="tel"
                changeModalData={changeModalData}
                fieldName="primaryPhone"
                headerText="Primary Phone"
              />
            </CustomH4>
          </div>
        )}
        {isStudentUser && secondPhone && (
          <div className="profile-detail-element">
            <h5>2nd Phone</h5>
            <CustomH4>
              {secondPhone}{" "}
              <PenIcon
                inputType="tel"
                changeModalData={changeModalData}
                fieldName="secondPhone"
                headerText="2nd Phone"
              />
            </CustomH4>
          </div>
        )}

        {!isParentUser && (
          <div className="profile-detail-element home-address">
            <h5>Home Address (optional)</h5>
            <CustomH4>
              {homeAddress}
              <PenIcon
                inputType="text"
                changeModalData={changeModalData}
                fieldName="homeAddress"
                headerText="Home Address"
              />
            </CustomH4>
          </div>
        )}

        {(isStudentUser || isTeacherUser) && (
          <>
            <div className="profile-detail-element ">
              <h5>City</h5>
              <CustomH4>
                {city}
                <PenIcon
                  inputType="text"
                  changeModalData={changeModalData}
                  fieldName="city"
                  headerText="City"
                />
              </CustomH4>
            </div>
            <div className="profile-detail-element ">
              <h5>State</h5>
              <CustomH4>
                {state}
                <PenIcon
                  inputType="text"
                  changeModalData={changeModalData}
                  fieldName="state"
                  headerText="State"
                />
              </CustomH4>
            </div>
            <div className="profile-detail-element home-address">
              <h5>Zip</h5>
              <CustomH4>
                {zip}
                <PenIcon
                  inputType="text"
                  changeModalData={changeModalData}
                  fieldName="zip"
                  headerText="Zip Code"
                />
              </CustomH4>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileDetails;
