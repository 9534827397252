import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import {
  parseAbsenceObjDates,
  parseMakeupLessonDates,
  parsePrivateLessonDates,
  parseTrialLessonDates,
} from "src/utils/firebaseDatesParserFns";
import {
  getCombinedPackageSetItems,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getNextSchoolYear,
} from "src/utils/helpers";
import {
  addInstrumentsToTeachers,
  attachAvailableDaysToTeachers,
  filterInitialDataByLocation,
} from "../helperFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import useFirebaseFns from "./useFirebaseFns";

const useScheduleData = (user) => {
  const {
    getStudentPLs,
    getStudentTLs,
    getInstruments,
    getPrograms,
    getStudios,
    getLocations,
    getTeachersByIds,
    getTeachersAvailableDays,
    getStudentMakeupLessons,
    getSchoolYears,
    getAbsencesByTeacherIds,
    getTeachersMakeUpOpenings,
  } = useFirebaseFns();

  const { getGroupClassEnrollmentsByStudentsIds, getGroupClassesByIds } =
    useGlobalFirebaseFns();

  // contains all locations for all events for that student
  const [allEventsLocationsIds, setAllEventsLocationsIds] = useState([]);
  // the selected location for displaying events on calendar
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const handleSelectedLocationIdChange = (locationId) => {
    if (locationId) {
      setSelectedLocationId(locationId);
    }
  };
  // initial page load state
  const [initialData, setInitialData] = useState({
    instruments: {},
    programs: {},
    locations: {},
    studios: {},
    studentPLs: [],
    studentTLs: [],
    studentMakeupLessons: [],
    studentGroupClasses: [],
    schoolYears: [],
    absences: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  // selectedEvent is when you click on an event on the calendar
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const fetchInitialPageLoadData = async () => {
    try {
      if (!user) return;
      setLoadingInitialData(true);
      // fetching all data we need on the page load
      const firebaseRequests = [
        getStudentPLs(user.uid),
        getStudentTLs(user.uid),
        getInstruments(),
        getPrograms(),
        getLocations(),
        getStudios(),
        getStudentMakeupLessons(user.uid),
        getSchoolYears(),
        getGroupClassEnrollmentsByStudentsIds([user.uid]),
      ];
      const [
        studentPLs,
        studentTLs,
        instruments,
        programs,
        locations,
        studios,
        studentMakeupLessons,
        schoolYears,
        groupClassesEnrollments,
      ] = await Promise.all(firebaseRequests);

      // group classes
      const groupClassesIds = [
        ...new Set(
          groupClassesEnrollments.map(({ groupClassId }) => groupClassId)
        ),
      ];

      const groupClasses = await getGroupClassesByIds(groupClassesIds);

      // GETTING ALL UNIQUE LOCATIONS FOR THE EVENTS
      const studentPLsLocationsChunks = Object.values(studentPLs).map(
        ({ timeline, type, packageSets }) =>
          isPackagePrivateLesson(type)
            ? getCombinedPackageSetItems(packageSets)?.map(
                ({ locationId }) => locationId
              )
            : timeline?.map(({ locationId }) => locationId)
      );
      const studentPLsLocations = studentPLsLocationsChunks.flat();
      const studentTLsLocations = Object.values(studentTLs)
        .map(({ locations }) => locations)
        .flat();
      const studentMakeupsLocations = Object.values(studentMakeupLessons).map(
        ({ locationId }) => locationId
      );
      const uniqueLocationsIds = [
        ...new Set([
          ...studentPLsLocations,
          ...studentTLsLocations,
          ...studentMakeupsLocations,
        ]),
      ];

      // LESSONS SECTION
      const privateLessonsTeachersIdsChunks = Object.values(studentPLs).map(
        ({ teachersIds }) => teachersIds
      );
      const privateLessonsTeachersIds = privateLessonsTeachersIdsChunks.flat();
      const groupClassesTeachersIds = groupClasses
        .map(({ teachersIds }) => teachersIds)
        .flat();

      const trialLessonsTeachersIds = Object.values(studentTLs).map(
        ({ teacherId }) => teacherId
      );
      const makeupLessonsTeachersIds = Object.values(studentMakeupLessons).map(
        ({ teacherId }) => teacherId
      );
      // Getting teacher Ids of all trial or private lessons
      const uniqueCombinedTeachersIds = [
        ...new Set([
          ...privateLessonsTeachersIds,
          ...trialLessonsTeachersIds,
          ...makeupLessonsTeachersIds,
          ...groupClassesTeachersIds,
        ]),
      ];

      const [teachers, teachersAvailableDays, teachersMakeUpOpenings] =
        await Promise.all([
          getTeachersByIds(uniqueCombinedTeachersIds),
          getTeachersAvailableDays(uniqueCombinedTeachersIds),
          getTeachersMakeUpOpenings(uniqueCombinedTeachersIds),
        ]);
      const teachersWithAvailableDays = attachAvailableDaysToTeachers(
        teachers,
        teachersAvailableDays,
        locations,
        teachersMakeUpOpenings
      );
      const teachersWithInstruments = addInstrumentsToTeachers(
        teachersWithAvailableDays,
        instruments,
        programs
      );
      const groupClassesWithAllData = groupClasses.map((groupClass) => {
        const { classes } = groupClass;
        const updatedClasses = classes.map((classObj) => {
          const classTeachers = classObj.teachersIds.map((teacherId) => {
            return teachersWithInstruments[teacherId];
          });
          return { ...classObj, teachers: classTeachers };
        });

        const lessonStudent = user;

        return {
          ...groupClass,
          student: lessonStudent,
          classes: updatedClasses,
        };
      });
      const privateLessonsWithAllData = Object.values(studentPLs).map(
        (lesson) => {
          const lessonWithDates = parsePrivateLessonDates(lesson);
          const { timeline, packageSets } = lessonWithDates || {};

          const lessonStudent = user;

          const timelineWithTeachers = timeline?.map((timelineObj) => {
            const teacher = teachersWithInstruments[timelineObj.teacherId];

            return {
              ...timelineObj,
              teacher,
            };
          });

          const packageSetsWithTeachers = packageSets?.map((setObj) => {
            const { setItems } = setObj;
            const updatedSetItems = setItems?.map((setItem) => {
              const teacher = teachersWithInstruments[setItem.teacherId];
              return {
                ...setItem,
                teacher,
              };
            });
            return {
              ...setObj,
              setItems: updatedSetItems,
            };
          });

          return {
            ...lessonWithDates,
            student: lessonStudent,
            ...(timeline && { timeline: timelineWithTeachers }),
            ...(packageSets && { packageSets: packageSetsWithTeachers }),
          };
        }
      );
      const trialLessonsWithAllData = Object.values(studentTLs).map(
        (lesson) => {
          const {
            teacherId,
            studentId,
            instrument,
            locations: lessonLocationsIds,
          } = lesson || {};
          const lessonWithDates = parseTrialLessonDates(lesson);

          const lessonStudent = user;
          // const lessonProgram = programs[programId];
          const lessonInstrument = instruments[instrument];
          const lessonLocations = lessonLocationsIds?.map(
            (locationId) => locations[locationId]
          );
          const lessonTeacher = teachersWithInstruments[teacherId];
          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // locations: lessonLocations,
          };
        }
      );

      const makeupLessonsWithAllData = Object.values(studentMakeupLessons).map(
        (lesson) => {
          const {
            forLessonId,
            forLessonType,
            programId,
            teacherId,
            studentId,
            instrumentId,
            locationId,
          } = lesson || {};
          const lessonWithDates = parseMakeupLessonDates(lesson);

          const lessonStudent = user;
          const lessonInstrument = instruments[instrumentId];
          const lessonProgram = programs[programId];
          const lessonLocation = locations[locationId];
          const lessonTeacher = teachersWithInstruments[teacherId];

          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // location: lessonLocation,
          };
        }
      );

      //Absences Section

      const absences = await getAbsencesByTeacherIds(privateLessonsTeachersIds);
      const absencesWithDates = absences.map((absence) => {
        return parseAbsenceObjDates(absence);
      });

      // Setting the state for lessons, instruments, programs and locations
      setInitialData((oldVal) => ({
        ...oldVal,
        instruments,
        programs,
        locations,
        studios,
        studentPLs: privateLessonsWithAllData,
        studentTLs: trialLessonsWithAllData,
        studentMakeupLessons: makeupLessonsWithAllData,
        schoolYears,
        absences: absencesWithDates,
        studentGroupClasses: groupClassesWithAllData,
      }));
      // setting the state for the locations
      setAllEventsLocationsIds(uniqueLocationsIds);
      setSelectedLocationId(uniqueLocationsIds[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingInitialData(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchInitialPageLoadData();
    };
    fetchInitialData();
  }, [refresh, user?.uid]);

  // sets the initial selectedLocationId
  useEffect(() => {
    if (allEventsLocationsIds.length && !selectedLocationId) {
      setSelectedLocationId(allEventsLocationsIds[0]);
    }
  }, [allEventsLocationsIds, user?.uid]);

  // current school year based on the selectedEvent locationId
  const currentSchoolYearInLocation = useMemo(
    () => getCurrentSchoolYear(initialData.schoolYears, selectedLocationId),
    [initialData, selectedLocationId]
  );

  // // next school year based on the selectedEvent locationId
  // const nextSchoolYearInLocation = useMemo(
  //   () =>
  //     getNextSchoolYear(
  //       initialData.schoolYears,
  //       selectedLocationId,
  //       currentSchoolYearInLocation
  //     ),
  //   [initialData, selectedLocationId, currentSchoolYearInLocation]
  // );

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears.length && !selectedLocationId) return [];

    const combinedSchoolBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      selectedLocationId,
      true
    );
    return combinedSchoolBreaks;
  }, [initialData, selectedLocationId]);

  const filteredInitialData = useMemo(
    () => filterInitialDataByLocation(initialData, selectedLocationId),
    [initialData, selectedLocationId]
  );

  return {
    initialData: filteredInitialData,
    loadingInitialData,
    selectedEvent,
    setSelectedEvent,
    refreshData,
    allEventsLocationsIds,
    selectedLocationId,
    handleSelectedLocationIdChange,
    currentSchoolYearInLocation,
    // nextSchoolYearInLocation,
    combinedSchoolBreaks,
  };
};

export default useScheduleData;
