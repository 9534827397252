import React from "react";
import { GroupClassesListContainer } from "../styled";
import Navbar from "./Navbar";
import GroupClassInfoCard from "./GroupClassInfoCard";
import useModal from "src/hooks/useModal";
import AddStudentToGroupClassModal from "../../common/components/AddStudentToGroupClassModal";
import ViewGroupClassInfoModal from "../../common/components/ViewGroupClassInfoModal";
import { groupClassesListViews } from "../constants";

const GroupClassesListSection = ({ hookData }) => {
  const addStudentModalData = useModal();
  const viewClassInfoModal = useModal();

  const {
    groupClassView,
    setGroupClassView,
    displayedGroupClasses,
    initialData,
    loadingInitialData,
    currentGroupClassObj,
    setCurrentGroupClassId,
    handleClassStatusChange,
    deleteGroupClass,
  } = hookData;
  const { active, inactive } = groupClassesListViews;

  function onAddStudentClick(groupClassId) {
    setCurrentGroupClassId(groupClassId);
    addStudentModalData.openModal();
  }
  function onOpenClassInfoClick(groupClassId) {
    setCurrentGroupClassId(groupClassId);
    viewClassInfoModal.openModal();
  }

  const colorsPallete = ["#bdb5dd", "#ddddb5", "#ddb5b5", "#ddb5db", "#f0b1ac"];

  const activeClasses = displayedGroupClasses.filter(
    (groupClass) => groupClass.status === active.value
  );
  const inactiveClasses = displayedGroupClasses.filter(
    (groupClass) => groupClass.status === inactive.value
  );

  const emptyActiveClasses =
    groupClassView === active.value &&
    !activeClasses.length &&
    !loadingInitialData;
  const emptyInactiveClasses =
    groupClassView === inactive.value &&
    !inactiveClasses.length &&
    !loadingInitialData;

  return (
    <>
      <div className="h-100">
        <div>
          <Navbar
            groupClassView={groupClassView}
            setGroupClassView={setGroupClassView}
          />
        </div>
        <GroupClassesListContainer>
          <div>
            {(emptyActiveClasses || emptyInactiveClasses) && (
              <p>No classes found.</p>
            )}
            {[groupClassView === active.value ? activeClasses : inactiveClasses]
              .flat()
              .map((groupClassObj) => {
                const { id, status } = groupClassObj;

                const newStatus =
                  status === active.value ? inactive.value : active.value;

                const currentColor = colorsPallete[0];
                colorsPallete.shift();
                colorsPallete.push(currentColor);
                return (
                  <GroupClassInfoCard
                    headerColor={currentColor}
                    key={id}
                    onAddStudentClick={() => onAddStudentClick(id)}
                    onOpenClick={() => onOpenClassInfoClick(id)}
                    groupClassObj={groupClassObj}
                    teachers={initialData.teachers}
                    onClassStatusChange={() =>
                      handleClassStatusChange(id, newStatus)
                    }
                    onGroupClassDelete={() => deleteGroupClass(id)}
                  />
                );
              })}
          </div>
        </GroupClassesListContainer>
      </div>

      {/* MODALS */}

      {addStudentModalData.isModalOpen && currentGroupClassObj?.id && (
        <AddStudentToGroupClassModal
          groupClassId={currentGroupClassObj?.id}
          modalData={addStudentModalData}
          overflowRedbarContent={true}
          redbarTitle={"Add Student"}
        />
      )}
      {viewClassInfoModal.isModalOpen && currentGroupClassObj?.id && (
        <ViewGroupClassInfoModal
          groupClassId={currentGroupClassObj?.id}
          modalData={viewClassInfoModal}
        />
      )}
    </>
  );
};

export default GroupClassesListSection;
