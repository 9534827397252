import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;
export const StyledInput = styled.input`
  &[type="checkbox"]:not(:checked),
  &[type="checkbox"]:checked {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.01;
  }
  &[type="checkbox"]:not(:checked) + label,
  &[type="checkbox"]:checked + label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-left: 1.9em;
    font-size: 1em;
    line-height: 1.7;
    cursor: pointer;
  }
  /* checkbox aspect */
  &[type="checkbox"]:not(:checked) + label:before,
  &[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.2em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.15em;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 rgba(203, 34, 237, 0.2);
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
  }
  &[type="checkbox"]:checked + label:before {
    background: ${({ teacherColor }) => teacherColor || "#464646"};
  }
  &[type="checkbox"]:not(:checked) + label:before {
    border: 2px solid ${({ teacherColor }) => teacherColor || "#464646"};
  }

  /* checked mark aspect */
  &[type="checkbox"]:not(:checked) + label:after,
  &[type="checkbox"]:checked + label:after {
    content: "✓";
    position: absolute;
    top: 0.85em;
    left: 0.3em;
    font-size: 1em;
    color: #fff;
    line-height: 0;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  /* checked mark aspect changes (For animating the checkmark) */
  &[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }

  &[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }

  // disabled styles
  &[type="checkbox"]:disabled:not(:checked) + label:before,
  &[type="checkbox"]:disabled:checked + label:before {
    background-color: #e9e9e9;
    border: none;
  }

  /* Accessibility */
  /* &[type="checkbox"]:checked:focus + label:before,
  &[type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 6px rgba(203, 34, 237, 0.2);
  } */
`;
export const StyledLabel = styled.label``;
