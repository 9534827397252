import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  /* min-width: 650px;
  max-width: 650px; */
  & .modal-content {
    border-radius: 1.3rem;
    border: none !important;
    padding: 0;
    position: relative;
    z-index: 999999;
  }
`;
export const CustomModalHeader = styled(ModalHeader)`
  border: none !important;
  padding-bottom: 25px !important;
  padding-top: 25px !important;
`;
export const CustomModalBody = styled(ModalBody)`
  padding: 0 !important;
`;
export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
  /* margin-left: -30px; */
  width: 100%;
`;
export const RedBarContainer = styled.div`
  background: #681e46;
  width: 50px;
  color: #fff;
  writing-mode: vertical-lr;
  text-orientation: revert;
  display: flex;
  align-items: center;
  padding: 40px 26px;
`;
export const RedBarText = styled.p`
  font-size: 1.3rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
`;
export const FormContainer = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  gap: 20px; */
  width: 100%;
  padding: 30px;
`;

export const CustomRangeContainer = styled.div`
  position: relative;
  height: 200px;
`;
export const CustomRangeInput = styled.input`
  position: absolute;
  transform: rotate(270deg);

  top: 100px;
  left: -98px;
  width: 215px;

  height: 0;
  padding: 0 5px;
  -webkit-appearance: none;
  margin: 10px 0;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 33px;
    /* height: 21px; */
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 5px #00000045;
    background: #fff;
    border-radius: 15px;
    /* border: 1px solid #000000; */
  }
  &::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 27px;
    width: 40px;
    /* height: 15px;
    width: 20px; */
    border-radius: 22px;
    background: #681e46;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 3px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: #fff;
  }
  &::-moz-range-track {
    width: 100%;
    height: 35px;
    /* height: 21px; */
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #fff;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  &::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 27px;
    width: 40px;
    /* height: 15px;
    width: 20px; */
    border-radius: 22px;
    background: #681e46;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 35px;
    /* height: 21px; */
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #fff;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  &::-ms-fill-upper {
    background: #fff;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 27px;
    width: 40px;
    /* height: 15px;
    width: 20px; */
    border-radius: 22px;
    background: #681e46;
    cursor: pointer;
  }
  &:focus::-ms-fill-lower {
    background: #fff;
  }
  &:focus::-ms-fill-upper {
    background: #fff;
  }
`;
export const OptionsContainer = styled.div`
  position: absolute;
  width: 65%;
  left: 65px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  height: 20px;
`;
export const OptionContainer = styled.div`
  height: 63px;
  margin: ${({ margin }) => (margin ? margin : "none")};
  background: ${({ isActive }) => (isActive ? "#681e4630" : "none")};
  border-radius: 15px;
  padding: 10px;
`;

export const MakeUpOptionContainer = styled.div`
  min-height: 100px;
  border: 1px solid #b6b6b6;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 20px;
`;
export const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }
`;

export const MakeUpTimeOptionsContainer = styled.div`
  display: flex;
  align-items: center;

  & :nth-child(1),
  & :nth-child(3) {
    width: 45%;
  }
  & :nth-child(2) {
    width: 10%;
  }

  @media (max-width: ${ScreenSize.SM}px) {
    flex-direction: column;
    gap: 10px;
    & :nth-child(1),
    & :nth-child(2),
    & :nth-child(3) {
      width: 100%;
    }
  }
`;
