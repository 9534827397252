import React from "react";
import { injectUserStore } from "src/utils/helpers";
import useCreatePayment from "./hooks/useCreatePayment";
import { RatesSection, PaymentSection } from "./components";
import { TopSectionContainer, TopSectionItemContainer } from "./styled";
import UserNavSection from "../UserNavSection";
import UserSelection from "../UserSelection";
import DateRangeSelection from "../DateRangeSelection";

import { convertPaymentDateToDateStr } from "src/utils/helpers";

const CreatePayment = ({ UserStore }) => {
  const user = UserStore?.user;
  const createPaymentData = useCreatePayment({ user });
  const {
    currentUserType,
    setCurrentUserType,
    selectedUser,
    setSelectedUserId,
    initialData,
    selectedDateRange,
    setSelectedDateRange,
    showPaymentSection,
    selectedUserData,
    refreshUserData,
  } = createPaymentData;

  return (
    <div>
      <TopSectionContainer>
        <TopSectionItemContainer>
          <div>
            <UserNavSection
              currentUserType={currentUserType}
              setCurrentUserType={setCurrentUserType}
            />
          </div>
          <div className="mt-4">
            <UserSelection
              users={initialData.users}
              currentUserType={currentUserType}
              setSelectedUserId={setSelectedUserId}
              selectedUser={selectedUser}
            />
          </div>
          <div className="mt-4">
            <DateRangeSelection
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              isDisabled={!selectedUser}
              isOptionDisabled={(option) => {
                // option is disabled if we paid for the date option already
                return isDisabledOption(
                  option,
                  selectedUserData.userPaymentsList
                );
              }}
            />
          </div>
        </TopSectionItemContainer>
        <TopSectionItemContainer>
          <RatesSection createPaymentData={createPaymentData} />
        </TopSectionItemContainer>
      </TopSectionContainer>
      <div className="mt-5">
        <PaymentSection
          userCurrentPaymentRates={
            createPaymentData.selectedUserData.userCurrentPaymentRates
          }
          showSection={showPaymentSection}
          selectedUser={selectedUser}
          selectedDateRange={selectedDateRange}
          refreshUserData={refreshUserData}
        />
      </div>
    </div>
  );
};

export default injectUserStore(CreatePayment);

const isDisabledOption = (option, userPaymentsList) => {
  if (!userPaymentsList?.length) return false;

  let isDisabled = false;
  for (const paymentObj of userPaymentsList) {
    const paymentObjValue = {
      start: convertPaymentDateToDateStr(paymentObj.startDate),
      end: convertPaymentDateToDateStr(paymentObj.endDate),
    };

    if (
      option.value.start === paymentObjValue.start &&
      option.value.end === paymentObjValue.end
    ) {
      isDisabled = true;
      break;
    }
  }

  return isDisabled;
};
