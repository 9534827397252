import React from "react";
import RouteContent from "../../common/RouteContent";
import StudentQR from "./StudentQR";
const StudentQRRoute = ({ user }) => {
  return (
    <RouteContent title={"Student Codes"}>
      <StudentQR user={user} />
    </RouteContent>
  );
};
export default StudentQRRoute;
