import React, { useEffect, useState, useRef } from "react";
import RouteContent from "../../common/RouteContent";
import StudioUsageDropdown from "./StudioUsageDropdown";
import months from "../../../constants/months";
import trialLessonYears from "../../../constants/trialLessonYears";
import useFirebaseFns from "../../../../src/hooks/useFirebaseFns";
import WeekGrid from "./WeekGrid";
import DragableTeacher from "./DragableTeacher";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import { toast } from "react-toastify";
import { toJS } from "mobx";
import { isSuperAdmin } from "src/constants/UserRoleEnum";
import {
  haveSuperAdminPermission,
  injectUserStore,
  isActiveTeacher,
} from "src/utils/helpers";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import ReactToPrint from "react-to-print";
import { Printer } from "@styled-icons/boxicons-solid/Printer";

const FilterContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const TeachersContaner = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 30px;
`;

const DragableButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

const PrintButton = styled.button`
  background-color: inherit;
  border: 0px;
  display: flex;
  gap: 10px;
  margin-top: 40px;
`;

export const PrinterIcon = styled(Printer)`
  color: #707070;
  width: 23px;
  height: auto;
`;

const StudioUsage = ({ UserStore }) => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [studios, setStudios] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [studioUsageData, setStudioUsageData] = useState([]);
  const monthRef = useRef(month);
  const yearRef = useRef(year);
  const locationRef = useRef(location);
  const printRef = useRef();
  const {
    getLocations,
    getStudios,
    getTeachers,
    getStudioUsage,
    addStudioUsage,
    deleteStudioUsage,
  } = useFirebaseFns();

  const formatStudioUsageData = (data = []) => {
    const returnedData = {};
    data.map((item) => {
      if (returnedData[item.studio_id] == undefined) {
        returnedData[item.studio_id] = {};
      }
      if (returnedData[item.studio_id][item.weekday] == undefined) {
        returnedData[item.studio_id][item.weekday] = [];
      }
      returnedData[item.studio_id][item.weekday].push(item);
    });
    return returnedData;
  };

  const fetchStudioUsages = async () => {
    getStudioUsage(
      monthRef.current.value,
      yearRef.current.value,
      locationRef.current.value
    ).then((studioUsages) => {
      studioUsages = formatStudioUsageData(studioUsages);
      setStudioUsageData(studioUsages);
    });
  };

  const addUsage = (data) => {
    addStudioUsage({
      ...data,
      month: monthRef.current.value,
      location_id: locationRef.current.value,
      year: yearRef.current.value,
    }).then(() => {
      fetchStudioUsages().then(() => {
        toast.success("Teacher added to week slot", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    });
  };

  const deleteUsage = (id) => {
    deleteStudioUsage(id).then(() => {
      fetchStudioUsages().then(() => {
        toast.success("Studio usage deleted", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    });
  };

  //Fetch all locations and set them as dropdown options
  //Get studios and set them in table
  //Get teachers to be dragged into table
  useEffect(() => {
    getLocations().then((locations) => {
      let temp = [];
      locations.map((location) => {
        let tempLocation = { label: location.name, value: location.id };
        temp.push(tempLocation);
      });
      setLocationOptions(temp);
    });

    getStudios("name", "asc").then((studios) => {
      let temp = [];
      studios.map((studio) => {
        let tempStudio = { name: studio.name, id: studio.id };
        temp.push(tempStudio);
      });
      setStudios(temp);
    });

    getTeachers().then((teachers) => {
      setTeachers(teachers);
    });
  }, []);

  useEffect(() => {
    monthRef.current = month;
    yearRef.current = year;
    locationRef.current = location;
    if (month && year && location) {
      fetchStudioUsages();
    }
  }, [month, year, location]);

  useEffect(() => {
    monthRef.current = month;
    yearRef.current = year;
    locationRef.current = location;
  });

  const user = toJS(UserStore)?.user;
  const hasPermisionToView = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.manageTeachersStudios
  );
  const isSuperAdminUser = isSuperAdmin(UserStore.user.role);

  return (
    <RouteContent title="Studio Usage">
      <DndProvider backend={HTML5Backend}>
        <FilterContainer>
          <div>
            Month:
            <StudioUsageDropdown
              placeholder={"Select month"}
              setValue={setMonth}
              options={months}
              width={"200px"}
            />
          </div>
          <div>
            Year:
            <StudioUsageDropdown
              placeholder={"Select year"}
              setValue={setYear}
              options={trialLessonYears}
              width={"200px"}
            />
          </div>
          <div>
            Location:
            <StudioUsageDropdown
              placeholder={"Select location"}
              setValue={setLocation}
              options={locationOptions}
              width={"200px"}
            />
          </div>
        </FilterContainer>

        {month && location && year ? (
          <div>
            <ReactToPrint
              trigger={() => (
                <PrintButton>
                  <PrinterIcon />
                  Print schedule
                </PrintButton>
              )}
              content={() => printRef.current}
            />
            <div className="weekGridContainer">
              <WeekGrid
                ref={printRef}
                fetchStudioUsages={fetchStudioUsages}
                studioUsageData={studioUsageData}
                studios={studios}
                addUsage={addUsage}
                teachers={teachers}
                deleteUsage={deleteUsage}
                month={month}
                year={year}
                location={location}
              />
            </div>
            {hasPermisionToView || isSuperAdminUser ? (
              <TeachersContaner>
                <div>Teachers:</div>
                <DragableButtonContainer>
                  {teachers
                    .filter((teacher) => isActiveTeacher(teacher))
                    .map((teacher) => {
                      return (
                        <DragableTeacher
                          key={teacher.id}
                          name={teacher.fullName}
                          id={teacher.id}
                        />
                      );
                    })}
                </DragableButtonContainer>
              </TeachersContaner>
            ) : null}
          </div>
        ) : null}
      </DndProvider>
    </RouteContent>
  );
};

export default injectUserStore(StudioUsage);
