import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";

export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const CheckboxesContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
export const CheckboxWrapper = styled.span`
  display: inline-block;
`;
export const CheckboxField = styled.input`
  margin-right: 5px;
`;
