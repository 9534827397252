import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  parseUserPaymentDates,
  parseUserPaymentRateDates,
} from "src/utils/firebaseDatesParserFns";
import { paymentUserTypes } from "../../../constants";

const initialDataInitialValues = {
  users: [],
};
const selectedUserDataInitialValues = {
  userCurrentPaymentRates: null,
  userPaymentsList: [],
};
const useCreatePayment = ({ user }) => {
  const { getTeachers, getAdminsTeam, getUserPaymentRates, getUserPayments } =
    useFirebaseFns();

  const [currentUserType, setCurrentUserType] = useState(
    paymentUserTypes.INSTRUCTOR
  );
  const [areRatesLocked, setAreRatesLocked] = useState(true);

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserData, setSelectedUserData] = useState(
    selectedUserDataInitialValues
  );
  const [loadingSelectedUserData, setLoadingSelectedUserData] = useState(false);
  const [userDataRefresher, setUserDataRefresher] = useState(0);

  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const refreshUserData = () => {
    setUserDataRefresher((oldVal) => oldVal + 1);
  };

  // fetches the initial page data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setInitialData(initialDataInitialValues);
        setSelectedUserId("");

        let users = [];

        if (currentUserType === paymentUserTypes.OPERATION_TEAM) {
          const [adminsTeam] = await Promise.all([getAdminsTeam()]);
          users = adminsTeam;
        } else if (currentUserType === paymentUserTypes.INSTRUCTOR) {
          const [teachers] = await Promise.all([getTeachers()]);
          users = teachers;
        }

        setInitialData((oldVal) => ({
          ...oldVal,
          users,
        }));

        setLoadingInitialData(false);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [currentUserType]);

  // fetches the selected user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!selectedUserId) return;
        setLoadingSelectedUserData(true);

        const [selectedUserRates, selectedUserPayments] = await Promise.all([
          getUserPaymentRates(selectedUserId, { orderByDate: true }),
          getUserPayments(selectedUserId, { orderByDate: true }),
        ]);

        setSelectedUserData((oldVal) => ({
          ...oldVal,
          userCurrentPaymentRates: selectedUserRates[0],
          userPaymentsList: selectedUserPayments,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingSelectedUserData(false);
      }
    };
    fetchUserData();
  }, [selectedUserId, userDataRefresher]);

  // resets the date field when the selected user changes
  useEffect(() => {
    setSelectedDateRange(null);
  }, [selectedUserId]);

  // getting the selected user object
  const selectedUser = useMemo(() => {
    if (!selectedUserId) return;

    const user = initialData.users.find(({ id }) => id === selectedUserId);
    return user;
  }, [initialData, selectedUserId]);

  const showRatesSection = useMemo(() => {
    return !!selectedUser;
  }, [selectedUser]);

  const showPaymentSection = useMemo(() => {
    return !!selectedUser && !!selectedDateRange;
  }, [selectedUser, selectedDateRange]);

  return {
    currentUserType,
    setCurrentUserType,
    areRatesLocked,
    setAreRatesLocked,
    initialData,
    loadingInitialData,
    selectedUser,
    setSelectedUserId,
    selectedUserData,
    loadingSelectedUserData,
    selectedDateRange,
    setSelectedDateRange,
    refreshUserData,
    showRatesSection,
    showPaymentSection,
  };
};

export default useCreatePayment;
