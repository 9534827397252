import React, { useState } from "react";

import ExistingPackageSetItemCreation from "./ExistingPackageSetItemCreation";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";
import MultiplePackageLessonsCreationSection from "../../MultiplePackageLessonsCreationSection";

const RadioWrapper = styled.label`
  display: inline-block;
  padding: 10px 0;
  margin-right: 25px;
`;

const RadioInput = styled.input`
  display: inline-block;
  margin-right: 10px;
`;

const radioOptions = {
  SINGLE: "single",
  RECURRING: "RECURRING",
};
const CreationBody = ({
  packageLessonId,
  setNumber,
  instrumentId,
  initialLessonDuration,
  initialLessonLocationId,
  isRenewal,
  modalData,
}) => {
  const [selectedType, setSelectedType] = useState(radioOptions.SINGLE);

  const renderCurrentPackageComponent = () => {
    // in case of renewal
    if (selectedType === radioOptions.RECURRING) {
      return (
        <div>
          <MultiplePackageLessonsCreationSection
            modalData={modalData}
            packageLessonId={packageLessonId}
            isRenewal={isRenewal}
            newSetNumber={setNumber}
            instrumentId={instrumentId}
          />
        </div>
      );
    } else {
      return (
        <ExistingPackageSetItemCreation
          initialLessonDuration={initialLessonDuration}
          initialLessonLocationId={initialLessonLocationId}
          packageLessonId={packageLessonId}
          setNumber={setNumber}
          instrumentId={instrumentId}
        />
      );
    }
  };
  return (
    <div>
      {isRenewal && (
        <div className="mb-4">
          <div>
            <CustomLabel className="me-4">Lesson Frequency:</CustomLabel>
            <RadioWrapper>
              <RadioInput
                type="radio"
                value={radioOptions.SINGLE}
                checked={selectedType === radioOptions.SINGLE}
                onChange={() => {
                  setSelectedType(radioOptions.SINGLE);
                }}
              />
              Single Lesson
            </RadioWrapper>
            <RadioWrapper>
              <RadioInput
                type="radio"
                value={radioOptions.RECURRING}
                checked={selectedType === radioOptions.RECURRING}
                onChange={() => {
                  setSelectedType(radioOptions.RECURRING);
                }}
              />
              10 Recurring Weeks
            </RadioWrapper>
          </div>
        </div>
      )}
      <div>{renderCurrentPackageComponent()}</div>
    </div>
  );
};

export default CreationBody;
