import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useState } from "react";
import { compose } from "recompose";
import { SubmitSpinner } from "src/components/common";
import { absenceTypes } from "src/constants/absenceTypes";
import {
  Container,
  RedBarContainer,
  RedBarText,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../styled";
import "react-datepicker/dist/react-datepicker.css";
import { SingleDatePicker } from "react-dates";
import { CalendarPickerWrapper } from "src/utils/shared/styled";
import useAbsenceRequestData from "../hooks/useAbsenceRequestData";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";
import AbsencesManualDropdown from "../../AbsencesManualDropdown";

const AbsenceRequestBody = ({
  absenceRequestModalData,
  privateLesson,
  UserStore,
  onAbsenceRequestSuccess,
}) => {
  const user = toJS(UserStore)?.user;
  const {
    initialData,
    loading,
    handleConfirmAbsenceRequest,
    loadingSaveAbsence,
    absenceFormInfo,
    setAbsenceFormInfo,
    isAllowedDate,
    isDuringSummerSession,
    combinedSchoolBreaks,
  } = useAbsenceRequestData({
    privateLesson: privateLesson,
    onAbsenceRequestSuccess,
    user,
    closeAbsenceModal: absenceRequestModalData.closeModal,
  });
  const { closeModal } = absenceRequestModalData;

  const lessonModifications = privateLesson.modifications || [];

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const isExcludedModificationDate = (date) => {
    const { startDate: lessonStartDate } = getPrivateLessonInfoOnSpecificDate({
      date: moment(date).toDate(),
      privateLesson,
      withTimelineApproximation: true,
    });
    //mods original dates to exclude
    const excludedOriginalDates = lessonModifications
      .filter(
        ({ date: modificationDate, weekNumber }) =>
          !moment(modificationDate).isSame(
            moment(lessonStartDate).add(parseInt(weekNumber) - 1, "weeks"),
            "day"
          )
      )
      .map(({ weekNumber }) =>
        moment(lessonStartDate)
          .add(parseInt(weekNumber) - 1, "weeks")
          .toDate()
      );

    let isExcluded = false;
    for (const excludedDate of excludedOriginalDates) {
      if (moment(date).isSame(excludedDate, "date")) {
        isExcluded = true;
        break;
      }
    }
    return isExcluded;
  };

  return (
    <>
      <Container>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>Absence Request</RedBarText>
        </RedBarContainer>
        <div style={{ padding: "15px 35px" }}>
          <InputWrapper>
            <CustomLabel>Absence Date:</CustomLabel>

            <CalendarPickerWrapper>
              <SingleDatePicker
                id="absenceDate"
                date={absenceFormInfo.date} // momentPropTypes.momentObj or null
                onDateChange={(date) => {
                  setAbsenceFormInfo({
                    ...absenceFormInfo,
                    date,
                    absenceType: "",
                  });
                }}
                focused={isDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  const { startDate: lessonStartDate } =
                    getPrivateLessonInfoOnSpecificDate({
                      date: moment(date).toDate(),
                      privateLesson,
                      withTimelineApproximation: true,
                    });
                  //disables dates before 2015
                  if (
                    moment(date).isBefore(
                      updatedMomentNow().set({ year: 2015 }).toDate()
                    )
                  ) {
                    return true;
                  }

                  if (!isAllowedDate(date)) {
                    return true;
                  }

                  // exclude school breaks
                  if (
                    combinedSchoolBreaks.some((schoolBreak) => {
                      return moment(date).isBetween(
                        schoolBreak.from.toDate(),
                        schoolBreak.to.toDate(),
                        "minutes",
                        "()"
                      );
                    })
                  ) {
                    return true;
                  }

                  // disables dates that are not on the same weekday as the lsn
                  if (moment(date).day() !== moment(lessonStartDate).day()) {
                    return true;
                  }
                  //disables dates before the lesson start
                  // if (moment(date).isBefore(lessonStartDate, "date"))
                  //   return true;

                  // if (isExcludedModificationDate(date)) return true;

                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </InputWrapper>
          <InputWrapper>
            <CustomLabel>
              Absence Type: <AbsencesManualDropdown />
            </CustomLabel>
            <CustomSelectField
              width="330px"
              name="absenceType"
              onChange={(e) =>
                setAbsenceFormInfo({
                  ...absenceFormInfo,
                  [e.target.name]: e.target.value,
                })
              }
              value={absenceFormInfo.absenceType}
            >
              <option value="" disabled>
                Absence Type
              </option>
              {Object.values(absenceTypes).map((absenceType) => {
                const isRegularAbsenceDuringSummerSession =
                  isDuringSummerSession &&
                  absenceType.code === absenceTypes.regular.code;
                const isSummerAbsenceNotInSummerSession =
                  !isDuringSummerSession &&
                  absenceType.code === absenceTypes.summerAbsence.code;
                const label = isRegularAbsenceDuringSummerSession
                  ? `${absenceType.abbr} (Disabled for Summer Session)`
                  : isSummerAbsenceNotInSummerSession
                  ? `${absenceType.abbr} (Disabled)`
                  : absenceType.abbr;

                const isDisabledOption =
                  isRegularAbsenceDuringSummerSession ||
                  isSummerAbsenceNotInSummerSession;
                return (
                  <option
                    disabled={isDisabledOption}
                    key={absenceType.code}
                    value={absenceType.code}
                  >
                    {label}
                  </option>
                );
              })}
            </CustomSelectField>
          </InputWrapper>
        </div>
      </Container>
      <CustomButtonsWrapper>
        <CustomButton onClick={closeModal}>Cancel</CustomButton>
        <CustomButton primary onClick={handleConfirmAbsenceRequest}>
          {loadingSaveAbsence ? (
            <div>
              <SubmitSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            "Confirm"
          )}
        </CustomButton>
      </CustomButtonsWrapper>
    </>
  );
};

export default compose(inject("UserStore"), observer)(AbsenceRequestBody);
