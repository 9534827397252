import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const usePackagePLCreationData = ({
  providedInitialData,
  studentId,
  modalData,
  user,
}) => {
  const { getInstruments, getLocations } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    instruments: [],
    locations: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLessonDuration, setSelectedLessonDuration] = useState("");
  const [selectedInstrumentId, setSelectedInstrumentId] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const reqs = [
          providedInitialData.instruments || getInstruments(),
          providedInitialData.locations || getLocations(),
        ];

        const [instruments = [], locations = []] = await Promise.all(reqs);

        setInitialData((oldVal) => ({
          ...oldVal,
          instruments,
          locations,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  const canScheduleFirstLesson =
    selectedLocationId && selectedLessonDuration && selectedInstrumentId;

  return {
    initialData,
    loadingInitialData,
    selectedLocationId,
    setSelectedLocationId,
    selectedLessonDuration,
    setSelectedLessonDuration,
    selectedInstrumentId,
    setSelectedInstrumentId,
    canScheduleFirstLesson,
  };
};

export default usePackagePLCreationData;
