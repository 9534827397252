import React from "react";
import { InputContainer, StyledInput, StyledLabel } from "./styled";

const SingleTeacher = ({
  teacherId,
  isDisabled,
  isChecked,
  onChange,
  teacherColor,
  label,
}) => {
  return (
    <div className="w-100">
      <p className="m-0">
        <StyledInput
          teacherColor={teacherColor}
          type="checkbox"
          id={`teacher-${teacherId}`}
          onClick={() => onChange(teacherId)}
          checked={isChecked}
          disabled={isDisabled}
        />
        <label
          for={`teacher-${teacherId}`}
          className="teacher-tooltip-anchor-element"
          data-tooltip-content={label}
        >
          {label || "Unknown"}
        </label>
      </p>
    </div>
  );
};

export default SingleTeacher;
