import calendar2024pdf from "src/assets/images/LandingPagePhotos/2024-2025_Calendar.pdf";
import calendar2024Image from "src/assets/images/LandingPagePhotos/2024-2025_Calendar.png";

export const calendarsList = [
  {
    value: "calendar2024",
    title: "2024-2025",
    src: calendar2024Image,
    downloadSrc: calendar2024pdf,
  },
];
