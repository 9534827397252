import {
  addDoc,
  deleteDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import CustomDropDown from "src/components/common/DropDown";

import { FirebaseContext } from "src/components/Firebase";
import { SchoolDatesContext, SCHOOL_DATES_ALERTS, SCHOOL_DATES_TABS } from "..";
import DeleteConfirmModal from "../DeleteConfirmModal";
import UnlockConfirmModal from "../UnlockConfirmModal";

const SchoolYears = ({ changeTab, showInfoModal }) => {
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();
  const {
    schoolYearsOptions,
    schoolYears,
    setSchoolYears,
    locations,
    lockSchoolYear,
    setIsUnlockConfirmModalOpen,
    selectedLocation,
    setSelectedLocation,
    locationsOptions,
    selectedSchoolYear,
    setSelectedSchoolYear,
  } = useContext(SchoolDatesContext);
  // const [schoolYears, setSchoolYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [isUnlockConfirmModalOpen, setIsUnlockConfirmModalOpen] =
  //   useState(false);
  // const [selectedLocation, setSelectedLocation] = useState({
  //   label: "",
  //   value: "",
  // });
  // const [locations, setLocations] = useState([]);
  // const [selectedSchoolYear, setSelectedSchoolYear] = useState({
  //   label: "",
  //   value: "",
  //   isLocked: false,
  // });
  const [selectedSchoolYearInputs, setSelectedSchoolYearInputs] = useState({
    title: "",
    start_date: "",
    end_date: "",
    learning_year_start: "",
    learning_year_end: "",
    summer_session_start: "",
    summer_session_end: "",
  });
  // const locationsOptions = useMemo(() => {
  //   if (selectedSchoolYear?.value && locations.length > 0) {
  //     const currentSchoolYear = schoolYears.find(
  //       ({ id }) => id === selectedSchoolYear.value
  //     );
  //     const locationsIds = Object.keys(currentSchoolYear.locations);
  //     return locations
  //       .filter(({ id }) => locationsIds.indexOf(id) !== -1)
  //       .map(({ id, name }) => ({ label: name, value: id }));
  //   }
  //   return [];
  // }, [locations, selectedSchoolYear]);
  // const schoolYearsOptions = useMemo(() => {
  //   return schoolYears.map((schoolYear) => ({
  //     label: schoolYear.title,
  //     value: schoolYear.id,
  //     title: schoolYear.title,
  //     locations: schoolYear.locations,
  //     isLocked: schoolYear?.isLocked || false,
  //   }));
  // }, [schoolYears]);
  // const fetchLocations = async () => {
  //   const locationsDocs = await getDocs(firebase.getLocations());
  //   const addedLocations = locationsDocs.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));
  //   setLocations(addedLocations);
  // };
  // useEffect(() => {
  //   if (locationsOptions.length > 0) {
  //     setSelectedLocation({ ...locationsOptions[0] });
  //   }
  // }, [locationsOptions]);
  useEffect(() => {
    const schoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    if (schoolYear) {
      setSelectedSchoolYearInputs({
        title: schoolYear?.title ?? "",
        start_date: schoolYear?.locations?.[selectedLocation.value]?.dates
          ?.start_date
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.start_date.toDate()
            ).format("YYYY-MM-DD")
          : "",
        end_date: schoolYear?.locations?.[selectedLocation.value]?.dates
          ?.end_date
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.end_date.toDate()
            ).format("YYYY-MM-DD")
          : "",
        learning_year_start: schoolYear?.locations?.[selectedLocation.value]
          ?.dates?.learning_year_start
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.learning_year_start.toDate()
            ).format("YYYY-MM-DD")
          : "",
        learning_year_end: schoolYear?.locations?.[selectedLocation.value]
          ?.dates?.learning_year_end
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.learning_year_end.toDate()
            ).format("YYYY-MM-DD")
          : "",
        summer_session_start: schoolYear?.locations?.[selectedLocation.value]
          ?.dates?.summer_session_start
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.summer_session_start.toDate()
            ).format("YYYY-MM-DD")
          : "",
        summer_session_end: schoolYear?.locations?.[selectedLocation.value]
          ?.dates?.summer_session_end
          ? moment(
              schoolYear?.locations?.[
                selectedLocation.value
              ]?.dates?.summer_session_end.toDate()
            ).format("YYYY-MM-DD")
          : "",
      });
    }
  }, [selectedSchoolYear, selectedLocation]);
  // const fetchSchoolYears = async () => {
  //   const docs = await getDocs(firebase.getSchoolYears());
  //   const yearsArray = [];
  //   docs.forEach((doc) => {
  //     yearsArray.push({
  //       id: doc.id,
  //       ...doc.data(),
  //     });
  //   });
  //   setSchoolYears(yearsArray);
  //   setIsLoading(false);
  // };
  useEffect(() => {
    setSelectedSchoolYear(schoolYearsOptions[0]);
  }, [schoolYearsOptions]);
  // useEffect(() => {
  //   (async () => {
  //     await Promise.all([fetchLocations(), fetchSchoolYears()]);
  //   })();
  // }, []);
  const saveSchoolYear = async () => {
    const locationId = selectedLocation.value;
    toast.info("Saving...");
    setIsLoading(true);
    const { value } = selectedSchoolYear;
    const locationDates = {
      start_date: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.start_date, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      end_date: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.end_date, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      learning_year_start: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.learning_year_start, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      learning_year_end: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.learning_year_end, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      summer_session_start: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.summer_session_start, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      summer_session_end: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.summer_session_end, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
    };
    const currentSelectedSchoolYear = schoolYears.find(
      ({ id }) => id === value
    );
    // const title = `${moment(locationDates.start_date.toDate()).year()} - ${moment(locationDates.end_date.toDate()).year()}`
    const newDocumentData = {
      title: selectedSchoolYearInputs.title,
      isLocked: true,
      locations: {
        ...(currentSelectedSchoolYear?.locations || {}),
        [locationId]: {
          ...(currentSelectedSchoolYear?.locations?.[locationId] || {}),
          dates: {
            ...locationDates,
          },
        },
      },
    };
    await updateDoc(firebase.getSchoolYearDoc(value), newDocumentData);
    toast.success("Successfully updated");
    if (
      currentSelectedSchoolYear?.locations?.[locationId]?.breaks === undefined
    ) {
      toast.warn(SCHOOL_DATES_ALERTS.schoolBreaks, {
        autoClose: false,
      });
      showInfoModal("Required Data", SCHOOL_DATES_ALERTS.schoolBreaks);
      changeTab(SCHOOL_DATES_TABS[1]);
    } else if (
      currentSelectedSchoolYear?.locations?.[locationId]?.makeups === undefined
    ) {
      toast.warn(SCHOOL_DATES_ALERTS.makeupDeadlines, {
        autoClose: false,
      });
      showInfoModal("Required Data", SCHOOL_DATES_ALERTS.makeupDeadlines);
      changeTab(SCHOOL_DATES_TABS[2]);
    } else {
      setSelectedSchoolYear((previous) => ({ ...previous, isLocked: true }));
      setSchoolYears((previous) =>
        previous.map((schoolYear) => {
          if (schoolYear.id === value) {
            return {
              ...schoolYear,
              isLocked: true,
              title: selectedSchoolYearInputs.title,
              locations: {
                ...schoolYear.locations,
                [locationId]: {
                  ...(schoolYear.locations?.[locationId] || {}),
                  dates: {
                    ...locationDates,
                  },
                },
              },
            };
          }
          return schoolYear;
        })
      );
      showInfoModal("All set", SCHOOL_DATES_ALERTS.allSet);
      setIsLoading(false);
    }
  };
  // const addNewYear = async () => {
  //   toast.info("Adding a new year");
  //   setIsLoading(true);
  //   const random = Math.ceil(Math.random() * 10);
  //   await addDoc(firebase.getSchoolYears(), {
  //     title: `New Year ${random}`,
  //   });
  //   await fetchSchoolYears();
  //   toast.success("Successfully added.");
  //   toast.warn(
  //     "Please fill the school dates, school breaks, and makeup deadlines and save",
  //     {
  //       autoClose: false,
  //     }
  //   );
  // };
  const updateSchoolYear = (key, value) => {
    setSelectedSchoolYearInputs((previous) => ({
      ...previous,
      [key]: value,
    }));
  };
  const deleteYear = async () => {
    setIsDeleteModalOpen(false);
    toast.info("Deleting school year...");
    await deleteDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value));
    setSchoolYears((previous) =>
      previous.filter(({ id }) => id !== selectedSchoolYear.value)
    );
    toast.success("Deleted.");
  };
  const isYearDataNotCompleted = () => {
    const currentSelectedSchoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    return (
      Object.keys(
        currentSelectedSchoolYear?.locations?.[selectedLocation.value] || {}
      ).length < 3
    );
  };
  // const lockSchoolYear = async () => {
  //   toast.info("Locking school year");
  //   await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {
  //     isLocked: true,
  //   });
  //   setSelectedSchoolYear((previous) => ({ ...previous, isLocked: true }));
  //   setSchoolYears((previous) =>
  //     previous.map((schoolYear) => {
  //       if (schoolYear.id === selectedSchoolYear.value) {
  //         return {
  //           ...schoolYear,
  //           isLocked: true,
  //         };
  //       }
  //       return schoolYear;
  //     })
  //   );
  //   toast.success("Successfully locked");
  // };
  // const unlockSchoolYear = async () => {
  //   toast.info("Unlocking school year");
  //   setIsUnlockConfirmModalOpen(false);
  //   await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {
  //     isLocked: false,
  //   });
  //   setSelectedSchoolYear((previous) => ({ ...previous, isLocked: false }));
  //   setSchoolYears((previous) =>
  //     previous.map((schoolYear) => {
  //       if (schoolYear.id === selectedSchoolYear.value) {
  //         return {
  //           ...schoolYear,
  //           isLocked: false,
  //         };
  //       }
  //       return schoolYear;
  //     })
  //   );
  //   toast.success("Successfully unlocked");
  // };
  return (
    <div className="h-100 w-100 p-4">
      {isLoading ? (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <SubmitSpinner style={{ color: "#681E46" }} />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center mb-2">
            <h2>School Years</h2>
            <button
              onClick={() => navigate("/dashboard/new-school-year")}
              className="submitButton h6 ms-2 px-3 py-1 mb-0"
            >
              Add New <i className="fa fa-plus ms-1" />
            </button>
            {selectedSchoolYear.isLocked ? (
              <Button
                onClick={() => setIsUnlockConfirmModalOpen(true)}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="danger"
              >
                Unlock
              </Button>
            ) : (
              <Button
                onClick={lockSchoolYear}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="success"
              >
                Lock
              </Button>
            )}
            {(schoolYears?.length || 0) > 1 ? (
              <Button
                onClick={() => setIsDeleteModalOpen(true)}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="danger"
              >
                Delete
              </Button>
            ) : null}
          </div>
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0">
              <CustomDropDown
                label={selectedSchoolYear.label}
                options={schoolYearsOptions}
                keyName={"label"}
                value={selectedSchoolYear.label}
                onOptionClick={setSelectedSchoolYear}
              />
            </div>
            <span className="col-1 px-3" />
            <div className="inputContainer col-4 ms-2">
              <div className="h5 mb-1">Location</div>
              <CustomDropDown
                label={selectedLocation.label}
                options={locationsOptions}
                keyName={"label"}
                value={selectedLocation.label}
                onOptionClick={setSelectedLocation}
              />
            </div>
            {locations.length > locationsOptions.length ? (
              <button
                onClick={() =>
                  navigate(
                    `/dashboard/new-school-year-location/${selectedSchoolYear.value}`
                  )
                }
                className="submitButton h6 ms-2 px-3 py-1 mb-1"
              >
                Add New Location
                <i className="fa fa-plus ms-1" />
              </button>
            ) : null}
          </div>
          <hr className={"py-1"} />
          {isYearDataNotCompleted() ? (
            <div className="alert alert-warning">
              Please fill the <b>school year dates</b>, <b>school breaks</b>,
              and <b>makeup deadlines</b> and save
            </div>
          ) : null}
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <label className="h5 mb-1" htmlFor="title">
                Title
              </label>
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="text"
                id="title"
                name="title"
                value={selectedSchoolYearInputs.title}
                onChange={(e) => {
                  updateSchoolYear("title", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <label className="h5 mb-1" htmlFor="start_date">
                First Date
              </label>
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                id="start_date"
                name="start_date"
                value={selectedSchoolYearInputs.start_date}
                onChange={(e) => {
                  updateSchoolYear("start_date", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center pb-2">TO</span>
            <div className="inputContainer col-4 m-0 me-2">
              <label className="h5 mb-1" htmlFor="end_date">
                End Date
              </label>
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                id="end_date"
                name="end_date"
                value={selectedSchoolYearInputs.end_date}
                onChange={(e) => {
                  updateSchoolYear("end_date", e.target.value);
                }}
              />
            </div>
          </div>
          <h5>Learning Year Dates:</h5>
          <div className="d-flex align-items-center mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                name="learning_year_start"
                value={selectedSchoolYearInputs.learning_year_start}
                onChange={(e) => {
                  updateSchoolYear("learning_year_start", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center">TO</span>
            <div className="inputContainer col-4 m-0">
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                name="learning_year_end"
                value={selectedSchoolYearInputs.learning_year_end}
                onChange={(e) => {
                  updateSchoolYear("learning_year_end", e.target.value);
                }}
              />
            </div>
          </div>
          <h5>Summer Session Dates:</h5>
          <div className="d-flex align-items-center mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                name="summer_session_start"
                value={selectedSchoolYearInputs.summer_session_start}
                onChange={(e) => {
                  updateSchoolYear("summer_session_start", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center">TO</span>
            <div className="inputContainer col-4 m-0">
              <input
                disabled={selectedSchoolYear.isLocked}
                className="InputField w-100 m-0"
                type="date"
                name="summer_session_end"
                value={selectedSchoolYearInputs.summer_session_end}
                onChange={(e) => {
                  updateSchoolYear("summer_session_end", e.target.value);
                }}
              />
            </div>
          </div>
          {!selectedSchoolYear.isLocked ? (
            <div className="d-flex justify-content-center">
              <button
                disabled={selectedSchoolYear.isLocked}
                onClick={saveSchoolYear}
                className="submitbutton h5 px-5"
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      )}
      {isDeleteModalOpen ? (
        <DeleteConfirmModal
          close={() => setIsDeleteModalOpen(false)}
          onDelete={deleteYear}
        />
      ) : null}
      {/* {isUnlockConfirmModalOpen ? (
        <UnlockConfirmModal
          close={() => setIsUnlockConfirmModalOpen(false)}
          onConfirm={unlockSchoolYear}
        />
      ) : null} */}
    </div>
  );
};
export default SchoolYears;
