import React from "react";
import { LibraryFilePreview } from "../../styled-components/teacherLibraryStyledComponents";

const FileUploadPreviewContainer = ({ name, onClick }) => {
  return (
    <LibraryFilePreview className="mb-2 d-flex align-items-center font-weight-bold me-2">
      <span>{name}</span>
      <i
        onClick={onClick}
        style={{ color: "black" }}
        className="fas fa-times mx-2"
      />
    </LibraryFilePreview>
  );
};
export default FileUploadPreviewContainer;
