import moment from "moment";
import React from "react";
import useModal from "src/hooks/useModal";
import { getLessonPackageItemsInfo } from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import CreatePackageItemModal from "../../CreatePackageItemModal";
import { usePackageLessonContext } from "../contexts/PackageLessonContext";
import PackageInfo from "./PackageInfo";

const PackageLessonBody = ({ modalData }) => {
  const { isLoading, packageLesson } = usePackageLessonContext();
  const createNewSeItemModalData = useModal();

  const { isRenewAvailable: showRenewButton, lastSetInfo } =
    getLessonPackageItemsInfo(packageLesson.packageSets);
  const lastSetNumber = lastSetInfo?.set;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="ps-4 pe-4 mb-4">
        {showRenewButton && (
          <div className="text-end">
            <PrimaryButton
              onClick={createNewSeItemModalData.openModal}
              width="150px"
              padding="9px"
            >
              Renew
            </PrimaryButton>
          </div>
        )}
      </div>
      <div>
        <PackageInfo />
      </div>

      {/* MODALS */}
      {createNewSeItemModalData.isModalOpen && (
        <CreatePackageItemModal
          modalData={createNewSeItemModalData}
          packageLessonId={packageLesson.id}
          instrumentId={packageLesson.instrumentId}
          setNumber={lastSetNumber + 1}
          modalTitle="Renew Package Lesson"
          initialLessonDuration={
            packageLesson.packageSets[0]?.setItems?.[0]?.duration
          }
          initialLessonLocationId={
            packageLesson.packageSets[0]?.setItems?.[0]?.locationId
          }
          isRenewal={true}
        />
      )}
    </div>
  );
};

export default PackageLessonBody;
