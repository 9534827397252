import React from "react";
import RedbarModal from "../RedbarModal";
import useGroupClassAbsence from "./hooks/useGroupClassAbsence";
import GroupClassAbsenceRequestBody from "./GroupClassAbsenceRequestBody";
import { injectUserStore } from "src/utils/helpers";

const GroupClassAbsenceRequestModal = ({
  groupClassId,
  modalData,
  UserStore,
}) => {
  const user = UserStore.user;
  const { isModalOpen, toggleModal, closeModal } = modalData;
  const hookData = useGroupClassAbsence({ groupClassId, user });

  return (
    <RedbarModal
      modalSize="md"
      modalData={modalData}
      moadalHeader={"Group Class"}
      redbarTitle={"Group Class Info"}
      overflowRedbarContent={true}
    >
      <GroupClassAbsenceRequestBody
        groupClassId={groupClassId}
        hookData={hookData}
      />
    </RedbarModal>
  );
};

export default injectUserStore(GroupClassAbsenceRequestModal);
