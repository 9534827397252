import moment from "moment";
import React from "react";
import { isStudent } from "src/constants/UserRoleEnum";
import { isSameDayOrPassedDeadline } from "src/utils/helpers";
import styled from "styled-components";
import DateRow from "../../../AvailabilitiesComponents/DateRow";
import TeacherRow from "../../../AvailabilitiesComponents/TeacherRow";
import { modalViews } from "../../constants";

const SectionContainer = styled.div`
  min-height: 400px;
  box-shadow: 0 0px 5px 0px #e8e8e8;
  border-radius: 10px;
  padding: 20px;
`;
const TeacherAvailabilitiesGrid = ({
  packageData,
  requestedDateData,
  user,
}) => {
  const { currentView, dateViewData, teachersViewData } = packageData;

  const renderDateView = () => {
    const { teachersWithAvailableTimeSlots } = dateViewData || {};
    if (!teachersWithAvailableTimeSlots?.length) {
      return (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="text-center">No Teachers are available on this day</h1>
        </div>
      );
    } else {
      return (
        <SectionContainer>
          {teachersWithAvailableTimeSlots?.map((teacher) => {
            return (
              <div key={teacher.id}>
                <TeacherRow
                  teacher={teacher}
                  requestedDate={requestedDateData.requestedDate}
                  handleSelectedSlotInfoChange={
                    packageData.handleSelectedLessonSlot
                  }
                  selectedSlotInfo={packageData.selectedLessonSlot}
                />
                <hr />
              </div>
            );
          })}
        </SectionContainer>
      );
    }
  };

  const renderTeachersView = () => {
    const { selectedTeacherWithAvailableTimeSlots } = teachersViewData || {};

    const { fullName, selectedTeacherAvailabilities } =
      selectedTeacherWithAvailableTimeSlots || {};

    if (!selectedTeacherWithAvailableTimeSlots) {
      return (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="text-center">Please Select a Teacher</h1>
        </div>
      );
    } else {
      return (
        <SectionContainer>
          <h2 className="text-center mb-5">{fullName || "N/A"}</h2>
          {selectedTeacherAvailabilities?.map((avail) => {
            return (
              <div key={avail.date}>
                <DateRow
                  dateAvailabilities={avail}
                  teacher={selectedTeacherWithAvailableTimeSlots}
                  handleSelectedSlotInfoChange={
                    packageData.handleSelectedLessonSlot
                  }
                  selectedSlotInfo={packageData.selectedLessonSlot}
                />
                <hr />
              </div>
            );
          })}
        </SectionContainer>
      );
    }
  };

  return currentView === modalViews.DATE
    ? renderDateView()
    : renderTeachersView();
};

export default TeacherAvailabilitiesGrid;
