import { Divider } from "antd";
import { InfoItem } from "../styled";

const PartnerApplicantInfo = ({ applicant }) => {
  return (
    <>
      <InfoItem capitalize>
        <span>
          Selected location:{" "}
          {Array.isArray(applicant?.selectedLocation)
            ? applicant?.selectedLocation?.join(", ")
            : applicant?.selectedLocation || "N/A"}
        </span>
      </InfoItem>
      <Divider />
      <InfoItem>
        <span>Message: {applicant?.message || "N/A"}</span>
      </InfoItem>
    </>
  );
};

export default PartnerApplicantInfo;
