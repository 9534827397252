import React from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useHelpers = () => {
  const { getFeedItemMetadata } = useFirebaseFns();

  const checkFeedItemMetadata = (itemId, feedItemsMetadata, actionType) => {
    if (!itemId || !feedItemsMetadata?.length || !actionType) return false;

    const hasMeta = !!feedItemsMetadata.find(
      (meta) => meta.feedItemId === itemId && meta.actionType === actionType
    );

    return hasMeta;
  };

  const isFeedItemMetaAlreadyExistsInDB = async (
    userId,
    feedItemId,
    metaAction
  ) => {
    try {
      if (!userId || !feedItemId || !metaAction) {
        return true;
      }
      const existingMetaDocs = await getFeedItemMetadata(feedItemId, {
        actionType: metaAction,
        userId,
      });
      const metaActionExists = !!existingMetaDocs?.length;
      return metaActionExists;
    } catch (err) {
      console.log(err);
    }
  };

  return { checkFeedItemMetadata, isFeedItemMetaAlreadyExistsInDB };
};

export default useHelpers;
