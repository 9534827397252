import styled from "styled-components";

export const OptionsContainer = styled.div`
  display: flex;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-shadow: 0px 2px 10px #0000000a;
`;

export const OptionContainer = styled.div.attrs((props) => {
  return { className: props.className };
})`
  padding: ${({ padding }) => padding || "20px"};
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  color: #777777;
  cursor: pointer;
  flex: 1;
  outline: 1px solid #e4e4e4;

  &.active {
    color: #fff;
    background-color: #681e46;
  }
`;
