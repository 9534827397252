import styled, { css } from "styled-components";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { InputNumber, Radio } from "antd";

export const CustomLabel = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const CustomTextarea = styled.textarea`
  display: block;
  border-radius: 15px;
  margin-top: 10px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "75px"};

  ::placeholder {
    color: #979797;
  }

  &:disabled {
    background-color: #f1f1f1;
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  padding: 12px 12px 12px 20px;
  border-radius: 10px;
  background-color: #fff;
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
  min-width: 100px;

  &:disabled {
    background-color: #f1f1f1;
  }
`;
export const CustomInput = styled.input`
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  ::placeholder {
    color: #979797;
  }

  &:disabled {
    background-color: #f1f1f1;
  }
`;
export const InputRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: ${({ flexWrap }) => flexWrap || "nowrap"};
`;
export const InputContainer = styled.div`
  flex-grow: ${({ flexGrow }) => flexGrow || "1"};
  flex-shrink: ${({ flexShrink }) => flexShrink || "1"};
  flex-basis: ${({ flexBasis }) => flexBasis || "0px"}; ;
`;
export const AvailabilitiesContainer = styled.div`
  background-color: #f3f3f3cc;
  padding: 15px;
  border-radius: 7px;
`;
export const SectionContainer = styled.div`
  padding: 20px;
  border: 1px solid #e4e4e4;
  box-shadow: #eeeeee 0 0 8px 0;
  margin-bottom: 20px;
  border-radius: 7px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`;
export const AddNewAmountBtn = styled.span`
  cursor: pointer;
  font-weight: 700;
  color: #681e46;
`;
export const DeleteIcon = styled(DeleteOff)`
  height: 25px;
  width: 25px;
  cursor: pointer;
`;

export const StudentAgeInput = styled(InputNumber)`
  font-size: 16px;
`;

export const StudentAgeRadio = styled(Radio)`
  font-size: 16px;
`;
