import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import ChangeTimezoneBody from "./ChangeTimezoneBody";
import { CloseIcon } from "./styled";

const ChangeTimezoneModal = ({ modalData }) => {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  return (
    <CustomModal
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
      // padding={"50px"}
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>Change the timezone</h2>
      </CustomModalHeader>
      <CustomModalBody>
        <ChangeTimezoneBody />
      </CustomModalBody>
    </CustomModal>
  );
};

export default ChangeTimezoneModal;
