import React, { useState } from "react";

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalName, setCurrentModalName] = useState("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentModalName("");
  };
  const toggleModal = () => setIsModalOpen((oldVal) => !oldVal);
  const isModalNameOpen = (modalName) => {
    const isOpen = isModalOpen && modalName === currentModalName;
    return isOpen;
  };
  const openModalWithName = (modalName) => {
    setCurrentModalName(modalName);
    setIsModalOpen(true);
  };
  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal,
    currentModalName,
    setCurrentModalName,
    isModalNameOpen,
    openModalWithName,
  };
};

export default useModal;
