import React from "react";
import PaymentOptionsSection from "../components/PaymentOptionsSection";
import NavSection from "../components/NavSection";
import { viewComponents } from "../constants";
import RosterSection from "../components/RosterSection";
import ClassesInfo from "../components/ClassesInfoSection/ClassesInfo";
import moment from "moment";
import { SubmitSpinner } from "src/components/common";

const ViewGroupClassInfoBody = ({ groupClassId, hookData }) => {
  const {
    initialData,
    loadingInitialData,
    currentView,
    setCurrentView,
    setCurrentStudentId,
    currentStudent,
  } = hookData;
  const {
    groupClass,
    enrollments,
    paymentOptions,
    users,
    absences,
    groupClassPayments,
  } = initialData;
  const { classes, title } = groupClass || {};

  const firstClass = classes?.[0];
  const lastClass = classes?.[classes?.length - 1];

  let classTitleInfo = "";
  if (firstClass && lastClass) {
    const firstClassDate = firstClass.startDate;
    const lastClassDate = lastClass.startDate;

    const formattedFirstDate = moment(firstClassDate).format("dddd hh:mm A");
    const firstAndLastClassText = `(${moment(firstClassDate).format(
      "MMM Do"
    )} - ${moment(lastClassDate).format("MMM Do")})`;

    classTitleInfo = `${formattedFirstDate} ${firstAndLastClassText}`;
  }

  const renderCurrentViewComponent = () => {
    if (currentView === viewComponents.classRoster.value) {
      return (
        <RosterSection
          paymentOptions={paymentOptions}
          groupClassId={groupClassId}
          enrollments={enrollments}
          users={users}
          setCurrentStudentId={setCurrentStudentId}
          currentStudent={currentStudent}
          groupClassPayments={groupClassPayments}
        />
      );
    } else if (currentView === viewComponents.classInfo.value) {
      return (
        <ClassesInfo
          absences={absences}
          groupClass={groupClass}
          users={users}
        />
      );
    } else if (currentView === viewComponents.paymentInfo.value) {
      return <PaymentOptionsSection paymentOptions={paymentOptions} />;
    }
  };

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div className="p-4">
      {title && (
        <>
          <h6 className="d-flex justify-content-between">
            <span>{title}</span>
            <span>{classTitleInfo}</span>
          </h6>
          <hr />
        </>
      )}
      <div className="pt-3 pb-3">
        <NavSection currentView={currentView} setCurrentView={setCurrentView} />
      </div>
      <div>{renderCurrentViewComponent()}</div>
    </div>
  );
};

export default ViewGroupClassInfoBody;
