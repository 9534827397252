import moment from "moment";
import React from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import {
  mapOptionsToRoles,
  mapRolesToOptions,
} from "src/components/Dashboard/SuperAdmin/TeachersPage/helperFns";
import { getTimeDiffInMins } from "src/utils/helpers";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { weekDays } from "../../../../../../../../../../constants/weekDays";
import { InputWithLabel } from "../../../../../../../../../../utils/shared/shared";
import {
  AddNewDayBtn,
  AvailableDaysSectionContainer,
  SelectField,
} from "./styled";
import { v4 as uuidv4 } from "uuid";

const TeacherDays = ({
  teacherDays,
  setTeacherDays,
  color,
  setColor,
  locations,
}) => {
  const handleAddNewDay = () => {
    setTeacherDays((oldVal) => [
      ...oldVal,
      {
        id: uuidv4(),
        day: "",
        start: "",
        end: "",
        location: "",
        isVirtualOnly: false,
        disabledForRoles: [],
      },
    ]);
  };
  const handleDeleteDay = (index) => {
    setTeacherDays((oldVal) => oldVal.filter((day, i) => i !== index));
  };

  const handleDayChange = (name, value, i) => {
    let teacherDaysCopy = [...teacherDays];
    teacherDaysCopy[i][name] = value;
    setTeacherDays(teacherDaysCopy);
  };
  return (
    <AvailableDaysSectionContainer>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className="me-3">Teacher's Days</h2>
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </div>
        <AddNewDayBtn onClick={handleAddNewDay}>Add A New Day</AddNewDayBtn>
      </div>
      {teacherDays.map((day, i) => {
        // if the end is before start, then its in the next day
        const isEndTimeNextDay = moment(day.start, "HH:mm").isAfter(
          moment(day.end, "HH:mm"),
          "minutes"
        );

        return (
          <div key={i}>
            <div className="d-flex justify-content-between align-items-end">
              <SelectField
                name="day"
                defaultValue=""
                value={teacherDays[i].day}
                onChange={(e) => handleDayChange("day", e.target.value, i)}
                width={"130px"}
              >
                <option value="" disabled>
                  Day
                </option>
                {weekDays.map((day) => (
                  <option key={day.id} value={day.id}>
                    {day.name}
                  </option>
                ))}
              </SelectField>
              <div>
                <InputWithLabel
                  label="Start"
                  type="time"
                  name="start"
                  width="115px"
                  value={teacherDays[i].start}
                  onChange={(e) => handleDayChange("start", e.target.value, i)}
                />
              </div>
              <div>
                <InputWithLabel
                  label={`End ${isEndTimeNextDay ? "(Next Day)" : ""}`}
                  type="time"
                  name="end"
                  width="115px"
                  value={teacherDays[i].end}
                  onChange={(e) => handleDayChange("end", e.target.value, i)}
                />
              </div>
              <SelectField
                name="location"
                defaultValue=""
                value={teacherDays[i].location}
                onChange={(e) => handleDayChange("location", e.target.value, i)}
                width={"130px"}
              >
                <option value="" disabled>
                  Location
                </option>
                {locations.map((day) => (
                  <option key={day.id} value={day.id}>
                    {day.name}
                  </option>
                ))}
              </SelectField>

              <div
                onClick={() => handleDeleteDay(i)}
                className="pb-2"
                style={{ cursor: "pointer" }}
              >
                <DeleteOff width={30} height={30} color="#8a8a8a" />
              </div>
            </div>
            <div
              className="mt-3 ps-1 d-flex align-items-center"
              style={{ gap: 20 }}
            >
              <div style={{ width: "50%" }}>
                <CustomReactSelect
                  placeholder="Disabled for"
                  width="100%"
                  options={mapRolesToOptions()}
                  onChange={(selectedRoles) => {
                    handleDayChange(
                      "disabledForRoles",
                      mapOptionsToRoles(selectedRoles),
                      i
                    );
                  }}
                  value={mapRolesToOptions(
                    teacherDays[i].disabledForRoles || []
                  )}
                  wrapOptions={false}
                />
              </div>
              <label>
                <input
                  type="checkbox"
                  className="me-1"
                  name="isVirtualOnly"
                  checked={teacherDays[i].isVirtualOnly}
                  onChange={(e) =>
                    handleDayChange("isVirtualOnly", e.target.checked, i)
                  }
                />
                Virtual Only
              </label>
            </div>
            <hr className="mb-4 mt-4" />
          </div>
        );
      })}
    </AvailableDaysSectionContainer>
  );
};

export default TeacherDays;
