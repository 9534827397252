import { useContext } from "react";
import { toast } from "react-toastify";
import store from "src/stores/UserStore";
import { updateDoc } from "firebase/firestore";
import { prices } from "src/components/Dashboard/Guest/utils";

import { PayPalButtons } from "@paypal/react-paypal-js";
import { PaypalWrapper } from "./styled";
import { FirebaseContext } from "src/components/Firebase";

const PaymentPanel = () => {
  const { user, setUser } = store;
  const firebase = useContext(FirebaseContext);

  const createOrder = (data, actions) => {
    return actions?.order?.create({
      purchase_units: [
        {
          amount: {
            value: prices[user?.package],
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING", // !!!
      },
    });
  };

  const onApprove = async (data, actions) => {
    const result = await actions?.order?.capture();

    const userToUpdate = firebase.user(user.uid);

    await updateDoc(userToUpdate, {
      paid: true,
    });
    setUser({ ...user, paid: true });
    toast.success("Transaction successful");
  };

  return (
    <PaypalWrapper>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={() => toast.info("Payment was canceled")}
        onError={() => toast.error("Something went wrong, please try again")}
      />
    </PaypalWrapper>
  );
};

export default PaymentPanel;
