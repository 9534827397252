import React from "react";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";
import ChangeAuthInfoBody from "./ChangeAuthInfoBody";

const ChangeAuthInfoModal = ({
  modalData,
  title,
  userId,
  onSuccessCallback = () => {},
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>{title}</h2>
      </CustomHeader>
      <CustomBody>
        <ChangeAuthInfoBody
          userId={userId}
          title={title}
          modalData={modalData}
          onSuccessCallback={onSuccessCallback}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default ChangeAuthInfoModal;
