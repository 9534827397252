import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Document, Page, pdfjs } from "react-pdf";
import {
  AttachmentImageContainer,
  CustomDocument,
  VideoContainer,
} from "../../../../styled";
import ContentLoader from "react-content-loader";

const ImgLoader = (
  <ContentLoader
    speed={3}
    width={100}
    height={100}
    viewBox="0 0 100 100"
    backgroundColor="#ababab"
    foregroundColor="#bdbcbc"
  >
    <rect x="0" y="0" rx="3" ry="3" width="100" height="100" />
  </ContentLoader>
);
const ItemAttachment = ({ attachment }) => {
  if (!attachment || !attachment.url) {
    return <div></div>;
  }

  const isImage = attachment.type.split("/")[0] === "image";
  const isPDF = attachment.type === "application/pdf";
  const isVideo = attachment.type.split("/")[0] === "video";

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isImage ? (
        <AttachmentImageContainer>
          <LazyLoadImage
            effect="blur"
            height="100%"
            src={attachment.url}
            alt="image"
            placeholder={ImgLoader}
          />
        </AttachmentImageContainer>
      ) : isPDF ? (
        <CustomDocument file={attachment.url}>
          <Page pageNumber={1} />
        </CustomDocument>
      ) : isVideo ? (
        <VideoContainer>
          <video width="100%" height="100%" className="video">
            <source src={attachment.url} type={"video/mp4"} />
          </video>
        </VideoContainer>
      ) : null}
    </div>
  );
};

export default ItemAttachment;
