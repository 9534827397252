import React from "react";
import { getUserPaymentProps, injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomInput, CustomTextarea } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import {
  paymentPropsWithoutInputField,
  paymentPropsWithoutRates,
  paymentsMap,
} from "src/constants/paymentsEnum";
import usePaymentData from "./hooks/usePaymentData";
import { InputItemContainer, InputsContainer, StyledContainer } from "./styled";

const PaymentSection = ({
  userCurrentPaymentRates,
  showSection,
  selectedUser,
  UserStore,
  selectedDateRange,
  refreshUserData,
}) => {
  const user = UserStore.user;

  const paymentData = usePaymentData({
    userCurrentPaymentRates,
    user,
    selectedUser,
    selectedDateRange,
    refreshUserData,
  });
  const {
    paymentFormData,
    handlePaymentFormDataChange,
    calculatedTotalObj,
    payUser,
    loadingPayUser,
    paymentNote,
    setPaymentNote,
  } = paymentData;

  const currentUserPaymentProps = getUserPaymentProps(selectedUser);

  if (!showSection) {
    return (
      <div>
        <h3>Payment Section</h3>
        <StyledContainer className="mt-5 d-flex justify-content-center align-items-center">
          <h4>Select User And Date Range To Unlock The Payment Section</h4>
        </StyledContainer>
      </div>
    );
  }

  const isDisabledField = (prop) => {
    const currentPaymentMap = paymentsMap.find(
      ({ propName }) => propName === prop
    );
    const hasRate = !!userCurrentPaymentRates?.rates?.find(
      ({ title, type }) =>
        title === currentPaymentMap.rateTitle &&
        type === currentPaymentMap.rateType
    )?.value;
    const isFieldDoesntRequireRate = paymentPropsWithoutRates.includes(prop);

    return !isFieldDoesntRequireRate && !hasRate;
  };

  return (
    <div>
      <h3>Payment Section</h3>
      <StyledContainer className="mt-5">
        <InputsContainer>
          {currentUserPaymentProps
            .filter((prop) => !paymentPropsWithoutInputField.includes(prop))
            .map((prop) => {
              const map = paymentsMap.find(({ propName }) => propName === prop);

              const { propName, name2 } = map;

              return (
                <InputWithLabel
                  key={propName}
                  propName={propName}
                  value={paymentFormData[propName]}
                  handlePaymentFormDataChange={handlePaymentFormDataChange}
                  label={`${name2}:`}
                  disabled={isDisabledField(propName)}
                />
              );
            })}
          <InputItemContainer></InputItemContainer>
          <InputItemContainer></InputItemContainer>
        </InputsContainer>
        <div className="mt-4">
          <CustomLabel style={{ fontSize: "0.9rem" }}>Note:</CustomLabel>
          <CustomTextarea
            width="100%"
            height="100px"
            name="note"
            value={paymentNote}
            onChange={(e) => setPaymentNote(e.target.value)}
          />
        </div>
        <div className="mt-5">
          <h4 className="text-center fw-bold">
            Total: ${calculatedTotalObj.total}
          </h4>
        </div>
        <div className="mt-4">
          {calculatedTotalObj.total > 0 && (
            <PrimaryButtonWithLoading
              onClick={payUser}
              width="100%"
              isLoading={loadingPayUser}
            >
              Pay ${calculatedTotalObj.total}
            </PrimaryButtonWithLoading>
          )}
        </div>
      </StyledContainer>
    </div>
  );
};

export default injectUserStore(PaymentSection);

const InputWithLabel = ({
  label,
  propName,
  value,
  handlePaymentFormDataChange,
  disabled,
}) => {
  return (
    <InputItemContainer>
      <CustomLabel style={{ fontSize: "0.9rem" }}>{label}</CustomLabel>
      <CustomInput
        value={value}
        onChange={(e) => handlePaymentFormDataChange(propName, e.target.value)}
        type="number"
        width="100%"
        disabled={disabled}
        disabledBgColor="#f5f5f5"
        min="0"
        onWheel={(e) => e.target.blur()} // prevents scroll behaviour
      />
    </InputItemContainer>
  );
};
