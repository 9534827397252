import React from "react";
import Select from "react-select";

const StudioUsageDropdown = ({
  options = [],
  placeholder,
  width,
  setValue,
}) => {
  let handleChange = (option) => {
    setValue(option);
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 10,
    }),
  };

  return (
    <div style={{ width: width, marginTop: "10px" }}>
      <Select
        styles={styles}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={placeholder || "Select..."}
        onChange={handleChange}
        isClearable
      />
    </div>
  );
};
export default StudioUsageDropdown;
