import React from "react";
import { SearchInputContainer } from "../styled";

const SearchSection = ({ value, onChange }) => {
  return (
    <SearchInputContainer>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={"Search"}
        className="searchInput"
      />
      <i className="fa fa-search position-absolute" />
    </SearchInputContainer>
  );
};

export default SearchSection;
