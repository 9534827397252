import React, { useContext } from "react";
import _ from "lodash";
import {
  ActiveTabContext,
  AddItemModalContext,
  ItemsSearchContext,
  LibraryContext,
  ViewItemModalContext,
} from "../../common/libraryContext";
import { AttachmentsListContainer } from "../../../styled-components/teacherLibraryStyledComponents";
import TeacherLibrarySearchInput from "../../common/TeacherLibrarySearchInput";
import AttachmentsSingleItem from "./AttachmentsSingleItem";
import EmptyResults from "../../common/EmptyResults";
import SelectAllItems from "./SelectAllItems";
import AllActions from "./AllActions";
import StudentUploadSingleItem from "./StudentUploadSingleItem";

const StudentsUploads = () => {
  const { activeTab } = useContext(ActiveTabContext);
  const { open } = useContext(ViewItemModalContext);

  const { itemsSearchValue, setItemsSearchValue } =
    useContext(ItemsSearchContext);
  const { library, selected } = useContext(LibraryContext);
  const filteredList =
    itemsSearchValue === ""
      ? library[activeTab]
      : _.filter(library[activeTab], ({ title }) =>
          title.toLowerCase().includes(itemsSearchValue.toLowerCase())
        );
  const showItem = (ID) => {
    const item = library[activeTab].find(({ id }) => id === ID);
    open(item);
  };
  const renderEmptyComponent = () => {
    return (
      filteredList.length === 0 && (
        <div className={"py-5"}>
          {itemsSearchValue !== "" ? (
            <EmptyResults isSearch={true} />
          ) : (
            <div
              className={
                " d-flex flex-column align-items-center justify-content-center"
              }
            >
              <div className="mb-5">
                <EmptyResults />
              </div>
            </div>
          )}
        </div>
      )
    );
  };
  const isAllSelected =
    selected.length === filteredList.length && filteredList.length;
  return (
    <AttachmentsListContainer>
      <div className="d-flex align-items-center justify-content-center mb-5">
        <div className="col">
          {itemsSearchValue === "" && (
            <SelectAllItems isAllSelected={isAllSelected} />
          )}
        </div>
        <div className="col">
          {isAllSelected ? (
            <AllActions />
          ) : (
            <TeacherLibrarySearchInput
              query={itemsSearchValue}
              setQuery={setItemsSearchValue}
            />
          )}
        </div>
      </div>
      <StudentUploadSingleItem
        item={{
          id: "test",
          title: "dummy item",
          fullName: "dummy student",
          fileId: "test",
          attachmentType: "test",
        }}
        showItem={showItem}
        checked={false}
        showActions={!isAllSelected}
      />
      {filteredList.map((item) => (
        <StudentUploadSingleItem
          key={item.id}
          item={item}
          showItem={showItem}
          checked={selected.includes(item.id)}
          showActions={!isAllSelected}
        />
      ))}
      {renderEmptyComponent()}
    </AttachmentsListContainer>
  );
};
export default StudentsUploads;
