import { LibraryLabeledCheckboxContainer } from "../../styled-components/teacherLibraryStyledComponents";

const LibraryLabeledCheckbox = ({ id, label, onChange, checked }) => {
  return (
    <LibraryLabeledCheckboxContainer
      className={"d-flex align-items-center"}
    >
      <input
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id={id}
        name={id}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </LibraryLabeledCheckboxContainer>
  );
};
export default LibraryLabeledCheckbox;
