import React from "react";
import RouteContent from "src/components/common/RouteContent";
import ConcertsListWithDetails from "../common/components/concerts/ConcertsListWithDetails";

const StudentsUpcomingConcerts = () => {
  return (
    <RouteContent title="Coming Up Concerts">
      <ConcertsListWithDetails />
    </RouteContent>
  );
};

export default StudentsUpcomingConcerts;
