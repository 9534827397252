import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import CreationBody from "./CreationBody";
import { CloseIcon } from "./styled";

const CreatePackageItemModal = ({
  modalData,
  packageLessonId,
  instrumentId,
  setNumber,
  modalTitle,
  initialLessonDuration, //optional
  initialLessonLocationId, // optional
  isRenewal,
}) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;

  return (
    <CustomModal
      size="lg"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      //   padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>{modalTitle || ""}</h2>
      </CustomModalHeader>
      <CustomModalBody
      //    padding="0px"
      >
        <CreationBody
          packageLessonId={packageLessonId}
          setNumber={setNumber}
          instrumentId={instrumentId}
          initialLessonDuration={initialLessonDuration}
          initialLessonLocationId={initialLessonLocationId}
          isRenewal={isRenewal}
          modalData={modalData}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default CreatePackageItemModal;
