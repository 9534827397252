import { useRef } from "react";

const ChangeProfilePicture = ({ profileImageModalData }) => {
  return (
    <div
      onClick={profileImageModalData.openModal}
      style={{
        zIndex: 9,
        cursor: "pointer",
      }}
      className={`h5 justify-content-center d-flex align-items-center submitbutton`}
    >
      <label
        style={{
          cursor: "pointer",
        }}
        className={`mb-0`}
      >
        Change Picture
      </label>
      <i className="fas fa-cloud-upload-alt mx-2" />
    </div>
  );
};
export default ChangeProfilePicture;
