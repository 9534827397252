import React from "react";
import Select from "react-select";

const SearchSelect = ({
  selectedOption = [],
  options = [],
  onOptionSelect = () => {},
  placeholder,
  name,
  isMulti = false,
  width = "320px",
  minWidth,
  value,
}) => {
  // const defaultValue = options.filter(({ value }) =>
  //   selectedOption.includes(value)
  // );
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: "#f7f7f7",
      color: "#151515",
      zIndex: 3,
    }),

    control: (provided) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: "#681E460D",
      color: "#505050",
      borderRadius: 30,
      border: "2px solid #681E46 !important",
      outline: "none",
      width,
      ...(minWidth && { minWidth }),
    }),

    singleValue: (provided, state) => {
      return { ...provided, backgroundColor: "#e5e5e5" };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        border: "1px solid #777",
      };
    },
  };
  return (
    <Select
      defaultValue={selectedOption}
      isMulti={isMulti}
      name={name}
      value={value}
      options={options}
      styles={customStyles}
      onChange={onOptionSelect}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder || "Select..."}
    />
  );
};
export default SearchSelect;
