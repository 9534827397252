import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const LayoutContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  min-height: 70vh;

  @media (max-width: ${ScreenSize.LG}px) {
    flex-direction: column;
    height: auto;
  }
`;
