import React from "react";
import useNotificationsSettingsData from "src/components/Settings/hooks/useNotificationsSettingsData";
import {
  notificationSettingsScopes,
  notificationSettingsTypes,
} from "src/constants/notificationSettingsEnum";

import SettingsContent from "./SettingsContent";

const AdminsNotifications = () => {
  const hookData = useNotificationsSettingsData({
    scope: notificationSettingsScopes.SCHOOL,
    type: notificationSettingsTypes.INTERNAL,
  });

  return <SettingsContent hookData={hookData} />;
};

export default AdminsNotifications;
