/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { FirebaseContext } from "../../../../Firebase";
import { getDoc, getDocs, query, where } from "firebase/firestore";
import { nextDayAndTime } from "../helperFns";
// import { getNearestLesson} from "../helperFns";
import { parseFirebaseDoc } from "../../../../../utils/getFirebaseDoc";
import {
  checkIfActivityDuringSchoolBreak,
  checkIfCanceledFutureEvents,
  checkIfCanceledRecurringActivity,
  getCombinedPackageSetItems,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getNearestFutureEvent,
  getRecurringEventTimelineObj,
  getTimeDiffInMins,
  isRecurringActivityAbsence,
  mapSummerBreakToCanceledRange,
  updatedMomentNow,
} from "src/utils/helpers";
import { parsePrivateLessonDates } from "src/utils/firebaseDatesParserFns";
import moment from "moment";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useLessonsData = (User) => {
  const firebase = useContext(FirebaseContext);
  const {
    getInstruments,
    getPrograms,
    getPrivateLessonsByStudentsIds,
    getUsersByIds,
    getAbsences,
    getSchoolYears,
    getStudentMakeupLessons,
    getTeachers,
  } = useFirebaseFns();
  const [loadingLessons, setLoadingLessons] = useState(true);
  const [allLessons, setAllLessons] = useState([]);
  const [currentLessonId, setCurrentLessonId] = useState("");

  const currentLesson = useMemo(() => {
    if (!currentLessonId || !allLessons?.length) return;
    return allLessons.find(({ id }) => id === currentLessonId);
  }, [currentLessonId, allLessons]);

  useEffect(async () => {
    if (!Object.values(User || {}).length) return;

    try {
      const [
        instruments,
        programs,
        privateLessons,
        absences,
        schoolYears,
        studentMakeups,
        teachers,
      ] = await Promise.all([
        getInstruments(),
        getPrograms(),
        getPrivateLessonsByStudentsIds([User?.uid]),
        getAbsences(),
        getSchoolYears(),
        getStudentMakeupLessons(User?.uid),
        getTeachers(),
      ]);

      const sortedStudentMakeups = studentMakeups
        // sort ascendingly (oldest absence first)
        .sort((a, b) => {
          const firstDate = a.date?.startDate;
          const secondDate = b.date?.startDate;

          return getTimeDiffInMins(secondDate, firstDate);
        })
        .map((makeup) => {
          const { instrumentId, teacherId } = makeup;
          const instrument = instruments.find(({ id }) => id === instrumentId);
          const teacher = teachers.find(({ id }) => id === teacherId);
          const teacherProgramId = teacher?.instrumentsInfo?.find(
            ({ instrument }) => instrument === instrumentId
          )?.program;
          const program = programs.find(({ id }) => id === teacherProgramId);
          return {
            ...makeup,
            instrument,
            teacher,
            program,
          };
        });

      const lessonsWithExtraData = privateLessons.map((lesson) => {
        const lessonMakeupLessons = sortedStudentMakeups.filter(
          ({ forLessonId }) => forLessonId === lesson.id
        );

        const nextMakeupLesson = getNearestFutureEvent(
          lessonMakeupLessons.map((lsn) => ({
            ...lsn,
            startDate: lsn.date.startDate,
          }))
        );
        if (isPackagePrivateLesson(lesson.type)) {
          const { packageSets, instrumentId } = lesson;
          const combinedPackageItems = getCombinedPackageSetItems(packageSets);

          const instrument = instruments.find(({ id }) => id === instrumentId);
          const packageItemsWithExtraData = combinedPackageItems?.map(
            (packageItem) => {
              const { teacherId } = packageItem || {};
              const teacher = teachers.find(({ id }) => id === teacherId);
              // getting the program based on the teacher's program for the insturment
              const teacherProgramId = teacher?.instrumentsInfo?.find(
                ({ instrument }) => instrument === instrumentId
              )?.program;
              const program = programs.find(
                ({ id }) => id === teacherProgramId
              );

              return {
                ...packageItem,
                teacher,
                program,
              };
            }
          );

          let nextPackageItem = null;
          for (const packageItem of packageItemsWithExtraData) {
            // we check if this package item is not absence or during school break , then we check if this package Item in the loop is closer to today than the existing next package item IN THE FUTURE

            const combinedSchoolBreaks = getCombinedSchoolBreaks(
              schoolYears,
              packageItem.locationId
            );

            const lessonEndTime = moment(packageItem.startDate)
              .add(packageItem.duration, "minutes")
              .toDate();

            const isAbsence = absences.some((absence) => {
              const absenceFound = isRecurringActivityAbsence(
                {
                  start: packageItem.startDate,
                  end: lessonEndTime,
                  id: lesson.id,
                },
                absence,
                { excludeTA: false, excludeGCAbsence: true }
              );

              return absenceFound;
            });
            const isDuringSchoolBreak = combinedSchoolBreaks?.some(
              (schoolBreak) =>
                checkIfActivityDuringSchoolBreak(
                  { start: packageItem.startDate },
                  schoolBreak
                )
            );

            if (isAbsence || isDuringSchoolBreak) {
              continue;
            }

            const diff1 = getTimeDiffInMins(
              updatedMomentNow().toDate(),
              packageItem.startDate
            );
            const diff2 = nextPackageItem
              ? getTimeDiffInMins(
                  updatedMomentNow().toDate(),
                  nextPackageItem?.startDate
                )
              : -1;

            if (diff1 < 0) {
              continue;
            } else if (diff2 < 0) {
              nextPackageItem = packageItem;
            } else if (diff1 < diff2) {
              nextPackageItem = packageItem;
            }
          }
          return {
            ...lesson,
            instrument,
            combinedPackageItems: packageItemsWithExtraData,
            nextPackageItem,
            nextMakeupLesson,
          };
        } else {
          const {
            instrumentId,
            timeline = [],
            withdrawal,
            summerBreaks,
          } = lesson;
          let currentTimeline = getRecurringEventTimelineObj({
            timeline,
            requestDate: updatedMomentNow().toDate(),
            startDateAccessor: "startDate",
            lastDateAccessor: "lastDate",
            withApproximation: true,
          });

          // getting the next day and time without the absences/schoolbreaks or withdrawal
          let nextDate = nextDayAndTime(currentTimeline?.startDate);
          let isValidNextDate = false;
          let loopIndex = 0;
          while (!isValidNextDate && loopIndex < 1000) {
            const isOutsideRange = !moment(nextDate).isBetween(
              currentTimeline?.startDate,
              currentTimeline?.lastDate || moment().add(50, "years").toDate(),
              "minutes",
              "[]"
            );

            if (isOutsideRange) {
              currentTimeline = getRecurringEventTimelineObj({
                timeline,
                requestDate: nextDate,
                startDateAccessor: "startDate",
                lastDateAccessor: "lastDate",
                withApproximation: true,
              });
              nextDate = nextDayAndTime(currentTimeline?.startDate);
            }

            const combinedSchoolBreaks = getCombinedSchoolBreaks(
              schoolYears,
              currentTimeline?.locationId
            );

            const lessonEndTime = moment(nextDate)
              .add(currentTimeline?.duration, "minutes")
              .toDate();

            const isAbsence = absences.some((absence) => {
              const absenceFound = isRecurringActivityAbsence(
                {
                  start: nextDate,
                  end: lessonEndTime,
                  id: lesson.id,
                },
                absence,
                { excludeTA: false, excludeGCAbsence: true }
              );
              return absenceFound;
            });

            const isDuringSchoolBreak = combinedSchoolBreaks?.some(
              (schoolBreak) =>
                checkIfActivityDuringSchoolBreak(
                  { start: nextDate },
                  schoolBreak
                )
            );

            // check if either withdrawn or summer break
            const isCanceled = checkIfCanceledRecurringActivity({
              occuranceDate: nextDate,
              canceledAt: withdrawal?.withdrawalDate,
              canceledDateRanges: summerBreaks?.map((summerBreak) =>
                mapSummerBreakToCanceledRange(summerBreak)
              ),
            });

            if (isAbsence || isDuringSchoolBreak || isCanceled) {
              nextDate = moment(nextDate).add(1, "week").toDate();
              isValidNextDate = false;
            } else {
              isValidNextDate = true;
            }

            loopIndex++;
          }

          const instrument = instruments.find(({ id }) => id === instrumentId);
          const teacher = teachers.find(
            ({ id }) => id === currentTimeline?.teacherId
          );
          // getting the program based on the teacher's program for the insturment
          const teacherProgramId = teacher?.instrumentsInfo?.find(
            ({ instrument }) => instrument === instrumentId
          )?.program;
          const program = programs.find(({ id }) => id === teacherProgramId);

          const currentTimelineWithData = currentTimeline && {
            ...currentTimeline,
            teacher,
            program,
            nextDate: isValidNextDate ? nextDate : null,
          };

          return {
            ...lesson,
            instrument,
            currentTimeline: currentTimelineWithData,
            nextMakeupLesson,
          };
        }
      });
      // console.log({ lessonsWithExtraData });
      setAllLessons(lessonsWithExtraData);
      setCurrentLessonId(lessonsWithExtraData[0]?.id || "");
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLessons(false);
    }
  }, [User?.uid]);
  return {
    allLessons,
    loadingLessons,
    setCurrentLessonId,
    currentLesson,
  };
};

export default useLessonsData;
