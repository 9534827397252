import moment from "moment";
import { getTimeDiffInMins } from "src/utils/helpers";
import _ from "lodash";
import { scheduleTypes } from "src/constants/scheduleTypes";

export const prepareAvailabilities = (
  teacherDaysWithAvailabilitiesData,
  makeupOpeningsWithAvailabilitiesData
) => {
  const teacherDaysTimeChunks =
    teacherDaysWithAvailabilitiesData?.map((teacherDay, i) => {
      const { availableSlotsChunks, location, isVirtualOnly } = teacherDay;
      const flattenedTimeChunks = availableSlotsChunks?.flat();
      return flattenedTimeChunks.map((chunk) => ({
        ...chunk,
        scheduleType: scheduleTypes.teacherDays.code,
        location,
        isVirtualOnly,
      }));
    }) || [];

  const makeupOpeningsTimeChunks =
    makeupOpeningsWithAvailabilitiesData?.map((makeupOpening, i) => {
      const { makeupOpeningsSlotsChunks, location, isVirtualOnly } =
        makeupOpening;
      const flattenedTimeChunks = makeupOpeningsSlotsChunks?.flat();
      return flattenedTimeChunks?.map((chunk) => ({
        ...chunk,
        scheduleType: scheduleTypes.makeupOpenings.code,
        location,
        isVirtualOnly,
      }));
    }) || [];

  // the array that contains all of the combined time slots (sorted ascendingly)
  const finalTimeChunksArray = [
    ...teacherDaysTimeChunks?.flat(),
    ...makeupOpeningsTimeChunks?.flat(),
  ].sort((a, b) => {
    const firstSlotDate = moment(a.startTime, "hh:mm A");
    const secondSlotDate = moment(b.startTime, "hh:mm A");

    return getTimeDiffInMins(secondSlotDate, firstSlotDate);
  });

  // grouping all chunks by location for each teacher
  const chunksGroupedByLocation = _.mapValues(
    _.groupBy(finalTimeChunksArray, "location.id")
  );

  return chunksGroupedByLocation;
};
