import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const LessonNoteContainer = styled.div`
  margin-bottom: 10px;
  box-shadow: 0 6px 15px #00000008;
  padding: 25px;
  border-radius: 40px;
  .note-description {
    max-height: 40px;
    line-height: 20px;
    white-space: pre-wrap;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
  }
`;

export const CustomDatePickerStyled = styled.div`
  width: ${({ width }) => width || "293px"};
  /* width: 100%; */
  margin-top: 10px;
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    padding: 5px 10px;
    border-radius: 20px;
    //background-color:
  }
  .react-date-picker__inputGroup__input:invalid {
    background: none;
  }

  position: relative;
  /* z-index: 1000; */
  @media (max-width: ${ScreenSize.MD}px) {
    & {
      width: 100%;
    }
    & > * {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

export const HomeworkModalContainer = styled.div``;
export const HomeworkNoteContainer = styled.div`
  border-radius: 20px;
  background-color: #681e460d;
  height: ${({ height }) => height || "210px"};
  padding: 20px;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  overflow: auto;
  gap: 10px;
`;

export const HomeworkTextareaContainer = styled.textarea`
  border-radius: 20px;
  background-color: #681e460d;
  height: ${({ height }) => height || "210px"};
  resize: none;
  width: 100%;
  padding: 20px;
  color: #333;
`;
export const NoteAttachmentContainer = styled.div`
  background-color: #681e460d;
  max-width: 50%;
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
    max-width: unset;
  }
`;
export const NoteModalHeader = styled.div`
  min-height: 150px;
  .curve-container {
    height: 120px;
  }

  .close-btn-container {
    top: 31px;
    right: 33px;
    z-index: 3;
  }
  .user-details {
    padding-top: 50px;
    z-index: 2;
    .avatar {
      height: 110px;
      width: 110px;
      img {
        height: 100%;
      }
    }
    .user-name {
    }
  }

  h5 {
    font-weight: 900;
    padding-top: 5px;
    font-size: 26px;
    color: #151515;
  }
  .user-select {
    min-width: 250px;
  }
  button.close {
    padding: 3px 7px;
    font-size: 20px;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    background-color: #681e460d;
    color: #707070;

    &:hover {
      background-color: #5a1a3d1d;
    }

    &:active {
      background-color: #42132d2d;
    }
  }
`;
export const CurveContainer = styled.div`
  /* background-color: #681e46; */
  background-color: #fff;
`;
