import FileUploadPreviewContainer from "./FileUploadPreviewContainer";
import _ from "lodash";
import React from "react";

const FilesPreview = ({ files, onRemove }) => {
  // TODO:: Remove static data
  return (
    <div className="d-flex align-items-center flex-wrap mb-3">
      {_.map(files, (file, index) => (
        <FileUploadPreviewContainer
          key={index}
          onClick={() => {
            onRemove(file);
          }}
          name={file.name}
        />
      ))}
    </div>
  );
};
export default FilesPreview;
