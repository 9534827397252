import { useEffect, useState } from "react";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import store from "src/stores/UserStore";
import { mainPagePadding } from "src/constants/styles";
import { isSuperAdmin } from "src/constants/UserRoleEnum";

import { ScreenSize } from "src/constants/ScreenSizeEnum";
import DesktopRouteHeader from "./DesktopRouteHeader";
import MobileRouteHeader from "./MobileRouteHeader";
import FeatureNotifyModal from "../FeatureNotifyModal";

const RouteContent = (props) => {
  const { width } = useWindowDimensions();
  const { user, isLoggedIn, setUser } = store;
  const [featureNotifyModalOpen, setFeatureNotifyModalOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const { toggleFeatureNotificationSeen, getFeatureNotifications } =
    useFirebaseFns();

  useEffect(() => {
    (async () => {
      if (user?.featureNotificationSeen) return;

      if (
        user?.featureNotification === null ||
        user?.featureNotification === undefined
      ) {
        await toggleFeatureNotificationSeen(user?.uid, true);
        setUser({ ...user, featureNotificationSeen: true });
      }

      if (user.featureNotificationSeen === false) {
        const notifications = await getFeatureNotifications();
        const latestNotification = notifications.sort(
          (a, b) => (b?.createdAt ?? 0) - (a?.createdAt ?? 0)
        )[0];
        setNotification(latestNotification);
      }

      if (
        user?.featureNotificationSeen === false &&
        !isSuperAdmin(user?.role) &&
        isLoggedIn
      ) {
        setFeatureNotifyModalOpen(true);
      }
    })();
  }, []);

  const isDesktopScreen = width > ScreenSize.MD;

  const handleModalClose = async () => {
    await toggleFeatureNotificationSeen(user?.uid, true);
    setUser({ ...user, featureNotificationSeen: true });
    setFeatureNotifyModalOpen((prevState) => !prevState);
  };

  // on mobile screen, sometimes a page won't require padding (Like student schedule page) so the
  // page content actually touches the edges of the screen
  const noPadding = props.childrenWithNoPadding && !isDesktopScreen;
  return (
    <>
      {isDesktopScreen ? (
        <DesktopRouteHeader {...props} />
      ) : (
        <MobileRouteHeader {...props} />
      )}
      <div
        style={{
          padding: noPadding ? "0px" : mainPagePadding,
        }}
      >
        {props.children}
      </div>
      {!isSuperAdmin(user?.role) && (
        <FeatureNotifyModal
          isOpen={featureNotifyModalOpen}
          toggle={handleModalClose}
          user={user}
          title={notification?.title}
          description={notification?.description}
          images={notification?.screenshots}
        />
      )}
    </>
  );
};

export default RouteContent;
