import styled from "styled-components";

export const ClassContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  background: #fff;
  border-radius: 15px;
`;

export const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;

export const ClassInfoButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  height: 40px;
`;
