import React from "react";
import { useContext } from "react";
import { SubmitSpinner } from "src/components/common";
import AddPrivateStudentModal from "src/components/Dashboard/common/components/AddPrivateStudentModal";
import useModal from "src/hooks/useModal";
import { PrimaryButton } from "src/utils/shared/styled";
import { TrialLessonsContext } from "../../../TrialLessons";
import useTrialStudentInfo from "../hooks/useTrialStudentInfo";
import LessonsInfoSection from "./LessonsInfoSection";

import StudentContactInfoSection from "./StudentContactInfoSection";

const TrialStudentInfoBody = ({
  studentIndex,
  showCreateStudentTrialLesson,
  onEditStudentClick,
}) => {
  const privateStudentCreationModalData = useModal();

  const {
    instruments,
    students,
    locations,
    teachers,
    programs,
    onLessonUpdateSuccess,
    loading,
  } = useContext(TrialLessonsContext);

  const currentStudent = students[studentIndex];

  const trialStudentData = useTrialStudentInfo({ student: currentStudent });

  if (loading || trialStudentData.loadingAction) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="p-4 ">
        <StudentContactInfoSection
          onEditStudentClick={onEditStudentClick}
          trialStudent={currentStudent}
        />
      </div>

      <hr className="mb-4 mt-4" />

      {currentStudent?.trialLessons?.length ? (
        <LessonsInfoSection
          trialStudentData={trialStudentData}
          studentIndex={studentIndex}
          showCreateStudentTrialLesson={showCreateStudentTrialLesson}
          openCreatePrivateStudentModal={
            privateStudentCreationModalData.openModal
          }
        />
      ) : null}
      {!trialStudentData.isPrivateStudent && (
        <div className="mt-5 text-center">
          <PrimaryButton
            onClick={privateStudentCreationModalData.openModal}
            width="50%"
          >
            Create Private Student
          </PrimaryButton>
        </div>
      )}
      <div className="mt-5"></div>

      {/* For creating private student from the current trial student */}
      <AddPrivateStudentModal
        isOpen={privateStudentCreationModalData.isModalOpen}
        onAddStudentSuccess={() => {}}
        onClose={() => privateStudentCreationModalData.closeModal()}
        instruments={Object.values(instruments) || []}
        locations={locations || []}
        programs={Object.values(programs) || []}
        teachers={Object.values(teachers) || []}
        providedStudentInitialValues={{
          fullName: currentStudent.name,
          birthday: currentStudent.birthday,

          primaryContactEmail: currentStudent.primaryContactEmail,
          primaryContactName: currentStudent.primaryContactName,
          primaryPhone: currentStudent.primaryPhone,

          secondaryContactEmail: currentStudent.secondaryContactEmail,
          secondaryContactName: currentStudent.secondaryContactName,
          secondPhone: currentStudent.secondPhone,
        }}
      />
    </div>
  );
};

export default TrialStudentInfoBody;
