import styled from "styled-components";

const List = styled.ul`
  list-style: none;
  margin: 12px 0 0 0;
  padding: 0;
`;

const Item = styled.li`
  display: flex;
  gap: 6px;
  align-items: center;

  :not(:first-child) {
    margin-top: 12px;
  }

  > span {
    width: 28px;
    height: 14px;
    background-color: ${({ backgroundcolor }) => backgroundcolor};
    border-radius: 4px;
  }

  p {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;

    color: #151515;
    font-size: 14px;

    span {
      font-weight: 700;
    }

    @media screen and (min-width: 576px) {
      font-size: 16px;
    }
  }
`;

export { List, Item };
