import { FormField, StyledButton, StyledInput } from "./styled";
import { CustomLabel } from "../../../../../utils/styled";

const NewStudentBlock = () => {
  return (
    <>
      <FormField>
        <CustomLabel>Full name</CustomLabel>
        <StyledInput name="name" />
      </FormField>
      <FormField margin>
        <CustomLabel>Email</CustomLabel>
        <StyledInput name="email" type="email" />
      </FormField>
      <FormField margin>
        <CustomLabel>Phone</CustomLabel>
        <StyledInput name="phone" type="tel" />
      </FormField>
      <FormField margin>
        <CustomLabel>Address</CustomLabel>
        <StyledInput name="address" />
      </FormField>
      <FormField margin>
        <CustomLabel>City</CustomLabel>
        <StyledInput name="city" />
      </FormField>
      <FormField margin>
        <CustomLabel>State</CustomLabel>
        <StyledInput name="state" />
      </FormField>
      <FormField margin>
        <CustomLabel>Postal/Zip code</CustomLabel>
        <StyledInput name="zip" />
      </FormField>
      <FormField margin>
        <StyledButton size="large" type="primary">
          Next
        </StyledButton>
      </FormField>
    </>
  );
};

export default NewStudentBlock;
