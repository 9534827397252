export const pageTabs = {
  PENDING: "pendingAbsences",
  RESOLVED: "resolvedAbsences",
};

export const dateFilters = {
  THIS_MONTH: { title: "This Month", value: "this_month" },
  THIS_QUARTER: { title: "This Quarter", value: "this_quarter" },
  THIS_YEAR: { title: "This Year", value: "this_year" },
  CUSTOM: { title: "Custom", value: "custom" },
};

export const SUBMITTED_COLOR = "#d7d700";
export const IN_PROGRESS_COLOR = "#ffa500";
export const DECLINED_COLOR = "#d50000";
export const APPROVED_COLOR = "#008000";
export const PARTIALLY_APPROVED_COLOR = "#00a500";
