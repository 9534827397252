import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const ConcertsListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  @media (max-width: ${ScreenSize.LG}px) {
    flex-direction: row;
  }
`;

export const ConcertListItemContainer = styled.div`
  height: 84px;
  display: flex;
  gap: 20px;
  border-radius: 45px;
  padding: 7px;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  max-width: 900px;
`;

export const ConcertListItemImage = styled.img`
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
`;
export const ConcertListItemInfoContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 4px;
  justify-content: center;
`;
export const ConcertListItemItemHeader = styled.h6`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: bold;
  font-size: 1.2rem;
`;
export const ConcertListItemSpan = styled.span`
  color: #a0a0a0;
  font-size: 0.8rem;
`;
