import { updateDoc } from "firebase/firestore";
import moment from "moment";
import { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { UserRole } from "src/constants/UserRoleEnum";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const updatePrivateLessonNote = async (newNote, lessonId) => {
    try {
      await updateDoc(firebase.PrivateLesson(lessonId), {
        note: newNote,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return {
    updatePrivateLessonNote,
  };
};

export default useFirebaseFns;
