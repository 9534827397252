import RouteContent from "../../../common/RouteContent";
import TopSection from "./components/TopSection";
import TableLayout from "./components/TableLayout";
import useTeacherData from "./hooks/useTeacherData";
import { useModal } from "./hooks/useModal";
import { InitialPageDataContextProvider } from "../../../../contexts/InitialPageDataContext";

const TeachersPage = () => {
  const teacherData = useTeacherData();
  const { loading, refreshData, displayedTeachers } = teacherData;
  const modalData = useModal();

  return (
    <RouteContent title="Teachers">
      {/* Add Btn and Search Bar */}
      <TopSection teacherData={teacherData} modalData={modalData} />
      {/* Table Layout */}
      <TableLayout
        modalData={modalData}
        teachersList={displayedTeachers}
        loadingTeachersList={loading}
        refreshData={refreshData}
      />
    </RouteContent>
  );
};

const TeacherPageWrapper = () => (
  <InitialPageDataContextProvider>
    <TeachersPage />
  </InitialPageDataContextProvider>
);

export default TeacherPageWrapper;
