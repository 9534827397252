import React from "react";
import { CustomInput, CustomLabel } from "src/utils/shared/styled";
import { InputContainer, InputRow } from "../../styled";
import handlePhoneChange from "src/components/Dashboard/common/handlePhoneChange";
import { NumberInput } from "src/utils/shared/shared";

const InputWithLabel = ({ label, ...props }) => (
  <>
    <CustomLabel>{label}:</CustomLabel>
    <CustomInput width={"100%"} {...props} />
  </>
);
const FormSection = ({ handleFormChange, formData }) => {
  return (
    <form onSubmit={() => {}}>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            value={formData.fullName}
            onChange={(e) => handleFormChange("fullName", e.target.value)}
            label="Student Name"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            disabled={true}
            value={formData.systemID}
            onChange={(e) => handleFormChange("systemID", e.target.value)}
            label="Student ID"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            type="date"
            value={formData.birthday}
            onChange={(e) => handleFormChange("birthday", e.target.value)}
            label="Date Of Birth"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            type="date"
            value={formData.enrollmentDate}
            onChange={(e) => handleFormChange("enrollmentDate", e.target.value)}
            label="First Enrollment Date"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            type="email"
            value={formData.primaryEmail}
            onChange={(e) => handleFormChange("primaryEmail", e.target.value)}
            label="Primary Email (For Login)"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            type="email"
            value={formData.primaryContactEmail}
            onChange={(e) =>
              handleFormChange("primaryContactEmail", e.target.value)
            }
            label="Primary Contact Email"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            type="email"
            value={formData.primaryContactName}
            onChange={(e) =>
              handleFormChange("primaryContactName", e.target.value)
            }
            label="Primary Contact Name"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            value={formData.primaryPhone}
            onChange={(e) =>
              handleFormChange(
                "primaryPhone",
                handlePhoneChange(e.target.value)
              )
            }
            label="Primary Phone"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            type="email"
            value={formData.secondaryContactEmail}
            onChange={(e) =>
              handleFormChange("secondaryContactEmail", e.target.value)
            }
            label="2nd Contact Email"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            value={formData.secondaryContactName}
            onChange={(e) =>
              handleFormChange("secondaryContactName", e.target.value)
            }
            label="2nd Contact Name"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            value={formData.secondPhone}
            onChange={(e) =>
              handleFormChange("secondPhone", handlePhoneChange(e.target.value))
            }
            label="2nd Phone"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            value={formData.homeAddress}
            onChange={(e) => handleFormChange("homeAddress", e.target.value)}
            label="Home Address"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <InputWithLabel
            value={formData.city}
            onChange={(e) => handleFormChange("city", e.target.value)}
            label="City"
          />
        </InputContainer>
        <InputContainer>
          <InputWithLabel
            value={formData.state}
            onChange={(e) => handleFormChange("state", e.target.value)}
            label="State"
          />
        </InputContainer>
        <InputContainer>
          <CustomLabel>Zip:</CustomLabel>
          <NumberInput
            max={10000000}
            value={formData.zip}
            onChange={(e) => handleFormChange("zip", e.target.value)}
            label="Zip"
          />
        </InputContainer>
      </InputRow>
    </form>
  );
};

export default FormSection;
