import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  LessonNotesContext,
  NoteAttachmentsContext,
  SubmitHomeworkModalContext,
  TeachersContext,
} from "../../common/Library/common/libraryContext";
import { FirebaseContext } from "../../Firebase";
import LessonNotes from "./LessonNotes";
import Spinner from "../../common/Spinner";
import { getDocs, updateDoc } from "firebase/firestore";
import moment from "moment";
import DatePicker from "react-date-picker";
import "react-calendar/dist/Calendar.css";
import { CustomDatePickerStyled } from "../../common/styled-components/lessonNotesStyledComponents";
import SubmitHomeworkModal from "./submit-homework-modal/SubmitHomeworkModal";
import { toast } from "react-toastify";
import AddLessonNoteAttachmentsModal from "./AddLessonNoteAttachmentsModal";
import {
  calculateFilesSize,
  canUserUploadFileCheck,
  uploadFileWithRandomId,
} from "../../../utils/helpers";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { UserRole } from "src/constants/UserRoleEnum";

const DatepickerWrapper = styled.div`
  margin-bottom: 20px;
`;

const StudentLessonNotes = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const [date, setDate] = useState("");
  const [studentNotes, setStudentNotes] = useState([]);
  const [teachersList, setTeachersList] = useState([]);
  const [showSubmitHomeworkModal, setShowSubmitHomework] = useState({
    isOpen: false,
    noteId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const filteredNotes = useMemo(() => {
    let filtered = studentNotes.filter(({ isDraft }) => !isDraft);

    if (date === "") {
      return filtered;
    }
    filtered = filtered.filter(
      (note) =>
        moment(note.date.toDate()).format("MM-DD-YYYY") ===
        moment(date).format("MM-DD-YYYY")
    );
    return filtered;
  }, [studentNotes, date]);
  const getTeachersList = async () => {
    const teachersDocs = await getDocs(
      firebase.filterUsersByRole(UserRole.TEACHER)
    );
    let teachersArray = [];
    teachersDocs.forEach((teacher) => {
      teachersArray.push({
        id: teacher.id,
        ...teacher.data(),
      });
    });
    setTeachersList(teachersArray);
  };
  const getStudentLessonsNotes = async () => {
    const notes = await getDocs(firebase.getStudentLessonsNotes(user.uid));
    const notesArray = [];
    notes.forEach((note) => {
      notesArray.push({
        ...note.data(),
        id: note.id,
      });
    });
    setStudentNotes(notesArray);
  };
  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([getStudentLessonsNotes(), getTeachersList()]);
    setIsLoading(false);
  }, [user.uid]);
  const saveNewAttachment = async (fileObject) => {
    const { file, attachmentType, recommendationLink } = fileObject;
    const newAttachment = {
      attachmentType,
      isDeleted: false,
    };
    if (attachmentType !== "recommendationLinks") {
      const { downloadUrl, id } = await uploadFileWithRandomId(
        firebase,
        file,
        `lessonNotes/homework/${user.uid}/`
      );
      if (file.type) {
        newAttachment.type = file.type;
      }
      newAttachment.downloadUrl = downloadUrl;
      newAttachment.fileId = id;
      newAttachment.name = file.name;
    } else {
      newAttachment.downloadUrl = recommendationLink;
      newAttachment.type = "link";
    }
    return newAttachment;
  };
  const saveAttachments = async (files) => {
    const attachments = [];
    for (const fileObject of files) {
      const newAttachment = await saveNewAttachment(fileObject);
      attachments.push(newAttachment);
    }
    return attachments;
  };
  const lessonNotesContextValue = {
    isEditable: false,
    showAttachmentsModal: () => {},
    editNote: () => {},
    publishNote: () => {},
    dleteNote: () => {},
    deleteAttachment: () => {},
    notes: filteredNotes,
    userId: user.uid,
    userRole: user.role,
    students: [
      {
        id: user.uid,
        name: user.fullName,
        avatar: user.imageUrl,
      },
    ],
  };
  const submitHomeworkModalContextValue = {
    ...showSubmitHomeworkModal,
    close: () => {
      setShowSubmitHomework({
        noteId: "",
        isOpen: false,
      });
    },
    open: (noteId) => {
      setShowSubmitHomework({
        isOpen: true,
        noteId,
      });
    },
    submitHomework: async (homework) => {
      const { noteId } = showSubmitHomeworkModal;
      setShowSubmitHomework({
        noteId: "",
        isOpen: false,
      });
      const files = homework.attachments?.map(({ file }) => file) || [];
      const filesSize = calculateFilesSize(files);
      const canUpload = canUserUploadFileCheck(user, filesSize);
      console.log({ canUpload, filesSize: filesSize });
      if (!canUpload) {
        toast.error("User has reached maximum storage capacity");
        return;
      }

      const attachments = await saveAttachments(homework.attachments);
      const note = studentNotes.find(({ id }) => id === noteId);
      const homeworkObj = {
        [user.uid]: {
          attachments,
          comment: homework.comment,
          isApproved: false,
        },
        ...note.homework,
      };
      await updateDoc(firebase.getLessonNote(noteId), {
        homework: homeworkObj,
      });
      setStudentNotes((previous) =>
        previous.map((note) => {
          if (note.id === noteId) {
            return {
              ...note,
              homework: { ...note.homework, ...homeworkObj },
            };
          }
          return note;
        })
      );
      setShowSubmitHomework({
        noteId: "",
        isOpen: false,
      });
      toast.success("Homework submitted successfully");
    },
  };

  const teachersContextValue = {
    teachers: teachersList,
  };
  return (
    <div className={"h-100"}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <DatepickerWrapper>
            <CustomDatePickerStyled>
              <DatePicker
                calendarClassName={"bg-white"}
                onChange={setDate}
                format={"MM-dd-y"}
                value={date}
                clearIcon={null}
                locale="en-US"
              />
            </CustomDatePickerStyled>
          </DatepickerWrapper>
          <div className={"d-flex justify-content-between"}>
            <LessonNotesContext.Provider value={lessonNotesContextValue}>
              <SubmitHomeworkModalContext.Provider
                value={submitHomeworkModalContextValue}
              >
                {/* {showSubmitHomeworkModal.isOpen ? (
                  <SubmitHomeworkModal />
                ) : null} */}
                <div className={"flex-grow-1"}>
                  <TeachersContext.Provider value={teachersContextValue}>
                    <LessonNotes />
                  </TeachersContext.Provider>
                </div>
              </SubmitHomeworkModalContext.Provider>
            </LessonNotesContext.Provider>
          </div>
        </>
      )}
    </div>
  );
};
export default StudentLessonNotes;
