import moment from "moment";

const DropdownContent = ({ makeupLessons }) => {
  if (!makeupLessons?.length) {
    return <div></div>;
  }

  return makeupLessons.map((makeup, i) => {
    const { teacher, date, id } = makeup;
    const { startDate, endDate } = date;
    const teacherName = teacher?.fullName;
    const formattedDate = moment(startDate).format("MM/DD/YYYY");
    const formattedTime = `${moment(startDate).format("hh:mm A")} - ${moment(
      endDate
    ).format("hh:mm A")}`;
    return (
      <div
        className="pb-4 p-3"
        key={id}
        style={{ background: "#0a0a0c", color: "#e6e6e6" }}
      >
        <div>
          <p className="mb-0">Teacher: {teacherName}</p>
          <p className="mb-0">Date: {`${formattedDate} ${formattedTime}`}</p>
        </div>
      </div>
    );
  });
};

export default DropdownContent;
