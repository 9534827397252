import CheckStudentIn from "./CheckStudentIn";

const AdminCheckIn = ({user}) => {
    return (
        <div className="d-flex align-items-center justify-content-center p-5">
            <CheckStudentIn/>
        </div>
    )
}
export default AdminCheckIn;
