import { toJS } from "mobx";
import React, { useState } from "react";
import { injectUserStore } from "src/utils/helpers";
import SinglePackageLesson from "./SinglePackageLesson";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";
import MultiplePackageLessonsCreationSection from "../../../MultiplePackageLessonsCreationSection";

const RadioWrapper = styled.label`
  display: inline-block;
  padding: 10px 0;
  margin-right: 25px;
`;

const RadioInput = styled.input`
  display: inline-block;
  margin-right: 10px;
`;

const radioOptions = {
  SINGLE: "single",
  TEN: "ten",
};
const PackagePrivateLessonCreationBody = ({
  modalData,
  studentId,
  providedInitialData,
  UserStore,
}) => {
  const user = toJS(UserStore).user;

  const [selectedType, setSelectedType] = useState(radioOptions.SINGLE);

  const renderCurrentPackageComponent = () => {
    if (selectedType === radioOptions.SINGLE) {
      return (
        <SinglePackageLesson
          modalData={modalData}
          studentId={studentId}
          providedInitialData={providedInitialData}
        />
      );
    } else {
      return (
        <div>
          <MultiplePackageLessonsCreationSection
            modalData={modalData}
            studentId={studentId}
            providedInitialData={providedInitialData}
          />
        </div>
      );
    }
  };
  return (
    <div>
      <div className="mb-4">
        <div>
          <CustomLabel className="me-4">Lesson Frequency:</CustomLabel>
          <RadioWrapper>
            <RadioInput
              type="radio"
              value={radioOptions.SINGLE}
              checked={selectedType === radioOptions.SINGLE}
              onChange={() => {
                setSelectedType(radioOptions.SINGLE);
              }}
            />
            Single Lesson
          </RadioWrapper>
          <RadioWrapper>
            <RadioInput
              type="radio"
              value={radioOptions.TEN}
              checked={selectedType === radioOptions.TEN}
              onChange={() => {
                setSelectedType(radioOptions.TEN);
              }}
            />
            10 Recurring Weeks
          </RadioWrapper>
        </div>
      </div>
      <div>{renderCurrentPackageComponent()}</div>
    </div>
  );
};

export default injectUserStore(PackagePrivateLessonCreationBody);
