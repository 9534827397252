import React, { useState } from "react";
import { adminsPaymentPageTabs } from "../constants";

const useAdminsPayment = () => {
  const [currentPage, setCurrentPage] = useState(
    adminsPaymentPageTabs.CREATE_PAYMENT.value
  );
  return { currentPage, setCurrentPage };
};

export default useAdminsPayment;
