import React from "react";
import { SectionContainer } from "../../styled";

const FreePaymentDetails = () => {
  return (
    <SectionContainer>
      <h5>Free</h5>
      <hr />

      <SectionContainer className="mt-3">
        <p>No payment for free lesson</p>
      </SectionContainer>
    </SectionContainer>
  );
};

export default FreePaymentDetails;
