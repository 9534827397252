import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Ellipse } from "@styled-icons/ionicons-outline/Ellipse";
import moment from "moment";
import { SubmitSpinner } from "../../../../common";
import Calendar from "react-calendar";
import classes from "../styles.module.css";
import { updatedMomentNow } from "src/utils/helpers";

const OuterContainer = styled.div`
  background-color: #681e46;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  margin-bottom: 50px;
`;
const InnerContainer = styled.div`
  background-color: #832357;
  border-radius: 15px;
`;
const DateSection = styled.div`
  background-color: white;
  border-radius: 15px;
  height: 80px;
  width: 75px;
  position: relative;
`;
const ArrRight = styled.div`
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 24px solid white;
  position: absolute;
  right: -17px;
  border-radius: 9px;
`;
const WeatherSection = styled.div``;
const TimeSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
`;
const Elipse = styled(Ellipse)`
  width: 8px;
  height: 10px;
  margin-top: -15px;
  margin-right: -5px;
`;

const DateAndCalendar = () => {
  const [CalenderValue, setCalenderValue] = useState(new Date());

  const [loadingWeather, setLoadingWeather] = useState(false);
  const month = updatedMomentNow().format("MMMM");
  const day = updatedMomentNow().format("DD");
  const hour = updatedMomentNow().format("hh:mm A");

  const [Coords, setCoords] = useState({
    Getlatitude: "",
    Getlongitude: "",
  });
  const [Weather, setWeather] = useState({});
  const getLocation = () => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition((position) => {
        setCoords({
          Getlatitude: position.coords.latitude,
          Getlongitude: position.coords.longitude,
        });
      });
    }
  };
  useEffect(() => getLocation(), []);
  useEffect(async () => {
    setLoadingWeather(true);
    try {
      if (Coords.Getlatitude && Coords.Getlongitude) {
        const WeatherFetch = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?&units=metric&lat=${Coords.Getlatitude}&lon=${Coords.Getlongitude}&appid=${process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY}`
        ).catch((err) => console.log(err));
        const WeatherData = await WeatherFetch.json();
        setWeather({
          Temp: WeatherData.main?.temp,
          IconSrc: WeatherData.weather[0]?.icon,
          TempText: WeatherData.weather[0]?.description,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingWeather(false);
    }
  }, [Coords]);

  const imgUrl = Weather?.IconSrc
    ? `http://openweathermap.org/img/wn/${Weather.IconSrc}.png`
    : null;
  const Temp = Math.floor(Weather.Temp);
  return (
    <>
      <OuterContainer>
        <InnerContainer className="d-flex justify-content-between">
          <DateSection className="d-flex align-items-center justify-content-center">
            <div className={`${classes.ViewDate} text-center`}>
              <h4
                style={{
                  textTransform: "capitalize",
                  fontWeight: 700,
                  paddingBottom: 3,
                }}
              >
                {month}
              </h4>
              <h4 style={{ fontWeight: 500 }}>{day}</h4>
            </div>
            <ArrRight />
          </DateSection>
          <WeatherSection className="d-flex align-items-center justify-content-center text-white">
            {loadingWeather ? (
              <SubmitSpinner
                style={{ width: "2rem", height: "2rem", color: "#fff" }}
              />
            ) : Object.keys(Weather)?.length ? (
              <div
                className={`${classes.ViewWeather} d-flex justify-content-center align-items-center`}
              >
                <img src={imgUrl} alt="weather icon" />
                <h4 style={{ textTransform: "capitalize" }}>
                  <p className="m-0" style={{ fontSize: 12, paddingBottom: 5 }}>
                    {Weather.TempText}
                  </p>
                  {Temp} <Elipse /> C
                </h4>
              </div>
            ) : (
              <span className="w-75 text-center">
                Allow location access to see the weather
              </span>
            )}
          </WeatherSection>
          <TimeSection className={`${classes.ViewTime} text-white`}>
            <h4>{hour}</h4>
          </TimeSection>
        </InnerContainer>
      </OuterContainer>
      <Calendar
        calendarType="US"
        onChange={setCalenderValue}
        value={CalenderValue}
      />
    </>
  );
};

export default DateAndCalendar;
