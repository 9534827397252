import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SubmitSpinner } from "src/components/common";
import {
  DOWNLOAD_AVAILABILITIES_PDF,
  GENERATE_AVAILABILITIES_DOC,
} from "src/constants/ApiEndpoints";
import {
  hasAdminCredentials,
  isParent,
  isStudent,
} from "src/constants/UserRoleEnum";

import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";
import styled from "styled-components";
import { makeupViews } from "../constants";
import NavigationBar from "./NavigationBar";
import RequestedDatePicker from "./RequestedDatePicker";
import TeachersAvailabilitiesGrid from "./TeachersAvailabilitiesGrid";
import TeachersSelectField from "./TeachersSelectField";
import {
  isMakeupTypeMakeupLesson,
  isPaidMakeupTypeMakeupLesson,
  isSubstituteTypeMakeupLesson,
  makeupLessonTypes,
} from "src/constants/makeupLessonsEnum";
import MaxMakeUpsComponent from "./MaxMakeUpsComponent";
import { isApprovedMakeupRequest } from "src/constants/makeupRequestEnum";
import ConfirmationModal from "../../ConfirmationModal";
import useModal from "src/hooks/useModal";

const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 18px;
    height: 16px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }
`;

const MakeUpBody = ({
  makeupModalData,
  makeupData,
  requestedDateData,
  onSuccess,
  user,
  lessonDuration,
  privateLesson,
  currentAbsence,
}) => {
  const confirmationModalData = useModal();
  const absenceDate = currentAbsence.date || currentAbsence.startDate;

  const {
    initialData,
    loadingInitialData,
    // isPolicyAccepted,
    // handlePolicyAcceptanceChange,
    createMakeupLesson,
    loadingCreatingMakeup,
    currentView,
    handleCurrentViewChange,
    teachersViewData,
    dateViewData,
    isVirtualLesson,
    handleIsVirtualLessonChange,
    selectedMakeupLesson,
    makeupLessonType,
    handleMakeupLessonTypeChange,
    reachedMaxMakeups,
  } = makeupData;
  const makeupPolicyDescription = initialData.makeupPolicy?.description;

  async function onConfirmationApprove() {
    await handleCreateMakeupLesson();
  }
  function onConfirmationCancel() {
    confirmationModalData.closeModal();
  }

  const showMakeUpTypeField =
    !isParent(user?.role) &&
    !isStudent(user?.role) &&
    !isPaidMakeupTypeMakeupLesson(makeupLessonType);

  const showMaxMakeupsComponent =
    reachedMaxMakeups &&
    !isApprovedMakeupRequest(initialData.latestMakeupRequest?.status);

  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const handleCreateMakeupLesson = async () => {
    const { success } = await createMakeupLesson();
    if (success) {
      makeupModalData.closeModal();
      onSuccess();
    }
  };

  const handleDownloadAvailabilitiesClick = async () => {
    //  generateWordDocument(availabilitiesTextContent, "Teachers Availabilities");
    try {
      setIsLoadingPdf(true);
      const isDateView = currentView === makeupViews.DATE;

      let body;
      if (isDateView) {
        body = {
          view: currentView,
          teachersWithAvailableTimeSlots:
            dateViewData.teachersWithAvailableTimeSlots,
          lessonDuration: lessonDuration,
          requestedDate: requestedDateData.requestedDate,
        };
      } else {
        body = {
          view: currentView,
          selectedTeacherWithAvailableTimeSlots:
            teachersViewData.selectedTeacherWithAvailableTimeSlots,
          lessonDuration: lessonDuration,
          requestedDate: requestedDateData.requestedDate,
        };
      }

      const { data } = await axios.post(GENERATE_AVAILABILITIES_DOC, body);
      if (data?.success) {
        window.open(DOWNLOAD_AVAILABILITIES_PDF, "_newtab");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  if (loadingInitialData) {
    return (
      <div
        style={{ height: "50vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  return (
    <div>
      {showMaxMakeupsComponent ? (
        <MaxMakeUpsComponent
          closeMakeupModal={makeupModalData.closeModal}
          handleMakeupLessonTypeChange={handleMakeupLessonTypeChange}
          user={user}
          absenceId={currentAbsence?.id}
          lessonId={privateLesson?.id}
          onSuccess={onSuccess}
          latestMakeupRequest={initialData.latestMakeupRequest}
        />
      ) : (
        <>
          <div className="mb-4">
            <NavigationBar
              currentView={currentView}
              handleCurrentViewChange={handleCurrentViewChange}
            />
          </div>
          <div className="mb-4 d-flex justify-content-center flex-wrap align-items-end gap-2">
            {currentView === makeupViews.DATE ? (
              <RequestedDatePicker requestedDateData={requestedDateData} />
            ) : (
              <TeachersSelectField
                teachers={initialData.teachers}
                onChange={teachersViewData.handleSelectedTeacherChange}
                value={teachersViewData.selectedTeacherId}
              />
            )}
            {hasAdminCredentials(user?.role) && (
              <div>
                <PrimaryButtonWithLoading
                  isLoading={isLoadingPdf}
                  onClick={handleDownloadAvailabilitiesClick}
                  style={{ background: "#a501a5" }}
                >
                  Download Availabilities
                </PrimaryButtonWithLoading>
              </div>
            )}
          </div>
          <TeachersAvailabilitiesGrid
            makeupData={makeupData}
            requestedDateData={requestedDateData}
            user={user}
          />
        </>
      )}

      {selectedMakeupLesson && showMakeUpTypeField && (
        <div className="text-center mb-3 mt-5">
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              checked={isMakeupTypeMakeupLesson(makeupLessonType)}
              onChange={() =>
                handleMakeupLessonTypeChange(makeupLessonTypes.MAKEUP)
              }
            />
            Make Up
          </label>
          <label>
            <input
              type="radio"
              className="me-2"
              checked={isSubstituteTypeMakeupLesson(makeupLessonType)}
              onChange={() =>
                handleMakeupLessonTypeChange(makeupLessonTypes.SUBSTITUTE)
              }
            />
            Substitute
          </label>
        </div>
      )}
      {selectedMakeupLesson && !selectedMakeupLesson?.isVirtualOnly && (
        <div className="text-center mb-3 mt-3">
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              checked={!isVirtualLesson}
              // value={false}
              onChange={() => handleIsVirtualLessonChange(false)}
            />
            In Person
          </label>
          <label>
            <input
              type="radio"
              className="me-2"
              checked={isVirtualLesson}
              // value={true}
              onChange={() => handleIsVirtualLessonChange(true)}
            />
            Virtual
          </label>
        </div>
      )}
      {/* <p className="mt-3">Make up lesson is final, It can't be rescheduled</p> */}
      {showMaxMakeupsComponent ? null : (
        <>
          {/* <CheckboxContainer
            className={"mt-4 d-flex align-items-center justify-content-center"}
          >
            <input
              checked={isPolicyAccepted}
              onChange={handlePolicyAcceptanceChange}
              type="checkbox"
              id="policyAccept"
              name="policyAccept"
            />
            <label className="ms-2" for="policyAccept">
              {makeupPolicyDescription ||
                "Make up lesson is final, It can't be rescheduled"}
            </label>
          </CheckboxContainer> */}

          <div className="mt-4 d-flex justify-content-center">
            {selectedMakeupLesson && (
              <div>
                <PrimaryButton onClick={confirmationModalData.openModal}>
                  Next
                </PrimaryButton>
              </div>
            )}
          </div>
        </>
      )}
      {/* MODALS */}
      {confirmationModalData.isModalOpen && (
        <ConfirmationModal
          modalData={confirmationModalData}
          onApprove={onConfirmationApprove}
          onCancel={onConfirmationCancel}
          isSubmitting={loadingCreatingMakeup}
          title="Confirm Make Up Lesson"
          approveBtnText="Book The Lesson"
          cancelBtnText="Cancel"
          withCheckbox={true}
          checkboxDescription={
            makeupPolicyDescription ||
            "Make up lesson is final, It can't be rescheduled"
          }
        />
      )}
    </div>
  );
};

export default MakeUpBody;
