import React from "react";
import BodyContent from "./BodyContent";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";

const SinglePackageLessonCreationModal = ({
  modalData,
  studentId, // only needed for creating new lessons, not new package item for existing lessons
  lessonLocationId,
  lessonInstrumentId,
  lessonDuration,
  shortenTimeSlots,
  onLessonCreateSuccess = () => {},
  isNewLesson,
  packageLessonId,
  itemSet,
}) => {
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          <h2 className="fw-bold">Schedule Package Lesson Date</h2>
        </CustomHeader>
        <CustomBody>
          <BodyContent
            itemSet={itemSet}
            isNewLesson={isNewLesson}
            packageLessonId={packageLessonId}
            onLessonCreateSuccess={onLessonCreateSuccess}
            modalData={modalData}
            studentId={studentId}
            shortenTimeSlots={shortenTimeSlots}
            lessonLocationId={lessonLocationId}
            lessonInstrumentId={lessonInstrumentId}
            lessonDuration={lessonDuration}
          />
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default SinglePackageLessonCreationModal;
