import { UserRole } from "src/constants/UserRoleEnum";

const checkGuestStudentData = (user) => {
  if (
    user?.role === "Guest_Student" &&
    user?.emailVerified &&
    user?.birthday &&
    user?.name &&
    user?.primaryPhone &&
    user?.instruments?.length &&
    user?.package &&
    user?.selectedTeacherId &&
    user?.selectedClassDays &&
    user?.classType
  ) {
    return true;
  }

  return false;
};

const checkTeacherApplicantData = (user) => {
  if (
    user?.role === "Teacher_Applicant" &&
    user?.emailVerified &&
    user?.birthday &&
    user?.name &&
    user?.primaryPhone &&
    user?.instruments?.length &&
    user?.openTeachingDays?.length &&
    user?.employmentType &&
    user?.salaryExpectations &&
    user?.resumeDownloadUrl
  ) {
    return true;
  }

  return false;
};

const checkAdminApplicantData = (user) => {
  if (
    user?.role === "Admin_Applicant" &&
    user?.emailVerified &&
    user?.birthday &&
    user?.name &&
    user?.primaryPhone &&
    user?.employmentType &&
    user?.salaryExpectations &&
    user?.resumeDownloadUrl
  ) {
    return true;
  }

  return false;
};

const checkPartnerApplicantData = (user) => {
  if (
    user?.role === "Partner_Applicant" &&
    user?.emailVerified &&
    user?.birthday &&
    user?.name &&
    user?.primaryPhone &&
    user?.message &&
    user?.selectedLocation
  ) {
    return true;
  }

  return false;
};

const getUserMissingFields = (user) => {
  const defaultFields = [
    { link: "/dashboard/guest-profile", field: !user?.name && "Name" },
    { link: "/dashboard/guest-profile", field: !user?.birthday && "Birthday" },
    {
      link: "/dashboard/guest-profile",
      field: !user?.primaryPhone && "Primary phone",
    },
  ].filter((entry) => entry.field);

  const studentFields = [
    ...defaultFields,
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.selectedTeacherId && "Teacher",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.instruments && "Instruments",
    },
    { link: "/dashboard/guest-profile/2", field: !user?.package && "Packages" },
    {
      link: "/dashboard/guest-profile/2",
      field: user?.package === "regular" && !user?.program && "Program",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.classType && "Class type",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.selectedClassDays && "Class days",
    },
  ].filter((entry) => entry.field);

  const adminFields = [
    ...defaultFields,
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.employmentType && "Employment type",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.salaryExpectations && "Salary expectations",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.resumeDownloadUrl && "Resume",
    },
  ].filter((entry) => entry.field);

  const teacherFields = [
    ...defaultFields,
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.openTeachingDays && "Open teaching days",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.instruments && "Instruments",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.employmentType && "Employment type",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.salaryExpectations && "Salary expectations",
    },
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.resumeDownloadUrl && "Resume",
    },
  ].filter((entry) => entry.field);

  const partnerFields = [
    ...defaultFields,
    {
      link: "/dashboard/guest-profile/2",
      field: !user?.selectedLocation && "Location",
    },
    {
      link: "/dashboard/guest-profile/summary",
      field: !user?.message && "Partnership interest",
    },
  ].filter((entry) => entry.field);

  switch (user?.role) {
    case UserRole.GUEST_STUDENT:
      return studentFields;
    case UserRole.ADMIN_APPLICANT:
      return adminFields;
    case UserRole.TEACHER_APPLICANT:
      return teacherFields;
    case UserRole.PARTNER_APPLICANT:
      return partnerFields;
    default:
      return null;
  }
};

export {
  checkGuestStudentData,
  checkTeacherApplicantData,
  checkAdminApplicantData,
  checkPartnerApplicantData,
  getUserMissingFields,
};
