import React from "react";
import { CustomLabel, CustomSelectField } from "../../styled";

const TeacherSelect = ({
  teachers,
  formInputs,
  handleTeacherChange,
  programs,
}) => {
  return (
    <>
      <CustomLabel className="mb-3">Teacher</CustomLabel>
      <CustomSelectField
        disabled={!formInputs.instrumentId}
        name="teacherId"
        value={formInputs.teacherId}
        onChange={(e) => {
          const selectedOptionElement =
            e.target.options[e.target.selectedIndex];
          const [teacherId, programId] = selectedOptionElement
            .getAttribute("data-value")
            .split("__");

          handleTeacherChange(teacherId, programId);
        }}
        width="100%"
      >
        <option value="" disabled>
          Teacher
        </option>
        {teachers?.map(({ id, fullName, instrumentsInfo }) => {
          const programId = instrumentsInfo?.find(
            ({ instrument }) => instrument === formInputs?.instrumentId
          )?.program;
          const programName = programs?.find(
            ({ id }) => id === programId
          )?.name;
          return (
            <option key={id} value={id} data-value={`${id}__${programId}`}>
              {`${fullName} | ${programName}`}
            </option>
          );
        })}
      </CustomSelectField>
    </>
  );
};

export default TeacherSelect;
