import { useNavigate } from "react-router-dom";
import "./style.css";

const IconWrapper = ({ children, name }) => {
  const navigate = useNavigate();
  const onClickHandler = (e) => {
    e.preventDefault();
    navigate(`/our-team?filter=${name}`);
  };

  return (
    <div className="iconWrapper" onClick={onClickHandler}>
      {children}
    </div>
  );
};

export default IconWrapper;
