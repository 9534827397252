import React from "react";
import { ActionButtonsContainer, DeleteIcon } from "../../styled";

const ActionButtons = ({ openDeleteModalWithCurrentUser, userId }) => {
  const onDeleteButtonClick = () => {
    openDeleteModalWithCurrentUser(userId);
  };

  return (
    <ActionButtonsContainer>
      <div onClick={onDeleteButtonClick}>
        <DeleteIcon />
      </div>
    </ActionButtonsContainer>
  );
};

export default ActionButtons;
