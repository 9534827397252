import moment from "moment";
import { RRule } from "rrule";
import {
  eventsMap,
  isGroupClassEvent,
  isMakeupLessonEvent,
  isOneTimeBreakEvent,
  isPackagePrivateLesson,
  isPrivateLessonEvent,
  isRecurringBreakEvent,
  isRecurringCalendarLabelEvent,
  isTrialLessonEvent,
} from "../../../constants/eventsEnum";
import { isStudent, UserRole } from "../../../constants/UserRoleEnum";
import {
  checkIfActivityDuringSchoolBreak,
  checkIfCanceledRecurringActivity,
  checkIfTimelineObjIsSummerBreakReturn,
  combineArrayToStr,
  getCombinedPackageSetItems,
  getRecurringEventModification,
  getTimeDiffInMins,
  isRecurringActivityAbsence,
  mapSummerBreakToCanceledRange,
  updatedMomentNow,
} from "../../../utils/helpers";
import { schoolEventsMap } from "../../../constants/schoolEventsEnum";
import {
  absenceTypes,
  isAbsenceShownOnCalendar,
  isApprovedAbsence,
  isMissedAbsence,
  isPartiallyApprovedAbsence,
  isPerDurationAbsence,
  isPerLessonAbsence,
} from "src/constants/absenceTypes";
import {
  defaultEventsColor,
  defaultMakeupLessonsColor,
  defaultModifiedPLColor,
  defaultOneTimeBreakColor,
  defaultPackageLessonsColor,
  defaultRecurringBreakColor,
  defaultTrialLessonsColor,
} from "./constants";
import { toast } from "react-toastify";
import { isSubstituteTypeMakeupLesson } from "src/constants/makeupLessonsEnum";

// docs can be private lessons or other recurring events
export const generateRecurringEvents = ({
  docs = [],
  date,
  startDateAccessor,
  lastDateAccessor,
  resourceIdAccessor,
  eventCode,
  instruments = {},
  role = UserRole.SUPER_ADMIN,
  resourceIds,
}) => {
  if (!docs?.length) return [];
  if (
    !date ||
    !startDateAccessor ||
    !lastDateAccessor ||
    !resourceIdAccessor ||
    !eventCode
  ) {
    toast.error("Something went wrong ! (generateRecurringEvents fn)");
    return [];
  }

  let combinedTimelinesAndDocs = [];
  docs.forEach((doc) => {
    const { timeline = [] } = doc;
    timeline.forEach((timelineObj) => {
      const mergedTimelineObj = {
        ...doc,
        currentTimeline: timelineObj,
      };
      combinedTimelinesAndDocs.push(mergedTimelineObj);
    });
  });

  // filter out docs that has currentTimeline startDate after the end of the given date's month and the lastDate is before the start of the month
  const filteredDocs = combinedTimelinesAndDocs.filter(
    ({ currentTimeline }) => {
      const startDate = currentTimeline[startDateAccessor];
      const lastDate =
        currentTimeline[lastDateAccessor] ||
        updatedMomentNow().add(50, "years").toDate();

      const startsBeforeMonthEnd = moment(startDate).isSameOrBefore(
        moment(date).endOf("month").add(1, "week"),
        "date"
      );
      const endsAfterMonthStart = moment(lastDate).isSameOrAfter(
        moment(date).startOf("month").subtract(1, "week"),
        "date"
      );
      const pass = startsBeforeMonthEnd && endsAfterMonthStart;

      return pass;
    }
  );

  const recurringEvents = [];
  for (const doc of filteredDocs) {
    const {
      id,
      timeline = [],
      modifications,
      currentTimeline = {},
      student,
      instrumentId,
      withdrawal,
      summerBreaks,
    } = doc;
    const instrumentName = instruments[instrumentId]?.name;
    // the resourceId (teacherId) can be either teacherId or userId depending on the doc (break or pl)
    // if pl, then teacherId is in the timeline , if break then userId in the main doc obj
    const resourceId =
      currentTimeline[resourceIdAccessor] || doc[resourceIdAccessor];

    // skip the doc if the current timeline has a teacher that is not selected
    // only applicable if resourceIds array was provided, else no skipping
    // this allows us to not show the events on the calendar if the current timeline user isn't included in the resourceIds
    if (resourceIds?.length && !resourceIds.includes(resourceId)) {
      continue;
    }

    const teacher = currentTimeline.teacher || doc.teacher;

    const startDate = currentTimeline[startDateAccessor];
    const lastDate = currentTimeline[lastDateAccessor];

    const ruleStart = getRecurringEventRuleStartDate(date, startDate);
    const ruleEnd = getRecurringEventRuleEndDate(date, lastDate);

    const rule = new RRule({
      freq: RRule.WEEKLY,
      dtstart: ruleStart,
      until: ruleEnd,
      count: 10,
      interval: 1,
    });
    // rule.all() returns an array of js Dates for the recurring event
    const weeklyDatesArray = rule.all();
    weeklyDatesArray.forEach((occuranceStartDate) => {
      // making the event title
      let eventTitle, editedEventColor;
      if (isPrivateLessonEvent(eventCode)) {
        // check if it's the first ever timeline
        const isFirstTimeline = currentTimeline?.id === timeline[0]?.id;

        const isReturnFromSummerBreak = checkIfTimelineObjIsSummerBreakReturn(
          currentTimeline,
          doc.summerBreaks
        );
        // check if it's the first lesson occurance in the current timeline
        const isFirstLessonInCurrentTimeline = currentTimeline
          ? moment(occuranceStartDate).isSame(
              currentTimeline[startDateAccessor],
              "date"
            )
          : false;

        const isLastLessonBeforeSummerBreak = summerBreaks?.some(
          (summerBreak) => {
            return moment(summerBreak.breakStart)
              .subtract(1, "week")
              .isSame(occuranceStartDate, "date");
          }
        );

        if (isReturnFromSummerBreak && isFirstLessonInCurrentTimeline) {
          editedEventColor = "#efae3e";
        } else if (isFirstLessonInCurrentTimeline) {
          editedEventColor = "#8e24aa";
        }
        if (isLastLessonBeforeSummerBreak) {
          editedEventColor = "#02924f";
        }

        // appended at the begining of the title
        const beforeTitleAppend =
          isFirstTimeline && isFirstLessonInCurrentTimeline
            ? "First Lesson - "
            : isReturnFromSummerBreak && isFirstLessonInCurrentTimeline
            ? "First Returning Lesson - "
            : isFirstLessonInCurrentTimeline
            ? "New Change - "
            : isLastLessonBeforeSummerBreak
            ? "Last Lesson Before Break"
            : "";
        // appended at the begining of the title
        const virtualTitleAppend = currentTimeline?.isVirtual
          ? "Virtual Lesson - "
          : "";
        const withdrawalTitleAppend =
          withdrawal &&
          moment(occuranceStartDate).isBetween(
            withdrawal.requestDate,
            withdrawal.withdrawalDate,
            "minutes",
            "[]"
          )
            ? "Withdrawing - "
            : "";

        eventTitle =
          role === UserRole.STUDENT
            ? `${beforeTitleAppend}${instrumentName} | ${teacher?.fullName}`
            : `${withdrawalTitleAppend}${virtualTitleAppend}${beforeTitleAppend}${student?.fullName} (${instrumentName})`;
      } else if (isRecurringBreakEvent(eventCode)) {
        eventTitle = `Break`;
      } else if (isRecurringCalendarLabelEvent(eventCode)) {
        eventTitle = currentTimeline.title;
      } else {
        eventTitle = "Unknown Event Title";
      }

      // building the event obj
      let eventObj;
      const modification = getRecurringEventModification(
        startDate,
        occuranceStartDate,
        modifications,
        currentTimeline?.id
      );

      if (modification && isPrivateLessonEvent(eventCode)) {
        const modifiedOccuranceDate = modification.date;
        const modifiedDuration = parseInt(modification.duration);
        const modifiedTitle = modification.title;
        eventObj = {
          ...doc,
          start: modifiedOccuranceDate,
          end: moment(modifiedOccuranceDate)
            .add(modifiedDuration, "minutes")
            .toDate(),
          ...(modification.instrumentId && {
            modifiedInstrumentId: modification.instrumentId,
          }),
          title: modifiedTitle,
          isModifiedEvent: true,
          eventColor: defaultModifiedPLColor,

          resourceId,
          eventCode,
        };
      } else if (isRecurringCalendarLabelEvent(eventCode)) {
        eventObj = {
          ...doc,
          allDay: true,
          title: currentTimeline.title,
          start: moment(occuranceStartDate).toDate(),
          end: moment(occuranceStartDate).toDate(),
          resourceId: doc.userId,
          eventColor: doc.eventColor,
          eventCode: eventsMap.recurringCalendarLabel.code,
        };
      } else {
        // recurring pls and breaks
        const eventDuration = parseInt(currentTimeline.duration);

        eventObj = {
          ...doc,
          start: occuranceStartDate,
          end: moment(occuranceStartDate)
            .add(eventDuration, "minutes")
            .toDate(),
          title: eventTitle,
          resourceId,
          eventCode,

          // in case of editing the event color (ex: if first lesson in the current timeline)
          ...(editedEventColor && {
            eventColor: editedEventColor,
          }),
        };
      }
      recurringEvents.push(eventObj);
    });
  }

  return recurringEvents;
};

export const getPastYear = (num = 2) => {
  const currentYear = updatedMomentNow().get("year");
  const requestedYear = parseInt(currentYear) - parseInt(num);
  return requestedYear;
};

export const mapTLToEvent = (
  lesson,
  role = UserRole.SUPER_ADMIN,
  instruments = {}
) => {
  const {
    lessonLength,
    date,
    teacherId,
    teacher,
    student,
    instrument,
    calendarTitle,
  } = lesson;
  if (!Object.keys(lesson).length || !date || !lessonLength) return {};
  const eventStartDate = date;
  const eventEndDate = moment(eventStartDate)
    .add(parseInt(lessonLength), "minutes")
    .toDate();

  let eventTitle;

  if (calendarTitle) {
    eventTitle = calendarTitle;
  } else {
    eventTitle =
      role !== UserRole.STUDENT
        ? `Trial Lesson - ${student?.name} (${instruments[instrument]?.name})`
        : `${instruments[instrument]?.name}/${teacher?.fullName}`;
  }

  return {
    ...lesson,
    title: eventTitle,
    start: eventStartDate,
    end: eventEndDate,
    resourceId: teacherId,
    eventCode: eventsMap.trialLesson.code,
  };
};

export const mapMakeupLessonToEvent = (
  lesson,
  role = UserRole.SUPER_ADMIN,
  instruments = {}
) => {
  const {
    date,
    teacherId,
    teacher,
    student,
    instrumentId,
    isVirtual,
    calendarTitle,
    type,
  } = lesson;
  const { lessonLength } = date;
  if (!Object.keys(lesson).length || !date || !lessonLength) return {};
  const eventStartDate = date.startDate;
  const eventEndDate = date.endDate;

  let eventTitle;
  if (calendarTitle) {
    eventTitle = calendarTitle;
  } else {
    // appended at the begining of the title
    const virtualTitleAppend = isVirtual ? "Virtual" : "";
    const typeTitleAppend = isSubstituteTypeMakeupLesson(type)
      ? "SUBSTITUTE"
      : "MAKEUP";
    eventTitle =
      role !== UserRole.STUDENT
        ? `${virtualTitleAppend} ${typeTitleAppend}-${student?.fullName}`
        : `${virtualTitleAppend} ${typeTitleAppend}-${instruments[instrumentId]?.name}/${teacher?.fullName}`;
  }

  return {
    ...lesson,
    title: eventTitle,
    start: eventStartDate,
    end: eventEndDate,
    resourceId: teacherId,
    eventCode: eventsMap.makeupLesson.code,
  };
};

export const mapAppointmentToEvent = (appointment) => {
  const { title, startDate, endDate, teacherId } = appointment;
  if (!Object.keys(appointment).length || !startDate || !endDate) return {};
  return {
    ...appointment,
    title,
    start: startDate,
    end: endDate,
    resourceId: teacherId,
    eventCode: eventsMap.appointment.code,
  };
};
export const mapCalendarLabelToEvent = (label) => {
  if (!Object.keys(label || {}).length || !label.date) return {};

  return {
    ...label,
    allDay: true,
    title: label.title,
    start: moment(label.date).toDate(),
    end: moment(label.date).toDate(),
    resourceId: label.userId,
    eventColor: label.eventColor,
    eventCode: eventsMap.calendarLabel.code,
  };
};

export const mapSchoolBreakToScheduleEvent = (schoolBreakWithTitle) => {
  const { from, to, schoolYearTitle } = schoolBreakWithTitle || {};
  const title = `(${schoolYearTitle || "N/A"}) - School Break`;
  return {
    ...schoolBreakWithTitle,
    title,
    start: from.toDate(),
    end: to.toDate(),
    eventColor: "#681e46",
  };
};

export const mapBreakToEvent = (userBreak) => {
  let { date, duration, type, userId, teacher } = userBreak;
  duration = parseInt(duration);
  type = parseInt(type);
  const start = date;
  const end = moment(date).add(duration, "minutes").toDate();
  const title = `Break`;
  const eventCode = type;
  return {
    ...userBreak,
    start,
    end,
    title,
    resourceId: userId,
    eventCode,
  };
};

export const getFirstDayOfYear = (date, year, day) => {
  const firstDayOfYear = moment(date)
    .set({ year: year, month: 0, date: 1 })
    .weekday(day)
    .toDate();

  return firstDayOfYear;
};

// Removes the recurring iterations that has an absence record in Private lessons except for specific types of absences (SDC/EA)
// for SDC and EA , we dont hide the event, instead we show the event greyed out with diff title
export const excludeAbsenceFromPLs = (
  privateLessonsRecurringEvents = [],
  absences = []
) => {
  if (!privateLessonsRecurringEvents?.length) return [];

  const privateLessonsWithAbsences = privateLessonsRecurringEvents.map((pl) => {
    const lessonAbsence = absences.find((absence) =>
      isRecurringActivityAbsence(
        {
          id: isPackagePrivateLesson(pl.type) ? pl.mainLessonId : pl.id,
          start: pl.start,
          end: pl.end,
        },
        absence,
        { excludeTA: false, excludeGCAbsence: true }
      )
    );

    if (lessonAbsence) {
      const isMA = isMissedAbsence(lessonAbsence.absenceType);
      const absenceType = Object.values(absenceTypes).find(
        ({ code }) => code === lessonAbsence.absenceType
      );
      const absenceAppend = isMA ? absenceType?.name : absenceType?.abbr;
      return {
        ...pl,
        title: `${absenceAppend} - ${pl.title}`,
        eventColor: "#767676",
        isPLAbsenceEvent: true,
        lessonAbsence,
      };
    } else {
      return pl;
    }
  });
  const filteredRecurringEvents = privateLessonsWithAbsences.filter(
    ({ lessonAbsence }) => {
      let pass =
        !lessonAbsence || isAbsenceShownOnCalendar(lessonAbsence?.absenceType);

      return pass;
    }
  );

  return filteredRecurringEvents;
};

// allBreaksEvents includes the recurring breaks and the one time breaks
export const excludeTeacherBreaksFromAbsences = (
  allTeacherBreaksEvents,
  absences
) => {
  if (!allTeacherBreaksEvents?.length) return [];

  if (!absences?.length) return allTeacherBreaksEvents;

  const filteredBreaks = allTeacherBreaksEvents.filter((breakEvent) => {
    const isAbsence = absences.some((absence) =>
      isEventWithinAbsence(breakEvent, absence)
    );
    return !isAbsence;
  });

  return filteredBreaks;
};

// checks if an event lies between duration absence
const isEventWithinAbsence = (event, absence) => {
  if (!absence || !event) return false;

  const { status, absenceBehaviour } = absence;
  const isDuration = isPerDurationAbsence(absenceBehaviour);

  const absenceHasEffect =
    isApprovedAbsence(status) || isPartiallyApprovedAbsence(status);

  // make sure that its either approved or partiallyApproved absence
  if (!absenceHasEffect) {
    return false;
  }

  if (isDuration) {
    return (
      moment(event.start).isBetween(
        absence.startDate,
        absence.endDate,
        "minute",
        "[)"
      ) ||
      moment(event.end).isBetween(
        absence.startDate,
        absence.endDate,
        "minute",
        "(]"
      )
    );
  }
};

// removes the recurring iterations that is within a school event
export const excludeSchoolBreaksFromRecurringEvents = (
  recurringEvents,
  schoolBreaks
) => {
  if (!recurringEvents?.length) return [];
  if (!schoolBreaks?.length) return recurringEvents;

  // hides the lesson if the lesson event start date is between the school break start and end dates
  const filteredRecurringEvents = recurringEvents.filter((event) => {
    let pass = true;
    for (const schoolBreak of schoolBreaks) {
      if (
        checkIfActivityDuringSchoolBreak({ start: event.start }, schoolBreak)
      ) {
        pass = false;
        break;
      }
    }
    return pass;
  });

  return filteredRecurringEvents;
};

function isCompatibleForJoin(firstEvent, secondEvent) {
  const firstCode = firstEvent.eventCode;
  const secondCode = secondEvent.eventCode;

  let compatible = false;
  if (isPrivateLessonEvent(firstCode) && isMakeupLessonEvent(secondCode)) {
    const isForSameLsn = firstEvent.id === secondEvent.forLessonId;
    const isSameTeacher =
      firstEvent.currentTimeline?.teacherId === secondEvent.teacherId;
    compatible = isForSameLsn && isSameTeacher;
  } else if (
    isMakeupLessonEvent(firstCode) &&
    isPrivateLessonEvent(secondCode)
  ) {
    const isForSameLsn = secondEvent.id === firstEvent.forLessonId;
    const isSameTeacher =
      secondEvent.currentTimeline?.teacherId === firstEvent.teacherId;
    compatible = isForSameLsn && isSameTeacher;
  } else if (
    isMakeupLessonEvent(firstCode) &&
    isMakeupLessonEvent(secondCode)
  ) {
    const isForSameLsn = firstEvent.forLessonId === secondEvent.forLessonId;
    const isSameTeacher = firstEvent.teacherId === secondEvent.teacherId;
    compatible = isForSameLsn && isSameTeacher;
  }
  return compatible;
}
function compareArrays(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const joinSimilarEvents = (events) => {
  if (!events?.length) return [];

  let sortedEvents = events.sort((a, b) => getTimeDiffInMins(b.start, a.start));

  // usedIndexes will contain indexes of events that we joined with other events
  let usedIndexes = [];
  const finalArr = [];

  loop1: for (let i = 0; i < sortedEvents.length; i++) {
    if (usedIndexes.includes(i)) {
      continue loop1;
    }

    const firstLoopCurrentEvent = sortedEvents[i];

    const eventsRightAfter = [];
    if (
      isPrivateLessonEvent(firstLoopCurrentEvent.eventCode) ||
      isMakeupLessonEvent(firstLoopCurrentEvent.eventCode)
    ) {
      // starting this loop from the event after our firstLoopCurrentEvent
      loop2: for (let j = i + 1; j < sortedEvents.length; j++) {
        if (usedIndexes.includes(j)) {
          continue loop2;
        }
        const secondLoopCurrentEvent = sortedEvents[j];
        if (!secondLoopCurrentEvent) break loop2; // in case j === sortedEvents.length

        const compareEv = eventsRightAfter.length
          ? eventsRightAfter[eventsRightAfter.length - 1]
          : firstLoopCurrentEvent;
        const isRightAfter = moment(secondLoopCurrentEvent.start).isSame(
          compareEv.end,
          "minutes"
        );

        const areCompatibleEvents = isCompatibleForJoin(
          compareEv,
          secondLoopCurrentEvent
        );
        const canBeJoined = isRightAfter && areCompatibleEvents;
        if (canBeJoined) {
          eventsRightAfter.push(secondLoopCurrentEvent);
          usedIndexes.push(j);
        } else {
          continue loop2;
        }
      }
    }

    const currentEventWithEventsAfter = [
      firstLoopCurrentEvent,
      ...eventsRightAfter,
    ];
    if (!eventsRightAfter.length) {
      finalArr.push(firstLoopCurrentEvent);
    } else {
      const firstEvent = firstLoopCurrentEvent;
      const lastEvent = eventsRightAfter[eventsRightAfter.length - 1];

      let makeupDuration = 0;
      for (const ev of currentEventWithEventsAfter) {
        if (isMakeupLessonEvent(ev.eventCode)) {
          const duration = getTimeDiffInMins(ev.start, ev.end);
          makeupDuration = makeupDuration + duration;
        }
      }

      let modifiedEvent;
      if (isMakeupLessonEvent(firstEvent.eventCode)) {
        const newMakeUpEndDate = lastEvent.end;

        const modifiedEventTitle =
          firstEvent.calendarTitle ||
          `+${makeupDuration} Minutes Makeup - ${firstEvent.student.fullName}`;

        modifiedEvent = {
          ...firstEvent,
          end: newMakeUpEndDate,
          title: modifiedEventTitle,
        };
      } else if (isMakeupLessonEvent(lastEvent.eventCode)) {
        const newMakeUpStartDate = firstEvent.start;

        const modifiedEventTitle =
          lastEvent.calendarTitle ||
          `+${makeupDuration} Minutes Makeup - ${lastEvent.student?.fullName}`;

        modifiedEvent = {
          ...lastEvent,
          start: newMakeUpStartDate,
          title: modifiedEventTitle,
        };
      }
      finalArr.push(modifiedEvent);
    }
  }

  return finalArr;
};

// filters events that start after the end of the "date" 's month
export const filterEventsByEndOfMonth = (date, events) => {
  if (!events?.length) return [];

  return events.filter(({ start }) =>
    moment(date).endOf("month").isAfter(start)
  );
};

// calculates when should a recurring event start
// starts at the start of the given date's month if the db record for the start date is before the date's month , else return the original start date of the event
export const getRecurringEventRuleStartDate = (date, eventStart) => {
  if (!eventStart || !date) return;

  const lessonDay = moment(eventStart).day();

  const ruleStart = moment(eventStart).isBefore(
    moment(date).set({
      date: 1,
      hour: 0,
      minute: 0,
      seconds: 0,
      milliseconds: 0,
    })
  )
    ? moment(eventStart)
        .set({
          year: moment(date).get("year"),
          month: moment(date).get("month"),
          date: 1,
        })
        .weekday(lessonDay)
        .toDate()
    : eventStart;

  return ruleStart;
};
export const getRecurringEventRuleEndDate = (date, timelineLastDate) => {
  if (!date) return;
  // setting the end of the day to the timelineLastDate so any lesson on that day will be considered (shown) on the calendar
  timelineLastDate = timelineLastDate
    ? moment(timelineLastDate).endOf("day")
    : undefined;

  const dateEndOfMonth = moment(date).endOf("month").add(1, "weeks").toDate();

  let lastDate;
  // if we have a timeline lastDate, then show lessons until either the end of this month (if lastDate is after the end of this month) , or until the lastDate if it's in the current month
  if (timelineLastDate) {
    const isLastDateBeforeEndOfMonth = moment(timelineLastDate).isBefore(
      dateEndOfMonth,
      "minute"
    );

    lastDate = isLastDateBeforeEndOfMonth ? timelineLastDate : dateEndOfMonth;
  } else {
    lastDate = dateEndOfMonth;
  }

  return lastDate;
};

export const shareSchoolBreaksAcrossResources = (
  resourceMap,
  breaksEvents = [],
  currentView = "week"
) => {
  if (!resourceMap?.length || currentView === "month") return breaksEvents;
  const resourceMapIds = resourceMap.map(({ resourceId }) => resourceId);

  let totalSharedBreaks = [];

  resourceMapIds.forEach((resourceId) => {
    breaksEvents.forEach((breakEvent) => {
      const resourceBreak = { ...breakEvent, resourceId };
      totalSharedBreaks.push(resourceBreak);
    });
  });
  return totalSharedBreaks;
};

// remove the event starting from the withdrawal date + change color and title of last lesson date
export const filterWithdrawalFromPLs = (privateLessonsRecurringEvents) => {
  if (!privateLessonsRecurringEvents?.length) return [];

  const filteredWithdrawalsFromPLs = privateLessonsRecurringEvents
    .filter(
      (event) =>
        !checkIfCanceledRecurringActivity({
          occuranceDate: event.start,
          canceledAt: event.withdrawal?.withdrawalDate,
        })
    )
    .map((event) => {
      const withdrawalDate = event.withdrawal?.withdrawalDate;
      const isLastLesson = withdrawalDate
        ? moment(event.start).isSame(
            moment(withdrawalDate).subtract(1, "week"),
            "date"
          )
        : false;
      return {
        ...event,
        ...(isLastLesson && { title: `LAST LESSON-${event.title}` }),
        ...(isLastLesson && { eventColor: "#02924f" }),
      };
    });

  return filteredWithdrawalsFromPLs;
};
export const filterSummerBreaksFromPLs = (privateLessonsRecurringEvents) => {
  if (!privateLessonsRecurringEvents?.length) return [];

  const filteredSummerBreaksFromPLs = privateLessonsRecurringEvents.filter(
    (event) =>
      !checkIfCanceledRecurringActivity({
        occuranceDate: event.start,
        canceledDateRanges: event.summerBreaks?.map((summerBreak) =>
          mapSummerBreakToCanceledRange(summerBreak)
        ),
        granularity: "[)",
      })
  );

  return filteredSummerBreaksFromPLs;
};

export const mapPackagePrivateLessonToEvents = ({
  packageLesson,
  selectedTeachers,
  role = UserRole.SUPER_ADMIN,
  instruments = {},
}) => {
  if (!packageLesson || !isPackagePrivateLesson(packageLesson.type)) return [];
  const { instrumentId, student } = packageLesson;
  const packageItemsEvents = getCombinedPackageSetItems(
    packageLesson.packageSets
  )
    // only get package items for selected teachers if provided, if no teachers provided , will sho all items
    .filter(({ teacherId }) =>
      selectedTeachers ? selectedTeachers.includes(teacherId) : true
    )
    .map((packageItem) => {
      const { startDate, duration, teacher, isVirtual, calendarTitle } =
        packageItem;
      const instrument = instruments[instrumentId];
      const instrumentName = instrument?.name;

      let eventTitle;
      if (isStudent(role)) {
        const teacherName = teacher?.fullName;
        eventTitle = `Package ${instrumentName} Lesson | ${teacherName}`;
      } else {
        const studentName = student?.fullName;
        const isVirtualAppend = isVirtual ? "Virtual " : "";
        eventTitle = `${isVirtualAppend}Package Lesson - ${studentName} (${instrumentName})`;
      }
      const start = packageItem.startDate;
      const end = moment(packageItem.startDate)
        .add(packageItem.duration, "minutes")
        .toDate();
      const resourceId = packageItem.teacherId;
      return {
        ...packageLesson,
        ...packageItem,
        mainLessonId: packageLesson.id,
        title: calendarTitle || eventTitle,
        start,
        end,
        resourceId,
        eventCode: eventsMap.privateLesson.code,
      };
    });

  return packageItemsEvents;
};
export const mapGroupClassToEvents = ({
  groupClass,
  selectedTeachers,
  role = UserRole.SUPER_ADMIN,
}) => {
  if (!groupClass) return [];

  const { classes, title: groupClassTitle } = groupClass;

  const classesEvents = classes
    // only get package items for selected teachers if provided, if no teachers provided , will sho all items
    .filter(({ teachersIds }) =>
      selectedTeachers
        ? selectedTeachers.some((selectedTeacherId) =>
            teachersIds.includes(selectedTeacherId)
          )
        : true
    )
    .map((classObj) => {
      const {
        duration,
        startDate,
        locationId,
        locationStatus,
        createdBy,
        createdAt,
        type,
        teachersIds,
      } = classObj;

      const eventTitle = groupClassTitle || "Group Class";

      const start = startDate;
      const end = moment(startDate).add(duration, "minutes").toDate();

      // we can only select 1 resource id so we just pick the first teacher detected in the group class and is selected to be the resourceId
      const resourceId = selectedTeachers?.find((selectedTeacherId) =>
        teachersIds.includes(selectedTeacherId)
      );
      return {
        ...groupClass,
        ...classObj,
        mainLessonId: groupClass.id,
        title: eventTitle,
        start,
        end,
        resourceId,
        eventCode: eventsMap.groupClass.code,
      };
    });

  return classesEvents;
};

// A function that returns an obj with the classes for styling the event
export const eventPropGetter = (
  event,
  start,
  end,
  isSelected,
  groupClassesAbsences
) => {
  const isGC = isGroupClassEvent(event.eventCode);
  const showGCAbsenceMark =
    isGC &&
    groupClassesAbsences?.find((absence) => {
      const isAbsence = isRecurringActivityAbsence(
        {
          start: event.start,
          end: event.end,
          id: event.mainLessonId,
        },
        absence,
        { excludeTA: true, excludeGCAbsence: false }
      );
      return isAbsence;
    });
  const gcAbsenceMarkStyle = showGCAbsenceMark
    ? {
        borderLeft: "7px solid #efef0d",
      }
    : {};

  return {
    style: {
      ...gcAbsenceMarkStyle,
      ...(isPrivateLessonEvent(event.eventCode) && {
        backgroundColor: isPackagePrivateLesson(event.type)
          ? event.eventColor || defaultPackageLessonsColor
          : event.currentTimeline?.teacher?.color || defaultEventsColor,
        color: "#fff",
      }),
      ...(isTrialLessonEvent(event.eventCode) && {
        backgroundColor: event.eventColor || defaultTrialLessonsColor,
        color: "#fff",
      }),
      ...(isMakeupLessonEvent(event.eventCode) && {
        backgroundColor: event.eventColor || defaultMakeupLessonsColor,
        color: "#fff",
      }),
      ...(isOneTimeBreakEvent(event.eventCode) && {
        backgroundColor: defaultOneTimeBreakColor,
        color: "#fff",
      }),
      ...(isRecurringBreakEvent(event.eventCode) && {
        backgroundColor: defaultRecurringBreakColor,
        color: "#fff",
      }),
      ...(event.eventColor && {
        backgroundColor: event.eventColor,
        color: "#fff",
      }),
    },
  };
};

export const isEventWithinDateMonth = (start, end, currentDate) => {
  if (!start || !end || !currentDate) return false;

  const startFilter = moment(currentDate)
    .startOf("month")
    .subtract(10, "days")
    .toDate();
  const endFilter = moment(currentDate).endOf("month").add(10, "days").toDate();

  // we do this so we dont filter out school breaks that can be for multiple days where the start,end wont be within the current month
  const MINUTES_IN_10_DAYS = 14400;
  const isVeryLongEvent = getTimeDiffInMins(start, end) > MINUTES_IN_10_DAYS;

  const passDateFilter =
    moment(start).isBetween(startFilter, endFilter, "date", "[]") ||
    moment(end).isBetween(startFilter, endFilter, "date", "[]");

  const pass = !isVeryLongEvent && passDateFilter;

  return pass;
};
