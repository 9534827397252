import React from "react";
import {
  SUBMITTED_COLOR,
  APPROVED_COLOR,
  DECLINED_COLOR,
  IN_PROGRESS_COLOR,
  PARTIALLY_APPROVED_COLOR,
} from "../../../../../../constants";
import {
  absenceStatuses,
  isApprovedAbsence,
  isDeclinedAbsence,
  isInProgressAbsence,
  isPartiallyApprovedAbsence,
  isPerLessonAbsence,
  isSubmittedAbsence,
} from "src/constants/absenceTypes";
import {
  CustomInput,
  CustomTextarea,
  PrimaryButton,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";
import { InfoText } from "../../../styled";
import PartiallyApprovedSection from "./PartiallyApprovedSection";

const ButtonsContainer = styled.div`
  background: #f1f1f1;
  border-radius: 15px;
  padding: 10px;
`;
const ButtonGroupContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 15px;
`;
const AdminActionsSection = ({ absencesData, absenceDetailsData }) => {
  const {
    initialData: { combinedStudents, instruments },
    currentAbsence,
  } = absencesData;
  const { selectedAction, setSelectedAction, adminNote, setAdminNote } =
    absenceDetailsData;
  const currentAbsenceBehaviour =
    currentAbsence?.absenceBehaviour ||
    currentAbsence?.request?.absenceBehaviour;

  // absence statusses
  const isSubmittedAction = isSubmittedAbsence(selectedAction);
  const isInProgressAction = isInProgressAbsence(selectedAction);
  const isDeclinedAction = isDeclinedAbsence(selectedAction);
  const isApprovedAction = isApprovedAbsence(selectedAction);
  const isPartiallyApprovedAction = isPartiallyApprovedAbsence(selectedAction);

  // absenceBehaviour
  const isPerLesson = isPerLessonAbsence(currentAbsenceBehaviour);

  return (
    <div>
      <ButtonsContainer>
        <ButtonGroupContainer>
          {/* Shows either the submitted status btn or the in progress */}
          <PrimaryButton
            onClick={() => setSelectedAction(absenceStatuses.SUBMITTED)}
            backgroundColor={
              isSubmittedAction ? SUBMITTED_COLOR : "transparent"
            }
            textColor={isSubmittedAction ? "#fff" : SUBMITTED_COLOR}
            padding="5px"
            width="120px"
          >
            Submitted
          </PrimaryButton>
          <PrimaryButton
            onClick={() => setSelectedAction(absenceStatuses.IN_PROGRESS)}
            backgroundColor={
              isInProgressAction ? IN_PROGRESS_COLOR : "transparent"
            }
            textColor={isInProgressAction ? "#fff" : IN_PROGRESS_COLOR}
            padding="5px"
            width="120px"
          >
            In Progress
          </PrimaryButton>
        </ButtonGroupContainer>
        <ButtonGroupContainer>
          <PrimaryButton
            onClick={() => setSelectedAction(absenceStatuses.DECLINED)}
            backgroundColor={isDeclinedAction ? DECLINED_COLOR : "transparent"}
            textColor={isDeclinedAction ? "#fff" : DECLINED_COLOR}
            padding="5px"
            width="120px"
          >
            Decline
          </PrimaryButton>
          <PrimaryButton
            onClick={() => setSelectedAction(absenceStatuses.APPROVED)}
            backgroundColor={isApprovedAction ? APPROVED_COLOR : "transparent"}
            textColor={isApprovedAction ? "#fff" : APPROVED_COLOR}
            padding="5px"
            width="120px"
          >
            Approve
          </PrimaryButton>
          {!isPerLesson && (
            <PrimaryButton
              onClick={() =>
                setSelectedAction(absenceStatuses.PARTIALLY_APPROVED)
              }
              backgroundColor={
                isPartiallyApprovedAction
                  ? PARTIALLY_APPROVED_COLOR
                  : "transparent"
              }
              textColor={
                isPartiallyApprovedAction ? "#fff" : PARTIALLY_APPROVED_COLOR
              }
              padding="5px"
              width="170px"
            >
              Partially Approve
            </PrimaryButton>
          )}
        </ButtonGroupContainer>
      </ButtonsContainer>

      {/* Section that appears if we selected partially approved action */}
      {isPartiallyApprovedAction && (
        <PartiallyApprovedSection
          absenceDetailsData={absenceDetailsData}
          combinedStudents={combinedStudents}
          instruments={instruments}
          currentAbsence={currentAbsence}
        />
      )}
      {isPartiallyApprovedAction || isDeclinedAction ? (
        <div className="mt-4">
          <CustomLabel>Message for the teacher:</CustomLabel>
          <CustomTextarea
            width="100%"
            value={adminNote}
            onChange={(e) => setAdminNote(e.target.value)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AdminActionsSection;
