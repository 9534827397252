import { getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import {
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import {
  parseAbsenceObjDates,
  parseUserActivityDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getAbsences = async () => {
    try {
      const q = query(
        firebase.absences(),
        where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const absencesSnap = await getDocs(q);
      const absences = parseFirebaseDoc(absencesSnap.docs);
      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );

      const absencesWithDates = filteredAbsences.map((absence) =>
        parseAbsenceObjDates(absence)
      );

      return absencesWithDates;
    } catch (err) {
      console.log(err);
    }
  };

  const getUserActivities = async (teacherId) => {
    try {
      const q1 = query(
        firebase.usersActivities(),
        where("userId", "==", teacherId),
        where("isActive", "==", true)
      );
      const q2 = query(
        firebase.usersActivities(),
        where("usersIds", "array-contains", teacherId),
        where("isActive", "==", true)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [docs1, docs2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedActivities = [...docs1, ...docs2];

      const activitiesWithDates = combinedActivities.map((activity) =>
        parseUserActivityDates(activity)
      );

      return activitiesWithDates;
    } catch (err) {
      console.log(err);
    }
  };
  return { getAbsences, getUserActivities };
};

export default useFirebaseFns;
