import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const initialDataInitialValues = {
  locations: [],
};
const useAdminContactInfo = ({ adminUser }) => {
  const { updateUserInfo, getLocations } = useFirebaseFns();

  const [userInfo, setUserInfo] = useState({});
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const handleFieldChange = (name, value) => {
    setUserInfo((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  };
  const saveUserInfo = async () => {
    try {
      if (isLoadingAction) return;

      const {
        id,
        fullName,
        birthday,
        primaryEmail,
        primaryPhone,
        homeAddress,
        city,
        state,
        zip,
        workCondition,
        locationId,
      } = userInfo;

      setIsLoadingAction(true);

      await updateUserInfo(id, {
        fullName: fullName ?? "",
        birthday: birthday ?? "",
        primaryPhone: primaryPhone ?? "",

        homeAddress: homeAddress ?? "",
        workCondition: workCondition ?? "",
        locationId: locationId ?? "",
      });
      toast.success("Updated Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsLoadingAction(false);
    }
  };

  useEffect(() => {
    if (!adminUser) return;
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [locations] = await Promise.all([getLocations()]);

        setInitialData((oldVal) => ({
          ...oldVal,
          locations,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!adminUser) return;
    setUserInfo({ ...adminUser });
  }, []);

  return {
    userInfo,
    isLoadingAction,
    saveUserInfo,
    handleFieldChange,
    initialData,
    loadingInitialData,
  };
};

export default useAdminContactInfo;
