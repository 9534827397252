import React from "react";
import Library from "../../common/Library/Library";

export const Modes = {
  VIEW: "VIEW",
  ADD: "ADD",
};

const TeacherLibrary = ({ user }) => {
  return (
    <Library user={user} isTeacher={true}/>
  );
};

export default TeacherLibrary;
