import React from "react";
import NavSection from "./components/NavSection";
import { FeedSectionMainContainer } from "./styled";
import FeedSectionContextProvider, {
  useFeedSectionContext,
} from "./contexts/FeedSectionContext";
import FeedItems from "./components/FeedItems";
import ViewFeedItemModal from "./modals/ViewFeedItemModal";
import EditFeedItemModal from "./modals/EditFeedItemModal";
import styled from "styled-components";
import { PrimaryButton } from "src/utils/shared/styled";
import { feedItems } from "src/constants/feedItemsEnum";
import { injectUserStore } from "src/utils/helpers";
import { isAdmin } from "src/constants/UserRoleEnum";
import AddNewFeedItemModal from "../AddFeedItemModal";

const FirstContainer = styled.div`
  height: 105px;
  margin-bottom: 20px;
`;
const SecondContainer = styled.div`
  height: calc(100% - 125px);
`;
const FeedItemsSectionContent = ({ user }) => {
  const {
    viewFeedItemModalData,
    editFeedItemModalData,
    currentFeedItem,
    currentMainTab,
    createWorkUpdateModalData,
    refreshData,
  } = useFeedSectionContext();

  const isAdminUser = isAdmin(user?.role);
  const isWorkUpdatesTab = currentMainTab === feedItems.workUpdates.type;
  const showAddWorkUpdateBtn = isAdminUser && isWorkUpdatesTab;

  return (
    <>
      <FeedSectionMainContainer>
        <FirstContainer>
          <NavSection />
        </FirstContainer>
        {showAddWorkUpdateBtn && (
          <div className="text-end mt-4 mb-3 ps-3 pe-3">
            <PrimaryButton
              onClick={createWorkUpdateModalData.openModal}
              padding="10px"
              width="130px"
              fontSize="0.8rem"
            >
              Add Work Update
            </PrimaryButton>
          </div>
        )}
        <SecondContainer>
          <FeedItems />
        </SecondContainer>
      </FeedSectionMainContainer>

      {currentFeedItem && (
        <>
          <ViewFeedItemModal
            modalData={viewFeedItemModalData}
            feedItem={currentFeedItem}
          />
          <EditFeedItemModal
            modalData={editFeedItemModalData}
            feedItem={currentFeedItem}
          />
        </>
      )}

      {/* Only for Admins */}
      {isAdminUser ? (
        <AddNewFeedItemModal
          modalData={createWorkUpdateModalData}
          feedItemType={feedItems.workUpdates.type}
          onCreateSuccess={refreshData}
        />
      ) : null}
    </>
  );
};

const FeedItemsSection = ({ UserStore }) => {
  const user = UserStore.user;
  return (
    <FeedSectionContextProvider>
      <FeedItemsSectionContent user={user} />
    </FeedSectionContextProvider>
  );
};

export default injectUserStore(FeedItemsSection);
