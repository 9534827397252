export const makeupRequestStatuses = {
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
};
const resolvedMakeupRequestsList = [
  makeupRequestStatuses.APPROVED,
  makeupRequestStatuses.DECLINED,
];
export function isPendingMakeupRequest(status) {
  if (!status) return false;

  return makeupRequestStatuses.PENDING === status;
}
export function isApprovedMakeupRequest(status) {
  if (!status) return false;

  return makeupRequestStatuses.APPROVED === status;
}
export function isDeclinedMakeupRequest(status) {
  if (!status) return false;

  return makeupRequestStatuses.DECLINED === status;
}
export function isResolvedMakeupRequest(status) {
  if (!status) return false;

  return resolvedMakeupRequestsList.includes(status);
}
