import { Form, Formik } from "formik";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { compose } from "recompose";
import InputWithLabel from "../shared/InputWithLabel";
import { Container } from "../shared/StyledComponents";
import * as yup from "yup";
import { passwordValidationSchema } from "../../../utils/validations";
import { SubmitSpinner } from "../../common";
import { FirebaseContext } from "../../Firebase";
import UpdateConfirmation from "./UpdateConfirmation";
import InputWrapper from "../shared/InputWrapper";
import { useNavigate } from "react-router";
import logo from "../../../assets/images/logo.png";
import { PrimaryButton } from "src/utils/shared/styled";

const updatePasswordValidationSchema = yup.object().shape({
  password: passwordValidationSchema,
});
const UpdatePassword = ({ UserStore }) => {
  const Navigate = useNavigate();
  const firebase = useContext(FirebaseContext);
  const { email } = UserStore.user || { email: "N/A" };
  const [currentDisplay, setCurrentDisplay] = useState("update-password");

  if (currentDisplay === "confirmation") {
    return (
      <Container className="text-center">
        <UpdateConfirmation />
      </Container>
    );
  }
  return (
    <Container>
      <div className="text-center mb-5">
        <img
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      <h3 className="text-center">{email}</h3>
      <Formik
        initialValues={{
          password: "",
        }}
        validationSchema={updatePasswordValidationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            const { password } = values;
            await firebase.passwordUpdate(password);
            setCurrentDisplay("confirmation");
          } catch (err) {
            console.log(err);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form className="mt-5">
            <InputWrapper>
              <InputWithLabel
                name="password"
                error={errors.password}
                touched={touched.password}
                label="New Password"
                placeholder="Password"
                type="password"
              />
            </InputWrapper>
            <div className="w-75 m-auto">
              <div className=" m-2 mt-5 d-flex flex-wrap justify-content-between mt-4 text-center">
                <PrimaryButton className="mb-2" type="submit" width="245px">
                  {isSubmitting ? (
                    <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                  ) : (
                    "Update Password"
                  )}
                </PrimaryButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default compose(inject("UserStore"), observer)(UpdatePassword);
