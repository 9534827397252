import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { parseUserPaymentDates } from "src/utils/firebaseDatesParserFns";
import { paymentUserTypes } from "../../../constants";

const initialDataInitialValues = {
  users: [],
};
const selectedUserDataInitialValues = {
  userPaymentsList: [],
};
const usePaymentList = () => {
  const { getTeachers, getUserPayments, getAdminsTeam } = useFirebaseFns();

  const [currentUserType, setCurrentUserType] = useState(
    paymentUserTypes.INSTRUCTOR
  );
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserData, setSelectedUserData] = useState(
    selectedUserDataInitialValues
  );
  const [loadingSelectedUserData, setLoadingSelectedUserData] = useState(false);

  const [currentPaymentId, setCurrentPaymentId] = useState("");

  const [refreshUser, setRefreshUser] = useState(0);

  const refreshUserData = () => setRefreshUser((oldVal) => oldVal + 1);

  // fetches the initial page data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setInitialData(initialDataInitialValues);
        setSelectedUserId("");

        let users = [];
        // abort here bec we dont have operation team in the system yet
        if (currentUserType === paymentUserTypes.OPERATION_TEAM) {
          const [adminsTeam] = await Promise.all([getAdminsTeam()]);
          users = adminsTeam;
        } else if (currentUserType === paymentUserTypes.INSTRUCTOR) {
          const [teachers] = await Promise.all([getTeachers()]);
          users = teachers;
        }

        setInitialData((oldVal) => ({
          ...oldVal,
          users,
        }));

        setLoadingInitialData(false);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [currentUserType]);

  // fetches the selected user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!selectedUserId) {
          setSelectedUserData(selectedUserDataInitialValues);
          return;
        }
        setLoadingSelectedUserData(true);

        const [selectedUserPayments] = await Promise.all([
          getUserPayments(selectedUserId, { orderByDate: true }),
        ]);

        setSelectedUserData((oldVal) => ({
          ...oldVal,
          userPaymentsList: selectedUserPayments,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingSelectedUserData(false);
      }
    };
    fetchUserData();
  }, [selectedUserId, refreshUser]);

  useEffect(() => {
    setSelectedUserId("");
  }, [currentUserType]);

  // getting the selected user object
  const selectedUser = useMemo(() => {
    if (!selectedUserId) return;

    const user = initialData.users.find(({ id }) => id === selectedUserId);
    return user;
  }, [initialData, selectedUserId]);

  const currentPaymentObj = useMemo(() => {
    const paymentObj = selectedUserData.userPaymentsList.find(
      ({ id }) => id === currentPaymentId
    );
    return paymentObj;
  }, [currentPaymentId, selectedUserData]);

  return {
    currentUserType,
    setCurrentUserType,
    initialData,
    loadingInitialData,
    selectedUserId,
    setSelectedUserId,
    selectedUser,
    selectedUserData,
    loadingSelectedUserData,
    currentPaymentObj,
    setCurrentPaymentId,
    refreshUserData,
  };
};

export default usePaymentList;
