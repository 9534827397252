import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const usePackageItemData = ({
  initialLessonDuration,
  initialLessonLocationId,
}) => {
  const { getInstruments, getLocations } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    instruments: [],
    locations: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLessonDuration, setSelectedLessonDuration] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const reqs = [getInstruments(), getLocations()];

        const [instruments = [], locations = []] = await Promise.all(reqs);

        setInitialData((oldVal) => ({
          ...oldVal,
          instruments,
          locations,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);
  useEffect(() => {
    if (initialLessonDuration) {
      setSelectedLessonDuration(initialLessonDuration);
    }
    if (initialLessonLocationId) {
      setSelectedLocationId(initialLessonLocationId);
    }
  }, []);

  const canScheduleLesson = selectedLocationId && selectedLessonDuration;

  return {
    initialData,
    loadingInitialData,
    selectedLocationId,
    setSelectedLocationId,
    selectedLessonDuration,
    setSelectedLessonDuration,
    canScheduleLesson,
  };
};
export default usePackageItemData;
