import { useState, useEffect } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useTeachersTableData = () => {
  const [teachers, setTeachers] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const { getTeachers, getInstrumentById, getTeacherProgramById } =
    useFirebaseFns();

  useEffect(() => {
    (async () => {
      try {
        const teachersResponse = await getTeachers();
        const validTeachers = Array.isArray(teachersResponse)
          ? teachersResponse?.filter((teacher) => !teacher?.disableObj)
          : [];
        setTeachers(validTeachers);

        const instrumentsPromises = validTeachers?.map(async (teacher) => {
          const validInstrumentsInfo = Array.isArray(teacher?.instrumentsInfo)
            ? teacher?.instrumentsInfo
            : [];

          const instruments = await Promise.all(
            validInstrumentsInfo?.map(async (info) => {
              const instrument = await getInstrumentById(info?.instrument);
              const program = await getTeacherProgramById(info?.program);
              return {
                instrument,
                program,
              };
            })
          );

          return instruments;
        });

        const teachersInstruments = await Promise.all(instrumentsPromises);
        setInstruments(teachersInstruments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);

  return { teachers, instruments };
};

export default useTeachersTableData;
