import React, { useMemo, useState } from "react";
import RouteContent from "../../../common/RouteContent";
import useLessonsData from "./hooks/useLessonsData";
import AbsenceTable from "./AbsenceTable";
import MakeUpTable from "./MakeUpTable";
import { Container, CustomSelectField, DropdownContainer } from "./styled";
import { SubmitSpinner } from "src/components/common";
import useModal from "./hooks/useModal";
import MakeupModal from "../../common/components/MakeupModal";
import { NavButton } from "src/utils/shared/styled";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import {
  checkIfCanceledRecurringActivity,
  getCombinedPackageSetItems,
  getCombinedSchoolYears,
  getPrivateLessonInfoOnSpecificDate,
  sortSchoolYears,
  updatedMomentNow,
} from "src/utils/helpers";

const tabsMap = {
  absence: {
    tabName: "Absence",
  },
  makeup: {
    tabName: "Make Up",
  },
};

const Absences = ({ user }) => {
  // const user = toJS(UserStore)?.user;
  const {
    initialData,
    loadingInitialData,
    handleSelectedLessonChange,
    selectedAbsence,
    handleSelectedAbsenceChange,
    refreshData,
    selectedLesson,
    selectedLessonAbsences,
    selectedSchoolYearId,
    setSelectedSchoolYearId,
    selectedLessonMakeups,

    hasReachedMaxMakeUps,
  } = useLessonsData(user.uid);

  const makeUpModalData = useModal();

  const [currentTab, setCurrentTab] = useState(tabsMap.absence);

  const openMakeUpModal = (absenceId) => {
    handleSelectedAbsenceChange(absenceId);
    makeUpModalData.openModal();
  };

  const schoolYearLocationId = useMemo(() => {
    if (!selectedLesson) {
      return "";
    }

    let currentLocationId = "";
    if (isPackagePrivateLesson(selectedLesson.type)) {
      currentLocationId = getCombinedPackageSetItems(
        selectedLesson?.packageSets
      )?.[0]?.locationId;
    } else {
      const { locationId } = getPrivateLessonInfoOnSpecificDate({
        date: updatedMomentNow().toDate(),
        privateLesson: selectedLesson,
        withTimelineApproximation: true,
      });

      currentLocationId = locationId;
    }

    return currentLocationId;
  }, [selectedLesson]);

  const limitedSchoolYears = useMemo(() => {
    if (!initialData.schoolYears?.length || !schoolYearLocationId) {
      return [];
    }
    const { prevSchoolYear, currentSchoolYear, nextSchoolYear } =
      getCombinedSchoolYears(initialData.schoolYears, schoolYearLocationId);

    return [prevSchoolYear, currentSchoolYear, nextSchoolYear].filter(
      (el) => el
    );
  }, [initialData, schoolYearLocationId]);

  return (
    <RouteContent title={"Absence/Make Up"}>
      <div>
        <div
          className="d-flex flex-wrap justify-content-between align-items-center mb-4"
          style={{ gap: 15 }}
        >
          <div className="d-flex flex-wrap align-items-center mb-4">
            <div>
              <NavButton
                withRedBar
                className={
                  currentTab.tabName === tabsMap.absence.tabName
                    ? "active"
                    : undefined
                }
                onClick={() => setCurrentTab(tabsMap.absence)}
              >
                {tabsMap.absence.tabName}
              </NavButton>
            </div>
            <div>
              <NavButton
                withRedBar
                className={
                  currentTab.tabName === tabsMap.makeup.tabName
                    ? "active"
                    : undefined
                }
                onClick={() => setCurrentTab(tabsMap.makeup)}
              >
                {tabsMap.makeup.tabName}
              </NavButton>
            </div>
          </div>
          <div>
            <DropdownContainer>
              <CustomSelectField
                value={selectedLesson?.id || ""}
                onChange={handleSelectedLessonChange}
                width={"100%"}
              >
                {initialData.privateLessons.map(
                  ({ withdrawal, instrument, id, type }) => {
                    const isPackageLsn = isPackagePrivateLesson(type);
                    const isWithdrawn = checkIfCanceledRecurringActivity({
                      occuranceDate: updatedMomentNow(),
                      canceledAt: withdrawal?.withdrawalDate,
                    });
                    const packageLsnAppend = isPackageLsn ? " | PACKAGE" : "";
                    const withdrawalAppend = isWithdrawn
                      ? " | Withdrawing"
                      : "";

                    const optionTitle = `${instrument?.name}${packageLsnAppend}${withdrawalAppend}`;
                    return (
                      <option key={id} value={id}>
                        {optionTitle}
                      </option>
                    );
                  }
                )}
              </CustomSelectField>
            </DropdownContainer>
            <DropdownContainer className="mt-3">
              <CustomSelectField
                value={selectedSchoolYearId}
                onChange={(e) => setSelectedSchoolYearId(e.target.value)}
                width={"100%"}
              >
                <option value={""} disabled>
                  Select School Year
                </option>
                {limitedSchoolYears.map((schoolYear) => {
                  const { id, title } = schoolYear;

                  // const currentSchoolYearAppend =
                  //   id === currentSchoolYearInLocation?.id ? "(Current)" : "";
                  return (
                    <option key={id} value={id}>
                      {`${title}`}
                    </option>
                  );
                })}
              </CustomSelectField>
            </DropdownContainer>
          </div>
        </div>
        <div className="">
          {loadingInitialData ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <SubmitSpinner
                style={{ width: "3rem", height: "3rem", color: "#681e46" }}
              />
            </div>
          ) : selectedLesson ? (
            currentTab.tabName === tabsMap.absence.tabName ? (
              <AbsenceTable
                lesson={selectedLesson}
                initialData={initialData}
                hasReachedMaxMakeUps={hasReachedMaxMakeUps}
                openMakeUpModal={openMakeUpModal}
                studentId={user?.uid}
                selectedLessonAbsences={selectedLessonAbsences}
              />
            ) : (
              <MakeUpTable
                absences={initialData.absences}
                selectedLessonMakeups={selectedLessonMakeups}
              />
            )
          ) : null}
        </div>
      </div>
      {makeUpModalData.isModalOpen && selectedLesson && selectedAbsence && (
        <MakeupModal
          // only passing the selected PL absences
          currentAbsence={selectedAbsence}
          makeupModalData={makeUpModalData}
          lesson={selectedLesson}
          onSuccess={refreshData}
          shortenTimeSlots
        />
      )}
    </RouteContent>
  );
};

export default Absences;
