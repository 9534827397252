import moment from "moment";
import {
  isPerLessonAbsence,
  isTeacherAbsence,
} from "src/constants/absenceTypes";
import {
  isActivityOverlappingWithSingleTimeEvent,
  mapDurationOrAllDayAbsenceToActivity,
  prepareTeacherActivities,
} from "src/utils/helpers";

export const validatePackageLessonItem = (
  packageItem,
  packageLessonId,
  userActivities = [],
  absences = []
) => {
  const { startDate: itemStart, duration, teacherId } = packageItem;
  const itemEnd = moment(itemStart).add(parseInt(duration), "minutes").toDate();
  if (moment(itemStart).isSameOrAfter(itemEnd)) {
    return { isValid: false, message: "Start time has to be before end time" };
  }

  if (!userActivities?.length && !absences?.length) return { isValid: true };

  //!?=> we don't want teacher absences to block the time here
  // const teacherAllDayAndDurationTAs = absences?.filter((absence) => {
  //   const isTA = isTeacherAbsence(absence.absenceType);
  //   const isSameTeacher = absence.teacherId === teacherId;
  //   const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
  //   return isTA && isSameTeacher && !isPerLesson;
  // });
  // const absencesActivities = teacherAllDayAndDurationTAs?.map((absence) =>
  //   mapDurationOrAllDayAbsenceToActivity(absence)
  // );

  const preparedActivities = prepareTeacherActivities(
    // [...userActivities, ...absencesActivities],
    userActivities,
    teacherId
  );
  let validationObj = { isValid: true };
  for (const activity of preparedActivities) {
    const isOverlapping = isActivityOverlappingWithSingleTimeEvent({
      eventStartDate: itemStart,
      eventEndDate: itemEnd,
      eventId: packageLessonId,
      teacherId,
      activity,
      absences,
    });
    if (isOverlapping) {
      validationObj = {
        isValid: false,
        message: "The lesson can't be overlapping with another event",
      };
      break;
    }
  }

  return validationObj;
};
