import React, { useEffect, useState } from "react";
import { componentViews } from "../constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import { concertInvitationStatuses } from "src/constants/concertEnum";

const initialDataInitialValues = {
  concert: undefined,
  location: undefined,
};
const useStudentConcertInvitation = ({
  user,
  onSubmit,
  concertId,
  invitationId,
}) => {
  const { getConcertById, getLocationById, updateConcertInvitation } =
    useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentView, setCurrentView] = useState(componentViews.FIRST_PAGE);
  const [isInviteAccepted, setIsInviteAccepted] = useState(false);

  const [loadingAction, setLoadingAction] = useState(false);

  function goToSecondPage() {
    setCurrentView(componentViews.SECOND_PAGE);
  }

  async function acceptInvitation() {
    try {
      if (!isInviteAccepted) throw new Error("Must accept invitation");

      setLoadingAction(true);
      const updateObj = {
        [`usersResponses.${user.uid}`]: {
          createdAt: new Date(),
          status:
            concertInvitationStatuses.TEACHER_RECOMMENDATION_USERS_ACCEPTED,
          message: "",
        },
      };

      await updateConcertInvitation(invitationId, updateObj);
      toast.success("Invitation Accepted!");
      onSubmit();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  async function declineInvitation() {
    try {
      if (isInviteAccepted) throw new Error("Must decline invitation");

      setLoadingAction(true);
      const updateObj = {
        [`usersResponses.${user.uid}`]: {
          createdAt: new Date(),
          status:
            concertInvitationStatuses.TEACHER_RECOMMENDATION_USERS_DECLINED,
          message: "",
        },
      };

      await updateConcertInvitation(invitationId, updateObj);
      toast.success("Invitation Declined!");
      onSubmit();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    if (!concertId) return;
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concert] = await Promise.all([getConcertById(concertId)]);
        const location = await getLocationById(concert.locationId);

        setInitialData({
          concert,
          location,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  return {
    currentView,
    setCurrentView,
    isInviteAccepted,
    setIsInviteAccepted,
    goToSecondPage,
    initialData,
    loadingInitialData,
    acceptInvitation,
    declineInvitation,
    loadingAction,
  };
};

export default useStudentConcertInvitation;
