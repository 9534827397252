import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Container from "../components/Dashboard/Container";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { PasswordReset, SignIn, SignUp } from "../components/Auth";
import {
  UserRole,
  adminCredentialsRolesList,
  isGuest,
} from "../constants/UserRoleEnum";
import Profile from "../components/Dashboard/Profile";
import TeacherLibraryRoute from "../components/Dashboard/TeacherLibrary/TeacherLibraryRoute";
import StudentLibraryRoute from "../components/Dashboard/student/StudentLibrary/StudentLibraryRoute";
import PreviewFile from "../components/Dashboard/PreviewFile/PreviewFile";
import PrivateLessons from "../components/Dashboard/PrivateLessons/PrivateLessons";
import {
  AdminSchedule,
  StudentSchedule,
  TeacherSchedule,
} from "../components/Dashboard/Schedule";
import AuthPageWrapper from "../components/Auth/AuthPageWrapper";
import StudentDashboard from "../components/Dashboard/student/Dashboard";
import PasswordlessLogin from "../components/Auth/Passwordless/PasswordlessLogin";
import UpdatePassword from "../components/Auth/UpdatePassword";
import {
  Dashboard as SuperAdminDashboard,
  RecentPosts,
  TeachersPage,
} from "../components/Dashboard/SuperAdmin";

import TeacherDashboard from "../components/Dashboard/Teacher/Dashboard/TeacherDashboard";
import StudentList from "../components/Dashboard/Teacher/StudentsList/StudentList";

// landing pages imports
import Home from "../components/pages/Home";
import PrivateInstructions from "../components/pages/PrivateInstructions";
import ArtisNews from "../components/pages/ArtisNews";
import NewsPage from "../components/pages/NewsPage";
import ContactUs from "../components/pages/ContactUs";
import OurTeam from "../components/pages/OurTeam";

import TrialLessons from "../components/Dashboard/TrialLessons/TrialLessons";
import { Spinner } from "../components/common";
import AdminLibraryRoute from "../components/Dashboard/AdminLibrary/AdminLibraryRoute";
import TeacherLessonNotesRoute from "../components/Dashboard/LessonNotes/TeacherLessonNotesRoute";
import StudentLessonNotesRoute from "../components/Dashboard/LessonNotes/StudentLessonNotesRoute";
import AdminLessonNotesRoute from "../components/Dashboard/LessonNotes/AdminLessonNotesRoute";
import AdminAttendanceListRoute from "../components/Dashboard/attendance/AdminAttendanceListRoute";
import StudentAttendanceListRoute from "../components/Dashboard/attendance/StudentAttendanceListRoute";
import Absences from "../components/Dashboard/student/Absences";
import StudentQRRoute from "../components/Dashboard/StudentQR/StudentQRRoute";
import AdminCheckInRoute from "../components/Dashboard/check-in/AdminCheckInRoute";
import AdminDashboard from "../components/Dashboard/AdminDashboard/AdminDashboard";
import SchoolDates from "src/components/Dashboard/SuperAdmin/SchoolDates";
import NewSchoolYear from "src/components/Dashboard/SuperAdmin/SchoolDates/SchoolYears/NewSchoolYear";
import AddSchoolYearLocation from "src/components/Dashboard/SuperAdmin/SchoolDates/SchoolYears/AddSchoolYearLocation";
import AbsenceLogs from "src/components/Dashboard/AbsenceLogs";
import TeacherAbsences from "src/components/Dashboard/Teacher/TeacherAbsences";
import TeacherAbsence from "src/components/Dashboard/SuperAdmin/TeacherAbsence";
import { ParentDashboardContext } from "src/contexts/ParentDashboardContext";
import { UserNotificationsContextProvider } from "src/contexts/UserNotificationsContext";
import MakeUpNeeded from "src/components/Dashboard/MakeUpNeeded";
import SchoolPolicies from "src/components/Dashboard/SchoolPolicies";
import ParentsPage from "src/components/Dashboard/ParentsPage";
import { sidebarContentTypes } from "src/constants/sidebarEnum";
import {
  BellNotifications as BellNotificationsSettings,
  EmailNotifications as EmailNotificationsSettings,
} from "src/components/Settings";
import AdminsPage from "src/components/Dashboard/AdminsPage";
import StudentsMakeUp from "src/components/Dashboard/Teacher/StudentsMakeUp";
import StudioUsage from "../components/Dashboard/StudioUsage/StudioUsage";
import TeacherAndStaffDirectory from "src/components/Dashboard/TeacherAndStaffDirectory/TeacherAndStaffDirectory";
import AdminsPayment from "src/components/Dashboard/AdminsPayment";
import MyPayments from "src/components/Dashboard/MyPayments";
import { canManageUsersPayment } from "src/utils/helpers";
import MakeupRequests from "src/components/Dashboard/MakeupRequests";
import GroupClass from "src/components/Dashboard/GroupClass";
import Concerts from "src/components/Dashboard/Concerts";
import TeachersUpcomingConcerts from "src/components/Dashboard/TeachersUpcomingConcerts";
import StudentsUpcomingConcerts from "src/components/Dashboard/StudentsUpcomingConcerts";
import GuestProfile from "src/components/Dashboard/Guest/GuestProfile";
import GuestProcess from "src/components/Dashboard/Guest/GuestProcess";
import EmailConfirm from "src/components/Dashboard/Guest/EmailConfirm";
import GuestProfileSecondPage from "src/components/Dashboard/Guest/GuestProfile/GuestProfileSecondPage";
import GuestProfileThirdPage from "src/components/Dashboard/Guest/GuestProfile/GuestProfileThirdPage";
import Applications from "src/components/Dashboard/Applications";
import OurTeamPersonalPage from "src/components/pages/OurTeam/OurTeamPersonalPage";
import ApplicationsArchive from "src/components/Dashboard/Applications/ApplicationsArchive";
import Feedbacks from "src/components/Dashboard/Feedbacks";
import FeedbacksArchive from "src/components/Dashboard/Feedbacks/FeedbacksArchive";
import FeatureNotifications from "src/components/Dashboard/FeatureNotifications";
import GroupClasses from "src/components/pages/GroupClasses";
import GroupClassSignup from "src/components/pages/GroupClasses/components/GroupClassSignup";

const RoutesContainer = ({ UserStore }) => {
  const [selectedStudent, setSelectedStudent] = useState(UserStore.user);
  const isStudent = UserStore.user?.role === UserRole.STUDENT;
  const isParent = UserStore.user?.role === UserRole.PARENT;
  const isTeacher = UserStore.user?.role === UserRole.TEACHER;
  const isAdmin = UserStore.user?.role === UserRole.ADMIN;
  const haveAccessToAdminsPaymentPage = canManageUsersPayment(UserStore.user);

  const isSuperAdmin = UserStore.user?.role === UserRole.SUPER_ADMIN;
  const hasAdminCredentials = adminCredentialsRolesList.includes(
    UserStore.user?.role
  );
  const { studentsList, selectedStudentIndex } = useContext(
    ParentDashboardContext
  );
  useEffect(() => {
    if (isParent) {
      setSelectedStudent(studentsList?.[selectedStudentIndex] || {});
    }
  }, [UserStore?.role, studentsList, selectedStudentIndex]);

  return (
    <UserNotificationsContextProvider
      userId={UserStore.user?.uid}
      userRole={UserStore.user?.role}
    >
      <Routes>
        {/* routes for the landing pages */}
        <Route path="/" element={<Home />} />
        <Route path="/private-instructions" element={<PrivateInstructions />} />
        <Route path="/group-classes" element={<GroupClasses />} />
        <Route path="/group-classes/signup" element={<GroupClassSignup />} />
        <Route path="/news" element={<ArtisNews />} />
        <Route path="/news/:id" element={<NewsPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/our-team/:id" element={<OurTeamPersonalPage />} />

        <Route element={<AuthPageWrapper />}>
          <Route
            path="/signin"
            element={UserStore.isLoggedIn ? <Navigate to="/" /> : <SignIn />}
          />
          <Route
            path="/signup"
            element={!hasAdminCredentials ? <Navigate to="/" /> : <SignUp />}
          />
          <Route
            path="/passwordless-signin"
            element={
              UserStore.isLoggedIn ? <Navigate to="/" /> : <PasswordlessLogin />
            }
          />
          {UserStore.isLoggedIn && (
            <Route path="/update-password" element={<UpdatePassword />} />
          )}
        </Route>
        <Route
          path="/passwordreset"
          element={
            UserStore.isLoggedIn ? <Navigate to="/" /> : <PasswordReset />
          }
        />
        {UserStore.user ? (
          <>
            {!isGuest(UserStore.user.role) && (
              <Route
                path="/settings/"
                element={
                  <Container
                    sidebarContentType={sidebarContentTypes.SETTINGS}
                  />
                }
              >
                {hasAdminCredentials && (
                  <>
                    <Route
                      exact
                      path={"/settings/"}
                      element={<Navigate to="/settings/email-notifications" />}
                    />
                    <Route
                      exact
                      path={"/settings/email-notifications"}
                      element={
                        <EmailNotificationsSettings user={UserStore.user} />
                      }
                    />
                    <Route
                      exact
                      path={"/settings/bell-notifications"}
                      element={
                        <BellNotificationsSettings user={UserStore.user} />
                      }
                    />
                  </>
                )}
              </Route>
            )}
            <Route
              path="/dashboard/"
              element={
                <Container sidebarContentType={sidebarContentTypes.DASHBOARD} />
              }
            >
              {!isGuest(UserStore.user.role) && (
                <Route
                  path={"/dashboard/profile"}
                  element={
                    <Profile
                      user={UserStore.user}
                      selectedStudent={selectedStudent}
                    />
                  }
                />
              )}
              {(hasAdminCredentials || isTeacher) && (
                <Route
                  path="/dashboard/teacher-and-staff-directory"
                  element={<TeacherAndStaffDirectory />}
                />
              )}
              {isAdmin ? (
                <>
                  <Route
                    exact
                    path={"/dashboard/"}
                    element={<Navigate to="/dashboard/home" />}
                  />
                </>
              ) : null}
              {hasAdminCredentials && (
                <>
                  <Route
                    path="/dashboard/home"
                    element={<AdminDashboard user={UserStore.user} />}
                  />
                  <Route
                    path="/dashboard/applications"
                    element={<Applications />}
                  />
                  <Route
                    path="/dashboard/applications/archive"
                    element={<ApplicationsArchive />}
                  />
                  <Route
                    path={"/dashboard/admin-library"}
                    element={<AdminLibraryRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/schedule"}
                    element={<AdminSchedule />}
                  />
                  <Route
                    path={"/dashboard/private-students"}
                    element={<PrivateLessons />}
                  />
                  <Route
                    path={"/dashboard/check-in"}
                    element={<AdminCheckInRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/lesson-notes"}
                    element={<AdminLessonNotesRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/attendance-list"}
                    element={<AdminAttendanceListRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/trial-lessons"}
                    element={<TrialLessons />}
                  />
                  <Route
                    path="/dashboard/teachers"
                    element={<TeachersPage />}
                  />
                  <Route
                    path="/dashboard/absence-logs"
                    element={<AbsenceLogs />}
                  />
                  <Route
                    path="/dashboard/teacher-absence"
                    element={<TeacherAbsence />}
                  />
                  <Route
                    path="/dashboard/makeup-needed"
                    element={<MakeUpNeeded />}
                  />
                  <Route path="/dashboard/parents" element={<ParentsPage />} />
                  <Route path="/dashboard/admins" element={<AdminsPage />} />
                  <Route
                    path="/dashboard/studio-usage"
                    element={<StudioUsage />}
                  />
                  <Route
                    path={"/dashboard/my-payments"}
                    element={<MyPayments />}
                  />
                  <Route
                    path={"/dashboard/makeup-requests"}
                    element={<MakeupRequests />}
                  />
                  <Route
                    path={"/dashboard/group-classes"}
                    element={<GroupClass />}
                  />
                  <Route path={"/dashboard/concerts"} element={<Concerts />} />
                  {haveAccessToAdminsPaymentPage ? (
                    <Route
                      exact
                      path={"/dashboard/admins-payment"}
                      element={<AdminsPayment />}
                    />
                  ) : null}
                </>
              )}
              {/* Teacher Routes */}
              {isTeacher && (
                <>
                  <Route path={"/dashboard/"} element={<TeacherDashboard />} />
                  <Route
                    path="/dashboard/StudentList"
                    element={<StudentList />}
                  />
                  <Route
                    path={"/dashboard/teacher-library"}
                    element={<TeacherLibraryRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/lesson-notes"}
                    element={<TeacherLessonNotesRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/preview-file"}
                    element={<PreviewFile />}
                  />
                  <Route
                    path={"/dashboard/schedule"}
                    element={<TeacherSchedule />}
                  />
                  <Route
                    path={"/dashboard/teacher-absences"}
                    element={<TeacherAbsences />}
                  />
                  <Route
                    path={"/dashboard/students-makeup"}
                    element={<StudentsMakeUp />}
                  />
                  <Route
                    path={"/dashboard/my-payments"}
                    element={<MyPayments />}
                  />
                  <Route
                    path={"/dashboard/upcoming-concerts"}
                    element={<TeachersUpcomingConcerts />}
                  />
                </>
              )}
              {/* Parent Routes */}
              {isParent ? (
                <>
                  <Route
                    path="/dashboard/"
                    element={<StudentDashboard user={selectedStudent} />}
                  />
                  <Route
                    path={"/dashboard/lesson-notes"}
                    element={<StudentLessonNotesRoute user={selectedStudent} />}
                  />
                  <Route
                    path={"/dashboard/student-library"}
                    element={<StudentLibraryRoute user={selectedStudent} />}
                  />
                  {/* 
                <Route
                  path={"/dashboard/check-in"}
                  element={<StudentCheckInRoute user={UserStore.user} />}
                /> */}

                  <Route
                    path={"/dashboard/attendance-list"}
                    element={
                      <StudentAttendanceListRoute user={selectedStudent} />
                    }
                  />
                  <Route
                    path={"/dashboard/student-code"}
                    element={<StudentQRRoute user={selectedStudent} />}
                  />
                  <Route
                    path={"/dashboard/schedule"}
                    element={<StudentSchedule user={selectedStudent} />}
                  />
                  <Route
                    path={"/dashboard/absences"}
                    element={<Absences user={selectedStudent} />}
                  />
                </>
              ) : null}
              {/* Student Routes */}
              {isStudent && (
                <>
                  <Route
                    path="/dashboard/"
                    element={<StudentDashboard user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/lesson-notes"}
                    element={<StudentLessonNotesRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/student-library"}
                    element={<StudentLibraryRoute user={UserStore.user} />}
                  />
                  {/* 
                <Route
                  path={"/dashboard/check-in"}
                  element={<StudentCheckInRoute user={UserStore.user} />}
                /> */}

                  <Route
                    path={"/dashboard/attendance-list"}
                    element={
                      <StudentAttendanceListRoute user={UserStore.user} />
                    }
                  />
                  <Route
                    path={"/dashboard/student-code"}
                    element={<StudentQRRoute user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/schedule"}
                    element={<StudentSchedule user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/absences"}
                    element={<Absences user={UserStore.user} />}
                  />
                  <Route
                    path={"/dashboard/upcoming-concerts"}
                    element={<StudentsUpcomingConcerts />}
                  />
                </>
              )}

              {/* Super Admin Routes */}
              {isSuperAdmin && (
                <>
                  <Route
                    exact
                    path={"/dashboard/"}
                    element={<Navigate to="/dashboard/super-admin" />}
                  />
                  <Route
                    path="/dashboard/super-admin"
                    element={<SuperAdminDashboard />}
                  />
                  <Route
                    path="/dashboard/applications"
                    element={<Applications />}
                  />
                  <Route
                    path="/dashboard/applications/archive"
                    element={<ApplicationsArchive />}
                  />
                  <Route path="/dashboard/feedbacks" element={<Feedbacks />} />
                  <Route
                    path="/dashboard/feedbacks/archive"
                    element={<FeedbacksArchive />}
                  />

                  <Route
                    path="/dashboard/school-dates"
                    element={<SchoolDates />}
                  />
                  <Route
                    path="/dashboard/new-school-year"
                    element={<NewSchoolYear />}
                  />

                  <Route
                    path="/dashboard/new-school-year-location/:schoolYearId"
                    element={<AddSchoolYearLocation />}
                  />
                  <Route
                    path="/dashboard/recent-posts"
                    element={<RecentPosts />}
                  />
                  <Route
                    path="/dashboard/school-policies"
                    element={<SchoolPolicies />}
                  />
                  <Route
                    path="/dashboard/feature-notifications"
                    element={<FeatureNotifications />}
                  />
                </>
              )}
              {isGuest(UserStore.user.role) && (
                <>
                  <Route
                    exact
                    path="/dashboard/"
                    element={<Navigate to="/dashboard/guest-profile" />}
                  />
                  <Route
                    path="/dashboard/guest-profile"
                    element={<GuestProfile />}
                  />
                  <Route
                    path="/dashboard/guest-profile/2"
                    element={<GuestProfileSecondPage />}
                  />
                  <Route
                    path="/dashboard/guest-profile/summary"
                    element={<GuestProfileThirdPage />}
                  />
                  <Route
                    path="/dashboard/guest-status"
                    element={<GuestProcess />}
                  />
                </>
              )}
            </Route>
            {isGuest(UserStore.user.role) && !UserStore.user.emailVerified && (
              <Route path="/confirm" element={<EmailConfirm />} />
            )}
          </>
        ) : (
          <Route path="/" element={<Home />} />
        )}
        <Route
          path="*"
          element={
            UserStore.isLoading ? (
              <Spinner />
            ) : (
              // <NotFound />
              <Navigate to="/signin" />
            )
          }
        />
      </Routes>
    </UserNotificationsContextProvider>
  );
};
export default compose(inject("UserStore"), observer)(RoutesContainer);
