import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import { modalMap } from "../../utils/constants";
import LessonsInfoBody from "./LessonsInfoBody";

const CustomModal = styled(Modal)`
  min-width: 800px;
  max-width: 800px;
  & .modal-content {
    border: none;
    border-radius: 20px;
  }
`;
const CustomHeader = styled(ModalHeader)`
  margin-bottom: 20px;
  & > h5 {
    display: flex;
    gap: 50px;
    align-items: center;
  }
`;
const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const CustomBody = styled(ModalBody)`
  padding: 50px !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;
`;

const LessonsInfoModal = ({
  modalData,
  teacher,
  refreshData,
  openCurrentTeacherCardModal,
}) => {
  return (
    <CustomModal
      size="md"
      className="min-w-50  text-dark"
      centered
      isOpen={modalData.isModalNameOpen(modalMap.lessonsInfo.name)}
      toggle={modalData.toggleModal}
    >
      <CustomHeader
        close={<CloseIcon onClick={modalData.closeModal} />}
        className="px-5 pb-0 border-bottom-0 "
        toggle={modalData.toggleModal}
      >
        <i
          onClick={() => openCurrentTeacherCardModal(teacher?.id)}
          className="fas fa-arrow-left"
          style={{ cursor: "pointer" }}
        ></i>
        <h4 className="fw-bold fs-3 m-0">Lessons Info</h4>
      </CustomHeader>
      <CustomBody>
        <LessonsInfoBody
          modalData={modalData}
          teacher={teacher}
          refreshData={refreshData}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default LessonsInfoModal;
