import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { trialLessonStatuses } from "src/constants/trialLessonStatuses";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { parseTrialLessonDates } from "src/utils/firebaseDatesParserFns";
import { TrialLessonsContext } from "../../../TrialLessons";

const useTrialStudentInfo = ({ student }) => {
  const { refreshData, loading } = useContext(TrialLessonsContext);
  const {
    updateTrialLesson,
    updateUserActivity,
    getUsersByFullName,
    deleteTrialLessonAndActivity,
  } = useFirebaseFns();

  // parses the trial lessons dates
  const studentLessons =
    student?.trialLessons?.map((tl) => parseTrialLessonDates(tl)) || [];

  /* States */
  const [selectedLessonId, setSelectedLessonId] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);
  // whether the student already exists as a private student of not
  const [isPrivateStudent, setIsPrivateStudent] = useState(false);
  const [followUpNote, setFollowUpNote] = useState("");

  const selectedLesson = useMemo(() => {
    if (!selectedLessonId) {
      return;
    }
    const tl = studentLessons.find(({ id }) => id === selectedLessonId);
    return tl;
  }, [student, selectedLessonId]);

  const handleTrialLessonUpdate = async (propName, value) => {
    try {
      if (loading || loadingAction) {
        return;
      }
      setLoadingAction(true);

      const tlUpdateObj = {
        [propName]: value,
      };
      const res = await updateTrialLesson(selectedLessonId, tlUpdateObj);

      // if we are updating the lesson status, then we also update the activity
      if (propName === "status") {
        const activityUpdateObj = {
          isActive: value === trialLessonStatuses.active.code ? true : false,
        };

        const activityRes = await updateUserActivity(
          selectedLessonId,
          activityUpdateObj
        );
      }

      toast.success("updated successfully");
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingAction(false);
    }
  };
  const handleDeleteTrialLesson = async () => {
    try {
      if (loading || loadingAction) {
        return;
      }
      setLoadingAction(true);

      const res = await deleteTrialLessonAndActivity(
        selectedLessonId,
        selectedLesson.studentId
      );

      toast.success("deleted successfully");
      setSelectedLessonId(studentLessons[0]?.id || "");
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingAction(false);
    }
  };

  useEffect(() => {
    // sets the initial selected lesson as the first lesson of the student's tls
    setSelectedLessonId(studentLessons[0]?.id || "");
  }, []);
  useEffect(() => {
    if (!selectedLesson) return;
    // sets the initial form data
    setFollowUpNote(selectedLesson.followUpNote || "");
    // selectedLessonId is in the dependency arr instead of the selectedLesson as the selectedLesson depends on the selectedLessonId and its better to have str in dep array
  }, [selectedLessonId]);
  useEffect(() => {
    const fetchPrivateStudent = async () => {
      try {
        const user = (await getUsersByFullName(student.name))[0];
        if (user) {
          setIsPrivateStudent(true);
        } else {
          setIsPrivateStudent(false);
        }
      } catch (err) {
        console.log(err);
        toast.error(err);
      }
    };
    fetchPrivateStudent();
  }, []);
  return {
    selectedLesson,
    setSelectedLessonId,
    studentLessons,
    isPrivateStudent,
    loadingAction,
    handleTrialLessonUpdate,
    followUpNote,
    setFollowUpNote,
    handleDeleteTrialLesson,
  };
};

export default useTrialStudentInfo;
