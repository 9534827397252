import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isDeclinedAbsence } from "src/constants/absenceTypes";
import {
  isPendingMakeupRequest,
  isDeclinedMakeupRequest,
  makeupRequestStatuses,
} from "src/constants/makeupRequestEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";

const initialDataInitialValues = {
  student: null,
  teacher: null,
  privateLesson: null,
  instrument: null,
};

const useMakeupRequestInfo = ({
  makeupRequest,
  closeModal,
  onSuccess = () => {},
  user,
}) => {
  const {
    getPrivateLessonsByIds,
    getInstrumentById,
    getUsersByIds,
    updateMakeupRequest,
    updateAbsence,
    getAbsencesByIds,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [selectedAction, setSelectedAction] = useState(
    makeupRequestStatuses.PENDING
  );
  const [reason, setReason] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);

  async function submitMakeupRequest() {
    if (!makeupRequest) return;

    try {
      setLoadingAction(true);

      const isPending = isPendingMakeupRequest(selectedAction);
      const isDeclined = isDeclinedMakeupRequest(selectedAction);
      if (isDeclined && !reason) {
        toast.warn("please add the reason for decline");
        return;
      }

      const resolveObj = isPending
        ? null
        : {
            userId: user.uid,
            userRole: user.role,
            resolvedAt: new Date(),
            ...(isDeclined && {
              reason,
            }),
          };
      const updateObj = {
        status: selectedAction,
        ...(resolveObj && { resolve: resolveObj }),
      };
      console.log({ updateObj });

      await updateMakeupRequest(makeupRequest.id, updateObj);
      if (isDeclined) {
        const [absence] = await getAbsencesByIds([makeupRequest.forAbsenceId]);
        const absenceComment = absence?.comment;
        const updatedAbsenceComment = `${absenceComment}\n${reason}`;
        await updateAbsence(makeupRequest.forAbsenceId, {
          comment: updatedAbsenceComment,
          updatedBy: user.uid,
        });
      }
      toast.success("Confirmed !");
      closeModal();
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    if (!makeupRequest) return;

    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [privateLessons] = await Promise.all([
          getPrivateLessonsByIds([makeupRequest.forLessonId]),
        ]);
        const privateLesson = privateLessons[0];
        if (!privateLesson) return;

        const lessonCurrentInfo = getPrivateLessonInfoOnSpecificDate({
          date: updatedMomentNow(),
          privateLesson,
          withTimelineApproximation: true,
        });

        const [studentAndTeacher, instrument] = await Promise.all([
          getUsersByIds([
            privateLesson.studentId,
            lessonCurrentInfo?.teacherId,
          ]),
          getInstrumentById(privateLesson.instrumentId),
        ]);
        const student = studentAndTeacher.find(
          ({ id }) => id === privateLesson.studentId
        );
        const teacher = studentAndTeacher.find(
          ({ id }) => id === lessonCurrentInfo?.teacherId
        );

        setInitialData((oldVal) => ({
          ...oldVal,
          teacher,
          student,
          instrument,
          privateLesson,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(true);
      }
    };
    fetchInitialData();
  }, []);

  return {
    initialData,
    loadingInitialData,
    selectedAction,
    setSelectedAction,
    reason,
    setReason,
    submitMakeupRequest,
    loadingAction,
  };
};

export default useMakeupRequestInfo;
