import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  isActiveTeacher,
  isDisabledTeacherDay,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  attachActivitiesToTeachers,
  attachAvailableDaysToTeachers,
  attachAvailableTimeSlotstoTeacher,
  checkIfTeacherHasAtLeastOneAvailability,
  filterTeachersByInstrument,
  getDatesBetweenTwoDates,
} from "./helperFns";

const useTeachersAvailabilities = ({
  locationId,
  lessonDuration,
  instrumentId,
  user,
  shortenTimeSlots = false,
  includeMakeupOpeningsAvailabilities = true,
  groupBy = {
    date: {
      requestedDate: null,
    },
    teacher: {
      teacherId: null,
      maxDateValue: null,
    },
  },
}) => {
  const {
    getLocations,
    getTeachers,
    getTeachersActivities,
    getAbsences,
    getTeachersMakeUpOpenings,
    getTeachersAvailableDays,
    getSchoolYears,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    teachers: [],
    absences: [],
    schoolYears: [],
    locations: [],
    teachersAvailableDays: [],
    teachersActivities: [],
    schoolYears: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);
        const requests = [
          getLocations(),
          getTeachers(),
          getTeachersAvailableDays(),
          includeMakeupOpeningsAvailabilities
            ? getTeachersMakeUpOpenings()
            : [],
          getTeachersActivities(),
          getAbsences(),
          getSchoolYears(),
        ];
        const [
          locations,
          teachers,
          teachersAvailableDays,
          teachersMakeUpOpenings,
          teachersActivities,
          absences,
          schoolYears,
        ] = await Promise.all(requests);

        // filters teacher days that are disabled for the user role
        const filteredTeacherDays = teachersAvailableDays.map((day) => ({
          ...day,
          availableDays: day.availableDays?.filter(
            (availableDay) => !isDisabledTeacherDay(user?.role, availableDay)
          ),
        }));

        const activeTeachers = teachers.filter((teacher) =>
          isActiveTeacher(teacher)
        );
        const teachersFilteredByInstrument = filterTeachersByInstrument(
          activeTeachers,
          instrumentId
        );
        // adds teacherDays to the teacher including the location for each teacherDay
        // filters the teacherDays and makeupOpenings based on the pl location
        const teachersWithAvailableDays = attachAvailableDaysToTeachers(
          teachersFilteredByInstrument,
          filteredTeacherDays,
          locations,
          locationId,
          teachersMakeUpOpenings
        );
        const teachersWithActivities = attachActivitiesToTeachers(
          teachersWithAvailableDays,
          teachersActivities
        );

        setInitialData((oldVal) => ({
          ...oldVal,
          locations,
          teachers: teachersWithActivities,
          teachersAvailableDays: filteredTeacherDays,
          teachersActivities,
          absences,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [instrumentId, locationId, user.uid]);

  // current school year based on the lesson locationId
  const currentSchoolYearInLocation = useMemo(() => {
    if (!initialData.schoolYears?.length) return;
    return getCurrentSchoolYear(initialData.schoolYears, locationId);
  }, [initialData.schoolYears, locationId]);

  const combinedSchoolBreaks = useMemo(() => {
    return getCombinedSchoolBreaks(initialData.schoolYears, locationId);
  }, [initialData, locationId]);

  // Dates View => all teachers with the availabile slots attached on the request date
  const teachersWithAvailableTimeSlots = useMemo(() => {
    if (!groupBy.date || !groupBy.date.requestedDate) return [];
    // adds available time slots to teachers
    // also filters the teachers that dont have time slots
    const teachersWithAvailableTimeSlots = initialData.teachers
      .map((teacher) => {
        const {
          teacherDaysWithAvailabilitiesData,
          makeupOpeningsWithAvailabilitiesData,
        } = attachAvailableTimeSlotstoTeacher(
          teacher,
          lessonDuration,
          groupBy.date.requestedDate,
          combinedSchoolBreaks,
          initialData.absences,
          shortenTimeSlots
        );

        return {
          ...teacher,
          teacherDaysWithAvailabilitiesData,
          makeupOpeningsWithAvailabilitiesData,
        };
      })
      // filter out teachers that has (no teacher days avail and no makeup avail) (if there is no at least 1 available time slots chunk in either the teacherdays avails or makeup openings avails)
      .filter((teacher) =>
        checkIfTeacherHasAtLeastOneAvailability(
          teacher.teacherDaysWithAvailabilitiesData,
          teacher.makeupOpeningsWithAvailabilitiesData
        )
      );
    // .reduce((acc, cur) => {
    //   acc[cur.id] = cur;
    //   return acc;
    // }, {});

    return teachersWithAvailableTimeSlots;
  }, [
    groupBy,
    initialData.teachers,
    initialData.absences,
    lessonDuration,
    combinedSchoolBreaks,
    shortenTimeSlots,
  ]);

  // Teachers View => the selected teacher obj with the availabile slots attached
  const selectedTeacherWithAvailableTimeSlots = useMemo(() => {
    if (
      groupBy.teacher &&
      groupBy.teacher.maxDateValue &&
      groupBy.teacher.teacherId
    ) {
      const selectedTeacherObj = initialData.teachers.find(
        ({ id }) => id === groupBy.teacher.teacherId
      );

      const datesTillMaxDate = getDatesBetweenTwoDates(
        updatedMomentNow(),
        groupBy.teacher.maxDateValue
      );

      const selectedTeacherAvailabilities = datesTillMaxDate.map((date) => {
        const {
          teacherDaysWithAvailabilitiesData,
          makeupOpeningsWithAvailabilitiesData,
        } = attachAvailableTimeSlotstoTeacher(
          selectedTeacherObj,
          lessonDuration,
          date,
          combinedSchoolBreaks,
          initialData.absences,
          shortenTimeSlots
        );

        return {
          date,
          teacherDaysWithAvailabilitiesData,
          makeupOpeningsWithAvailabilitiesData,
        };
      });
      const finalTeacherObj = {
        ...selectedTeacherObj,
        selectedTeacherAvailabilities,
      };
      return finalTeacherObj;
    } else {
      return null;
    }
  }, [
    initialData.teachers,
    initialData.absences,
    groupBy,
    combinedSchoolBreaks,
    lessonDuration,
    shortenTimeSlots,
  ]);

  return {
    initialData,
    loadingInitialData,
    teachersWithAvailableTimeSlots,
    selectedTeacherWithAvailableTimeSlots,
  };
};

export default useTeachersAvailabilities;
