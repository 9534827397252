export const adminsPaymentPageTabs = {
  CREATE_PAYMENT: { value: "create_payment", title: "Create Payment" },
  PAYMENT_LIST: { value: "payment_list", title: "Payment List" },
  REPORT: { value: "report", title: "Report" },
};
export const paymentUserTypes = {
  INSTRUCTOR: "Instructor",
  OPERATION_TEAM: "Operation Team",
  BOTH: "Both",
};
