import { useState } from "react";
import { useLocation } from "react-router";
import { Divider, Radio } from "antd";
import { CustomLabel } from "src/utils/styled";
import {
  SignupWrapper,
  GroupClassSignupForm,
  FormField,
  StudentTypeRadio,
} from "./styled";
import NewStudentBlock from "./NewStudentBlock";
import CurrentStudentBlock from "./CurrentStudentBlock";

const GroupClassSignup = () => {
  const [studentType, setStudentType] = useState("");
  const location = useLocation();

  const handleStudentTypeChange = ({ target }) => {
    setStudentType(target?.value);
  };

  return (
    <SignupWrapper>
      <GroupClassSignupForm>
        <FormField>
          <CustomLabel>
            Are you a current student at Artis School of Music?
          </CustomLabel>
          <StudentTypeRadio onChange={handleStudentTypeChange}>
            <Radio value="current">I'm a current student</Radio>
            <Radio value="new">I'm a new student</Radio>
          </StudentTypeRadio>
        </FormField>
        {studentType === "new" ? (
          <>
            <Divider />
            <NewStudentBlock />
          </>
        ) : studentType === "current" ? (
          <>
            <Divider />
            <CurrentStudentBlock />
          </>
        ) : null}
      </GroupClassSignupForm>
    </SignupWrapper>
  );
};

export default GroupClassSignup;
