/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import "../Style/Style.css";
import UseGetStudentsAndLessons from "./Hooks/UseGetStudentsAndLessons";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { CameraVideo } from "@styled-icons/bootstrap/CameraVideo";
import { Edit } from "@styled-icons/entypo/Edit";
import { CheckSquare } from "@styled-icons/bootstrap/CheckSquare";
import styled from "styled-components";
import { Spinner } from "../../../common";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import {
  checkIfCanceledRecurringActivity,
  getAgeFromDate,
  updatedMomentNow,
} from "src/utils/helpers";
import LessonInfoModal from "./LessonInfoModal";
import useModal from "src/hooks/useModal";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";

const EditIcon = styled(Edit)`
  color: #151515;
  width: 17px;
`;

function StudentsTable({ lessonsData, searchTerm }) {
  const { StudentRows, loadingData } = lessonsData;
  const [userLesson, setuserLesson] = useState({});

  const lessonInfoModalData = useModal();

  const Navigate = useNavigate();

  const openLessonInfoModal = (lesson) => {
    if (!lesson) return;
    setuserLesson(lesson);
    lessonInfoModalData.openModal();
  };

  const filteredLessons = useMemo(() => {
    const StudentsFilteredList = StudentRows.filter((lesson) => {
      const { student } = lesson;
      const isWithdrawn = checkIfCanceledRecurringActivity({
        occuranceDate: updatedMomentNow(),
        canceledAt: lesson?.withdrawal?.withdrawalDate,
      });

      if (isWithdrawn) return false;
      if (searchTerm == null || searchTerm === "" || searchTerm === " ") {
        return true;
      } else {
        const targetStudent = student?.fullName
          ? student.fullName.toLowerCase().includes(searchTerm)
          : null;
        return targetStudent;
      }
    });
    return StudentsFilteredList;
  }, [StudentRows, searchTerm]);

  const renderActions = (lesson) => (
    <div className="userActions">
      <div>
        <EditIcon
          className="icon"
          onClick={() => {
            openLessonInfoModal(lesson);
          }}
        />
      </div>
    </div>
  );
  const RenderData = (
    <>
      <CustomTableContainer>
        <CustomTable>
          <thead>
            <TableRow>
              <th>
                <div className="HeadContainer">
                  <>Student's Name</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>ID</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Lesson Day</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Lesson Time</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Lesson Length</>
                </div>
              </th>
              <th>
                <div className="HeadContainer">
                  <>Age</>
                </div>
              </th>
              <th className="actions">
                <div className="HeadContainer">
                  <>Actions</>
                </div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {filteredLessons.map((lesson, index) => {
              const { id, currentTimeline, instrument, student, type } =
                lesson || {};
              const { birthday } = student || {};

              const isPackageLsn = isPackagePrivateLesson(type);
              let formattedDuration, lessonWeekday, formattedTime;
              if (!isPackageLsn) {
                const { startDate, duration } = currentTimeline || {};

                formattedDuration = `${duration} Minutes`;
                const endDate = moment(startDate)
                  .add(parseInt(duration), "minutes")
                  .toDate();
                lessonWeekday = moment(startDate).format("dddd");
                formattedTime = `${moment(startDate).format(
                  "hh:mm A"
                )} - ${moment(endDate).format("hh:mm A")}`;
              }
              const studentAge =
                birthday &&
                getAgeFromDate(moment(birthday, "YYYY-MM-DD").toDate());
              return (
                <TableRow key={id}>
                  <ClickableTableCell
                    onClick={() => {
                      openLessonInfoModal(lesson);
                    }}
                  >
                    {student?.fullName}
                  </ClickableTableCell>
                  <td>{student?.systemID}</td>
                  <td>{lessonWeekday || "N/A"}</td>
                  <td>{formattedTime || "N/A"}</td>
                  <td>{formattedDuration || "N/A"}</td>
                  <td>{studentAge || "N/A"}</td>
                  <td>
                    <div className="Iconsactions">
                      {renderActions(lesson, student?.id)}
                    </div>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
    </>
  );
  return (
    <>
      {loadingData ? <Spinner /> : <>{RenderData}</>}
      <LessonInfoModal
        Userlesson={userLesson}
        modalData={lessonInfoModalData}
      />
    </>
  );
}

export default StudentsTable;
