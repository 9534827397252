import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { hasAdminCredentials, isTeacher } from "src/constants/UserRoleEnum";
import { concertProgramTypes } from "src/constants/concertEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { shouldTeacherSeeConcertProgram } from "src/utils/helpers";

const initialDataInitialValues = {
  concertPrograms: [],
  programUsers: [],
  programUsersPLs: [],
  instruments: [],
  teachers: [],
};
const useConcertPrograms = ({ user, concertId }) => {
  const {
    getConcertProgramsByConcertId,
    getUsersByIds,
    getPrivateLessonsByStudentsIds,
    getInstruments,
    getTeachers,
  } = useFirebaseFns();
  const isTeacherUser = isTeacher(user.role);
  const isAdminUser = hasAdminCredentials(user.role);

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentProgramId, setCurrentProgramId] = useState("");

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concertPrograms, instruments, teachers] = await Promise.all([
          getConcertProgramsByConcertId(concertId),
          getInstruments(),
          getTeachers(),
        ]);

        const programUsersIds = [
          ...new Set(concertPrograms.map(({ usersIds }) => usersIds).flat()),
        ];
        const [programUsers, programUsersPLs] = await Promise.all([
          getUsersByIds(programUsersIds),
          getPrivateLessonsByStudentsIds(programUsersIds),
        ]);

        let filteredPrograms = [];
        if (isTeacherUser) {
          filteredPrograms = concertPrograms.filter((program) => {
            const isSameTeacher = shouldTeacherSeeConcertProgram({
              program,
              pls: programUsersPLs,
              teacherId: user.uid,
            });
            return isSameTeacher;
          });
        } else if (isAdminUser) {
          filteredPrograms = concertPrograms;
        }

        setInitialData({
          concertPrograms: filteredPrograms,
          programUsers,
          programUsersPLs,
          instruments,
          teachers,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  const currentProgram = useMemo(() => {
    return initialData.concertPrograms.find(
      ({ id }) => id === currentProgramId
    );
  }, [initialData, currentProgramId]);

  return {
    initialData,
    loadingInitialData,
    currentProgramId,
    setCurrentProgramId,
    currentProgram,
  };
};

export default useConcertPrograms;
