import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { eventsMap, privateLessonTypes } from "src/constants/eventsEnum";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useTeachersAvailabilities from "src/hooks/useTeachersAvailabilities/useTeachersAvailabilities";
import { modalViews } from "../constants";
import { filterTeachersByInstrument } from "../helperFns";
import { v4 as uuidv4 } from "uuid";
import { updatedMomentNow } from "src/utils/helpers";
import { packageLessonSetTypes } from "src/constants/packageLessonsEnum";

const twoWeeksFromNowDate = updatedMomentNow().add(14, "day").toDate();

const usePackageData = ({
  studentId,
  lessonLocationId,
  lessonInstrumentId,
  lessonDuration,
  user,
  shortenTimeSlots = false,
  isNewLesson = true,
  packageLessonId, // if isNewLesson= false
  itemSet, // if isNewLesson= false
}) => {
  const {
    getTeachers,
    createPackagePrivateLessonAndActivity,
    createPackageItemAndActivity,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    teachers: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  // for the navigation tabs
  const [currentView, setCurrentView] = useState(modalViews.DATE);
  // selected teacher from the select field (for the teacher view only)
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  //state for when the student is choosing whether the lsn is virtual or not (Based on whether the availability is marked as "isVirtualOnly" or not)
  const [isVirtualLesson, setIsVirtualLesson] = useState(false);

  const [selectedLessonSlot, setSelectedLessonSlot] = useState();

  // state for when creating the makeup lesson
  const [loadingCreatingLesson, setLoadingCreatingLesson] = useState(false);
  // requestedDate state
  const [requestedDate, setRequestedDate] = useState(null);

  const groupByObj =
    currentView === modalViews.DATE
      ? { date: { requestedDate } }
      : {
          teacher: {
            maxDateValue: twoWeeksFromNowDate,
            teacherId: selectedTeacherId,
          },
        };
  // gets the teachers available slots
  const {
    selectedTeacherWithAvailableTimeSlots,
    teachersWithAvailableTimeSlots,
  } = useTeachersAvailabilities({
    instrumentId: lessonInstrumentId,
    lessonDuration,
    locationId: lessonLocationId,
    user,
    groupBy: groupByObj,
    shortenTimeSlots,
    includeMakeupOpeningsAvailabilities: true,
  });

  const handleIsVirtualLessonChange = (val) => {
    setIsVirtualLesson(val);
  };
  const handleSelectedLessonSlot = (val) => {
    setSelectedLessonSlot(val);
  };
  const handleSelectedTeacherChange = (teacherId) => {
    setSelectedTeacherId(teacherId);
  };
  const handleCurrentViewChange = (newView) => {
    setCurrentView(newView);
    handleSelectedLessonSlot(null);
  };

  const createNewLessonPackageItem = async () => {
    console.log("CREATING NEW PACKAGE LESSON ");

    try {
      if (!selectedLessonSlot) {
        toast.warn("Please select a time slot");
        return { success: false };
      }

      const { date, location, teacher, scheduleType } = selectedLessonSlot;

      setLoadingCreatingLesson(true);

      /* -- Creating The Lesson -- */
      // the lesson document structure
      const lessonObj = {
        createdAt: new Date(),
        instrumentId: lessonInstrumentId,
        studentId: studentId,
        type: privateLessonTypes.PACKAGE,
        packageSets: [
          {
            setNumber: 1,
            setType: packageLessonSetTypes.SINGLE,
            setItems: [
              {
                id: uuidv4(),
                startDate: date,
                duration: parseInt(lessonDuration),
                teacherId: teacher.id,
                locationId: location.id,
                isVirtual: isVirtualLesson,
                requestedBy: user.uid,
                requestedAt: new Date(),
              },
            ],
          },
        ],
      };
      console.log({ lessonObj });
      const lessonCreatedRes = await createPackagePrivateLessonAndActivity(
        lessonObj
      );

      toast.success("Created Successfully");
      setLoadingCreatingLesson(false);

      return { success: true };
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      return { success: false };
    } finally {
      setLoadingCreatingLesson(false);
    }
  };

  const createExistingLessonPackageItem = async () => {
    console.log("CREATING PACKAGE ITEM");
    try {
      if (!selectedLessonSlot) {
        toast.warn("Please select a time slot");
        return { success: false };
      }
      if (!itemSet || !packageLessonId) {
        toast.warn("couldnt find itemSet or packageLessonId");
        return;
      }
      const { date, location, teacher, scheduleType } = selectedLessonSlot;

      setLoadingCreatingLesson(true);

      /* -- Creating The Lesson -- */
      const packageItemObj = {
        id: uuidv4(),
        startDate: date,
        duration: parseInt(lessonDuration),
        teacherId: teacher.id,
        locationId: location.id,
        isVirtual: isVirtualLesson,
        requestedBy: user.uid,
        requestedAt: new Date(),
      };

      console.log({ packageItemObj, packageLessonId, itemSet });
      const lessonCreatedRes = await createPackageItemAndActivity(
        packageLessonId,
        packageItemObj,
        itemSet
      );

      toast.success("Created Successfully");
      setLoadingCreatingLesson(false);

      return { success: true };
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      return { success: false };
    } finally {
      setLoadingCreatingLesson(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);
        const requests = [getTeachers()];
        const [teachers] = await Promise.all(requests);

        const teachersFilteredByInstrument = filterTeachersByInstrument(
          teachers,
          lessonInstrumentId
        );

        setInitialData((oldVal) => ({
          ...oldVal,
          teachers: teachersFilteredByInstrument,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [lessonInstrumentId]);

  useEffect(() => {
    if (!selectedLessonSlot) {
      handleIsVirtualLessonChange(false);
      return;
    }
    if (selectedLessonSlot?.isVirtualOnly) {
      handleIsVirtualLessonChange(true);
    }
  }, [selectedLessonSlot]);

  // reset the selected makeup if request date changes
  useEffect(() => {
    setSelectedLessonSlot(null);
  }, [requestedDate]);

  const dateViewData = {
    teachersWithAvailableTimeSlots,
  };
  const teachersViewData = {
    selectedTeacherId,
    handleSelectedTeacherChange,
    selectedTeacherWithAvailableTimeSlots,
  };
  const maxDate = twoWeeksFromNowDate;
  const minDate = new Date();
  const requestedDateData = {
    requestedDate,
    setRequestedDate,
    maxDate,
    minDate,
  };

  const createLesson = isNewLesson
    ? createNewLessonPackageItem
    : createExistingLessonPackageItem;
  return {
    initialData,
    loadingInitialData,
    requestedDateData,
    handleIsVirtualLessonChange,
    handleSelectedTeacherChange,
    currentView,
    handleCurrentViewChange,
    selectedTeacherWithAvailableTimeSlots,
    teachersWithAvailableTimeSlots,
    isVirtualLesson,
    setIsVirtualLesson,
    selectedLessonSlot,
    handleSelectedLessonSlot,
    dateViewData,
    teachersViewData,
    createLesson,
    loadingCreatingLesson,
  };
};

export default usePackageData;
