import React from "react";
import CustomNavButtons from "src/components/common/CustomNavButtons";
import { groupClassPaymentTypes } from "src/constants/groupClassPaymentsConstants";
import { CustomLabel } from "src/utils/styled";

const SelectPaymentMethod = ({
  isDirectPaymentAvailable,
  isScheduledPaymentAvailable,
  selectedPaymentOption,
  setSelectedPaymentOption,
}) => {
  const options = [
    ...(isDirectPaymentAvailable
      ? [
          {
            name: "One Time",
            value: groupClassPaymentTypes.ONE_TIME,
          },
        ]
      : []),
    ...(isScheduledPaymentAvailable
      ? [
          {
            name: "Installments",
            value: groupClassPaymentTypes.SCHEDULED,
          },
        ]
      : []),
  ];
  return (
    <div>
      <CustomLabel>Payment Method:</CustomLabel>
      <CustomNavButtons
        buttonsPadding={"12px"}
        options={options}
        currentOption={selectedPaymentOption}
        setCurrentOption={(value) => setSelectedPaymentOption(value)}
      />
    </div>
  );
};

export default SelectPaymentMethod;
