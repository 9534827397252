import React, { useState } from "react";

import { CustomInput, CustomLabel, CustomTextarea } from "../../styled";
import TeacherSelect from "./TeacherSelect";
import InstrumentSelect from "./InstrumentSelect";
import WeekDaySelect from "./WeekDaySelect";
import LessonLengthSelect from "./LessonLengthSelect";
import moment from "moment";
import AvailabilitiesSection from "./AvailabilitiesSection";
import { PrimaryButton } from "src/utils/shared/styled";
import { SubmitSpinner } from "src/components/common";
import useModal from "src/hooks/useModal";
import ConfirmationModal from "../../../ConfirmationModal";
import { injectUserStore, updatedMomentNow } from "src/utils/helpers";
import { toJS } from "mobx";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { usePrivateLessonData } from "../../../PrivateLessonCommonComponents";

const RegularPrivateLessonCreationBody = ({
  modalData,
  studentId,
  providedInitialData,
  UserStore,
  isReturnFromSummerBreak,
  returnFromSummerBreakData,
}) => {
  const user = toJS(UserStore).user;

  const confirmationModalData = useModal();
  const plCreationData = usePrivateLessonData({
    providedInitialData,
    studentId,
    modalData,
    user,
    isReturnFromSummerBreak,
    returnFromSummerBreakData,
  });
  const {
    initialData,
    handleFormInputsChange,
    formInputs,
    teachersBasedOnInstrument,
    selectedTeacherObj,
    showAvailabilities,
    showSaveBtn,
    handleTeacherChange,
    handleLessonTimeChange,
    handleCreatePrivateLesson,
    handleReturnFromSummerBreak,
    loadingSavingLesson,
  } = plCreationData;
  const { instruments, locations, programs } = initialData;

  const handleApproveOption = async () => {
    if (isReturnFromSummerBreak) {
      await handleReturnFromSummerBreak();
    } else {
      await handleCreatePrivateLesson();
    }
    window.location.reload();
  };
  const handleCancelOption = () => {
    confirmationModalData.closeModal();
  };
  return (
    <div>
      <div className="d-flex" style={{ gap: "30px" }}>
        {/* Instrument */}
        <div className="w-50">
          <InstrumentSelect
            isDisabled={isReturnFromSummerBreak}
            instruments={instruments}
            formInputs={formInputs}
            handleFormInputsChange={handleFormInputsChange}
          />
        </div>

        {/* Teacher */}
        <div className="w-50">
          <TeacherSelect
            programs={programs}
            teachers={teachersBasedOnInstrument}
            formInputs={formInputs}
            handleTeacherChange={handleTeacherChange}
          />
        </div>
      </div>
      <div className="d-flex mt-5 mb-4" style={{ gap: "30px" }}>
        {/* Day */}
        <div style={{ width: "33.3333%" }}>
          <WeekDaySelect
            teacher={selectedTeacherObj}
            formInputs={formInputs}
            handleFormInputsChange={handleFormInputsChange}
          />
        </div>

        {/* Start Date */}
        <div style={{ width: "33.3333%" }}>
          <CustomLabel className="mb-3">Starting Date</CustomLabel>
          <CustomInput
            disabled={!formInputs.day}
            name="startDate"
            value={formInputs.startDate}
            onChange={handleFormInputsChange}
            type="date"
            width="100%"
            min={
              formInputs.day &&
              updatedMomentNow()
                .set({ year: 2015 })
                .isoWeekday(parseInt(formInputs.day))
                .format("YYYY-MM-DD")
            }
            step="7"
          />
        </div>

        {/* Lesson Length */}
        <div style={{ width: "33.3333%" }}>
          <LessonLengthSelect
            formInputs={formInputs}
            handleFormInputsChange={handleFormInputsChange}
          />
        </div>
      </div>
      <div className="mt-5">
        {/* Availabilities */}
        {showAvailabilities && (
          <>
            <AvailabilitiesSection
              plCreationData={plCreationData}
              handleLessonTimeChange={handleLessonTimeChange}
            />

            {/* Note */}
            {!isReturnFromSummerBreak && (
              <div className="mt-3">
                <CustomLabel className="mb-3">Note (For Admins)</CustomLabel>
                <CustomTextarea
                  name="note"
                  value={formInputs.note}
                  onChange={handleFormInputsChange}
                />
              </div>
            )}
          </>
        )}

        {showSaveBtn && (
          <div className="mt-4 text-center">
            <PrimaryButtonWithLoading
              isLoading={loadingSavingLesson}
              onClick={confirmationModalData.openModal}
              width="60%"
            >
              {isReturnFromSummerBreak
                ? "Confirm Return Schedule"
                : "Create Lesson And Send Confirmation Email"}
            </PrimaryButtonWithLoading>
          </div>
        )}
      </div>

      <ConfirmationModal
        modalData={confirmationModalData}
        onApprove={handleApproveOption}
        onCancel={handleCancelOption}
        isSubmitting={loadingSavingLesson}
        title="Confirm Lesson ?"
        approveBtnText="Create Lesson"
        cancelBtnText="Cancel"
      />
    </div>
  );
};

export default injectUserStore(RegularPrivateLessonCreationBody);
