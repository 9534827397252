import moment from "moment";
import React, { useEffect, useState } from "react";
import MakeupModal from "src/components/Dashboard/common/components/MakeupModal";
import useModal from "src/hooks/useModal";
import {
  getLessonWithCurrentTimeline,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
} from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import styled from "styled-components";
import useAffectedLessonsData from "../hooks/useAffectedLessonsData";
import { Container, RedBarContainer, RedBarText } from "../styled";

const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
  width: 65%;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 25px;
`;
const AffectedLessonsBody = ({
  absencesData,
  modalData,
  withJustApprovedAbsence,
}) => {
  const { justApprovedAbsence, currentAbsence } = absencesData;
  const absence = withJustApprovedAbsence
    ? justApprovedAbsence
    : currentAbsence;
  const absenceDate = absence.date || absence.startDate;

  const { combinedStudents, locations, instruments } =
    absencesData.initialData || {};

  const makeupModalData = useModal();

  const {
    initialData,
    currentLesson,
    setCurrentLesson,
    currentLessonAbsences,
    loadingCurrentLessonData,
    refreshData,
  } = useAffectedLessonsData({
    absence,
  });

  return (
    <div>
      <Container>
        <RedBarContainer>
          <RedBarText>Affected Private Lessons</RedBarText>
        </RedBarContainer>
        <div className="p-4 w-100 d-flex justify-content-center align-items-center">
          <div>
            {initialData.absencePrivateLessons?.length ? (
              initialData.absencePrivateLessons
                .sort((lessonA, lessonB) => {
                  const { startDate: lessonAStart } =
                    getPrivateLessonInfoOnSpecificDate({
                      privateLesson: lessonA,
                      date: absenceDate,
                      withTimelineApproximation: true,
                    });
                  const { startDate: lessonBStart } =
                    getPrivateLessonInfoOnSpecificDate({
                      privateLesson: lessonB,
                      date: absenceDate,
                      withTimelineApproximation: true,
                    });
                  const updatedAStart = moment().set({
                    hours: moment(lessonAStart).hours(),
                    minutes: moment(lessonAStart).minutes(),
                  });
                  const updatedBStart = moment().set({
                    hours: moment(lessonBStart).hours(),
                    minutes: moment(lessonBStart).minutes(),
                  });
                  const diff = getTimeDiffInMins(updatedBStart, updatedAStart);
                  return diff;
                })
                .map((lesson) => {
                  const { studentId, instrumentId } = lesson;
                  const { startDate: lessonStart, duration } =
                    getPrivateLessonInfoOnSpecificDate({
                      privateLesson: lesson,
                      date: absenceDate,
                      withTimelineApproximation: true,
                    });

                  const lessonEnd = lessonStart
                    ? moment(lessonStart).add(parseInt(duration), "minutes")
                    : undefined;

                  const student = combinedStudents?.find(
                    ({ id }) => id === studentId
                  );
                  const instrument = instruments?.find(
                    ({ id }) => id === instrumentId
                  );

                  const formattedStartTime =
                    moment(lessonStart).format("hh:mm A");
                  const formattedEndTime = moment(lessonEnd).format("hh:mm A");

                  const title = `${student?.fullName} (${instrument?.name}) ${formattedStartTime} - ${formattedEndTime}`;

                  const lessonMakeups = initialData.absenceMakeupLessons.filter(
                    ({ forLessonId }) => forLessonId === lesson.id
                  );
                  // if there's at least 1 makeup lesson for the pl , then its marked as scheduled
                  const isSubScheduled = lessonMakeups.length;
                  return (
                    <RowContainer>
                      <InfoText>{title}</InfoText>
                      {isSubScheduled ? (
                        <PrimaryButton width="170px" backgroundColor="#00bf2c">
                          Scheduled
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          disabled={loadingCurrentLessonData}
                          onClick={() => {
                            setCurrentLesson(lesson);
                            makeupModalData.openModal();
                          }}
                          width="170px"
                        >
                          Substitute
                        </PrimaryButton>
                      )}
                    </RowContainer>
                  );
                })
            ) : (
              <h4>No Affected Lessons To Substitute</h4>
            )}
          </div>
        </div>
      </Container>
      <div className="text-center mt-4 mb-4">
        <PrimaryButton onClick={() => modalData.closeModal()}>
          Confirm
        </PrimaryButton>
      </div>
      {/* <div className="mt-5 mb-5"></div> */}
      {currentLesson &&
        makeupModalData.isModalOpen &&
        !loadingCurrentLessonData && (
          <MakeupModal
            currentAbsence={absence}
            makeupModalData={makeupModalData}
            lesson={currentLesson}
            onSuccess={() => refreshData()}
            // shortenTimeSlots
          />
        )}
    </div>
  );
};

export default AffectedLessonsBody;
