import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useState } from "react";
import { compose } from "recompose";
import { SubmitSpinner } from "src/components/common";
import {
  Container,
  RedBarContainer,
  RedBarText,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../../styled";
import "react-datepicker/dist/react-datepicker.css";
import useLessonChanges from "../hooks/useLessonChanges";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import {
  CalendarPickerWrapper,
  CustomTextarea,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import FormSection from "./components/FormSection";
import FieldsSelection from "./components/FieldsSelection";
import { injectUserStore } from "src/utils/helpers";
import StyledBarContent from "src/components/common/StyledBarContent";

const LessonChangesBody = ({
  lessonChangesModalData,
  lessonWithCurrentTimeline,
  UserStore,
}) => {
  const { closeModal } = lessonChangesModalData;

  const user = toJS(UserStore)?.user;

  const hookData = useLessonChanges({ user, lessonWithCurrentTimeline });
  const {
    step,
    nextStep,
    prevStep,
    formValues,
    handleFormValuesChange,
    saveLesson,
    loading,
    availableTeachers,
    availableClassDays,

    selectedGroup,
    handleSelectedGroupChange,
  } = hookData;

  const steps = {
    0: {
      mainComponent: (
        <FieldsSelection
          selectedGroup={selectedGroup}
          handleSelectedGroupChange={handleSelectedGroupChange}
        />
      ),
      buttonsComponent: (
        <CustomButtonsWrapper>
          <CustomButton onClick={closeModal}>Cancel</CustomButton>
          <CustomButton primary onClick={nextStep}>
            Next
          </CustomButton>
        </CustomButtonsWrapper>
      ),
    },
    1: {
      mainComponent: (
        <FormSection
          hookData={hookData}
          lessonChangesModalData={lessonChangesModalData}
          lessonWithCurrentTimeline={lessonWithCurrentTimeline}
        />
      ),
      buttonsComponent: (
        <CustomButtonsWrapper>
          <CustomButton onClick={closeModal}>Cancel</CustomButton>
          <CustomButton primary onClick={saveLesson}>
            {loading ? (
              <div>
                <SubmitSpinner style={{ width: "20px", height: "20px" }} />
              </div>
            ) : (
              "Confirm"
            )}
          </CustomButton>
        </CustomButtonsWrapper>
      ),
    },
  };

  const renderCurrentStepMainComponent = () => {
    return steps[step].mainComponent;
  };
  const renderCurrentStepButtonsComponent = () => {
    return steps[step].buttonsComponent;
  };
  return (
    <>
      <StyledBarContent overFlowContent={"visible"} title={"Lesson Changes"}>
        <div style={{ padding: "15px 35px" }}>
          {renderCurrentStepMainComponent()}
        </div>
      </StyledBarContent>

      {renderCurrentStepButtonsComponent()}
    </>
  );
};

export default injectUserStore(LessonChangesBody);
