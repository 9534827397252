import React from "react";
import RouteContent from "../../../components/common/RouteContent";
import TeacherLessonNotes from "./TeacherLessonNotes";
const TeacherLessonNotesRoute = ({ user }) => {
  return (
    <RouteContent title={"Lesson Notes"}>
      <TeacherLessonNotes user={user} />
    </RouteContent>
  );
};
export default TeacherLessonNotesRoute;
