import React from "react";
import { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import {
  CalendarPickerWrapper,
  CustomInput,
  CustomSelectField,
  PrimaryButton,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import { InputWrapper } from "../../styled";
import { isActiveTeacher } from "src/utils/helpers";

const EditForm = ({ timelineData, instrumentId }) => {
  const {
    formData,
    onFormFieldChange,
    initialData,
    loadingInitialData,
    isSubmitting,
    availableTeachers,
    submitChanges,
  } = timelineData;
  const { locations, programs } = initialData;

  const [isStartDateDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isLastDatePickerOpen, setIsLastDatePickerOpen] = useState(false);

  return (
    <div>
      <InputWrapper>
        <CustomLabel>
          Start Date:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CalendarPickerWrapper>
          <SingleDatePicker
            id="startDatePicker"
            date={formData.startDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              onFormFieldChange("startDate", date);
            }}
            focused={isStartDateDatePickerOpen} // PropTypes.bool
            onFocusChange={({ focused }) => {
              setIsStartDatePickerOpen(focused);
            }}
            isOutsideRange={(date) => {
              return false;
            }}
            // numberOfMonths={1}
            // showClearDate={true}
          />
        </CalendarPickerWrapper>
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Last Lesson Date (remove if still in progress):
        </CustomLabel>
        <CalendarPickerWrapper>
          <SingleDatePicker
            id="lastDatePicker"
            date={formData.lastDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              onFormFieldChange("lastDate", date);
            }}
            focused={isLastDatePickerOpen} // PropTypes.bool
            onFocusChange={({ focused }) => {
              setIsLastDatePickerOpen(focused);
            }}
            // numberOfMonths={1}
            showClearDate={true}
            isOutsideRange={(date) => {
              return false;
            }}
          />
        </CalendarPickerWrapper>
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Lesson Time:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomInput
          name="startTime"
          value={formData.startTime}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          type="time"
          width="100%"
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Lesson Length:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          width="100%"
          name="lessonDuration"
          value={formData.lessonDuration}
          onChange={(e) =>
            onFormFieldChange(e.target.name, parseInt(e.target.value))
          }
        >
          <option value="" disabled>
            Select Length
          </option>

          {Object.keys(LessonsLengthEnum).map((duration) => (
            <option key={duration} value={duration}>
              {`${duration} Minutes`}
            </option>
          ))}
        </CustomSelectField>
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Location:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          width="100%"
          name="locationId"
          value={formData.locationId}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
        >
          <option value="" disabled>
            Select Location
          </option>
          {locations.map(({ id, name }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            );
          })}
        </CustomSelectField>
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Teacher/Program:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          hideDisabledOptions
          width="100%"
          name="teacherId"
          value={formData.teacherId}
          onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
        >
          <option value="" disabled>
            Select Teacher
          </option>
          {availableTeachers.map((teacher) => {
            const programId = teacher.instrumentsInfo?.find(
              ({ instrument, program }) => instrument === instrumentId
            )?.program;

            const programName = programs.find(
              ({ id }) => id === programId
            )?.name;
            return (
              <option
                disabled={!isActiveTeacher(teacher)}
                key={teacher.id}
                value={teacher.id}
              >
                {`${teacher.fullName} | ${programName}`}
              </option>
            );
          })}
        </CustomSelectField>
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>
          Lesson Type:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <div width="100%">
          <label className="me-3">
            <input
              type="radio"
              checked={!formData.isVirtual}
              name="isVirtual"
              onChange={(e) => onFormFieldChange(e.target.name, false)}
            />{" "}
            In Person
          </label>
          <label>
            <input
              type="radio"
              checked={formData.isVirtual}
              name="isVirtual"
              onChange={(e) => onFormFieldChange(e.target.name, true)}
            />{" "}
            Virtual
          </label>
        </div>
      </InputWrapper>
      <div className="mt-4 text-center">
        <PrimaryButtonWithLoading
          onClick={submitChanges}
          isLoading={isSubmitting}
        >
          Save Changes
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

export default EditForm;
