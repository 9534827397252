import styled from "styled-components";
import { UploadCloud } from "styled-icons/feather";
import { Cross } from "@styled-icons/entypo/Cross";

export const MainContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const ColumnContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const InputContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

export const InputsRow = styled.div`
  display: flex;
  gap: 0.5rem;
`;

// Attachments Section
export const AttachmentsSectionContainer = styled.div``;

export const UploadAttachmentContainer = styled.div`
  min-height: 85px;
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const UploadPlaceholder = styled.h4`
  /* color: #dddddd; */
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
  margin-right: 15px;
`;
export const UploadIcon = styled(UploadCloud)`
  /* color: #dddddd; */
`;

export const AttachmentsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const AttachmentItemContainer = styled.div`
  padding: 15px;
  background: #eecfd9;
  border-radius: 25px;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`;
export const AttachmentName = styled.p`
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CrossIconContainer = styled.div`
  width: 25px;
`;
export const CrossIcon = styled(Cross)`
  width: 100%;
  cursor: pointer;
`;

export const RadioInputcContainer = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  gap: 2rem;
`;

export const CustomButtonsWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const CustomButton = styled.button`
  border: none;
  outline: none;
  background: #fff;
  color: ${({ primary }) => (primary ? "#681e46" : "grey")};
`;

export const SectionContainer = styled.div`
  background-color: #f5f2f4;
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #ababab;
  min-height: ${({ minHeight }) => minHeight || "200px"};
`;
