import { useNavigate } from "react-router";
import moment from "moment";
import { GroupClassCardContainer, GroupClassHeader } from "../../styled";
import {
  InnerCard,
  ButtonsContainer,
  CardButton,
  ClassDescription,
  StyledCollapse,
} from "./styled";
import ClassInfo from "../GroupClassInfoCard/ClassInfo";

const IntegrationInfoCard = ({ headerColor, teachers, groupClass }) => {
  const { classes, title } = groupClass;
  const firstClass = classes?.[0];
  const lastClass = classes?.[classes?.length - 1];
  const navigate = useNavigate();

  let classTitleInfo = "";

  if (firstClass && lastClass) {
    const firstClassDate = firstClass.startDate;
    const lastClassDate = lastClass.startDate;

    const classStart = moment(firstClass.startDate);
    const duration = firstClass.duration;

    const formattedFirstDate = classStart.format("hh:mm A");
    const classEnd = classStart.clone().add(duration, "minutes");
    const formattedEndDate = classEnd.format("hh:mm A");

    const firstAndLastClassText = `(${moment(firstClassDate).format(
      "MMM Do"
    )} - ${moment(lastClassDate).format("MMM Do")})`;

    classTitleInfo = `${formattedFirstDate} - ${formattedEndDate} ${firstAndLastClassText}`;
  }

  const handleSignupClick = () => {
    navigate("/group-classes/signup", { state: { classId: groupClass?.id } });
  };

  return (
    <GroupClassCardContainer>
      <GroupClassHeader bgColor={headerColor}>
        <span>Class: {title || ""}</span>
      </GroupClassHeader>
      <InnerCard>
        <ClassInfo groupClassObj={groupClass} teachers={teachers} />
        <ButtonsContainer>
          <StyledCollapse
            items={[
              {
                key: "1",
                label: "Class description",
                children: (
                  <ClassDescription>
                    <p>
                      Artis School of Music is dedicated to offer quality and
                      creative music courses to the local community. Please
                      select your desired course information below.
                    </p>
                    <p>Class type: {firstClass?.type}</p>
                    <p>Tuition: $185</p>
                  </ClassDescription>
                ),
              },
            ]}
          />
          <CardButton onClick={handleSignupClick}>Sign up</CardButton>
        </ButtonsContainer>
      </InnerCard>
    </GroupClassCardContainer>
  );
};

export default IntegrationInfoCard;
