import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  getCombinedSchoolBreaks,
  getFullDateFromDateAndTimeInputs,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  updatedMomentNow,
} from "src/utils/helpers";

import useFirebaseFns from "./useFirebaseFns";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import { useContext } from "react";
import { weekDays } from "src/constants/weekDays";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import {
  checkForOverlapping,
  checkIfLessonInTeacherAvailabilities,
} from "src/components/Dashboard/common/helperFns/lessonTimelineHelpers";
import { isInterChangeableOption, lessonChangeGroups } from "../constants";

const initialFormValues = {
  lessonTime: "",
  lessonLength: undefined, // number
  locationId: "",
  teacherId: "",
  weekDay: "", // number
  isVirtual: false,
  note: "",
  instrumentId: "",

  // lastLessonDate: moment(lessonStartDate), // date
  lastLessonDate: undefined, // date

  newLessonDate: undefined, // date
};

const useLessonChanges = ({ user, lessonWithCurrentTimeline }) => {
  const { teachers, instruments } = useStudentInfoContext();
  const { updatePrivateLessonNote } = useFirebaseFns();
  const {
    getTeachersAvailableDays,
    getTeacherActivities,
    editRegularLessonAndActivityTimeline,
    getSchoolYears,
    updatePrivateLesson,
  } = useGlobalFirebaseFns();

  const lessonStartDate = lessonWithCurrentTimeline.currentTimeline?.startDate;

  const [formValues, setFormValues] = useState(initialFormValues);
  const [loading, setLoading] = useState(false);

  const [teachersAvailableDays, setTeachersAvailableDays] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(lessonChangeGroups.LESSON);

  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep((oldVal) => oldVal + 1);
  };
  const prevStep = () => {
    setStep((oldVal) => oldVal - 1);
  };

  const handleSelectedGroupChange = (selectedGroup) => {
    setSelectedGroup(selectedGroup);
  };

  const handleFormValuesChange = (name, value) => {
    if (name === "locationId") {
      setFormValues((oldVal) => ({
        ...oldVal,
        [name]: value,
        teacherId: "",
        weekDay: "",
      }));
      return;
    }
    if (name === "teacherId") {
      setFormValues((oldVal) => ({
        ...oldVal,
        [name]: value,
        weekDay: "",
      }));
      return;
    }
    setFormValues((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  };

  const saveLessonChanges = async () => {
    try {
      const {
        lastLessonDate,
        lessonLength,
        lessonTime,
        newLessonDate,
        teacherId,
        weekDay,
        locationId,
        isVirtual,
        note,
      } = formValues;

      if (
        !lastLessonDate ||
        !lessonLength ||
        !lessonTime ||
        !newLessonDate ||
        !teacherId ||
        !locationId ||
        !note
      ) {
        toast.warn("Please fill all required fields");
        return;
      }

      setLoading(true);

      const fullLastLessonDate = moment(lastLessonDate)
        .set({
          hours: moment(lessonStartDate).hours(),
          minutes: moment(lessonStartDate).minutes(),
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      const fullNewLessonStartDate = getFullDateFromDateAndTimeInputs(
        newLessonDate,
        lessonTime
      );

      // teacherDays is an array with the teacher days
      // const teacherDays = await getTeacherAvailableDays(formValues.teacherId);
      const teacherDays = teachersAvailableDays.find(
        ({ id }) => id === formValues.teacherId
      )?.availableDays;

      const isWithinTeacherAvailabilities =
        checkIfLessonInTeacherAvailabilities(
          {
            lessonStartDate: fullNewLessonStartDate,
            duration: parseInt(formValues.lessonLength),
          },
          teacherDays
        );

      if (!isWithinTeacherAvailabilities) {
        toast.warn("Must be within the teacher schedule in (Teacher Days)");
        return;
      }
      const teacherActivities = await getTeacherActivities(
        formValues.teacherId
      );
      const isLessonOverlapping = checkForOverlapping(
        teacherActivities,
        formValues.teacherId,
        {
          lessonId: lessonWithCurrentTimeline.id, // need the id to prevent validating lesson against itself
          lessonStartDate: fullNewLessonStartDate,
          duration: parseInt(formValues.lessonLength),
        }
      );
      if (isLessonOverlapping) {
        toast.warn(
          "The teacher has another activity that overlaps with this lesson"
        );
        return;
      }

      const newTimelineObj = {
        id: uuidv4(),
        startDate: fullNewLessonStartDate, // the new lesson date from where we start new timeline
        duration: parseInt(lessonLength),
        teacherId,
        locationId,
        isVirtual,
        requestedBy: user?.uid,
        requestedAt: new Date(),
        note,
      };

      let updatedTimeline = lessonWithCurrentTimeline.timeline.map(
        (timelineObj) => {
          if (
            timelineObj.id === lessonWithCurrentTimeline.currentTimeline?.id
          ) {
            return {
              ...timelineObj,
              lastDate: fullLastLessonDate,
            };
          } else {
            return timelineObj;
          }
        }
      );
      updatedTimeline = [...updatedTimeline, newTimelineObj];

      const res = await editRegularLessonAndActivityTimeline({
        updatedTimeline,
        lessonId: lessonWithCurrentTimeline.id,
      });

      const newTimelineNote = `${moment(newTimelineObj.requestedAt).format(
        "MM-DD-YYYY"
      )}: Received Lesson Change Request Starting from ${moment(
        newTimelineObj.startDate
      ).format("MM-DD-YYYY")}`;
      const newLessonNote =
        lessonWithCurrentTimeline.note + "\n" + newTimelineNote;
      await updatePrivateLessonNote(
        newLessonNote,
        lessonWithCurrentTimeline.id
      );
      toast.success("Lesson Edited Successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const saveLessonInstrument = async () => {
    try {
      if (!formValues.instrumentId) {
        toast.warn("Please fill all required fields");
        return;
      }
      setLoading(true);
      const updateObj = {
        instrumentId: formValues.instrumentId,
      };
      const res = await updatePrivateLesson(
        lessonWithCurrentTimeline.id,
        updateObj
      );

      toast.success("Lesson Instrument Edited Successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  async function saveLesson() {
    if (selectedGroup === lessonChangeGroups.INSTRUMENT) {
      await saveLessonInstrument();
    } else {
      await saveLessonChanges();
    }
  }

  const isInstrumentChangeSelected = useMemo(() => {
    return selectedGroup === lessonChangeGroups.INSTRUMENT;
  }, [selectedGroup]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [teachersAvailableDays, schoolYears] = await Promise.all([
          getTeachersAvailableDays(),
          getSchoolYears(),
        ]);
        setTeachersAvailableDays(teachersAvailableDays);
        setSchoolYears(schoolYears);
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      }
    };
    fetchInitialData();
  }, []);

  // setting initial form values
  useEffect(() => {
    const lessonTime = moment(lessonStartDate).format("HH:mm");
    const lessonLength = parseInt(
      lessonWithCurrentTimeline.currentTimeline?.duration
    );
    const teacherId = lessonWithCurrentTimeline.currentTimeline?.teacherId;
    const weekDay = moment(lessonStartDate).get("weekday");
    const locationId = lessonWithCurrentTimeline.currentTimeline?.locationId;
    const isVirtual = lessonWithCurrentTimeline.currentTimeline?.isVirtual;
    const instrumentId = lessonWithCurrentTimeline.instrumentId;
    setFormValues((oldVal) => ({
      ...oldVal,
      lessonTime,
      lessonLength,
      teacherId,
      weekDay,
      locationId,
      isVirtual,
      instrumentId,
    }));
  }, [lessonWithCurrentTimeline]);

  // available teachers for the lesson instrument + selected location (appears in the teachers select field)
  const availableTeachers = useMemo(() => {
    const filteredTeachers = teachers.filter((teacher) => {
      const teacherInstruments =
        teacher.instrumentsInfo?.map((info) => info.instrument) || [];
      const sameInstrument = teacherInstruments.includes(
        lessonWithCurrentTimeline.instrumentId
      );

      const teacherLocations =
        teachersAvailableDays
          .find(({ id }) => id === teacher.id)
          ?.availableDays?.map(({ location }) => location) || [];
      const sameLocation = teacherLocations.includes(formValues.locationId);

      return sameInstrument && sameLocation;
    });

    return filteredTeachers;
  }, [teachers, teachersAvailableDays, lessonWithCurrentTimeline, formValues]);

  const availableClassDays = useMemo(() => {
    const teacherDaysWeekDays = teachersAvailableDays
      .find(({ id }) => id === formValues.teacherId)
      ?.availableDays?.filter(
        ({ location }) => location === formValues.locationId
      )
      ?.map((day) => {
        const { startDate, endDate } = day;
        const startDateWeekDay = moment(startDate).day();
        const endDateWeekDay = moment(endDate).day();

        return [startDateWeekDay, endDateWeekDay];
      })
      ?.flat();
    const uniqueWeekDays = [...new Set(teacherDaysWeekDays || [])];

    const filteredWeekDays = weekDays.filter(({ id }) => {
      const isDayAvailable = uniqueWeekDays.includes(id);
      return isDayAvailable;
    });

    return filteredWeekDays;
  }, [formValues, teachersAvailableDays]);

  const combinedSchoolBreaks = useMemo(() => {
    if (!schoolYears) return [];

    const { locationId } = getPrivateLessonInfoOnSpecificDate({
      date: updatedMomentNow(),
      privateLesson: lessonWithCurrentTimeline,
      withTimelineApproximation: true,
    });
    if (!locationId) return [];

    const combinedBreaks = getCombinedSchoolBreaks(schoolYears, locationId);

    return combinedBreaks;
  }, [schoolYears, lessonWithCurrentTimeline]);

  const currentTeacher = useMemo(() => {
    return teachers?.find(({ id }) => id === formValues.teacherId);
  }, [teachers, formValues.teacherId]);

  const filteredTeacherInstrumentsList = useMemo(() => {
    if (!currentTeacher) return [];
    const currentTeacherInstruments = instruments.filter(({ id }) =>
      currentTeacher.instrumentsInfo?.some((info) => info.instrument === id)
    );
    return currentTeacherInstruments;
  }, [instruments, currentTeacher]);

  return {
    formValues,
    handleFormValuesChange,
    loading,
    availableTeachers,
    availableClassDays,
    step,
    nextStep,
    prevStep,
    schoolYears,
    selectedGroup,
    handleSelectedGroupChange,
    combinedSchoolBreaks,
    isInstrumentChangeSelected,
    saveLesson,
    currentTeacher,
    filteredTeacherInstrumentsList,
  };
};

export default useLessonChanges;
