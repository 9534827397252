import { Button } from "reactstrap";
import styled from "styled-components";

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

const FooterButton = styled(Button)`
  display: block;
  margin-left: ${({ leftmargin }) => (leftmargin ? "auto" : "")};
  border: none;

  border-radius: 12px;
  background-color: #e357a2;

  &:is(:hover, :focus) {
    background-color: #e357a2cc;
  }
`;

export { StyledFooter, FooterButton };
