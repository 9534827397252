import React from "react";
import "./style.css";
import NavMenu from "../common/NavMenu";
import Content from "./Content";
import CoursesTable from "./CoursesTable";
import MusicRecitals from "./MusicRecitals";
import BookClassToday from "../common/BookClassToday";
import Footer from "../common/Footer";

function PrivateInstructions() {
  return (
    <main className="private-instructions-container">
      <NavMenu />
      <div className="private-instructions">
        <div className="home-page-container">
          <Content />
          <CoursesTable />
          <MusicRecitals />
          <BookClassToday />
          <Footer />
        </div>
      </div>
    </main>
  );
}

export default PrivateInstructions;
