import styled from "styled-components";

const ConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  p {
    font-size: 18px;
  }
`;

export { ConfirmWrapper };
