import { addDoc, Timestamp } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "../../../Firebase";

const useDraftStudent = () => {
  const firebase = useContext(FirebaseContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkIfStudentCanBeSavedAsDraft = (values) => {
    if (!values) return false;

    // const canBeSaved = Object.keys(values).some((key) => {
    //   const isValueExist = !!values[key];
    //   return isValueExist;
    // });

    const canBeSaved = !!values.name;

    return canBeSaved;
  };

  const saveStudentAsDraft = async (values, onSuccess) => {
    try {
      setIsSubmitting(true);
      const canBeSaved = checkIfStudentCanBeSavedAsDraft(values);
      if (!canBeSaved) {
        toast.warn(
          "Please Fill At Least The Name Field To Save A Draft Student"
        );
        return;
      }

      console.log("SAVING AS DRAFT", { values });
      const doc = {
        ...values,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };
      const snap = await addDoc(firebase.getTrialStudents(), doc);
      const createdDoc = {
        id: snap.id,
        ...doc,
      };

      toast.success("Student Created Successfully");
      onSuccess(createdDoc);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    checkIfStudentCanBeSavedAsDraft,
    saveStudentAsDraft,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useDraftStudent;
