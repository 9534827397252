import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import InputWithLabel from "../shared/InputWithLabel";
import { SubmitSpinner } from "../../common";
import InputWrapper from "../shared/InputWrapper";
import { getDocs, query, where } from "firebase/firestore";
import * as yup from "yup";
import {
  emailValidationSchema,
  stringMaxValidationSchema,
} from "../../../utils/validations";
import { useNavigate } from "react-router";
import {
  signInViews,
  USER_DISABLED,
  USER_NOT_FOUND,
  WRONG_PASSWORD,
} from "../constants";
import { AuthButtonsWrapper, AuthSubmitButton, SideButton } from "../styled";

const loginValidationSchema = yup.object().shape({
  email: emailValidationSchema,
  password: stringMaxValidationSchema,
});

const SignInComponent = ({ handleViewChange }) => {
  const Navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          const { email, password } = values;
          const lowerCaseEmail = email.toLowerCase();
          setSubmitting(true);
          const usersQuery = query(
            firebase.users(),
            where("primaryEmail", "==", lowerCaseEmail)
          );
          const usersSnapshot = await getDocs(usersQuery);
          console.log({ usersSnapshot });
          // Checks if we have the user in firestore before logging in
          if (usersSnapshot.docs?.length) {
            await firebase
              .signInWithEmailAndPassword(lowerCaseEmail, password)
              .then(() => Navigate("/dashboard"))
              .catch((error) => {
                if (error.code === USER_NOT_FOUND) {
                  setErrors({ email: "User not found" });
                } else if (error.code === USER_DISABLED) {
                  toast.error("User Disabled");
                } else if (error.code === WRONG_PASSWORD) {
                  setErrors({ password: "Wrong password" });
                } else {
                  console.log(error);
                  toast.error(error.message);
                }
              });
          } else {
            setErrors({ email: "User not found" });
          }
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form className="mt-5">
          <InputWrapper>
            <InputWithLabel
              name="email"
              error={errors.email}
              touched={touched.email}
              label="Email Address"
              placeholder="Email Address"
              type="email"
            />
          </InputWrapper>
          <InputWrapper>
            <InputWithLabel
              name="password"
              error={errors.password}
              touched={touched.password}
              label="Password"
              placeholder="Password"
              type="password"
            />
          </InputWrapper>
          <div className="w-75 m-auto">
            <AuthButtonsWrapper>
              <AuthSubmitButton type="submit" width="245px">
                {isSubmitting ? (
                  <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                ) : (
                  "Log In"
                )}
              </AuthSubmitButton>
              <SideButton
                onClick={() => handleViewChange(signInViews.FORGOT_PASSWORD)}
              >
                Forgot Password?
              </SideButton>
            </AuthButtonsWrapper>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInComponent;
