import React from "react";
import { InputContainer, RadioInputcContainer } from "../../styled";
import { CustomLabel } from "src/utils/shared/styled";

// option= {title , value}
const LabeledRadioInput = ({ label, onChange, value, options = [] }) => {
  function isSelected(option) {
    return value === option.value;
  }
  return (
    <InputContainer>
      <div>
        <CustomLabel>{label}:</CustomLabel>
      </div>
      <RadioInputcContainer>
        {options.map((option) => {
          return (
            <label key={option.value}>
              <input
                type="radio"
                checked={isSelected(option)}
                onChange={(e) => onChange(option.value)}
              />{" "}
              {option.title}
            </label>
          );
        })}
      </RadioInputcContainer>
    </InputContainer>
  );
};

export default LabeledRadioInput;
