import styled from "styled-components";

export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "300px")};
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fbf8f9";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
