import React from "react";
import { useContext } from "react";
import { TrialLessonsContext } from "src/components/Dashboard/TrialLessons/TrialLessons";
import { NavButton } from "src/utils/shared/styled";

const LessonsNavSection = ({
  trialLessons,
  selectedLesson,
  setSelectedLessonId,
}) => {
  const { instruments } = useContext(TrialLessonsContext);
  return trialLessons?.map((tl, i) => {
    const instrumentName = tl?.instrument
      ? Object.values(instruments).find(({ id }) => id === tl.instrument)?.name
      : null;

    const isActive = selectedLesson?.id === tl.id;
    return (
      <NavButton
        className={`${isActive ? "active" : "unactive"}`}
        onClick={() => {
          setSelectedLessonId(tl.id);
        }}
        key={tl.id}
        id={tl.id}
      >
        {instrumentName}
      </NavButton>
    );
  });
};

export default LessonsNavSection;
