import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import PaymentHistoryBody from "./PaymentHistoryBody";
import { FaInfoCircle } from "react-icons/fa";
import CustomTooltip from "../CustomTooltip";

const PaymentHistoryModal = ({
  modalData,
  selectedFilter,
  setSelectedFilter,
  selectedMonth,
  handleSelectedMonthChange,
  customStart,
  setCustomStart,
  customEnd,
  setCustomEnd,
}) => {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>
          Payment Info <FaInfoCircle id="report-info" size={20} />
          <CustomTooltip anchorSelect="#report-info" style={{ fontSize: 18 }}>
            Select a date range to display all payments for the period
          </CustomTooltip>
        </h2>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <PaymentHistoryBody
          closeModal={modalData.closeModal}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedMonth={selectedMonth}
          handleSelectedMonthChange={handleSelectedMonthChange}
          customStart={customStart}
          setCustomStart={setCustomStart}
          customEnd={customEnd}
          setCustomEnd={setCustomEnd}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default PaymentHistoryModal;
