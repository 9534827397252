import {
  AttachmentsItemContainer,
  StyledLink,
  TextButton,
} from "../../../styled-components/teacherLibraryStyledComponents";
import LibraryLabeledCheckbox from "../../common/LibraryLabeledCheckbox";
import { useContext } from "react";
import { LibraryContext } from "../../common/libraryContext";

const StudentUploadSingleItem = ({ item, checked, showActions, showItem }) => {
  const { id, title, fullName, fileId, attachmentType } = item;
  const { select, shareOne, downloadOne } = useContext(LibraryContext);
  return (
    <AttachmentsItemContainer
      className={"d-flex justify-content-between align-items-center"}
    >
      <div className={"d-flex align-items-center"}>
        <LibraryLabeledCheckbox
          id={id}
          onChange={() => select(id)}
          checked={checked}
        />
        <TextButton className={"ms-2"} onClick={() => showItem(id)}>
          {title}
        </TextButton>
        {/* <span class="mx-2 text-black">|</span> */}
        <i className="fa fa-at ms-2 text-black"></i>
        <TextButton
          onClick={() => {
            alert(
              "This should either display the student details or navigate to the student profile page"
            );
          }}
        >
          {fullName}
        </TextButton>
      </div>
      {showActions && (
        <div className={"d-flex align-items-center"}>
          <TextButton onClick={() => downloadOne(attachmentType, fileId)}>
            <i className="fa fa-cloud-download-alt" />
          </TextButton>
          <TextButton className={"ms-2"} onClick={() => shareOne(id)}>
            <i className="fa fa-share" />
          </TextButton>
        </div>
      )}
    </AttachmentsItemContainer>
  );
};
export default StudentUploadSingleItem;
