import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useRegistrationContext } from "../../../../../../../contexts/RegisterationContext";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { modalMap } from "../../TableLayout/utils/constants";

const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const RegistrationModal = ({ componentList, modalData }) => {
  const { step } = useRegistrationContext();

  const renderCurrentRegistraionComponent = () => {
    return componentList[step]?.component;
  };

  return (
    <div>
      <Modal
        size="lg"
        style={{ maxWidth: "1050px" }}
        className="min-w-50  text-dark"
        centered
        isOpen={modalData.isModalNameOpen(modalMap.registration.name)}
        toggle={modalData.toggleModal}
      >
        <ModalHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="px-5 pb-0 border-bottom-0 "
          toggle={modalData.toggleModal}
        >
          <span className="fs-3 fw-bold">{componentList[step]?.title}</span>
        </ModalHeader>
        <ModalBody className="px-5">
          {/* <FirstTeacherRegister /> */}
          {renderCurrentRegistraionComponent()}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RegistrationModal;
