import { useContext, useEffect } from "react";
import { updateDoc } from "firebase/firestore";
import store from "src/stores/UserStore";

import Profile from "../../Profile";
import { FirebaseContext } from "src/components/Firebase";

const GuestProfile = () => {
  const { user, setStatus } = store;
  const firebase = useContext(FirebaseContext);
  const userToUpdate = firebase.user(user.uid);

  useEffect(() => {
    (async () => {
      if (
        user.emailVerified &&
        user.status !== "On review" &&
        user.status !== "Approved" &&
        user.status !== "Declined"
      ) {
        await updateDoc(userToUpdate, {
          status: "Need profile information",
        });
        setStatus("Need profile information");
      }
    })();
  }, [setStatus, user, userToUpdate]);

  return <Profile user={store.user} />;
};

export default GuestProfile;
