import "./style.css";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "./images/closeIcon.svg";
import emailIcon from "./images/emailIcon.svg";
import { FirebaseContext } from "../../../Firebase";
import { updateDoc } from "firebase/firestore";
import { Spinner } from "reactstrap";
import updateStore from "../../common/updateUserStore";
import handlePhoneChange from "../../common/handlePhoneChange";
import { isStudent } from "src/constants/UserRoleEnum";

function ProfileModal({
  activeStatus,
  changeModalData,
  fieldName,
  headerText,
  inputType,
  bodyText,
  uid,
  email,
  otherSettings,
  placeHolder,
  role,
}) {
  const isStudentUser = isStudent(role);

  //

  // states
  const [inputValue, setInputValue] = useState("");
  const [recentSignIn, setRecentSignIn] = useState(false);

  const [headerTextState, setHeaderTextState] = useState();
  const [inputTypeState, setInputType] = useState();
  const [fieldNameState, setFieldName] = useState();
  const [bodyTextState, setBodyText] = useState();
  const [activeStatusState, setActiveStatusState] = useState();
  const [placeHolderState, setPlaceHolderState] = useState();
  const [otherSettingsState, setOtherSettingsState] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const firebase = useContext(FirebaseContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user = firebase.user(uid);
    e.target.reset();
    // ahmed@teacher13.com
    if (fieldNameState === "password") {
      firebase
        .signInWithEmailAndPassword(email, inputValue)
        .then((res) => {
          setRecentSignIn(true);
          setHeaderTextState("Email");
          setInputType("email");
          setFieldName("email");
          setBodyText(
            <p>
              This new email address will be your new login <br />
              username
            </p>
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (fieldNameState === "email") {
      firebase
        .updateEmail(inputValue)
        .then(() => {
          updateDoc(user, { primaryEmail: inputValue }).then((res) => {});
          // Update successful
          setIsLoading(false);
          setResponse("Success");
          updateStore(firebase);
          setTimeout(() => {
            setActiveStatusState(false);
            setResponse(false);
            setInputValue(undefined);
            e.target.value = undefined;
          }, 1000);
          // ...
        })
        .catch((error) => {
          console.log(error);
          // An error occurred
          // ...
        });
    } else {
      updateDoc(user, { [fieldNameState]: inputValue })
        .then((res) => {
          setIsLoading(false);
          setResponse("Success");
          updateStore(firebase);
          setTimeout(() => {
            setResponse(false);
            setInputValue(undefined);
            setActiveStatusState(false);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // ahmed@teacher13.com
  };

  // managing the default states based on the props
  useEffect(() => {
    setOtherSettingsState(otherSettings);
    setPlaceHolderState(placeHolder);
    if (!recentSignIn && fieldName === "email") {
      setHeaderTextState("Confirm your password");
      setInputType("password");
      setFieldName("password");
      setBodyText(
        <p>
          {" "}
          you would need to confirm your password <br /> to change the email{" "}
        </p>
      );
      setActiveStatusState(activeStatus);
    } else {
      setHeaderTextState(headerText);
      setInputType(inputType);
      setFieldName(fieldName);
      if (inputType === "tel") {
        setInputValue("");
        setPlaceHolderState("(123) 123-1234");
        setOtherSettingsState({
          pattern: `\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}`,
          maxLength: 14,
          onInvalid: (e) =>
            e.target.setCustomValidity(
              "Please Match this format (123) 123-1234"
            ),
          onInput: (e) => e.target.setCustomValidity(""),
          ...otherSettings,
        });
      }
      setBodyText(bodyText);
      setActiveStatusState(activeStatus);
    }
  }, [fieldName]);

  return (
    <div
      className={
        "profile-modal-container" + (activeStatusState ? " active" : "")
      }
    >
      <div className={"profile-modal" + (isStudentUser ? " student" : "")}>
        <header>
          <picture>
            <img src={emailIcon} alt="email icon" />
          </picture>
          <h2>
            {" "}
            {fieldNameState === "password"
              ? "Confirm Your Password"
              : "Edit " + headerTextState}
          </h2>
          <picture
            className="close-icon"
            onClick={() => {
              changeModalData({ activeStatusState: false });
            }}
          >
            <img src={closeIcon} alt="close icon" />
          </picture>
        </header>
        <form onSubmit={handleSubmit}>
          <label htmlFor={headerTextState} name={headerTextState}>
            {fieldNameState === "password"
              ? "Confirm Your Password"
              : fieldNameState === "name"
              ? "Full name"
              : headerTextState}
          </label>

          <input
            required
            htmlFor={headerTextState}
            className="profile-modal-input"
            name={headerTextState}
            placeholder={placeHolderState ? placeHolderState : headerTextState}
            type={inputTypeState}
            value={inputType === "tel" ? inputValue : undefined}
            key={inputType + headerText}
            onChange={(e) => {
              e.preventDefault();
              if (inputTypeState === "tel") {
                // setInputValue(e.target.value);
                handlePhoneChange(e.target.value, setInputValue);
              } else {
                setInputValue(e.target.value);
              }
            }}
            {...otherSettingsState}
          />
          {bodyTextState ? (
            bodyTextState
          ) : (
            <p>
              {" "}
              You will be changing your {headerTextState} <br />
              <br />
            </p>
          )}
          <fieldset className="profile-modal-submit-container">
            <button
              className="profile-modal-submit"
              type="submit"
              name="submit"
              disabled={isLoading || response ? true : false}
            >
              {!isLoading && !response && "Confirm"}
              {isLoading && <Spinner />}
              {!isLoading && response && response}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default ProfileModal;
