import {
  isGroupClassEvent,
  isMakeupLessonEvent,
  isPrivateLessonEvent,
  isTrialLessonEvent,
} from "src/constants/eventsEnum";
import EventContent from "./EventContent";
import {
  BodyInnerWrapper,
  CloseIcon,
  CurveContainer,
  CustomBody,
  CustomHeader,
  CustomModal,
} from "./styled";
import GroupClassEventContent from "../../../components/EventDetailsModal/GroupClassEventContent";

const StudentEventDetailsModal = ({
  modalData,
  event,
  initialData,
  openAbsenceModal,
}) => {
  const isPrivateLesson = isPrivateLessonEvent(event.eventCode);
  const isTrialLesson = isTrialLessonEvent(event.eventCode);
  const isMakeupLesson = isMakeupLessonEvent(event.eventCode);
  const isGroupClass = isGroupClassEvent(event.eventCode);

  const renderCurrentModalData = () => {
    if (isPrivateLesson) {
      return (
        <EventContent
          event={event}
          initialData={initialData}
          openAbsenceModal={openAbsenceModal}
        />
      );
    } else if (isTrialLesson) {
      return (
        <EventContent
          event={event}
          initialData={initialData}
          openAbsenceModal={openAbsenceModal}
        />
      );
    } else if (isMakeupLesson) {
      return (
        <EventContent
          event={event}
          initialData={initialData}
          openAbsenceModal={openAbsenceModal}
        />
      );
    } else if (isGroupClass) {
      return <GroupClassEventContent event={event} />;
    } else {
      return <div></div>;
    }
  };
  return (
    <div>
      <CustomModal
        size="md"
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        ></CustomHeader>
        <CustomBody>
          <CurveContainer>
            <svg
              width="100%"
              height="100%"
              id="svg"
              viewBox="0 0 1440 300"
              xmlns="http://www.w3.org/2000/svg"
              class="transition duration-300 ease-in-out delay-150"
            >
              <path
                d="M 0,400 C 0,400 0,200 0,200 C 107.46666666666664,243.60000000000002 214.93333333333328,287.20000000000005 400,271 C 585.0666666666667,254.79999999999998 847.7333333333333,178.79999999999998 1034,157 C 1220.2666666666667,135.20000000000002 1330.1333333333332,167.60000000000002 1440,200 C 1440,200 1440,400 1440,400 Z"
                stroke="none"
                stroke-width="0"
                fill="#681e46"
                class="transition-all duration-300 ease-in-out delay-150 path-0"
                transform="rotate(-180 720 200)"
              ></path>
            </svg>
          </CurveContainer>
          <BodyInnerWrapper>{renderCurrentModalData()}</BodyInnerWrapper>
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default StudentEventDetailsModal;
