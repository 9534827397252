import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 60%;
  min-height: 300px;
  margin: auto;
  box-shadow: 0px 0px 8px 2px #d5d5d5;
  background: whitesmoke;
  border-radius: 15px;
  padding: 30px;
`;
export const InputsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: end;
`;
export const InputItemContainer = styled.div`
  /* flex: 1; */
  width: 200px;
`;
