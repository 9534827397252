import React, { useState } from "react";
import { toast } from "react-toastify";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import {
  isApprovedMakeupRequest,
  makeupRequestStatuses,
} from "src/constants/makeupRequestEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useMaxMakeUps = ({
  user,
  closeMakeupModal,
  absenceId,
  lessonId,
  onSuccess = () => {},
}) => {
  const { createMakeupRequest } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);

  async function sendMakeupRequest() {
    try {
      setLoadingAction(true);

      const isAdmin = hasAdminCredentials(user.role);

      const makeupRequestStatus = isAdmin
        ? makeupRequestStatuses.APPROVED
        : makeupRequestStatuses.PENDING;
      const resolveObj = {
        userId: user.uid,
        userRole: user.role,
        resolvedAt: new Date(),
      };

      const makeupRequestObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        createdByRole: user.role,
        forAbsenceId: absenceId,
        forLessonId: lessonId,
        status: makeupRequestStatus,
        ...(isApprovedMakeupRequest(makeupRequestStatus) ? resolveObj : {}),
      };
      console.log({ makeupRequestObj });
      await createMakeupRequest(makeupRequestObj);
      toast.success(
        isAdmin
          ? "Success !"
          : "Your Request has been sent, Artis Admin will let you know soon for booking"
      );
      closeMakeupModal();
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  return { loadingAction, sendMakeupRequest };
};

export default useMaxMakeUps;
