import React from "react";
import { NavButton } from "src/utils/shared/styled";
import { adminsPaymentPageTabs } from "../../constants";

const PageNavSection = ({ currentPage, setCurrentPage }) => {
  return (
    <div className="d-flex align-items-center">
      {Object.values(adminsPaymentPageTabs).map((tab) => {
        return (
          <div>
            <NavButton
              withRedBar
              className={currentPage === tab.value ? "active" : undefined}
              onClick={() => setCurrentPage(tab.value)}
            >
              {tab.title}
            </NavButton>
          </div>
        );
      })}
    </div>
  );
};

export default PageNavSection;
