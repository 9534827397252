import store from "src/stores/UserStore";
import {
  isAdminApplicant,
  isGuestStudent,
  isPartnerApplicant,
  isTeacherApplicant,
} from "src/constants/UserRoleEnum";

import TeacherSecondPage from "./TeacherSecondPage";
import StudentSecondPage from "./StudentSecondPage";
import RouteContent from "src/components/common/RouteContent";
import PartnerSecondPage from "./PartnerSecondPage";
import AdminSecondPage from "./AdminSecondPage";

const GuestProfileSecondPage = () => {
  const { user } = store;

  return (
    <RouteContent title={user?.role.replace(/_/g, " ") + " Profile"}>
      {isTeacherApplicant(user?.role) && <TeacherSecondPage />}
      {isGuestStudent(user?.role) && <StudentSecondPage />}
      {isPartnerApplicant(user?.role) && <PartnerSecondPage />}
      {isAdminApplicant(user?.role) && <AdminSecondPage />}
    </RouteContent>
  );
};

export default GuestProfileSecondPage;
