import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getCurrentSchoolYear,
  getPrivateLessonInfoOnSpecificDate,
  getTheTimelineObjBeforeSummerBreak,
  updatedMomentNow,
} from "src/utils/helpers";

const initialFormValues = {
  breakStart: null,
  breakEnd: null,
};
const initialDataInitialValues = {
  privateLesson: null,
  schoolYears: [],
};
const useEditSummerBreak = ({
  privateLessonId,
  summerBreakId,
  user,
  onEditSuccess = () => {},
}) => {
  const {
    getPrivateLessonsByIds,
    getSchoolYears,
    getUserActivityById,
    updatePrivateLesson,
    updateUserActivity,
  } = useFirebaseFns();

  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [initialData, setInitialData] = useState(initialDataInitialValues);

  const [formData, setFormData] = useState(initialFormValues);
  const [isBreakStartPickerOpen, setIsBreakStartPickerOpen] = useState(false);
  const [isBreakEndPickerOpen, setIsBreakEndPickerOpen] = useState(false);

  const [loadingAction, setLoadingAction] = useState(false);

  function handleFormChange(name, value) {
    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  async function submitChanges() {
    try {
      if (!initialData.privateLesson) {
        return;
      }
      if (!formData.breakStart) {
        toast.warn("Must provide the break start date");
        return;
      }
      setLoadingAction(true);

      const originalSummerBreak = initialData.privateLesson.summerBreaks.find(
        ({ id }) => id === summerBreakId
      );

      // getting the startDate of the timeline that is previous to this summer break
      const { startDate } =
        getTheTimelineObjBeforeSummerBreak(
          originalSummerBreak,
          initialData.privateLesson.timeline
        ) || {};

      if (!startDate) {
        toast.error("Something Went Wrong");
        return;
      }

      const fullBreakStart = moment(formData.breakStart.toDate())
        .set({
          hours: moment(startDate).hours(),
          minutes: moment(startDate).minutes(),
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();

      const updatedSummerBreaks = initialData.privateLesson.summerBreaks?.map(
        (summerBreak) => {
          if (summerBreak.id === summerBreakId) {
            return {
              ...summerBreak,
              breakStart: fullBreakStart,
              // resets the breakEnd value
              ...(!formData.breakEnd && {
                breakEnd: null,
              }),
            };
          } else {
            return summerBreak;
          }
        }
      );
      const updateObj = {
        summerBreaks: updatedSummerBreaks,
      };

      const activity = await getUserActivityById(privateLessonId);
      const currentCanceledRanges = activity.canceledRanges || [];

      const updatedCanceledRanges = currentCanceledRanges.map((range) => {
        if (
          moment(originalSummerBreak.breakStart).isSame(range.start, "minutes")
        ) {
          return {
            ...range,
            start: fullBreakStart,
            ...(!formData.breakEnd && {
              end: null,
            }),
          };
        } else {
          return range;
        }
      });

      const activityUpdateObj = {
        canceledRanges: updatedCanceledRanges,
      };
      console.log({ activityUpdateObj, updateObj, moment });
      await Promise.all([
        updatePrivateLesson(privateLessonId, updateObj),
        updateUserActivity(privateLessonId, activityUpdateObj),
      ]);

      toast.success("Updated Successfully!");
      onEditSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);
        const [[privateLesson], schoolYears] = await Promise.all([
          getPrivateLessonsByIds([privateLessonId]),
          getSchoolYears(),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          privateLesson,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };

    fetchData();
  }, [privateLessonId, summerBreakId]);

  // setting initial form values
  useEffect(() => {
    if (!initialData.privateLesson) return;
    const summerBreak = initialData.privateLesson.summerBreaks.find(
      ({ id }) => id === summerBreakId
    );
    if (!summerBreak) return;

    const { breakStart, breakEnd } = summerBreak;
    setFormData((oldVal) => ({
      ...oldVal,
      breakStart: moment(breakStart),
      ...(breakEnd && { breakEnd: moment(breakEnd) }),
    }));
  }, [initialData]);

  const currentLessonInfo = useMemo(() => {
    if (!initialData.privateLesson) return;

    const lessonInfo = getPrivateLessonInfoOnSpecificDate({
      date: updatedMomentNow(),
      privateLesson: initialData.privateLesson,
      withTimelineApproximation: true,
    });

    return lessonInfo;
  }, [initialData]);

  const currentSchoolYearInLocation = useMemo(() => {
    if (!initialData.schoolYears.length || !initialData.privateLesson) return;

    const { locationId } = getPrivateLessonInfoOnSpecificDate({
      date: updatedMomentNow(),
      privateLesson: initialData.privateLesson,
      withTimelineApproximation: true,
    });
    const currentSchoolYear = getCurrentSchoolYear(
      initialData.schoolYears,
      locationId
    );
    return currentSchoolYear;
  }, [initialData]);

  return {
    initialData,
    loadingInitialData,
    loadingAction,
    submitChanges,
    handleFormChange,
    formData,
    isBreakStartPickerOpen,
    setIsBreakStartPickerOpen,
    isBreakEndPickerOpen,
    setIsBreakEndPickerOpen,
    currentSchoolYearInLocation,
    currentLessonInfo,
  };
};

export default useEditSummerBreak;
