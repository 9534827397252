import React, {useContext} from "react";
import LabeledRadioButton from "../common/LabeledRadioButton";
import {LibraryModalInput} from "../../styled-components/teacherLibraryStyledComponents";
import LibraryTeachersList from "../tab-content/teachers-list/LibraryTeachersList";
import {TeachersContext} from "../common/libraryContext";

const ShareModalStudentView = ({email, setView, view, setEmail}) => {
  const {teachers} = useContext(TeachersContext);
  const renderBodyView = () => {
    switch (view) {
      case "email":
        return (
          <div className="mb-5">
            <div className="d-flex flex-column">
              <LibraryModalInput
                formik={false}
                label={"Email"}
                name="Email"
                placeholder="Email"
                height={"40px"}
                size={12}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="text-muted mb-3">This field is required</div>
            </div>
          </div>
        );
      case 'teacher':
        return (
          <div className="col pt-2 mb-2">
            <h4 className="mb-2 text-center">Select Teacher</h4>
            <LibraryTeachersList fullWidth={true}/>
          </div>
        );
      case 'school':
        return (<h3 className={"text-center"}>Share with the school.</h3>);
    }
  };
  return (
    <>
      <div className="mb-2 d-flex align-items-center px-3">
        {teachers.length > 0 ?
          <LabeledRadioButton
            label={"teacher"}
            onChange={setView}
            checked={view === "teacher"}
            id={"libraryViewTeacher"}
          /> : null}
        <LabeledRadioButton
          label={"school"}
          onChange={setView}
          checked={view === "school"}
          id={"libraryViewSchool"}
        />
        <LabeledRadioButton
          label={"email"}
          onChange={setView}
          checked={view === "email"}
          id={"libraryViewEmail"}
        />
      </div>
      <div className="px-3">
        {renderBodyView()}
      </div>
    </>
  )
}
export default ShareModalStudentView
