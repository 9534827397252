import React from "react";
import StudentLibrary from "./StudentLibrary";
import RouteContent from "../../../common/RouteContent";
const StudentLibraryRoute = ({ user }) => {
  return (
    <RouteContent title={"Student Library"}>
      <StudentLibrary user={user} />
    </RouteContent>
  );
};
export default StudentLibraryRoute;
