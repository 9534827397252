const packagesEnum = {
  "1_CLASS_PACKAGE": "1_class",
  "10_CLASSES_PACKAGE": "1_classes",
  GROUPS_PACKAGE: "groups",
  REGULAR_PACKAGE: "regular",
};

const prices = {
  [packagesEnum["1_CLASS_PACKAGE"]]: 36,
  [packagesEnum["10_CLASSES_PACKAGE"]]: 680,
  [packagesEnum["GROUPS_PACKAGE"]]: 100,
  [packagesEnum["REGULAR_PACKAGE"]]: 100,
};

const packageOptions = [
  {
    title: "1 class package",
    text: "Try any instrument with any available teacher",
    price: prices[packagesEnum["1_CLASS_PACKAGE"]],
    value: packagesEnum["1_CLASS_PACKAGE"],
  },
  {
    title: "10 classes package",
    text: "Rsrv. -- Pre-Pay and Schedule 10 classes",
    price: prices[packagesEnum["10_CLASSES_PACKAGE"]],
    value: packagesEnum["10_CLASSES_PACKAGE"],
  },
  {
    title: "Groups package",
    text: "Pre-Rsrved Schedule Deposit",
    price: prices[packagesEnum["GROUPS_PACKAGE"]],
    value: packagesEnum["GROUPS_PACKAGE"],
  },
  {
    title: "Regular package",
    text: "Tuition deposit into scheduling orientation enrolment ",
    price: prices[packagesEnum["REGULAR_PACKAGE"]],
    value: packagesEnum["REGULAR_PACKAGE"],
  },
];

export { packagesEnum, prices, packageOptions };
