import React, { useState } from "react";
import { InputContainer } from "../../styled";
import {
  CalendarPickerWrapper,
  CustomInput,
  CustomLabel,
} from "src/utils/shared/styled";
import { SingleDatePicker } from "react-dates";

const LabeledDateInput = ({ label, value, onChange, ...props }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  return (
    <InputContainer>
      <CustomLabel>{label}:</CustomLabel>
      <CalendarPickerWrapper padding={"6px 11px 6px"}>
        <SingleDatePicker
          date={value}
          onDateChange={onChange}
          focused={isPickerOpen} // PropTypes.bool
          onFocusChange={({ focused }) => {
            setIsPickerOpen(focused);
          }}
          // numberOfMonths={1}
          // showClearDate={true}
          isOutsideRange={(date) => {
            return false;
          }}
          {...props}
        />
      </CalendarPickerWrapper>
    </InputContainer>
  );
};

export default LabeledDateInput;
