import React from "react";
import DisableUserModal from "src/components/Dashboard/common/components/DisableUserModal";
import useModal from "src/hooks/useModal";
import DropdownButton from "src/components/Dashboard/common/components/DropdownButton";
import useTeacherActions from "./hooks/useTeacherActions";

const TeacherActions = ({ teacher, refreshData, closeTeacherInfoModal }) => {
  const { toggleTeacherLoginStatus, loadingAction, isLoginDisabledUser } =
    useTeacherActions({
      teacherId: teacher?.id,
      onSuccess: () => {
        closeTeacherInfoModal();
        refreshData();
      },
    });

  const disableUserModalData = useModal();

  const optionsList = [
    isLoginDisabledUser
      ? {
          title: "Turn on Platform Access",
          action: () => toggleTeacherLoginStatus(true),
        }
      : {
          title: "Turn off Platform Access",
          action: () => toggleTeacherLoginStatus(false),
        },
    {
      title: "Delete User",
      action: disableUserModalData.openModal,
    },
  ];

  return (
    <div className="text-end">
      <DropdownButton loadingAction={loadingAction} optionsList={optionsList} />

      {/* MODALS */}
      {disableUserModalData.isModalOpen && teacher.id && (
        <DisableUserModal
          modalData={disableUserModalData}
          userId={teacher.id}
          onSuccess={() => {
            closeTeacherInfoModal();
            refreshData();
          }}
        />
      )}
    </div>
  );
};

export default TeacherActions;
