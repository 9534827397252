import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useMemo } from "react";
import { toast } from "react-toastify";
import InfoDropdown from "src/components/Dashboard/common/components/InfoDropdown";
import { groupClassEnrollmentTypes } from "src/constants/groupClassEnrollmentsConstants";
import { groupClassPaymentOptionsMap } from "src/constants/groupClassPaymentsConstants";
import {
  capturePaypalGroupClassOrder,
  createPaypalOrder,
} from "src/utils/apiFns";
import {
  getGroupClassPaymentOptionsTotal,
  injectUserStore,
} from "src/utils/helpers";

const DirectPaymentButton = ({
  paymentOption,
  onPaymentSuccess,
  studentId,
  studentType,
  groupClassId,
  UserStore,
}) => {
  const user = UserStore.user;

  const { paymentType, optionPayments, forStudentType } = paymentOption;

  const renderPaymentInfoBody = () => {
    return (
      <div>
        {optionPayments?.map((option) => {
          const { type, amount, id } = option;
          const optionObj = Object.values(groupClassPaymentOptionsMap).find(
            ({ value }) => value === type
          );
          const optionName = optionObj?.title1;

          return (
            <div className="mb-3" key={id}>
              <span className="fw-bold">{optionName}</span>
              <span className="fw-normal"> - ${amount}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const paymentTotal = useMemo(() => {
    const total = getGroupClassPaymentOptionsTotal(
      paymentOption.optionPayments
    );
    return total;
  }, [paymentOption]);

  console.log({ paymentOption });

  async function createOrder(data, actions) {
    console.log("CREATE ORDER");
    const purchaseUnits = [
      {
        amount: {
          value: paymentTotal,
          currency_code: "USD",
        },
      },
    ];
    const orderData = await createPaypalOrder(purchaseUnits);
    console.log({ orderData });

    return orderData?.data?.id;
  }

  async function captureOrder(data, actions) {
    console.log("APPROVE ORDER");

    const groupClassData = {
      createdBy: user.uid,
      groupClassId,
      studentId,
      studentType,
      enrollmentType: groupClassEnrollmentTypes.REGULAR,
      enrollmentDate: new Date(),
      paymentOptionId: paymentOption.id,
    };
    const captureRes = await capturePaypalGroupClassOrder(
      data.orderID,
      groupClassData
    );
    console.log({ captureRes });

    toast.success("PAYMENT APPROVED");
    await onPaymentSuccess();

    // return captureRes;
  }

  return (
    <div className="mt-4">
      <h6 className="text-center mb-3">
        Pay: ${paymentTotal}
        <InfoDropdown renderBodyContent={renderPaymentInfoBody} />
      </h6>
      <PayPalButtons
        // style={{ layout: "horizontal" }}
        onApprove={captureOrder}
        onCancel={(data, actions) => {
          // console.log("CANCELED", { data, actions });
        }}
        onError={(err) => {
          // console.log("ERROR", { err });
        }}
        onClick={(data, actions) => {
          // console.log("CLICKED", { data, actions });
        }}
        createOrder={createOrder}
        // createOrder={(data, actions) =>
        //   actions.order.create({
        //     intent: "AUTHORIZE",
        //     purchase_units: [{ amount: { currency_code: "EUR" } }],
        //   })
        // }
      />
    </div>
  );
};

export default injectUserStore(DirectPaymentButton);
