import React, { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useReactivation = ({ teacherId }) => {
  const { updateUserInfo } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);

  async function reactivateTeacher() {
    if (!teacherId) {
      toast.error("Can't reactivate teacher");
      return;
    }

    try {
      setLoadingAction(true);

      const updateObj = {
        disableObj: null,
      };
      await updateUserInfo(teacherId, updateObj);
      toast.success("Reactivated Successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  return { loadingAction, reactivateTeacher };
};

export default useReactivation;
