import { useEffect } from "react";
import { connect } from "formik";
const ErrorFormFocus = ({ formik }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
    }
  }, [formik]);

  return null;
};

export default connect(ErrorFormFocus);
