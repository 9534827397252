import "./style.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Toolbar from "./ToolBar";
import moment from "moment";

import React from "react";
import useCalendarData from "./hooks/useCalendarData";
import { mapSchoolBreakToScheduleEvent } from "src/components/Dashboard/Schedule/helperFns";
import { useMemo } from "react";
var localizer = momentLocalizer(moment);
function CustomCalendar() {
  const { combinedSchoolBreaks } = useCalendarData();

  const schoolBreaksCalendarEvents = useMemo(
    () =>
      combinedSchoolBreaks.map((schoolBreak) =>
        mapSchoolBreakToScheduleEvent(schoolBreak)
      ),
    [combinedSchoolBreaks]
  );

  const events = schoolBreaksCalendarEvents;

  const eventPropGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: "#f27180",
        color: "#fff",
      },
    };
  };

  return (
    <figure className="calendar">
      <Calendar
        eventPropGetter={eventPropGetter}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month", "week", "day", "agenda"]}
        components={{ toolbar: Toolbar }}
      />
    </figure>
  );
}

export default CustomCalendar;
