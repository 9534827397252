import React from "react";
import { Spinner } from "reactstrap";

const SubmitSpinner = (props) => (
  <Spinner
    style={
      !props.style && {
        width: "3rem",
        height: "3rem",
        color: "#fff",
      }
    }
    {...props}
  />
);
export default SubmitSpinner;
