import {
  ActionsContainer,
  TextButton,
} from "../../../styled-components/teacherLibraryStyledComponents";
import { useContext } from "react";
import {
  ActiveTabContext,
  DownloadItemsModalContext,
  LibraryContext,
} from "../../common/libraryContext";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

const AllActions = () => {
  const { shareAll, deleteAll, downloadAll } = useContext(LibraryContext);
  const { width } = useWindowDimensions();

  return (
    <ActionsContainer>
      {/* <TextButton onClick={downloadAll}>
        {isDesktopScreen ? "Download All" : ""}
        <i className="fa fa-cloud-download-alt ms-2" />
      </TextButton> */}
      <TextButton
        onClick={() => {
          shareAll();
        }}
      >
        Share All
        <i className="fa fa-share ms-2" />
      </TextButton>
      <TextButton
        onClick={() => {
          deleteAll();
        }}
      >
        Delete All
        <i className="fa fa-trash ms-2" />
      </TextButton>
    </ActionsContainer>
  );
};
export default AllActions;
