import React from "react";
import {
  AddNewAmountBtn,
  CustomInput,
  CustomLabel,
  InputContainer,
  InputRow,
  SectionContainer,
} from "../../../../styled";
import NumberInput from "../../shared/NumberInput";
import { paymentAmountTypes } from "../../../../constants";
import PaymentAmountsSelection from "../../PaymentAmountsSelection";

const OneTimePaymentSection = ({
  paymentFormInputs,
  isAvailableForAll,
  isAvailableForPublic,
  isAvailableForCurrentStudents,
  handlePaymentFormInputChange,
  handleAddNewPaymentAmount,
  handleDeletePaymentAmount,
  handleEditPaymentAmount,
}) => {
  const showPublicAmount = isAvailableForAll || isAvailableForPublic;
  const showCurrentStudentsAmount =
    isAvailableForAll || isAvailableForCurrentStudents;
  return (
    <SectionContainer>
      <h5>One Time Payment Info</h5>
      <hr />
      <div>
        <InputRow>
          {showPublicAmount && (
            <SectionContainer width="100%">
              <div className="d-flex justify-content-between">
                <h6>New Students Amounts:</h6>
                <div>
                  <AddNewAmountBtn
                    onClick={() =>
                      handleAddNewPaymentAmount(
                        paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS
                      )
                    }
                  >
                    Add New
                  </AddNewAmountBtn>
                </div>
              </div>
              <hr />
              <PaymentAmountsSelection
                paymentFormInputs={paymentFormInputs}
                amountType={paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS}
                handleDeletePaymentAmount={handleDeletePaymentAmount}
                handleEditPaymentAmount={handleEditPaymentAmount}
              />
            </SectionContainer>
          )}
        </InputRow>
        <InputRow>
          {showCurrentStudentsAmount && (
            <SectionContainer width="100%">
              <div className="d-flex justify-content-between">
                <h6>Current Students Amounts:</h6>
                <div>
                  <AddNewAmountBtn
                    onClick={() =>
                      handleAddNewPaymentAmount(
                        paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS
                      )
                    }
                  >
                    Add New
                  </AddNewAmountBtn>
                </div>
              </div>
              <hr />
              <PaymentAmountsSelection
                paymentFormInputs={paymentFormInputs}
                amountType={paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS}
                handleDeletePaymentAmount={handleDeletePaymentAmount}
                handleEditPaymentAmount={handleEditPaymentAmount}
              />
            </SectionContainer>
          )}
        </InputRow>
      </div>
    </SectionContainer>
  );
};

export default OneTimePaymentSection;
