import React, { useContext } from "react";
import {
  LessonNotesContext,
  SubmitHomeworkModalContext,
  ViewHomeworkModalContext,
  ViewNoteModalContext,
} from "../../../common/Library/common/libraryContext";

import {
  HomeworkModalContainer,
  HomeworkNoteContainer,
} from "../../../common/styled-components/lessonNotesStyledComponents";
import moment from "moment";
import BaseNoteModal from "../BaseNoteModal";
import NoteAttachmentsList from "../NoteAttachmentsList";
import { useRef } from "react";
import { toast } from "react-toastify";
import { StyledCopyIcon } from "src/utils/shared/styled";
import { Interweave } from "interweave";
import { handleCopyText } from "src/utils/helpers";

const ViewNoteModal = () => {
  const { isOpen, close, note } = useContext(ViewNoteModalContext);
  isOpen && console.log({ note });
  const { userId, isEditable } = useContext(LessonNotesContext);
  const { open: openSubmitHomework } = useContext(SubmitHomeworkModalContext);
  const { openHomework } = useContext(ViewHomeworkModalContext);

  const noteElementRef = useRef();

  const handleCopyNote = () => {
    const noteElement = noteElementRef.current;
    const textValue = noteElement.innerHTML;

    // Copy the text inside the text field
    handleCopyText(textValue);
  };

  const modalBody = () => (
    <HomeworkModalContainer>
      <p className="lead mb-2">
        Lesson Date: {moment(note.date.toDate()).format("MM-DD-YYYY")}
      </p>
      <p className="lead mb-2">Title: {note.title}</p>
      <HomeworkNoteContainer height="500px" className={"mb-3"}>
        <div className="text-end">
          <StyledCopyIcon onClick={handleCopyNote} width={30} height={30} />
        </div>
        <div ref={noteElementRef}>
          <Interweave content={note.description} />
        </div>
      </HomeworkNoteContainer>

      {note.isEdited ? (
        <p className="text-muted text-sm mb-3">
          <em>
            Edited at:{" "}
            {moment(note.updatedAt.toDate()).format("MM-DD-YYYY, hh:mmA")}
          </em>
        </p>
      ) : null}
      <div className={"flex-wrap mb-3 d-flex align-items-center"}>
        <NoteAttachmentsList note={note} />
      </div>
    </HomeworkModalContainer>
  );
  return (
    <BaseNoteModal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Note"}
      isOpen={isOpen}
      actionButtonColor={"success"}
      buttonText={
        isEditable
          ? "View Homework"
          : note.homework?.[userId]
          ? "View My Homework"
          : "Submit My Homework"
      }
      okAction={() => {
        if (isEditable) {
          openHomework(note?.homework, note);
        } else {
          if (note.homework?.[userId]) {
            openHomework(note?.homework, note);
          } else {
            openSubmitHomework(note.id);
          }
        }
      }}
      toggle={close}
    />
  );
};
export default ViewNoteModal;
