import { useMemo } from "react";
import {
  paymentRates,
  paymentRateTypes,
  paymentsMap,
} from "src/constants/paymentsEnum";
import { getTeacherPaymentName } from "../helpers";
import store from "src/stores/UserStore";

import { CustomTableContainer } from "src/utils/shared/styled";
import { RatesList } from "../../styled";
import PaymentInfoChart from "../PaymentInfoChart";
import TotalPaymentsList from "./TotalPaymentsList";
import { Divider } from "antd";

const PrivateLessonsSection = ({
  privateLessonPayments,
  paymentRatesObj,
  teachingPeriod,
}) => {
  const { user } = store;
  const { rates } = paymentRatesObj || {};

  const privateLessonHourlyPaymentRate = rates?.find(
    (rate) =>
      paymentRateTypes.HOUR === rate.type &&
      paymentRates.regularClassRate.title === rate.title
  );
  const rateValue = privateLessonHourlyPaymentRate?.value || "Unknown Rate";

  const paymentsData = useMemo(() => {
    const payments = privateLessonPayments?.map((payment) => {
      const { type, totalPaid, fractionPerUnit } = payment;

      const paymentMap = paymentsMap?.find(
        (map) =>
          type === map.paymentType &&
          payment.fractionPerUnit === map.fractionPerUnit &&
          payment.calculatedBy === map.paymentCalculationType
      );

      return {
        type,
        name: getTeacherPaymentName(type, paymentMap),
        value: totalPaid,
        fractionPerUnit,
      };
    });

    return payments;
  }, [privateLessonPayments]);

  return (
    <div>
      <h4 className="fw-bold">Private Lessons</h4>
      <CustomTableContainer minHeight={"0px"}>
        <div className="mt-4">
          <PaymentInfoChart
            data={paymentsData}
            subtitle={teachingPeriod}
            userType="teacher"
          />
        </div>
        <Divider />
        <TotalPaymentsList payments={paymentsData} userType="teacher" />
        <Divider />
        <h5 className="fw-bold">Rates</h5>
        <RatesList>
          <p>
            <span>Private Hourly Rate:</span>
            <span>${rateValue}</span>
          </p>
        </RatesList>
      </CustomTableContainer>
      <Divider />
    </div>
  );
};

export default PrivateLessonsSection;
