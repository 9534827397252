import { useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { formatTimestamp } from "src/utils/helpers";
import { List, Image } from "antd";

const NotificationsHistory = () => {
  const [notifications, setNotifications] = useState([]);
  const { getFeatureNotifications } = useFirebaseFns();

  useEffect(() => {
    (async () => {
      const response = await getFeatureNotifications();
      setNotifications(response);
    })();
  }, []);

  return (
    <List
      style={{ width: "100%", marginTop: 32 }}
      bordered
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Image
                width={50}
                height={50}
                src={item?.screenshots[0]}
                placeholder
                preview={{
                  destroyOnClose: true,
                }}
                loading="lazy"
              />
            }
            title={formatTimestamp(item?.createdAt)}
            description={item?.title}
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          />
        </List.Item>
      )}
    />
  );
};

export default NotificationsHistory;
