import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import LessonsInfoSection from "./LessonsInfoSection";
import { injectUserStore } from "src/utils/helpers";
import { toJS } from "mobx";

import { toast } from "react-toastify";
import { NoteModalHeader } from "src/components/common/styled-components/lessonNotesStyledComponents";
import { CustomModal, CustomModalBody } from "src/utils/styled";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";

import ChangeProfileImageButton from "../ChangeProfileImageButton";
import ContactInfoSection from "./ContactInfoSection";
function ViewStudentInfoModal({ modalData, currentStudent, UserStore }) {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  const user = toJS(UserStore)?.user;

  const { privateLessons, isLoading } = useStudentInfoContext();

  const [index, setindex] = useState(0);
  const [targetLessons, settargetLessons] = useState([]);

  useEffect(() => {
    settargetLessons([]);
    const StudentLessons = currentStudent?.Privatelessons;
    if (StudentLessons) {
      const studentLessons = [];
      StudentLessons.forEach((les) => {
        for (let lesson of privateLessons) {
          if (les === lesson.id) {
            studentLessons.push(lesson);
          }
        }
      });

      const sortedLessons = [...(studentLessons || [])]?.sort((a, b) => {
        return a.hasOwnProperty("withdrawal")
          ? 1
          : b.hasOwnProperty("withdrawal")
          ? -1
          : 0;
      });
      settargetLessons(sortedLessons);
    }
  }, [currentStudent, privateLessons]);
  const handleIndex = (e) => {
    const theLesson = targetLessons.find((lesson) => e.target.id === lesson.id);
    const lessonIndex = targetLessons.indexOf(theLesson);
    setindex(lessonIndex);
  };

  if (!currentStudent) {
    modalData.closeModal();
    toast.error("Cant open student modal without the student");
    return <div></div>;
  }
  return (
    <div>
      <CustomModal
        padding="0px"
        centered
        isOpen={isModalOpen}
        toggle={toggleModal}
        // size={"lg"}
        style={{ maxWidth: "1000px" }}

        // style={{ width: "1200px" }}
      >
        <NoteModalHeader className="position-relative">
          <div className="position-absolute close-btn-container bg-white rounded-circle">
            <button
              onClick={() => {
                closeModal();
              }}
              className={"close border-0 outline-0"}
            >
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="curve-container position-absolute top-0 left-0 w-100">
            <svg
              width="100%"
              height="100%"
              id="svg"
              viewBox="0 0 750 150"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition duration-300 ease-in-out delay-150"
            >
              <path fill="#681E46" d="M0,150V0h750v150C750,150,375,70,0,150z" />
            </svg>
          </div>
          <div className="position-relative user-details d-flex justify-content-center flex-column align-items-center">
            <div className="avatar overflow-hidden d-flex align-items-center justify-content-center bg-light rounded-circle border border-4 border-white">
              {currentStudent?.imageUrl ? (
                <img src={currentStudent.imageUrl} alt="user image" />
              ) : null}
            </div>
          </div>
        </NoteModalHeader>
        <CustomModalBody padding="0px">
          <div className="pt-2 mb-4 px-4 pb-4">
            <h5 className="text-center text-capitalize mb-4">
              {currentStudent?.fullName}
              <div className="text-center mt-3">
                <ChangeProfileImageButton
                  userId={currentStudent?.id}
                  onSuccess={() => window.location.reload()}
                />
              </div>
            </h5>
            <ContactInfoSection
              closeModal={closeModal}
              currentStudent={currentStudent}
            />
          </div>
          {/*  */}

          <>
            {currentStudent?.Privatelessons?.length && !isLoading ? (
              <>
                <hr className="CenterLine" />
                <LessonsInfoSection
                  student={currentStudent}
                  targetLessons={targetLessons}
                  handleIndex={handleIndex}
                  index={index}
                />
              </>
            ) : null}
          </>
        </CustomModalBody>
      </CustomModal>
    </div>
  );
}
export default injectUserStore(ViewStudentInfoModal);
