import moment from "moment";
import { toast } from "react-toastify";
import {
  isPerLessonAbsence,
  isTeacherAbsence,
} from "src/constants/absenceTypes";
import { isRecurringEvent } from "src/constants/eventsEnum";
import {
  checkIfActivityDuringSchoolBreak,
  checkIfCanceledRecurringActivity,
  convertDurationOrAllDayAbsenceToBusySlot,
  getAvailableSlotsNearActivities,
  getAvailableTimeSlots,
  getNewDatesOnRequestDateByWeekday,
  getRecurringEventTimelineObj,
  getTimeChunks,
  isRecurringActivityAbsence,
  prepareBusySlots,
  prepareMakeupOpenings,
  prepareTeacherActivities,
  prepareTeacherDays,
} from "src/utils/helpers";

export const filterTeachersByInstrument = (teachers, instrumentId) => {
  if (!teachers.length) return [];

  const filteredTeachers = teachers.filter((teacher) => {
    const teachersInstrumentsIds = teacher?.instrumentsInfo?.map(
      (instrumentInfo) => instrumentInfo?.instrument
    );
    return teachersInstrumentsIds?.includes(instrumentId);
  });

  return filteredTeachers;
};

export const attachAvailableDaysToTeachers = (
  teachers = [],
  availableDays = [],
  locations = [],
  locationId, // the pl location to filter avail days by by
  teachersMakeUpOpenings = []
) => {
  if (!teachers?.length || !locationId) return [];

  const updatedTeachers = [];

  availableDays?.forEach((daysObj) => {
    const teacherId = daysObj?.id;
    const teacher = teachers.find(({ id }) => id === teacherId);
    if (teacher) {
      const teacherWithDay = {
        ...teacher,
        teacherDays: {
          ...daysObj,
          availableDays: daysObj?.availableDays
            ?.filter(({ location }) => location === locationId)
            ?.map((availableDay) => ({
              ...availableDay,
              location: locations?.find(
                ({ id }) => id === availableDay.location
              ),
            })),
        },
        makeupOpenings: teachersMakeUpOpenings
          ?.filter(
            ({ location, userId }) =>
              userId === teacher.id && location === locationId
          )
          ?.map((opening) => ({
            ...opening,
            location: locations?.find(({ id }) => id === opening.location),
          })),
      };
      updatedTeachers.push(teacherWithDay);
    }
  });
  return updatedTeachers;
};

export const attachActivitiesToTeachers = (
  teachers = [],
  teachersActivities = []
) => {
  if (!teachers?.length) return [];

  if (!teachersActivities?.length) return teachers;

  const teachersWithActivities = teachers.map((teacher) => ({
    ...teacher,
    teacherActivities: teachersActivities.filter(
      ({ userId, usersIds }) =>
        userId === teacher.id || usersIds?.includes(teacher.id)
    ),
  }));

  return teachersWithActivities;
};
// adds data for the teacher's availability at a specific date for a specific lsn duration (includes teacherDays availabilities and makeup availabilities)
// Assumes that the param teachers has the teacherDays  and the teacherActivities
export const attachAvailableTimeSlotstoTeacher = (
  teacher, // {}
  lessonDuration, // int
  requestedStartDate, // date
  schoolBreaks, // []
  absences, //[]
  shortenTimeSlots = false
) => {
  try {
    if (!teacher) return {};

    // get teacher absences (TAs) on request date without per lesson absences
    const teacherAbsencesOnRequestedDate = getTeacherAbsencesOnRequestDate(
      teacher.id,
      absences,
      requestedStartDate
    );
    // gets the school break that is hapening on the same day as the requestedDate + adds newStartDate and newEndDate props based on the requestDate
    // the schoolbreak will then be added to the busyslots
    let schoolBreakInRequestedDate = getSchoolBreakInSpecificDate(
      schoolBreaks,
      requestedStartDate
    );

    const { teacherActivities, teacherDays } = teacher || {};
    const { availableDays } = teacherDays || {};

    // returns recurring events at the same weekDay and non-recurring events at the same exact day + adds start_time and end_time to them (based on the requested date)
    const teacherActivitiesInRequestedDate = getTeacherActivitiesInSpecificDate(
      teacher.id,
      teacherActivities,
      requestedStartDate,
      absences,
      schoolBreakInRequestedDate
    );

    const makeupOpeningsWithAvailabilitiesData = getTeacherMakeupOpeningsSlots(
      teacher,
      requestedStartDate,
      teacherActivitiesInRequestedDate,
      lessonDuration,
      teacherAbsencesOnRequestedDate, // teacher TAs (all day + duration only)
      shortenTimeSlots
    )?.filter(
      (makeupOpening) =>
        // filters out makeup openings that have no avails
        makeupOpening.makeupOpeningsSlotsChunks?.length &&
        makeupOpening.makeupOpeningsSlotsChunks?.some((el) => el?.length)
    );

    // filter the available days that matches the requested date + add newStartDate and newEndDate to them
    let teacherDaysInRequestedDate = prepareTeacherDays(
      requestedStartDate,
      availableDays
    );

    const teacherDaysWithAvailabilitiesData = getTeacherAvailableDaysSlots(
      teacherActivitiesInRequestedDate,
      teacherDaysInRequestedDate,
      lessonDuration,
      schoolBreakInRequestedDate,
      teacherAbsencesOnRequestedDate, // teacher TAs (all day + duration only)
      shortenTimeSlots
    )?.filter(
      (teacherDay) =>
        // filters out teacher days that have no avails

        teacherDay.availableSlotsChunks?.length &&
        teacherDay.availableSlotsChunks?.some((el) => el?.length)
    );

    return {
      teacherDaysWithAvailabilitiesData,
      makeupOpeningsWithAvailabilitiesData,
    };
  } catch (err) {
    console.log(err);
    toast.error(err?.message);
  }
};
export const getTeacherAbsencesOnRequestDate = (
  teacherId,
  absences = [],
  requestedDate
) => {
  if (!absences.length) return [];
  const filteredAbsences = absences.filter((absence) => {
    const isTA = isTeacherAbsence(absence.absenceType);
    const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
    const isSameTeacher = absence.teacherId === teacherId;

    const absenceStart = absence.date || absence.startDate;
    const absenceEnd = absence.endDate;

    const isSameDay =
      (absenceStart &&
        moment(requestedDate, "YYYY-MM-DD").isSame(absenceStart, "date")) ||
      (absenceEnd &&
        moment(requestedDate, "YYYY-MM-DD").isSame(absenceEnd, "date")) ||
      false;

    return isTA && isSameTeacher && isSameDay && !isPerLesson;
  });

  return filteredAbsences;
};
export const getSchoolBreakInSpecificDate = (schoolBreaks, requestedDate) => {
  if (!schoolBreaks.length) return undefined;
  const schoolBreakAtReqDate = schoolBreaks.find((schoolBreak) =>
    moment(requestedDate).isBetween(
      // assuming school events block the whole day, we don't need to set the start and end time of requestDate
      moment(schoolBreak.from.toDate()).set({ seconds: 0, millisecond: 0 }),
      moment(schoolBreak.to.toDate()).set({ seconds: 0, millisecond: 0 }),
      "day",
      "[]"
    )
  );

  if (!schoolBreakAtReqDate) return;

  return schoolBreakAtReqDate;
};
export const getTeacherActivitiesInSpecificDate = (
  teacherId,
  teacherActivities,
  requestedStartDate,
  absences,
  schoolBreakInRequestedDate
) => {
  const requestedDay = moment(requestedStartDate).day();

  const preparedActivities = prepareTeacherActivities(
    teacherActivities,
    teacherId
  );

  const activitiesInRequestedDate = preparedActivities
    ?.filter((activity) => {
      if (isRecurringEvent(activity.type, activity.privateLessonType)) {
        // gets timeline obj in the makeup lesson date
        const timelineObj = getRecurringEventTimelineObj({
          requestDate: requestedStartDate,
          timeline: activity.timeline,
          startDateAccessor: "start_time",
          lastDateAccessor: "lastDate",
        });

        if (!timelineObj || !timelineObj.start_time || !timelineObj.end_time)
          return false;

        // check if the activity start or end is in the same day as the requestDate
        const isSameWeekDay =
          requestedDay === moment(timelineObj.start_time).day() ||
          requestedDay === moment(timelineObj.end_time).day();
        const isSameTeacherOnTimeline = timelineObj.userId === teacherId;

        if (!isSameWeekDay || !isSameTeacherOnTimeline) return false;

        const { newStartDate: activityStart, newEndDate: activityEnd } =
          getNewDatesOnRequestDateByWeekday(
            requestedStartDate,
            timelineObj.start_time,
            timelineObj?.end_time
          );

        //filtering out pl absences (except TAs)
        const isAbsence = absences?.some((absence) => {
          return isRecurringActivityAbsence(
            {
              start: activityStart,
              end: activityEnd,
              id: activity.id,
            },
            absence,
            { excludeTA: true, excludeGCAbsence: true }
          );
        });

        //check if canceled activity
        const isCanceled = checkIfCanceledRecurringActivity({
          occuranceDate: activityStart,
          canceledAt: activity.canceledAt,
          canceledDates: activity.canceledDates,
          canceledDateRanges: activity.canceledRanges,
        });

        // check if the recurring activity is during a school break (recurring activities are canceled during school breaks (private lsns) )
        const isDuringSchoolBreak = schoolBreakInRequestedDate
          ? checkIfActivityDuringSchoolBreak(
              { start: activityStart },
              schoolBreakInRequestedDate
            )
          : false;

        return !isAbsence && !isCanceled && !isDuringSchoolBreak;
      } else {
        return (
          moment(requestedStartDate).isSame(activity.start_time, "date") ||
          moment(requestedStartDate).isSame(activity.end_time, "date")
        );
      }
    })
    ?.map((activity) => {
      const { type, privateLessonType } = activity;

      let activityStart, activityEnd;
      if (isRecurringEvent(type, privateLessonType)) {
        const timelineObj = getRecurringEventTimelineObj({
          requestDate: requestedStartDate,
          timeline: activity.timeline,
          startDateAccessor: "start_time",
          lastDateAccessor: "lastDate",
        });
        // we know that the timeline start and end will exist because if not it would've been filtered in the previous filter method
        activityStart = timelineObj.start_time;
        activityEnd = timelineObj.end_time;
      } else {
        activityStart = activity.start_time;
        activityEnd = activity.end_time;
      }

      const { newStartDate: newActivityStart, newEndDate: newActivityEnd } =
        getNewDatesOnRequestDateByWeekday(
          requestedStartDate,
          activityStart,
          activityEnd
        );
      return {
        ...activity,
        start_time: newActivityStart,
        end_time: newActivityEnd,
      };
    });
  return activitiesInRequestedDate;
};
const getTeacherMakeupOpeningsSlots = (
  teacher,
  requestedStartDate,
  teacherActivitiesInRequestedDate = [],
  lessonDuration,
  teacherAbsencesOnRequestedDate = [],
  shortenTimeSlots = false
) => {
  const { makeupOpenings } = teacher;

  // only get makeupOpenings in the requestedDate
  const makeupOpeningsOnRequestedDate = prepareMakeupOpenings(
    requestedStartDate,
    makeupOpenings
  );

  if (makeupOpeningsOnRequestedDate?.length) {
    const makeupOpeningsWithActivities = makeupOpeningsOnRequestedDate.map(
      (makeupOpening) => {
        const { newStartDate: makeupNewStart, newEndDate: makeupNewEnd } =
          makeupOpening;
        // a filter to get activities that are within this makeupOpening (This is in case teacher has 2 availableDays in the same day in diff locations)
        const activitiesWithinMakeupOpening =
          teacherActivitiesInRequestedDate?.filter(
            (activity) =>
              // isMakeupLessonEvent(activity.type) &&
              moment(activity.start_time).isSameOrAfter(
                makeupNewStart,
                "minutes"
              ) &&
              moment(activity.end_time).isSameOrBefore(makeupNewEnd, "minutes")
          );
        return {
          ...makeupOpening,
          activities: activitiesWithinMakeupOpening || [],
        };
      }
    );

    const makeupOpeningsWithSlots = makeupOpeningsWithActivities.map(
      (opening) => {
        const { activities } = opening;
        const openingStartTime = opening.newStartDate;
        const openingEndTime = opening.newEndDate;
        // considering the TA (duration and all day) as busy slots to block the time (per lsn absences are already included in the activities (as We dont remove the activity if it has teacher absence))
        const absencesBusySlots =
          teacherAbsencesOnRequestedDate.map((absence) =>
            convertDurationOrAllDayAbsenceToBusySlot(
              absence,
              openingStartTime,
              openingEndTime
            )
          ) || [];
        const activitiesBusySlots =
          activities.map((activity) => ({
            start_time: activity.start_time,
            end_time: activity.end_time,
          })) || [];
        const busySlots = [...absencesBusySlots, ...activitiesBusySlots];

        const preparedBusySlots = prepareBusySlots(
          busySlots,
          openingStartTime,
          openingEndTime
        );

        const duration = parseInt(lessonDuration);
        let makeupOpeningSlotsOutput = getAvailableTimeSlots(
          openingStartTime,
          openingEndTime,
          preparedBusySlots,
          duration,
          true
        );
        makeupOpeningSlotsOutput = makeupOpeningSlotsOutput.error
          ? []
          : makeupOpeningSlotsOutput;

        // shortens the time slots to be 1 hr before or after each activity (returns the normal time slots output if no activities)
        const shortenedTimeSlotsOutput = getAvailableSlotsNearActivities(
          makeupOpeningSlotsOutput,
          preparedBusySlots,
          60
        );

        const finalTimeSlotsOutput = shortenTimeSlots
          ? shortenedTimeSlotsOutput
          : makeupOpeningSlotsOutput;

        const makeupOpeningsSlotsChunks = finalTimeSlotsOutput?.map((slot) =>
          getTimeChunks(
            slot.start_time,
            slot.end_time,
            duration,
            undefined,
            false
          )
        );

        return {
          ...opening,
          shortenedTimeSlotsOutput,
          makeupOpeningSlotsOutput,
          makeupOpeningsSlotsChunks,
        };
      }
    );
    return makeupOpeningsWithSlots;
  } else {
    return [];
  }
};
const getTeacherAvailableDaysSlots = (
  teacherActivitiesInRequestedDate,
  teacherDaysInRequestedDate,
  lessonDuration,
  schoolBreakInRequestedDate,
  teacherAbsencesOnRequestedDate = [],
  shortenTimeSlots
) => {
  // add activities to each teacherDay
  const teacherDaysWithActivities = teacherDaysInRequestedDate.map(
    (teacherDay) => {
      // a filter to get activities that are within this teacherDay (This is in case teacher has 2 availableDays in the same day in diff locations)
      const activitiesWithinTeacherDay =
        teacherActivitiesInRequestedDate?.filter(
          (activity) =>
            moment(activity.start_time).isSameOrAfter(
              teacherDay.newStartDate,
              "minutes"
            ) &&
            moment(activity.end_time).isSameOrBefore(
              teacherDay.newEndDate,
              "minutes"
            )
        );
      return {
        ...teacherDay,
        activities: activitiesWithinTeacherDay || [],
      };
    }
  );
  /* ---------------------------------------------------------------------------------------- */
  /* ---------------------------------------------------------------------------------------- */
  const teacherDaysWithAvailabilitiesData = teacherDaysWithActivities.map(
    (teacherDay) => {
      const { newStartDate, newEndDate, activities } = teacherDay;
      //perparing getAvailableTimeSlots Arguments
      const workStartTime = newStartDate;
      const workEndTime = newEndDate;
      // considering the TA (duration and all day) as busy slots to block the time (per lsn absences are already included in the activities (as We dont remove the activity if it has teacher absence))
      const absencesBusySlots =
        teacherAbsencesOnRequestedDate.map((absence) =>
          convertDurationOrAllDayAbsenceToBusySlot(
            absence,
            workStartTime,
            workEndTime
          )
        ) || [];

      const schoolBreakBusySlots = schoolBreakInRequestedDate
        ? [
            {
              start_time: schoolBreakInRequestedDate.from.toDate(),
              end_time: schoolBreakInRequestedDate.to.toDate(),
            },
          ]
        : [];
      const activitiesBusySlots =
        activities.map((activity) => ({
          start_time: activity.start_time,
          end_time: activity.end_time,
        })) || [];

      const busySlots = [
        ...absencesBusySlots,
        ...activitiesBusySlots,
        ...schoolBreakBusySlots,
      ];

      const preparedBusySlots = prepareBusySlots(
        busySlots,
        workStartTime,
        workEndTime
      );

      const duration = parseInt(lessonDuration);
      let availableTimeSlotsOutput = getAvailableTimeSlots(
        workStartTime,
        workEndTime,
        preparedBusySlots,
        duration,
        true
      );
      availableTimeSlotsOutput = availableTimeSlotsOutput.error
        ? []
        : availableTimeSlotsOutput;
      // shortens the time slots to be 1 hr before or after each activity (returns the normal time slots output if no activities)
      const shortenedTimeSlotsOutput = getAvailableSlotsNearActivities(
        availableTimeSlotsOutput,
        preparedBusySlots,
        60
      );

      const finalTimeSlotsOutput = shortenTimeSlots
        ? shortenedTimeSlotsOutput
        : availableTimeSlotsOutput;
      const availableSlotsChunks = finalTimeSlotsOutput?.map((slot) =>
        getTimeChunks(
          slot.start_time,
          slot.end_time,
          duration,
          undefined,
          false
        )
      );

      return {
        ...teacherDay,
        shortenedTimeSlotsOutput,
        availableSlots: availableTimeSlotsOutput,
        availableSlotsChunks,
      };
    }
  );
  return teacherDaysWithAvailabilitiesData;
};
export const getDatesBetweenTwoDates = (startDate, endDate) => {
  startDate = moment(startDate).startOf("day");
  let dates = [];
  let counter = 0;
  while (startDate.isSameOrBefore(moment(endDate).startOf("day"))) {
    dates.push(startDate.toDate());
    startDate.add(1, "days");

    // break in case of infinite loop
    if (counter === 10000) break;
  }
  return dates;
};
export const checkIfTeacherHasAtLeastOneAvailability = (
  teacherDaysWithAvailabilitiesData,
  makeupOpeningsWithAvailabilitiesData
) => {
  if (
    !teacherDaysWithAvailabilitiesData?.length &&
    !makeupOpeningsWithAvailabilitiesData?.length
  )
    return false;

  let passTeacherDays = false;
  if (teacherDaysWithAvailabilitiesData?.length) {
    for (const teacherDay of teacherDaysWithAvailabilitiesData) {
      if (teacherDay.availableSlotsChunks?.length) {
        passTeacherDays = true;
        break;
      }
    }
  }

  let passMakeupOpenings = false;
  if (makeupOpeningsWithAvailabilitiesData?.length) {
    for (const makeupOpening of makeupOpeningsWithAvailabilitiesData) {
      if (makeupOpening.makeupOpeningsSlotsChunks?.length) {
        passTeacherDays = true;
        break;
      }
    }
  }
  return passTeacherDays || passMakeupOpenings;
};
