import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { SubmitSpinner } from "src/components/common";
import { absenceTypes } from "src/constants/absenceTypes";
import useModal from "src/hooks/useModal";
import {
  getAbsencePackageItem,
  getCombinedPackageSetItems,
  getPackageLessonSetsNumbers,
  getTimeDiffInMins,
} from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";
import ConfirmationModal from "../../../../ConfirmationModal";
import AbsenceAndMakeUpItem from "../../shared/AbsenceAndMakeUpItem";
import { AbsencesSectionSpinnerWrapper, CustomSelectField } from "../../styled";

const AbsencesSection = ({
  makeUpData,
  student,
  currentLesson,
  setCurrentAbsenceId,
  makeupModalData,
  isPassedLastSetDeadline,
}) => {
  const absenceDeleteConfirmationModalData = useModal();
  const makeupDeleteConfirmationModalData = useModal();
  const deleteAllAbsencesAndMakeupsConfirmationModalData = useModal();

  const availableSetsNumbers = getPackageLessonSetsNumbers(
    currentLesson.packageSets
  );

  // Current absence type (From select box filter)
  const [selectedAbsenceType, setSelectedAbsenceType] = useState(0);

  const [selectedSet, setSelectedSet] = useState(
    Math.max(...availableSetsNumbers)
  );

  const areChangesDetected =
    makeUpData.haveAbsencesChanged || makeUpData.haveMakeupLessonsChanged;

  let lessonAbsences = makeUpData.absencesList;
  // only show absences in current school year
  const filteredLessonAbsences = useMemo(() => {
    const filtered = lessonAbsences.filter((absence) => {
      const absencePackageSetObj = currentLesson.packageSets?.find((setObj) => {
        const found = !!getAbsencePackageItem({
          lessonId: currentLesson.id,
          combinedPackageItems: setObj.setItems,
          startDateAccessor: "startDate",
          absence,
        });
        return found;
      });

      return absencePackageSetObj?.setNumber === selectedSet;
    });
    return filtered;
  }, [lessonAbsences, selectedSet]);

  // returns the absenceTypes with additional property for the number of absences for each type
  const absenceTypesWithAbsencesLength = useMemo(
    () =>
      Object.values(absenceTypes).map((absenceType) => {
        const absencesInAbsenceType = filteredLessonAbsences.filter(
          (absence) =>
            parseInt(absence.absenceType) === parseInt(absenceType.code)
        );
        return {
          ...absenceType,
          numberOfAbsences: absencesInAbsenceType.length,
        };
      }),
    [absenceTypes, filteredLessonAbsences]
  );
  const handleSelectedAbsenceTypeChange = (e) => {
    setSelectedAbsenceType(e.target.value);
  };
  const handleSelectedSetChange = (e) => {
    setSelectedSet(parseInt(e.target.value));
  };

  //Filters absences based on selected absence type, (if the value === 0 then return all absences)
  const selectedAbsences = useMemo(
    () =>
      filteredLessonAbsences
        .filter((absence) =>
          parseInt(selectedAbsenceType) === 0
            ? true
            : parseInt(absence.absenceType) === parseInt(selectedAbsenceType)
        )
        // sort ascendingly (oldest absence first)
        .sort((a, b) => {
          const firstAbsenceDate = a.date || a.startDate;
          const secondAbsenceDate = b.date || b.startDate;

          return getTimeDiffInMins(secondAbsenceDate, firstAbsenceDate);
        }),
    [filteredLessonAbsences, selectedAbsenceType]
  );

  // modals fns
  const openAbsenceDeleteConfirmationModal = (absenceId) => {
    makeUpData.setCurrentAbsenceId(absenceId);
    absenceDeleteConfirmationModalData.openModal();
  };
  const handleApproveAbsenceDelete = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.deleteCurrentAbsence();
      absenceDeleteConfirmationModalData.closeModal();
    }
  };
  const handleDeclineAbsenceDelete = () => {
    absenceDeleteConfirmationModalData.closeModal();
  };
  const openMakeupDeleteConfirmationModal = (makeupId) => {
    makeUpData.setCurrentMakeupId(makeupId);
    makeupDeleteConfirmationModalData.openModal();
  };
  const handleApproveMakeupDelete = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.deleteCurrentMakeupLesson();
      makeupDeleteConfirmationModalData.closeModal();
    }
  };
  const handleDeclineMakeupDelete = () => {
    makeupDeleteConfirmationModalData.closeModal();
  };

  const handleApproveDeleteAllAbsencesAndMakeups = async () => {
    if (!makeUpData.loadingSaveData) {
      makeUpData.handleDeleteAllAbsencesAndMakeUps();
      deleteAllAbsencesAndMakeupsConfirmationModalData.closeModal();
    }
  };
  const handleDeclineDeleteAllAbsencesAndMakeups = () => {
    deleteAllAbsencesAndMakeupsConfirmationModalData.closeModal();
  };

  if (makeUpData.loadingInitialData) {
    return (
      <AbsencesSectionSpinnerWrapper>
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </AbsencesSectionSpinnerWrapper>
    );
  }
  return (
    <>
      <hr className="CenterLine" />
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>Package Absence & Make Up</h3>
          <div>
            {areChangesDetected && (
              <PrimaryButtonWithLoading
                isLoading={makeUpData.loadingSaveData}
                onClick={makeUpData.handleSaveChanges}
                width="150px"
                padding="7px"
              >
                Save Changes
              </PrimaryButtonWithLoading>
            )}
          </div>
          <div>
            {/* Absence type select field */}
            <CustomSelectField
              value={selectedAbsenceType}
              onChange={handleSelectedAbsenceTypeChange}
              width={"200px"}
            >
              <option
                value={0}
              >{`All (${filteredLessonAbsences.length})`}</option>
              {absenceTypesWithAbsencesLength.map(
                ({ code, name, abbr, numberOfAbsences }) => (
                  <option key={code} value={code}>
                    {`${abbr} (${numberOfAbsences})`}
                  </option>
                )
              )}
            </CustomSelectField>
          </div>
        </div>
        {/* Absence type select field */}
        <div>
          <CustomSelectField
            value={selectedSet}
            onChange={handleSelectedSetChange}
            width={"236px"}
          >
            <option value={""} disabled>
              Select Set
            </option>
            {availableSetsNumbers.map((setNumber) => (
              <option key={setNumber} value={setNumber}>
                {`Set ${setNumber}`}
              </option>
            ))}
          </CustomSelectField>
        </div>
        <div className="text-center mt-4 mb-5">
          <PrimaryButtonWithLoading
            isLoading={makeUpData.loadingSaveData}
            onClick={deleteAllAbsencesAndMakeupsConfirmationModalData.openModal}
            width="350px"
          >
            Delete All Absences & Make Ups
          </PrimaryButtonWithLoading>
        </div>
        {/* Absences And Make Ups List */}
        <div>
          {selectedAbsences.map((absence, i) => {
            return (
              <AbsenceAndMakeUpItem
                student={student}
                currentLesson={currentLesson}
                absence={absence}
                index={i}
                makeUpData={makeUpData}
                setCurrentAbsenceId={setCurrentAbsenceId}
                makeupModalData={makeupModalData}
                openAbsenceDeleteConfirmationModal={
                  openAbsenceDeleteConfirmationModal
                }
                openMakeupDeleteConfirmationModal={
                  openMakeupDeleteConfirmationModal
                }
                isPassedLastSetDeadline={isPassedLastSetDeadline}
              />
            );
          })}
        </div>
        <div className="text-end">
          {areChangesDetected && (
            <PrimaryButtonWithLoading
              isLoading={makeUpData.loadingSaveData}
              onClick={makeUpData.handleSaveChanges}
              width="235px;"
            >
              Save Absences/Make Ups
            </PrimaryButtonWithLoading>
          )}
        </div>
        {absenceDeleteConfirmationModalData.isModalOpen &&
          makeUpData.currentAbsenceId && (
            <ConfirmationModal
              onApprove={handleApproveAbsenceDelete}
              onCancel={handleDeclineAbsenceDelete}
              modalData={absenceDeleteConfirmationModalData}
              isSubmitting={makeUpData.loadingSaveData}
              approveBtnText={"Delete Absence"}
              cancelBtnText="Cancel"
              title="Delete Absence ?"
            />
          )}
        {makeupDeleteConfirmationModalData.isModalOpen &&
          makeUpData.currentMakeupId && (
            <ConfirmationModal
              onApprove={handleApproveMakeupDelete}
              onCancel={handleDeclineMakeupDelete}
              modalData={makeupDeleteConfirmationModalData}
              isSubmitting={makeUpData.loadingSaveData}
              approveBtnText={"Delete"}
              cancelBtnText="Cancel"
              title="Delete Make Up Lesson ?"
            />
          )}
        {deleteAllAbsencesAndMakeupsConfirmationModalData.isModalOpen && (
          <ConfirmationModal
            onApprove={handleApproveDeleteAllAbsencesAndMakeups}
            onCancel={handleDeclineDeleteAllAbsencesAndMakeups}
            modalData={deleteAllAbsencesAndMakeupsConfirmationModalData}
            isSubmitting={makeUpData.loadingSaveData}
            approveBtnText={"Confirm And Delete"}
            cancelBtnText="Cancel"
            title="Delete All Absences and Makeup Lessons ?"
            withCheckbox={true}
            checkboxDescription="I understand that this will delete all absences and make up lessons of this lesson."
          />
        )}
      </div>
    </>
  );
};

export default AbsencesSection;
