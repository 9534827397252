import React from "react";
import { ThumbsUpIconContainer } from "../styled";
import { HandThumbsUpFill } from "@styled-icons/bootstrap/HandThumbsUpFill";
import ProgramTypeSection from "../components/ProgramTypeSection";
import StudentsSelectionSection from "../components/StudentsSelectionSection";
import {
  ModalButtonWithLoading,
  PrimaryButtonWithLoading,
} from "src/utils/shared/shared";
import { ModalButton } from "src/utils/shared/styled";

const ConcertRecommendationBody = ({ closeModal, hookData }) => {
  const {
    initialData,
    loadingInitialData,
    programType,
    setProgramType,
    studentSearchTerm,
    setStudentSearchTerm,
    handleSelectedStudentsChange,
    selectedStudents,
    filteredStudentsList,
    isStudentSelected,
    sendInvite,
    sendingInvite,
  } = hookData;
  const { privateLessons, instruments } = initialData;
  return (
    <div>
      <h5 className="d-flex align-items-center mb-5">
        <ThumbsUpIconContainer>
          <HandThumbsUpFill />
        </ThumbsUpIconContainer>
        From Teacher
      </h5>
      <div>
        <ProgramTypeSection
          programType={programType}
          setProgramType={setProgramType}
        />
      </div>
      <div className="mt-4">
        <StudentsSelectionSection
          isStudentSelected={isStudentSelected}
          selectedStudents={selectedStudents}
          handleSelectedStudentsChange={handleSelectedStudentsChange}
          filteredStudentsList={filteredStudentsList}
          studentSearchTerm={studentSearchTerm}
          setStudentSearchTerm={setStudentSearchTerm}
          privateLessons={privateLessons}
          instruments={instruments}
          programType={programType}
        />
      </div>

      <div className="mt-5 d-flex justify-content-center" style={{ gap: 20 }}>
        <ModalButton fontWeight="bold" onClick={closeModal}>
          Cancel
        </ModalButton>
        <span style={{ fontWeight: "bold" }}>|</span>
        <ModalButtonWithLoading
          primary
          fontWeight="bold"
          isLoading={sendingInvite}
          onClick={sendInvite}
        >
          Send Invite
        </ModalButtonWithLoading>
      </div>
    </div>
  );
};

export default ConcertRecommendationBody;
