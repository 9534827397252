import { Link } from "react-router-dom";
import "./style.css";
import React from "react";

function BookClassToday() {
  return (
    <figure className="book-class">
      <h2>Book A Class Today</h2>
      <Link to="/contact-us">Register</Link>
    </figure>
  );
}

export default BookClassToday;
