import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useExtraFunctions = ({
  adminUser,
  onExtraFunctionsChangeSuccess = () => {},
}) => {
  const { updateUserInfo } = useFirebaseFns();
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const handleExtraFunctionsChange = async (propName) => {
    if (isLoadingAction) return;

    try {
      setIsLoadingAction(true);
      const isEnabled = adminUser.extraFunctions?.includes(propName);
      let updateObj, updatedExtraFunctions;
      if (isEnabled) {
        updatedExtraFunctions = adminUser.extraFunctions?.filter(
          (prop) => prop !== propName
        );
        updateObj = {
          extraFunctions: updatedExtraFunctions,
        };
      } else {
        updatedExtraFunctions = [...(adminUser.extraFunctions || []), propName];
        updateObj = {
          extraFunctions: updatedExtraFunctions,
        };
      }

      await updateUserInfo(adminUser.id, updateObj);

      toast.success("Changed Successfully");
      onExtraFunctionsChangeSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsLoadingAction(false);
    }
  };
  return { handleExtraFunctionsChange };
};

export default useExtraFunctions;
