import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { compose } from "recompose";
import useAbsenceDetailsData from "../hooks/useAbsenceDetailsData";
import { Container, RedBarContainer, RedBarText } from "../styled";
import AbsenceRequestDetails from "./AbsenceRequestDetails";

const AbsenceDetailsBody = ({
  absencesData,
  modalData,
  refreshData,
  UserStore,
  openAffectedLessonsModal,
  readOnly,
}) => {
  const user = toJS(UserStore)?.user;

  const absenceDetailsData = useAbsenceDetailsData({
    user,
    absencesData,
    modalData,
    refreshData,
    openAffectedLessonsModal,
    readOnly,
  });

  if (absenceDetailsData.loadingInitialData) {
    return <div></div>;
  }

  return (
    <div>
      <Container>
        <RedBarContainer>
          <RedBarText>Absence Request</RedBarText>
        </RedBarContainer>
        <div className="p-4 w-100">
          <AbsenceRequestDetails
            absencesData={absencesData}
            absenceDetailsData={absenceDetailsData}
            readOnly={readOnly}
          />
        </div>
      </Container>
      <div className="mt-5 mb-5"></div>
    </div>
  );
};

export default compose(inject("UserStore"), observer)(AbsenceDetailsBody);
