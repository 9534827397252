import React, { useContext, useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { emailValidationSchema } from "../../../utils/validations";
import { FirebaseContext } from "../../Firebase";
import { useNavigate } from "react-router";
import { Container, PageLogo } from "../shared/StyledComponents";
import InputWithLabel from "../shared/InputWithLabel";
import EmailSentConfirmation from "./EmailSentConfirmation";
import { SubmitSpinner } from "../../common";
import { getDocs, query, where } from "firebase/firestore";
import InputWrapper from "../shared/InputWrapper";
import { PrimaryButton } from "src/utils/shared/styled";
import { AuthButtonsWrapper, AuthSubmitButton } from "../styled";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import MobileAuthNav from "../MobileAuthNav";

const loginValidationSchema = yup.object().shape({
  email: emailValidationSchema,
});
const PasswordlessLogin = () => {
  const ORIGIN_URL = window.location.origin;
  const EMAIL_LINK_REDIRECT = `${ORIGIN_URL}/passwordless-signin`;

  const Navigate = useNavigate();

  const [currentDisplay, setCurrentDisplay] = useState("signin");
  const [loading, setLoading] = useState(false);

  const firebase = useContext(FirebaseContext);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;

  useEffect(() => {
    async function completeEmailLinkSignIn() {
      // Get the saved email
      const saved_email = window.localStorage.getItem("emailForSignIn");

      // Verify the user went through an email link and the saved email is not null
      if (
        firebase.isSignInWithEmailLink(window.location.href) &&
        !!saved_email
      ) {
        setLoading(true);
        // Sign the user in
        try {
          await firebase.signInWithEmailLink(saved_email, window.location.href);
          setLoading(false);
          Navigate("/update-password");
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }
    }
    completeEmailLinkSignIn();
  }, []);

  if (loading) {
    return (
      <Container className="text-center">
        <SubmitSpinner />
      </Container>
    );
  }
  if (currentDisplay === "confirmation") {
    return (
      <Container>
        <EmailSentConfirmation />
      </Container>
    );
  }
  return (
    <Container>
      <div className="text-center">
        <PageLogo
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      {!isDesktopScreen && <MobileAuthNav />}
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={loginValidationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { email } = values;
          const lowerCaseEmail = email.toLowerCase();

          setSubmitting(true);

          const userQuery = query(
            firebase.users(),
            where("primaryEmail", "==", lowerCaseEmail)
          );
          const usersRes = await getDocs(userQuery);
          // To check for users that has 'email' or 'primaryEmail' records, till we clean it up
          if (!usersRes.docs.length) {
            setErrors({ email: "User not found" });
          } else {
            // If the user is re-entering their email address but already has a code
            if (
              firebase.isSignInWithEmailLink(window.location.href) &&
              !!lowerCaseEmail
            ) {
              console.log("User Already Has A Code");
              // Sign the user in
              firebase
                .signInWithEmailLink(lowerCaseEmail, window.location.href)
                .catch((err) => {
                  console.log(err);
                });
            } else {
              console.log("Sending Email Link");
              try {
                await firebase.sendSignInLinkToEmail(lowerCaseEmail, {
                  url: EMAIL_LINK_REDIRECT,
                  handleCodeInApp: true,
                });
                // Save the users email to verify it after they access their email
                window.localStorage.setItem("emailForSignIn", lowerCaseEmail);
                setCurrentDisplay("confirmation");
              } catch (err) {
                console.log(err);
              }
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form className="mt-5">
            <InputWrapper>
              <InputWithLabel
                name="email"
                error={errors.email}
                touched={touched.email}
                label="Email Address"
                placeholder="Email Address"
                type="email"
              />
            </InputWrapper>
            <div className="w-75 m-auto">
              <AuthButtonsWrapper>
                <AuthSubmitButton type="submit" width="245px">
                  {isSubmitting ? (
                    <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                  ) : (
                    "Log In With Email Link"
                  )}
                </AuthSubmitButton>
              </AuthButtonsWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default PasswordlessLogin;
