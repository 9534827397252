import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useModal from "src/hooks/useModal";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomInput, PrimaryButton } from "src/utils/shared/styled";
import ChangeAuthInfoModal from "src/components/Dashboard/common/components/ChangeAuthInfoModal";
import { FieldsContainer } from "../../../styled";
import handlePhoneChange from "src/components/Dashboard/common/handlePhoneChange";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { workConditions } from "src/constants/paymentsEnum";
import useAdminContactInfo from "./hooks/useAdminContactInfo";

const ContactInfoSection = ({ adminUser }) => {
  const authInfoChangeModalData = useModal();

  const {
    isLoadingAction,
    saveUserInfo,
    userInfo,
    handleFieldChange,
    initialData,
    loadingInitialData,
  } = useAdminContactInfo({ adminUser });
  const { locations } = initialData;

  const selectedWorkCondition = Object.values(workConditions).find(
    ({ value }) => value === userInfo.workCondition
  );
  const selectedLocation = locations.find(
    ({ id }) => id == userInfo.locationId
  );

  return (
    <div>
      <h4 className={"fw-bold mb-3"}>Admin Profile</h4>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Name </p>
        <CustomInput
          type="text"
          className="w-100 form-control"
          name={"name"}
          id="name"
          placeholder="Name"
          value={userInfo.fullName}
          onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">
          Login Email
          <PrimaryButton
            onClick={authInfoChangeModalData.openModal}
            className="ms-2"
            padding="6px"
            width="120px"
          >
            Change
          </PrimaryButton>
        </p>

        <CustomInput
          className="w-100 form-control"
          name="primaryEmail"
          placeholder="Login Email"
          type="email"
          value={userInfo.primaryEmail}
          disabled
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Phone Number</p>
        <CustomInput
          className="w-100 form-control"
          name="primaryPhone"
          placeholder="Phone Number"
          type="text"
          value={userInfo.primaryPhone}
          onChange={(e) =>
            handleFieldChange(e.target.name, handlePhoneChange(e.target.value))
          }
          maxlength="14"
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Birthday</p>
        <CustomInput
          className="w-100 form-control"
          name="birthday"
          placeholder="Birthday"
          type="date"
          value={userInfo.birthday}
          onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Home Address</p>
        <CustomInput
          className="w-100 form-control"
          name="homeAddress"
          placeholder="Home Address"
          type="text"
          value={userInfo.homeAddress}
          onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Payment Type</p>
        <CustomReactSelect
          placeholder="Payment Type"
          options={Object.values(workConditions).map((condition) => {
            const value = condition.value;
            const label = condition.title;
            return {
              label,
              value,
            };
          })}
          onChange={(option) => {
            if (!option) return;

            handleFieldChange("workCondition", option.value);
          }}
          value={
            selectedWorkCondition
              ? {
                  label: selectedWorkCondition.title,
                  value: selectedWorkCondition.value,
                }
              : null
          }
          isMulti={false}
          width="100%"
          wrapOptions={false}
        />
      </FieldsContainer>
      <FieldsContainer>
        <p className="mb-2 fw-medium">Location</p>
        <CustomReactSelect
          placeholder="Select Location"
          options={locations.map((location) => {
            const value = location.id;
            const label = location.name;
            return {
              label,
              value,
            };
          })}
          onChange={(option) => {
            if (!option) return;

            handleFieldChange("locationId", option.value);
          }}
          value={
            selectedLocation
              ? {
                  label: selectedLocation.name,
                  value: selectedLocation.value,
                }
              : null
          }
          isMulti={false}
          width="100%"
          wrapOptions={false}
        />
      </FieldsContainer>

      <div className="mt-4 text-end">
        <PrimaryButtonWithLoading
          onClick={saveUserInfo}
          isLoading={isLoadingAction}
        >
          Update
        </PrimaryButtonWithLoading>
      </div>

      {/* Modals */}
      <ChangeAuthInfoModal
        title="Change Login Email"
        modalData={authInfoChangeModalData}
        userId={adminUser?.id}
        onSuccessCallback={() => window.location.reload()}
      />
    </div>
  );
};

export default ContactInfoSection;
