import React from "react";
import { useContext } from "react";
import { ParentDashboardContext } from "src/contexts/ParentDashboardContext";
import { CustomSelectField2 } from "src/utils/shared/styled";

const ParentUserStudentSelect = () => {
  const { studentsList, selectedStudentIndex, setSelectedStudentIndex } =
    useContext(ParentDashboardContext);
  const currentStudent =
    studentsList.length > selectedStudentIndex
      ? studentsList[selectedStudentIndex]
      : {};

  return (
    <div>
      <CustomSelectField2
        value={selectedStudentIndex}
        onChange={(e) => setSelectedStudentIndex(e.target.value)}
      >
        {studentsList.map((student, i) => {
          return (
            <option value={i} key={i}>
              {student.fullName}
            </option>
          );
        })}
      </CustomSelectField2>
    </div>
  );
};

export default ParentUserStudentSelect;
