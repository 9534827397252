import { UserRole } from "src/constants/UserRoleEnum";

const setColorByRole = (role) => {
  switch (role) {
    case UserRole.ADMIN:
      return "#681e46";
    case UserRole.TEACHER:
      return "#3174ad";
    case UserRole.STUDENT:
      return "#e4aacc";
    case UserRole.PARENT:
      return "#e357a2";
    case UserRole.GUEST_STUDENT:
    case UserRole.TEACHER_APPLICANT:
    case UserRole.PARTNER_APPLICANT:
    case UserRole.ADMIN_APPLICANT:
      return "#008000";
    default:
      return "";
  }
};

export default setColorByRole;
