import styled from "styled-components";

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  font-size: 18px;
  border-radius: 16px;
  background-color: #e4aabb;

  @media screen and (min-width: 768px) {
    max-width: 420px;
    height: 620px;
  }

  p {
    margin: 0;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

export { Card, CardHeader, SocialIcons };
