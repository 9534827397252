import "./style.css";
import IconWrapper from "./IconWrapper";
import { icons } from "src/components/pages/OurTeam/staffInfo";

const IconsTable = () => {
  return (
    <>
      <div className="iconsWrapper">
        {icons.map((icon, index) => (
          <IconWrapper key={index} name={icon.name}>
            {icon.name}
            {<icon.Icon className="privateInstructionsIcons" />}
          </IconWrapper>
        ))}
      </div>
    </>
  );
};

export default IconsTable;
