import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  concertAgeGroupTypes,
  concertInitialEquipment,
  concertInstrumentTypes,
  concertLevels,
  concertPaymentTypes,
  concertSignupOptions,
  concertTeachingStudioTypes,
} from "src/constants/concertEnum";
import { concertViews } from "../constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import {
  getTimeDiffInMins,
  isActiveTeacher,
  uploadFile,
} from "src/utils/helpers";
import {
  isValidFirstPage,
  isValidPaymentPage,
  isValidSecondPage,
  parsePaymentData,
} from "../helperFns";
import { FirebaseContext } from "src/components/Firebase";
import moment from "moment";
import {
  concertPaymentOptions,
  concertPaymentRates,
  concertPianoAccompanimentLevels,
  concertTeacherDuetLevels,
} from "src/constants/concertPaymentConstants";
import { v4 as uuidv4 } from "uuid";

const initialConcertData = {
  title: "", //
  date: null, //
  startTime: "", //
  endTime: "", //
  address: "", //

  // const attachment= {
  //     id: "",
  //     file: Object,
  // }
  attachments: [], //
  level: concertLevels.ALL, //
  minsPerProgram: "", //
  isVirtual: false, //

  //
  teachingStudios: {
    type: concertTeachingStudioTypes.ALL,
    value: [], // teacherId[]
  },
  //
  ageGroup: {
    type: concertAgeGroupTypes.ANY,
    min: "",
    max: "",
  },
  equipmentList: [...concertInitialEquipment], // if we add more to the equipmentList, new items will have a property of isNew:true
  //
  signupDeadline: {
    isEnabled: false,
    value: null,
  },
  //
  waitingList: {
    isEnabled: false,
    value: "",
  },
  signUpType: {
    type: concertSignupOptions.FREE_FOR_ALL_STUDENTS,
    value: [],
  },
  instruments: {
    type: concertInstrumentTypes.ALL,
    value: [],
  },
  payment: {
    paymentType: concertPaymentTypes.FREE,
  },
};
const initialConcertPaymentData = {
  paymentOption: concertPaymentOptions.FREE,
  paymentRates: [
    {
      title: "Solo Performance",
      name: concertPaymentRates.solo.name,
      isEnabled: false,
      value: "",
      hasOptions: concertPaymentRates.solo.hasOptions,
    },
    {
      title: "Piano Accompaniment",
      name: concertPaymentRates.pianoAccompaniment.name,
      isEnabled: false,
      hasOptions: concertPaymentRates.pianoAccompaniment.hasOptions,
      options: [
        {
          title: "Level 1",
          name: concertPianoAccompanimentLevels.LEVEL_1,
          value: "",
        },
        {
          title: "Level 2",
          name: concertPianoAccompanimentLevels.LEVEL_2,
          value: "",
        },
        {
          title: "Level 3",
          name: concertPianoAccompanimentLevels.LEVEL_3,
          value: "",
        },
        {
          title: "Professional Level",
          name: concertPianoAccompanimentLevels.PROFESSIONAL,
          value: "",
        },
      ],
    },
    {
      title: "Teacher Duet",
      name: concertPaymentRates.teacherDuet.name,
      isEnabled: false,
      hasOptions: concertPaymentRates.teacherDuet.hasOptions,
      options: [
        {
          title: "Level 1",
          name: concertTeacherDuetLevels.LEVEL_1,
          value: "",
        },
        {
          title: "Level 2",
          name: concertTeacherDuetLevels.LEVEL_2,
          value: "",
        },
        {
          title: "Level 3",
          name: concertTeacherDuetLevels.LEVEL_3,
          value: "",
        },
      ],
    },
  ],
  customRates: [],
};

const initialDataInitialValues = {
  teachers: [],
  instruments: [],
};
const useCreateConcert = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const {
    getTeachers,
    getInstruments,
    createConcert,
    createConcertPaymentOption,
  } = useFirebaseFns();

  const [currentView, setCurrentView] = useState(concertViews.FIRST_PAGE);
  const [concertData, setConcertData] = useState(initialConcertData);
  const [paymentData, setPaymentData] = useState(initialConcertPaymentData);

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [isSavingConcert, setIsSavingConcert] = useState(false);

  function convertLevelKeyToTitle(levelKey) {
    return levelKey.charAt(0).toUpperCase() + levelKey.slice(1).toLowerCase();
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingInitialData(true);
        const [teachers, instruments] = await Promise.all([
          getTeachers(),
          getInstruments(),
        ]);

        const activeTeachers = teachers.filter((teacher) =>
          isActiveTeacher(teacher)
        );

        setInitialData({
          teachers: activeTeachers,
          instruments,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  const handleDataChange = useCallback(
    (name, value, type = "concert") => {
      let stateClone, setState;
      if (type === "payment") {
        stateClone = { ...paymentData };
        setState = setPaymentData;
      } else {
        stateClone = { ...concertData };
        setState = setConcertData;
      }
      /* Path of nested field to update, in array notation */
      const path = name.split(".");

      /* Aquire the parent object of the target field */
      const nestedObject = path
        .slice(0, -1)
        .reduce(
          (object, part) => (object === undefined ? undefined : object[part]),
          stateClone
        );

      if (nestedObject !== undefined) {
        /* Obtain last key in path */
        const [pathTail] = path.slice(-1);

        /* Update value of last key on target object to new value */
        nestedObject[pathTail] = value;
      }

      setState(stateClone);
    },
    [concertData, paymentData]
  );

  function handleConcertDataChange(name, value) {
    handleDataChange(name, value, "concert");
  }
  function handlePaymentDataChange(name, value) {
    handleDataChange(name, value, "payment");
  }
  function onPaymentRateChange(rateName, prop, value) {
    const newVal = paymentData.paymentRates.map((rate) => ({
      ...rate,
      ...(rate.name === rateName && {
        [prop]: value,
      }),
    }));

    handlePaymentDataChange("paymentRates", newVal);
  }
  function onPaymentRateOptionChange(rateName, optionName, prop, value) {
    const newVal = paymentData.paymentRates.map((rate) => {
      if (rate.name === rateName) {
        const updatedOptions = rate.options?.map((option) => {
          if (option.name === optionName) {
            return {
              ...option,
              [prop]: value,
            };
          } else {
            return option;
          }
        });
        return {
          ...rate,
          options: updatedOptions,
        };
      } else {
        return rate;
      }
    });

    handlePaymentDataChange("paymentRates", newVal);
  }
  function addNewCustomPaymentRow() {
    const newRow = {
      id: uuidv4(),
      title: "",
      value: "",
    };
    handlePaymentDataChange("customRates", [
      ...paymentData.customRates,
      newRow,
    ]);
  }
  function handleDeleteCustomPaymentRow(id) {
    let copy = [...paymentData.customRates];
    copy = copy.filter((curr) => curr.id !== id);
    handlePaymentDataChange("customRates", copy);
  }

  function handleCustomPaymentChange(name, value, i) {
    let copy = [...paymentData.customRates];
    copy[i][name] = value;
    handlePaymentDataChange("customRates", copy);
  }
  const handleCreateConcert = useCallback(async () => {
    try {
      if (
        !isValidFirstPage(concertData) ||
        !isValidSecondPage(concertData) ||
        !isValidPaymentPage(paymentData)
      ) {
        toast.warn("Concert Data is Incomplete");
        return;
      }

      setIsSavingConcert(true);

      const concertDuration = getTimeDiffInMins(
        moment(concertData.startTime, "HH:mm"),
        moment(concertData.endTime, "HH:mm")
      );

      const concertAttachments = [];
      const filePath = `concerts/${user.uid}/`;
      for (const attachment of concertData.attachments) {
        const { downloadUrl } = await uploadFile(
          firebase,
          attachment.file,
          filePath,
          attachment.id
        );
        const attachmentObj = {
          id: attachment.id,
          url: downloadUrl,
          name: attachment.file.name,
          type: attachment.file.type,
        };
        concertAttachments.push(attachmentObj);
      }

      const availableSignupOptions = {
        type: concertData.signUpType.type,
        ...(concertData.signUpType.type === concertSignupOptions.LIMITED && {
          value: concertData.signUpType.value,
        }),
      };
      const signupDeadline = {
        isEnabled: concertData.signupDeadline.isEnabled,
        ...(concertData.signupDeadline.isEnabled && {
          value: concertData.signupDeadline.value.toDate(),
        }),
      };

      const waitingList = {
        isEnabled: concertData.waitingList.isEnabled,
        ...(concertData.waitingList.isEnabled && {
          value: parseInt(concertData.waitingList.value),
        }),
      };

      const instruments = {
        type: concertData.instruments.type,
        ...(concertData.instruments.type === concertInstrumentTypes.LIMITED && {
          value: concertData.instruments.value,
        }),
      };

      const ageGroup = {
        type: concertData.ageGroup.type,
        ...(concertData.ageGroup.type === concertAgeGroupTypes.LIMITED && {
          min: parseInt(concertData.ageGroup.min),
          max: parseInt(concertData.ageGroup.max),
        }),
      };

      const teachingStudios = {
        type: concertData.teachingStudios.type,
        ...(concertData.teachingStudios.type ===
          concertTeachingStudioTypes.SPECIFIC && {
          value: concertData.teachingStudios.value,
        }),
      };

      const equipmentList = concertData.equipmentList.map(
        ({ id, name, isAvailable }) => ({
          id,
          name,
          isAvailable,
        })
      );

      const concertObj = {
        createdAt: new Date(),
        title: concertData.title,
        date: concertData.date.toDate(),
        duration: parseInt(concertDuration), // minutes
        address: concertData.address,
        attachments: concertAttachments,
        availableSignupOptions: availableSignupOptions,
        minsPerProgram: parseInt(concertData.minsPerProgram),
        signupDeadline: signupDeadline,
        waitingList: waitingList, // list of strings, str=requestId
        isVirtual: concertData.isVirtual,
        level: parseInt(concertData.level),
        instruments: instruments,
        ageGroup: ageGroup,
        teachingStudios: teachingStudios,
        equipmentList: equipmentList,
      };

      console.log({ concertObj });

      const concertRes = await createConcert(concertObj);
      const concertId = concertRes.id;
      if (!concertId) {
        throw new Error("Couldnt create concert");
      }

      const parsedPaymentData = parsePaymentData(paymentData);

      const paymentOptionObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        paymentOption: parsedPaymentData.paymentOption,
        concertId,

        paymentRates: parsedPaymentData.paymentRates,

        customRates: parsedPaymentData.customRates,
      };
      console.log({ paymentOptionObj });

      await createConcertPaymentOption(paymentOptionObj);
      toast.success("Concert Created Successfully!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSavingConcert(false);
    }
  }, [concertData, paymentData]);

  const teachingStudiosSelectOptions = useMemo(() => {
    const options = initialData.teachers.map((teacher) => {
      const value = teacher.id;
      const label = teacher.fullName;
      return {
        label,
        value,
      };
    });
    return options;
  }, [initialData]);

  const teachingStudiosCurrentOption = useMemo(() => {
    const currentOption = concertData.teachingStudios.value.map((teacherId) => {
      const teacher = initialData.teachers.find(
        (teacher) => teacher.id === teacherId
      );
      const label = teacher?.fullName;
      return { value: teacherId, label };
    });
    return currentOption;
  }, [concertData.teachingStudios.value, initialData]);

  const instrumentsSelectOptions = useMemo(() => {
    const options = initialData.instruments.map((instrument) => {
      const value = instrument.id;
      const label = instrument.name;
      return {
        label,
        value,
      };
    });
    return options;
  }, [initialData]);
  const instrumentsCurrentOption = useMemo(() => {
    const currentOption = concertData.instruments.value.map((instrumentId) => {
      const instrumentName = initialData.instruments.find(
        (instrument) => instrument.id === instrumentId
      )?.name;
      const label = instrumentName;
      return { value: instrumentId, label };
    });
    return currentOption;
  }, [concertData.instruments.value, initialData]);

  const levelsSelectOptions = useMemo(() => {
    const options = Object.keys(concertLevels).map((key) => {
      const value = concertLevels[key];
      const label = convertLevelKeyToTitle(key);
      return {
        label,
        value,
      };
    });
    return options;
  }, []);

  const levelCurrentOption = useMemo(() => {
    const levelKey = Object.keys(concertLevels).find(
      (key) => concertLevels[key] === concertData.level
    );
    const currentOption = {
      value: concertLevels[levelKey],
      label: convertLevelKeyToTitle(levelKey),
    };

    return currentOption;
  }, [concertData.level]);

  const maxNumberOfEnrollments = useMemo(() => {
    if (
      !concertData.startTime ||
      !concertData.endTime ||
      !concertData.minsPerProgram
    )
      return;

    const concertDuration = getTimeDiffInMins(
      moment(concertData.startTime, "HH:mm"),
      moment(concertData.endTime, "HH:mm")
    );
    const maxNo = Math.floor(
      concertDuration / parseInt(concertData.minsPerProgram)
    );

    return maxNo;
  }, [concertData]);

  return {
    initialData,
    concertData,
    handleConcertDataChange,
    currentView,
    setCurrentView,
    teachingStudiosSelectOptions,
    teachingStudiosCurrentOption,

    levelsSelectOptions,
    levelCurrentOption,
    isSavingConcert,
    handleCreateConcert,
    instrumentsSelectOptions,
    instrumentsCurrentOption,
    maxNumberOfEnrollments,
    paymentData,
    handlePaymentDataChange,
    onPaymentRateChange,
    onPaymentRateOptionChange,
    addNewCustomPaymentRow,
    handleDeleteCustomPaymentRow,
    handleCustomPaymentChange,
  };
};

export default useCreateConcert;
