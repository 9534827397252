import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useFirebaseHelpers from "src/hooks/useFirebaseHelpers";
import {
  getCurrentSchoolYear,
  getCurrentSchoolYearOfLocation,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  isRequestDateWithinSchoolYear,
  sortSchoolYears,
} from "src/utils/helpers";

// import useFirebaseFns from "./useFirebaseFns";

const useLessonsData = (studentId) => {
  const {
    getPrivateLessonsByStudentsIds,
    getInstruments,
    getPrivateLessonsAbsences,
    getStudentMakeupLessons,
    getSchoolYears,
    getTeachersByIds,
    getMakeUpRequestsByLessonsIds,
  } = useFirebaseFns();
  const { hasStudentReachedMaxMakeUps } = useFirebaseHelpers();

  const [initialData, setInitialData] = useState({
    privateLessons: [],
    absences: [],
    makeUpLessons: [],
    schoolYears: [],
    makeupRequests: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const [selectedLessonId, setSelectedLessonId] = useState(null);

  const [selectedAbsenceId, setSelectedAbsenceId] = useState(null);
  const [hasReachedMaxMakeUps, setHasReachedMaxMakeups] = useState(false);
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState("");

  const handleSelectedAbsenceChange = (absenceId) => {
    setSelectedAbsenceId(absenceId);
  };

  const handleSelectedLessonChange = (e) => {
    setSelectedLessonId(e.target.value);
  };

  // the selectedLesson initial state will be the id of the first lesson
  useEffect(() => {
    const firstLessonId = initialData.privateLessons[0]?.id;
    if (firstLessonId) setSelectedLessonId(firstLessonId);
  }, [initialData]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!studentId) {
        setLoadingInitialData(false);
        return;
      }
      try {
        const firebaseReqs = [
          getInstruments(),
          getPrivateLessonsByStudentsIds([studentId]),
          getStudentMakeupLessons(studentId),
          getSchoolYears(),
        ];
        const [instruments, privateLessons, makeUpLessons, schoolYears] =
          await Promise.all(firebaseReqs);

        //adding instrument obj to each lsn
        const privateLessonsWithAdditionalData = privateLessons.map(
          (lesson) => {
            const { instrumentId } = lesson;
            const lessonInstrument = instruments.find(
              ({ id, name }) => id === instrumentId
            );
            return {
              ...lesson,
              instrument: lessonInstrument,
            };
          }
        );

        //Absences
        const privateLessonsIds = privateLessons.map(
          (privateLesson) => privateLesson.id
        );

        // Make Ups
        const makeUpTeachersIds = makeUpLessons.map(({ teacherId }) => {
          return teacherId;
        });
        const uniqueTeachersIds = [...new Set(makeUpTeachersIds)];

        const [absences, makeupRequests, teachers] = await Promise.all([
          getPrivateLessonsAbsences(privateLessonsIds),
          getMakeUpRequestsByLessonsIds(privateLessonsIds),
          getTeachersByIds(uniqueTeachersIds),
        ]);
        const absenceWithAdditionalData = absences
          .sort((a, b) => {
            const firstAbsenceDate = a.date || a.startDate;
            const secondAbsenceDate = b.date || b.startDate;

            return getTimeDiffInMins(firstAbsenceDate, secondAbsenceDate);
          })
          .map((absence) => {
            const absenceMakeupReq = makeupRequests.find(
              ({ forAbsenceId }) => forAbsenceId === absence.id
            );
            return {
              ...absence,
              makeupRequest: absenceMakeupReq,
            };
          });

        const makeUpsWithAdditionalData = makeUpLessons.map((makeUp) => {
          const makeUpTeacher = teachers.find(
            ({ id }) => id === makeUp.teacherId
          );
          return {
            ...makeUp,
            teacher: makeUpTeacher,
          };
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          privateLessons: privateLessonsWithAdditionalData,
          absences: absenceWithAdditionalData,
          makeUpLessons: makeUpsWithAdditionalData,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh, studentId]);

  useEffect(() => {
    const checkStudentMaxMakeups = async () => {
      try {
        setLoadingInitialData(true);
        if (!selectedLessonId) return;

        const { reachedMaxMakeups } = await hasStudentReachedMaxMakeUps(
          studentId,
          selectedLessonId
        );

        setHasReachedMaxMakeups(reachedMaxMakeups);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    checkStudentMaxMakeups();
  }, [refresh, selectedLessonId]);

  useEffect(() => {
    if (!initialData.schoolYears?.length) return;
    const sorted = sortSchoolYears(initialData.schoolYears);

    setSelectedSchoolYearId(sorted[sorted.length - 1]?.id);
  }, [initialData.schoolYears]);

  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const selectedLesson = useMemo(() => {
    const lesson = initialData.privateLessons.find(
      (lesson) => lesson.id === selectedLessonId
    );
    return lesson;
  }, [initialData, selectedLessonId]);

  const selectedAbsence = useMemo(
    () => initialData.absences.find(({ id }) => id == selectedAbsenceId),
    [initialData, selectedAbsenceId]
  );

  const selectedSchoolYear = useMemo(() => {
    if (!selectedSchoolYearId) return;

    return initialData.schoolYears?.find(
      ({ id }) => id === selectedSchoolYearId
    );
  }, [initialData.schoolYears, selectedSchoolYearId]);

  const selectedLessonAbsences = useMemo(() => {
    if (
      !initialData.absences?.length ||
      !initialData.schoolYears?.length ||
      !selectedLesson
    )
      return [];

    const isPackageLsn = isPackagePrivateLesson(selectedLesson?.type);

    return (
      initialData.absences?.filter((absence) => {
        const absenceDate = absence.date || absence.startDate;
        const isAffectedLesson =
          absence.lessonId === selectedLesson?.id ||
          absence.affectedPrivateLessonsIds?.includes(selectedLesson?.id);

        let isAbsenceWithinSchoolYear;
        if (!isPackageLsn) {
          const { locationId } = getPrivateLessonInfoOnSpecificDate({
            privateLesson: selectedLesson,
            date: absenceDate,
            withTimelineApproximation: true,
          });
          const currentSchoolYearInLocation = getCurrentSchoolYearOfLocation(
            selectedSchoolYear,
            locationId
          );
          isAbsenceWithinSchoolYear = isRequestDateWithinSchoolYear(
            absenceDate,
            currentSchoolYearInLocation
          );
        }
        // only filter by school year for regular lessons, but show all lesson absences for package lsns
        const passSchoolYearFilter = isPackageLsn
          ? true
          : isAbsenceWithinSchoolYear;

        return isAffectedLesson && passSchoolYearFilter;
      }) || []
    );
  }, [initialData, selectedLesson, selectedSchoolYear]);

  const selectedLessonMakeups = useMemo(() => {
    if (
      !initialData.makeUpLessons?.length ||
      !initialData.schoolYears?.length ||
      !selectedLesson
    )
      return [];

    const isPackageLsn = isPackagePrivateLesson(selectedLesson?.type);

    return initialData.makeUpLessons
      ?.filter(({ forLessonId, date }) => {
        const makeupDate = date?.startDate;

        const isSameLesson = forLessonId === selectedLesson?.id;

        let isMakeupWithinSchoolYear;
        if (!isPackageLsn) {
          const { locationId } = getPrivateLessonInfoOnSpecificDate({
            privateLesson: selectedLesson,
            date: makeupDate,
            withTimelineApproximation: true,
          });
          const currentSchoolYearInLocation = getCurrentSchoolYearOfLocation(
            selectedSchoolYear,
            locationId
          );

          isMakeupWithinSchoolYear = isRequestDateWithinSchoolYear(
            makeupDate,
            currentSchoolYearInLocation
          );
        }

        // only filter by school year for regular lessons, but show all  for package lsns
        const passSchoolYearFilter = isPackageLsn
          ? true
          : isMakeupWithinSchoolYear;

        return isSameLesson && passSchoolYearFilter;
      })
      .sort((a, b) => {
        const firstDate = a.date?.startDate;
        const secondDate = b.date?.startDate;

        return getTimeDiffInMins(firstDate, secondDate);
      });
  }, [initialData, selectedLesson, selectedSchoolYear]);

  return {
    initialData,
    loadingInitialData,
    selectedLesson,
    handleSelectedLessonChange,
    handleSelectedAbsenceChange,
    refreshData,
    selectedAbsence,
    hasReachedMaxMakeUps,
    selectedLessonAbsences,
    selectedLessonMakeups,
    selectedSchoolYearId,
    setSelectedSchoolYearId,
  };
};

export default useLessonsData;
