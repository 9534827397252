import { Card } from "antd";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 56px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledProfileCard = styled(Card)`
  border-color: #681e46aa;

  &:not(:first-child) {
    margin-top: 72px;
  }
`;

const ProfileField = styled.div`
  &:not(:first-child) {
    margin-top: 36px;
  }
`;

export { ButtonsWrapper, StyledProfileCard, ProfileField };
