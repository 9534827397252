import React from "react";
// import { Spinner } from "reactstrap";

const spinner = (props) => (
  // <Spinner
  //   style={!props.style && { width: "3rem", height: "3rem" }}
  //   color="info"
  //   {...props}
  // />
  <div className="LoaderMainContainer">
    <div className="loader-container">
      <div className="dot dot-1"></div>
      <div className="dot dot-2"></div>
      <div className="dot dot-3"></div>
      <div className="dot dot-4"></div>
    </div>
  </div>
);
export default spinner;
