import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "./useFirebaseFns";

const LOGS_PER_PAGE = 8;

let timer; // Timer identifier
const waitTime = 1000; // Wait time in milliseconds

const useLogsData = () => {
  // initialData state
  const [initialData, setInitialData] = useState({
    instruments: [],
    locations: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  // logs list state
  const [logsListData, setLogsListData] = useState({
    logs: [],
    hasNextPage: false,
    hasPrevPage: false,
    currentPage: 1,
    lastLogSnap: undefined,
    firstLogSnap: undefined,
  });
  const [loadingLogsList, setLoadingLogsList] = useState(false);

  // state for the search data
  const [searchData, setSearchData] = useState({
    searchTerm: "",
    isSearchEnabled: false,
  });
  const handleSearchTermChange = (searchTerm) => {
    if (searchTerm) {
      setSearchData((oldVal) => ({
        ...oldVal,
        searchTerm,
        isSearchEnabled: true,
      }));
    } else {
      setSearchData((oldVal) => ({
        ...oldVal,
        searchTerm: "",
        isSearchEnabled: false,
      }));
    }
  };

  // state for the date and location filters
  const [filtersData, setFiltersData] = useState({
    locationFilter: { isEnabled: false, value: "" },
    dateFilter: { isEnabled: false, value: "" },
    areFiltersEnabled: false,
  });
  const handleApplyFilters = (filtersObj) => {
    const { locationValue, dateValue } = filtersObj;
    if (!locationValue && !dateValue) {
      toast.warn("Please Apply 1 filter at least");
      return;
    }
    setFiltersData((oldVal) => ({
      ...oldVal,
      ...(locationValue && {
        locationFilter: {
          ...oldVal.locationFilter,
          isEnabled: true,
          value: locationValue,
        },
      }),
      ...(dateValue && {
        dateFilter: {
          ...oldVal.dateFilter,
          isEnabled: true,
          value: dateValue,
        },
      }),
      areFiltersEnabled: true,
    }));
  };
  const handleClearFilters = () => {
    setFiltersData((oldVal) => ({
      ...oldVal,
      locationFilter: { isEnabled: false, value: "" },
      dateFilter: { isEnabled: false, value: "" },
      areFiltersEnabled: false,
    }));
  };

  const {
    getInstruments,
    getLocations,
    getInitialLogs,
    getNextPageLogs,
    getPrevPageLogs,
    // getAllLogs,
  } = useFirebaseFns(searchData, filtersData);

  const handleNextLogsPageClick = async (lastLogCursor) => {
    try {
      if (lastLogCursor) {
        setLoadingLogsList(true);
        const { logs, hasNextPage, lastLogSnap, firstLogSnap } =
          await getNextPageLogs(lastLogCursor, LOGS_PER_PAGE);

        if (logs) {
          setLogsListData((oldVal) => ({
            ...oldVal,
            logs,

            hasNextPage,
            hasPrevPage: true,
            currentPage: oldVal.currentPage + 1,
            lastLogSnap,
            firstLogSnap,
          }));
        }
      }
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    } finally {
      setLoadingLogsList(false);
    }
  };

  const handlePrevLogsPageClick = async (firstLogCursor) => {
    try {
      if (firstLogCursor) {
        setLoadingLogsList(true);
        const { logs, hasPrevPage, lastLogSnap, firstLogSnap } =
          await getPrevPageLogs(firstLogCursor, LOGS_PER_PAGE);

        if (logs) {
          setLogsListData((oldVal) => ({
            ...oldVal,
            logs,
            hasNextPage: true,
            hasPrevPage,
            currentPage: oldVal.currentPage - 1,
            lastLogSnap,
            firstLogSnap,
          }));
        }
      }
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    } finally {
      setLoadingLogsList(false);
    }
  };

  // initial Data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);

        const reqs = [getInstruments(), getLocations()];
        const [instruments, locations] = await Promise.all(reqs);

        setInitialData({
          ...initialData,
          instruments,
          locations,
        });
      } catch (err) {
        toast.error(err?.message);
        console.log(err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  // first logs page fetched dynamically (detects if we are searching or not)
  useEffect(() => {
    const fetchFirstPageLogs = async () => {
      if (searchData.isSearchEnabled) {
        // Wait for waitTime ms and then process the request
        clearTimeout(timer);
        timer = setTimeout(async () => {
          try {
            setLoadingLogsList(true);
            const { logs, hasNextPage, lastLogSnap, firstLogSnap } =
              await getInitialLogs(LOGS_PER_PAGE);

            setLogsListData((oldVal) => ({
              ...oldVal,
              logs,
              hasNextPage,
              hasPrevPage: false,
              currentPage: 1,
              lastLogSnap,
              firstLogSnap,
            }));
          } catch (err) {
            toast.error(err?.message);
            console.log(err);
          } finally {
            setLoadingLogsList(false);
          }
        }, waitTime);
      } else {
        try {
          clearTimeout(timer);
          setLoadingLogsList(true);
          const { logs, hasNextPage, lastLogSnap, firstLogSnap } =
            await getInitialLogs(LOGS_PER_PAGE);

          setLogsListData((oldVal) => ({
            ...oldVal,
            logs,
            hasNextPage,
            hasPrevPage: false,
            currentPage: 1,
            lastLogSnap,
            firstLogSnap,
          }));
        } catch (err) {
          toast.error(err?.message);
          console.log(err);
        } finally {
          setLoadingLogsList(false);
        }
      }
    };
    fetchFirstPageLogs();
  }, [searchData, filtersData]);

  return {
    initialData,
    loadingInitialData,
    logsListData,
    loadingLogsList,
    handleNextLogsPageClick,
    handlePrevLogsPageClick,
    searchData,
    handleSearchTermChange,
    handleApplyFilters,
    handleClearFilters,
  };
};

export default useLogsData;
