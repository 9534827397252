import { getDocs, updateDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { SubmitSpinner } from "src/components/common";
import SingleTab from "src/components/common/Library/tabs/SingleTab";
import RouteContent from "src/components/common/RouteContent";
import { FirebaseContext } from "src/components/Firebase";
import InformationModal from "./InformationModal";
import MakeupDeadlines from "./MakeupDeadlines/MakeupDeadlines";
import SchoolBreaks from "./SchoolBreaks/SchoolBreaks";
import SchoolYears from "./SchoolYears/SchoolYears";
import UnlockConfirmModal from "./UnlockConfirmModal";
import MakeupSectionsExplanationDropdown from "../../common/components/MakeupSectionsExplanationDropdown";

export const SCHOOL_DATES_TABS = [
  "School Years",
  "School Breaks",
  "Makeup Deadlines",
];
export const SCHOOL_DATES_ALERTS = {
  schoolDates: "Please fill and save the school year dates for this location",
  schoolBreaks: "Please fill and save the school year breaks for this location",
  makeupDeadlines:
    "Please fill and save the makeup deadlines for this location",
  allSet: "All data filled for this location",
};
export const SchoolDatesContext = createContext({
  schoolYearsOptions: [],
  isUnlockConfirmModalOpen: false,
  setIsUnlockConfirmModalOpen: () => {},
  selectedLocation: {},
  setSelectedLocation: () => {},
  locationsOptions: [],
  selectedSchoolYear: {},
  setSelectedSchoolYear: () => {},
});
const SchoolDates = () => {
  const firebase = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState(SCHOOL_DATES_TABS[0]);
  const [infoModalState, setInfoModalState] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [schoolYears, setSchoolYears] = useState([]);
  const [isUnlockConfirmModalOpen, setIsUnlockConfirmModalOpen] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  const [locations, setLocations] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState({
    label: "",
    value: "",
    isLocked: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const locationsOptions = useMemo(() => {
    if (selectedSchoolYear?.value && locations.length > 0) {
      const currentSchoolYear = schoolYears.find(
        ({ id }) => id === selectedSchoolYear.value
      );
      const locationsIds = Object.keys(currentSchoolYear.locations);
      return locations
        .filter(({ id }) => locationsIds.indexOf(id) !== -1)
        .map(({ id, name }) => ({ label: name, value: id }));
    }
    return [];
  }, [locations, selectedSchoolYear]);
  const schoolYearsOptions = useMemo(() => {
    return schoolYears.map((schoolYear) => ({
      label: schoolYear.title,
      value: schoolYear.id,
      title: schoolYear.title,
      locations: schoolYear.locations,
      isLocked: schoolYear?.isLocked || false,
    }));
  }, [schoolYears]);
  const fetchLocations = async () => {
    const locationsDocs = await getDocs(firebase.getLocations());
    const addedLocations = locationsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLocations(addedLocations);
  };
  const fetchSchoolYears = async () => {
    const docs = await getDocs(firebase.getSchoolYears());
    const yearsArray = [];
    docs.forEach((doc) => {
      yearsArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setSchoolYears(yearsArray);
  };
  useEffect(() => {
    (async () => {
      await Promise.all([fetchLocations(), fetchSchoolYears()]);
      setIsLoading(false);
    })();
  }, []);
  useEffect(() => {
    if (locationsOptions.length > 0) {
      setSelectedLocation({ ...locationsOptions[0] });
    }
  }, [locationsOptions]);
  const showInfoModal = (title, body) => {
    setInfoModalState({
      isOpen: true,
      title,
      body,
    });
  };
  const renderLayout = () => {
    switch (activeTab) {
      case SCHOOL_DATES_TABS[0]:
        return (
          <SchoolYears showInfoModal={showInfoModal} changeTab={setActiveTab} />
        );
      case SCHOOL_DATES_TABS[1]:
        return (
          <SchoolBreaks
            showInfoModal={showInfoModal}
            changeTab={setActiveTab}
          />
        );
      case SCHOOL_DATES_TABS[2]:
        return (
          <MakeupDeadlines
            showInfoModal={showInfoModal}
            changeTab={setActiveTab}
          />
        );
      default:
        return null;
    }
  };
  const closeModal = () => {
    setInfoModalState({
      isOpen: false,
      title: "",
      body: "",
    });
  };
  const lockSchoolYear = async () => {
    toast.info("Locking school year");
    await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {
      isLocked: true,
    });
    setSelectedSchoolYear((previous) => ({ ...previous, isLocked: true }));
    setSchoolYears((previous) =>
      previous.map((schoolYear) => {
        if (schoolYear.id === selectedSchoolYear.value) {
          return {
            ...schoolYear,
            isLocked: true,
          };
        }
        return schoolYear;
      })
    );
    toast.success("Successfully locked");
  };
  const unlockSchoolYear = async () => {
    toast.info("Unlocking school year");
    setIsUnlockConfirmModalOpen(false);
    await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {
      isLocked: false,
    });
    setSelectedSchoolYear((previous) => ({ ...previous, isLocked: false }));
    setSchoolYears((previous) =>
      previous.map((schoolYear) => {
        if (schoolYear.id === selectedSchoolYear.value) {
          return {
            ...schoolYear,
            isLocked: false,
          };
        }
        return schoolYear;
      })
    );
    toast.success("Successfully unlocked");
  };
  const schoolDatesContextValue = {
    schoolYearsOptions,
    schoolYears,
    locations,
    lockSchoolYear,
    unlockSchoolYear,
    setSchoolYears,
    isUnlockConfirmModalOpen,
    setIsUnlockConfirmModalOpen,
    selectedLocation,
    setSelectedLocation,
    locationsOptions,
    selectedSchoolYear,
    setSelectedSchoolYear,
  };
  return (
    <RouteContent title="School Dates">
      <SchoolDatesContext.Provider value={schoolDatesContextValue}>
        <div className={"d-flex align-items-start mb-3"}>
          {SCHOOL_DATES_TABS.map((item, i) => (
            <SingleTab
              key={item}
              className={"me-5 btn"}
              isActive={item === activeTab}
              onClick={() => setActiveTab(item)}
            >
              {item}
              {i === 2 && <MakeupSectionsExplanationDropdown />}
            </SingleTab>
          ))}
        </div>
        {isLoading ? <SubmitSpinner /> : renderLayout()}
        {infoModalState.isOpen ? (
          <InformationModal
            body={infoModalState.body}
            title={infoModalState.title}
            close={closeModal}
          />
        ) : null}
        {isUnlockConfirmModalOpen ? (
          <UnlockConfirmModal
            close={() => setIsUnlockConfirmModalOpen(false)}
            onConfirm={unlockSchoolYear}
          />
        ) : null}
      </SchoolDatesContext.Provider>
    </RouteContent>
  );
};
export default SchoolDates;
