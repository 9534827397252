import teacherInfoItems from "./teacherInfoItems";
import { ModalBody } from "reactstrap";
import { Descriptions } from "antd";
import {
  StyledModal,
  StyledModalHeader,
} from "../../../../pages/common/GuestMap/styled";

const TeacherInfoModal = ({ isOpen, toggle, teacher }) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>
        Teacher contact information
      </StyledModalHeader>
      <ModalBody>
        <Descriptions
          bordered
          column={1}
          items={teacherInfoItems(teacher)}
          labelStyle={{ fontSize: 16 }}
          contentStyle={{ fontSize: 16 }}
        />
      </ModalBody>
    </StyledModal>
  );
};

export default TeacherInfoModal;
