import { Divider } from "antd";
import { InfoItem } from "../styled";

const AdminApplicantInfo = ({ applicant }) => {
  return (
    <>
      <InfoItem capitalize>
        <span>Employment type:</span> {applicant?.employmentType || "N/A"}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Salary expectations:</span>{" "}
        <p>Hourly: {applicant?.salaryExpectations?.hourly || "N/A"}</p>
        <p>Quarterly: {applicant?.salaryExpectations?.quarterly || "N/A"}</p>
        <p>
          <span>Flexible:</span>{" "}
          {applicant?.salaryExpectations?.flexible ? "Yes" : "No"}
        </p>
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Resume:</span>{" "}
        <a
          href={applicant?.resumeDownloadUrl}
          target="_blank"
          rel="noreferrer nofollow"
        >
          {applicant?.resumeDownloadUrl ? "Click to open" : "N/A"}
        </a>
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>LinkedIn:</span>{" "}
        <a
          href={applicant?.linkedinURL}
          target="_blank"
          rel="noreferrer nofollow"
        >
          {applicant?.linkedinURL || "N/A"}
        </a>
      </InfoItem>
    </>
  );
};

export default AdminApplicantInfo;
