import React, { useMemo } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { paymentUserTypes } from "src/components/Dashboard/AdminsPayment/constants";
import { workConditions } from "src/constants/paymentsEnum";
import { isTeacher } from "src/constants/UserRoleEnum";
import { isActiveTeacher } from "src/utils/helpers";
import { CustomLabel } from "src/utils/shared/styled";
import styled from "styled-components";

const CustomText = styled.p`
  margin: 0;
  color: #681e46;
  font-weight: bold;
  text-align: center;
  font-style: italic;
`;

const UserSelection = ({
  currentUserType,
  users,
  selectedUser,
  setSelectedUserId,
}) => {
  const isTeacherUser = currentUserType === paymentUserTypes.INSTRUCTOR;
  const isStaff = currentUserType === paymentUserTypes.OPERATION_TEAM;

  const label = isTeacherUser
    ? "Select Teacher"
    : isStaff
    ? "Select Team Member"
    : "";

  const options = useMemo(() => {
    return users
      .filter((user) => (isTeacher(user.role) ? isActiveTeacher(user) : true))
      .map((user) => mapUserToOption(user));
  }, [users]);

  const handleOptionsChange = (option) => {
    if (!option) return;

    setSelectedUserId(option.value);
  };

  const selectedOption = selectedUser ? mapUserToOption(selectedUser) : null;
  const selectedUserWorkConditionObj = Object.values(workConditions).find(
    ({ value }) => value === selectedUser?.workCondition
  );
  return (
    <div>
      <CustomLabel>{label}:</CustomLabel>
      <CustomReactSelect
        // isDisabled={!hasPermissionToEdit}
        isMulti={false}
        placeholder={label}
        width="100%"
        options={options}
        onChange={handleOptionsChange}
        value={selectedOption}
        wrapOptions={false}
      />
      {selectedUserWorkConditionObj && (
        <div className="mt-3">
          <CustomText>{selectedUserWorkConditionObj.title}</CustomText>
        </div>
      )}
    </div>
  );
};

const mapUserToOption = (user) => {
  if (!user) return;
  const option = {
    label: user.fullName,
    value: user.id,
  };

  return option;
};

export default UserSelection;
