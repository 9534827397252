import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { compose } from "recompose";
import {
  UserRole,
  adminCredentialsRolesList,
} from "../../constants/UserRoleEnum";
import { FirebaseContext } from "../Firebase";
import {
  AuthContainer,
  LogoutBtnWrapper,
  SidebarContent,
  SidebarContentItem,
} from "./styled";

const AuthPageWrapper = ({ UserStore }) => {
  const { user, isLoggedIn } = UserStore;
  const firebase = useContext(FirebaseContext);

  return (
    <AuthContainer>
      <aside>
        <SidebarContent>
          {adminCredentialsRolesList.includes(user?.role) ? (
            <>
              <SidebarContentItem to="signup">SIGN UP</SidebarContentItem>
              <SidebarContentItem to="dashboard">Dashboard</SidebarContentItem>
            </>
          ) : null}
          {!isLoggedIn ? (
            <>
              <SidebarContentItem to="signin">
                <span className="fs-6">COMMUNITY LOGIN</span>
              </SidebarContentItem>
              <SidebarContentItem to="passwordless-signin">
                <span className="fs-6">FIRST TIME LOGIN</span>
              </SidebarContentItem>
            </>
          ) : null}
        </SidebarContent>
        {isLoggedIn ? (
          <LogoutBtnWrapper onClick={firebase.signOut}>
            <i className="text-white fas fa-power-off fs-2" />
          </LogoutBtnWrapper>
        ) : null}
      </aside>
      <main>
        <Outlet />
      </main>
    </AuthContainer>
  );
};

export default compose(inject("UserStore"), observer)(AuthPageWrapper);
