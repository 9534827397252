import React from "react";
import { SubmitSpinner } from "src/components/common";
import StyledBarContent from "src/components/common/StyledBarContent";
import {
  paymentPropsWithoutInputField,
  paymentPropsWithoutRates,
  paymentsMap,
} from "src/constants/paymentsEnum";
import { getUserPaymentProps, injectUserStore } from "src/utils/helpers";
import {
  CustomInput,
  CustomLabel,
  CustomTextarea,
} from "src/utils/shared/styled";
// import { CustomLabel } from "src/utils/styled";
import useEditPayment from "../hooks/useEditPayment";
import {
  CustomButton,
  CustomButtonsWrapper,
  InputItemContainer,
} from "../styled";

const EditPaymentBody = ({ closeModal, paymentObj, UserStore }) => {
  const user = UserStore.user;
  const editPaymentData = useEditPayment({
    paymentObj,
    user,
    onEditSuccess: () => window.location.reload(),
  });
  const {
    paymentFormData,
    initialData,
    handlePaymentFormDataChange,
    calculatedTotalObj,
    submitEditPayment,
    isSubmitting,
    isAbleToSave,
    paymentNote,
    setPaymentNote,
  } = editPaymentData;
  const { currentPaymentRates, paymentUser } = initialData;

  const isDisabledField = (prop) => {
    const currentPaymentMap = paymentsMap.find(
      ({ propName }) => propName === prop
    );
    const hasRate = !!currentPaymentRates?.rates?.find(
      ({ title, type }) =>
        title === currentPaymentMap.rateTitle &&
        type === currentPaymentMap.rateType
    )?.value;

    const isFieldDoesntRequireRate = paymentPropsWithoutRates.includes(prop);

    return !isFieldDoesntRequireRate && !hasRate;
  };

  const userPaymentProps = getUserPaymentProps(paymentUser);
  return (
    <div>
      <StyledBarContent title="Edit Payment">
        <div className="p-3">
          <div>
            {userPaymentProps
              .filter((prop) => !paymentPropsWithoutInputField.includes(prop))
              .map((prop) => {
                const map = paymentsMap.find((curr) => curr.propName === prop);
                const { propName, name2 } = map;

                return (
                  <InputWithLabel
                    key={propName}
                    propName={propName}
                    value={paymentFormData[propName]}
                    handlePaymentFormDataChange={handlePaymentFormDataChange}
                    label={`${name2}:`}
                    disabled={isDisabledField(propName)}
                  />
                );
              })}
            <div className="mt-4 mb-4">
              <CustomLabel>Note:</CustomLabel>
              <CustomTextarea
                width="100%"
                height="100px"
                name="note"
                value={paymentNote}
                onChange={(e) => setPaymentNote(e.target.value)}
              />
            </div>
          </div>
          <div>
            <h4 className="fw-bold text-center">
              Total: ${calculatedTotalObj?.total}
            </h4>
          </div>
        </div>
      </StyledBarContent>
      <CustomButtonsWrapper>
        <CustomButton onClick={closeModal}>Cancel</CustomButton>
        <CustomButton
          disabled={!isAbleToSave}
          primary
          onClick={submitEditPayment}
        >
          {isSubmitting ? (
            <div>
              <SubmitSpinner style={{ width: "20px", height: "20px" }} />
            </div>
          ) : (
            "Confirm"
          )}
        </CustomButton>
      </CustomButtonsWrapper>
    </div>
  );
};

export default injectUserStore(EditPaymentBody);

const InputWithLabel = ({
  label,
  propName,
  value,
  handlePaymentFormDataChange,
  disabled,
}) => {
  return (
    <InputItemContainer>
      <CustomLabel>{label}</CustomLabel>
      <CustomInput
        value={value}
        onChange={(e) => handlePaymentFormDataChange(propName, e.target.value)}
        type="number"
        width="100%"
        disabled={disabled}
        disabledBgColor="#f5f5f5"
        min="0"
        onWheel={(e) => e.target.blur()} // prevents scroll behaviour
      />
    </InputItemContainer>
  );
};
