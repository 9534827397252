export const trialLessonStatuses = {
  active: {
    code: 1,
    title: "Active",
  },
  canceled: {
    code: 2,
    title: "Canceled",
  },
};

export const isCanceledTrialLesson = (status) => {
  if (!status) return false;

  return parseInt(status) === trialLessonStatuses.canceled.code;
};
