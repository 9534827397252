import Modal from "../../../../Modal";
import React, { useContext, useMemo } from "react";
import {
  ActiveTabContext,
  DownloadItemsModalContext,
  LibraryContext,
} from "../../../common/libraryContext";
import { Button } from "reactstrap";

const DownloadConfirmationModal = ({ user }) => {
  const {
    state,
    close,
    downloadItem,
    moveItemsToUserLibrary,
    moveSingleItemToUserLibrary,
  } = useContext(DownloadItemsModalContext);
  const { library: userLibrary } = useContext(LibraryContext);
  const { activeTab } = useContext(ActiveTabContext);

  const isAbleToMoveToOwnLibrary = useMemo(
    () =>
      state.items.every((itemId) => {
        const item = userLibrary[activeTab].find(({ id }) => itemId === id);

        const isItemSharedWithUser = item.sharedWith?.includes(user.uid);
        return isItemSharedWithUser;
      }),
    [state, userLibrary, activeTab, user]
  );

  const isAbleToDownloadToDevice = state.items.length === 1;

  const buttons = (
    <>
      {isAbleToDownloadToDevice ? (
        <Button className={"ms-2 bg-purple"} onClick={downloadItem}>
          Download to device
        </Button>
      ) : null}
      {isAbleToMoveToOwnLibrary ? (
        <Button className={"ms-2 bg-purple"} onClick={moveItemsToUserLibrary}>
          Move to your own library
        </Button>
      ) : null}
    </>
  );

  const title =
    isAbleToDownloadToDevice || isAbleToMoveToOwnLibrary
      ? "How would you like to download ?"
      : "Download is not available";

  const modalBody = () => (
    <div className={"d-flex py-5 align-items-center justify-content-center"}>
      <p className="lead mb-0">{title}</p>
    </div>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Download Options"}
      isOpen={state.isOpen}
      buttons={buttons}
      toggle={close}
    />
  );
};
export default DownloadConfirmationModal;
