import axios from "axios";
import React, { useState, useEffect } from "react";

const useWeather = () => {
  const [weatherData, setWeatherData] = useState({});
  const [loadingWeather, setLoadingWeather] = useState(false);
  const [coordinates, setCoordinates] = useState({});

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      setCoordinates({});
    }
  }, []);
  useEffect(() => {
    if (Object.keys(coordinates).length) {
      const getWeatherData = async () => {
        setLoadingWeather(true);
        try {
          const { data, error } = await axios.get(
            `https://api.openweathermap.org/data/2.5/weather?&units=imperial&lat=${coordinates.latitude}&lon=${coordinates.longitude}&appid=${process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY}`
          );
          if (data && !error)
            setWeatherData({
              temp: data.main?.temp,
              weatherDescription: data.weather[0]?.main,
            });
        } catch (err) {
          console.log(err);
        } finally {
          setLoadingWeather(false);
        }
      };
      getWeatherData();
    }
  }, [coordinates]);

  return { weatherData, loadingWeather };
};

export default useWeather;
