import React from "react";
import { useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import { useUserNotificationsContext } from "src/contexts/UserNotificationsContext";
import useNotificationsData from "./hooks/useNotificationsData";
import NotificationsBody from "./NotificationsBody";
import { CustomDropdownMenu } from "./styled";

const NotificationsMenu = ({
  dropdownToggleContainer: DropdownToggleContainer,
  dropdownToggleIcon: DropdownToggleIcon,

  ellipseIcon: EllipseIcon,
}) => {
  const { unreadNotifications, readAllUnreadNotifications } =
    useUserNotificationsContext();

  const [isOpen, setIsOpen] = useState(false);
  const notificationsData = useNotificationsData({
    isNotificationsOpen: isOpen,
  });

  const toggleDropdown = async () => {
    if (isOpen) {
      setIsOpen(false);
      await readAllUnreadNotifications();
    } else {
      setIsOpen(true);
    }
  };

  const dropdownToggleMarkup = () => (
    <DropdownToggleContainer>
      <DropdownToggleIcon />
      {unreadNotifications?.length ? <EllipseIcon /> : null}
    </DropdownToggleContainer>
  );
  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown} direction={"down"}>
        <DropdownToggle tag="span">{dropdownToggleMarkup()}</DropdownToggle>
        <CustomDropdownMenu>
          <NotificationsBody notificationsData={notificationsData} />
        </CustomDropdownMenu>
      </Dropdown>
    </div>
  );
};

export default NotificationsMenu;
