import React from "react";
import { NavButtonsContainer } from "../../styled";
import CustomNavButtons from "src/components/common/CustomNavButtons";
import { studentViewOptions } from "../../constants";

const NavButtons = ({ selectedStudentView, handleSelectedViewChange }) => {
  const options = Object.values(studentViewOptions).map((option) => ({
    name: option,
    value: option,
  }));
  return (
    <NavButtonsContainer>
      <CustomNavButtons
        buttonsPadding={"12px"}
        options={options}
        currentOption={selectedStudentView}
        setCurrentOption={(value) => handleSelectedViewChange(value)}
      />
    </NavButtonsContainer>
  );
};

export default NavButtons;
