import moment from "moment";
import {
  areTwoEventsOverlapping,
  checkStartTimeIsBeforeEndTime,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getFullDateFromDateAndTimeInputs,
  getTimeDiffInMins,
  updatedMomentNow,
} from "src/utils/helpers";

export const validatePerDurationAbsence = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return { isValid: false };
  }

  const timeDiff = getTimeDiffInMins(
    moment(startTime, "HH:mm"),
    moment(endTime, "HH:mm")
  );
  if (timeDiff <= 0) {
    return { isValid: false };
  }

  return { isValid: true };
};

export const validateMakeUpOpening = ({
  makeUpOpening,
  schoolYears,
  teacherDays,
}) => {
  const startDate = getFullDateFromDateAndTimeInputs(
    makeUpOpening.date,
    makeUpOpening.startTime
  );
  const endDate = getFullDateFromDateAndTimeInputs(
    makeUpOpening.date,
    makeUpOpening.endTime
  );
  makeUpOpening = {
    ...makeUpOpening,
    startDate,
    endDate,
  };

  /*------------------------------------------------------------------------*/

  const isStartBeforeEnd = checkStartTimeIsBeforeEndTime(
    makeUpOpening.startTime,
    makeUpOpening.endTime
  );
  if (!isStartBeforeEnd) {
    return {
      isValid: false,
      message: "Makeup openings start time has to be before end time",
    };
  }

  // gets the schoolbreaks based on the location of each opening
  const combinedSchoolBreaks = getCombinedSchoolBreaks(
    schoolYears,
    makeUpOpening.location
  );

  // is valid if opening date is during a school break (no need to proceed to validate against the teacher day)
  if (isOpeningDuringSchoolBreak(makeUpOpening, combinedSchoolBreaks)) {
    console.log("Opening is during school break");
    return { isValid: true };
  }
  let validationObj = { isValid: true };
  for (const teacherDay of teacherDays) {
    //checks if teacherDay and makeup opening are overlapping
    if (
      moment(makeUpOpening.startDate).isSameOrAfter(
        updatedMomentNow(),
        "date"
      ) &&
      areTwoEventsOverlapping(
        {
          start_time: teacherDay.startDate,
          end_time: teacherDay.endDate,
          isRecurring: true,
        },
        {
          start_time: makeUpOpening.startDate,
          end_time: makeUpOpening.endDate,
          isRecurring: false,
        }
      )
    ) {
      validationObj = {
        isValid: false,
        message:
          "Makeup opening days and normal teacher days can't be overlapping",
      };
      // No need to continue through the  loop if the  opening isnt valid
      break;
    }
  }
  return validationObj;
};

//checks if opening is within a school break
const isOpeningDuringSchoolBreak = (opening, schoolBreaks) => {
  if (!schoolBreaks?.length) return false;

  let isDuringSchoolBreak = false;
  for (const schoolBreak of schoolBreaks) {
    if (
      moment(opening.startDate).isBetween(
        schoolBreak.from.toDate(),
        schoolBreak.to.toDate(),
        undefined,
        "[]"
      ) &&
      moment(opening.endDate).isBetween(
        schoolBreak.from.toDate(),
        schoolBreak.to.toDate(),
        undefined,
        "[]"
      )
    ) {
      console.log("the opening matches a school event", {
        schoolBreak,
        opening,
      });
      isDuringSchoolBreak = true;
      break;
    }
  }

  return isDuringSchoolBreak;
};
