import { MediaViewerContainer } from "../../styled-components/teacherLibraryStyledComponents";
import ReactPlayer from "react-player";
import React, { useContext, useState } from "react";
import { ViewItemModalContext } from "../common/libraryContext";
import MediaControls from "./MediaControls";

const AudioViewer = ({ audio = "", title = '' }) => {
  const { close } = useContext(ViewItemModalContext);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playedDuration, setPlayedDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const [url, setUrl] = useState(audio);
  return (
    <MediaViewerContainer className={"d-flex"}>
      <ReactPlayer
        url={url}
        config={{
          file: {
            forceAudio: true,
          },
        }}
        playing={isPlaying}
        played={playedDuration}
        width={0}
        height={0}
        fallback={
          <div className={"alert alert-danger"}>Error loading this audio</div>
        }
        onReady={() => {
          setIsReady(true);
        }}
        onDuration={(d) => {
          setDuration(d);
        }}
        onProgress={({ played }) => {
          setPlayedDuration(played);
        }}
      />
      <MediaControls
        isReady={isReady}
        playedDuration={playedDuration}
        setIsPlaying={setIsPlaying}
        mediaUrl={url}
        setMediaUrl={setUrl}
        originalUrl={audio}
        duration={duration}
        title={title}
        setPlayedDuration={setPlayedDuration}
      />
      <div className={"ms-2 close-container"}>
        <button className="close" onClick={close}>
          <i className="fas fa-times" />
        </button>
      </div>
    </MediaViewerContainer>
  );
};
export default AudioViewer;
