import { useMemo, useState } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import RouteContent from "../../../common/RouteContent";
import CustomCalendarComponent from "../../../common/CustomCalendar";
import { toJS } from "mobx";
import useScheduleData from "./hooks/useScheduleData";
import { SubmitSpinner } from "../../../common";
import {
  eventPropGetter,
  excludeAbsenceFromPLs,
  excludeSchoolBreaksFromRecurringEvents,
  excludeTeacherBreaksFromAbsences,
  filterSummerBreaksFromPLs,
  filterWithdrawalFromPLs,
  generateRecurringEvents,
  joinSimilarEvents,
  mapAppointmentToEvent,
  mapBreakToEvent,
  mapGroupClassToEvents,
  mapMakeupLessonToEvent,
  mapPackagePrivateLessonToEvents,
  mapSchoolBreakToScheduleEvent,
  mapTLToEvent,
} from "../helperFns";
import {
  eventsMap,
  isPackagePrivateLesson,
  isRecurringBreakEvent,
} from "../../../../constants/eventsEnum";
import useModal from "../hooks/useModal";
import EventDetailsModal from "../components/EventDetailsModal";
import { UserRole } from "../../../../constants/UserRoleEnum";
import { CustomSelectField } from "../styled";
import { modalDetailsEventsCodes } from "../constants";
import { isCanceledTrialLesson } from "src/constants/trialLessonStatuses";
import { checkIfCanceledRecurringActivity } from "src/utils/helpers";
import CustomEventComponent from "../components/CustomEventComponent";
import CustomTooltip from "../../common/components/CustomTooltip";
import { groupClassesListViews } from "../../GroupClass/constants";

const TeacherSchedule = ({ UserStore }) => {
  // modal data for the event details modal
  const eventDetailsModalData = useModal();

  const user = toJS(UserStore)?.user;
  const {
    initialData,
    loadingInitialData,
    selectedEvent,
    setSelectedEvent,
    allEventsLocationsIds,
    selectedLocationId,
    handleSelectedLocationIdChange,

    combinedSchoolBreaks,
  } = useScheduleData(user);

  const {
    instruments,
    teacherPLs,
    teacherTLs,
    teacherMakeupLessons,
    teacherAppointments,
    absences,
    schoolYears,
    breaks,
    studioUsages,
    groupClasses,
  } = initialData;

  const [currentDate, setCurrentDate] = useState(new Date());

  const regularPrivateLessonsRecurringEvents = useMemo(() => {
    const recurringEvents = generateRecurringEvents({
      docs: teacherPLs.filter(({ type }) => !isPackagePrivateLesson(type)),
      startDateAccessor: "startDate",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "teacherId",
      eventCode: eventsMap.privateLesson.code,
      instruments,
      resourceIds: [user?.uid],
    });

    // location filter
    const eventsInLocation = recurringEvents.filter(
      ({ currentTimeline }) =>
        selectedLocationId === currentTimeline?.locationId
    );

    return eventsInLocation;
  }, [teacherPLs, selectedLocationId, currentDate]);

  const packagePrivateLessonsEvents = useMemo(() => {
    const packageItemsEvents = teacherPLs
      .filter(({ type }) => isPackagePrivateLesson(type))
      .map((lesson) =>
        mapPackagePrivateLessonToEvents({
          packageLesson: lesson,
          selectedTeachers: [user?.uid],
          role: UserRole.TEACHER,
          instruments,
        })
      );
    const flatPackageLessonEvents = packageItemsEvents.flat();
    // location filter
    const eventsInLocation = flatPackageLessonEvents.filter(
      ({ locationId }) => selectedLocationId === locationId
    );

    return eventsInLocation;
  }, [teacherPLs, selectedLocationId]);

  const combinedPrivateLessonsEvents = useMemo(() => {
    return [
      ...regularPrivateLessonsRecurringEvents,
      ...packagePrivateLessonsEvents,
    ];
  }, [regularPrivateLessonsRecurringEvents, packagePrivateLessonsEvents]);

  const filteredPLsRecurringEvents = useMemo(() => {
    const filteredAbsencesFromPLs = excludeAbsenceFromPLs(
      combinedPrivateLessonsEvents,
      absences
    );

    // remove the event starting from the withdrawal date + change color and title of last lesson date
    const filteredWithdrawalsFromPLs = filterWithdrawalFromPLs(
      filteredAbsencesFromPLs
    );

    const filteredSummerBreaksFromPLs = filterSummerBreaksFromPLs(
      filteredWithdrawalsFromPLs
    );
    return filteredSummerBreaksFromPLs;
  }, [combinedPrivateLessonsEvents, absences]);
  const PLsRecurringEventsWithoutSchoolBreaks = useMemo(
    () =>
      excludeSchoolBreaksFromRecurringEvents(
        filteredPLsRecurringEvents,
        combinedSchoolBreaks
      ),
    [filteredPLsRecurringEvents, combinedSchoolBreaks]
  );
  const trialLessonsEvents = useMemo(
    () =>
      teacherTLs
        .filter(({ status }) => !isCanceledTrialLesson(status))
        .map((lesson) => mapTLToEvent(lesson, UserRole.TEACHER, instruments)),
    [teacherTLs, instruments]
  );
  const makeupLessonsEvents = useMemo(
    () =>
      teacherMakeupLessons.map((lesson) =>
        mapMakeupLessonToEvent(lesson, instruments)
      ),
    [teacherMakeupLessons, instruments]
  );
  const appointmentsEvents = useMemo(
    () =>
      teacherAppointments.map((appointment) =>
        mapAppointmentToEvent(appointment)
      ),
    [teacherAppointments]
  );

  const schoolBreaksCalendarEvents = useMemo(
    () =>
      combinedSchoolBreaks.map((schoolBreak) =>
        mapSchoolBreakToScheduleEvent(schoolBreak)
      ),
    [combinedSchoolBreaks]
  );

  const oneTimeBreaksEvents = useMemo(
    () =>
      breaks
        .filter(({ type }) => !isRecurringBreakEvent(type))
        .map((userBreak) => mapBreakToEvent(userBreak)),
    [breaks]
  );
  const recurringBreaksEvents = useMemo(() => {
    const recurringBreaks = breaks.filter(({ type }) =>
      isRecurringBreakEvent(type)
    );

    const recurringEvents = generateRecurringEvents({
      docs: recurringBreaks,
      startDateAccessor: "date",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "userId",
      eventCode: eventsMap.recurringBreak.code,
    });

    // filter out canceled breaks
    const filteredRecurringBreaks = recurringEvents.filter(
      ({ start, canceledAt, canceledDates }) => {
        const isCanceled = checkIfCanceledRecurringActivity({
          occuranceDate: start,
          canceledAt,
          canceledDates,
        });
        return !isCanceled;
      }
    );
    return filteredRecurringBreaks;
  }, [breaks, currentDate]);

  const allBreaksEventsWithoutAbsences = useMemo(
    () =>
      excludeTeacherBreaksFromAbsences(
        [...oneTimeBreaksEvents, ...recurringBreaksEvents],
        absences
      ),
    [oneTimeBreaksEvents, recurringBreaksEvents, absences]
  );

  const allBreaksWithoutSchoolBreaks = useMemo(
    () =>
      excludeSchoolBreaksFromRecurringEvents(
        allBreaksEventsWithoutAbsences,
        combinedSchoolBreaks
      ),
    [allBreaksEventsWithoutAbsences, combinedSchoolBreaks]
  );

  const groupClassesEvents = useMemo(() => {
    const events = groupClasses
      .map((groupClass) =>
        mapGroupClassToEvents({
          groupClass,
          selectedTeachers: [user?.uid],
          role: UserRole.SUPER_ADMIN,
        })
      )
      .flat();

    return events;
  }, [groupClasses, user?.uid]);

  let selectedEvents = useMemo(
    () => [
      ...PLsRecurringEventsWithoutSchoolBreaks,
      ...trialLessonsEvents,
      ...makeupLessonsEvents,
      ...appointmentsEvents,
      ...schoolBreaksCalendarEvents,
      ...allBreaksWithoutSchoolBreaks,
      ...groupClassesEvents
        .filter((event) => event?.status === groupClassesListViews.active.value)
        .map((event) => ({
          ...event,
          title: !event?.isCanceled
            ? event?.title
            : `${event?.title} (Canceled)`,
        })),
    ],
    [
      PLsRecurringEventsWithoutSchoolBreaks,
      trialLessonsEvents,
      makeupLessonsEvents,
      appointmentsEvents,
      schoolBreaksCalendarEvents,
      allBreaksWithoutSchoolBreaks,
      groupClassesEvents,
    ]
  );

  selectedEvents = useMemo(
    () => joinSimilarEvents(selectedEvents),
    [selectedEvents]
  );

  const { components } = useMemo(
    () => ({
      components: {
        event: CustomEventComponent,
      },
    }),
    []
  );
  return (
    <RouteContent title={"My Schedule"}>
      {!loadingInitialData ? (
        <div className="vh-100" style={{ marginTop: "50px" }}>
          {allEventsLocationsIds.length > 1 && (
            <div className="mb-4">
              <CustomSelectField
                className="m-auto"
                width="300px"
                value={selectedLocationId}
                onChange={(e) => handleSelectedLocationIdChange(e.target.value)}
              >
                <option value="" disabled>
                  Select A Location
                </option>

                {Object.values(initialData.locations)
                  .filter(({ id }) => allEventsLocationsIds.includes(id))
                  .map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
              </CustomSelectField>
            </div>
          )}
          <CustomCalendarComponent
            showCurrentTimeIndicatorDot
            events={selectedEvents}
            eventPropGetter={eventPropGetter}
            onNavigate={(newDate) => {
              setCurrentDate(newDate);
            }}
            // On clicking on an event in the calendar
            onSelectEvent={(event) => {
              if (modalDetailsEventsCodes.includes(event.eventCode)) {
                eventDetailsModalData.openModal();
                setSelectedEvent(event);
              }
            }}
            popup
            date={currentDate}
            components={components}
          />
          <CustomTooltip anchorSelect=".calendar-event-tooltip" />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <SubmitSpinner
            style={{ width: "3rem", height: "3rem", color: "#681e46" }}
          />
        </div>
      )}
      {eventDetailsModalData.isModalOpen &&
        modalDetailsEventsCodes.includes(selectedEvent.eventCode) && (
          <EventDetailsModal
            event={selectedEvent}
            modalData={eventDetailsModalData}
            initialData={initialData}
            userRole={UserRole.TEACHER}
            studioUsages={studioUsages}
          />
        )}
    </RouteContent>
  );
};
export default compose(inject("UserStore"), observer)(TeacherSchedule);
