import React from "react";

const RegisterationContext = React.createContext();

export const RegisterationContextProvider = ({
  componentList,
  children,
  initialValues,
  ...props
}) => {
  const [step, setStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState(initialValues || {});
  const [formSubmission, setFormSubmission] = React.useState(false);
  const stepCount = React.useRef(0);

  const handleResetForm = React.useCallback(() => {
    setStep(0);
    setFormValues({});
    setFormSubmission(false);
  }, []);

  const nextStep = React.useCallback(() => {
    let lastStep = stepCount.current === Object.keys(componentList).length - 1;
    if (!lastStep) {
      let stepCountValue = stepCount.current;
      stepCountValue++;
      setStep(stepCountValue);
      stepCount.current = stepCountValue;
    } else {
      stepCount.current = 0;
    }
  }, [stepCount, componentList]);

  const prevStep = React.useCallback(() => {
    let firstStep = stepCount.current === 0;
    if (!firstStep) {
      let stepCountValue = stepCount.current;
      stepCountValue--;
      setStep(stepCountValue);
      stepCount.current = stepCountValue;
    } else {
      stepCount.current = 0;
    }
  }, [stepCount]);

  const contextValues = React.useMemo(
    () => ({
      step,
      nextStep,
      prevStep,
      formValues,
      setFormValues,
      formSubmission,
      setFormSubmission,
      handleResetForm,
    }),
    [formValues, formSubmission, handleResetForm, step, nextStep, prevStep]
  );
  return (
    <RegisterationContext.Provider value={contextValues} {...props}>
      {children}
    </RegisterationContext.Provider>
  );
};

export function useRegistrationContext() {
  const context = React.useContext(RegisterationContext);

  return context;
}
