import { useMemo } from "react";
import {
  paymentRates,
  paymentsMap,
  paymentTypes,
  workConditions,
} from "src/constants/paymentsEnum";
import { getAdminPaymentName, getTeacherPaymentName } from "../helpers";
import store from "src/stores/UserStore";
import { UserRole } from "src/constants/UserRoleEnum";

import { CustomTableContainer } from "src/utils/shared/styled";
import { RatesList } from "../../styled";
import PaymentInfoChart from "../PaymentInfoChart";
import TotalPaymentsList from "../PrivateLessonsSection/TotalPaymentsList";
import { Divider } from "antd";

const OtherPaymentsSection = ({
  payments,
  paymentRatesObj,
  paymentMethod,
  workingPeriod,
}) => {
  const { user } = store;
  const { rates } = paymentRatesObj || {};

  const paymentsWithMap = payments.map((payment) => {
    const paymentMap = paymentsMap.find(
      (map) =>
        map.paymentType === payment.type &&
        map.paymentCalculationType === payment.calculatedBy
    );
    return {
      ...payment,
      paymentMap,
    };
  });
  const displayedRatesList =
    rates?.filter((rate) => {
      const isRateUsed = paymentsWithMap.some(
        ({ paymentMap }) =>
          rate.title === paymentMap?.rateTitle &&
          rate.title === paymentMap?.rateTitle
      );
      return !!isRateUsed;
    }) || [];

  const headerTitle =
    paymentMethod === workConditions.fullTime.value
      ? "Paycheck"
      : "Additional payments";

  const paymentsData = useMemo(() => {
    const adminPayments = payments?.map((payment) => {
      const { type, totalPaid } = payment;

      const paymentMap = paymentsMap?.find(
        (map) =>
          type === map.paymentType &&
          payment.fractionPerUnit === map.fractionPerUnit &&
          payment.calculatedBy === map.paymentCalculationType
      );

      return {
        type,
        name:
          user?.role === UserRole.TEACHER
            ? getTeacherPaymentName(type, paymentMap)
            : getAdminPaymentName(type),
        value: totalPaid,
      };
    });

    return adminPayments;
  }, [payments]);

  return (
    <CustomTableContainer minHeight={"0px"}>
      <h4 className="fw-bold">{headerTitle}</h4>
      <div className="mt-4">
        <PaymentInfoChart
          data={paymentsData?.filter((payment) => payment?.value > 0)}
          subtitle={workingPeriod}
          userType="admin"
        />
      </div>
      <Divider />
      <TotalPaymentsList payments={paymentsData} userType="admin" />
      <Divider />
      <h5 className="fw-bold">Rates</h5>
      <RatesList>
        {displayedRatesList.map((rate, index) => {
          const { title, value } = rate;
          const rateName = paymentRates[title]?.name1;

          return (
            <p key={index}>
              <span>{rateName}:</span>
              <span>${value}</span>
            </p>
          );
        })}
      </RatesList>
    </CustomTableContainer>
  );
};

export default OtherPaymentsSection;
