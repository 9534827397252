import React from "react";
import EditBody from "./EditBody";
import { CustomModal, CustomModalHeader } from "src/utils/styled";
import { CloseIcon } from "src/utils/styled";
import { CustomModalBody } from "src/utils/styled";

const EditSummerBreakModal = ({
  modalData,
  privateLessonId,
  onEditSuccess = () => {},
  summerBreakId,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      padding="0px"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomModalHeader
        padding="25px"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h4>Edit Summer Break</h4>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <EditBody
          modalData={modalData}
          privateLessonId={privateLessonId}
          onEditSuccess={onEditSuccess}
          summerBreakId={summerBreakId}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default EditSummerBreakModal;
