import React from "react";
import SettingsRow from "src/components/common/SettingsRow";
import { adminsExtraFunctionsRows } from "../../../constants";
import useExtraFunctions from "../../../hooks/useExtraFunctions";

const ExtraFunctionsSection = ({
  adminUser,
  onExtraFunctionsChangeSuccess,
}) => {
  const { handleExtraFunctionsChange } = useExtraFunctions({
    adminUser,
    onExtraFunctionsChangeSuccess,
  });

  const isSettingEnabled = (prop) => {
    return adminUser.extraFunctions?.includes(prop);
  };
  return (
    <div>
      <h4 className={"fw-bold mb-4"}>Extra Functions</h4>
      <div>
        {adminsExtraFunctionsRows.map(({ description, propName }) => {
          return (
            <SettingsRow
              key={propName}
              description={description}
              isOn={isSettingEnabled(propName)}
              onChange={() => handleExtraFunctionsChange(propName)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ExtraFunctionsSection;
