import { formatTimestamp } from "src/utils/helpers";
import { Divider } from "antd";
import { InfoItem } from "../styled";

const DefaultApplicantInfo = ({ applicant }) => {
  const date = formatTimestamp(applicant?.createdAt);

  return (
    <>
      <InfoItem>
        <span>Name:</span> {applicant?.name || "N/A"}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Job type:</span> {applicant?.role?.replace(/_/g, " ")}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Email:</span> {applicant?.email}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Email verified:</span> {applicant?.emailVerified ? "Yes" : "No"}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Primary phone:</span> {applicant?.primaryPhone || "N/A"}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Status:</span> {applicant?.status}
      </InfoItem>
      <InfoItem>
        <Divider />
      </InfoItem>
      <InfoItem>
        <span>Request date: {date}</span>
      </InfoItem>
    </>
  );
};

export default DefaultApplicantInfo;
