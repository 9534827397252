import React from "react";
import { NavButton } from "src/utils/shared/styled";
import { pageTabs } from "../constants";

const NavigationBar = ({ currentView, setCurrentView }) => {
  return (
    <div className="d-flex align-items-center">
      <div>
        <NavButton
          className={currentView === pageTabs.PENDING ? "active" : undefined}
          onClick={() => setCurrentView(pageTabs.PENDING)}
          withRedBar
        >
          Pending Absences
        </NavButton>
      </div>
      <div>
        <NavButton
          className={currentView === pageTabs.RESOLVED ? "active" : undefined}
          onClick={() => setCurrentView(pageTabs.RESOLVED)}
          withRedBar
        >
          Resolved Absences
        </NavButton>
      </div>
    </div>
  );
};

export default NavigationBar;
