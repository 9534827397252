import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  paymentCalculationTypes,
  paymentsMap,
} from "src/constants/paymentsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { parseUserPaymentRateDates } from "src/utils/firebaseDatesParserFns";
import {
  calculateTotalFromPaymentForm,
  updatedMomentNow,
} from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";

const initialDataInitialValues = {
  currentPaymentRates: null,
  paymentUser: null,
};
const paymentFormDataInitialValues = paymentsMap.reduce(
  (acc, { propName }) => ({ ...acc, [propName]: "" }),
  {}
);

const useEditPayment = ({ paymentObj, user, onEditSuccess = () => {} }) => {
  const { getUserPaymentRateById, updateUserPayment, getUserById } =
    useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [paymentFormData, setPaymentFormData] = useState(
    paymentFormDataInitialValues
  );
  const [paymentNote, setPaymentNote] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  function handlePaymentFormDataChange(name, value) {
    const acceptsNegativeValue = paymentsMap.find(
      ({ propName }) => propName === name
    )?.acceptsNegativeValue;

    let modifiedValue;
    if (acceptsNegativeValue) {
      modifiedValue = !value ? "" : parseFloat(value);
    } else {
      modifiedValue = !value || parseFloat(value) <= 0 ? "" : parseFloat(value);
    }
    setPaymentFormData((oldVal) => ({
      ...oldVal,
      [name]: modifiedValue,
    }));
  }

  async function submitEditPayment() {
    try {
      if (calculatedTotalObj.total <= 0) {
        toast.error("Error while editing payment");
        return;
      }
      setIsSubmitting(true);

      const paymentsArr = [];

      for (const propName of Object.keys(paymentFormData)) {
        const currentPaymentMap = paymentsMap.find(
          (map) => propName === map.propName
        );
        if (!currentPaymentMap) {
          toast.error("Couldnt find currentPaymentMap ( payUser() )");
          continue;
        }

        const initialPaymentId = paymentObj.payments?.find(
          ({ type, calculatedBy, fractionPerUnit }) =>
            type === currentPaymentMap.paymentType &&
            calculatedBy === currentPaymentMap.paymentCalculationType &&
            fractionPerUnit === currentPaymentMap.fractionPerUnit
        )?.id;

        paymentFormData[propName] &&
          paymentsArr.push({
            id: initialPaymentId || uuidv4(),
            type: currentPaymentMap.paymentType,
            ...(currentPaymentMap.fractionPerUnit && {
              fractionPerUnit: currentPaymentMap.fractionPerUnit,
            }),
            calculatedBy: currentPaymentMap.paymentCalculationType,
            calculatedByAmount: paymentFormData[propName],
            totalPaid:
              calculatedTotalObj.individualPaymentsTotal[propName] || 0,
          });
      }

      const paymentUpdateObj = {
        updatedAt: new Date(),
        updatedBy: user.uid,

        payments: paymentsArr,
        note: paymentNote,
      };

      console.log(paymentUpdateObj);
      const res = await updateUserPayment(paymentObj.id, paymentUpdateObj);
      toast.success("Updated Successfully");
      onEditSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (!paymentObj) return;

    let initialPaymentsValues = {};
    for (const payment of paymentObj.payments) {
      const {
        type,
        fractionPerUnit,
        calculatedBy,
        calculatedByAmount,
        totalPaid,
      } = payment;
      const currentPaymentMap = paymentsMap.find(
        (map) =>
          map.paymentType === type &&
          map.fractionPerUnit === fractionPerUnit &&
          map.paymentCalculationType === calculatedBy
      );
      if (!currentPaymentMap) {
        toast.error(
          `Couldnt find currentPaymentMap for (${type}) in ( useEditPayment() )`
        );
        continue;
      }
      const propName = currentPaymentMap?.propName;
      initialPaymentsValues[propName] = calculatedByAmount;
    }
    setPaymentFormData((oldVal) => ({
      ...oldVal,
      ...initialPaymentsValues,
    }));
    setPaymentNote(paymentObj.note);
  }, [paymentObj?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!paymentObj) return;

        setLoadingInitialData(true);

        const [paymentRates, paymentUser] = await Promise.all([
          getUserPaymentRateById(paymentObj.paymentRatesId),
          getUserById(paymentObj.userId),
        ]);
        const parsedPaymentRates = parseUserPaymentRateDates(paymentRates);
        console.log({ paymentUser });
        setInitialData((oldVal) => ({
          ...oldVal,
          currentPaymentRates: parsedPaymentRates,
          paymentUser,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };

    fetchInitialData();
  }, [paymentObj.id]);

  const calculatedTotalObj = useMemo(() => {
    const userRates = initialData.currentPaymentRates?.rates || [];
    return calculateTotalFromPaymentForm({ paymentFormData, userRates });
  }, [paymentFormData, initialData]);

  const isAbleToSave = useMemo(() => {
    return calculatedTotalObj?.total && calculatedTotalObj?.total > 0;
  }, [calculatedTotalObj?.total]);

  console.log(calculatedTotalObj);

  return {
    initialData,
    paymentFormData,
    handlePaymentFormDataChange,
    loadingInitialData,
    calculatedTotalObj,
    submitEditPayment,
    isSubmitting,
    isAbleToSave,
    paymentNote,
    setPaymentNote,
  };
};

export default useEditPayment;
