import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import ModalBodyContent from "./ModalBodyContent";
import { CloseIcon } from "./styled";

const EditFeedItemModal = ({ modalData, feedItem }) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  return (
    <CustomModal size="lg" centered isOpen={isModalOpen} toggle={toggleModal}>
      <CustomModalHeader
        close={<CloseIcon onClick={closeModal} />}
      ></CustomModalHeader>
      <CustomModalBody padding="0">
        <div>
          <ModalBodyContent modalData={modalData} feedItem={feedItem} />
        </div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default EditFeedItemModal;
