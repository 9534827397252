import React from "react";
import { Button } from "reactstrap";
import { Modal } from "src/components/common";

const UnlockConfirmModal = ({ onConfirm, close }) => {
  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      <p className="lead mb-0">
        Are you sure you want to unlock the selected school year and make it
        editable?
      </p>
      <div className="m-2 alert alert-warning fw-bold">
        Editing the school year information may break the entire platform
      </div>
    </div>
  );

  const buttons = (
    <>
      <Button color="danger" onClick={onConfirm}>
        Yes, Unlock
      </Button>
    </>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Confirm Destructive update"}
      isOpen={true}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default UnlockConfirmModal;
