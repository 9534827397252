import React from "react";
import RouteContent from "src/components/common/RouteContent";
import { injectUserStore } from "src/utils/helpers";
import AdminsTable from "./AdminsTable";
import useAdminsData from "./hooks/useAdminsData";

const AdminsPage = ({ UserStore }) => {
  const user = UserStore.user;
  const adminsData = useAdminsData({ user });

  return (
    <RouteContent title="Admins">
      <div>
        <AdminsTable adminsData={adminsData} user={user} />
      </div>
    </RouteContent>
  );
};

export default injectUserStore(AdminsPage);
