import { Input, Radio, Button } from "antd";
import styled from "styled-components";

const SignupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4ced9;
`;

const CurrentStudentContainer = styled.div`
  width: 100%;
`;

const GroupClassSignupForm = styled.form`
  width: 100%;
  max-width: 320px;
  padding: 24px;

  background-color: #f9f9f9;
  border-radius: 12px;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 1080px) {
    max-width: 760px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 980px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1200px;
  }
`;

const FormField = styled.fieldset`
  margin-top: ${({ margin }) => margin && "32px"};
`;

const StudentTypeRadio = styled(Radio.Group)`
  display: block;
  margin-top: 16px;
`;

const StyledInput = styled(Input)`
  border-radius: 12px;
`;

const StyledButton = styled(Button)`
  width: 120px;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
`;

export {
  SignupWrapper,
  CurrentStudentContainer,
  GroupClassSignupForm,
  FormField,
  StudentTypeRadio,
  StyledInput,
  StyledButton,
};
