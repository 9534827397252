import { Menu } from "antd";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled, { css } from "styled-components";

const sharedSidebarStyles = css`
  background-color: #681e46;
  height: 100vh;
  transition: all 0.3s;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  & li {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & ul li a {
    text-align: left;
    text-decoration: none !important;
    color: #ffffff;
    font-weight: 600;
    opacity: 0.5;
  }

  & .sidebar-header {
    padding: 20px;
  }

  & .sidebar-header strong {
    font-size: 1.8em;
  }

  & ul.components {
    padding: 0 4px 0 0;
  }

  & ul li a {
    padding: 15px;
    font-size: 1.1em;

    display: block;
  }

  & ul li a:hover {
    opacity: 1;
  }

  & ul li a i {
    margin-right: 10px;
  }

  & ul li.active > a {
    opacity: 1;
  }
`;

export const SiderbarMainContainer = styled.nav.attrs((props) => {
  return {
    className: props.className,
  };
})`
  ${sharedSidebarStyles}

  min-width: 250px;
  max-width: 250px;

  &.is-closed {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
  }

  &.is-closed .sidebar-header h3,
  &.is-closed .sidebar-header strong {
    display: block;
  }

  &.is-closed ul li a {
    padding: 10px 10px;
    text-align: center;
    font-size: 0.85em;
  }

  &.is-closed ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
  }

  &.is-closed ul ul a {
    padding: 10px !important;
  }

  &.is-closed .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }

  & .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  //MEDIA QUERIES
  @media (max-width: ${ScreenSize.MD + "px"}) {
    & {
      display: none;
    }
  }
`;

export const MobileSidebarMainContainer = styled.nav`
  ${sharedSidebarStyles}

  min-width: 300px;
  max-width: 300px;

  &.is-closed {
    min-width: 0px;
    max-width: 0px;
    overflow: hidden;
  }
`;
export const ToggleArrowIconContainer = styled.div`
  position: absolute;
  background-color: #fff;
  right: -7px;
  top: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  z-index: 9;
  justify-content: center;
  border-radius: 12px;
  color: #681e46;
`;

export const LogoContainer = styled.div`
  height: ${({ height }) => height || "auto"};
`;
export const StyledLogo = styled.img`
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 172px;
`;

export const MobileSidebarOverlay = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  // hides the overlay when we close the sidebar
  bottom: ${({ isHidden }) => (isHidden ? "unset" : 0)};
  right: ${({ isHidden }) => (isHidden ? "unset" : 0)};
  background: #0000007a;
`;
export const TeacherAbsenceNotification = styled.span`
  color: #fff;
  padding: 10px;
`;

export const StyledMenu = styled(Menu)`
  background: transparent;
  user-select: none;

  * {
    color: #fff !important;
    font-size: 18px;
    font-weight: 600;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    margin: 10px 0;
  }

  .ant-menu-sub * {
    font-size: 16px;
    text-overflow: unset !important;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    opacity: 0.5;
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .active {
    opacity: 1;
  }
`;
