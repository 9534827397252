const filterUnusedPrograms = (programs) => {
  return Array.from(
    new Set(
      programs?.filter(
        (program) =>
          program !== "Senior" &&
          program !== "Master 1" &&
          program !== "Master 2" &&
          program !== "Virtual"
      )
    )
  );
};

export default filterUnusedPrograms;
