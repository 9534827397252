import React, { useEffect } from "react";
import { SectionContainer } from "../../styled";
import ClassInfo from "./ClassInfo";
import MakeupModal from "../../../MakeupModal";
import useModal from "src/hooks/useModal";
import useGroupClassAbsence from "../../../GroupClassAbsenceRequestModal/hooks/useGroupClassAbsence";
import store from "src/stores/UserStore";

const ClassesInfo = ({ groupClass, users, absences }) => {
  const makeupModalData = useModal();
  const { initialData } = useGroupClassAbsence({
    groupClassId: groupClass?.id,
  });
  const { user } = store;

  // useEffect(() => {
  //   console.log(absences);
  //   console.log(groupClass);
  // }, [absences, groupClass]);

  return (
    <>
      <SectionContainer>
        <h6>Classes Info</h6>
        <hr />
        {groupClass?.classes?.map((classObj, i) => {
          return (
            <ClassInfo
              key={classObj.id}
              classObj={classObj}
              users={users}
              i={i}
              absences={absences}
              groupClassId={groupClass.id}
            />
          );
        })}
      </SectionContainer>
      {/* <MakeupModal
        makeupModalData={makeupModalData}
        // currentAbsence={absences[0]}
        lesson={groupClass}
        UserStore={store}
      /> */}
    </>
  );
};

export default ClassesInfo;
