import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { Field } from "formik";

export const CustomModal = styled(Modal)`
  min-width: 650px;
  max-width: 650px;
  & .modal-content {
    border: none;
    /* background: transparent; */
  }
`;
export const CustomHeader = styled(ModalHeader)`
  border-radius: 30px 30px 0 0;
  height: 65px;
  padding-top: 0 !important;
`;

export const CustomBody = styled(ModalBody)`
  padding: 0 !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

export const BodyContainer = styled.div`
  min-height: 300px;
  padding: 30px;
  padding-top: 30px;
`;

export const ColorPalleteContainer = styled.div`
  border: none;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border-radius: 15px;
  padding: 10px;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 54%;
  padding-left: 12px;
`;
export const ColorOption = styled.div`
  border-radius: 50%;
  background-color: ${({ background }) => background};
  height: 30px;
  width: 30px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
`;
export const ErrorMessage = styled.p`
  margin-top: 10px;
  color: #ce0000;
  font-weight: 400;
`;
export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const CustomLabel = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
`;
export const CustomInput = styled.input`
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "250px"};
  ::placeholder {
    color: #979797;
  }

  &[type="time"] {
    /* width: 180px; */
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "250px")};
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fff";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;
