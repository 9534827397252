import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  absenceStatuses,
  makeupLimitAbsenceTypes,
} from "src/constants/absenceTypes";
import {
  isApprovedMakeupRequest,
  isDeclinedMakeupRequest,
  isPendingMakeupRequest,
} from "src/constants/makeupRequestEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useFirebaseHelpers from "src/hooks/useFirebaseHelpers";
import useModal from "src/hooks/useModal";

const useMakeupSection = ({
  privateLesson,
  absence,
  setCurrentAbsenceId,
  refreshData,
  user,
}) => {
  const { hasStudentReachedMaxMakeUps } = useFirebaseHelpers();
  const { getMakeupRequestsByAbsenceId, updateAbsence } = useFirebaseFns();
  const creditedAbsenceModalData = useModal();

  const [reachedMaxMakeups, setReachedMaxMakeups] = useState(false);
  const [currentMakeupRequest, setCurrentMakeupRequest] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  async function confirmCreditedAbsence() {
    try {
      setIsLoading(true);

      const updateObj = {
        status: absenceStatuses.APPROVED,
        updatedAt: new Date(),
        updatedBy: user.uid,
      };
      await updateAbsence(absence.id, updateObj);
      toast.success("Absence Updated Successfully");
      refreshData();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!absence || !privateLesson) return;
      try {
        const absenceDate = absence.date || absence.startDate;

        if (makeupLimitAbsenceTypes.includes(absence.absenceType)) {
          setIsLoading(true);

          const [maxMakeupsRes, [makeUpRequest]] = await Promise.all([
            hasStudentReachedMaxMakeUps(
              privateLesson.studentId,
              privateLesson.id,
              absenceDate
            ),
            getMakeupRequestsByAbsenceId(absence.id),
          ]);
          setCurrentMakeupRequest(makeUpRequest);
          setReachedMaxMakeups(maxMakeupsRes.reachedMaxMakeups);
        } else {
          setReachedMaxMakeups(false);
          setCurrentMakeupRequest(null);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [privateLesson, absence]);

  const reachedMaxMakeupsAndNoRequest = useMemo(() => {
    if (reachedMaxMakeups && !currentMakeupRequest) return true;
    return false;
  });
  const reachedMaxMakeupsAndPending = useMemo(() => {
    if (!reachedMaxMakeups || !currentMakeupRequest) return false;
    const isPending = isPendingMakeupRequest(currentMakeupRequest.status);
    return isPending;
  });
  const reachedMaxMakeupsAndDeclined = useMemo(() => {
    if (!reachedMaxMakeups || !currentMakeupRequest) return false;
    const isPending = isDeclinedMakeupRequest(currentMakeupRequest.status);
    return isPending;
  });
  const reachedMaxMakeupsAndApproved = useMemo(() => {
    if (!reachedMaxMakeups || !currentMakeupRequest) return false;
    const isPending = isApprovedMakeupRequest(currentMakeupRequest.status);
    return isPending;
  });

  const onCreditedAbsenceModalApprove = async () => {
    await confirmCreditedAbsence();
    creditedAbsenceModalData.closeModal();
    setCurrentAbsenceId("");
  };

  const onCreditedAbsenceModalCancel = async () => {
    creditedAbsenceModalData.closeModal();
    setCurrentAbsenceId("");
  };

  return {
    reachedMaxMakeups,
    isLoading,
    currentMakeupRequest,
    reachedMaxMakeupsAndNoRequest,
    reachedMaxMakeupsAndPending,
    reachedMaxMakeupsAndDeclined,
    reachedMaxMakeupsAndApproved,
    creditedAbsenceModalData,
    onCreditedAbsenceModalApprove,
    onCreditedAbsenceModalCancel,
  };
};

export default useMakeupSection;
