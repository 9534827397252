import React from "react";
import { ReportTable } from "./components";

const ReportDataSection = ({ hookData }) => {
  const { initialData, reportData, paymentsTotal } = hookData;
  const { users, payments } = reportData;
  return (
    <div>
      <ReportTable
        paymentsTotal={paymentsTotal}
        users={users}
        payments={payments}
      />
    </div>
  );
};

export default ReportDataSection;
