import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { CloseIcon, ImageContainer, InfoText, TeacherImage } from "./styled";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import { useState, useRef } from "react";

const ProfileImageModal = ({ modalData, user }) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  const { changeUserProfileImage } = useFirebaseFns();

  const { imageUrl } = user || {};
  const userId = user?.uid || user?.id;

  const uploadRef = useRef();

  const [isUploading, setIsUploading] = useState(false);

  const uploadImg = async (e) => {
    try {
      const file = e.target.files[0];
      setIsUploading(true);

      const { downloadUrl } = await changeUserProfileImage(userId, file);

      toast.success("Successfully uploaded.");
      closeModal();
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setIsUploading(false);
    }
  };

  const onUploadClick = () => {
    if (!isUploading) {
      uploadRef.current.click();
    }
  };

  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomModalHeader
        close={<CloseIcon onClick={closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      ></CustomModalHeader>
      <CustomModalBody>
        <div>
          <ImageContainer>
            <TeacherImage src={imageUrl || UnknownProfilePhoto} alt="profile" />
          </ImageContainer>
          <div className="mt-3 text-center">
            <InfoText>Please Upload a headshot with a clear face view</InfoText>
          </div>
          <div className="text-center mt-5">
            <input ref={uploadRef} hidden type={"file"} onChange={uploadImg} />
            <PrimaryButtonWithLoading
              onClick={onUploadClick}
              isLoading={isUploading}
              width="80%"
            >
              Change Image
            </PrimaryButtonWithLoading>
          </div>
        </div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ProfileImageModal;
