import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getNextSchoolYear,
} from "src/utils/helpers";

// the systemID of the location required to display school year info (Belmont)
const requiredLocationSystemID = 1;

const useCalendarData = () => {
  const { getSchoolYears, getLocations } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    schoolYears: [],
    locations: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);

        const [schoolYears, locations] = await Promise.all([
          getSchoolYears(),
          getLocations(),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          schoolYears,
          locations,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  const requiredLocation = useMemo(
    () =>
      initialData.locations.find(
        ({ systemID }) => systemID === requiredLocationSystemID
      ),
    [initialData.locations, requiredLocationSystemID]
  );
  // // current school year based on the selectedEvent locationId
  // const currentSchoolYearInLocation = useMemo(
  //   () => getCurrentSchoolYear(initialData.schoolYears, requiredLocation?.id),
  //   [initialData.schoolYears, requiredLocation?.id]
  // );

  // // next school year based on the selectedEvent locationId
  // const nextSchoolYearInLocation = useMemo(
  //   () =>
  //     getNextSchoolYear(
  //       initialData.schoolYears,
  //       requiredLocation?.id,
  //       currentSchoolYearInLocation
  //     ),
  //   [initialData.schoolYears, requiredLocation?.id, currentSchoolYearInLocation]
  // );

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears.length && !requiredLocation?.id) return [];

    const combinedSchoolBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      requiredLocation?.id,
      true
    );
    return combinedSchoolBreaks;
  }, [initialData, requiredLocation?.id]);

  return {
    // currentSchoolYearInLocation,
    // nextSchoolYearInLocation,
    combinedSchoolBreaks,
  };
};

export default useCalendarData;
