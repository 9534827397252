import "./style.css";
import React from "react";
import Attachment from "../../ArtisNews/NewContainer/NewsElement/Attachment";
import { Interweave } from "interweave";

function NewElement(props) {
  let date = new Date(props.date).toDateString();
  date = [date.slice(4, 10), ",", date.slice(11)].join("");
  return (
    <figure className="news-element">
      <h2 className="page-heading">Artis News</h2>

      <h2 className="news-element-heading">{props.title}</h2>
      <Attachment attachments={props.attachments} />

      <p className="summary">
        <Interweave content={props.body} />
      </p>
    </figure>
  );
}

export default NewElement;
