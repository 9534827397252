import { useState } from "react";
import { useNavigate } from "react-router";
import { useFeedbacks } from "../hooks";

import RouteContent from "src/components/common/RouteContent";
import { ButtonsWrapper } from "../../Guest/GuestProfile/GuestProfileSecondPage/styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { StyledFeedbacksTable } from "./styled";
import feedbacksTableColumns from "./feedbacksTableColumns";
import FeedbackDetailsModal from "../components/modals/FeedbackDetailsModal";
import { formatTimestamp } from "src/utils/helpers";

const FeedbacksArchive = () => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState(null);
  const navigate = useNavigate();
  const {
    archivedFeedbacks,
    archiveFeedbackToggle,
    isLoading,
    deleteFeedbackFromList,
  } = useFeedbacks();

  const handleDetailsModalOpen = (feedback) => {
    setCurrentFeedback(feedback);
    setDetailsModalOpen(true);
  };

  const columns = feedbacksTableColumns({
    onDeleteClick: deleteFeedbackFromList,
    onUnarchiveClick: archiveFeedbackToggle,
    onMessageClick: handleDetailsModalOpen,
    isLoading,
  });

  return (
    <RouteContent title="Feedbacks archive">
      <StyledFeedbacksTable
        loading={isLoading}
        columns={columns}
        bordered
        dataSource={archivedFeedbacks?.map((feedback) => {
          const date = formatTimestamp(feedback?.createdAt);

          return {
            request_date: date,
            name: feedback?.createdBy?.name,
            email: feedback?.createdBy?.email,
            role: feedback?.createdBy?.role?.replace(/_/g, " "),
            message: feedback?.message,
            attachments: feedback?.attachments,
            status: feedback?.status,
            key: feedback?.id,
          };
        })}
      />
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/feedbacks")}
        >
          Back to feedbacks
        </PrimaryButton>
      </ButtonsWrapper>
      <FeedbackDetailsModal
        isOpen={detailsModalOpen}
        toggle={() => setDetailsModalOpen((prevState) => !prevState)}
        feedback={currentFeedback}
      />
    </RouteContent>
  );
};

export default FeedbacksArchive;
