import React from "react";
import { InfoText1, InfoText2 } from "../styled";
import moment from "moment";

const MakeupLessonInfo = ({ makeupLesson }) => {
  const { teacher, program, date, instrument } = makeupLesson || {};
  const { startDate, endDate } = date || {};

  const instrumentName = instrument?.name;
  const teacherName = teacher?.fullName;
  const programName = program?.name;

  const time = `${moment(startDate).format("hh:mm A")} - ${moment(
    endDate
  ).format("hh:mm A")}`;
  const day = moment(startDate).format("dddd");
  const formattedNextLessonDate = startDate
    ? moment(startDate).format("dddd, MMM D, YYYY")
    : null;

  return (
    <div className="d-flex justify-content-between">
      <div>
        <div className="mb-3 mx-2">
          <InfoText1>Makeup Lesson Date</InfoText1>
          <InfoText2>{formattedNextLessonDate || "N/A"}</InfoText2>
        </div>
        <div className="mb-3 mx-2">
          <InfoText1>Program</InfoText1>
          <InfoText2>{programName || "N/A"}</InfoText2>
        </div>
        <div className="mb-3 mx-2">
          <InfoText1>Lesson Day</InfoText1>
          <InfoText2>{day || "N/A"}</InfoText2>
        </div>
      </div>
      <div className="w-35">
        <div className="mb-3 mx-2">
          <InfoText1>Teacher</InfoText1>
          <InfoText2>{teacherName || "N/A"}</InfoText2>
        </div>
        <div className="mb-3 mx-2">
          <InfoText1>Lesson Time</InfoText1>
          <InfoText2>{time || "N/A"}</InfoText2>
        </div>
        <div className="mb-3 mx-2">
          <InfoText1>Instrument</InfoText1>
          <InfoText2>{instrumentName || "N/A"}</InfoText2>
        </div>
      </div>
    </div>
  );
};

export default MakeupLessonInfo;
