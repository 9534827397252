import moment from "moment";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { feedItems } from "src/constants/feedItemsEnum";
import { getTimeDiffInMins, updatedMomentNow } from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";

// only gets feed items that were created after the maxData (new items)
const maxDate = updatedMomentNow().subtract(1, "week").toDate();

const useLatestFeed = ({ user }) => {
  const userRole = user.role;

  const { getFeedItems } = useFirebaseFns();
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState({
    feedItems: [],
  });
  const [showLatestFeedItem, setShowLatestFeedItem] = useState(true);
  const toggleLatestFeedSection = () => {
    setShowLatestFeedItem((oldVal) => !oldVal);
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);

        const [feedItems] = await Promise.all([
          getFeedItems({
            maxDate,
            filterByRoles: [userRole],
            afterPublishDate: true,
          }),
        ]);

        const sortedFeedItems = sortFeedItems(feedItems);

        setInitialData((oldVal) => ({
          ...oldVal,
          feedItems: sortedFeedItems,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  const latestFeedItem = useMemo(() => {
    const { feedItems } = initialData;

    const latest = feedItems.reduce(function (r, a) {
      return r?.publishDate > a?.publishDate ? r : a;
    }, undefined);
    return latest;
  }, [initialData]);

  const latestSeparatedFeedItems = useMemo(() => {
    const newAnnouncements = initialData.feedItems.filter(
      ({ type }) => type === feedItems.announcements.type
    );
    const newNews = initialData.feedItems.filter(
      ({ type }) => type === feedItems.news.type
    );
    const newWorkUpdates = initialData.feedItems.filter(
      ({ type }) => type === feedItems.workUpdates.type
    );
    const newOtherTopics = initialData.feedItems.filter(
      ({ type }) => type === feedItems.otherTopics.type
    );

    return { newAnnouncements, newNews, newWorkUpdates, newOtherTopics };
  }, [initialData.feedItems]);

  return {
    loading,
    latestFeedItems: initialData.feedItems,
    latestSeparatedFeedItems,
    latestFeedItem,
    showLatestFeedItem,
    toggleLatestFeedSection,
  };
};

export default useLatestFeed;

const sortFeedItems = (items) => {
  if (!items?.length) return [];
  const sorted = items.sort((a, b) => {
    const firstItemDate = a.publishDate;
    const secondItemDate = b.publishDate;

    return getTimeDiffInMins(secondItemDate, firstItemDate);
  });
  return sorted;
};
