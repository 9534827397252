import React from "react";
import styled from "styled-components";
import { Cross } from "@styled-icons/entypo/Cross";
import { toJS } from "mobx";
import { isSuperAdmin } from "src/constants/UserRoleEnum";
import { haveSuperAdminPermission, injectUserStore } from "src/utils/helpers";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";

export const CrossIcon = styled(Cross)`
  height: 15px;
  width: 15px;
  color: #000000;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

const TableCellData = styled.div`
  background-color: #f6f2f4;
  border-radius: 30px;
  padding: 10px 15px 10px 15px;
  border: 0px;
  box-shadow: 0px 2px 10px #0000000a;
  text-align: center;
  white-space: nowrap;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 150px;
  &:not(:hover) ${Button} {
    display: none;
  }
  &:hover ${Button} {
    display: block;
  }
`;

const TeacherContainer = ({
  id,
  teachers,
  teacher_id,
  deleteUsage,
  UserStore,
}) => {
  const getTeacherNameById = () => {
    const teacher_name = teachers.find((teacher) => {
      return teacher.id == teacher_id;
    });
    let nameArray = teacher_name?.fullName.split(" ");
    return nameArray[0] + " " + nameArray[1].charAt(0) + ".";
  };

  const user = toJS(UserStore)?.user;
  const hasPermisionToDelete = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.manageTeachersStudios
  );
  const isSuperAdminUser = isSuperAdmin(UserStore.user.role);

  return (
    <TableCellData>
      <div>{getTeacherNameById()}</div>
      {hasPermisionToDelete || isSuperAdminUser ? (
        <Button>
          <CrossIcon
            onClick={() => {
              deleteUsage(id);
            }}
          />
        </Button>
      ) : null}
    </TableCellData>
  );
};

export default injectUserStore(TeacherContainer);
